/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./task-pdf-downloader.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i5 from "@angular/material/progress-spinner";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/common";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i10 from "@angular/material/button";
import * as i11 from "@angular/cdk/a11y";
import * as i12 from "@angular/material/tooltip";
import * as i13 from "@angular/cdk/overlay";
import * as i14 from "@angular/cdk/scrolling";
import * as i15 from "@angular/cdk/bidi";
import * as i16 from "@angular/platform-browser";
import * as i17 from "@ngx-translate/core";
import * as i18 from "./task-pdf-downloader.component";
var styles_TaskPdfDownloaderComponent = [i0.styles];
var RenderType_TaskPdfDownloaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TaskPdfDownloaderComponent, data: {} });
export { RenderType_TaskPdfDownloaderComponent as RenderType_TaskPdfDownloaderComponent };
function View_TaskPdfDownloaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(1, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["color", "accent"], ["diameter", "20"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i4.View_MatSpinner_0, i4.RenderType_MatSpinner)), i1.ɵdid(3, 49152, null, 0, i5.MatSpinner, [i1.ElementRef, i6.Platform, [2, i7.DOCUMENT], [2, i8.ANIMATION_MODULE_TYPE], i5.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], { color: [0, "color"], diameter: [1, "diameter"] }, null)], function (_ck, _v) { _ck(_v, 1, 0); var currVal_4 = "accent"; var currVal_5 = "20"; _ck(_v, 3, 0, currVal_4, currVal_5); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).inline; _ck(_v, 0, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 3)._noopAnimations; var currVal_2 = i1.ɵnov(_v, 3).diameter; var currVal_3 = i1.ɵnov(_v, 3).diameter; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3); }); }
export function View_TaskPdfDownloaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "pdf-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 16777216, null, null, 7, "button", [["class", "pdf-button"], ["color", "warn"], ["mat-raised-button", ""], ["matTooltipPosition", "left"], ["type", "button"]], [[1, "data-cy", 0], [8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"], [null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.getReport() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i9.View_MatButton_0, i9.RenderType_MatButton)), i1.ɵdid(2, 180224, null, 0, i10.MatButton, [i1.ElementRef, i6.Platform, i11.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), i1.ɵdid(3, 147456, null, 0, i12.MatTooltip, [i13.Overlay, i1.ElementRef, i14.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i6.Platform, i11.AriaDescriber, i11.FocusMonitor, i12.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i15.Directionality], [2, i12.MAT_TOOLTIP_DEFAULT_OPTIONS], [2, i16.HAMMER_LOADER]], { position: [0, "position"], message: [1, "message"] }, null), i1.ɵpid(131072, i17.TranslatePipe, [i17.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(5, 0, [" ", " "])), i1.ɵpid(131072, i17.TranslatePipe, [i17.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_TaskPdfDownloaderComponent_1)), i1.ɵdid(8, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = "warn"; _ck(_v, 2, 0, currVal_3); var currVal_4 = "left"; var currVal_5 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 3, 1, i1.ɵnov(_v, 4).transform("TASKS.DOWNLOAD_PDF.TOOLTIP")), ""); _ck(_v, 3, 0, currVal_4, currVal_5); var currVal_7 = _co.buttonLoading; _ck(_v, 8, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = "getReport"; var currVal_1 = (i1.ɵnov(_v, 2).disabled || null); var currVal_2 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_6 = i1.ɵunv(_v, 5, 0, i1.ɵnov(_v, 6).transform("TASKS.DOWNLOAD_PDF.BUTTON")); _ck(_v, 5, 0, currVal_6); }); }
export function View_TaskPdfDownloaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-task-pdf-downloader", [], null, null, null, View_TaskPdfDownloaderComponent_0, RenderType_TaskPdfDownloaderComponent)), i1.ɵdid(1, 114688, null, 0, i18.TaskPdfDownloaderComponent, [i17.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TaskPdfDownloaderComponentNgFactory = i1.ɵccf("app-task-pdf-downloader", i18.TaskPdfDownloaderComponent, View_TaskPdfDownloaderComponent_Host_0, { task: "task", branch: "branch", forwarder: "forwarder" }, {}, []);
export { TaskPdfDownloaderComponentNgFactory as TaskPdfDownloaderComponentNgFactory };
