export const locale = {
  lang: 'pl',
  data: {
    'NAV': {
      'APPLICATIONS': 'Aplikacje',
      'MAIN': {
        'CALENDAR': 'Kalendarz',
        'CUSTOMER_COMPANIES': 'Firmy',
        'USERS': 'Użytkownicy',
        'ORDERS': 'Zlecenia',
        'TRANSPORT': 'Środki transportu',
        'FRACTIONS': 'Frakcje',
        'RECYCLING_ITEMS': 'Sprzęt do utylizacji',
        'PACKAGE_TYPES': 'Sposoby pakowania',
        'TASKS': 'Zadania',
        'REPORTS': 'Raporty',
      },
      'SAMPLE': {
        'TITLE': 'Test',
        'BADGE': '25'
      }
    }
  }
};
