import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Fraction, FractionsListRespone } from '../shared/models/fractions.model';
import { RequestParams } from '../shared/models/list.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class FractionsService {
  onEditEvent: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient, private authService: AuthService) {
  }

  getFractionsList(params: RequestParams): Observable<FractionsListRespone> {
    return this.http.get(`${environment.apiUrl}/${this.authService.getUserType()}/fraction`, {params: params})
      .pipe(map((res: FractionsListRespone) => res));
  }

  getFraction(fractionTypeUuid: Fraction['uuid']): Observable<Fraction> {
    return this.http.get(`${environment.apiUrl}/${this.authService.getUserType()}/fraction/${fractionTypeUuid}`).pipe(
      map((res: any) => res.fraction)
    );
  }

  createFraction(fraction: Fraction): Observable<Fraction> {
    return this.http.post<any>(`${environment.apiUrl}/${this.authService.getUserType()}/fraction`, fraction).pipe(
      map((res: any) => res.fraction)
    );
  }

  updateFraction(fractionId: Fraction['uuid'], fraction: Fraction): Observable<Fraction> {
    return this.http.put<any>(`${environment.apiUrl}/${this.authService.getUserType()}/fraction/${fractionId}`, fraction);
  }

  deleteFraction(fractionId: Fraction['uuid']) {
    return this.http.delete<any>(`${environment.apiUrl}/${this.authService.getUserType()}/fraction/${fractionId}`);
  }
}