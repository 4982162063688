import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {FuseTranslationLoaderService} from '../../../../../@fuse/services/translation-loader.service';
import {locale as polish} from '../../../../translations/pl';
import {locale as english} from '../../../../translations/en';
import {ConfirmModalComponent} from '../../../shared/components/confirm-modal/confirm-modal.component';
import {Company} from '../../../../shared/models/companies.model';
import {CustomerCompanyCar} from '../../../../shared/models/company-cars.model';
import {CompanyCarsService} from '../../../../services/company-cars.service';
import {CompaniesService} from '../../../../services/companies.service';
import {parseForm} from '../../../../shared/utils/parse-form';
import {CompanyBranch} from '../../../../shared/models/company.model';
import {UsersService} from '../../../../services/users.service';
import {FilteredTransport} from '../../../../shared/models/filtered-items.model';
import {FilteredListsService} from "../../../../services/filtered-lists.service";
import {RequestParams} from "../../../../shared/models/list.model";
import {markFormGroupTouched} from "../../../../shared/utils/markFormGroupAsTouched";

@Component({
  selector: 'app-company-car-editor',
  templateUrl: './company-car-editor.component.html',
  styleUrls: ['./company-car-editor.component.scss']
})
export class CompanyCarEditorComponent implements OnInit {
  public companyCarForm: FormGroup;
  public companyCarUuid: CustomerCompanyCar['uuid'];
  public companyCar: CustomerCompanyCar;
  public company: Company;
  public companyUuid: Company['uuid'];
  public companyBranches: CompanyBranch[] = [];
  public mode: string;
  public isLoading = false;
  public searchTransport: FormControl;
  public transportOptions: FilteredTransport[] = [];
  private filterParamsFullList: RequestParams = {
    page: 1,
    limit: 1000,
    sort: 'name',
    order: 'asc'
  };

  constructor(
    public companyCarEditorDialogRef: MatDialogRef<
      CompanyCarEditorComponent
    >,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private matDialog: MatDialog,
    private companyCarsService: CompanyCarsService,
    private companiesService: CompaniesService,
    private filteredListsService: FilteredListsService,
    private usersService: UsersService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService
  ) {
    this.mode = data.mode;
    this.companyUuid = data.companyUuid;
    this.companyCarUuid = data.companyCarUuid;

    this._fuseTranslationLoaderService.loadTranslations(polish, english);
  }

  ngOnInit() {
    this.createForm({});
    this.createSearchFilters();
    this.usersService.getCompanyBranchList().subscribe( res => {
      this.companyBranches = res.branches;
    });
    this.filteredListsService.getTransportList(this.filterParamsFullList).subscribe(res => {
      this.transportOptions = res.transports;
    });
    if (this.mode === 'add') {
      this.companiesService.getCompany(this.companyUuid)
        .subscribe(res => {
          this.company = res;
          this.createForm(res);
        });
    }
    if (this.mode === 'edit' || this.mode === 'view') {
      this.companyCarsService
        .getCompanyCar(this.companyUuid, this.companyCarUuid)
        .subscribe(res => {
          this.companyCar = res;
          this.createForm(res);
        });
    }
  }

  public submitCompanyCar() {
    this.isLoading = true;
    markFormGroupTouched(this.companyCarForm);

    if (this.companyCarForm.invalid) {
      this.isLoading = false;
      return;
    }
    if (this.mode === 'add') {
      this.companyCarsService
        .createCompanyCar(
          this.companyUuid,
          parseForm(this.companyCarForm.getRawValue())
        )
        .subscribe(
          () => {
            this.companyCarEditorDialogRef.close();
            this.companyCarEditorDialogRef.afterClosed().subscribe(() => {
              this.companyCarsService.onEditEvent.emit();
            });
            this.snackBar.open(
              this.translateService.instant(
                'COMPANY_CARS.MESSAGES.CREATE_SUCCESS'
              ),
              '',
              { duration: 5000 }
            );
          },
          err => {
            this.isLoading = false;
            this.snackBar.open(
              this.translateService.instant(
                'COMPANY_CARS.MESSAGES.CREATE_ERROR'
              ),
              '',
              { duration: 5000 }
            );
          }
        );
    } else {
      this.companyCarsService
        .updateCompanyCar(
          this.companyUuid,
          this.companyCarUuid,
          parseForm(this.companyCarForm.getRawValue())
        )
        .subscribe(
          () => {
            this.companyCarEditorDialogRef.close();
            this.companyCarEditorDialogRef.afterClosed().subscribe(() => {
              this.companyCarsService.onEditEvent.emit();
            });
            this.snackBar.open(
              this.translateService.instant(
                'COMPANY_CARS.MESSAGES.UPDATE_SUCCESS'
              ),
              '',
              { duration: 5000 }
            );
          },
          err => {
            this.isLoading = false;
            this.snackBar.open(
              this.translateService.instant(
                'COMPANY_CARS.MESSAGES.UPDATE_ERROR'
              ),
              '',
              { duration: 5000 }
            );
          }
        );
    }
  }

  public deleteCompanyCar() {
    const deleteDialogRef = this.matDialog.open(ConfirmModalComponent, {
      disableClose: false,
      autoFocus: false
    });
    deleteDialogRef.componentInstance.confirmMessage = this.translateService.instant(
      'COMPANY_CARS.MESSAGES.DELETE_QUESTION'
    );
    deleteDialogRef.componentInstance.titleMessage = this.translateService.instant(
      'COMPANY_CARS.MESSAGES.DELETE_TITLE'
    );
    deleteDialogRef.componentInstance.confirmButton = this.translateService.instant(
      'COMPANY_CARS.DELETE'
    );

    deleteDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.companyCarsService
          .deleteCompanyCar(this.companyUuid, this.companyCarUuid)
          .subscribe(
            () => {
              this.companyCarEditorDialogRef.close();
              this.companyCarEditorDialogRef.afterClosed().subscribe(() => {
                this.companyCarsService.onEditEvent.emit();
              });
              this.snackBar.open(
                this.translateService.instant(
                  'COMPANY_CARS.MESSAGES.DELETE_SUCCESS'
                ),
                '',
                { duration: 5000 }
              );
            },
            () => {
              this.snackBar.open(
                this.translateService.instant(
                  'COMPANY_CARS.MESSAGES.DELETE_ERROR'
                ),
                '',
                { duration: 5000 }
              );
            }
          );
      }
    });
  }

  public enableEditMode() {
    this.companyCarEditorDialogRef.close();
    this.matDialog.open(CompanyCarEditorComponent, {
      panelClass: 'company-car-editor-dialog',
      autoFocus: false,
      disableClose: true,
      data: {
        mode: 'edit',
        companyUuid: this.companyUuid,
        companyCarUuid: this.companyCarUuid
      }
    });
  }

  public getTitle(): string {
    switch (this.mode) {
      case 'add':
        return this.translateService.instant('COMPANY_CARS.TITLE_ADD');
      case 'edit':
        return this.translateService.instant('COMPANY_CARS.TITLE_EDIT');
      case 'view':
        return this.translateService.instant('COMPANY_CARS.TITLE_VIEW');
      default:
        return;
    }
  }

  private createForm(inputData: CustomerCompanyCar | any) {
    this.companyCarForm = this.formBuilder.group({
      name: new FormControl(this.mode === 'add' ? '' : inputData.name || '', Validators.required),
      type: new FormControl(inputData.type || '', Validators.required),
      registrationNumberFront: new FormControl(inputData.registrationNumberFront || '', Validators.required),
      registrationNumberBack: new FormControl(inputData.registrationNumberBack || '', Validators.required),
      driverName: new FormControl(inputData.driverName || '', Validators.required),
      driverPhone: new FormControl(inputData.driverPhone || '', Validators.required),
      bdo: new FormControl(inputData.bdo || '', Validators.required),
      companyName: new FormControl(this.mode === 'add' ? inputData.name : inputData.companyName || '', Validators.required),
      comments: new FormControl(inputData.comments || ''),
      companyBranchId: new FormControl(inputData.companyBranchId || '', Validators.required),
    });

    if (this.mode === 'view') {
      this.companyCarForm.disable();
    }
  }

  private createSearchFilters() {
    this.searchTransport = new FormControl('');
    this.searchTransport.valueChanges.debounceTime(300).subscribe((value) => {
      this.filteredListsService.getTransportList({ ...this.filterParamsFullList, ...{ name: value } }).subscribe(
        response => {
          this.transportOptions = response.transports;
        });
    });
  }

}
