import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-package-types.container',
  templateUrl: './package-types.container.component.html',
  styleUrls: ['./package-types.container.component.scss']
})
export class PackageTypesContainerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
