import {AbstractControl} from '@angular/forms';
import {FilteredOrderData} from '../models/filtered-items.model';

export const ValidateItemsObject = (item) => {
  let error = false;
  Object.keys(item).forEach(key => {
    if (key === 'code' || key === 'description') {
      return error;
    }

    if (!item[key]) {
      error = true;
    }
    if (key === 'value' && item.unit && item.unit !== 'pieces' && !(/^[\d]+[.]{0,1}[\d]{0,3}$/.test(item[key]))) {
      error = true;
    }
    if (key === 'value' && (!item.unit || (item.unit && item.unit === 'pieces')) &&
      !(/^[\d]+$/.test(item[key]))) {
      error = true;
    }
    if (key === 'value' && item[key] === '0') {
      error = true;
    }
  });
  return error;
};

export function ValidateItemsList(control: AbstractControl) {
  let emptyInput = false;
  control.value && control.value.forEach((item: FilteredOrderData) => {
    if (ValidateItemsObject(item.recyclingItem)) {
      emptyInput = true;
    }
    item.packageTypes.forEach((pack) => {
      if (ValidateItemsObject(pack)) {
        emptyInput = true;
      }
    });
  });
  if (emptyInput) {
    return {recyclingItems: true};
  }
  return null;
}
