import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-task-orders-changes-array',
  templateUrl: './task-orders-changes-array.component.html',
  styleUrls: ['./task-orders-changes-array.component.scss']
})
export class TaskOrdersChangesArrayComponent {
  @Input() changedOrders: any;
}
