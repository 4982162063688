import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { ReportsListRespone } from '../shared/models/reports.model';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  onEditEvent: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient, private authService: AuthService) {
  }

  getReports(filterParams: {dateFrom: string, dateTo: string, companyBranchId?: string}): Observable<ReportsListRespone> {
    return this.http.get(`${environment.apiUrl}/${this.authService.getUserType()}/tasks/reports`,
      {params: filterParams})
      .pipe(map((res: ReportsListRespone) => res));
  }
}