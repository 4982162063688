import { Component, Inject, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
  MatSnackBar,
} from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { FuseTranslationLoaderService } from "../../../../../@fuse/services/translation-loader.service";

import { CompanyKpoService } from "app/services/company-kpo.service";
import { Company } from "../../../../shared/models/companies.model";
import { CompanyKpo } from "../../../../shared/models/company-kpo.model";
import { markFormGroupTouched } from "../../../../shared/utils/markFormGroupAsTouched";
import { locale as english } from "../../../../translations/en";
import { locale as polish } from "../../../../translations/pl";
import { ConfirmModalComponent } from "../../../shared/components/confirm-modal/confirm-modal.component";

@Component({
  selector: "app-company-kpo-editor",
  templateUrl: "./company-kpo-editor.component.html",
  styleUrls: ["./company-kpo-editor.component.scss"],
})
export class CompanyKpoEditorComponent implements OnInit {
  public companyKpoForm: FormGroup;
  public companyKpoUuid: CompanyKpo["uuid"];
  public companyKpo: CompanyKpo;
  public companyUuid: Company["uuid"];
  public passwordForm: FormGroup;
  public changePassword = false;
  public mode: string;
  public isLoading = false;

  constructor(
    public companyKpoEditorDialogRef: MatDialogRef<CompanyKpoEditorComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private matDialog: MatDialog,
    private companyKpoService: CompanyKpoService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService
  ) {
    this.mode = data.mode;
    this.companyUuid = data.companyUuid;
    this.companyKpoUuid = data.companyKpoUuid;

    this._fuseTranslationLoaderService.loadTranslations(polish, english);
  }

  ngOnInit() {
    this.createForm({});

    if (this.mode === "edit" || this.mode === "view") {
      this.companyKpoService
        .getCompanyKpo(this.companyUuid, this.companyKpoUuid)
        .subscribe((res) => {
          this.companyKpo = res;
          this.createForm(res);
        });
    }
  }

  public submitCompanyKpo() {
    this.isLoading = true;
    markFormGroupTouched(this.companyKpoForm);

    if (this.companyKpoForm.invalid) {
      this.isLoading = false;
      return;
    }
    if (this.mode === "add") {
      this.companyKpoService
        .createCompanyKpo(this.companyUuid, this.companyKpoForm.getRawValue())
        .subscribe(
          () => {
            this.companyKpoEditorDialogRef.close();
            this.companyKpoEditorDialogRef.afterClosed().subscribe(() => {
              this.companyKpoService.onEditEvent.emit();
            });
            this.snackBar.open(
              this.translateService.instant(
                "COMPANY_KPO.MESSAGES.CREATE_SUCCESS"
              ),
              "",
              { duration: 5000 }
            );
          },
          (err) => {
            this.isLoading = false;
            if (err.status === 409 && err.error && err.error.details) {
              this.assignExistingCompanyKpo(err.error.details.uuid);
            } else {
              this.snackBar.open(
                this.translateService.instant(
                  "COMPANY_KPO.MESSAGES.CREATE_ERROR"
                ),
                "",
                { duration: 5000 }
              );
            }
          }
        );
    } else {
      this.companyKpoService
        .updateCompanyKpo(
          this.companyUuid,
          this.companyKpoUuid,
          this.companyKpoForm.getRawValue()
        )
        .subscribe(
          () => {
            this.companyKpoEditorDialogRef.close();
            this.companyKpoEditorDialogRef.afterClosed().subscribe(() => {
              this.companyKpoService.onEditEvent.emit();
            });
            this.snackBar.open(
              this.translateService.instant(
                "COMPANY_KPO.MESSAGES.UPDATE_SUCCESS"
              ),
              "",
              { duration: 5000 }
            );
          },
          (err) => {
            this.isLoading = false;
            if (err.status === 409) {
              this.snackBar.open(
                this.translateService.instant(
                  "COMPANY_KPO.MESSAGES.UPDATE_EMAIL_ERROR"
                ),
                "",
                { duration: 5000 }
              );
            } else {
              this.snackBar.open(
                this.translateService.instant(
                  "COMPANY_KPO.MESSAGES.UPDATE_ERROR"
                ),
                "",
                { duration: 5000 }
              );
            }
          }
        );
    }
  }

  public deleteCompanyKpo() {
    let deleteDialogRef = this.matDialog.open(ConfirmModalComponent, {
      disableClose: false,
      autoFocus: false,
    });
    deleteDialogRef.componentInstance.confirmMessage =
      this.translateService.instant("COMPANY_KPO.MESSAGES.DELETE_QUESTION");
    deleteDialogRef.componentInstance.titleMessage =
      this.translateService.instant("COMPANY_KPO.MESSAGES.DELETE_TITLE");
    deleteDialogRef.componentInstance.confirmButton =
      this.translateService.instant("COMPANY_KPO.DELETE");

    deleteDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.companyKpoService
          .detachCompanyKpo(this.companyUuid, this.companyKpoUuid)
          .subscribe(
            () => {
              this.companyKpoEditorDialogRef.close();
              this.companyKpoEditorDialogRef.afterClosed().subscribe(() => {
                this.companyKpoService.onEditEvent.emit();
              });
              this.snackBar.open(
                this.translateService.instant(
                  "COMPANY_KPO.MESSAGES.DELETE_SUCCESS"
                ),
                "",
                { duration: 5000 }
              );
            },
            () => {
              this.snackBar.open(
                this.translateService.instant(
                  "COMPANY_KPO.MESSAGES.DELETE_ERROR"
                ),
                "",
                { duration: 5000 }
              );
            }
          );
      }
    });
  }

  public assignExistingCompanyKpo(companyKpoUuid: CompanyKpo["uuid"]) {
    let assignDialogRef = this.matDialog.open(ConfirmModalComponent, {
      disableClose: false,
      autoFocus: false,
    });
    assignDialogRef.componentInstance.confirmMessage =
      this.translateService.instant("COMPANY_KPO.MESSAGES.ASSIGN_QUESTION");
    assignDialogRef.componentInstance.titleMessage =
      this.translateService.instant("COMPANY_KPO.MESSAGES.ASSIGN_TITLE");
    assignDialogRef.componentInstance.confirmButton =
      this.translateService.instant("COMPANY_KPO.ASSIGN");

    assignDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.companyKpoService
          .attachCompanyKpo(this.companyUuid, companyKpoUuid)
          .subscribe(
            () => {
              this.companyKpoEditorDialogRef.close();
              this.companyKpoEditorDialogRef.afterClosed().subscribe(() => {
                this.companyKpoService.onEditEvent.emit();
              });
              this.snackBar.open(
                this.translateService.instant(
                  "COMPANY_KPO.MESSAGES.ASSIGN_SUCCESS"
                ),
                "",
                { duration: 5000 }
              );
            },
            () => {
              this.snackBar.open(
                this.translateService.instant(
                  "COMPANY_KPO.MESSAGES.ASSIGN_ERROR"
                ),
                "",
                { duration: 5000 }
              );
            }
          );
      }
    });
  }

  public enableEditMode() {
    this.companyKpoEditorDialogRef.close();
    this.matDialog.open(CompanyKpoEditorComponent, {
      panelClass: "company-kpo-editor-dialog",
      autoFocus: false,
      disableClose: true,
      data: {
        mode: "edit",
        companyUuid: this.companyUuid,
        companyKpoUuid: this.companyKpoUuid,
      },
    });
  }

  public getTitle(): string {
    switch (this.mode) {
      case "add":
        return this.translateService.instant("COMPANY_KPO.TITLE_ADD");
      case "edit":
        return this.translateService.instant("COMPANY_KPO.TITLE_EDIT");
      case "view":
        return this.translateService.instant("COMPANY_KPO.TITLE_VIEW");
      default:
        return;
    }
  }

  private createForm(inputData: CompanyKpo | any) {
    this.companyKpoForm = this.formBuilder.group({
      name: new FormControl(inputData.name || "", Validators.required),
      surname: new FormControl(inputData.surname || "", Validators.required),
      email: new FormControl(inputData.email || "", Validators.required),
      phone: new FormControl(inputData.phone || "", Validators.required),
    });

    if (this.mode === "view") {
      this.companyKpoForm.disable();
    }
  }
}
