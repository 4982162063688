var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FuseTranslationLoaderService } from '../../../../../../@fuse/services/translation-loader.service';
import { locale as polish } from '../../../../../translations/pl';
import { locale as english } from '../../../../../translations/en';
import { List } from '../../../../../shared/models/list.class';
import { AuthService } from '../../../../../services/auth.service';
import { CompanyAccountsService } from '../../../../../services/company-accounts.service';
import { CompanyAccountEditorComponent } from '../../../../company-accounts/components/company-account-editor/company-account-editor.component';
var CompanyAccountsListComponent = /** @class */ (function (_super) {
    __extends(CompanyAccountsListComponent, _super);
    function CompanyAccountsListComponent(companyAccountsService, authService, matDialog, _fuseTranslationLoaderService) {
        var _this = _super.call(this) || this;
        _this.companyAccountsService = companyAccountsService;
        _this.authService = authService;
        _this.matDialog = matDialog;
        _this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        _this.displayedColumns = ['name', 'surname', 'email', 'phone', 'status'];
        _this.listFilters = {};
        _this.requestParams = {
            page: 1,
            limit: 10,
            sort: 'surname',
            order: 'asc'
        };
        _this.user = _this.authService.getUser();
        _this.reloadList$ = companyAccountsService.onEditEvent.subscribe(function () {
            _this.getElementsList();
        });
        _this._fuseTranslationLoaderService.loadTranslations(polish, english);
        _this.getElementsList = function () {
            _this.companyAccountsService.getCompanyAccountsList(_this.requestParams, _this.companyUuid)
                .subscribe(function (res) {
                _this.totalCount = res.totalCount;
                _this.dataSource = res.clients;
            });
        };
        return _this;
    }
    CompanyAccountsListComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.getElementsList();
    };
    CompanyAccountsListComponent.prototype.ngOnDestroy = function () {
        this.reloadList$.unsubscribe();
    };
    CompanyAccountsListComponent.prototype.addCompanyAccount = function () {
        this.disableAnimations = true;
        this.matDialog.open(CompanyAccountEditorComponent, {
            panelClass: 'company-account-editor-dialog',
            autoFocus: false,
            disableClose: true,
            data: {
                mode: 'add',
                companyUuid: this.companyUuid
            }
        });
    };
    CompanyAccountsListComponent.prototype.viewCompanyAccount = function (companyAccountUuid) {
        this.disableAnimations = true;
        this.matDialog.open(CompanyAccountEditorComponent, {
            panelClass: 'company-account-editor-dialog',
            autoFocus: false,
            disableClose: true,
            data: {
                mode: 'view',
                companyUuid: this.companyUuid,
                companyAccountUuid: companyAccountUuid
            }
        });
    };
    return CompanyAccountsListComponent;
}(List));
export { CompanyAccountsListComponent };
