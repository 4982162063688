<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">{{getTitle()}}</span>
      <button mat-icon-button (click)="transportEditorDialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>

    <form [formGroup]="transportForm">

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'TRANSPORT.NAME' | translate}}</mat-label>
          <mat-icon matSuffix class="secondary-text">local_shipping</mat-icon>
          <input name="name" formControlName="name" matInput required [attr.data-cy]="'transportName'">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'TRANSPORT.DESC' | translate}}</mat-label>
          <textarea name="description" formControlName="description" matInput [attr.data-cy]="'transportDescription'"></textarea>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'TRANSPORT.CAPACITY' | translate}} [T]</mat-label>
          <input name="capacity" formControlName="capacity" matInput [attr.data-cy]="'transportCapacity'">
          <mat-error
            *ngIf="transportForm.get('capacity').touched && transportForm.controls['capacity'].errors?.pattern">
            {{'SHARED.MESSAGES.DECIMAL_ERROR' | translate}}
          </mat-error>
          <mat-error
            *ngIf="transportForm.get('capacity').touched && transportForm.controls['capacity'].errors?.smaller">
            {{'SHARED.MESSAGES.SMALLER_EQUAL_ZERO' | translate}}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'TRANSPORT.VOLUME' | translate}} [m<sup>3</sup>]</mat-label>
          <input name="volume" formControlName="volume" matInput [attr.data-cy]="'transportVolume'">
          <mat-error
            *ngIf="transportForm.get('volume').touched && transportForm.controls['volume'].errors?.pattern">
            {{'SHARED.MESSAGES.DECIMAL_ERROR' | translate}}
          </mat-error>
          <mat-error
            *ngIf="transportForm.get('volume').touched && transportForm.controls['volume'].errors?.smaller">
            {{'SHARED.MESSAGES.SMALLER_EQUAL_ZERO' | translate}}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'TRANSPORT.CATEGORY' | translate}}</mat-label>
          <mat-select formControlName="category" required [attr.data-cy]="'transportSetCategory'">
            <mat-option *ngFor="let category of transportCategories" [value]="category.value" [attr.data-cy]="category.value">
              {{category.name | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'TRANSPORT.STATUS' | translate}}</mat-label>
          <mat-select formControlName="active" [attr.data-cy]="'transportIsActive'">
            <mat-option [value]="true" [attr.data-cy]="'ACTIVE'">{{'TRANSPORT.STATUSES.ACTIVE' | translate}}</mat-option>
            <mat-option [value]="false" [attr.data-cy]="'INACTIVE'">{{'TRANSPORT.STATUSES.INACTIVE' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </form>

  </div>

  <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

    <button *ngIf="mode === 'edit'"
            mat-button
            class="mr-8"
            [attr.data-cy]="'transportDelete'"
            (click)="deleteTransport()">
      {{'TRANSPORT.DELETE' | translate}}
    </button>

    <button *ngIf="mode === 'view'"
            mat-button
            class="mr-8"
            [attr.data-cy]="'transportEdit'"
            (click)="enableEditMode()">
      {{'TRANSPORT.EDIT' | translate}}
    </button>

    <button *ngIf="mode === 'edit' || mode === 'add'"
            mat-button
            class="save-button"
            (click)="submitTransport()"
            [attr.data-cy]="'transportSave'"
            [disabled]="isLoading">
      <span *ngIf="!isLoading"> {{'TRANSPORT.SAVE' | translate}}</span>
      <span *ngIf="isLoading" class="loading-spinner-wrapper">
        <mat-progress-spinner mode="indeterminate"
                              [strokeWidth]="2"
                              [diameter]="20">
        </mat-progress-spinner>
      </span>
    </button>

  </div>
</div>