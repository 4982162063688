import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {locale as polish} from '../../../../translations/pl';
import {locale as english} from '../../../../translations/en';
import {FuseTranslationLoaderService} from '../../../../../@fuse/services/translation-loader.service';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {FILTERS_DICT} from './filters-dictionary';
import {ListFilters} from '../../../../shared/models/list.model';
import {DisabledOptions, Filter} from '../../../../shared/models/filters.model';
import * as moment from 'moment';

@Component({
  selector: 'app-list-filters',
  templateUrl: './list-filters.component.html',
  styleUrls: ['./list-filters.component.scss']
})
export class ListFiltersComponent implements OnInit {
  @Output() listFilters = new EventEmitter<ListFilters>();
  @Input() textFilters: string[] = [];
  @Input() weightFilters: string[] = [];
  @Input() filters: string[] = [];
  @Input() filtersMulti: string[] = [];
  @Input() dateFilters: string[] = [];
  @Input() taskDateFilters: string[] = [];
  @Input() tagsFilters: string[] = [];
  @Input() customFilters: Filter[] = [];
  @Input() customMultiFilters: Filter[] = [];
  @Input() disabledFilters: DisabledOptions[] = [];
  @Input() tasks: boolean;
  @Input() initFilters: { [key: string]: any } = {};
  @Input() companyBranchName?: string;
  @Input() companyBranchId?: string;
  public filtersTypes: object = FILTERS_DICT;
  public filtersForm: FormGroup;

  constructor(private _fuseTranslationLoaderService: FuseTranslationLoaderService,
              private formBuilder: FormBuilder) {
    this._fuseTranslationLoaderService.loadTranslations(polish, english);
  }

  ngOnInit() {
    this.createFiltersForm();
  }

  public clearFilters() {
    this.filtersForm.reset();
  }

  private createFiltersForm() {
    this.filtersForm = this.formBuilder.group({});
    this.filtersMulti.forEach((filterStatus) => {
      this.filtersForm.addControl(filterStatus, new FormControl(this.initFilters[filterStatus] || null));
    });
    this.textFilters && this.textFilters.forEach((filter) => {
      this.filtersForm.addControl(filter, new FormControl(this.initFilters[filter] || null));
    });
    this.weightFilters && this.weightFilters.forEach((filter) => {
      this.filtersForm.addControl(filter, new FormControl(this.initFilters[filter] || null));
      this.filtersForm.addControl(`${filter}Operator`, new FormControl(this.initFilters[filter] || null));
    });
    this.filters && this.filters.forEach((filter) => {
      this.filtersForm.addControl(filter, new FormControl(this.initFilters[filter] || null));
    });
    this.dateFilters && this.dateFilters.forEach((dateFilter) => {
      this.filtersForm.addControl(dateFilter, new FormControl(this.initFilters[dateFilter] || null));
    });
    this.taskDateFilters && this.taskDateFilters.forEach((dateFilter) => {
      this.filtersForm.addControl(dateFilter, new FormControl(this.initFilters[dateFilter] || null));
    });
    this.customFilters && this.customFilters.forEach((customFilter) => {
      this.filtersForm.addControl(customFilter.name, new FormControl(this.initFilters[customFilter.name] || null));
    });
    this.customMultiFilters && this.customMultiFilters.forEach((customMultiFilter) => {
      this.filtersForm.addControl(customMultiFilter.name, new FormControl(this.initFilters[customMultiFilter.name] || null));
    });
    this.tagsFilters && this.tagsFilters.forEach((filter) => {
      this.filtersForm.addControl(filter, new FormControl(this.initFilters[filter] || []));
    });
    this.filtersForm.addControl('tagsToggle', new FormControl(false));
    this.listFilters.emit(this.getFilters());
    this.filtersForm.valueChanges.subscribe(() => {
      this.listFilters.emit(this.getFilters());
    });

    this.toggleDisableFilters();

  }

  private toggleDisableFilters() {
    if (!this.filtersForm || !this.disabledFilters || this.disabledFilters.length === 0) {
      return;
    }

    this.disabledFilters.forEach(disabledFilters => {
      this.filtersForm.get(disabledFilters.disabledBy).valueChanges.subscribe(value => {
        disabledFilters.disabled.forEach(filter => {
          if (value) {
            this.filtersForm.get(filter).reset();
            this.filtersForm.get(filter).disable();
          } else {
            this.filtersForm.get(filter).enable();
          }
        });
      });
    });

  }

  private getFilters() {
    let filtersObject: any = {};
    this.filtersMulti && this.filtersMulti.length && this.filtersMulti.forEach((filterMulti, index) => {
      const value = this.filtersForm.get(this.filtersMulti[index]).value;
      if (value && value.length) {
        const filterName = this.filtersMulti[index] === 'taskStatus' ? 'status' : this.filtersMulti[index];
        filtersObject[filterName] = value.join(',');
      }
    });
    this.textFilters && this.textFilters.forEach((filter) => {
      const value = this.filtersForm.get(filter).value;
      if (value) {
        filtersObject[filter] = value;
      }
    });
    this.weightFilters && this.weightFilters.forEach((filter) => {
      const value = this.filtersForm.get(filter).value;
      const lessMore = this.filtersForm.get(`${filter}Operator`).value;
      if (value) {
        filtersObject[filter] = value;
        filtersObject[`${filter}Operator`] = lessMore;
      }
    });
    this.filters && this.filters.forEach((filter) => {
      const value = this.filtersForm.get(filter).value;
      if (value && value !== 'all_options') {
        filtersObject[filter] = value;
      }
    });
    this.dateFilters && this.dateFilters.forEach((dateFilter) => {
      const value = this.filtersForm.get(dateFilter).value;
      if (value) {
        filtersObject[dateFilter] = moment(value).format('YYYY-MM-DD');
      }
    });
    this.taskDateFilters && this.taskDateFilters.forEach((dateFilter) => {
      const value = this.filtersForm.get(dateFilter).value;
      if (value) {
        filtersObject[dateFilter] = moment(value).format('YYYY-MM-DD');
      }
    });
    this.customFilters && this.customFilters.forEach((customFilter) => {
      const value = this.filtersForm.get(customFilter.name).value;
      if (value) {
        filtersObject[customFilter.name] = value;
      }
    });
    this.customMultiFilters && this.customMultiFilters.length && this.customMultiFilters.forEach((customMultiFilter, index) => {
      const value = this.filtersForm.get(this.customMultiFilters[index].name).value;
      if (value && value.length) {
        filtersObject[this.customMultiFilters[index].name] = value.join(',');
      }
    });
    this.tagsFilters && this.tagsFilters.length && this.tagsFilters.forEach((tagFilter, index) => {
      const value = this.filtersForm.get(tagFilter).value;
      if (value && value.length) {
        filtersObject[tagFilter] = value.join(',');
        if (this.filtersForm.get('tagsToggle').value) {
          filtersObject['tag_operator'] = 'and';
        } else {
          delete filtersObject.tag_operator;
        }
      }
    });
    return filtersObject;
  }

  public handleWeightPrefix(name) {
    const control = this.filtersForm.get(name + 'Operator');
    if (control.value === 'gte') {
      control.setValue('lte');
    } else if (control.value === '') {
      control.setValue('gte');
    } else {
      control.setValue('');
    }
  }
}
