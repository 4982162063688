<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{'TASKS.ADD_INVOICE' | translate}}</span>
            <button mat-icon-button (click)="taskInvoiceModalDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>
    <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>
        <div>
            <form class="form" [formGroup]="invoiceForm">
                <div class="input-wrapper-task-receiving">
                    <mat-form-field appearance="outline" fxFlex>
                        <mat-label>{{'TASKS.FORWARDER.INVOICE_NUMBER' | translate}}</mat-label>
                        <input formControlName="invoiceNumber" matInput [attr.data-cy]="'forwarderInvoiceNumber'">
                    </mat-form-field>
                </div>
            </form>
        </div>
        <div class="action-btn" fxLayout="row" fxLayoutAlign="end center">
            <button
                    mat-button
                    class="save-button"
                    [attr.data-cy]="'saveTask'"
                    (click)="save()"
                    [disabled]="invoiceForm.invalid || isLoading">
                <span *ngIf="!isLoading"> {{'TASKS.SAVE' | translate}}</span>
                <span *ngIf="isLoading" class="loading-spinner-wrapper">
        <mat-progress-spinner mode="indeterminate"
                              [strokeWidth]="2"
                              [diameter]="20">
        </mat-progress-spinner>
      </span>
            </button>
        </div>
    </div>
</div>