export const locale = {
  lang: 'en',
  data: {
    'NAV': {
      'APPLICATIONS': 'Applications',
      'MAIN': {
        'CALENDAR': 'Calendar',
        'CUSTOMER_COMPANIES': 'Customer Companies',
        'USERS': 'Users',
        'ORDERS': 'Orders',
        'TRANSPORT': 'Transport',
        'RECYCLING_ITEMS': 'Recycling items',
        'PACKAGE_TYPES': 'Package types',
        'TASKS': 'Tasks',
        'REPORTS': 'Reports'
      },
      'SAMPLE': {
        'TITLE': 'Sample',
        'BADGE': '25'
      }
    }
  }
};
