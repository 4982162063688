/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./style-2.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "@angular/flex-layout/extended";
import * as i9 from "@angular/flex-layout/core";
import * as i10 from "../../../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i11 from "@angular/material/icon";
import * as i12 from "../../../../../../@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive";
import * as i13 from "../../../../../../@fuse/services/config.service";
import * as i14 from "@angular/router";
import * as i15 from "../../../../../../@fuse/components/navigation/navigation.component.ngfactory";
import * as i16 from "../../../../../../@fuse/components/navigation/navigation.component";
import * as i17 from "../../../../../../@fuse/components/navigation/navigation.service";
import * as i18 from "./style-2.component";
import * as i19 from "../../../../../../@fuse/components/sidebar/sidebar.service";
var styles_NavbarVerticalStyle2Component = [i0.styles];
var RenderType_NavbarVerticalStyle2Component = i1.ɵcrt({ encapsulation: 2, styles: styles_NavbarVerticalStyle2Component, data: {} });
export { RenderType_NavbarVerticalStyle2Component as RenderType_NavbarVerticalStyle2Component };
export function View_NavbarVerticalStyle2Component_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { directive: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 17, "div", [["class", "navbar-header"]], null, null, null, null, null)), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "logo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["class", "logo-icon"], ["src", "assets/images/logo_white.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "span", [["class", "logo-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["FUSE"])), (_l()(), i1.ɵeld(7, 0, null, null, 5, "button", [["class", "toggle-sidebar-folded"], ["fxHide.lt-lg", ""], ["mat-icon-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleSidebarFolded() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(8, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(9, 737280, null, 0, i8.ShowHideDirective, [i9.MediaMonitor, [8, null], i1.ElementRef, i9.StyleUtils, i1.PLATFORM_ID, [2, i9.SERVER_TOKEN]], { hideLtLg: [0, "hideLtLg"] }, null), (_l()(), i1.ɵeld(10, 0, null, 0, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i10.View_MatIcon_0, i10.RenderType_MatIcon)), i1.ɵdid(11, 9158656, null, 0, i11.MatIcon, [i1.ElementRef, i11.MatIconRegistry, [8, null], [2, i11.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["menu"])), (_l()(), i1.ɵeld(13, 0, null, null, 5, "button", [["class", "toggle-sidebar-opened"], ["fxHide.gt-md", ""], ["mat-icon-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleSidebarOpened() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(14, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.Platform, i6.FocusMonitor, [2, i7.ANIMATION_MODULE_TYPE]], null, null), i1.ɵdid(15, 737280, null, 0, i8.ShowHideDirective, [i9.MediaMonitor, [8, null], i1.ElementRef, i9.StyleUtils, i1.PLATFORM_ID, [2, i9.SERVER_TOKEN]], { hideGtMd: [0, "hideGtMd"] }, null), (_l()(), i1.ɵeld(16, 0, null, 0, 2, "mat-icon", [["class", "mat-icon"], ["role", "img"]], [[2, "mat-icon-inline", null]], null, null, i10.View_MatIcon_0, i10.RenderType_MatIcon)), i1.ɵdid(17, 9158656, null, 0, i11.MatIcon, [i1.ElementRef, i11.MatIconRegistry, [8, null], [2, i11.MAT_ICON_LOCATION]], null, null), (_l()(), i1.ɵted(-1, 0, ["arrow_back"])), (_l()(), i1.ɵeld(19, 0, null, null, 5, "div", [["class", "navbar-content"], ["fusePerfectScrollbar", ""]], null, [["window", "resize"], ["document", "click"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 21)._updateOnResize() !== false);
        ad = (pd_0 && ad);
    } if (("document:click" === en)) {
        var pd_1 = (i1.ɵnov(_v, 21).documentClick($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(20, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(21, 4341760, [[1, 4]], 0, i12.FusePerfectScrollbarDirective, [i1.ElementRef, i13.FuseConfigService, i5.Platform, i14.Router], { fusePerfectScrollbarOptions: [0, "fusePerfectScrollbarOptions"], enabled: [1, "enabled"] }, null), i1.ɵpod(22, { suppressScrollX: 0 }), (_l()(), i1.ɵeld(23, 0, null, null, 1, "fuse-navigation", [["layout", "vertical"]], null, null, null, i15.View_FuseNavigationComponent_0, i15.RenderType_FuseNavigationComponent)), i1.ɵdid(24, 114688, null, 0, i16.FuseNavigationComponent, [i1.ChangeDetectorRef, i17.FuseNavigationService], { layout: [0, "layout"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "navbar-header"; var currVal_1 = _co.fuseConfig.layout.navbar.secondaryBackground; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_4 = ""; _ck(_v, 9, 0, currVal_4); _ck(_v, 11, 0); var currVal_8 = ""; _ck(_v, 15, 0, currVal_8); _ck(_v, 17, 0); var currVal_10 = "navbar-content"; var currVal_11 = _co.fuseConfig.layout.navbar.primaryBackground; _ck(_v, 20, 0, currVal_10, currVal_11); var currVal_12 = _ck(_v, 22, 0, true); var currVal_13 = ""; _ck(_v, 21, 0, currVal_12, currVal_13); var currVal_14 = "vertical"; _ck(_v, 24, 0, currVal_14); }, function (_ck, _v) { var currVal_2 = (i1.ɵnov(_v, 8).disabled || null); var currVal_3 = (i1.ɵnov(_v, 8)._animationMode === "NoopAnimations"); _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_5 = i1.ɵnov(_v, 11).inline; _ck(_v, 10, 0, currVal_5); var currVal_6 = (i1.ɵnov(_v, 14).disabled || null); var currVal_7 = (i1.ɵnov(_v, 14)._animationMode === "NoopAnimations"); _ck(_v, 13, 0, currVal_6, currVal_7); var currVal_9 = i1.ɵnov(_v, 17).inline; _ck(_v, 16, 0, currVal_9); }); }
export function View_NavbarVerticalStyle2Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "navbar-vertical-style-2", [], null, null, null, View_NavbarVerticalStyle2Component_0, RenderType_NavbarVerticalStyle2Component)), i1.ɵdid(1, 245760, null, 0, i18.NavbarVerticalStyle2Component, [i13.FuseConfigService, i17.FuseNavigationService, i19.FuseSidebarService, i14.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NavbarVerticalStyle2ComponentNgFactory = i1.ɵccf("navbar-vertical-style-2", i18.NavbarVerticalStyle2Component, View_NavbarVerticalStyle2Component_Host_0, {}, {}, []);
export { NavbarVerticalStyle2ComponentNgFactory as NavbarVerticalStyle2ComponentNgFactory };
