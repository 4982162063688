<h3> {{'ORDERS.RECYCLING_ITEMS' | translate}} <sup *ngIf="required">*</sup></h3>
<mat-accordion>
  <mat-expansion-panel *ngFor="let item of chosenItems; let i=index"
                       [expanded]="step === i"
                       #itemPanel
                       (opened)="setStep(i)"
                       [disabled]="validateRecyclingItems()"
                       class="recycling-item-wrapper"
  >
    <mat-expansion-panel-header class="recycling-item-header">
      <mat-panel-title class="item-tittle">
        <p>{{item.recyclingItem.name ? item.recyclingItem.name : 'ORDERS.RECYCLING_ITEM' | translate}}</p>
        <p *ngIf="item.recyclingItem.unit && item.recyclingItem.value" class="item-weight">
          &nbsp;-&nbsp;{{'RECYCLING.ITEM_WEIGHT' | translate}} {{getItemWeight(item)}}
          {{'RECYCLING.UNIT_TYPES.KGS' | translate}}
        </p>
      </mat-panel-title>
      <div *ngIf="mode !== 'view' && chosenItems.length > 1 && itemPanel.expanded" class="remove-item">
        <button mat-stroked-button
                color="primary"
                class="remove-item-button"
                [attr.data-cy]="'deleteItem'"
                (click)="deleteItem(i)">
          {{'ORDERS.DELETE' | translate}}
        </button>
      </div>
    </mat-expansion-panel-header>
    <div class="recycle-item full-name-select">
      <mat-form-field appearance="outline" fxFlex>
        <mat-label>{{'ORDERS.RECYCLING' | translate}}</mat-label>
        <mat-select [name]="'recycling.uuid'+i"
                    [(ngModel)]="item.recyclingItem.uuid"
                    (ngModelChange)="onModelChange()"
                    (valueChange)="pickOrderItem($event, item)"
                    [disabled]="mode === 'view'" required [attr.data-cy]="'recyclingItem'">
          <mat-option>
            <ngx-mat-select-search [formControl]="searchRecycling"
                                   [placeholderLabel]="'ORDERS.SEARCH' | translate"
                                   [noEntriesFoundLabel]="'SHARED.NO_RESULTS' | translate">
            </ngx-mat-select-search>
          </mat-option>
          <mat-option class="full-name-option" *ngIf="item.recyclingItem.uuid" [value]=item.recyclingItem.uuid [attr.data-cy]="item.recyclingItem.name">
            {{item.recyclingItem.name}}
            <ng-container *ngIf="item.recyclingItem.code">[<small>{{ item.recyclingItem.code }}</small>]</ng-container>
            <div *ngIf="item.recyclingItem.description">
              <small>{{ item.recyclingItem.description }}</small>
            </div>
          </mat-option>
          <mat-option class="full-name-option" *ngFor="let option of getRecyclingOptions()" [value]="option.uuid" [attr.data-cy]="option.name">
            {{ option.name }}
            <ng-container *ngIf="option.code">[<small>{{ option.code }}</small>]</ng-container>
            <div *ngIf="option.description">
              <small>{{ option.description }}</small>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="recycle-item">
      <div fxLayout="row" fxLayoutAlign="start start" class="item-value-unit">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'ORDERS.QUANTITY' | translate}}</mat-label>
          <input #recycleValue="ngModel" [name]="'recycling.value' + i" [(ngModel)]="item.recyclingItem.value" matInput
                 required
                 [disabled]="mode === 'view'"
                 [pattern]="item.recyclingItem.unit === 'pieces' ? valuePattern : decimalValuePattern"
                 (ngModelChange)="onModelChange()"
                 [attr.data-cy]="'valueRecycling'">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start" class="item-value-unit">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'ORDERS.UNIT' | translate}}</mat-label>
          <mat-select [name]="'recycling.unit' + i" [(ngModel)]="item.recyclingItem.unit"
                      (ngModelChange)="onModelChange()"
                      [disabled]="mode === 'view'" required
                      [attr.data-cy]="'unitRecycling'">
            <mat-option value="m3" [attr.data-cy]="'m3'">m<sup>3</sup></mat-option>
            <mat-option value="kg" [attr.data-cy]="'kg'">{{'RECYCLING.UNIT_TYPES.KG' | translate}}</mat-option>
            <mat-option value="pieces" [attr.data-cy]="'pieces'">{{'RECYCLING.UNIT_TYPES.PIECES' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <p *ngIf="item.recyclingItem.unit && item.recyclingItem.value" class="item-weight-additional">
        {{'RECYCLING.ITEM_WEIGHT' | translate}} {{getItemWeight(item)}}{{'RECYCLING.UNIT_TYPES.KGS' | translate}}
      </p>

      <mat-error *ngIf="recycleValue.touched && recycleValue.errors?.pattern && item.recyclingItem.unit !== 'pieces'">
        {{'SHARED.MESSAGES.DECIMAL_ERROR_THREE' | translate}}
      </mat-error>
      <mat-error *ngIf="recycleValue.touched && recycleValue.errors?.pattern && item.recyclingItem.unit === 'pieces'">
        {{'SHARED.MESSAGES.NO_DECIMAL_ERROR' | translate}}
      </mat-error>
    </div>

    <div *ngIf="item.recyclingItem.name && item.recyclingItem.value && item.recyclingItem.unit">
      <h3 class="packages-header"> {{'ORDERS.PACKAGE_TYPES' | translate}} <sup *ngIf="required">*</sup></h3>

      <div *ngFor="let pack of item.packageTypes; let packageIndex = index" class="package-type">

        <div fxLayout="row" fxLayoutAlign="start start" class="package-name">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>{{'ORDERS.PACKAGE_TYPE' | translate}}</mat-label>
            <mat-select [name]="'package.uuid' + packageIndex"
                        [(ngModel)]="pack.uuid"
                        (ngModelChange)="onModelChange()"
                        (valueChange)="pickPackageType($event, pack)"
                        [disabled]="mode === 'view'" required
                        [attr.data-cy]="'packageName'">
              <mat-option>
                <ngx-mat-select-search [formControl]="searchPackage"
                                       [placeholderLabel]="'ORDERS.SEARCH' | translate"
                                       [noEntriesFoundLabel]="'SHARED.NO_RESULTS' | translate">
                </ngx-mat-select-search>
              </mat-option>
              <mat-option *ngIf="pack.uuid" [value]=pack.uuid [attr.data-cy]="pack.name">{{pack.name}}</mat-option>
              <mat-option *ngFor="let option of getPackageOptions(i)"
                          [attr.data-cy]="option.name"
                          [value]="option.uuid">{{ option.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="start start" class="package-value">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>{{'ORDERS.QUANTITY' | translate}}</mat-label>
            <input #packageValue="ngModel" [name]="'package.value' + packageIndex" [(ngModel)]="pack.value" matInput
                   required
                   [disabled]="mode === 'view'" [pattern]="pack.unit === 'pieces' ? valuePattern : decimalValuePattern"
                   (ngModelChange)="onModelChange()" [attr.data-cy]="'packageUnit'">
            <span *ngIf="pack.unit" matSuffix>{{'RECYCLING.UNIT_TYPES.' + pack.unit.toUpperCase() | translate}}</span>
          </mat-form-field>
        </div>

        <div fxLayout="row" fxLayoutAlign="end center" class="remove-package">
          <button mat-icon-button [disabled]="mode === 'view' || item.packageTypes.length <= 1"
                  (click)="deletePackage(packageIndex, item.packageTypes)">
            <mat-icon>close</mat-icon>
          </button>
        </div>

        <mat-error *ngIf="packageValue.touched && packageValue.errors?.pattern && pack.unit !== 'pieces'">
          {{'SHARED.MESSAGES.DECIMAL_ERROR_THREE' | translate}}
        </mat-error>
        <mat-error *ngIf="packageValue.touched && packageValue.errors?.pattern && pack.unit === 'pieces'">
          {{'SHARED.MESSAGES.NO_DECIMAL_ERROR' | translate}}
        </mat-error>
      </div>

      <div *ngIf="mode !== 'view'" class="mb-24" fxLayout="row" fxLayoutAlign="center start">
        <button mat-button
                (click)="addPackageType(item.packageTypes)"> {{'ORDERS.ADD_PACKAGE_TYPE'| translate}}
        </button>
      </div>
    </div>
  </mat-expansion-panel>

  <p class="order-total-weight">
    {{'ORDERS.TOTAL_WEIGHT' | translate}} {{getTotalWeight()}}{{'RECYCLING.UNIT_TYPES.KGS' | translate}}
  </p>
  <div *ngIf="mode !== 'view'" class="mb-24" fxLayout="row" fxLayoutAlign="center start">
    <button mat-button
            mat-stroked-button
            color="primary"
            (click)="addItem()"
            [attr.data-cy]="'addItem'"
            [disabled]="chosenItems.length > 0 && validateRecyclingItems()"
    >
      {{'ORDERS.ADD_RECYCLING_ITEM'| translate}}
    </button>
  </div>
</mat-accordion>


