import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { RequestParams } from '../shared/models/list.model';
import { AuthService } from './auth.service';
import {Description, NoteListResponse} from '../shared/models/description.model';

@Injectable({
  providedIn: 'root'
})
export class NoteCalendarService {
  onEditEvent: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient, private authService: AuthService) {
  }

  getNoteList(params: RequestParams): Observable<NoteListResponse> {
    return this.http.get(`${environment.apiUrl}/${this.authService.getUserType()}/calendar-notes`, {params})
      .pipe(map((res: NoteListResponse) => res));
  }

  public addNote(note: string, date: string, companyBranchId: string): Observable<Description> {
    return this.http.post<any>(`${environment.apiUrl}/${this.authService.getUserType()}/calendar-notes`, {note, date, companyBranchId}).pipe(
      map((res: any) => res.description)
    );
  }
}