import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { OrdersListComponent } from './components/orders-list/orders-list.component';
import { Routes } from './orders.routes';
import { OrdersContainerComponent } from './orders-container.component';
import { OrderExportFormComponent } from './components/order-forms/order-export-form/order-export-form.component';
import { OrderTypePickerComponent } from './components/order-type-picker/order-type-picker.component';
import { OrderImportFormComponent } from './components/order-forms/order-import-form/order-import-form.component';
import { OrderReceivingFormComponent } from './components/order-forms/order-receiving-form/order-receiving-form.component';
import { OrderDetailsFormComponent } from './components/order-forms/order-details-form/order-details-form.component';
import { OrderLinkFormComponent } from './components/order-forms/order-link-form/order-link-form.component';
import { TasksModule } from '../tasks/tasks.module';
import { HistoryDialogComponent } from '../shared/components/history-dialog/history-dialog.component';
import {MatChipsModule} from "@angular/material/chips";

@NgModule({
  declarations: [
    OrdersContainerComponent,
    OrdersListComponent,
    OrderTypePickerComponent,
    OrderExportFormComponent,
    OrderImportFormComponent,
    OrderReceivingFormComponent,
    OrderDetailsFormComponent,
    OrderLinkFormComponent,
  ],
  imports: [
    RouterModule.forChild(Routes),
    SharedModule,
    TasksModule,
    MatChipsModule,
  ],
  exports: [
    OrdersListComponent,
    OrderTypePickerComponent,
    OrderExportFormComponent,
    OrderImportFormComponent,
    OrderReceivingFormComponent,
    OrderDetailsFormComponent,
    OrderLinkFormComponent,
    HistoryDialogComponent,
  ],
  entryComponents: [
    OrderTypePickerComponent,
    OrderExportFormComponent,
    OrderImportFormComponent,
    OrderReceivingFormComponent,
    OrderLinkFormComponent,
    HistoryDialogComponent,
  ]
})
export class OrdersModule { }
