import { NgModule } from '@angular/core';
import { UsersContainerComponent } from './users-container.component';
import { UsersListComponent } from './components/users-list/users-list.component';
import { RouterModule } from '@angular/router';
import { Routes } from './users.routes';
import { SharedModule } from '../shared/shared.module';
import { UserEditorComponent } from './components/user-editor/user-editor.component';

@NgModule({
  declarations: [
    UsersContainerComponent,
    UsersListComponent,
    UserEditorComponent
  ],
  imports: [
    RouterModule.forChild(Routes),
    SharedModule
  ],
  exports: [
    UsersListComponent,
    UserEditorComponent
  ],
  entryComponents: [
    UserEditorComponent
  ]
})
export class UsersModule { }



