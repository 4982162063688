import { EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./auth.service";
var NoteCalendarService = /** @class */ (function () {
    function NoteCalendarService(http, authService) {
        this.http = http;
        this.authService = authService;
        this.onEditEvent = new EventEmitter();
    }
    NoteCalendarService.prototype.getNoteList = function (params) {
        return this.http.get(environment.apiUrl + "/" + this.authService.getUserType() + "/calendar-notes", { params: params })
            .pipe(map(function (res) { return res; }));
    };
    NoteCalendarService.prototype.addNote = function (note, date, companyBranchId) {
        return this.http.post(environment.apiUrl + "/" + this.authService.getUserType() + "/calendar-notes", { note: note, date: date, companyBranchId: companyBranchId }).pipe(map(function (res) { return res.description; }));
    };
    NoteCalendarService.ngInjectableDef = i0.defineInjectable({ factory: function NoteCalendarService_Factory() { return new NoteCalendarService(i0.inject(i1.HttpClient), i0.inject(i2.AuthService)); }, token: NoteCalendarService, providedIn: "root" });
    return NoteCalendarService;
}());
export { NoteCalendarService };
