import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { FuseConfigService } from '../../../../../@fuse/services/config.service';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { locale as polish } from '../../../../translations/pl';
import { PasswordValidator } from '../../../../shared/validators/password.validator';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  public changePasswordForm: FormGroup;
  public isLoading = false;
  public mode: string;
  private token: string;

  constructor(private authService: AuthService,
              private translateService: TranslateService,
              private router: Router,
              private route: ActivatedRoute,
              private snackBar: MatSnackBar,
              private formBuilder: FormBuilder,
              private _fuseConfigService: FuseConfigService,
              private _fuseTranslationLoaderService: FuseTranslationLoaderService) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };

    authService.removeAuthData();

    this.mode = this.route.snapshot.data.mode;

    this._fuseTranslationLoaderService.loadTranslations(polish);
  }

  ngOnInit() {
    this.token = this.route.snapshot.params.token;

    this.changePasswordForm = this.formBuilder.group({
      token: [this.token, Validators.required],
      password: ['', [Validators.required, Validators.minLength(5)]],
      confirmPassword: ['', Validators.required]
    }, {
      validator: PasswordValidator.MatchPassword
    });
  }

  public submit() {
    this.isLoading = true;

    this.authService.setPassword(this.changePasswordForm.value).subscribe(
      () => {
        if (this.mode === 'set') {
          this.snackBar.open(this.translateService.instant('SHARED.MESSAGES.SET_PASSWORD_SUCCESS'), '', {duration: 5000});
        } else {
          this.snackBar.open(this.translateService.instant('SHARED.MESSAGES.RESET_PASSWORD_SUCCESS'), '', {duration: 5000});
        }
        this.router.navigate(['/login']);
      }, () => {
        this.isLoading = false;
        this.snackBar.open(this.translateService.instant('SHARED.MESSAGES.ERROR'), '', {duration: 5000});
      }
    )
  }

  public getTitle() {
    switch (this.mode) {
      case 'set':
        return this.translateService.instant('SHARED.SET_PASS');
      case 'reset':
        return this.translateService.instant('SHARED.RESET_PASS');
    }
  }

}
