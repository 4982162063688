<div class="orders">
  <div class="header p-16 p-sm-24"
       fxLayout.gt-xs="row"
       fxLayoutAlign.gt-xs="space-between center" *ngIf="user">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="logo" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="logo-icon mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
          style
        </mat-icon>
        <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
          {{'ORDERS.TITLE_LIST' | translate}}
        </span>
      </div>
    </div>
    <div class="search-wrapper">
      <div class="search">
        <label for="search" class="mr-8">
          <mat-icon (click)="clearSearch()">{{searchOrder.value ? 'clear' : 'search'}}</mat-icon>
        </label>
        <input id="search" [formControl]="searchOrder" [placeholder]="'ORDERS.SEARCH_PLACEHOLDER' | translate">
      </div>
    </div>
  </div>

  <div class="orders-content">
    <app-list-filters class="filters-container"
                      [filters]="tableFilters"
                      [textFilters]="textFilters"
                      [weightFilters]="weightFilters"
                      [filtersMulti]="tableStatus"
                      [dateFilters]="['dateFrom', 'dateTo']"
                      [taskDateFilters]="['suggestedDateFrom', 'suggestedDateTo', 'taskDate']"
                      [tagsFilters]="tagsFilters"
                      [initFilters]="initialUserFilters"
                      [disabledFilters]="[{disabledBy: 'taskDate', disabled: ['dateFrom', 'dateTo']}]"
                      [customMultiFilters]="
                          (user.userType !== userTypes.Manager ||
                          (user.userType === userTypes.Manager && user.roleType === roleTypes.Admin)) &&
                          [{name: 'companyBranchUuid', options: companyBranches}]"
                      [customFilters]="(user.userType === userTypes.Sales) && [{name: 'ownerId', options: salesList}]"
                      (listFilters)="getListFilters($event)">
    </app-list-filters>
    <div class="orders-table">
      <mat-table
        matSort
        [dataSource]="dataSource"
        [matSortActive]="requestParams.sort"
        [matSortDirection]="requestParams.order"
        [@animateStaggerRemondis]="dataSource?.length"
        [@.disabled]="disableAnimations"
      >
        <!-- Checkbox Column -->
        <ng-container matColumnDef="select">
          <mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null"
                          [checked]="selection.hasValue() && isAllSelected()"
                          [indeterminate]="selection.hasValue() && !isAllSelected()">
            </mat-checkbox>
          </mat-header-cell>
          <mat-cell *matCellDef="let order">
            <mat-checkbox (click)="$event.stopPropagation()"
                          (change)="$event ? selection.toggle(order) : null"
                          [checked]="selection.isSelected(order)"
                          [disabled]="order.status !== orderStatuses.Planned && filterByTaskDate">
            </mat-checkbox>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="tags">
          <mat-header-cell *matHeaderCellDef>{{'ORDERS.TAGS' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let order">
            <mat-chip-list *ngIf="order.tags.length > 0 && order.tags.length <= 4 ">
              <mat-chip *ngFor="let tag of order.tags" [selectable]="false">
                {{ tag}}
              </mat-chip>
            </mat-chip-list>
            <mat-chip-list *ngIf="order.tags.length > 4">
              <mat-chip *ngFor="let i of [0,1,2,3]" [selectable]="false">
                {{ order.tags[i]}}
              </mat-chip>
            </mat-chip-list>
            <span *ngIf="order.tags.length > 4 "> (...)</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="salesAcceptedDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'ORDERS.SALES_ACCEPTED_DATE' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let order"> {{order.salesAcceptedDate | date:'yyy-MM-dd HH:mm':'':'pl'}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="customerCompanyName">
          <mat-header-cell *matHeaderCellDef>{{'ORDERS.CUSTOMER_COMPANY' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let order">
            <span class="customer-company-name" [matTooltip]="order.customerCompany?.name"
                  matTooltipPosition="above">
                {{order.customerCompany?.name}}
            </span></mat-cell>
        </ng-container>

        <ng-container matColumnDef="city">
          <mat-header-cell *matHeaderCellDef>{{'ORDERS.ADDRESS' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let order">
            <span class="customer-company-address"
                  *ngIf="order.orderType !== orderTypes.Import"
                  [matTooltip]="order.customerCompanyBranch?.street + '\n' +order.customerCompanyBranch?.postalCode +', '
                  + order.customerCompanyBranch?.city "
                  matTooltipPosition="above"> {{order.customerCompanyBranch?.street}},
              <br/> {{order.customerCompanyBranch?.postalCode}}, {{order.customerCompanyBranch?.city}}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="orderType">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'ORDERS.TYPE' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let order">
             <span class="badge-type" [ngClass]="order.orderType"
                   [matTooltip]="'ORDERS.TYPES.' + order.orderType.toUpperCase() | translate"
                   matTooltipPosition="above">
              {{'ORDERS.TYPES.' + order.orderType.toUpperCase() + '.SHORT' | translate}}
            </span></mat-cell>
        </ng-container>

        <ng-container matColumnDef="totalWeight">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'ORDERS.TOTAL_WEIGHT' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let order"> {{order.totalWeight}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="comment">
          <mat-header-cell *matHeaderCellDef>{{'ORDERS.COMMENT' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let order">
             <span *ngIf="order.comment" class="badge-type import"
                   [matTooltip]="order.comment"
                   matTooltipPosition="above">
              !
            </span></mat-cell>
        </ng-container>

        <ng-container matColumnDef="suggestedDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'ORDERS.SUGGESTED_DATE' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let order">  {{order.suggestedDate | date:'yyy-MM-dd':'':'pl'}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'ORDERS.STATUS' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let order"> {{'ORDERS.STATUSES.' + order.status?.toUpperCase() | translate}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="createdBySurname">
          <mat-header-cell *matHeaderCellDef>{{'ORDERS.OWNER_TITLE' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let order"> {{order.createdBy?.name.substring(0,1).toUpperCase()}} {{order.createdBy?.surname.substring(0,2).toUpperCase()}}</mat-cell>
        </ng-container>

         <ng-container matColumnDef="createdDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'ORDERS.DATE' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let order"> {{order.createdDate | date:'yyy-MM-dd HH:mm':'':'pl'}}</mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          [ngClass]="order.status==='planned' ? 'order-row-planned' : 'order-row'"
          *matRowDef="let order; columns: displayedColumns;"
          [@animateRemondis]="{value:'*',params:{y:'100%'}}"
          [id]="order.uuid"
          (click)="viewOrder(order.uuid, order.orderType)"
        >
        </mat-row>
      </mat-table>

      <h4 class="no-data" *ngIf="totalCount === 0">
        {{'SHARED.NO_DATA' | translate}}
      </h4>

      <div *ngIf="totalCount" class="paginator-container">
        <button *ngIf="filterByTaskDate"
                mat-raised-button
                class="mat-accent close-order-button"
                [disabled]="!this.selection.selected || this.selection.selected.length === 0"
                (click)="closeOrders($event)">
          {{'ORDERS.CLOSE_CHOSEN_ORDERS' | translate}}
        </button>
        <button *ngIf="!filterByTaskDate && user.userType !== userTypes.Sales"
                mat-raised-button
                class="mat-accent close-order-button"
                [disabled]="!this.selection.selected || this.selection.selected.length === 0"
                (click)="generateTask($event)">
          {{'ORDERS.GENERATE_TASK_FROM_CHOSEN_ORDERS' | translate}}
        </button>
        <mat-paginator
          (page)="pageChange($event);"
          [pageSize]="requestParams.limit"
          [pageIndex]="(requestParams.page - 1)"
          [length]="totalCount"
          class="paginator-holder"
        ></mat-paginator>
      </div>
    </div>
  </div>
  <button mat-fab class="add-order-button"
          [@animate]="{value:'*', params:{delay:'300ms',scale:'.2'}}"
          [matTooltip]="'ORDERS.TITLE_ADD' | translate"
          matTooltipPosition="above"
          [attr.data-cy]="'addOrder'"
          (click)="addOrder()">
    <mat-icon>add</mat-icon>
  </button>
</div>

