var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { ElementRef, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatAutocomplete, } from "@angular/material";
import { TagsService } from "../../../services/tags.service";
var TagsAutocompleteComponent = /** @class */ (function () {
    function TagsAutocompleteComponent(tagsService) {
        this.tagsService = tagsService;
        this.formControl = new FormControl();
        this.visible = true;
        this.selectable = false;
        this.removable = true;
        this.addOnBlur = true;
        this.separatorKeysCodes = [ENTER, COMMA];
        this.selectedTags = [];
        this.allTags = [];
        this.filterParamsFullList = {
            page: 1,
            limit: 1000,
            sort: "name",
            order: "asc",
        };
    }
    TagsAutocompleteComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.tagsService.getTagsList(this.filterParamsFullList).subscribe(function (res) {
            _this.allTags = res.tags.map(function (tag) { return tag.name; });
            if (_this.tagsControl && _this.tagsControl.value) {
                _this.allTags = _this.allTags.filter(function (tag) { return !_this.tagsControl.value.includes(tag); });
            }
        });
        this.createSearchFilters();
        if (this.mode === "view") {
            this.formControl.disable();
            this.selectable = false;
            this.removable = false;
        }
        else {
            this.formControl.enable();
        }
    };
    TagsAutocompleteComponent.prototype.add = function (event) {
        this.selectedTags = this.tagsControl.value;
        // Add tag only when MatAutocomplete is not open
        // To make sure this does not conflict with OptionSelected Event
        if (!this.matAutocomplete.isOpen) {
            var input = event.input;
            var value = event.value;
            // Add our tag
            if ((value || "").trim()) {
                this.selectedTags.push(value.trim());
            }
            // Reset the input value
            if (input) {
                input.value = "";
            }
            this.formControl.setValue(null);
            this.tagsControl.setValue(this.selectedTags);
        }
    };
    TagsAutocompleteComponent.prototype.remove = function (tag) {
        this.selectedTags = this.tagsControl.value;
        var index = this.selectedTags.indexOf(tag);
        if (index >= 0) {
            this.selectedTags.splice(index, 1);
        }
        this.tagsControl.setValue(this.selectedTags);
    };
    TagsAutocompleteComponent.prototype.selected = function (event) {
        this.selectedTags = this.tagsControl.value || [];
        this.selectedTags.push(event.option.viewValue);
        this.tagInput.nativeElement.value = "";
        this.formControl.setValue(null);
        this.tagsControl.setValue(this.selectedTags);
    };
    TagsAutocompleteComponent.prototype.createSearchFilters = function () {
        var _this = this;
        this.formControl.valueChanges.debounceTime(300).subscribe(function (value) {
            var search = value && value.toLowerCase();
            _this.tagsService
                .getTagsList(__assign({}, _this.filterParamsFullList, { search: search || "" }))
                .subscribe(function (res) {
                _this.allTags = res.tags.map(function (tag) { return tag.name; });
                if (_this.tagsControl && _this.tagsControl.value) {
                    _this.allTags = _this.allTags.filter(function (tag) { return !_this.tagsControl.value.includes(tag); });
                }
            });
        });
    };
    return TagsAutocompleteComponent;
}());
export { TagsAutocompleteComponent };
