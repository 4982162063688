import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { CompanyAccountEditorComponent } from './components/company-account-editor/company-account-editor.component';

@NgModule({
  declarations: [
    CompanyAccountEditorComponent
  ],
  imports: [
    SharedModule,
  ],
  exports: [
    CompanyAccountEditorComponent
  ],
  entryComponents: [
    CompanyAccountEditorComponent
  ]
})
export class CompanyAccountsModule { }
