import { NgModule } from '@angular/core';
import { TagEditorComponent } from './components/tag-editor/tag-editor.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { TagsListComponent } from './components/tags-list/tags-list.component';
import { Routes } from './tags.routes';
import { TagsContainerComponent } from './tags-container.component';

@NgModule({
  declarations: [
    TagsContainerComponent,
    TagsListComponent,
    TagEditorComponent,
  ],
  imports: [
    RouterModule.forChild(Routes),
    SharedModule,
  ],
  exports: [
    TagsListComponent,
    TagEditorComponent,
  ],
  entryComponents: [
    TagEditorComponent
  ]
})
export class TagsModule { }
