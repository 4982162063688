<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">{{getTitle()}}</span>
      <button mat-icon-button (click)="recyclingItemEditorDialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>

    <form [formGroup]="recyclingItemForm">

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'RECYCLING.NAME' | translate}}</mat-label>
          <mat-icon matSuffix class="secondary-text">repeat</mat-icon>
          <input name="name" formControlName="name" matInput required [attr.data-cy]="'recyclingName'">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'RECYCLING.CODE' | translate}}</mat-label>
          <mat-icon matSuffix class="secondary-text">select_all</mat-icon>
          <input name="code" formControlName="code" matInput [attr.data-cy]="'recyclingCode'">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'RECYCLING.DESC' | translate}}</mat-label>
          <textarea name="description" formControlName="description" matInput [attr.data-cy]="'recyclingDescription'"></textarea>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'RECYCLING.WEIGHT_PER_PIECE' | translate}} [kg]</mat-label>
          <input name="weightPerPiece" formControlName="weightPerPiece" matInput required [attr.data-cy]="'recyclingWeightPerPiece'">
          <mat-error
            *ngIf="recyclingItemForm.get('weightPerPiece').touched && recyclingItemForm.controls['weightPerPiece'].errors?.pattern">
            {{'SHARED.MESSAGES.DECIMAL_ERROR_THREE' | translate}}
          </mat-error>
          <mat-error
            *ngIf="recyclingItemForm.get('weightPerPiece').touched && recyclingItemForm.controls['weightPerPiece'].errors?.smaller">
            {{'SHARED.MESSAGES.SMALLER_EQUAL_ZERO' | translate}}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'RECYCLING.WEIGHT_PER_CUBIC_METER' | translate}}<sup>3</sup> [kg]</mat-label>
          <input name="weightPerCubicMeter" formControlName="weightPerCubicMeter" matInput required [attr.data-cy]="'recyclingWeightPerCubicMeter'">
          <mat-error
            *ngIf="recyclingItemForm.get('weightPerCubicMeter').touched && recyclingItemForm.controls['weightPerCubicMeter'].errors?.pattern">
            {{'SHARED.MESSAGES.DECIMAL_ERROR_THREE' | translate}}
          </mat-error>
          <mat-error
            *ngIf="recyclingItemForm.get('weightPerCubicMeter').touched && recyclingItemForm.controls['weightPerCubicMeter'].errors?.smaller">
            {{'SHARED.MESSAGES.SMALLER_EQUAL_ZERO' | translate}}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'RECYCLING.VISIBLE_BY_CLIENT' | translate}}</mat-label>
          <mat-select formControlName="isPublic" [attr.data-cy]="'recyclingIsPublic'">
            <mat-option [value]="true" [attr.data-cy]="'VISIBLE'">{{'RECYCLING.VISIBLE_STATUSES.VISIBLE' | translate}}</mat-option>
            <mat-option [value]="false" [attr.data-cy]="'INVISIBLE'">{{'RECYCLING.VISIBLE_STATUSES.INVISIBLE' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'RECYCLING.STATUS' | translate}}</mat-label>
          <mat-select formControlName="active" [attr.data-cy]="'recyclingIsActive'">
            <mat-option [value]="true" [attr.data-cy]="'ACTIVE'">{{'RECYCLING.STATUSES.ACTIVE' | translate}}</mat-option>
            <mat-option [value]="false" [attr.data-cy]="'INACTIVE'">{{'RECYCLING.STATUSES.INACTIVE' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </form>

  </div>

  <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

    <button *ngIf="mode === 'edit'"
            mat-button
            class="mr-8"
            [attr.data-cy]="'recyclingDelete'"
            (click)="deleteRecyclingItem()">
      {{'RECYCLING.DELETE' | translate}}
    </button>

    <button *ngIf="mode === 'view'"
            mat-button
            class="mr-8"
            [attr.data-cy]="'recyclingEdit'"
            (click)="enableEditMode()">
      {{'RECYCLING.EDIT' | translate}}
    </button>

    <button *ngIf="mode === 'edit' || mode === 'add'"
            mat-button
            class="save-button"
            [attr.data-cy]="'recyclingSave'"
            (click)="submitRecyclingItem()"
            [disabled]="isLoading">
      <span *ngIf="!isLoading"> {{'RECYCLING.SAVE' | translate}}</span>
      <span *ngIf="isLoading" class="loading-spinner-wrapper">
        <mat-progress-spinner mode="indeterminate"
                              [strokeWidth]="2"
                              [diameter]="20">
        </mat-progress-spinner>
      </span>
    </button>

  </div>
</div>