import { AbstractControl } from '@angular/forms';

export class StartEndDateValidator {
  static CheckDates(abstractControl: AbstractControl) {
    let startDate = abstractControl.get('startDate').value;
    let endDate = abstractControl.get('endDate').value;
    if (new Date(startDate) > endDate || new Date(startDate) > new Date(endDate)) {
      abstractControl.get('endDate').setErrors({CheckDates: true})
    } else {
      return null
    }
  }
}