var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { MatDialog, MatDialogRef, MatSnackBar, } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { FuseTranslationLoaderService } from "../../../../../@fuse/services/translation-loader.service";
import { AuthService } from "../../../../services/auth.service";
import { OrdersService } from "../../../../services/orders.service";
import { TasksService } from "../../../../services/tasks.service";
import { ItemsOrigins, } from "../../../../shared/models/history.model";
import { List } from "../../../../shared/models/list.class";
import { locale as english } from "../../../../translations/en";
import { locale as polish } from "../../../../translations/pl";
import { compareObjectsChanges } from "./utils/compare-objects-function";
var HistoryDialogComponent = /** @class */ (function (_super) {
    __extends(HistoryDialogComponent, _super);
    function HistoryDialogComponent(historyDialogRef, data, matDialog, ordersService, tasksService, authService, translateService, snackBar, formBuilder, _fuseTranslationLoaderService) {
        var _this = _super.call(this) || this;
        _this.historyDialogRef = historyDialogRef;
        _this.data = data;
        _this.matDialog = matDialog;
        _this.ordersService = ordersService;
        _this.tasksService = tasksService;
        _this.authService = authService;
        _this.translateService = translateService;
        _this.snackBar = snackBar;
        _this.formBuilder = formBuilder;
        _this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        _this.requestParams = {
            page: 1,
            limit: 20,
            sort: "eventDate",
            order: "asc",
        };
        _this._fuseTranslationLoaderService.loadTranslations(polish, english);
        _this.item = data.item;
        _this.itemOrigin = data.itemOrigin;
        _this.itemId = data.itemId;
        _this.itemNumber = data.itemNumber;
        _this.noValueHelperString = _this.translateService.instant("HISTORY.HISTORY_KEYS.noValue");
        _this.getElementsList = function () {
            if (_this.itemOrigin === ItemsOrigins.Order) {
                _this.ordersService
                    .getOrderHistoryNew(_this.itemId, _this.requestParams)
                    .subscribe(function (res2) {
                    _this.totalCount = res2.totalCount;
                    _this.dataSourceNew = res2.orderHistory.map(function (item) {
                        return item;
                    });
                });
            }
            else if (_this.itemOrigin === ItemsOrigins.Task) {
                _this.tasksService
                    .getTaskHistory(_this.itemId, _this.requestParams)
                    .subscribe(function (res) {
                    _this.totalCount = res.totalCount;
                    _this.dataSource = res.taskHistory.map(function (item) {
                        return compareObjectsChanges(item, _this.noValueHelperString);
                    });
                });
            }
        };
        return _this;
    }
    HistoryDialogComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.getElementsList();
    };
    HistoryDialogComponent.prototype.toggleOrder = function (order) {
        this.requestParams.order = order;
        this.requestParams.page = 1;
        this.getElementsList();
    };
    return HistoryDialogComponent;
}(List));
export { HistoryDialogComponent };
