import { NgModule } from '@angular/core';
import { PackageTypesContainerComponent } from './package-types.container.component';
import { PackageTypesListComponent } from './components/package-types-list/package-types-list.component';
import { PackageTypesEditorComponent } from './components/package-types-editor/package-types-editor.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { Routes } from './package-types.routes';

@NgModule({
  declarations: [
    PackageTypesContainerComponent,
    PackageTypesListComponent,
    PackageTypesEditorComponent
  ],
  imports: [
    RouterModule.forChild(Routes),
    SharedModule,
  ],
  exports: [
    PackageTypesListComponent,
    PackageTypesEditorComponent,
  ],
  entryComponents: [
    PackageTypesEditorComponent,
  ]
})
export class PackageTypesModule {
}
