import { Component, OnDestroy, OnInit } from '@angular/core';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { locale as polish } from '../../../../translations/pl';
import { locale as english } from '../../../../translations/en';
import {MatDialog, MatSnackBar} from '@angular/material';
import { OrdersService } from '../../../../services/orders.service';
import { Order, OrderSatuses, OrdersListRespone } from '../../../../shared/models/orders.model';
import { List } from '../../../../shared/models/list.class';
import { ListFilters } from '../../../../shared/models/list.model';
import { fuseAnimations } from '../../../../../@fuse/animations';
import { remondisListAnimation } from '../../../../shared/consts/remondis.lists.animation';
import {RoleTypes, User, UsersListResponse, UserTypes} from '../../../../shared/models/users.model';
import { FormControl } from '@angular/forms';
import { AuthService } from '../../../../services/auth.service';
import { OrderTypePickerComponent } from '../order-type-picker/order-type-picker.component';
import { OrderTypes } from '../../../../shared/consts/orders';
import { OrderExportFormComponent } from '../order-forms/order-export-form/order-export-form.component';
import { UsersService } from '../../../../services/users.service';
import { registerLocaleData } from '@angular/common';
import localePl from '@angular/common/locales/pl';
import { OrderImportFormComponent } from '../order-forms/order-import-form/order-import-form.component';
import { OrderReceivingFormComponent } from '../order-forms/order-receiving-form/order-receiving-form.component';
import { TranslateService } from '@ngx-translate/core';
import { SelectionModel } from '@angular/cdk/collections';
import {TaskReceivingFormComponent} from '../../../tasks/components/task-forms/task-receiving-form/task-receiving-form.component';
import {TaskImportFormComponent} from '../../../tasks/components/task-forms/task-import-form/task-import-form.component';
import {TaskExportFormComponent} from '../../../tasks/components/task-forms/task-export-form/task-export-form.component';
import * as _ from 'lodash';

@Component({
  selector: 'app-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.scss'],
  animations: [...fuseAnimations, ...remondisListAnimation]
})
export class OrdersListComponent extends List implements OnInit, OnDestroy {
  public displayedColumns = ['select', 'tags', 'salesAcceptedDate', 'customerCompanyName', 'city', 'orderType', 'totalWeight', 'comment', 'suggestedDate', 'status', 'createdBySurname', 'createdDate'];
  public user: User;
  public userTypes = UserTypes;
  public roleTypes = RoleTypes;
  public companyBranches = [];
  public salesList: any[];
  public searchOrder: FormControl;
  public orderStatuses = OrderSatuses;
  public tableStatus = ['status'];
  public tableFilters = ['orderType', 'salesAccepted'];
  public textFilters = ['customerCompanyName', 'address', 'comment'];
  public weightFilters = ['totalWeight'];
  public tagsFilters = ['tags'];
  public initialUserFilters = {orderType: null, salesAccepted: '1', companyBranchUuid: [], status: ['new', 'read']};
  public orderTypes = OrderTypes;
  public canSendOrderLink = false;
  public selection = new SelectionModel<Order>(true, []);
  private listFilters: ListFilters = {};
  private filterByTaskDate: boolean = false;
  private taskForms = {'receiving': TaskReceivingFormComponent, 'import': TaskImportFormComponent, 'export': TaskExportFormComponent};
  private reloadList$;
  public customFilters: any[];

  constructor(private ordersService: OrdersService,
              private matDialog: MatDialog,
              private authService: AuthService,
              private translateService: TranslateService,
              private usersService: UsersService,
              private snackBar: MatSnackBar,
              private _fuseTranslationLoaderService: FuseTranslationLoaderService) {
    super();
    registerLocaleData(localePl);
    this.user = this.authService.user.user;

    if (this.user.userType === this.userTypes.Manager) {
      this.tableFilters = [];
      this.displayedColumns = ['tags', 'salesAcceptedDate', 'createdDate', 'customerCompanyName', 'city', 'orderType', 'totalWeight', 'comment', 'suggestedDate', 'status', 'createdBySurname'];
    }

    if (this.user.userType !== this.userTypes.Logistician) {
      this.tagsFilters = [];
      this.displayedColumns = ['createdDate', 'salesAcceptedDate', 'customerCompanyName', 'city', 'orderType', 'totalWeight', 'comment', 'suggestedDate', 'status', 'createdBySurname'];
    }

    this.requestParams.sort = 'salesAcceptedDate';
    this.requestParams.order = 'desc';

    this._fuseTranslationLoaderService.loadTranslations(polish, english);

    this.reloadList$ = ordersService.onEditEvent.subscribe(
      () => {
        this.getElementsList();
      });

    this.getElementsList = () => {
      let filters = this.getQueryParams();
      if (this.user.userType === this.userTypes.Manager && this.user.roleType !== this.roleTypes.Admin) {
        filters = {...this.getQueryParams(), companyBranchUuid: this.user.companyBranchId};
      }
      this.ordersService[this.filterByTaskDate ? 'getOrdersListFilteredByTaskDate' : 'getOrdersList'](filters)
        .subscribe((res: OrdersListRespone) => {
          this.totalCount = res.totalCount;
          this.dataSource = res.orders;
        });
    };
  }

  ngOnInit() {
    super.ngOnInit();
    this.createSearchFormControl();

    if (this.user.userType !== this.userTypes.Manager ||
      (this.user.userType === this.userTypes.Manager && this.user.roleType === this.roleTypes.Admin)) {
      this.canSendOrderLink = true;
      this.usersService.getCompanyBranchList().subscribe(
        res => {
          res.branches.forEach(branch => {
            this.companyBranches.push({name: branch.name, value: branch.uuid});
          });
          this.companyBranches.push({name: this.translateService.instant('ORDERS.UNALLOCATED'), value: 'unallocated'});

          if (this.user.userType === this.userTypes.Sales) {
            this.companyBranches.forEach(branch => {
              this.initialUserFilters.companyBranchUuid.push(branch.value);
            });
          }
        }
      );
    }

    switch (this.user.userType) {
      case this.userTypes.Logistician:
        this.initialUserFilters.companyBranchUuid = [this.user.companyBranchId, 'unallocated'];
        this.initialUserFilters.orderType = 'all_options';
        break;
      case this.userTypes.Sales:
        const filterParams = {
          page: 1,
          limit: 1000,
          userType: UserTypes.Sales
        };

        this.usersService.getUsersList(filterParams).subscribe((response: UsersListResponse) => {
          this.salesList = response.users.map(user => ({
            name: `${user.name} ${user.surname}`,
            value: user.uuid
          }));
        });
        this.initialUserFilters.orderType = 'all_options';
        break;
      case this.userTypes.Manager:
        this.initialUserFilters.companyBranchUuid = [this.user.companyBranchId];
        break;
    }
  }

  ngOnDestroy() {
    this.reloadList$.unsubscribe();
  }

  public addOrder() {
    this.disableAnimations = true;

    this.matDialog.open(OrderTypePickerComponent, {
      panelClass: 'order-type-picker',
      autoFocus: false,
      disableClose: true,
      data: {
        mode: 'add'
      }
    });
  }

  public viewOrder(orderUuid: Order['uuid'], orderType: Order['orderType']) {
    let editorDialogRef;
    this.disableAnimations = true;

    this.dataSource.map(order => {
      if (order.uuid === orderUuid && order.status === this.orderStatuses.New
        && this.user.userType === this.userTypes.Logistician) {
        order.status = this.orderStatuses.Read;
      }
    });

    if (orderType === OrderTypes.Export) {
      editorDialogRef = OrderExportFormComponent;
    } else if (orderType === OrderTypes.Import) {
      editorDialogRef = OrderImportFormComponent;
    } else {
      editorDialogRef = OrderReceivingFormComponent;
    }

    this.matDialog.open(editorDialogRef, {
      panelClass: 'order-editor-dialog',
      autoFocus: false,
      disableClose: true,
      data: {
        mode: 'view',
        orderUuid: orderUuid
      }
    });
  }

  public masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() : (!this.filterByTaskDate ? this.dataSource.forEach(row => this.selection.select(row)) :
      this.dataSource.forEach(row => row.status === this.orderStatuses.Planned && this.selection.select(row)));
  }

  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }

  public closeOrders(event: Event) {
    event.stopPropagation();

    this.ordersService.closeOrders(this.selection.selected.map(item => item.uuid)).subscribe(() => {
      this.getElementsList();
      this.clearSelect();
    });
  }

  public generateTask(event: Event) {
    event.stopPropagation();
    const selected = _.uniqBy(this.selection.selected, 'uuid');
    if (selected.some(item => item.companyBranch !== null)) {
      if (selected.some(item => item.orderType !== selected[0].orderType)) {
        this.snackBar.open(
          this.translateService.instant('ORDERS.MESSAGES.GENERATE_ERROR_WRONG_ORDER_TYPE'),
          '',
          {duration: 5000}
        );
      } else if (selected.some(item => item.companyBranch.uuid !== selected[0].companyBranch.uuid)) {
        this.snackBar.open(
          this.translateService.instant('ORDERS.MESSAGES.GENERATE_ERROR_WRONG_COMPANY_BRANCH'),
          '',
          {duration: 5000}
        );
      } else {
        this.matDialog.open(this.taskForms[selected[0].orderType], {
          panelClass: 'task-editor-dialog',
          autoFocus: false,
          disableClose: true,
          data: {
            mode: 'add',
            orders: selected
          }
        });
        this.clearSelect();
      }
    }
    else {
      this.snackBar.open(
        this.translateService.instant('ORDERS.MESSAGES.GENERATE_ERROR'),
        '',
        { duration: 5000 }
      );
    }
  }

  public clearSelect() {
    this.selection = new SelectionModel<Order>(true, []);
  }

  public clearSearch() {
    if (this.searchOrder.value) {
      this.searchOrder.setValue('');
    }
  }

  public getListFilters(filters: ListFilters) {
    if (filters['taskDate']) {
      filters['dateFrom'] = filters['taskDate'];
      filters['dateTo'] = filters['taskDate'];
      delete filters['taskDate'];
      this.companyBranches = this.companyBranches.filter(item => item.value !== 'unallocated');
      this.filterByTaskDate = true;
      this.selection.clear();
    } else {
      this.filterByTaskDate = false;
      if (this.companyBranches.length > 0 && !this.companyBranches.find(item => item.value === 'unallocated')) {
        this.companyBranches.push({name: this.translateService.instant('ORDERS.UNALLOCATED'), value: 'unallocated'});
      }
    }
    this.listFilters = filters;
    this.requestParams.page = 1;
    this.disableAnimations = true;
    this.getElementsList();
  }

  private createSearchFormControl() {
    this.searchOrder = new FormControl('');
    this.searchOrder.valueChanges.debounceTime(300).subscribe((value) => {
      this.requestParams.search = value;
      this.requestParams.page = 1;
      this.disableAnimations = true;
      this.getElementsList();
    });
  }

  private getQueryParams() {
    return Object.assign({}, this.requestParams, this.listFilters);
  }
}
