<div id="login" fxLayout="column">

  <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

    <div id="login-form">

      <div class="logo">
        <img src="assets/images/logo.svg">
      </div>

      <div class="title">{{'SHARED.LOGIN_TITLE' | translate}}</div>

      <form name="loginForm" [formGroup]="loginForm" novalidate (ngSubmit)="login()">

        <mat-form-field>
          <input matInput
                 type="email"
                 [attr.data-cy]="'email'"
                 placeholder="{{'SHARED.EMAIL' | translate}}"
                 formControlName="email"
                 required>
          <mat-error *ngIf="loginForm.get('email').hasError('required')">
            {{'SHARED.NO_EMAIL_ERROR' | translate}}
          </mat-error>
          <mat-error
            *ngIf="!loginForm.get('email').hasError('required') && loginForm.get('email').hasError('email')">
            {{'SHARED.EMAIL_ERROR' | translate}}
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <input matInput
                 type="password"
                 [attr.data-cy]="'password'"
                 placeholder="{{'SHARED.PASS' | translate}}"
                 formControlName="password"
                 required>
          <mat-error *ngIf="loginForm.get('password').hasError('required')">
            {{'SHARED.NO_PASS_ERROR' | translate}}
          </mat-error>
        </mat-form-field>   
        
        <mat-form-field *ngIf="twoFa">
          <input matInput
                 type="password"
                 [attr.data-cy]="'2fa'"
                 placeholder="{{'SHARED.2FA' | translate}}"
                 formControlName="twoFa">
          <mat-error *ngIf="loginForm.get('twoFa').hasError('wrong')">
            {{'SHARED.2FA_ERROR' | translate}}
          </mat-error>
        </mat-form-field>

        <div class="forgot-password-wrapper" fxLayout="row" fxLayout.xs="column"
             fxLayoutAlign="end center">
          <a class="forgot-password" routerLink="/forgot-password" [attr.data-cy]="'forgot-password'">
            {{'SHARED.FORGOT_PASS' | translate}}
          </a>
        </div>

        <button mat-raised-button color="primary" class="submit-button" aria-label="LOG IN"
                [disabled]="loginForm.invalid || isLoading" type="submit" [attr.data-cy]="'submit-button'">
          <span *ngIf="!isLoading">{{'SHARED.LOGIN' | translate}}</span>
          <span *ngIf="isLoading" class="loading-spinner-wrapper">
              <mat-progress-spinner
                mode="indeterminate"
                [strokeWidth]="2"
                [diameter]="20">
              </mat-progress-spinner>
            </span>
        </button>

      </form>

    </div>

  </div>

</div>