import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatAutocompleteSelectedEvent,
  MatDialog,
  MatDialogRef,
  MatSnackBar,
  MatStepper,
} from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { CompaniesService } from "app/services/companies.service";
import * as moment from "moment";
import { Observable, of, zip } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { FuseTranslationLoaderService } from "../../../../../../@fuse/services/translation-loader.service";
import { AuthService } from "../../../../../services/auth.service";
import { CompanyBranchesService } from "../../../../../services/company-branches.service";
import { CompanyCarsService } from "../../../../../services/company-cars.service";
import { FilteredListsService } from "../../../../../services/filtered-lists.service";
import { OrdersService } from "../../../../../services/orders.service";
import { TasksService } from "../../../../../services/tasks.service";
import { UsersService } from "../../../../../services/users.service";
import { Mode } from "../../../../../shared/consts/mode";
import { OrderTypes } from "../../../../../shared/consts/orders";
import {
  EndTimeOptions,
  EndTimeOptionsReceiving,
  StartTimeOptions,
  StartTimeOptionsReceiving,
} from "../../../../../shared/consts/tasks";
import { CustomerCompanyBranch } from "../../../../../shared/models/company-branches.model";
import { CustomerCompanyCar } from "../../../../../shared/models/company-cars.model";
import { CompanyBranch } from "../../../../../shared/models/company.model";
import {
  FilteredCompany,
  FilteredTransport,
} from "../../../../../shared/models/filtered-items.model";
import { ItemsOrigins } from "../../../../../shared/models/history.model";
import { RequestParams } from "../../../../../shared/models/list.model";
import { OrderReceiving } from "../../../../../shared/models/orders.model";
import {
  OrderReceivingSubform,
  TaskReceiving,
  TaskStatuses,
  TaskTypes,
} from "../../../../../shared/models/tasks.model";
import { User, UserTypes } from "../../../../../shared/models/users.model";
import { getDaysInMonth } from "../../../../../shared/utils/custom-date-formatter";
import { markFormGroupTouched } from "../../../../../shared/utils/markFormGroupAsTouched";
import {
  mapDatetimeToUtc,
  mapOrderDataApiValues,
  mapOrderDataToUuid,
} from "../../../../../shared/utils/tasks-map";
import { ValidateItemsList } from "../../../../../shared/validators/itemsList.validator";
import { StartEndDateValidator } from "../../../../../shared/validators/startEndDate.validator";
import { locale as english } from "../../../../../translations/en";
import { locale as polish } from "../../../../../translations/pl";
import { CompanyEditorComponent } from "../../../../companies/components/company-editor/company-editor.component";
import { ConfirmModalComponent } from "../../../../shared/components/confirm-modal/confirm-modal.component";
import { HistoryDialogComponent } from "../../../../shared/components/history-dialog/history-dialog.component";

@Component({
  selector: "app-task-receiving-form",
  templateUrl: "./task-receiving-form.component.html",
  styleUrls: ["./task-receiving-form.component.scss"],
})
export class TaskReceivingFormComponent implements OnInit {
  @ViewChild("companySelect") companySelect;
  @ViewChild("stepper") stepper: MatStepper;
  isExpanded: boolean[] = [];
  public indexStep: number;

  public taskReceivingForm: FormGroup;
  public taskId: TaskReceiving["uuid"] | TaskReceiving["taskNumber"];
  public task: TaskReceiving;
  public customerCompanyBranches: { [key: string]: CustomerCompanyBranch[] } =
    {};
  public taskStatuses = TaskStatuses;
  public taskTypes = TaskTypes;
  public user: User;
  public userTypes = UserTypes;
  public transportOptions: FilteredTransport[] = [];
  public customerCompanyOptions: FilteredCompany[] = [];
  public companyBranches: CompanyBranch[] = [];
  public ordersOptions: OrderReceiving[] = [];
  public ordersOptionsInitial: string[] = [];
  public ordersOptionsRemoved: OrderReceiving[] = [];
  public forwarderCompanies: FilteredCompany[] = [];
  public companyCars: CustomerCompanyCar[] = [];
  public startTimeOptions = StartTimeOptions;
  public endTimeOptions = EndTimeOptions;
  public mode: Mode;
  public isLoading = false;
  public searchCustomerCompany: FormControl;
  public searchTransport: FormControl;
  public searchOrders: FormControl;
  public searchForwarder: FormControl;
  public companyCarSelect: FormControl;
  public forwarderIdSelect: FormControl;
  public startTimeOptionsReceiving = StartTimeOptionsReceiving;
  public endTimeOptionsReceiving = EndTimeOptionsReceiving;
  public todayDate: Date;
  public rangeDateEndMin: Date;
  public rangeDateEndMax: Date;
  public pdfBranch: any;
  public pdfForwarder: any;
  private filterParams: RequestParams = {
    page: 1,
    limit: 20,
    sort: "name",
    order: "asc",
  };
  private filterParamsFullList: RequestParams = {
    page: 1,
    limit: 1000,
    sort: "name",
    order: "asc",
    active: "true",
  };
  private filterOrdersParams: RequestParams = {
    page: 1,
    limit: 20,
    sort: "createdDate",
    order: "asc",
    available: true,
    orderType: this.taskTypes.Receiving,
    task: false,
    salesAccepted: true,
  };
  private filterForwarderParams: RequestParams = {
    page: 1,
    limit: 20,
    sort: "createdDate",
    order: "asc",
    companyType: "forwarder",
  };

  constructor(
    public taskEditorDialogRef: MatDialogRef<TaskReceivingFormComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private matDialog: MatDialog,
    private authService: AuthService,
    private tasksService: TasksService,
    private ordersService: OrdersService,
    private filteredListsService: FilteredListsService,
    private companyBranchesService: CompanyBranchesService,
    private companiesService: CompaniesService,
    private companyCarsService: CompanyCarsService,
    private usersService: UsersService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService
  ) {
    this.user = this.authService.user.user;
    this.mode = data.mode;
    this.taskId = data.taskId || data.taskNumber;
    this._fuseTranslationLoaderService.loadTranslations(polish, english);
    this.indexStep = data.indexStep || 0;
  }

  ngOnInit() {
    this.createSearchFilters();
    this.createForm();

    if (this.mode === "edit" || this.mode === "view" || this.mode === "clone") {
      this.tasksService
        .getTask(this.taskId)
        .pipe(
          map((res) => {
            this.task = res;
            this.taskId = res.uuid;
            res.taskData.forEach((item) => {
              if (item.order && item.order.uuid) {
                this.ordersOptionsInitial.push(item.order.uuid);
              }
            });
            this.createForm(this.task);
            this.getPdfNeededData();
          }),
          switchMap(() => {
            return zip(
              this.filteredListsService.getTransportList(
                this.filterParamsFullList
              ),
              this.filteredListsService.getCompaniesList(this.filterParams),
              this.usersService.getCompanyBranchList(),
              this.filteredListsService.getOrdersList(this.filterOrdersParams),
              this.filteredListsService.getCompaniesList(
                this.filterForwarderParams
              )
            );
          })
        )
        .subscribe(
          ([
            transportRes,
            companiesRes,
            branchesRes,
            ordersRes,
            forwarderRes,
          ]) => {
            this.transportOptions = transportRes.transports;
            this.customerCompanyOptions = companiesRes.customerCompanies;
            this.companyBranches = branchesRes.branches;
            this.ordersOptions = <OrderReceiving[]>ordersRes.orders;
            this.forwarderCompanies = forwarderRes.customerCompanies;
          }
        );
    } else {
      this.todayDate = new Date();

      zip(
        this.filteredListsService.getTransportList(this.filterParamsFullList),
        this.filteredListsService.getCompaniesList(this.filterParams),
        this.filteredListsService.getOrdersList(this.filterOrdersParams),
        this.usersService.getCompanyBranchList(),
        this.filteredListsService.getCompaniesList(this.filterForwarderParams)
      ).subscribe(
        ([
          transportRes,
          companiessRes,
          ordersRes,
          branchesRes,
          forwarderRes,
        ]) => {
          this.transportOptions = transportRes.transports;
          this.customerCompanyOptions = companiessRes.customerCompanies;
          this.ordersOptions = <OrderReceiving[]>ordersRes.orders;
          this.companyBranches = branchesRes.branches;
          this.forwarderCompanies = forwarderRes.customerCompanies;
          if (this.data.orders && this.data.orders.length) {
            this.createForm(this.data.orders[0]);
            this.data.orders.forEach((item, index) => {
              this.addOrderSubform(index, item, true);
            });
          }
        }
      );
    }
  }

  private setRangeEndDateMax(date: Date) {
    this.rangeDateEndMin = new Date(
      this.taskReceivingForm.get("startDate").value || this.todayDate
    );
    this.rangeDateEndMax = new Date(date || this.todayDate);
    this.rangeDateEndMax.setMonth(
      this.rangeDateEndMax.getMonth() !== 11
        ? this.rangeDateEndMax.getMonth() + 1
        : 0
    );
    if (this.rangeDateEndMax.getMonth() === 0) {
      this.rangeDateEndMax.setFullYear(this.rangeDateEndMax.getFullYear() + 1);
    }
    this.rangeDateEndMax.setDate(
      getDaysInMonth(
        this.rangeDateEndMax.getFullYear(),
        this.rangeDateEndMax.getMonth()
      )
    );
  }

  public submitTask() {
    this.isLoading = true;
    markFormGroupTouched(this.taskReceivingForm);
    if (this.taskReceivingForm.invalid) {
      this.isLoading = false;
      return;
    }
    const taskForm = this.taskReceivingForm.getRawValue();
    let taskData = taskForm.taskData.map((item) => {
      item = Object.assign({}, item);
      const id = item.customerCompanyBranch;
      item.customerCompanyBranch = Object.assign(
        {},
        item.customerCompanyBranchOther,
        { uuid: id === "other" ? null : id }
      );
      item.customerCompanyBranch.email =
        item.customerCompanyBranch.email || null;
      item.suggestedDate = item.suggestedDate || null;
      item.order.comment = item.customerCompanyBranchOther.order_comment;
      delete item.customerCompanyBranchOther;

      return item;
    });

    this.createNewOrders(taskData, taskForm.companyBranchId).subscribe(
      (taskDataFromOther) => {
        taskData = taskData.filter((item) => item.uuid !== "other");
        taskDataFromOther.forEach((item) => {
          taskData.push(item);
        });

        const newTask = {
          name: taskForm.name,
          companyBranchId: taskForm.companyBranchId,
          transportId: taskForm.transport ? taskForm.transport.uuid : null,
          startDate: mapDatetimeToUtc(taskForm.startDate, taskForm.startTime),
          endDate: mapDatetimeToUtc(taskForm.endDate, taskForm.endTime),
          taskType: taskForm.taskType,
          comment: taskForm.comment,
          referenceNumber: taskForm.referenceNumber,
          emailService: taskForm.emailService,
          registrationNumFront: taskForm.forwarderRegistrationNumberFront,
          registrationNumBack: taskForm.forwarderRegistrationNumberBack,
          taskData: this.mapTaskData(taskData),
          forwarderId: !!taskForm.forwarderCompanyName
            ? taskForm.forwarderId
            : null,
          forwarderCompanyName: taskForm.forwarderCompanyName,
          forwarderDriverName: taskForm.forwarderDriverName,
          forwarderDriverPhone: taskForm.forwarderDriverPhone,
          forwarderRegistrationNumberFront:
            taskForm.forwarderRegistrationNumberFront,
          forwarderRegistrationNumberBack:
            taskForm.forwarderRegistrationNumberBack,
          forwarderKilometers: Number(
            taskForm.forwarderKilometers.replace(",", ".")
          ),
          forwarderRouteDescription: taskForm.forwarderRouteDescription,
          forwarderBdo: taskForm.forwarderBdo,
          forwarderCarName: taskForm.forwarderCarName,
          forwarderCarType: taskForm.forwarderCarType,
          forwarderExpense: Number(taskForm.forwarderExpense.replace(",", ".")),
          forwarderInvoiceNumber: taskForm.forwarderInvoiceNumber,
        };

        if (this.mode === "add" || this.mode === "clone") {
          this.createTask(newTask);
        } else {
          const ordersToDelete = [];
          const oldOrdersIds =
            this.task && this.task.taskData
              ? this.task.taskData.map((item) => {
                  if (item.order && item.order.uuid) {
                    return item.order.uuid;
                  }
                })
              : [];
          const newOrdersIds = newTask.taskData
            ? newTask.taskData.map((item) => {
                if (item.orderId) {
                  return item.orderId;
                }
              })
            : [];

          oldOrdersIds.forEach((oldItem) => {
            if (oldItem && !newOrdersIds.includes(oldItem)) {
              ordersToDelete.push(oldItem);
            }
          });

          zip(
            this.tasksService.updateTask(this.taskId, newTask),
            this.tasksService.updateTaskOrders(this.taskId, ordersToDelete)
          ).subscribe(
            () => {
              this.taskEditorDialogRef.close();
              this.taskEditorDialogRef.afterClosed().subscribe(() => {
                this.tasksService.onEditEvent.emit();
                this.ordersService.onEditEvent.emit();
              });
              this.snackBar.open(
                this.translateService.instant("TASKS.MESSAGES.UPDATE_SUCCESS"),
                "",
                { duration: 5000 }
              );
            },
            () => {
              this.isLoading = false;
              this.snackBar.open(
                this.translateService.instant("TASKS.MESSAGES.UPDATE_ERROR"),
                "",
                { duration: 5000 }
              );
            }
          );
        }
      }
    );
  }

  private createTask(newTask: any) {
    const taskForm = this.taskReceivingForm.getRawValue();
    if (taskForm.recurring) {
      const recurringTask = {
        intervalType: "WEEK",
        intervalValue: taskForm.recurringInfo.intervalValue,
        rangeDateEnd: moment(taskForm.recurringInfo.rangeDateEnd).format(
          "YYYY-MM-DD"
        ),
        weekdays: taskForm.recurringInfo.weekdays,
        task: newTask,
      };
      this.tasksService.createRecurringTask(recurringTask).subscribe(
        () => {
          this.taskEditorDialogRef.close();
          this.taskEditorDialogRef.afterClosed().subscribe(() => {
            this.tasksService.onEditEvent.emit();
            this.ordersService.onEditEvent.emit();
          });
          this.snackBar.open(
            this.translateService.instant("TASKS.MESSAGES.CREATE_SUCCESS"),
            "",
            { duration: 5000 }
          );
        },
        () => {
          this.isLoading = false;
          this.snackBar.open(
            this.translateService.instant("TASKS.MESSAGES.CREATE_ERROR"),
            "",
            { duration: 5000 }
          );
        }
      );
    } else {
      this.tasksService.createTask(newTask).subscribe(
        () => {
          this.taskEditorDialogRef.close();
          this.taskEditorDialogRef.afterClosed().subscribe(() => {
            this.tasksService.onEditEvent.emit();
            this.ordersService.onEditEvent.emit();
          });
          this.snackBar.open(
            this.translateService.instant("TASKS.MESSAGES.CREATE_SUCCESS"),
            "",
            { duration: 5000 }
          );
        },
        () => {
          this.isLoading = false;
          this.snackBar.open(
            this.translateService.instant("TASKS.MESSAGES.CREATE_ERROR"),
            "",
            { duration: 5000 }
          );
        }
      );
    }
  }

  public deleteTask() {
    const deleteDialogRef = this.matDialog.open(ConfirmModalComponent, {
      disableClose: false,
      autoFocus: false,
    });
    deleteDialogRef.componentInstance.confirmMessage =
      this.translateService.instant("TASKS.MESSAGES.DELETE_QUESTION");
    deleteDialogRef.componentInstance.titleMessage =
      this.translateService.instant("TASKS.MESSAGES.DELETE_TASK_TITLE");
    deleteDialogRef.componentInstance.confirmButton =
      this.translateService.instant("TASKS.DELETE");

    deleteDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const ordersToDelete = [];
        this.task &&
          this.task.taskData &&
          this.task.taskData.forEach((item) => {
            if (item.order && item.order.uuid) {
              ordersToDelete.push(item.order.uuid);
            }
          });

        zip(
          this.tasksService.deleteTask(this.taskId),
          this.tasksService.updateTaskOrders(this.taskId, ordersToDelete)
        ).subscribe(
          () => {
            this.taskEditorDialogRef.close();
            this.taskEditorDialogRef.afterClosed().subscribe(() => {
              this.tasksService.onEditEvent.emit();
              this.ordersService.onEditEvent.emit();
            });
            this.snackBar.open(
              this.translateService.instant("TASKS.MESSAGES.DELETE_SUCCESS"),
              "",
              { duration: 5000 }
            );
          },
          () => {
            this.snackBar.open(
              this.translateService.instant("TASKS.MESSAGES.DELETE_ERROR"),
              "",
              { duration: 5000 }
            );
          }
        );
      }
    });
  }

  public completeTask() {
    const completeDialogRef = this.matDialog.open(ConfirmModalComponent, {
      disableClose: false,
      autoFocus: false,
    });
    completeDialogRef.componentInstance.confirmMessage =
      this.translateService.instant("TASKS.MESSAGES.COMPLETE_QUESTION");
    completeDialogRef.componentInstance.titleMessage =
      this.translateService.instant("TASKS.MESSAGES.COMPLETE_TASK_TITLE");
    completeDialogRef.componentInstance.confirmButton =
      this.translateService.instant("TASKS.COMPLETE_TASK");
    completeDialogRef.componentInstance.skipButton =
      this.translateService.instant("TASKS.COMPLETE_SKIP");

    completeDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.tasksService.completeTask(this.taskId).subscribe(
          () => {
            this.taskEditorDialogRef.close();
            this.tasksService.onEditEvent.emit();
            this.ordersService.onEditEvent.emit();
            this.snackBar.open(
              this.translateService.instant("TASKS.MESSAGES.COMPLETE_SUCCESS"),
              "",
              { duration: 5000 }
            );
          },
          () => {
            this.snackBar.open(
              this.translateService.instant("TASKS.MESSAGES.COMPLETE_ERROR"),
              "",
              { duration: 5000 }
            );
          }
        );
      }
    });
  }

  public cancelTask() {
    const cancelDialogRef = this.matDialog.open(ConfirmModalComponent, {
      disableClose: false,
      autoFocus: false,
    });
    cancelDialogRef.componentInstance.confirmMessage =
      this.translateService.instant("TASKS.MESSAGES.CANCEL_QUESTION");
    cancelDialogRef.componentInstance.titleMessage =
      this.translateService.instant("TASKS.MESSAGES.CANCEL_TASK_TITLE");
    cancelDialogRef.componentInstance.confirmButton =
      this.translateService.instant("TASKS.CANCEL_TASK");
    cancelDialogRef.componentInstance.skipButton =
      this.translateService.instant("TASKS.CANCEL_SKIP");

    cancelDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.tasksService.cancelTask(this.taskId).subscribe(
          () => {
            this.taskEditorDialogRef.close();
            this.tasksService.onEditEvent.emit();
            this.ordersService.onEditEvent.emit();
            this.snackBar.open(
              this.translateService.instant("TASKS.MESSAGES.CANCEL_SUCCESS"),
              "",
              { duration: 5000 }
            );
          },
          () => {
            this.snackBar.open(
              this.translateService.instant("TASKS.MESSAGES.CANCEL_ERROR"),
              "",
              { duration: 5000 }
            );
          }
        );
      }
    });
  }

  public setStartDate(newDate) {
    this.taskReceivingForm.patchValue({ endDate: new Date(newDate) });
    this.setRangeEndDateMax(newDate);
    this.handleRecurringChange();
  }

  public handleRecurringChange() {
    const weekdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const date = this.taskReceivingForm.get("startDate").value || new Date();

    this.taskReceivingForm.get("recurringInfo").get("weekdays").patchValue({
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
      Sunday: false,
    });
    this.taskReceivingForm
      .get("recurringInfo")
      .get("weekdays")
      .patchValue({
        [weekdays[moment(date).day()]]: true,
      });
  }

  public addOrderSubform(
    index: number,
    orderItem: OrderReceivingSubform = new OrderReceivingSubform(),
    generatedFromOrderId: boolean = false
  ) {
    if (orderItem.customerCompany && orderItem.customerCompany.uuid) {
      this.getCustomerCompanyBranches(orderItem.customerCompany.uuid);
    }
    Object.keys(this.isExpanded).forEach(
      (item) => (this.isExpanded[item] = false)
    );
    this.isExpanded.push(!orderItem.orderData);
    orderItem.orderData = mapOrderDataToUuid(orderItem.orderData);
    this.orderFilter();
    const control = <FormArray>this.taskReceivingForm.controls.taskData;
    control.push(
      this.formBuilder.group({
        uuid: new FormControl(
          this.mode === "clone"
            ? "other"
            : (generatedFromOrderId && orderItem.uuid) ||
              (orderItem.order && orderItem.order.uuid) ||
              orderItem.orderId ||
              "other"
        ),
        order: new FormControl(
          this.mode === "clone"
            ? { uuid: "other" }
            : (generatedFromOrderId && orderItem) ||
              orderItem.order || { uuid: "other" }
        ),
        customerCompany: new FormControl(
          orderItem.customerCompany || null,
          Validators.required
        ),
        customerCompanyBranch: new FormControl(
          orderItem.customerCompanyBranch
            ? orderItem.customerCompanyBranch.uuid
              ? orderItem.customerCompanyBranch.uuid
              : "other"
            : null,
          Validators.required
        ),
        orderData: new FormControl(orderItem.orderData || [], [
          Validators.required,
          ValidateItemsList,
        ]),
        referenceNumber: new FormControl(
          this.mode === "clone" ? null : orderItem.referenceNumber || null
        ),
        salesAccepted: new FormControl(
          (orderItem.order && orderItem.order.salesAccepted) ||
            orderItem.salesAccepted ||
            false,
          Validators.required
        ),
        suggestedDate: new FormControl(
          this.mode === "clone"
            ? null
            : (orderItem.order && orderItem.order.suggestedDate) ||
              orderItem.suggestedDate ||
              null,
          Validators.required
        ),
        availableHoursFrom: new FormControl(
          (orderItem.order && orderItem.order.availableHoursFrom) ||
            orderItem.availableHoursFrom ||
            null
        ),
        availableHoursTo: new FormControl(
          (orderItem.order && orderItem.order.availableHoursTo) ||
            orderItem.availableHoursTo ||
            null
        ),
        companyBranchId: new FormControl(
          (this.task &&
            this.task.companyBranch &&
            this.task.companyBranch.uuid) ||
            (orderItem.companyBranch && orderItem.companyBranch.uuid) ||
            null,
          Validators.required
        ),
        bdo: new FormControl(orderItem.bdo || null, Validators.required),
        bdoFree: new FormControl(orderItem.bdoFree),
        bdoPeopleCollection: new FormControl(orderItem.bdoPeopleCollection),
        kpoEmails: new FormControl(
          orderItem.kpoEmails ||
            (orderItem.order && orderItem.order.kpoEmails) ||
            null
        ),
        reinvoice: new FormControl(orderItem.reinvoice || false),
        price: new FormControl(
          orderItem.price ? String(orderItem.price).replace(".", ",") : ""
        ),
        reinvoiceNumber: new FormControl(orderItem.reinvoiceNumber || ""),
        invoiceComment: new FormControl(orderItem.invoiceComment || ""),
        customerCompanyBranchOther: new FormGroup({
          name: new FormControl(
            (orderItem.customerCompanyBranch &&
              orderItem.customerCompanyBranch.name) ||
              null,
            orderItem.customerCompanyBranch &&
            orderItem.customerCompanyBranch.name
              ? Validators.required
              : null
          ),
          street: new FormControl(
            (orderItem.customerCompanyBranch &&
              orderItem.customerCompanyBranch.street) ||
              null,
            Validators.required
          ),
          postalCode: new FormControl(
            (orderItem.customerCompanyBranch &&
              orderItem.customerCompanyBranch.postalCode) ||
              null,
            Validators.required
          ),
          city: new FormControl(
            (orderItem.customerCompanyBranch &&
              orderItem.customerCompanyBranch.city) ||
              null,
            Validators.required
          ),
          country: new FormControl(
            (orderItem.customerCompanyBranch &&
              orderItem.customerCompanyBranch.country) ||
              "Polska",
            Validators.required
          ),
          contact: new FormControl(
            (orderItem.customerCompanyBranch &&
              orderItem.customerCompanyBranch.contact) ||
              null
          ),
          email: new FormControl(
            (orderItem.customerCompanyBranch &&
              orderItem.customerCompanyBranch.email) ||
              null
          ),
          phone: new FormControl(
            (orderItem.customerCompanyBranch &&
              orderItem.customerCompanyBranch.phone) ||
              null,
            Validators.required
          ),
          note: new FormControl(
            (orderItem.customerCompanyBranch &&
              orderItem.customerCompanyBranch.note) ||
              null
          ),
          order_comment: new FormControl(
            orderItem.comment ||
              (orderItem.order && orderItem.order.comment) ||
              null
          ),
          mainBranch: new FormControl(
            (orderItem.customerCompanyBranch &&
              orderItem.customerCompanyBranch.mainBranch) ||
              false
          ),
        }),
      })
    );
    generatedFromOrderId && control.at(index).get("order").disable();
    generatedFromOrderId &&
      setTimeout(() => {
        if (
          control.at(index).get("companyBranchId").value !== null &&
          this.data &&
          this.data.orders &&
          this.data.orders.length &&
          this.data.orders[0].companyBranchId !== null
        ) {
          this.taskReceivingForm.get("companyBranchId").disable();
        }
      }, 0);

    this.updateBdo(orderItem.bdo, index);

    control.at(index).get("salesAccepted").disable();
    if (control.at(index).get("companyBranchId").value !== null) {
      control.at(index).get("companyBranchId").disable();
    }

    if (control.at(index).get("reinvoice").value === false) {
      control.at(index).get("price").disable();
      control.at(index).get("reinvoiceNumber").disable();
    }
  }

  public deleteOrderSubform(index: number) {
    this.isExpanded.splice(index, 1);
    const control = <FormArray>this.taskReceivingForm.controls.taskData;
    const order = control.value[index];
    if (
      order.uuid !== "other" &&
      this.ordersOptionsInitial.includes(order.uuid)
    ) {
      this.ordersService.getOrder(order.uuid).subscribe((res) => {
        this.ordersOptionsRemoved.push(res);
      });
    }
    control.removeAt(index);
  }

  public pickOrder(order: OrderReceiving, index: number) {
    const control = <FormArray>this.taskReceivingForm.controls.taskData;
    const previousOrder = control.value[index];
    if (order.customerCompany && order.customerCompany.uuid) {
      this.getCustomerCompanyBranches(order.customerCompany.uuid);
    } else {
      control.controls[index].get("customerCompanyBranch").disable();
    }
    order.orderData = mapOrderDataToUuid(order.orderData);
    if (order.uuid === "other") {
      control.controls[index].reset();
      control.controls[index].patchValue({
        uuid: "other",
        order: { uuid: "other" },
        orderData: [],
        customerCompanyBranchOther: {
          country: "Polska",
          mainBranch: false,
        },
      });
    } else {
      let CCBranch =
        order.customerCompanyBranch && order.customerCompanyBranch.uuid;
      if (
        order.customerCompanyBranch &&
        !order.customerCompanyBranch.uuid &&
        order.customerCompanyBranch.street
      ) {
        CCBranch = "other";
      }

      control.controls[index].patchValue({
        uuid: order.uuid || "other",
        order: order || { uuid: "other" },
        customerCompany: order.customerCompany || null,
        referenceNumber: order.referenceNumber || null,
        bdo: order.bdo || null,
        bdoFree: order.bdoFree,
        bdoPeopleCollection: order.bdoPeopleCollection,
        reinvoice: order.reinvoice,
        kpoEmails: order.kpoEmails,
        price: order.price ? String(order.price).replace(".", ",") : "",
        reinvoiceNumber: order.reinvoiceNumber,
        invoiceComment: order.invoiceComment,
        availableHoursTo: order.availableHoursFrom || null,
        availableHoursFrom: order.availableHoursFrom || null,
        suggestedDate: order.suggestedDate,
        companyBranchId: order.companyBranch.uuid || null,
        salesAccepted: order.salesAccepted,
        orderData: order.orderData || [],
        customerCompanyBranch: CCBranch,
        customerCompanyBranchOther: {
          name:
            (order.customerCompanyBranch && order.customerCompanyBranch.name) ||
            null,
          street:
            (order.customerCompanyBranch &&
              order.customerCompanyBranch.street) ||
            null,
          postalCode:
            (order.customerCompanyBranch &&
              order.customerCompanyBranch.postalCode) ||
            null,
          city:
            (order.customerCompanyBranch && order.customerCompanyBranch.city) ||
            null,
          country:
            (order.customerCompanyBranch &&
              order.customerCompanyBranch.country) ||
            "Polska",
          contact:
            (order.customerCompanyBranch &&
              order.customerCompanyBranch.contact) ||
            null,
          email:
            (order.customerCompanyBranch &&
              order.customerCompanyBranch.email) ||
            null,
          phone:
            (order.customerCompanyBranch &&
              order.customerCompanyBranch.phone) ||
            null,
          note:
            (order.customerCompanyBranch && order.customerCompanyBranch.note) ||
            null,
          order_comment: order.comment || null,
          mainBranch: false,
        },
      });
    }

    if (order.uuid !== "other") {
      this.ordersOptionsRemoved = this.ordersOptionsRemoved.filter(
        (item) => item.uuid !== order.uuid
      );
    }
    if (
      previousOrder &&
      this.ordersOptionsInitial.includes(previousOrder.uuid)
    ) {
      this.ordersService.getOrder(previousOrder.uuid).subscribe((res) => {
        this.ordersOptionsRemoved.push(res);
      });
    }

    this.updateBdo(order.bdo, index);
  }

  public async onChangeCustomerCompany(
    company: FilteredCompany,
    index: number
  ) {
    if (company && company.uuid) {
      await this.getCustomerCompanyBranches(company.uuid);
    }
    const control = <FormArray>this.taskReceivingForm.controls.taskData;

    if (this.taskReceivingForm.get("taskData").value[index].uuid !== "other") {
      control.controls[index].patchValue({
        customerCompany: company,
        uuid: "other",
        order: { uuid: "other" },
        orderData: [],
      });
    }
    const ccMainBranch =
      this.customerCompanyBranches[company && company.uuid] &&
      this.customerCompanyBranches[company && company.uuid][0];
    control.controls[index].patchValue({
      customerCompany: company,
      kpoEmails: company.kpo.map((kpo) => kpo.email).join(","),
      customerCompanyBranch: ccMainBranch ? ccMainBranch.uuid || "other" : null,
      customerCompanyBranchOther: {
        name: (ccMainBranch && ccMainBranch.name) || null,
        street: (ccMainBranch && ccMainBranch.street) || null,
        postalCode: (ccMainBranch && ccMainBranch.postalCode) || null,
        city: (ccMainBranch && ccMainBranch.city) || null,
        country: (ccMainBranch && ccMainBranch.country) || "Polska",
        contact: (ccMainBranch && ccMainBranch.contact) || null,
        email: (ccMainBranch && ccMainBranch.email) || null,
        phone: (ccMainBranch && ccMainBranch.phone) || null,
        note: (ccMainBranch && ccMainBranch.note) || null,
        mainBranch: false,
      },
    });
    this.updateBdo(company.bdo, index, true);
  }

  public compareObjectsByUuid(val1, val2): boolean {
    return val1 && val2 && val1.uuid && val2.uuid && val1.uuid === val2.uuid;
  }

  public addCustomerCompany(index: number) {
    const editorDialogRef = this.matDialog.open(CompanyEditorComponent, {
      panelClass: "company-editor-dialog",
      autoFocus: false,
      disableClose: true,
      data: {
        mode: "add",
      },
    });

    editorDialogRef.componentInstance.onAddCompany.subscribe((res) => {
      this.customerCompanyOptions.push(res);
      this.onChangeCustomerCompany(res, index);
      this.companySelect.close();
    });
  }

  public getTitle(): string {
    switch (this.mode) {
      case "add":
      case "clone":
        return this.translateService.instant("TASKS.TITLE_ADD");
      case "edit":
        return this.translateService.instant("TASKS.TITLE_EDIT");
      case "view":
        return this.translateService.instant("TASKS.TITLE_VIEW");
      default:
        return;
    }
  }

  public showHistory() {
    this.matDialog.open(HistoryDialogComponent, {
      panelClass: "history-dialog",
      autoFocus: false,
      disableClose: true,
      data: {
        item: this.task,
        itemOrigin: ItemsOrigins.Task,
        itemId: this.taskId,
        itemNumber: this.task.taskNumber,
      },
    });
  }

  public enableEditMode() {
    this.indexStep = this.stepper.selectedIndex;
    this.taskEditorDialogRef.close();
    this.matDialog.open(TaskReceivingFormComponent, {
      panelClass: "task-editor-dialog",
      autoFocus: false,
      disableClose: true,
      data: {
        mode: "edit",
        taskId: this.taskId,
        indexStep: this.indexStep,
      },
    });
  }

  public enableCloneMode() {
    this.indexStep = this.stepper.selectedIndex;
    this.taskEditorDialogRef.close();
    this.matDialog.open(TaskReceivingFormComponent, {
      panelClass: "task-editor-dialog",
      autoFocus: false,
      disableClose: true,
      data: {
        mode: "clone",
        taskId: this.taskId,
        indexStep: this.indexStep,
      },
    });
  }

  public getOrdersOptions(index: number) {
    const options = [...this.ordersOptions, ...this.ordersOptionsRemoved];
    const selectedOrder = this.taskReceivingForm.get("taskData").value[index];
    this.taskReceivingForm.get("taskData").value &&
      this.taskReceivingForm.get("taskData").value.forEach((chosenOrder) => {
        if (
          chosenOrder.uuid &&
          chosenOrder.uuid !== "other" &&
          options.findIndex((item) => item.uuid === chosenOrder.uuid) > -1 &&
          chosenOrder.uuid !== selectedOrder.uuid
        ) {
          options.splice(
            options.findIndex((item) => item.uuid === chosenOrder.uuid),
            1
          );
        }
      });
    if (
      selectedOrder.uuid &&
      options.findIndex((order) => order.uuid === selectedOrder.uuid) === -1 &&
      selectedOrder.uuid !== "other"
    ) {
      options.push(selectedOrder.order || selectedOrder);
    }
    return options;
  }

  public getCustomerCompaniesOptions(index: number) {
    const options = [...this.customerCompanyOptions];
    const selectedOrder = this.taskReceivingForm.get("taskData").value[index];
    if (
      selectedOrder.customerCompany &&
      selectedOrder.customerCompany.uuid &&
      options.findIndex(
        (item) => item.uuid === selectedOrder.customerCompany.uuid
      ) === -1
    ) {
      options.push(selectedOrder.customerCompany);
    }
    return options;
  }

  public getTransportOptions() {
    const options = [...this.transportOptions];
    const chosenTransport = this.taskReceivingForm.get("transport").value;
    if (
      chosenTransport &&
      options.findIndex((item) => item.uuid === chosenTransport.uuid) === -1
    ) {
      options.push(chosenTransport);
    }
    return options;
  }

  public openEmailClient(index: number) {
    const taskData = this.taskReceivingForm.getRawValue();
    if (taskData.taskData[index].customerCompanyBranchOther.email) {
      return `mailto:${taskData.taskData[index].customerCompanyBranchOther.email}`;
    }
  }

  public canSendEmail(index: number) {
    return (
      this.mode === "view" &&
      this.taskReceivingForm.getRawValue().taskData[index]
        .customerCompanyBranchOther.email
    );
  }

  public reinvoiceChange(reinvoiceChecked: boolean, orderIndex: number) {
    const control = <FormArray>this.taskReceivingForm.controls.taskData;
    if (reinvoiceChecked) {
      control.controls[orderIndex].get("price").enable();
      control.controls[orderIndex].get("reinvoiceNumber").enable();
    } else {
      control.controls[orderIndex].get("price").setValue(null);
      control.controls[orderIndex].get("price").disable();
      control.controls[orderIndex].get("reinvoiceNumber").setValue("");
      control.controls[orderIndex].get("reinvoiceNumber").disable();
    }
  }

  public bdoRequiredToggle(bdoFree: boolean, orderIndex: number) {
    const control = <FormArray>this.taskReceivingForm.controls.taskData;
    const pickedOrder = control.controls[orderIndex].get("order").value;
    const pickedCustomerCompany =
      control.controls[orderIndex].get("customerCompany").value;
    const defaultBdo =
      (pickedOrder && pickedOrder.bdo) ||
      (pickedCustomerCompany && pickedCustomerCompany.bdo) ||
      null;
    if (bdoFree) {
      control.controls[orderIndex].get("bdo").clearValidators();
      control.controls[orderIndex].get("bdo").disable();
      control.controls[orderIndex].get("bdo").setValue("");
      control.controls[orderIndex].get("bdoPeopleCollection").setValue(false);
    } else if (!bdoFree && defaultBdo) {
      control.controls[orderIndex]
        .get("bdo")
        .setValidators(Validators.required);
      control.controls[orderIndex].get("bdo").disable();
      control.controls[orderIndex].get("bdo").setValue(defaultBdo);
    } else if (!bdoFree && !defaultBdo) {
      control.controls[orderIndex]
        .get("bdo")
        .setValidators(Validators.required);
      control.controls[orderIndex].get("bdo").enable();
    }
  }

  public bdoPeopleCollectionToggle(
    bdoPeopleCollection: boolean,
    orderIndex: number
  ) {
    const control = <FormArray>this.taskReceivingForm.controls.taskData;
    const pickedOrder = control.controls[orderIndex].get("order").value;
    const pickedCustomerCompany =
      control.controls[orderIndex].get("customerCompany").value;
    const defaultBdo =
      (pickedOrder && pickedOrder.bdo) ||
      (pickedCustomerCompany && pickedCustomerCompany.bdo) ||
      null;
    if (bdoPeopleCollection) {
      control.controls[orderIndex].get("bdo").clearValidators();
      control.controls[orderIndex].get("bdo").disable();
      control.controls[orderIndex].get("bdo").setValue("");
      control.controls[orderIndex].get("bdoFree").setValue(false);
    } else if (!bdoPeopleCollection && defaultBdo) {
      control.controls[orderIndex]
        .get("bdo")
        .setValidators(Validators.required);
      control.controls[orderIndex].get("bdo").disable();
      control.controls[orderIndex].get("bdo").setValue(defaultBdo);
    } else if (!bdoPeopleCollection && !defaultBdo) {
      control.controls[orderIndex]
        .get("bdo")
        .setValidators(Validators.required);
      control.controls[orderIndex].get("bdo").enable();
    }
  }

  private updateBdo(
    bdo: string,
    orderIndex: number,
    enableCheckbox: boolean = false
  ) {
    const control = <FormArray>this.taskReceivingForm.controls.taskData;

    if (enableCheckbox) {
      control.controls[orderIndex].get("bdoFree").enable();
      control.controls[orderIndex].get("bdoPeopleCollection").enable();
    }

    const bdoFree = control.controls[orderIndex].get("bdoFree").value;
    const bdoPeopleCollection = control.controls[orderIndex].get(
      "bdoPeopleCollection"
    ).value;
    control.controls[orderIndex].get("bdo").clearValidators();
    if (bdo && !bdoFree && !bdoPeopleCollection) {
      control.controls[orderIndex].get("bdo").setValue(bdo);
      control.controls[orderIndex].get("bdo").disable();
    } else if ((!bdo && !bdoFree) || !bdoPeopleCollection) {
      control.controls[orderIndex].get("bdo").setValue("");
      control.controls[orderIndex].get("bdo").enable();
    } else if (!bdo && (bdoFree || bdoPeopleCollection)) {
      control.controls[orderIndex].get("bdo").setValue("");
      control.controls[orderIndex].get("bdo").disable();
    }
  }

  public availableHoursFromDisabled = (i, timeIndex) => {
    const control = <FormArray>this.taskReceivingForm.controls.taskData;
    return (
      control.controls[i].get("availableHoursTo").value &&
      this.startTimeOptionsReceiving.includes(
        control.controls[i].get("availableHoursTo").value
      ) &&
      timeIndex + 1 >
        this.startTimeOptionsReceiving.indexOf(
          control.controls[i].get("availableHoursTo").value
        )
    );
  };

  public availableHoursToDisabled = (i, timeIndex) => {
    const control = <FormArray>this.taskReceivingForm.controls.taskData;
    return (
      control.controls[i].get("availableHoursFrom").value &&
      timeIndex <
        this.startTimeOptionsReceiving.indexOf(
          control.controls[i].get("availableHoursFrom").value
        )
    );
  };

  private startEndDateTimezoneValid(start, end) {
    if (this.mode === "view") {
      //we need to display current values even if they are wrong in `view` mode
      if (start && !this.startTimeOptions.includes(start)) {
        this.startTimeOptions = [start, ...this.startTimeOptions];
      }
      if (end && !this.endTimeOptions.includes(end)) {
        this.endTimeOptions = [end, ...this.endTimeOptions];
      }
      return true;
    } else {
      // if at least one of current values is wrong we clear fields
      if (start && !this.startTimeOptions.includes(start)) {
        return false;
      }
      if (end && !this.endTimeOptions.includes(end)) {
        return false;
      }
      return true;
    }
  }

  private createForm(inputData: TaskReceiving = new TaskReceiving()) {
    inputData.startTime = inputData.startDate
      ? moment(inputData.startDate).format("HH:mm")
      : null;
    inputData.endTime = inputData.endDate
      ? moment(inputData.endDate).format("HH:mm")
      : null;
    if (
      !this.startEndDateTimezoneValid(inputData.startTime, inputData.endTime)
    ) {
      inputData.startTime = null;
      inputData.endTime = null;
    }
    this.taskReceivingForm = this.formBuilder.group(
      {
        name: new FormControl(inputData.name || null, Validators.required),
        companyBranchId: new FormControl(
          (inputData.companyBranch && inputData.companyBranch.uuid) ||
            this.data.companyBranchId ||
            null,
          Validators.required
        ),
        transport: new FormControl(
          inputData.transport || null,
          Validators.required
        ),
        startDate: new FormControl(
          this.mode === "clone"
            ? null
            : inputData.startDate || this.data.startDate || null,
          Validators.required
        ),
        startTime: new FormControl(
          inputData.startTime || this.data.startTime || null,
          Validators.required
        ),
        endDate: new FormControl(
          this.mode === "clone"
            ? null
            : inputData.endDate || this.data.endDate || null,
          Validators.required
        ),
        endTime: new FormControl(
          inputData.endTime || this.data.endTime || null,
          Validators.required
        ),
        taskType: new FormControl(
          inputData.taskType || this.taskTypes.Receiving,
          Validators.required
        ),
        comment: new FormControl(
          this.mode === "add" || this.mode === "clone"
            ? null
            : inputData.comment || null
        ),
        referenceNumber: new FormControl(
          this.mode === "add" || this.mode === "clone"
            ? null
            : inputData.referenceNumber || null
        ),
        emailService: new FormControl(
          this.mode === "add" || this.mode === "clone"
            ? true
            : inputData.emailService
        ),
        registrationNumFront: new FormControl(
          this.mode === "add"
            ? null
            : inputData.forwarderRegistrationNumberFront
            ? inputData.forwarderRegistrationNumberFront
            : null
        ),
        registrationNumBack: new FormControl(
          this.mode === "add"
            ? null
            : inputData.forwarderRegistrationNumberBack
            ? inputData.forwarderRegistrationNumberBack
            : null
        ),
        taskData: this.formBuilder.array([], Validators.required),
        recurring: new FormControl(false),
        recurringInfo: this.formBuilder.group({
          intervalType: new FormControl("WEEK"),
          intervalValue: new FormControl(1, Validators.pattern(/\d/)),
          rangeDateEnd: new FormControl(inputData.startDate),
          weekdays: new FormGroup({
            Monday: new FormControl(false),
            Tuesday: new FormControl(false),
            Wednesday: new FormControl(false),
            Thursday: new FormControl(false),
            Friday: new FormControl(false),
            Saturday: new FormControl(false),
            Sunday: new FormControl(false),
          }),
        }),
        forwarderId: new FormControl(inputData.forwarderId || ""),
        forwarderCompanyName: new FormControl(
          inputData.forwarderCompanyName || ""
        ),
        originalCompanyName: new FormControl(
          inputData.originalCompanyName || ""
        ),
        forwarderDriverName: new FormControl(
          inputData.forwarderDriverName || ""
        ),
        forwarderDriverPhone: new FormControl(
          inputData.forwarderDriverPhone || ""
        ),
        forwarderRegistrationNumberFront: new FormControl(
          inputData.forwarderRegistrationNumberFront || ""
        ),
        forwarderRegistrationNumberBack: new FormControl(
          inputData.forwarderRegistrationNumberBack || ""
        ),
        forwarderKilometers: new FormControl(
          inputData.forwarderKilometers
            ? String(inputData.forwarderKilometers).replace(".", ",")
            : ""
        ),
        forwarderRouteDescription: new FormControl(
          inputData.forwarderRouteDescription || ""
        ),
        forwarderBdo: new FormControl(inputData.forwarderBdo || ""),
        forwarderCarName: new FormControl(inputData.forwarderCarName || ""),
        forwarderCarType: new FormControl(inputData.forwarderCarType || ""),
        forwarderExpense: new FormControl(
          inputData.forwarderExpense
            ? String(inputData.forwarderExpense).replace(".", ",")
            : ""
        ),
        forwarderInvoiceNumber: new FormControl(
          inputData.forwarderInvoiceNumber || ""
        ),
      },
      {
        validator: StartEndDateValidator.CheckDates,
      }
    );
    inputData.taskData &&
      inputData.taskData.forEach((item, index) =>
        this.addOrderSubform(index, item)
      );
    this.setRangeEndDateMax(this.taskReceivingForm.get("startDate").value);
    if (
      (this.mode === "add" || this.mode === "clone") &&
      !!this.data.companyBranchId
    ) {
      this.taskReceivingForm.get("companyBranchId").disable();
    }
    this.searchForwarder.setValue(inputData.originalCompanyName || "");
    this.taskReceivingForm.get("registrationNumFront").disable();
    this.taskReceivingForm.get("registrationNumBack").disable();

    if (this.mode === "view") {
      this.taskReceivingForm.disable();
    }
    if (this.mode === "edit") {
      this.getCompanyCars(inputData.forwarderId);
    }
  }

  private createSearchFilters() {
    this.searchCustomerCompany = new FormControl("");
    this.searchTransport = new FormControl("");
    this.searchOrders = new FormControl("");
    this.searchForwarder = new FormControl("");
    this.companyCarSelect = new FormControl("");
    this.forwarderIdSelect = new FormControl("");

    this.searchCustomerCompany.valueChanges
      .debounceTime(300)
      .subscribe((value) => {
        this.filteredListsService
          .getCompaniesList({ ...this.filterParams, ...{ search: value } })
          .subscribe((response) => {
            this.customerCompanyOptions = response.customerCompanies;
          });
      });
    this.searchTransport.valueChanges.debounceTime(300).subscribe((value) => {
      this.filteredListsService
        .getTransportList({ ...this.filterParamsFullList, ...{ name: value } })
        .subscribe((response) => {
          this.transportOptions = response.transports;
        });
    });
    this.searchOrders.valueChanges.debounceTime(300).subscribe((value) => {
      this.filteredListsService
        .getOrdersList({
          ...this.filterOrdersParams,
          ...{
            search: value,
            companyBranchUuid: this.taskReceivingForm.value.companyBranchId,
          },
        })
        .subscribe((response) => {
          this.ordersOptions = <OrderReceiving[]>response.orders;
        });
    });
    this.searchForwarder.valueChanges.debounceTime(300).subscribe((value) => {
      this.filteredListsService
        .getCompaniesList({
          ...this.filterForwarderParams,
          ...{ search: value },
        })
        .subscribe((response) => {
          this.forwarderCompanies = response.customerCompanies;
        });
    });
    this.companyCarSelect.valueChanges.subscribe((companyCar) => {
      this.taskReceivingForm.patchValue({
        forwarderCompanyName: companyCar.companyName,
        forwarderDriverName: companyCar.driverName,
        forwarderDriverPhone: companyCar.driverPhone,
        forwarderRegistrationNumberFront: companyCar.registrationNumberFront,
        forwarderRegistrationNumberBack: companyCar.registrationNumberBack,
        forwarderBdo: companyCar.bdo,
        forwarderCarName: companyCar.name,
        forwarderCarType: companyCar.type,
      });
    });
  }

  public orderFilter() {
    this.filteredListsService
      .getOrdersList({
        ...this.filterOrdersParams,
        ...{ companyBranchUuid: this.taskReceivingForm.value.companyBranchId },
      })
      .subscribe((response) => {
        this.ordersOptions = <OrderReceiving[]>response.orders;
      });
  }

  private createNewOrders(
    taskData: OrderReceivingSubform[],
    formCompanyBranchId: string
  ): Observable<OrderReceivingSubform[]> {
    const taskDataWithOtherType = taskData.filter(
      (task) => task.uuid === "other"
    );
    if (!taskDataWithOtherType.length) {
      return of([]);
    }
    const observables = taskDataWithOtherType.map((item) => {
      if (item.customerCompanyBranch.uuid === "other") {
        delete item.customerCompanyBranch.uuid;
      }
      let tmpCompanyBranchId;
      if (this.data.companyBranchId) {
        tmpCompanyBranchId = this.data.companyBranchId;
      } else if (
        this.task &&
        this.task.companyBranch &&
        this.task.companyBranch.uuid
      ) {
        tmpCompanyBranchId = this.task.companyBranch.uuid;
      } else if (this.task && this.task.companyBranchId) {
        tmpCompanyBranchId = this.task.companyBranchId;
      } else {
        tmpCompanyBranchId = formCompanyBranchId;
      }
      const newOrder = {
        companyBranchId: tmpCompanyBranchId,
        customerCompanyId: item.customerCompany.uuid,
        salesAccepted: item.salesAccepted,
        availableHoursFrom: item.availableHoursFrom || null,
        availableHoursTo: item.availableHoursTo || null,
        customerCompanyBranch: item.customerCompanyBranch,
        bdo: item.bdo ? item.bdo.toString() : null,
        bdoFree: Boolean(item.bdoFree && !item.bdo),
        bdoPeopleCollection: Boolean(item.bdoPeopleCollection && !item.bdo),
        kpoEmails: item.kpoEmails,
        reinvoice: item.reinvoice,
        price: Number(String(item.price).replace(",", ".")),
        reinvoiceNumber: item.reinvoiceNumber,
        invoiceComment: item.invoiceComment,
        suggestedDate:
          moment(item.suggestedDate).format("YYYY-MM-DD") ||
          moment(this.taskReceivingForm.get("startDate").value).format(
            "YYYY-MM-DD"
          ) ||
          moment().format("YYYY-MM-DD"),
        referenceNumber: item.referenceNumber,
        orderData: mapOrderDataApiValues(item.orderData),
        orderType: OrderTypes.Receiving,
      };
      return this.ordersService.createOrder(newOrder).pipe(
        map((resOrder: OrderReceiving) => {
          return {
            uuid: resOrder.uuid,
            salesAccepted: resOrder.salesAccepted,
            suggestedDate: resOrder.suggestedDate || null,
            availableHoursFrom: resOrder.availableHoursFrom || null,
            availableHoursTo: resOrder.availableHoursTo || null,
            customerCompanyId: resOrder.customerCompany
              ? resOrder.customerCompany.uuid
              : null,
            customerCompany: resOrder.customerCompany,
            customerCompanyBranch: resOrder.customerCompanyBranch,
            bdo: resOrder.bdo ? resOrder.bdo.toString() : null,
            bdoFree: resOrder.bdoFree && !resOrder.bdo,
            bdoPeopleCollection: resOrder.bdoPeopleCollection && !resOrder.bdo,
            kpoEmails: resOrder.kpoEmails,
            reinvoice: resOrder.reinvoice,
            price: resOrder.price,
            reinvoiceNumber: resOrder.reinvoiceNumber,
            invoiceComment: resOrder.invoiceComment,
            referenceNumber: resOrder.referenceNumber || null,
            orderData: item.orderData,
          };
        })
      );
    });
    return zip(...observables);
  }

  private mapTaskData(taskData: OrderReceivingSubform[]) {
    taskData = taskData.map((item) => {
      console.log(item);
      const taskDataItem = {
        order: item.order,
        customerCompanyBranch: item.customerCompanyBranch,
        salesAccepted: item.salesAccepted,
        suggestedDate: item.suggestedDate,
        availableHoursFrom: item.availableHoursFrom || null,
        availableHoursTo: item.availableHoursTo || null,
        customerCompanyId: item.customerCompany
          ? item.customerCompany.uuid
          : null,
        referenceNumber: item.referenceNumber || null,
        bdo: item.bdo ? item.bdo.toString() : null,
        bdoFree: item.bdoFree && !item.bdo,
        bdoPeopleCollection: item.bdoPeopleCollection && !item.bdo,
        kpoEmails: item.kpoEmails,
        reinvoice: item.reinvoice,
        price: Number(String(item.price).replace(",", ".")) || null,
        reinvoiceNumber: item.reinvoiceNumber,
        invoiceComment: item.invoiceComment,
        orderData: mapOrderDataApiValues(item.orderData),
      };
      if (item.uuid !== "other") {
        taskDataItem["orderId"] = item.uuid;
      }
      return taskDataItem;
    });
    return taskData;
  }

  public changeStep(event) {
    this.indexStep = event.selectedIndex;
  }

  public prevStep() {
    this.indexStep = this.indexStep - 1 >= 0 ? this.indexStep - 1 : 0;
  }

  public handleCCBranchChange(branchId: string, i: number) {
    const control = <FormArray>this.taskReceivingForm.controls.taskData;
    const group = <FormGroup>control.controls[i];
    const companyId =
      group.get("customerCompany").value &&
      group.get("customerCompany").value.uuid;
    const branch =
      companyId &&
      this.customerCompanyBranches[companyId] &&
      this.customerCompanyBranches[companyId].find(
        (item: CustomerCompanyBranch) => item.uuid === branchId
      );
    group.get("customerCompanyBranchOther").patchValue({
      name: (branch && branch.name) || null,
      street: (branch && branch.street) || null,
      postalCode: (branch && branch.postalCode) || null,
      city: (branch && branch.city) || null,
      country: (branch && branch.country) || "Polska",
      contact: (branch && branch.contact) || null,
      email: (branch && branch.email) || null,
      phone: (branch && branch.phone) || null,
      note: (branch && branch.note) || null,
      mainBranch: false,
    });
  }

  private async getCustomerCompanyBranches(customerCompanyId: string) {
    try {
      const res = await this.companyBranchesService
        .getCompanyBranchesList({ limit: 500, page: 0 }, customerCompanyId)
        .toPromise();
      this.customerCompanyBranches[customerCompanyId] = res.branches;
      return true;
    } catch (e) {
      return false;
    }
  }

  public getFormCompanyUuid(i: number): string {
    const control = <FormArray>this.taskReceivingForm.controls.taskData;
    const group = <FormGroup>control.controls[i];
    return (
      (group.get("customerCompany").value &&
        group.get("customerCompany").value.uuid) ||
      "-"
    );
  }

  public getTooltip(branch: any) {
    return `${branch.name}
    ${branch.street}
    ${branch.postalCode} ${branch.city}`;
  }

  public getTooltipCompany(company: any) {
    if (company) {
      return `${company.name} 
      ${company.address.street}
      ${company.address.postalCode} ${company.address.city}`;
    }
  }

  private getCompanyCars(forwarderId: string) {
    this.companyCarsService
      .getCompanyCarsList(this.filterParamsFullList, forwarderId)
      .subscribe((response) => {
        this.companyCars = response.cars;
      });
  }

  public selected(event: MatAutocompleteSelectedEvent): void {
    this.searchForwarder.patchValue(event.option.viewValue);
    this.companyCarsService
      .getCompanyCarsList(this.filterParamsFullList, event.option.value)
      .subscribe((response) => {
        this.companyCars = response.cars;
        this.taskReceivingForm.patchValue({
          forwarderId: event.option.value,
          forwarderCompanyName: event.option.viewValue,
          forwarderDriverName: null,
          forwarderDriverPhone: null,
          forwarderRegistrationNumberFront: null,
          forwarderRegistrationNumberBack: null,
          forwarderBdo: null,
          forwarderCarName: null,
          forwarderCarType: null,
        });
      });
  }

  private getPdfNeededData() {
    this.usersService.getCompanyBranchList().subscribe((res) => {
      this.pdfBranch = res.branches.find(
        (item) => item.uuid === this.task.companyBranch.uuid
      );
    });
    if (this.task.forwarderId) {
      this.companiesService
        .getCompany(this.task.forwarderId)
        .subscribe((res) => {
          this.pdfForwarder = res;
        });
    } else {
      this.pdfForwarder = { name: "Brak Danych", address: "Brak danych" };
    }
  }

  public getEmailControl(index: number) {
    const control = <FormArray>this.taskReceivingForm.controls.taskData;
    return control.controls[index].get("kpoEmails");
  }

  public resendNotification(index: number) {
    const orderId = this.task.taskData[index].order.uuid;
    this.isLoading = true;
    this.ordersService.resendNotification(orderId).subscribe(
      () => {
        this.isLoading = false;
        this.snackBar.open(
          this.translateService.instant("TASKS.MESSAGES.NOTIFICATION_SENT"),
          "",
          { duration: 5000 }
        );
      },
      () => {
        this.isLoading = false;
        this.snackBar.open(
          this.translateService.instant("TASKS.MESSAGES.ERROR"),
          "",
          { duration: 5000 }
        );
      }
    );
  }
}
