<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">{{getTitle()}}</span>
      <button mat-icon-button (click)="companyEditorDialogRef.close()" aria-label="Close dialog" [attr.data-cy]="'closeModal'">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <mat-tab-group mat-stretch-tabs [(selectedIndex)]="selectedTab">

    <!--COMPANY TAB-->
    <mat-tab class="company-tab" [label]="'CUSTOMER_COMPANIES.COMPANY_DATA' | translate">
      <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>
        <form class="form" [formGroup]="companyForm">
          <div class="full-width-wrapper-horizontal">
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>{{'CUSTOMER_COMPANIES.NAME' | translate}}</mat-label>
              <mat-icon matSuffix class="secondary-text">store_mall_directory</mat-icon>
              <input formControlName="name" matInput required [attr.data-cy]="'companyName'">
            </mat-form-field>
          </div>

          <div class="input-wrapper field-left">
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>{{'CUSTOMER_COMPANIES.NIP' | translate}}</mat-label>
              <input formControlName="nip" matInput required [attr.data-cy]="'companyNip'">
              <mat-error
                *ngIf="companyForm.get('nip').touched && companyForm.get('nip').errors">
                {{'CUSTOMER_COMPANIES.MESSAGES.NIP_LENGTH_ERROR' | translate}}
              </mat-error>
              <button class="btn-gus" *ngIf="mode === 'add'"
                      [disabled]="companyForm.get('nip').invalid && companyForm.get('nip').errors "
                      ng-disabled="companyForm.get('nip').invalid === false"
                      (click)="getGusInfo()" [attr.data-cy]="'getGusInfo'">
                {{"CUSTOMER_COMPANIES.GET_DATA" | translate}}
              </button>
            </mat-form-field>
          </div>
          <div>
          </div>

          <div class="input-wrapper field-right">
            <mat-form-field class="multiple-select" appearance="outline" fxFlex>
              <mat-label>{{'CUSTOMER_COMPANIES.COMPANY_TYPE' | translate}}</mat-label>
              <mat-select formControlName="companyType" required multiple [attr.data-cy]="'companyType'">
                <mat-option value="supplier" [attr.data-cy]="'SUPPLIER'">{{'CUSTOMER_COMPANIES.COMPANY_TYPES.SUPPLIER' | translate}}</mat-option>
                <mat-option value="recipient" [attr.data-cy]="'RECIPIENT'">{{'CUSTOMER_COMPANIES.COMPANY_TYPES.RECIPIENT' | translate}}
                </mat-option>
                <mat-option value="forwarder" [attr.data-cy]="'FORWARDER'" *ngIf="user.userType === userTypes.Logistician">
                  {{'CUSTOMER_COMPANIES.COMPANY_TYPES.FORWARDER' | translate}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="full-width-wrapper-horizontal" formGroupName="address">
            <div class="input-wrapper field-left">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>{{'CUSTOMER_COMPANIES.STREET' | translate}}</mat-label>
                <input formControlName="street" matInput required [attr.data-cy]="'companyStreet'">
              </mat-form-field>
            </div>

            <div class="input-wrapper field-right">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>{{'CUSTOMER_COMPANIES.POSTAL_CODE' | translate}}</mat-label>
                <input formControlName="postalCode" matInput required [attr.data-cy]="'companyPostalCode'">
              </mat-form-field>
            </div>

            <div class="input-wrapper field-left">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>{{'CUSTOMER_COMPANIES.CITY' | translate}}</mat-label>
                <input formControlName="city" matInput required [attr.data-cy]="'companyCity'">
              </mat-form-field>
            </div>

            <div class="input-wrapper field-right">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>{{'CUSTOMER_COMPANIES.COUNTRY' | translate}}</mat-label>
                <input formControlName="country" matInput required>
              </mat-form-field>
            </div>
          </div>

          <div class="input-wrapper field-left">
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>{{'CUSTOMER_COMPANIES.REGON' | translate}}</mat-label>
              <input formControlName="regon" matInput required [attr.data-cy]="'companyRegon'">
            </mat-form-field>
          </div>

          <div class="input-wrapper field-right">
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>{{'CUSTOMER_COMPANIES.KRS' | translate}}</mat-label>
              <input formControlName="krs" matInput [attr.data-cy]="'companyKrs'">
            </mat-form-field>
          </div>

          <div class="input-wrapper field-left">
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>{{'CUSTOMER_COMPANIES.BDO' | translate}}</mat-label>
              <input formControlName="bdo" matInput [attr.data-cy]="'companyBdo'">
            </mat-form-field>
          </div>

          <div class="input-wrapper field-right">
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>{{'CUSTOMER_COMPANIES.EMAIL' | translate}}</mat-label>
              <input formControlName="email" type="email" matInput [attr.data-cy]="'companyEmail'">
            </mat-form-field>
          </div>

          <div class="input-wrapper field-left">
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>{{'CUSTOMER_COMPANIES.PHONE' | translate}}</mat-label>
              <input formControlName="phone" matInput [attr.data-cy]="'companyPhone'">
            </mat-form-field>
          </div>

          <div class="input-wrapper field-right">
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>{{'CUSTOMER_COMPANIES.CONTACT' | translate}}</mat-label>
              <input formControlName="contact" matInput [attr.data-cy]="'companyContact'">
            </mat-form-field>
          </div>

          <div class="input-wrapper field-left" *ngIf="hasAccessToUsersList">
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>{{'CUSTOMER_COMPANIES.OWNER' | translate}}</mat-label>
              <mat-select formControlName="ownerId" [attr.data-cy]="'companyOwner'">
                <mat-option [value]="" [attr.data-cy]="'NOBODY'">- {{'CUSTOMER_COMPANIES.CLEAN' | translate}} -</mat-option>
                <mat-option *ngFor="let owner of ownersOptions" [value]="owner.uuid" [attr.data-cy]="owner.name+owner.surname">
                  {{owner.name}} {{owner.surname}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="input-wrapper field-right">
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>{{'CUSTOMER_COMPANIES.TRUSTED' | translate}}</mat-label>
              <mat-select formControlName="trusted" [attr.data-cy]="'companyIsTrusted'">
                <mat-option [value]="true" [attr.data-cy]="'TRUSTED'">{{'CUSTOMER_COMPANIES.TRUSTED' | translate}}</mat-option>
                <mat-option [value]="false" [attr.data-cy]="'UNTRUSTED'">{{'CUSTOMER_COMPANIES.UNTRUSTED' | translate}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </form>


        <div *ngIf="mode === 'add' && !differentInvoiceAddress" fxLayoutAlign="center center">
          <button mat-button
                  class="mr-8"
                  color="warn"
                  (click)="toggleInvoiceAddress()"
                  [attr.data-cy]="'invoiceAddressOpen'">
            {{'CUSTOMER_COMPANIES.DIFFERENT_INVOICE_ADDRESS' | translate}}
          </button>
        </div>
        <div fxLayoutAlign="space-between center">
          <h3 *ngIf="mode === 'edit' || mode === 'view' || differentInvoiceAddress">
            {{'CUSTOMER_COMPANIES.INVOICE_ADDRESS' | translate}}
          </h3>
          <button *ngIf="mode === 'add' && differentInvoiceAddress"
                  mat-icon-button
                  color="warn"
                  (click)="toggleInvoiceAddress()"
                  [attr.data-cy]="'invoiceAddressClose'">
            <mat-icon>close</mat-icon>
          </button>
        </div>


        <form class="full-width-wrapper-horizontal"
              *ngIf="mode === 'edit' || mode === 'view' || differentInvoiceAddress"
              [formGroup]="invoiceForm">
          <div class="input-wrapper field-left">
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>{{'CUSTOMER_COMPANIES.STREET' | translate}}</mat-label>
              <input formControlName="street" matInput required [attr.data-cy]="'invoiceStreet'">
            </mat-form-field>
          </div>

          <div class="input-wrapper field-right">
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>{{'CUSTOMER_COMPANIES.POSTAL_CODE' | translate}}</mat-label>
              <input formControlName="postalCode" matInput required [attr.data-cy]="'invoicePostalCode'">
            </mat-form-field>
          </div>

          <div class="input-wrapper field-left">
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>{{'CUSTOMER_COMPANIES.CITY' | translate}}</mat-label>
              <input formControlName="city" matInput required [attr.data-cy]="'invoiceCity'">
            </mat-form-field>
          </div>

          <div class="input-wrapper field-right">
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>{{'CUSTOMER_COMPANIES.COUNTRY' | translate}}</mat-label>
              <input formControlName="country" matInput required [attr.data-cy]="'invoiceCountry'">
            </mat-form-field>
          </div>
        </form>


        <form class="full-width-wrapper-horizontal" [formGroup]="datesForm" *ngIf="mode === 'view'">
          <h3 class="additional-info">{{'CUSTOMER_COMPANIES.ADDITIONAL_DATA' | translate}}</h3>
          <div class="input-wrapper field-left">
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>{{'CUSTOMER_COMPANIES.CREATED_DATE' | translate}}</mat-label>
              <input formControlName="createdDate" matInput>
            </mat-form-field>
          </div>

          <div class="input-wrapper field-right">
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>{{'CUSTOMER_COMPANIES.UPDATED_DATE' | translate}}</mat-label>
              <input formControlName="updatedDate" matInput>
            </mat-form-field>
          </div>
        </form>
      </div>

      <div *ngIf="selectedTab === 0" mat-dialog-actions class="company-action-buttons m-0 p-16"
           fxLayout="row" fxLayoutAlign="end center">
        <button *ngIf="mode === 'edit' && user.roleType === roleTypes.Admin"
                mat-button
                class="mr-8"
                (click)="deleteCompany()"
                [attr.data-cy]="'deleteCompany'">
          {{'CUSTOMER_COMPANIES.DELETE' | translate}}
        </button>
        <button *ngIf="mode === 'view'"
                mat-button
                class="mr-8"
                (click)="enableEditMode()"
                [attr.data-cy]="'editCompany'">
          {{'CUSTOMER_COMPANIES.EDIT' | translate}}
        </button>
        <button *ngIf="mode === 'edit' || mode === 'add'"
                mat-button
                class="save-button"
                (click)="submitCompany()"
                [attr.data-cy]="'saveCompany'"
                [disabled]="((differentInvoiceAddress || mode === 'edit') && invoiceForm.invalid) || isLoading">
          <span *ngIf="!isRequestInProgress"> {{'CUSTOMER_COMPANIES.SAVE' | translate}}</span>
          <span *ngIf="isRequestInProgress" class="loading-spinner-wrapper">
        <mat-progress-spinner mode="indeterminate"
                              [strokeWidth]="2"
                              [diameter]="20">
        </mat-progress-spinner>
      </span>
        </button>
      </div>
    </mat-tab>

    <!--CLIENTS TAB-->
    <mat-tab *ngIf="mode !== 'add'" class="clients-tab" [label]="'CUSTOMER_COMPANIES.COMPANY_ACCOUNTS' | translate">
      <div mat-dialog-content class="p-24 m-0" fusePerfectScrollbar [attr.data-cy]="'accountTab'">
        <app-company-accounts-list [companyUuid]="companyUuid" [attr.data-cy]="'accountTab'"></app-company-accounts-list>
      </div>
    </mat-tab> 
    
    <!--KPO TAB-->
    <mat-tab *ngIf="mode !== 'add'" class="clients-tab" [label]="'CUSTOMER_COMPANIES.COMPANY_KPO' | translate">
      <div mat-dialog-content class="p-24 m-0" fusePerfectScrollbar [attr.data-cy]="'kpoTab'">
        <app-company-kpo-list [companyUuid]="companyUuid" [attr.data-cy]="'kpoTab'"></app-company-kpo-list>
      </div>
    </mat-tab>

    <mat-tab *ngIf="mode !== 'add'" class="clients-tab" [label]="'CUSTOMER_COMPANIES.COMPANY_BRANCHES' | translate">
      <div mat-dialog-content class="p-24 m-0" fusePerfectScrollbar [attr.data-cy]="'branchesTab'">
        <app-company-branches-list [companyUuid]="companyUuid"></app-company-branches-list>
      </div>
    </mat-tab>

    <mat-tab *ngIf="mode !== 'add'" class="clients-tab" [label]="'CUSTOMER_COMPANIES.BUSINESS_TERMS' | translate">
      <div mat-dialog-content class="p-24 m-0" fusePerfectScrollbar [attr.data-cy]="'businessTermsTab'">
        <form class="form" [formGroup]="businessTermsForm">
          <div class="input-wrapper field-right">
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>{{'CUSTOMER_COMPANIES.BUSINESS_TERMS' | translate}}</mat-label>
              <textarea formControlName="businessTerms" matInput required [attr.data-cy]="'businessTerms'"></textarea>
            </mat-form-field>
          </div>
        </form>
      </div>
      <div *ngIf="selectedTab === 3" mat-dialog-actions class="company-action-buttons m-0 p-16"
           fxLayout="row" fxLayoutAlign="end center">
        <button *ngIf="businessTermsForm.disabled"
                mat-button
                class="mr-8"
                (click)="enableEditModeForBusinessTerms()"
                [attr.data-cy]="'editCompany'">
          {{'CUSTOMER_COMPANIES.EDIT' | translate}}
        </button>
        <button *ngIf="businessTermsForm.enabled"
                mat-button
                class="save-button"
                (click)="submitBusinessTerms()"
                [attr.data-cy]="'saveCompany'"
                [disabled]="isLoading">
          <span *ngIf="!isRequestInProgress"> {{'CUSTOMER_COMPANIES.SAVE' | translate}}</span>
          <span *ngIf="isRequestInProgress" class="loading-spinner-wrapper">
              <mat-progress-spinner mode="indeterminate"
                                    [strokeWidth]="2"
                                    [diameter]="20">
              </mat-progress-spinner>
            </span>
        </button>
      </div>
    </mat-tab>

    <mat-tab *ngIf="mode !== 'add'" class="clients-tab" [label]="'CUSTOMER_COMPANIES.COMPANY_CARS' | translate">
      <div mat-dialog-content class="p-24 m-0" fusePerfectScrollbar [attr.data-cy]="'carsTab'">
        <app-company-cars-list [companyUuid]="companyUuid"></app-company-cars-list>
      </div>
    </mat-tab>
  </mat-tab-group>

</div>