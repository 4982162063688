import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {FuseTranslationLoaderService} from '../../../../../@fuse/services/translation-loader.service';
import {Tag} from '../../../../shared/models/tags.model';
import {TagsService} from '../../../../services/tags.service';
import {locale as polish} from '../../../../translations/pl';
import {locale as english} from '../../../../translations/en';
import {ConfirmModalComponent} from '../../../shared/components/confirm-modal/confirm-modal.component';
import {markFormGroupTouched} from "../../../../shared/utils/markFormGroupAsTouched";

@Component({
  selector: 'app-tag-editor',
  templateUrl: './tag-editor.component.html',
  styleUrls: ['./tag-editor.component.scss']
})
export class TagEditorComponent implements OnInit {
  public tagForm: FormGroup;
  public tagUuid: Tag['uuid'];
  public tag;
  public mode: string;
  public isLoading = false;

  constructor(public tagEditorDialogRef: MatDialogRef<TagEditorComponent>,
              @Inject(MAT_DIALOG_DATA) private data: any,
              private matDialog: MatDialog,
              private tagsService: TagsService,
              private translateService: TranslateService,
              private snackBar: MatSnackBar,
              private formBuilder: FormBuilder,
              private _fuseTranslationLoaderService: FuseTranslationLoaderService) {
    this.mode = data.mode;
    this.tagUuid = data.tagUuid;

    this._fuseTranslationLoaderService.loadTranslations(polish, english);
  }

  ngOnInit() {
    this.createForm({});

    if (this.mode === 'edit' || this.mode === 'view') {
      this.tagsService.getTag(this.tagUuid).subscribe(
        res => {
          this.tag = res;
          this.createForm(this.tag);
        }
      );
    }
  }

  public submitTag() {
    this.isLoading = true;
    markFormGroupTouched(this.tagForm);
    if (this.tagForm.invalid) {
      this.isLoading = false;
      return;
    }
    if (this.mode === 'add') {
      this.tagsService.createTag(this.tagForm.value).subscribe(
        () => {
          this.tagEditorDialogRef.close();

          this.tagEditorDialogRef.afterClosed().subscribe(() => {
            this.tagsService.onEditEvent.emit();
          });

          this.snackBar.open(this.translateService.instant('TAGS.MESSAGES.CREATE_SUCCESS'), '', {duration: 5000});
        }, () => {
          this.isLoading = false;
          this.snackBar.open(this.translateService.instant('TAGS.MESSAGES.CREATE_ERROR'), '', {duration: 5000});
        }
      )
    } else {
      this.tagsService.updateTag(this.tagUuid, this.tagForm.value).subscribe(
        () => {
          this.tagEditorDialogRef.close();

          this.tagEditorDialogRef.afterClosed().subscribe(() => {
            this.tagsService.onEditEvent.emit();
          });

          this.snackBar.open(this.translateService.instant('TAGS.MESSAGES.UPDATE_SUCCESS'), '', {duration: 5000});
        }, () => {
          this.isLoading = false;
          this.snackBar.open(this.translateService.instant('TAGS.MESSAGES.UPDATE_ERROR'), '', {duration: 5000});
        }
      )
    }
  }

  public deleteTag() {
    let deleteDialogRef = this.matDialog.open(ConfirmModalComponent, {
      disableClose: false,
      autoFocus: false
    });

    deleteDialogRef.componentInstance.confirmMessage = this.translateService.instant('TAGS.MESSAGES.DELETE_QUESTION');
    deleteDialogRef.componentInstance.titleMessage = this.translateService.instant('TAGS.MESSAGES.DELETE_TAG_TITLE');
    deleteDialogRef.componentInstance.confirmButton = this.translateService.instant('TAGS.DELETE');

    deleteDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.tagsService.deleteTag(this.tagUuid).subscribe(
          () => {
            this.tagEditorDialogRef.close();
            this.tagEditorDialogRef.afterClosed().subscribe(() => {
              this.tagsService.onEditEvent.emit();
            });
            this.snackBar.open(this.translateService.instant('TAGS.MESSAGES.DELETE_SUCCESS'), '', {duration: 5000});
          }, () => {
            this.snackBar.open(this.translateService.instant('TAGS.MESSAGES.DELETE_ERROR'), '', {duration: 5000});
          }
        )
      }
    });
  }

  public enableEditMode() {
    this.tagEditorDialogRef.close();
    this.matDialog.open(TagEditorComponent, {
      panelClass: 'tag-editor-dialog',
      autoFocus: false,
      disableClose: true,
      data: {
        mode: 'edit',
        tagUuid: this.tagUuid
      }
    });
  }

  public getTitle(): string {
    switch (this.mode) {
      case 'add':
        return this.translateService.instant('TAGS.TITLE_ADD');
      case 'edit':
        return this.translateService.instant('TAGS.TITLE_EDIT');
      case 'view':
        return this.translateService.instant('TAGS.TITLE_VIEW');
      default:
        return;
    }
  }

  private createForm(inputData: Tag | any) {
    this.tagForm = this.formBuilder.group({
      name: new FormControl(inputData.name || '', Validators.required),
      active: new FormControl(this.mode === 'add' ? true : inputData.active),
    });

    if (this.mode === 'view') {
      this.tagForm.disable();
    }
  }

}
