import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-recycling-items-changes-display',
  templateUrl: './recycling-items-changes-display.component.html',
  styleUrls: ['./recycling-items-changes-display.component.scss']
})
export class RecyclingItemsChangesDisplayComponent {
  @Input() recyclingItemsChanged: any;
}
