var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { RecyclingItemsEditorComponent } from '../recycling-items-editor/recycling-items-editor.component';
import { RecyclingService } from '../../../../services/recycling.service';
import { locale as polish } from '../../../../translations/pl';
import { locale as english } from '../../../../translations/en';
import { List } from '../../../../shared/models/list.class';
import { FormControl } from '@angular/forms';
var RecyclingItemsListComponent = /** @class */ (function (_super) {
    __extends(RecyclingItemsListComponent, _super);
    function RecyclingItemsListComponent(recyclingService, matDialog, _fuseTranslationLoaderService) {
        var _this = _super.call(this) || this;
        _this.recyclingService = recyclingService;
        _this.matDialog = matDialog;
        _this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        _this.displayedColumns = ['name', 'weightPerPiece', 'weightPerCubicMeter', 'code', 'description', 'isPublic', 'active'];
        _this.listFilters = {};
        _this._fuseTranslationLoaderService.loadTranslations(polish, english);
        _this.reloadList$ = recyclingService.onEditEvent.subscribe(function () {
            _this.getElementsList();
        });
        _this.getElementsList = function () {
            _this.recyclingService.getRecyclingItemsList(_this.getQueryParams()).subscribe(function (res) {
                _this.totalCount = res.totalCount;
                _this.dataSource = res.recyclingItems;
            });
        };
        return _this;
    }
    RecyclingItemsListComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.createSearchFormControl();
    };
    RecyclingItemsListComponent.prototype.ngOnDestroy = function () {
        this.reloadList$.unsubscribe();
    };
    RecyclingItemsListComponent.prototype.addRecyclingItem = function () {
        this.disableAnimations = true;
        var editorDialogRef = this.matDialog.open(RecyclingItemsEditorComponent, {
            panelClass: 'recycling-editor-dialog',
            autoFocus: false,
            disableClose: true,
            data: {
                mode: 'add'
            }
        });
    };
    RecyclingItemsListComponent.prototype.viewRecyclingItem = function (recyclingItemUuid) {
        this.disableAnimations = true;
        var editorDialogRef = this.matDialog.open(RecyclingItemsEditorComponent, {
            panelClass: 'recycling-editor-dialog',
            autoFocus: false,
            disableClose: true,
            data: {
                mode: 'view',
                recyclingItemUuid: recyclingItemUuid
            }
        });
    };
    RecyclingItemsListComponent.prototype.clearSearch = function () {
        if (this.searchRecycling.value) {
            this.searchRecycling.setValue('');
        }
    };
    RecyclingItemsListComponent.prototype.getListFilters = function (filters) {
        this.listFilters = filters;
        this.requestParams.page = 1;
        this.disableAnimations = true;
        this.getElementsList();
    };
    RecyclingItemsListComponent.prototype.createSearchFormControl = function () {
        var _this = this;
        this.searchRecycling = new FormControl('');
        this.searchRecycling.valueChanges.debounceTime(300).subscribe(function (value) {
            _this.requestParams.search = value;
            _this.requestParams.page = 1;
            _this.disableAnimations = true;
            _this.getElementsList();
        });
    };
    RecyclingItemsListComponent.prototype.getQueryParams = function () {
        return Object.assign({}, this.requestParams, this.listFilters);
    };
    return RecyclingItemsListComponent;
}(List));
export { RecyclingItemsListComponent };
