    <div class="categories-summary-cell">
    <div class="column">
        <p *ngFor="let category of transportCategories">
            {{category.name | translate}}: {{getTransportCategoryCount(category.value)}}
        </p>
    </div>
    <div class="column summary">
        <p>
            {{'CALENDAR.TASK_TYPES.IMPORT_SHORT' | translate}}: {{getValue('income', false)}} /
            {{getValue('income',true)}} / {{getTotal('income')}}
        </p>
        <p>
            {{'CALENDAR.TASK_TYPES.EXPORT_SHORT' | translate}}: {{getValue('outcome', false)}} /
            {{getValue('outcome',true)}} / {{getTotal('outcome')}}
        </p>
    </div>
</div>