import {ChangeDetectionStrategy, Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {FuseTranslationLoaderService} from '@fuse/services/translation-loader.service';
import {locale as polish} from '../../../../translations/pl';
import {locale as english} from '../../../../translations/en';
import {MatDialog, MatSelect, MatSnackBar} from '@angular/material';
import {Subject, zip} from 'rxjs';
import {TasksService} from '../../../../services/tasks.service';
import {NoteCalendarService} from '../../../../services/note-calendar.service';
import {
  CalendarDayViewBeforeRenderEvent,
  CalendarEvent,
  CalendarEventAction,
  CalendarMonthViewBeforeRenderEvent,
  CalendarMonthViewDay,
  CalendarWeekViewBeforeRenderEvent,
  CalendarWeekViewComponent
} from 'angular-calendar';
import {isSameDay, isSameMonth, startOfDay} from 'date-fns';
import {CalendarTaskModel, CalendarUpdatedTaskModel} from '../../calendar.model';
import {registerLocaleData} from '@angular/common';
import localePl from '@angular/common/locales/pl';
import * as moment from 'moment';
import {UsersService} from '../../../../services/users.service';
import {TranslateService} from '@ngx-translate/core';
import {CompaniesService} from '../../../../services/companies.service';
import {RequestParams} from '../../../../shared/models/list.model';
import {List} from '../../../../shared/models/list.class';
import {FormControl} from '@angular/forms';
import {TaskTypePickerComponent} from '../../../tasks/components/task-type-picker/task-type-picker.component';
import {RoleTypes, User, UsersListResponse, UserTypes} from '../../../../shared/models/users.model';
import {AuthService} from '../../../../services/auth.service';
import {
  TaskExportFormComponent
} from '../../../tasks/components/task-forms/task-export-form/task-export-form.component';
import {
  TaskImportFormComponent
} from '../../../tasks/components/task-forms/task-import-form/task-import-form.component';
import {
  TaskReceivingFormComponent
} from '../../../tasks/components/task-forms/task-receiving-form/task-receiving-form.component';
import {ConfirmModalComponent} from '../../../shared/components/confirm-modal/confirm-modal.component';
import {TaskStatuses} from '../../../../shared/models/tasks.model';
import {TaskWarningsService} from '../../../../services/task-warnings.service';
import {FuseSidebarService} from '../../../../../@fuse/components/sidebar/sidebar.service';
import {CompanyBranch} from '../../../../shared/models/company.model';
import {Description} from '../../../../shared/models/description.model';

@Component({
  selector: 'calendar',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CalendarComponent extends List implements OnInit {
  @ViewChild('calendarComponent') calendarComponent: CalendarWeekViewComponent;
  @ViewChild('additionalCalendarComponent') additionalCalendarComponent: CalendarWeekViewComponent;
  public actions: CalendarEventAction[];
  public activeDayIsOpen: boolean;
  public tasks = [];
  public mainCalendarTasks = [];
  public additionalCalendarTasks = [];
  public dailySummary = {};
  public cumulativelySummary = {};
  public totalResponse = {};
  public refresh: Subject<any> = new Subject();
  public selectedDay: any;
  public view: string;
  public viewName: string;
  public viewDate: Date;
  public locale = 'pl';
  public selectedCompanyBranchId: string;
  public selectedCompanyBranchName: string;
  public selectedSalesUserUuid: string;
  public selectedStatus: string[];
  public companyBranches: CompanyBranch[] = [];
  public searchTask: FormControl;
  public searchTaskNameNumber: string;
  public user: User;
  public salesList: User[];
  public userTypes = UserTypes;
  public roleTypes = RoleTypes;
  public taskStatuses = TaskStatuses;
  public taskStatusesList = [];
  public calendarSplitView = false;
  public folded: boolean;
  public mainCalendarView = {
    export: true,
    import: true,
    receiving: true,
    cancelled: true
  };
  public additionalCalendarView = {
    export: false,
    import: false,
    receiving: false,
    cancelled: false
  };
  public tasksTabsActive = {
    export: true,
    import: true,
    receiving: true,
    cancelled: false
  };
  public noteList: Description[];
  private taskWarnings = [];
  public lastRequestParams: RequestParams;

  constructor(
    private tasksService: TasksService,
    private noteCalendarService: NoteCalendarService,
    private usersService: UsersService,
    private authService: AuthService,
    private companiesService: CompaniesService,
    private translateService: TranslateService,
    private taskWarningService: TaskWarningsService,
    private snackBar: MatSnackBar,
    private matDialog: MatDialog,
    private _fuseSidebarService: FuseSidebarService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService
  ) {
    super();
    this.user = this.authService.user.user;

    this._fuseTranslationLoaderService.loadTranslations(polish, english);
    registerLocaleData(localePl);

    // Set the defaults
    this.view = 'week';
    this.viewName = 'workweek';
    this.viewDate = new Date();
    this.activeDayIsOpen = true;
    this.selectedDay = {date: startOfDay(new Date())};
    this.selectedCompanyBranchId = this.user.companyBranchId;
    this.selectedCompanyBranchName = this.user.companyBranch;
    this.getTasksList();
  }


  ngOnInit() {
    this.createSearchFormControl();
    this._fuseSidebarService.getSidebar('navbar').foldedChanged.subscribe(() => {
      this.folded = this._fuseSidebarService.getSidebar('navbar').folded;
    });

    this.tasksService.onEditEvent.subscribe(() => {
      this.getTasksList();
    });

    this.usersService.getCompanyBranchList().subscribe(res => {
      this.companyBranches = res.branches;
      if (!this.selectedCompanyBranchId && this.companyBranches.length > 0) {
        this.selectedCompanyBranchId = this.companyBranches[0].uuid;
        this.getTasksList();
      }
      this.selectedCompanyBranchName = this.companyBranches.find(item => item.uuid === this.selectedCompanyBranchId).name;
    });

    const filterParams: RequestParams = {
      page: 1,
      limit: 1000,
      ['userType']: UserTypes.Sales
    };

    this.usersService.getUsersList(filterParams).subscribe((response: UsersListResponse) => {
      this.salesList = response.users;
      const filter_all_sales = this.translateService.instant('CALENDAR.ALL_SELS_FITER');
      this.salesList.unshift({uuid: '0', name: filter_all_sales, surname: ''} as User);
    });

    this.taskStatusesList.push({display: 'ALL', value: 'all'});
    Object.keys(this.taskStatuses).forEach(key => {
      this.taskStatusesList.push({
        display: key.toUpperCase()
        , value: this.taskStatuses[key]
      });
    });
  }

  public dayClicked(day: CalendarMonthViewDay) {
    const date: Date = day.date;
    const tasks: CalendarEvent[] = day.events;

    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        tasks.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
        this.viewDate = date;
      }
    }
    this.selectedDay = day;
    this.refresh.next();
  }

  public toggleDayBlocked(day: Date) {
    if (
      (this.user.userType === this.userTypes.Logistician &&
        this.user.roleType === this.roleTypes.Admin) || this.user.userType === this.userTypes.Manager
    ) {
      const index = this.taskWarnings.findIndex(
        warning =>
          warning.startDate.getUTCDate() >= day.getDate() &&
          warning.endDate.getUTCDate() <= day.getDate()
      );
      if (index > -1) {
        this.deleteTaskWarning(this.taskWarnings[index].uuid);
      } else {
        this.createTaskWarning(day);
      }
    }
  }

  private createTaskWarning(day) {
    const confirmDialogRef = this.matDialog.open(ConfirmModalComponent, {
      disableClose: false,
      autoFocus: false
    });
    confirmDialogRef.componentInstance.confirmMessage = this.translateService.instant(
      'CALENDAR.BLOCK_DAY_MESSAGE'
    );
    confirmDialogRef.componentInstance.titleMessage = this.translateService.instant(
      'CALENDAR.BLOCK_DAY_TITLE'
    );
    confirmDialogRef.componentInstance.confirmButton = this.translateService.instant(
      'CALENDAR.BLOCK_DAY_BUTTON'
    );

    const subscription = confirmDialogRef.afterClosed().subscribe(result => {
      subscription.unsubscribe();
      if (result) {
        const startDate = `${moment(day).format('YYYY-MM-DD HH:mm:ss')}`;
        const endDate = `${moment(day)
          .add(23, 'h')
          .add(59, 'm')
          .format('YYYY-MM-DD HH:mm:ss')}`;

        this.taskWarningService
          .createTaskWarning({
            companyBranchId: this.selectedCompanyBranchId,
            startDate,
            endDate
          })
          .subscribe(
            () => {
              this.snackBar.open(
                this.translateService.instant('CALENDAR.BLOCK_DAY_SUCCESS'),
                '',
                {duration: 5000}
              );
              this.getWarningsList();
            },
            () => {
              this.snackBar.open(
                this.translateService.instant('CALENDAR.BLOCK_DAY_ERROR'),
                '',
                {duration: 5000}
              );
            }
          );
      }
    });
  }

  private deleteTaskWarning(taskWarningId) {
    const confirmDialogRef = this.matDialog.open(ConfirmModalComponent, {
      disableClose: false,
      autoFocus: false
    });
    confirmDialogRef.componentInstance.confirmMessage = this.translateService.instant(
      'CALENDAR.UNBLOCK_DAY_MESSAGE'
    );
    confirmDialogRef.componentInstance.titleMessage = this.translateService.instant(
      'CALENDAR.UNBLOCK_DAY_TITLE'
    );
    confirmDialogRef.componentInstance.confirmButton = this.translateService.instant(
      'CALENDAR.UNBLOCK_DAY_BUTTON'
    );

    const subscription = confirmDialogRef.afterClosed().subscribe(result => {
      subscription.unsubscribe();
      if (result) {
        this.taskWarningService.deleteTaskWarning(taskWarningId).subscribe(
          () => {
            this.snackBar.open(
              this.translateService.instant('CALENDAR.UNBLOCK_DAY_SUCCESS'),
              '',
              {duration: 5000}
            );
            this.getWarningsList();
          },
          () => {
            this.snackBar.open(
              this.translateService.instant('CALENDAR.UNBLOCK_DAY_ERROR'),
              '',
              {duration: 5000}
            );
          }
        );
      }
    });
  }

  public beforeMonthViewRender(
    renderEvent: CalendarMonthViewBeforeRenderEvent
  ): void {
    renderEvent.body.forEach(day => {
      const dayOfMonth = day.date.getDate();
      this.taskWarnings.forEach(warning => {
        if (
          dayOfMonth >= warning.startDate.getUTCDate() &&
          dayOfMonth <= warning.endDate.getUTCDate() &&
          day.inMonth
        ) {
          day.cssClass = 'blocked-day';
        }
      });
    });
  }

  public beforeWeekViewRender(renderEvent: CalendarWeekViewBeforeRenderEvent) {
    renderEvent.hourColumns.forEach(hourColumn => {
      hourColumn.hours.forEach(hour => {
        hour.segments.forEach(segment => {
          this.taskWarnings.forEach(warning => {
            if (
              segment.date.getUTCDate() >= warning.startDate.getUTCDate() &&
              segment.date.getUTCDate() <= warning.endDate.getUTCDate()
            ) {
              segment.cssClass = 'blocked-day';
            }
          });
        });
      });
    });
  }

  public beforeDayViewRender(renderEvent: CalendarDayViewBeforeRenderEvent) {
    renderEvent.body.hourGrid.forEach(hour => {
      hour.segments.forEach((segment, index) => {
        this.taskWarnings.forEach(warning => {
          if (
            segment.date.getUTCDate() >= warning.startDate.getUTCDate() &&
            segment.date.getUTCDate() <= warning.endDate.getUTCDate()
          ) {
            segment.cssClass = 'blocked-day';
          }
        });
      });
    });
  }

  public addTask(date?: Date) {
    if (
      this.user.userType === this.userTypes.Logistician &&
      this.selectedCompanyBranchId
    ) {
      const start = date || null;
      const end = date
        ? new Date(new Date(start).setHours(start.getHours() + 1))
        : '';
      const startDate = start ? moment(start).format('YYYY-MM-DD') : null;
      const startTime = start ? moment(start).format('HH:mm') : null;
      const endDate = end ? moment(end).format('YYYY-MM-DD') : null;
      let endTime = end ? moment(end).format('HH:mm') : null;

      if (startTime === '16:30') {
        endTime = '17:00';
      }

      this.matDialog.open(TaskTypePickerComponent, {
        panelClass: 'task-type-picker',
        autoFocus: false,
        disableClose: true,
        data: {
          mode: 'add',
          startDate: startDate,
          endDate: endDate,
          startTime: startTime,
          endTime: endTime,
          companyBranchId: this.selectedCompanyBranchId
        }
      });
    }
  }

  public editTask(task: CalendarTaskModel) {

    let editTaskDialogRef;

    if (this.user.userType === this.userTypes.Logistician || this.user.userType === this.userTypes.Sales || this.user.userType === this.userTypes.Manager) {
      if (task.type === 'export') {
        editTaskDialogRef = this.matDialog.open(TaskExportFormComponent, {
          panelClass: 'task-editor-dialog',
          autoFocus: false,
          disableClose: true,
          data: {
            mode: 'view',
            taskId: task.uuid
          }
        });
      } else if (task.type === 'import') {
        editTaskDialogRef = this.matDialog.open(TaskImportFormComponent, {
          panelClass: 'task-editor-dialog',
          autoFocus: false,
          disableClose: true,
          data: {
            mode: 'view',
            taskId: task.uuid
          }
        });
      } else if (task.type === 'receiving') {
        editTaskDialogRef = this.matDialog.open(TaskReceivingFormComponent, {
          panelClass: 'task-editor-dialog',
          autoFocus: false,
          disableClose: true,
          data: {
            mode: 'view',
            taskId: task.uuid
          }
        });
      }
      const subscription = editTaskDialogRef.afterClosed().subscribe(() => {
        // workaround for displaying tooltips after closing edit task modal
        if (this.calendarComponent) {
          this.calendarComponent.dragActive = false;
        }
        if (this.additionalCalendarComponent) {
          this.additionalCalendarComponent.dragActive = false;
        }
        subscription.unsubscribe();
      });

    }
  }

  public updateOnDragOrResize(task: CalendarUpdatedTaskModel) {
    const isSameDay = moment(task.newStart).isSame(task.newEnd, 'day');
    const isSameStartAndEnd =
      moment(task.newStart).isSame(task.event.start, 'minute') &&
      moment(task.newEnd).isSame(task.event.end, 'minute');

    if (
      this.user.userType === this.userTypes.Logistician &&
      isSameDay &&
      !isSameStartAndEnd
    ) {
      const confirmDialogRef = this.matDialog.open(ConfirmModalComponent, {
        disableClose: false,
        autoFocus: false
      });
      confirmDialogRef.componentInstance.confirmMessage = this.translateService.instant(
        'TASKS.MESSAGES.UPDATE_QUESTION'
      );
      confirmDialogRef.componentInstance.titleMessage = this.translateService.instant(
        'TASKS.MESSAGES.UPDATE_TASK_TITLE'
      );
      confirmDialogRef.componentInstance.confirmButton = this.translateService.instant(
        'TASKS.UPDATE'
      );

      const subscription = confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          const startDate = task.newStart
            ? `${moment.utc(task.newStart).format('YYYY-MM-DD HH:mm:ss')}`
            : null;
          const endDate = task.newEnd
            ? `${moment.utc(task.newEnd).format('YYYY-MM-DD HH:mm:ss')}`
            : null;

          subscription.unsubscribe();

          this.tasksService
            .updateTaskDate(task.event.uuid, startDate, endDate)
            .subscribe(
              () => {
                this.tasksService.onEditEvent.emit();
                this.snackBar.open(
                  this.translateService.instant(
                    'TASKS.MESSAGES.UPDATE_SUCCESS'
                  ),
                  '',
                  {duration: 5000}
                );
              },
              () => {
                this.snackBar.open(
                  this.translateService.instant('TASKS.MESSAGES.UPDATE_ERROR'),
                  '',
                  {duration: 5000}
                );
              }
            );
        }
      });
    }
    this.refresh.next();
  }

  public changeCompanyBranch(companyId: string) {
    this.selectedCompanyBranchId = companyId;
    this.selectedCompanyBranchName = this.companyBranches.find(branch => branch.uuid === companyId).name;
    this.getTasksList();
  }

  public excludeDays() {
    if (this.viewName === 'workweek') {
      return [0, 6];
    }
    return [];
  }

  public clearSearch() {
    if (this.searchTask.value) {
      this.searchTask.setValue('');
    }
  }

  public changeCalendarView(view: string, viewName: string) {
    this.view = view;
    this.viewName = viewName;
    for (const key in this.additionalCalendarView) {
      this.additionalCalendarView[key] = false;
    }
    for (const key in this.mainCalendarView) {
      this.mainCalendarView[key] = true;
    }
    for (const key in this.tasksTabsActive) {
      this.tasksTabsActive[key] = true;
    }
    this.calendarSplitView = false;
    this.getTasksList();
  }

  public changeDate(event: Date) {
    this.selectedDay.date = event;
    this.getTasksList();
  }

  public splitCalendarView(type: string) {
    if (this.selectedCompanyBranchId) {
      for (const key in this.additionalCalendarView) {
        if (this.additionalCalendarView[key]) {
          this.additionalCalendarView[key] = false;
          this.mainCalendarView[key] = true;
          this.tasksTabsActive[key] = true;
        }
      }
      this.mainCalendarView[type] = false;
      this.additionalCalendarView[type] = true;
      this.calendarSplitView = true;
      this.setCalendarsTasks();
    }
  }

  public mergeCalendarView(type: string) {
    this.mainCalendarView[type] = true;
    this.additionalCalendarView[type] = false;
    this.tasksTabsActive[type] = true;
    this.calendarSplitView = false;
    this.setCalendarsTasks();
  }

  public toggleActiveTaskTab(type: string) {
    if (this.selectedCompanyBranchId) {
      this.tasksTabsActive[type] = !this.tasksTabsActive[type];
      this.setCalendarsTasks();
    }
  }

  public getDayTooltipMessage(events: CalendarTaskModel[]) {
    let exportNum = 0;
    let importNum = 0;
    let receivingNum = 0;

    events.length &&
    events.forEach(event => {
      if (event.type === 'export' && event.status !== 'cancelled') {
        exportNum++;
      } else if (event.type === 'import' && event.status !== 'cancelled') {
        importNum++;
      } else if (event.type === 'receiving' && event.status !== 'cancelled') {
        receivingNum++;
      }
    });
    return `${this.translateService.instant(
      'CALENDAR.TASK_TYPES.EXPORT'
    )}: ${exportNum} 
    ${this.translateService.instant(
      'CALENDAR.TASK_TYPES.IMPORT'
    )}: ${importNum} 
    ${this.translateService.instant(
      'CALENDAR.TASK_TYPES.RECEIVING'
    )}: ${receivingNum}`;
  }

  public getDayTasksCount(events: CalendarTaskModel[]) {
    let dayTasksCount = 0;
    events.length &&
    events.forEach(event => {
      if (event.status !== 'cancelled') {
        dayTasksCount++;
      }
    });
    return dayTasksCount;
  }

  public getOrderTotalWeight(recyclingItems) {
    let totalWeight = 0;
    recyclingItems.forEach(item => {
      totalWeight += item.value;
    });
    return totalWeight;
  }

  private getCalendarSummary = () => {
    const date = moment(this.getRequestParams().dateFrom).startOf('month').format('YYYY-MM-DD');
    const endWeekDate = moment(this.getRequestParams().dateTo).startOf('month').format('YYYY-MM-DD');
    if (moment(date).month() !== moment(endWeekDate).month()) {
      zip(this.tasksService.getTaskCalendarSummary(date, this.selectedCompanyBranchId),
        this.tasksService.getTaskCalendarSummary(endWeekDate, this.selectedCompanyBranchId)).subscribe(
        ([res, resNextMonth]) => {
          this.dailySummary = {...res.response, ...resNextMonth.response};
          this.cumulativelySummary = {...res.cumulativelyResponse, ...resNextMonth.cumulativelyResponse};
          this.totalResponse = {
            income: {
              [moment(date).month()]: res.totalIncome,
              [moment(endWeekDate).month()]: resNextMonth.totalIncome
            },
            outcome: {
              [moment(date).month()]: res.totalOutcome,
              [moment(endWeekDate).month()]: resNextMonth.totalOutcome
            }
          };
        });
    } else {
      this.tasksService.getTaskCalendarSummary(date, this.selectedCompanyBranchId).subscribe(
        res => {
          this.dailySummary = res.response;
          this.cumulativelySummary = res.cumulativelyResponse;
          this.totalResponse = {
            income: {
              [moment(date).month()]: res.totalIncome
            },
            outcome: {
              [moment(date).month()]: res.totalOutcome
            }
          };
        });
    }
  }

  private getTasksList() {
    this.getCalendarSummary();
    const requestParams = this.getRequestParams();
    this.lastRequestParams = requestParams;
    this.selectedCompanyBranchId &&
    this.tasksService.getTasksList(requestParams).subscribe(res => {
      this.tasks = res.tasks.map(item => {
        return new CalendarTaskModel(this.user, item);
      });
      this.setCalendarsTasks();
    });
    this.getWarningsList();
    this.getNotesList();
  }

  public getNotesList() {
    this.noteCalendarService.getNoteList(this.getRequestParams()).subscribe(res => {
      this.noteList = res.calendarNotes.map(item => ({
        date: item.date,
        note: item.note
      }));
      this.refresh.next();
    });
  }

  private getWarningsList() {
    this.taskWarningService
      .getTaskWarningList(this.getRequestParams())
      .subscribe(res => {
        this.taskWarnings = res.taskWarning.map(warning => ({
          startDate: new Date(warning.startDate),
          endDate: new Date(warning.endDate),
          name: warning.name,
          uuid: warning.uuid
        }));
        this.refresh.next();
      });
  }

  private setCalendarsTasks() {
    this.mainCalendarTasks = this.tasks.filter(
      task =>
        task.status !== 'cancelled'
          ? this.mainCalendarView[task.type] && this.tasksTabsActive[task.type]
          : this.mainCalendarView[task.status] &&
          this.tasksTabsActive[task.status]
    );
    this.additionalCalendarTasks = this.tasks.filter(
      task =>
        task.status !== 'cancelled'
          ? this.additionalCalendarView[task.type] &&
          this.tasksTabsActive[task.type]
          : this.additionalCalendarView[task.status] &&
          this.tasksTabsActive[task.status]
    );
    this.refresh.next();
  }

  private getRequestParams() {
    const filterParams: RequestParams = {
      page: 1,
      limit: 1000
    };
    if (this.searchTaskNameNumber) {
      filterParams.search = this.searchTaskNameNumber;
    }
    if (this.selectedCompanyBranchId) {
      filterParams.companyBranchId = this.selectedCompanyBranchId;
    }
    if (this.selectedSalesUserUuid) {
      filterParams['salesId'] = this.selectedSalesUserUuid;
    }
    if (this.selectedStatus) {
      filterParams['status'] = this.selectedStatus.join(',');
    }
    switch (this.viewName) {
      case 'day':
        filterParams.dateFrom = moment(this.selectedDay.date).format(
          'YYYY-MM-DD'
        );
        filterParams.dateTo = moment(this.selectedDay.date)
          .add(1, 'd')
          .format('YYYY-MM-DD');
        break;
      case 'week':
        filterParams.dateFrom = moment(this.selectedDay.date)
          .startOf('isoWeek')
          .add(0, 'd')
          .format('YYYY-MM-DD');
        filterParams.dateTo = moment(this.selectedDay.date)
          .endOf('isoWeek')
          .add(4, 'day')
          .format('YYYY-MM-DD');
        break;
      case 'month':
        filterParams.dateFrom = moment(this.selectedDay.date)
          .startOf('month')
          .format('YYYY-MM-DD');
        filterParams.dateTo = moment(this.selectedDay.date)
          .endOf('month')
          .add(1, 'd')
          .format('YYYY-MM-DD');
        break;
      default:
        filterParams.dateFrom = moment(this.selectedDay.date)
          .startOf('isoWeek')
          .add(0, 'd')
          .format('YYYY-MM-DD');
        filterParams.dateTo = moment(this.selectedDay.date)
          .startOf('isoWeek')
          .add(4, 'd')
          .format('YYYY-MM-DD');
    }
    return filterParams;
  }

  private createSearchFormControl() {
    this.searchTask = new FormControl('');
    this.searchTask.valueChanges.debounceTime(300).subscribe(value => {
      this.searchTaskNameNumber = value;
      this.disableAnimations = true;
      this.getTasksList();
    });
  }

  public getDescriptionForAdditionalFieldInGivenOneDay() {
    const givenDay = moment(this.selectedDay.date).format('YYYY-MM-DD');
    return this.noteList.find(note => moment(note.date).format('YYYY-MM-DD') === givenDay);
  }

  public getDescriptionForAdditionalFieldInGivenDayOfWeek(dayOfWeek: number): Description {
    const givenDay = moment(this.selectedDay.date)
      .startOf('week')
      .add(dayOfWeek + 1, 'd')
      .format('YYYY-MM-DD');
    return this.noteList.find(note => moment(note.date).format('YYYY-MM-DD') === givenDay);
  }

  public salesFilterChanged(event) {
    if (event.value === "0") {
      (event.source as MatSelect).value = null;
      this.selectedSalesUserUuid = null;
    } else {
      this.selectedSalesUserUuid = event.value;
    }
    this.getTasksList();
  }

  public statusFilterChanged(event) {
    const allItems = this.taskStatusesList.filter(item => item.value !== 'all').map(item => item.value);
    if (event.value.includes('all') && (event.source as MatSelect).value.length === ['all', ...allItems].length) {
      (event.source as MatSelect).value = null;
      this.selectedStatus = null;
    } else if (event.value.includes('all')) {
      (event.source as MatSelect).value = allItems;
      this.selectedStatus = allItems;
    } else {
      this.selectedStatus = event.value;
    }
    this.getTasksList();
  }

  public getSummaryObject(diffBetweenStartOfWeek, daily = false) {
    const baseDate = this.viewName !== 'day' ? moment(this.selectedDay.date).startOf('week').format('YYYY-MM-DD')
      : moment(this.selectedDay.date).format('YYYY-MM-DD');
    const date = moment(baseDate).add(diffBetweenStartOfWeek, 'days').format('YYYY-MM-DD');
    if (daily) {
      return this.dailySummary[date];
    } else {
      return this.cumulativelySummary[date];
    }
  }
}
