import { EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./auth.service";
var TransportService = /** @class */ (function () {
    function TransportService(http, authService) {
        this.http = http;
        this.authService = authService;
        this.onEditEvent = new EventEmitter();
    }
    TransportService.prototype.getTransportList = function (params) {
        return this.http.get(environment.apiUrl + "/" + this.authService.getUserType() + "/transport", { params: params })
            .pipe(map(function (res) { return res; }));
    };
    TransportService.prototype.getTransport = function (transportUuid) {
        return this.http.get(environment.apiUrl + "/" + this.authService.getUserType() + "/transport/" + transportUuid).pipe(map(function (res) { return res.transport; }));
    };
    TransportService.prototype.createTransport = function (transport) {
        return this.http.post(environment.apiUrl + "/" + this.authService.getUserType() + "/transport", transport).pipe(map(function (res) { return res.transport; }));
    };
    TransportService.prototype.updateTransport = function (transportUuid, transport) {
        return this.http.put(environment.apiUrl + "/" + this.authService.getUserType() + "/transport/" + transportUuid, transport);
    };
    TransportService.prototype.deleteTransport = function (transportUuid) {
        return this.http.delete(environment.apiUrl + "/" + this.authService.getUserType() + "/transport/" + transportUuid);
    };
    TransportService.ngInjectableDef = i0.defineInjectable({ factory: function TransportService_Factory() { return new TransportService(i0.inject(i1.HttpClient), i0.inject(i2.AuthService)); }, token: TransportService, providedIn: "root" });
    return TransportService;
}());
export { TransportService };
