import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {RequestParams} from '../shared/models/list.model';
import {AuthService} from './auth.service';
import {RecurringTaskRequest, Task, TasksListResponse} from '../shared/models/tasks.model';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {Order} from '../shared/models/orders.model';
import {TaskHistoryListResponse} from '../shared/models/history.model';

@Injectable({
    providedIn: 'root'
})
export class TasksService {
    onEditEvent: EventEmitter<any> = new EventEmitter();

    constructor(private http: HttpClient, private authService: AuthService) {
    }

    getTasksList(params: RequestParams): Observable<TasksListResponse> {
        return this.http.get(`${environment.apiUrl}/${this.authService.getUserType()}/tasks`, {params: params})
            .pipe(map((res: TasksListResponse) => res));
    }

    getTasksListByDay(date: string, companyBranchId: any): Observable<any> {
        const params = {
            dateFrom: date,
            dateTo: date,
            companyBranchId
        };
        return this.http.get(`${environment.apiUrl}/${this.authService.getUserType()}/tasks`, {params})
            .pipe(map((res: TasksListResponse) => res));
    }

    getTaskCalendarSummary(date: string, companyBranchId?: any): Observable<any> {
        const params = {
            date,
            companyBranchId
        };
        return this.http.get(`${environment.apiUrl}/${this.authService.getUserType()}/tasks/calendar_summary`, {params})
            .pipe(map((res: any) => res));
    }

    getTask(taskId: Task['uuid'] | Task['taskNumber']): Observable<any> {
        const id = taskId.replace(/\//g, '-');
        return this.http.get(`${environment.apiUrl}/${this.authService.getUserType()}/tasks/${id}`).pipe(
            map((res: any) => res.task)
        );
    }

    getTaskHistory(taskTypeUuid: Task['uuid'], params: RequestParams): Observable<any>  {
        return this.http.get<TaskHistoryListResponse>(`${environment.apiUrl}/${this.authService.getUserType()}/tasks/${taskTypeUuid}/history`,
            {params: params});
    }

    getTasksExcel(params: RequestParams): any {
        return this.http.get(`${environment.apiUrl}/${this.authService.getUserType()}/tasks/download-view`,
          {params: params, responseType: 'blob'});
    }

    createTask(task: Task): Observable<Task> {
        return this.http.post(`${environment.apiUrl}/${this.authService.getUserType()}/tasks`, task).pipe(
            map((res: any) => res.task)
        );
    }

    createRecurringTask(task: RecurringTaskRequest): Observable<Task> {
        return this.http.post(`${environment.apiUrl}/${this.authService.getUserType()}/tasks/cycle`, task).pipe(
            map((res: any) => res.task)
        );
    }

    updateTask(taskId: Task['uuid'], task: Task): Observable<Task> {
        return this.http.put(`${environment.apiUrl}/${this.authService.getUserType()}/tasks/${taskId}`, task).pipe(
            map((res: any) => res.task)
        );
    }

    updateTaskDate(taskId: Task['uuid'], startDate: string, endDate: string): Observable<Task> {
        return this.http.post(`${environment.apiUrl}/${this.authService.getUserType()}/tasks/${taskId}`,
            {startDate, endDate}).pipe(
            map((res: any) => res.task)
        );
    }

    updateTaskOrders(taskId: Task['uuid'], orderIds: Order['uuid'][]) {
            return this.http.post<any>(`${environment.apiUrl}/${this.authService.getUserType()}/tasks/${taskId}/orderCancelled`, {orderIds: orderIds});
    }

    deleteTask(taskId: Task['uuid']) {
        return this.http.delete<any>(`${environment.apiUrl}/${this.authService.getUserType()}/tasks/${taskId}`);
    }

    cancelTask(taskId: Task['uuid']) {
        return this.http.put<any>(`${environment.apiUrl}/${this.authService.getUserType()}/tasks/${taskId}/cancelled`, {})
            .pipe(map((res: any) => res.task));
    }

    completeTask(taskId: Task['uuid']) {
        return this.http.put<any>(`${environment.apiUrl}/${this.authService.getUserType()}/tasks/${taskId}/complete`, {})
            .pipe(map((res: any) => res.task));
    }


    passInvoiceNumberToTasks(data) {
        return this.http.put<any>(`${environment.apiUrl}/${this.authService.getUserType()}/tasks/invoices`, data)
            .pipe(map((res: any) => res.task));
    }
}