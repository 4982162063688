<mat-accordion>
  <mat-expansion-panel *ngFor="let item of recyclingItems">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <strong>{{item.recyclingItem.name}}</strong>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <p class="top-paragraph">{{'HISTORY.HISTORY_KEYS.value' | translate}} <strong>{{item.value}} </strong></p>
    <p>{{'HISTORY.HISTORY_KEYS.unit' | translate}} <strong>{{'HISTORY.HISTORY_KEYS.' + item.unit | translate}}</strong>
    </p>
    <p>{{'HISTORY.HISTORY_KEYS.weight' | translate}}
      <strong>{{item.weight}} {{'HISTORY.HISTORY_KEYS.kg' | translate}}</strong></p>
    <mat-divider></mat-divider>
    <h4>
      <strong>{{(item.packageTypes.length === 1 ? 'HISTORY.HISTORY_KEYS.packageType' : 'HISTORY.HISTORY_KEYS.packageTypes') | translate}}</strong>
    </h4>
    <app-package-types-display [packageTypes]="item.packageTypes"></app-package-types-display>
  </mat-expansion-panel>
</mat-accordion>