import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import * as i0 from "@angular/core";
import * as i1 from "../auth.service";
import * as i2 from "@angular/router";
var AuthGuard = /** @class */ (function () {
    function AuthGuard(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    AuthGuard.prototype.canActivate = function (next, state) {
        var loggedUser = this.authService.user;
        var userTypes = next.data.userTypes || [];
        if (!loggedUser['xsrfToken']) {
            this.router.navigate(['/login'], { replaceUrl: true });
        }
        else if (userTypes.length > 0 && !userTypes.includes(loggedUser['user']['userType'])) {
            this.router.navigate(['/no-access'], { replaceUrl: true });
        }
        else {
            return true;
        }
    };
    AuthGuard.ngInjectableDef = i0.defineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.inject(i1.AuthService), i0.inject(i2.Router)); }, token: AuthGuard, providedIn: "root" });
    return AuthGuard;
}());
export { AuthGuard };
