import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ErrorHandler, NgModule } from "@angular/core";
import {
  MatButtonModule,
  MatIconModule,
  MatPaginatorIntl,
} from "@angular/material";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import "hammerjs";

import {
  FuseProgressBarModule,
  FuseSidebarModule,
  FuseThemeOptionsModule,
} from "@fuse/components";
import { FuseModule } from "@fuse/fuse.module";
import { FuseSharedModule } from "@fuse/shared.module";

import { fuseConfig } from "app/fuse-config";

import * as Sentry from "@sentry/browser";
import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import { CookieModule } from "ngx-cookie";
import { environment } from "../environments/environment";
import { AppRoutes } from "./app.routes";
import { CalendarModule } from "./modules/calendar/calendar.module";
import { CompaniesModule } from "./modules/companies/companies.module";
import { CompanyAccountsModule } from "./modules/company-accounts/company-accounts.module";
import { CompanyBranchesModule } from "./modules/company-branches/company-branches.module";
import { CompanyKpoModule } from "./modules/company-kpo/company-kpo.module";
import { FractionsModule } from "./modules/fractions/fractions.module";
import { OrdersModule } from "./modules/orders/orders.module";
import { PackageTypesModule } from "./modules/package-types/package-types.module";
import { RecyclingItemsModule } from "./modules/recycling-items/recycling-items.module";
import { ReportsModule } from "./modules/reports/reports.module";
import { SharedModule } from "./modules/shared/shared.module";
import { TagsModule } from "./modules/tags/tags.module";
import { TransportModule } from "./modules/transport/transport.module";
import { UsersModule } from "./modules/users/users.module";
import { SentryErrorHandler } from "./services/sentry.service";
import { TokenInterceptor } from "./shared/interceptors/token-interceptor";
import { MatPaginatorI18n } from "./shared/utils/mat-paginator-i18n";

if (environment.sentry) {
  Sentry.init({
    dsn: "https://dcf2887973f544d597723612871aba5b@sentry.io/1434671",
  });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(AppRoutes),
    CookieModule.forRoot(),

    TranslateModule.forRoot(),

    // Material moment date module
    MatMomentDateModule,

    // Material
    MatButtonModule,
    MatIconModule,

    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,

    // App modules
    LayoutModule,
    CalendarModule,
    SharedModule,
    CompaniesModule,
    CompanyAccountsModule,
    CompanyKpoModule,
    CompanyBranchesModule,
    UsersModule,
    OrdersModule,
    TransportModule,
    FractionsModule,
    TagsModule,
    RecyclingItemsModule,
    PackageTypesModule,
    ReportsModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: MatPaginatorIntl,
      deps: [TranslateService],
      useFactory: (translateService: TranslateService) =>
        new MatPaginatorI18n(translateService).getPaginatorIntl(),
    },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
  ],
})
export class AppModule {}
