import {NgModule} from '@angular/core';
import {TasksContainerComponent} from './tasks-container.component';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {FuseSharedModule} from '../../../@fuse/shared.module';
import {Routes} from './tasks.routes';
import {TaskExportFormComponent} from './components/task-forms/task-export-form/task-export-form.component';
import {TaskTypePickerComponent} from './components/task-type-picker/task-type-picker.component';
import {TaskImportFormComponent} from './components/task-forms/task-import-form/task-import-form.component';
import {TaskReceivingFormComponent} from './components/task-forms/task-receiving-form/task-receiving-form.component';
import {MatAutocompleteModule, MatButtonModule, MatInputModule, MatStepperModule} from '@angular/material';
import {TasksListComponent} from './components/tasks-list/tasks-list.component';
import {TaskInvoiceModalComponent} from "./components/task-invoice-modal/task-invoice-modal.component";

@NgModule({
  declarations: [
    TasksContainerComponent,
    TaskTypePickerComponent,
    TaskExportFormComponent,
    TaskImportFormComponent,
    TaskReceivingFormComponent,
    TaskInvoiceModalComponent,
    TasksListComponent
  ],
  imports: [
    RouterModule.forChild(Routes),
    SharedModule,
    TranslateModule,
    MatStepperModule,
    MatInputModule,
    MatButtonModule,
    MatAutocompleteModule,
    FuseSharedModule
  ],
  exports: [
    TaskTypePickerComponent,
    TaskExportFormComponent,
    TaskImportFormComponent,
    TaskInvoiceModalComponent,
    TaskReceivingFormComponent,
  ],
  entryComponents: [
    TaskTypePickerComponent,
    TaskExportFormComponent,
    TaskImportFormComponent,
    TaskInvoiceModalComponent,
    TaskReceivingFormComponent,
  ]
})
export class TasksModule { }
