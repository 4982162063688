/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./day-events-summary.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./day-events-summary.component";
import * as i5 from "../../../../services/tasks.service";
var styles_DayEventsSummaryComponent = [i0.styles];
var RenderType_DayEventsSummaryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DayEventsSummaryComponent, data: {} });
export { RenderType_DayEventsSummaryComponent as RenderType_DayEventsSummaryComponent };
function View_DayEventsSummaryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", ": ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_v.context.$implicit.name)); var currVal_1 = _co.getTransportCategoryCount(_v.context.$implicit.value); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_DayEventsSummaryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "categories-summary-cell"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "column"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DayEventsSummaryComponent_1)), i1.ɵdid(3, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "column summary"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, [" ", ": ", " / ", " / ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(8, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", ": ", " / ", " / ", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.transportCategories; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("CALENDAR.TASK_TYPES.IMPORT_SHORT")); var currVal_2 = _co.getValue("income", false); var currVal_3 = _co.getValue("income", true); var currVal_4 = _co.getTotal("income"); _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform("CALENDAR.TASK_TYPES.EXPORT_SHORT")); var currVal_6 = _co.getValue("outcome", false); var currVal_7 = _co.getValue("outcome", true); var currVal_8 = _co.getTotal("outcome"); _ck(_v, 9, 0, currVal_5, currVal_6, currVal_7, currVal_8); }); }
export function View_DayEventsSummaryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-day-events-summary", [], null, null, null, View_DayEventsSummaryComponent_0, RenderType_DayEventsSummaryComponent)), i1.ɵdid(1, 49152, null, 0, i4.DayEventsSummaryComponent, [i5.TasksService], null, null)], null, null); }
var DayEventsSummaryComponentNgFactory = i1.ɵccf("app-day-events-summary", i4.DayEventsSummaryComponent, View_DayEventsSummaryComponent_Host_0, { events: "events", diffBetweenStartOfWeek: "diffBetweenStartOfWeek", selectedDate: "selectedDate", calendarView: "calendarView", dailySummary: "dailySummary", cumulativelySummary: "cumulativelySummary", totalSummary: "totalSummary" }, {}, []);
export { DayEventsSummaryComponentNgFactory as DayEventsSummaryComponentNgFactory };
