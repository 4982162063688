import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { CompanyBranchEditorComponent } from './components/company-branch-editor/company-branch-editor.component';

@NgModule({
  declarations: [
    CompanyBranchEditorComponent
  ],
  imports: [
    SharedModule,
  ],
  exports: [
    CompanyBranchEditorComponent
  ],
  entryComponents: [
    CompanyBranchEditorComponent
  ]
})
export class CompanyBranchesModule { }
