<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">{{getTitle()}}</span>
      <button mat-icon-button (click)="companyBranchEditorDialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>
    <form [formGroup]="companyBranchForm">
      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'COMPANY_BRANCHES.NAME' | translate}}</mat-label>
          <input formControlName="name" matInput required [attr.data-cy]="'companyBranchName'">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'COMPANY_BRANCHES.STREET' | translate}}</mat-label>
          <input formControlName="street" matInput required [attr.data-cy]="'companyBranchStreet'">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'COMPANY_BRANCHES.POSTAL_CODE' | translate}}</mat-label>
          <input formControlName="postalCode" matInput required [attr.data-cy]="'companyBranchPostalCode'">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'COMPANY_BRANCHES.CITY' | translate}}</mat-label>
          <input formControlName="city" matInput required [attr.data-cy]="'companyBranchCity'">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'COMPANY_BRANCHES.COUNTRY' | translate}}</mat-label>
          <input formControlName="country" matInput required [attr.data-cy]="'companyBranchCountry'">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'COMPANY_BRANCHES.CONTACT' | translate}}</mat-label>
          <input formControlName="contact" matInput [attr.data-cy]="'companyBranchContact'">
        </mat-form-field>
      </div>

      <div class="input-wrapper" fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'COMPANY_BRANCHES.PHONE' | translate}}</mat-label>
          <input formControlName="phone" matInput required [attr.data-cy]="'companyBranchPhone'">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'COMPANY_BRANCHES.EMAIL' | translate}}</mat-label>
          <input type="email" formControlName="email" matInput [attr.data-cy]="'companyBranchEmail'">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'COMPANY_BRANCHES.NOTE' | translate}}</mat-label>
          <textarea formControlName="note" matInput [attr.data-cy]="'companyBranchNote'"></textarea>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
          <mat-checkbox formControlName="mainBranch" [disabled]="(mode ==='edit' && companyBranch && companyBranch.mainBranch) || mode==='view'" [attr.data-cy]="'companyBranchMain'">
          {{'COMPANY_BRANCHES.MAIN_BRANCH' | translate}}
          </mat-checkbox>
      </div>
    </form>


  </div>

  <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

    <button *ngIf="mode === 'edit' && !companyBranchForm.get('mainBranch').value"
            mat-button
            class="mr-8"
            [attr.data-cy]="'companyBranchDelete'"
            (click)="deleteCompanyBranch()">
      {{'COMPANY_BRANCHES.DELETE' | translate}}
    </button>

    <button *ngIf="mode === 'view'"
            mat-button
            class="mr-8"
            [attr.data-cy]="'companyBranchEdit'"
            (click)="enableEditMode()">
      {{'COMPANY_BRANCHES.EDIT' | translate}}
    </button>

    <button *ngIf="mode === 'edit' || mode === 'add'"
            mat-button
            class="save-button"
            [attr.data-cy]="'companyBranchSave'"
            (click)="submitCompanyBranch()"
            [disabled]="isLoading">
      <span *ngIf="!isLoading"> {{'COMPANY_BRANCHES.SAVE' | translate}}</span>
      <span *ngIf="isLoading" class="loading-spinner-wrapper">
        <mat-progress-spinner mode="indeterminate"
                              [strokeWidth]="2"
                              [diameter]="20">
        </mat-progress-spinner>
      </span>
    </button>

  </div>
</div>