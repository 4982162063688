<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">{{getTitle()}}</span>
      <button mat-icon-button (click)="companyAccountEditorDialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>
    <form [formGroup]="companyAccountForm">

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'COMPANY_ACCOUNTS.NAME' | translate}}</mat-label>
          <input formControlName="name" matInput required [attr.data-cy]="'companyAccountName'">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'COMPANY_ACCOUNTS.SURNAME' | translate}}</mat-label>
          <input formControlName="surname" matInput required [attr.data-cy]="'companyAccountSurname'">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'COMPANY_ACCOUNTS.EMAIL' | translate}}</mat-label>
          <input type="email" formControlName="email" matInput required [attr.data-cy]="'companyAccountEmail'">
        </mat-form-field>
      </div>

      <div class="input-wrapper" fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'COMPANY_ACCOUNTS.PHONE' | translate}}</mat-label>
          <input formControlName="phone" matInput required [attr.data-cy]="'companyAccountPhone'">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'COMPANY_ACCOUNTS.STATUS' | translate}}</mat-label>
          <mat-select formControlName="active" [attr.data-cy]="'companyAccountIsActive'">
            <mat-option [value]="true" [attr.data-cy]="'ACTIVE'">{{'COMPANY_ACCOUNTS.STATUSES.ACTIVE' | translate}}</mat-option>
            <mat-option [value]="false" [attr.data-cy]="'INACTIVE'">{{'COMPANY_ACCOUNTS.STATUSES.INACTIVE' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>

    <div *ngIf="mode === 'edit' && !changePassword"
         fxLayoutAlign="center start">
      <button mat-button
              class="mr-8"
              color="warn"
              (click)="toggleChangePassword()"
              [attr.data-cy]="'companyAccountOpenChangePassword'">
        {{'COMPANY_ACCOUNTS.PASSWORD.CHANGE_PASSWORD' | translate}}
      </button>
    </div>
    <div *ngIf="mode === 'edit' && changePassword"
         fxLayoutAlign="end start">
      <button mat-icon-button
              color="warn"
              (click)="toggleChangePassword()"
              [attr.data-cy]="'companyAccountCloseChangePassword'">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <form *ngIf="changePassword" [formGroup]="passwordForm">
      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'COMPANY_ACCOUNTS.PASSWORD.PASSWORD' | translate}}</mat-label>
          <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
          <input name="email" formControlName="password" type="password" matInput required [attr.data-cy]="'companyAccountPassword'">
          <mat-error
            *ngIf="passwordForm.get('password').touched && passwordForm.controls['password'].errors?.minlength">
            {{'COMPANY_ACCOUNTS.PASSWORD.PASSWORDS_MIN_CHARACTERS' | translate}}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'COMPANY_ACCOUNTS.PASSWORD.CONFIRM_PASSWORD' | translate}}</mat-label>
          <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
          <input name="name" formControlName="confirmPassword" type="password" matInput required [attr.data-cy]="'companyAccountConfirmPassword'">
          <mat-error
            *ngIf="passwordForm.get('confirmPassword').touched && passwordForm.controls['confirmPassword'].errors?.MatchPassword">
            {{'COMPANY_ACCOUNTS.PASSWORD.PASSWORDS_NOT_MATCH' | translate}}
          </mat-error>
        </mat-form-field>
      </div>
    </form>
  </div>

  <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

    <button *ngIf="mode === 'edit'"
            mat-button
            class="mr-8"
            (click)="deleteCompanyAccount()"
            [attr.data-cy]="'companyAccountDelete'">
      {{'COMPANY_ACCOUNTS.DELETE' | translate}}
    </button>

    <button *ngIf="mode === 'view'"
            mat-button
            class="mr-8"
            (click)="enableEditMode()"
            [attr.data-cy]="'companyAccountEdit'">
      {{'COMPANY_ACCOUNTS.EDIT' | translate}}
    </button>

    <button *ngIf="mode === 'edit' || mode === 'add'"
            mat-button
            class="save-button"
            (click)="submitCompanyAccount()"
            [attr.data-cy]="'companyAccountSave'"
            [disabled]="(changePassword && passwordForm.invalid) || isLoading">
      <span *ngIf="!isLoading"> {{'COMPANY_ACCOUNTS.SAVE' | translate}}</span>
      <span *ngIf="isLoading" class="loading-spinner-wrapper">
        <mat-progress-spinner mode="indeterminate"
                              [strokeWidth]="2"
                              [diameter]="20">
        </mat-progress-spinner>
      </span>
    </button>

  </div>
</div>