import { Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { OrdersService } from '../../../../services/orders.service';
import { locale as polish } from '../../../../translations/pl';
import { locale as english } from '../../../../translations/en';
import { User, UserTypes } from '../../../../shared/models/users.model';
import { AuthService } from '../../../../services/auth.service';
import { TaskExportFormComponent } from '../task-forms/task-export-form/task-export-form.component';
import { TaskTypes } from '../../../../shared/models/tasks.model';
import { TaskImportFormComponent } from '../task-forms/task-import-form/task-import-form.component';
import { TaskReceivingFormComponent } from '../task-forms/task-receiving-form/task-receiving-form.component';

@Component({
  selector: 'app-task-type-picker',
  templateUrl: './task-type-picker.component.html',
  styleUrls: ['./task-type-picker.component.scss']
})
export class TaskTypePickerComponent {
  public taskTypes = TaskTypes;
  public mode: string;
  public user: User;
  public userTypes = UserTypes;

  constructor(public taskTypePickerDialogRef: MatDialogRef<TaskTypePickerComponent>,
              @Inject(MAT_DIALOG_DATA) private data: any,
              private matDialog: MatDialog,
              private ordersService: OrdersService,
              private authService: AuthService,
              private translateService: TranslateService,
              private snackBar: MatSnackBar,
              private formBuilder: FormBuilder,
              private _fuseTranslationLoaderService: FuseTranslationLoaderService) {
    this.user = this.authService.user.user;

    this._fuseTranslationLoaderService.loadTranslations(polish, english);
  }

  public setTaskType(type) {
    if (type === this.taskTypes.Import) {
      this.taskTypePickerDialogRef.close();
      this.matDialog.open(TaskImportFormComponent, {
        panelClass: 'task-editor-dialog',
        autoFocus: false,
        disableClose: true,
        data: this.data
      });
    } else if (type === this.taskTypes.Receiving) {
      this.taskTypePickerDialogRef.close();
      this.matDialog.open(TaskReceivingFormComponent, {
        panelClass: 'task-editor-dialog',
        autoFocus: false,
        disableClose: true,
        data: this.data
      });
    } else {
      this.taskTypePickerDialogRef.close();
      this.matDialog.open(TaskExportFormComponent, {
        panelClass: 'task-editor-dialog',
        autoFocus: false,
        disableClose: true,
        data: this.data
      });
    }
  }

}
