import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {FuseTranslationLoaderService} from '../../../../../@fuse/services/translation-loader.service';
import {Fraction} from '../../../../shared/models/fractions.model';
import {FractionsService} from '../../../../services/fractions.service';
import {locale as polish} from '../../../../translations/pl';
import {locale as english} from '../../../../translations/en';
import {ConfirmModalComponent} from '../../../shared/components/confirm-modal/confirm-modal.component';
import {markFormGroupTouched} from "../../../../shared/utils/markFormGroupAsTouched";

@Component({
  selector: 'app-fraction-editor',
  templateUrl: './fraction-editor.component.html',
  styleUrls: ['./fraction-editor.component.scss']
})
export class FractionEditorComponent implements OnInit {
  public fractionForm: FormGroup;
  public fractionUuid: Fraction['uuid'];
  public fraction;
  public mode: string;
  public isLoading = false;

  constructor(public fractionEditorDialogRef: MatDialogRef<FractionEditorComponent>,
              @Inject(MAT_DIALOG_DATA) private data: any,
              private matDialog: MatDialog,
              private fractionsService: FractionsService,
              private translateService: TranslateService,
              private snackBar: MatSnackBar,
              private formBuilder: FormBuilder,
              private _fuseTranslationLoaderService: FuseTranslationLoaderService) {
    this.mode = data.mode;
    this.fractionUuid = data.fractionUuid;

    this._fuseTranslationLoaderService.loadTranslations(polish, english);
  }

  ngOnInit() {
    this.createForm({});

    if (this.mode === 'edit' || this.mode === 'view') {
      this.fractionsService.getFraction(this.fractionUuid).subscribe(
        res => {
          this.fraction = res;
          this.createForm(this.fraction);
        }
      );
    }
  }

  public submitFraction() {
    this.isLoading = true;
    markFormGroupTouched(this.fractionForm);
    if (this.fractionForm.invalid) {
      this.isLoading = false;
      return;
    }
    if (this.mode === 'add') {
      this.fractionsService.createFraction(this.fractionForm.value).subscribe(
        () => {
          this.fractionEditorDialogRef.close();

          this.fractionEditorDialogRef.afterClosed().subscribe(() => {
            this.fractionsService.onEditEvent.emit();
          });

          this.snackBar.open(this.translateService.instant('FRACTIONS.MESSAGES.CREATE_SUCCESS'), '', {duration: 5000});
        }, () => {
          this.isLoading = false;
          this.snackBar.open(this.translateService.instant('FRACTIONS.MESSAGES.CREATE_ERROR'), '', {duration: 5000});
        }
      )
    } else {
      this.fractionsService.updateFraction(this.fractionUuid, this.fractionForm.value).subscribe(
        () => {
          this.fractionEditorDialogRef.close();

          this.fractionEditorDialogRef.afterClosed().subscribe(() => {
            this.fractionsService.onEditEvent.emit();
          });

          this.snackBar.open(this.translateService.instant('FRACTIONS.MESSAGES.UPDATE_SUCCESS'), '', {duration: 5000});
        }, () => {
          this.isLoading = false;
          this.snackBar.open(this.translateService.instant('FRACTIONS.MESSAGES.UPDATE_ERROR'), '', {duration: 5000});
        }
      )
    }
  }

  public deleteFraction() {
    let deleteDialogRef = this.matDialog.open(ConfirmModalComponent, {
      disableClose: false,
      autoFocus: false
    });

    deleteDialogRef.componentInstance.confirmMessage = this.translateService.instant('FRACTIONS.MESSAGES.DELETE_QUESTION');
    deleteDialogRef.componentInstance.titleMessage = this.translateService.instant('FRACTIONS.MESSAGES.DELETE_FRACTION_TITLE');
    deleteDialogRef.componentInstance.confirmButton = this.translateService.instant('FRACTIONS.DELETE');

    deleteDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fractionsService.deleteFraction(this.fractionUuid).subscribe(
          () => {
            this.fractionEditorDialogRef.close();
            this.fractionEditorDialogRef.afterClosed().subscribe(() => {
              this.fractionsService.onEditEvent.emit();
            });
            this.snackBar.open(this.translateService.instant('FRACTIONS.MESSAGES.DELETE_SUCCESS'), '', {duration: 5000});
          }, () => {
            this.snackBar.open(this.translateService.instant('FRACTIONS.MESSAGES.DELETE_ERROR'), '', {duration: 5000});
          }
        )
      }
    });
  }

  public enableEditMode() {
    this.fractionEditorDialogRef.close();
    this.matDialog.open(FractionEditorComponent, {
      panelClass: 'fraction-editor-dialog',
      autoFocus: false,
      disableClose: true,
      data: {
        mode: 'edit',
        fractionUuid: this.fractionUuid
      }
    });
  }

  public getTitle(): string {
    switch (this.mode) {
      case 'add':
        return this.translateService.instant('FRACTIONS.TITLE_ADD');
      case 'edit':
        return this.translateService.instant('FRACTIONS.TITLE_EDIT');
      case 'view':
        return this.translateService.instant('FRACTIONS.TITLE_VIEW');
      default:
        return;
    }
  }

  private createForm(inputData: Fraction | any) {
    this.fractionForm = this.formBuilder.group({
      name: new FormControl(inputData.name || '', Validators.required),
      description: new FormControl(inputData.description || ''),
      code: new FormControl(inputData.code || ''),
      active: new FormControl(this.mode=== 'add' ? true : inputData.active),
    });

    if (this.mode === 'view') {
      this.fractionForm.disable();
    }
  }

}
