<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">{{'ORDERS.TITLE_PICK' | translate}}</span>
      <button mat-icon-button (click)="orderTypePickerDialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-actions class="m-0 p-24" fxLayout="column" fxLayoutAlign="center end">
    <button mat-raised-button class="order-type-button ml-0 mr-0" (click)="setOrderType(orderTypes.Export)"
            *ngIf="user.userType === userTypes.Manager || user.userType === userTypes.Logistician" [attr.data-cy]="orderTypes.Export">
      {{'ORDERS.TYPES.EXPORT' | translate}}
    </button>
    <button mat-raised-button class="order-type-button ml-0 mr-0" (click)="setOrderType(orderTypes.Import)"
            *ngIf="user.userType === userTypes.Sales || user.userType === userTypes.Logistician" [attr.data-cy]="orderTypes.Import">
      {{'ORDERS.TYPES.IMPORT' | translate}}
    </button>
    <button mat-raised-button class="order-type-button ml-0 mr-0" (click)="setOrderType(orderTypes.Receiving)"
            *ngIf="user.userType === userTypes.Sales || user.userType === userTypes.Logistician" [attr.data-cy]="orderTypes.Receiving">
      {{'ORDERS.TYPES.RECEIVING' | translate}}
    </button>
  </div>
</div>