/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./users-container.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./users-container.component";
var styles_UsersContainerComponent = [i0.styles];
var RenderType_UsersContainerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UsersContainerComponent, data: {} });
export { RenderType_UsersContainerComponent as RenderType_UsersContainerComponent };
export function View_UsersContainerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_UsersContainerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-users-container", [], null, null, null, View_UsersContainerComponent_0, RenderType_UsersContainerComponent)), i1.ɵdid(1, 49152, null, 0, i3.UsersContainerComponent, [], null, null)], null, null); }
var UsersContainerComponentNgFactory = i1.ɵccf("app-users-container", i3.UsersContainerComponent, View_UsersContainerComponent_Host_0, {}, {}, []);
export { UsersContainerComponentNgFactory as UsersContainerComponentNgFactory };
