/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./no-access.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./no-access.component";
import * as i6 from "../../../../../@fuse/services/translation-loader.service";
var styles_NoAccessComponent = [i0.styles];
var RenderType_NoAccessComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NoAccessComponent, data: {} });
export { RenderType_NoAccessComponent as RenderType_NoAccessComponent };
export function View_NoAccessComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "main-card error-card"], ["fxLayout", "column"], ["fxLayoutAlign", "center start"]], null, null, null, null, null)), i1.ɵdid(1, 737280, null, 0, i2.LayoutDirective, [i3.MediaMonitor, i1.ElementRef, i3.StyleUtils], { layout: [0, "layout"] }, null), i1.ɵdid(2, 737280, null, 0, i2.LayoutAlignDirective, [i3.MediaMonitor, i1.ElementRef, [6, i2.LayoutDirective], i3.StyleUtils], { align: [0, "align"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "error-code"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["403"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "error-message"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_0 = "column"; _ck(_v, 1, 0, currVal_0); var currVal_1 = "center start"; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform("NO_ACCESS.MESSAGE")); _ck(_v, 6, 0, currVal_2); }); }
export function View_NoAccessComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-no-access", [], null, null, null, View_NoAccessComponent_0, RenderType_NoAccessComponent)), i1.ɵdid(1, 114688, null, 0, i5.NoAccessComponent, [i6.FuseTranslationLoaderService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NoAccessComponentNgFactory = i1.ɵccf("app-no-access", i5.NoAccessComponent, View_NoAccessComponent_Host_0, {}, {}, []);
export { NoAccessComponentNgFactory as NoAccessComponentNgFactory };
