<mat-divider></mat-divider>
<ng-container *ngIf="changes.fractions">
  <div *ngIf="changes.fractions[0].length > 0">
    <p>{{'HISTORY.HISTORY_KEYS.fractions' | translate}}
    <strong
      *ngFor="let fraction of changes.fractions[1]; let i=index">{{fraction.name}}{{i < changes.fractions[1].length - 1 ? ', ' : ''}} </strong>
  -->
    <strong
      *ngFor="let fraction of changes.fractions[0]; let i=index">{{fraction.name}}{{i < changes.fractions[0].length - 1 ? ', ' : ''}} </strong>
    </p>
  </div>
</ng-container>

<p *ngIf="changes.isReady">
  {{'HISTORY.HISTORY_KEYS.isReady' | translate}} <strong>{{(changes.isReady[1] ?  'HISTORY.HISTORY_KEYS.yes' : 'HISTORY.HISTORY_KEYS.no') | translate}}</strong>
  --> <strong>{{ (changes.isReady[0] ?  'HISTORY.HISTORY_KEYS.yes' : 'HISTORY.HISTORY_KEYS.no') | translate}}</strong>
</p>
  <mat-divider></mat-divider>

<ng-container *ngIf="changes.tags">
  <p>{{'HISTORY.HISTORY_KEYS.tags' | translate}}
 <strong
      *ngFor="let tag of changes.tags[1]; let i=index">{{tag}}{{i < changes.tags[1].length - 1 ? ', ' : ''}} </strong>
  -->      
  <strong
      *ngFor="let tag of changes.tags[0]; let i=index">{{tag}}{{i < changes.tags[0].length - 1 ? ', ' : ''}} </strong>
  </p>
  </ng-container>


<p *ngIf="changes.customerCompany && changes.customerCompany.name">
  {{'HISTORY.HISTORY_KEYS.companyName' | translate}} <strong>{{changes.customerCompany.name[1]}}</strong>
  --> <strong>{{changes.customerCompany.name[0]}}</strong>
</p>

<p *ngIf="changes.customerCompanyBranch && changes.customerCompanyBranch.name">
  {{'HISTORY.HISTORY_KEYS.name' | translate}} <strong>{{changes.customerCompanyBranch.name[1]}}</strong>
  --> <strong>{{changes.customerCompanyBranch.name[0]}}</strong>
</p>
<p *ngIf="changes.customerCompanyBranch && changes.customerCompanyBranch.street">
  {{'HISTORY.HISTORY_KEYS.street' | translate}} <strong>{{changes.customerCompanyBranch.street[1]}}</strong>
  --> <strong>{{changes.customerCompanyBranch.street[0]}}</strong>
</p>
<p *ngIf="changes.customerCompanyBranch && changes.customerCompanyBranch.postalCode">
  {{'HISTORY.HISTORY_KEYS.postalCode' | translate}} <strong>{{changes.customerCompanyBranch.postalCode[1]}}</strong>
  --> <strong>{{changes.customerCompanyBranch.postalCode[0]}}</strong>
</p>
<p *ngIf="changes.customerCompanyBranch && changes.customerCompanyBranch.city">
  {{'HISTORY.HISTORY_KEYS.city' | translate}} <strong>{{changes.customerCompanyBranch.city[1]}}</strong>
  --> <strong>{{changes.customerCompanyBranch.city[0]}}</strong>
</p>
<p *ngIf="changes.customerCompanyBranch && changes.customerCompanyBranch.country">
  {{'HISTORY.HISTORY_KEYS.country' | translate}} <strong>{{changes.customerCompanyBranch.country[1]}}</strong>
  --> <strong>{{changes.customerCompanyBranch.country[0]}}</strong>
</p>
<p *ngIf="changes.customerCompanyBranch && changes.customerCompanyBranch.contact">
  {{'HISTORY.HISTORY_KEYS.contact' | translate}} <strong>{{changes.customerCompanyBranch.contact[1]}}</strong>
  --> <strong>{{changes.customerCompanyBranch.contact[0]}}</strong>
</p>
<p *ngIf="changes.customerCompanyBranch && changes.customerCompanyBranch.phone">
  {{'HISTORY.HISTORY_KEYS.phone' | translate}} <strong>{{changes.customerCompanyBranch.phone[1]}}</strong>
  --> <strong>{{changes.customerCompanyBranch.phone[0]}}</strong>
</p>
<p *ngIf="changes.customerCompanyBranch && changes.customerCompanyBranch.email">
  {{'HISTORY.HISTORY_KEYS.email' | translate}} <strong>{{changes.customerCompanyBranch.email[1]}}</strong>
  --> <strong>{{changes.customerCompanyBranch.email[0]}}</strong>
</p>
<p *ngIf="changes.customerCompanyBranch && changes.customerCompanyBranch.note">
  {{'HISTORY.HISTORY_KEYS.note' | translate}} <strong>{{changes.customerCompanyBranch.note[1]}}</strong>
  --> <strong>{{changes.customerCompanyBranch.note[0]}}</strong>
</p>
<p *ngIf="changes.registrationNumFront">
  {{'HISTORY.HISTORY_KEYS.registrationNumFront' | translate}} <strong>{{changes.registrationNumFront[1]}}</strong>
  --> <strong>{{changes.registrationNumFront[0]}}</strong>
</p>
<p *ngIf="changes.registrationNumBack">
  {{'HISTORY.HISTORY_KEYS.registrationNumBack' | translate}} <strong>{{changes.registrationNumBack[1]}}</strong>
  --> <strong>{{changes.registrationNumBack[0]}}</strong>
</p>
<p *ngIf="changes.bdo">
  {{'HISTORY.HISTORY_KEYS.bdo' | translate}} <strong>{{changes.bdo[1]}}</strong>
  --> <strong>{{ changes.bdo[0]}}</strong>
</p>
<p *ngIf="changes.bdoFree">
  {{'HISTORY.HISTORY_KEYS.bdoFree' | translate}} <strong>{{(changes.bdoFree[1] ?  'HISTORY.HISTORY_KEYS.yes' : 'HISTORY.HISTORY_KEYS.no') | translate}}</strong>
  --> <strong>{{ (changes.bdoFree[0]?  'HISTORY.HISTORY_KEYS.yes' : 'HISTORY.HISTORY_KEYS.no') | translate}}</strong>
</p>
<p *ngIf="changes.bdoPeopleCollection">
  {{'HISTORY.HISTORY_KEYS.bdoPeopleCollection' | translate}} <strong>{{(changes.bdoPeopleCollection[1] ? 'HISTORY.HISTORY_KEYS.yes' : 'HISTORY.HISTORY_KEYS.no') | translate}}</strong>
  --> <strong>{{ (changes.bdoPeopleCollection[0] ?  'HISTORY.HISTORY_KEYS.yes' : 'HISTORY.HISTORY_KEYS.no') | translate}}</strong>
</p>
<p *ngIf="changes.orderData && changes.transport && changes.transport.name">
  {{'HISTORY.HISTORY_KEYS.transport' | translate}}
  <strong>{{changes.transport.name[1]}}</strong>
  --> <strong>{{changes.transport.name[0]}}</strong>
</p>
<p *ngIf="changes.referenceNumber">
  {{'HISTORY.HISTORY_KEYS.orderReferenceNumber' | translate}}: <strong>{{changes.referenceNumber[1]}}</strong>
  --> <strong>{{changes.referenceNumber[0]}}</strong>
</p>
 
  <mat-divider></mat-divider>
  <p *ngIf="changes.suggestedDate">
    {{'HISTORY.HISTORY_KEYS.suggestedDate' | translate}} <strong>{{changes.suggestedDate[1]*1000 | date:'yyyy-MM-dd':'':'pl' }}</strong>
    --> <strong>{{changes.suggestedDate[0]*1000 |  date:'yyyy-MM-dd':'':'pl' }}</strong>
  </p>
  <p *ngIf="changes.availableHoursFrom">
    {{'HISTORY.HISTORY_KEYS.availableHoursFrom' | translate}} <strong>{{changes.availableHoursFrom[1]}}</strong>
    --> <strong>{{changes.availableHoursFrom[0]}}</strong>
  </p>
  <p *ngIf="changes.availableHoursTo">
    {{'HISTORY.HISTORY_KEYS.availableHoursTo' | translate}} <strong>{{changes.availableHoursTo[1]}}</strong>
    --> <strong>{{changes.availableHoursTo[0]}}</strong>
  </p>
  <p *ngIf="changes.salesAccepted">
    {{'HISTORY.HISTORY_KEYS.salesAccepted' | translate}}
    <strong>{{(changes.salesAccepted[1] ? 'HISTORY.HISTORY_KEYS.yes' : 'HISTORY.HISTORY_KEYS.no') | translate}}</strong>
    -->
    <strong>{{(changes.salesAccepted[0] ? 'HISTORY.HISTORY_KEYS.yes' : 'HISTORY.HISTORY_KEYS.no') | translate}}</strong>
  </p>
  <p *ngIf="changes.salesAcceptedDate">
    {{'HISTORY.HISTORY_KEYS.salesAcceptedDate' | translate}}
    <strong>{{changes.salesAcceptedDate[1]*1000 | date:'yyy-MM-dd HH:mm':'':'pl' }}</strong>
    -->
    <strong>{{changes.salesAcceptedDate[0]*1000 | date:'yyy-MM-dd HH:mm':'':'pl'}}</strong>
  </p>
  <p *ngIf="changes.comment">
    {{'HISTORY.HISTORY_KEYS.comment' | translate}}
    <strong>{{changes.comment[1]}}</strong>
    -->
    <strong>{{changes.comment[0]}}</strong>
  </p>
  <p *ngIf="changes.price">
    {{'HISTORY.HISTORY_KEYS.price' | translate}}
    <strong>{{changes.price[1] || '-'}}</strong> {{'HISTORY.HISTORY_KEYS.zl' | translate}}
    --> <strong>{{changes.price[0] || '-'}}</strong>{{'HISTORY.HISTORY_KEYS.zl' | translate}}
  </p>
  <p *ngIf="changes.reinvoiceNumber">
    {{'HISTORY.HISTORY_KEYS.reinvoiceNumber' | translate}}
    <strong>{{changes.reinvoiceNumber[1] }}</strong>
    --> <strong>{{changes.reinvoiceNumber[0]}}</strong>
  </p>
  <p *ngIf="changes.invoiceComment">
    {{'HISTORY.HISTORY_KEYS.invoiceComment' | translate}}
    <strong>{{changes.invoiceComment[1] }}</strong>
    --> <strong>{{changes.invoiceComment[0]}}</strong>
  </p>
  <p *ngIf="changes.totalWeight">
    {{'HISTORY.HISTORY_KEYS.totalWeight' | translate}}
    <strong>{{changes.totalWeight[1] || '-'}}</strong> {{'HISTORY.HISTORY_KEYS.kg' | translate}}
    --> <strong>{{changes.totalWeight[0] || '-'}}</strong> {{'HISTORY.HISTORY_KEYS.kg' | translate}}
  </p>

<ng-container *ngIf="changes.orderData">
  <ng-container *ngIf="changes.orderData && changes.orderData[1].length">
    <h3>
      <strong>{{'HISTORY.HISTORY_KEYS.recyclingItemsOld' | translate}}</strong>
    </h3>
    <app-recycling-items-display [recyclingItems]="changes.orderData[1]"></app-recycling-items-display>
  </ng-container>
  <ng-container *ngIf="changes.orderData[0].length">
    <h3>
      <strong>{{'HISTORY.HISTORY_KEYS.recyclingItemsNew' | translate}}</strong>
    </h3>
    <app-recycling-items-display [recyclingItems]="changes.orderData[0]"></app-recycling-items-display>
  </ng-container>
</ng-container>