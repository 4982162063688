var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { CompaniesService } from '../../../../services/companies.service';
import { MatDialog } from '@angular/material';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { CompanyEditorComponent } from '../company-editor/company-editor.component';
import { locale as polish } from '../../../../translations/pl';
import { locale as english } from '../../../../translations/en';
import { List } from '../../../../shared/models/list.class';
import { RoleTypes, UserTypes } from '../../../../shared/models/users.model';
import { FormControl } from '@angular/forms';
import { AuthService } from '../../../../services/auth.service';
var CompaniesListComponent = /** @class */ (function (_super) {
    __extends(CompaniesListComponent, _super);
    function CompaniesListComponent(companiesService, authService, matDialog, _fuseTranslationLoaderService) {
        var _this = _super.call(this) || this;
        _this.companiesService = companiesService;
        _this.authService = authService;
        _this.matDialog = matDialog;
        _this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        _this.displayedColumns = ['name', 'city', 'phone', 'email', 'nip', 'owner', 'types', 'trusted'];
        _this.ownersOptions = [];
        _this.hasAccessToUsersList = false;
        _this.listFilters = {};
        _this.ownerFilters = {
            page: 1,
            limit: 1000,
            sort: 'surname',
            order: 'asc',
            userType: UserTypes.Sales
        };
        _this.user = _this.authService.getUser();
        _this.reloadList$ = companiesService.onEditEvent.subscribe(function () {
            _this.getElementsList();
        });
        _this._fuseTranslationLoaderService.loadTranslations(polish, english);
        _this.getElementsList = function () {
            _this.companiesService.getCompaniesList(_this.getQueryParams())
                .subscribe(function (res) {
                _this.totalCount = res.totalCount;
                _this.dataSource = res.customerCompanies;
            });
        };
        return _this;
    }
    CompaniesListComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.createSearchFormControl();
        if (this.user.userType === UserTypes.Logistician ||
            (this.user.userType === UserTypes.Sales && this.user.roleType === RoleTypes.Admin)) {
            this.hasAccessToUsersList = true;
            this.companiesService.getOwnersList(this.ownerFilters).subscribe(function (res) {
                res.users.forEach(function (user) {
                    _this.ownersOptions.push({ name: user.name + " " + user.surname, value: user.uuid });
                });
            });
        }
    };
    CompaniesListComponent.prototype.ngOnDestroy = function () {
        this.reloadList$.unsubscribe();
    };
    CompaniesListComponent.prototype.addCompany = function () {
        this.disableAnimations = true;
        var editorDialogRef = this.matDialog.open(CompanyEditorComponent, {
            panelClass: 'company-editor-dialog',
            autoFocus: false,
            disableClose: true,
            data: {
                mode: 'add'
            }
        });
    };
    CompaniesListComponent.prototype.viewCompany = function (companyUuid) {
        this.disableAnimations = true;
        var editorDialogRef = this.matDialog.open(CompanyEditorComponent, {
            panelClass: 'company-editor-dialog',
            autoFocus: false,
            disableClose: true,
            data: {
                mode: 'view',
                companyUuid: companyUuid
            }
        });
    };
    CompaniesListComponent.prototype.clearSearch = function () {
        if (this.searchCompany.value) {
            this.searchCompany.setValue('');
        }
    };
    CompaniesListComponent.prototype.getListFilters = function (filters) {
        this.listFilters = filters;
        this.requestParams.page = 1;
        this.disableAnimations = true;
        this.getElementsList();
    };
    CompaniesListComponent.prototype.createSearchFormControl = function () {
        var _this = this;
        this.searchCompany = new FormControl('');
        this.searchCompany.valueChanges.debounceTime(300).subscribe(function (value) {
            _this.requestParams.search = value;
            _this.requestParams.page = 1;
            _this.disableAnimations = true;
            _this.getElementsList();
        });
    };
    CompaniesListComponent.prototype.getQueryParams = function () {
        return Object.assign({}, this.requestParams, this.listFilters);
    };
    return CompaniesListComponent;
}(List));
export { CompaniesListComponent };
