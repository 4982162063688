import * as Sentry from "@sentry/browser";
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { AuthService } from '../../services/auth.service';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let newRequestOptions = {
      withCredentials: true
    };

    const loggedUser = this.authService.user;

    const requestRequiresToken = request.method === 'POST' || request.method === 'PUT' || request.method === 'DELETE';
    if (requestRequiresToken) {
      newRequestOptions = Object.assign(
        newRequestOptions,
        {
          setHeaders: {
            'X-xsrfToken': loggedUser['xsrfToken'] || '',
            'Content-Type': 'application/json',
          }
        });
    }

    const newRequest = request.clone(newRequestOptions);

    return next.handle(newRequest)
      .pipe(
        catchError(
          (error: any) => {
            if (error.status === 401) {
              this.snackBar.open(this.translateService.instant('SHARED.MESSAGES.SESSION_EXPIRED'), '', {duration: 5000});
              this.authService.logout();
            } else if (error.status === 403) {
              this.snackBar.open(this.translateService.instant('SHARED.MESSAGES.NO_CREDENTIALS'), '', {duration: 5000});
            } else if (error.status >= 400) {
              Sentry.withScope(scope => {
                scope.setExtra('error', { request, error});
                Sentry.captureException(new Error('Bad Request'));
              });
            }
            return throwError(error);
          }
        ),
        tap(() => {
          if (loggedUser && Object.keys(loggedUser).length > 0) {
            this.authService.updateSession(loggedUser);
          }
        })
      );
  }
}
