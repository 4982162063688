<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">{{getTitle()}}</span>
      <button mat-icon-button (click)="companyKpoEditorDialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>
    <form [formGroup]="companyKpoForm">

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'COMPANY_KPO.NAME' | translate}}</mat-label>
          <input formControlName="name" matInput required [attr.data-cy]="'companyKpoName'">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'COMPANY_KPO.SURNAME' | translate}}</mat-label>
          <input formControlName="surname" matInput required [attr.data-cy]="'companyKpoSurname'">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'COMPANY_KPO.EMAIL' | translate}}</mat-label>
          <input type="email" formControlName="email" matInput required [attr.data-cy]="'companyKpoEmail'">
        </mat-form-field>
      </div>

      <div class="input-wrapper" fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'COMPANY_KPO.PHONE' | translate}}</mat-label>
          <input formControlName="phone" matInput required [attr.data-cy]="'companyKpoPhone'">
        </mat-form-field>
      </div>
    </form>

  <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

    <button *ngIf="mode === 'edit'"
            mat-button
            class="mr-8"
            (click)="deleteCompanyKpo()"
            [attr.data-cy]="'companyKpoDelete'">
      {{'COMPANY_KPO.DELETE' | translate}}
    </button>

    <button *ngIf="mode === 'view'"
            mat-button
            class="mr-8"
            (click)="enableEditMode()"
            [attr.data-cy]="'companyKpoEdit'">
      {{'COMPANY_KPO.EDIT' | translate}}
    </button>

    <button *ngIf="mode === 'edit' || mode === 'add'"
            mat-button
            class="save-button"
            (click)="submitCompanyKpo()"
            [attr.data-cy]="'companyKpoSave'"
            [disabled]="isLoading">
      <span *ngIf="!isLoading"> {{'COMPANY_KPO.SAVE' | translate}}</span>
      <span *ngIf="isLoading" class="loading-spinner-wrapper">
        <mat-progress-spinner mode="indeterminate"
                              [strokeWidth]="2"
                              [diameter]="20">
        </mat-progress-spinner>
      </span>
    </button>

  </div>
</div>