<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">{{getTitle()}}</span>
      <button mat-icon-button (click)="tagEditorDialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>

    <form [formGroup]="tagForm">

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'TAGS.NAME' | translate}}</mat-label>
          <mat-icon matSuffix class="secondary-text">dashboard</mat-icon>
          <input name="name" formControlName="name" matInput required [attr.data-cy]="'tagName'">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'TAGS.STATUS' | translate}}</mat-label>
          <mat-select formControlName="active" [attr.data-cy]="'tagIsActive'">
            <mat-option [value]="true" [attr.data-cy]="'ACTIVE'">{{'TAGS.STATUSES.ACTIVE' | translate}}</mat-option>
            <mat-option [value]="false" [attr.data-cy]="'INACTIVE'">{{'TAGS.STATUSES.INACTIVE' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </form>

  </div>

  <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

    <button *ngIf="mode === 'edit'"
            mat-button
            class="mr-8"
            [attr.data-cy]="'tagDelete'"
            (click)="deleteTag()">
      {{'TAGS.DELETE' | translate}}
    </button>

    <button *ngIf="mode === 'view'"
            mat-button
            class="mr-8"
            [attr.data-cy]="'tagEdit'"
            (click)="enableEditMode()">
      {{'TAGS.EDIT' | translate}}
    </button>

    <button *ngIf="mode === 'edit' || mode === 'add'"
            mat-button
            class="save-button"
            [attr.data-cy]="'tagSave'"
            (click)="submitTag()"
            [disabled]="isLoading">
      <span *ngIf="!isLoading"> {{'TAGS.SAVE' | translate}}</span>
      <span *ngIf="isLoading" class="loading-spinner-wrapper">
        <mat-progress-spinner mode="indeterminate"
                              [strokeWidth]="2"
                              [diameter]="20">
        </mat-progress-spinner>
      </span>
    </button>

  </div>
</div>