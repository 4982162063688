<div class="companies">
  <div class="header p-16 p-sm-24"
       fxLayout.gt-xs="row"
       fxLayoutAlign.gt-xs="space-between center" *ngIf="user">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="logo" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="logo-icon mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
          store_mall_directory
        </mat-icon>
        <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
          {{'CUSTOMER_COMPANIES.TITLE_LIST' | translate}}
        </span>
      </div>
    </div>
    <div class="search-wrapper">
      <div class="search">
        <label for="search" class="mr-8">
          <mat-icon (click)="clearSearch()">{{searchCompany.value ? 'clear' : 'search'}}</mat-icon>
        </label>
        <input id="search" [formControl]="searchCompany"
               [placeholder]="'CUSTOMER_COMPANIES.SEARCH_PLACEHOLDER' | translate">
      </div>
    </div>
  </div>

  <div class="companies-content">
    <app-list-filters class="filters-container"
                      [filters]="['trusted']"
                      [customFilters]="hasAccessToUsersList ? [{name: 'ownerId', options: ownersOptions}] : []"
                      (listFilters)="getListFilters($event)">
    </app-list-filters>
    <div class="companies-table">
      <mat-table
        matSort
        [dataSource]="dataSource"
        [matSortActive]="requestParams.sort"
        [matSortDirection]="requestParams.order"
        [@animateStaggerRemondis]="dataSource && dataSource.length"
        [@.disabled]="disableAnimations">

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'CUSTOMER_COMPANIES.NAME' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let company"> {{company.name}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="city">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'CUSTOMER_COMPANIES.CITY' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let company"> {{company?.address?.city}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="phone">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'CUSTOMER_COMPANIES.PHONE' | translate}}
          </mat-header-cell>
          <mat-cell *matCellDef="let company"> {{company?.phone}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'CUSTOMER_COMPANIES.EMAIL' | translate}}
          </mat-header-cell>
          <mat-cell *matCellDef="let company"> {{company?.email}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="nip">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'CUSTOMER_COMPANIES.NIP' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let company"> {{company?.nip}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="owner">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'CUSTOMER_COMPANIES.OWNER' | translate}}
          </mat-header-cell>
          <mat-cell *matCellDef="let company"> {{company?.owner?.name}} {{company?.owner?.surname}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="types">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'CUSTOMER_COMPANIES.COMPANY_TYPE' | translate}}
          </mat-header-cell>
          <mat-cell *matCellDef="let company">
            <span *ngIf="company.companyType.supplier" class="badge-type supplier"
                  [matTooltip]="'CUSTOMER_COMPANIES.COMPANY_TYPES.SUPPLIER' | translate" matTooltipPosition="above">
              {{'CUSTOMER_COMPANIES.COMPANY_TYPES_SHORT.SUPPLIER' | translate }}
            </span>
            <span *ngIf="company.companyType.recipient" class="badge-type recipient"
                  [matTooltip]="'CUSTOMER_COMPANIES.COMPANY_TYPES.RECIPIENT' | translate" matTooltipPosition="above">
              {{'CUSTOMER_COMPANIES.COMPANY_TYPES_SHORT.RECIPIENT' | translate }}
            </span>
            <span *ngIf="company.companyType.forwarder" class="badge-type forwarder"
                  [matTooltip]="'CUSTOMER_COMPANIES.COMPANY_TYPES.FORWARDER' | translate" matTooltipPosition="above">
              {{'CUSTOMER_COMPANIES.COMPANY_TYPES_SHORT.FORWARDER' | translate }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="trusted">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'CUSTOMER_COMPANIES.TRUSTED' | translate}}
          </mat-header-cell>
          <mat-cell *matCellDef="let company">
            <mat-icon [ngClass]="company.trusted && 'green-icon'">
              {{company.trusted ? 'check' : 'close'}}
            </mat-icon>
          </mat-cell>
        </ng-container>


        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          class="company-row"
          *matRowDef="let company; columns: displayedColumns;"
          [@animateRemondis]="{value:'*',params:{y:'100%'}}"
          [id]="company.uuid"
          (click)="viewCompany(company.uuid)"
        >
        </mat-row>

      </mat-table>

      <h4 class="no-data" *ngIf="totalCount === 0">
        {{'SHARED.NO_DATA' | translate}}
      </h4>

      <div *ngIf="totalCount" class="paginator-container">
        <mat-paginator
          (page)="pageChange($event)"
          [pageSize]="requestParams.limit"
          [length]="totalCount"
          class="paginator-holder"
        ></mat-paginator>
      </div>
    </div>
  </div>
  <button mat-fab class="add-company-button"
          [@animate]="{value:'*', params:{delay:'300ms',scale:'.2'}}"
          (click)="addCompany()"
          [attr.data-cy]="'companyAdd'"
          [matTooltip]="'CUSTOMER_COMPANIES.TITLE_ADD' | translate"
          matTooltipPosition="above">
    <mat-icon>add</mat-icon>
  </button>
</div>

