import { OnDestroy, OnInit, } from "@angular/core";
import { MatDialog } from "@angular/material";
import { NavigationEnd, Router } from "@angular/router";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { FusePerfectScrollbarDirective } from "@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive";
import { FuseConfigService } from "@fuse/services/config.service";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { delay, filter, take, takeUntil } from "rxjs/operators";
import { FuseTranslationLoaderService } from "../../../../../../@fuse/services/translation-loader.service";
import { OrderLinkFormComponent } from "../../../../../modules/orders/components/order-forms/order-link-form/order-link-form.component";
import { UserEditorComponent } from "../../../../../modules/users/components/user-editor/user-editor.component";
import { AuthService } from "../../../../../services/auth.service";
import { UserTypes } from "../../../../../shared/models/users.model";
import { locale as polish } from "../../../i18n/pl";
var NavbarVerticalStyle1Component = /** @class */ (function () {
    function NavbarVerticalStyle1Component(authService, matDialog, _fuseConfigService, _fuseNavigationService, _fuseSidebarService, _fuseTranslationLoaderService, _translateService, _router) {
        this.authService = authService;
        this.matDialog = matDialog;
        this._fuseConfigService = _fuseConfigService;
        this._fuseNavigationService = _fuseNavigationService;
        this._fuseSidebarService = _fuseSidebarService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this._translateService = _translateService;
        this._router = _router;
        // Set the private defaults
        this._unsubscribeAll = new Subject();
        this._fuseTranslationLoaderService.loadTranslations(polish);
    }
    Object.defineProperty(NavbarVerticalStyle1Component.prototype, "directive", {
        // -----------------------------------------------------------------------------------------------------
        // @ Accessors
        // -----------------------------------------------------------------------------------------------------
        // Directive
        set: function (theDirective) {
            var _this = this;
            if (!theDirective) {
                return;
            }
            this._fusePerfectScrollbar = theDirective;
            // Update the scrollbar on collapsable item toggle
            this._fuseNavigationService.onItemCollapseToggled
                .pipe(delay(500), takeUntil(this._unsubscribeAll))
                .subscribe(function () {
                _this._fusePerfectScrollbar.update();
            });
            // Scroll to the active item position
            this._router.events
                .pipe(filter(function (event) { return event instanceof NavigationEnd; }), take(1))
                .subscribe(function () {
                setTimeout(function () {
                    var activeNavItem = document.querySelector("navbar .nav-link.active");
                    if (activeNavItem) {
                        var activeItemOffsetTop = activeNavItem.offsetTop, activeItemOffsetParentTop = activeNavItem.offsetParent.offsetTop, scrollDistance = activeItemOffsetTop - activeItemOffsetParentTop - 48 * 3 - 168;
                        _this._fusePerfectScrollbar.scrollToTop(scrollDistance);
                    }
                });
            });
        },
        enumerable: true,
        configurable: true
    });
    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------
    NavbarVerticalStyle1Component.prototype.ngOnInit = function () {
        var _this = this;
        this.user = this.authService.getUser();
        this._router.events
            .pipe(filter(function (event) { return event instanceof NavigationEnd; }), takeUntil(this._unsubscribeAll))
            .subscribe(function () {
            if (_this._fuseSidebarService.getSidebar("navbar")) {
                _this._fuseSidebarService.getSidebar("navbar").close();
            }
        });
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (config) {
            _this.fuseConfig = config;
            _this.horizontalNavbar = config.layout.navbar.position === "top";
            _this.rightNavbar = config.layout.navbar.position === "right";
            _this.hiddenNavbar = config.layout.navbar.hidden === true;
        });
        // Get current navigation
        this._fuseNavigationService.onNavigationChanged
            .pipe(filter(function (value) { return value !== null; }), takeUntil(this._unsubscribeAll))
            .subscribe(function () {
            _this.navigation = _this._fuseNavigationService.getCurrentNavigation();
        });
    };
    NavbarVerticalStyle1Component.prototype.ngOnDestroy = function () {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    NavbarVerticalStyle1Component.prototype.sendOrderLink = function () {
        this.matDialog.open(OrderLinkFormComponent, { autoFocus: false });
    };
    NavbarVerticalStyle1Component.prototype.getFolded = function () {
        return this._fuseSidebarService.getSidebar("navbar").folded;
    };
    NavbarVerticalStyle1Component.prototype.toggleSidebarFolded = function () {
        this._fuseSidebarService.getSidebar("navbar").toggleFold();
        this.closeMenu = !this.closeMenu;
    };
    NavbarVerticalStyle1Component.prototype.toggleSidebarOpen = function (key) {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    };
    NavbarVerticalStyle1Component.prototype.toggleSidebarClose = function () {
        this._fuseSidebarService.getSidebar("navbar").close();
    };
    NavbarVerticalStyle1Component.prototype.getNavbarTitle = function () {
        if (this.user) {
            switch (this.user.userType) {
                case UserTypes.Logistician:
                    return "LOGISTICIAN";
                case UserTypes.Sales:
                    return "SALES";
                case UserTypes.Manager:
                    return "MANAGER";
            }
        }
    };
    NavbarVerticalStyle1Component.prototype.viewUser = function () {
        this.matDialog.open(UserEditorComponent, {
            panelClass: "user-editor-dialog",
            autoFocus: false,
            disableClose: true,
            data: {
                mode: "view",
                editedUserUuid: this.user.uuid,
            },
        });
    };
    NavbarVerticalStyle1Component.prototype.logout = function () {
        this.authService.logout();
    };
    return NavbarVerticalStyle1Component;
}());
export { NavbarVerticalStyle1Component };
