import { MatPaginatorIntl } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

export class MatPaginatorI18n {
  constructor(private readonly translateService: TranslateService) {}

  getPaginatorIntl(): MatPaginatorIntl {
    const paginatorIntl = new MatPaginatorIntl();
    this.translateService
      .get('SHARED.PAGINATION.ITEMS_PER_PAGE')
      .subscribe(value => (paginatorIntl.itemsPerPageLabel = value));
    this.translateService.get('SHARED.PAGINATION.NEXT_PAGE').subscribe(value => (paginatorIntl.nextPageLabel = value));
    this.translateService
      .get('SHARED.PAGINATION.PREV_PAGE')
      .subscribe(value => (paginatorIntl.previousPageLabel = value));
    paginatorIntl.getRangeLabel = this.getRangeLabel.bind(this);
    return paginatorIntl;
  }

  private getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0 || pageSize === 0) {
      let rangePageLabel1 = '';
      this.translateService
        .get('SHARED.PAGINATION.RANGE_PAGE_LABEL_1', { length })
        .subscribe(value => (rangePageLabel1 = value));
      return rangePageLabel1;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

    let rangePageLabel2 = '';
    this.translateService
      .get('SHARED.PAGINATION.RANGE_PAGE_LABEL_2', {
        startIndex: startIndex + 1,
        endIndex,
        length
      })
      .subscribe(value => (rangePageLabel2 = value));
    return rangePageLabel2;
  }
}
