import { Component, OnDestroy, OnInit } from '@angular/core';
import { PackageType, PackageTypesListRespone } from '../../../../shared/models/package.model';
import { PackageTypesEditorComponent } from '../package-types-editor/package-types-editor.component';
import { locale as polish } from '../../../../translations/pl';
import { locale as english } from '../../../../translations/en';
import { PackageService } from '../../../../services/package.service';
import { MatDialog } from '@angular/material';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { List } from '../../../../shared/models/list.class';
import { remondisListAnimation } from '../../../../shared/consts/remondis.lists.animation';
import { fuseAnimations } from '../../../../../@fuse/animations';
import { FormControl } from '@angular/forms';
import { ListFilters } from '../../../../shared/models/list.model';
import { AuthService } from '../../../../services/auth.service';
import { User } from '../../../../shared/models/users.model';

@Component({
  selector: 'app-package-types-list',
  templateUrl: './package-types-list.component.html',
  styleUrls: ['./package-types-list.component.scss'],
  animations: [...fuseAnimations, ...remondisListAnimation]
})
export class PackageTypesListComponent extends List implements OnInit, OnDestroy {
  public displayedColumns = ['name', 'unit', 'length', 'width', 'height', 'volume', 'capacity', 'isPublic', 'active'];
  public searchPackage: FormControl;
  public user: User;
  private listFilters: ListFilters = {};
  private reloadList$;

  constructor(private packageService: PackageService,
              private matDialog: MatDialog,
              private _fuseTranslationLoaderService: FuseTranslationLoaderService,
              private authService: AuthService) {
    super();

    this._fuseTranslationLoaderService.loadTranslations(polish, english);

    this.reloadList$ = packageService.onEditEvent.subscribe(
      () => {
        this.getElementsList();
      });

    this.getElementsList = () => {
      this.packageService.getPackageTypesList(this.getQueryParams()).subscribe((res: PackageTypesListRespone) => {
        this.totalCount = res.totalCount;
        this.dataSource = res.packageTypes;
      });
    };
  }

  ngOnInit() {
    super.ngOnInit();
    this.createSearchFormControl();
    this.user = this.authService.getUser();
  }

  ngOnDestroy() {
    this.reloadList$.unsubscribe();
  }

  public addPackageType() {
    this.disableAnimations = true;

    let editorDialogRef = this.matDialog.open(PackageTypesEditorComponent, {
      panelClass: 'package-editor-dialog',
      autoFocus: false,
      disableClose: true,
      data: {
        mode: 'add'
      }
    });
  }

  public viewPackageType(packageTypeUuid: PackageType['uuid']) {
    this.disableAnimations = true;

    let editorDialogRef = this.matDialog.open(PackageTypesEditorComponent, {
      panelClass: 'package-editor-dialog',
      autoFocus: false,
      disableClose: true,
      data: {
        mode: 'view',
        packageTypeUuid: packageTypeUuid
      }
    });
  }

  public clearSearch() {
    if (this.searchPackage.value) {
      this.searchPackage.setValue('');
    }
  }

  public getListFilters(filters: ListFilters) {
    this.listFilters = filters;
    this.requestParams.page = 1;
    this.disableAnimations = true;
    this.getElementsList();
  }


  private createSearchFormControl() {
    this.searchPackage = new FormControl('');
    this.searchPackage.valueChanges.debounceTime(300).subscribe((value) => {
      this.requestParams.name = value;
      this.requestParams.page = 1;
      this.disableAnimations = true;
      this.getElementsList();
    });
  }

  private getQueryParams() {
    return Object.assign({}, this.requestParams, this.listFilters);
  }

}
