import {Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {map} from 'rxjs/operators';
import { VersionResponse } from '../shared/models/version.model';

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  version: VersionResponse;

  constructor(private http: HttpClient) {
  }
  getVersion() {
    return this.http.get('./version.json').pipe(map((res: VersionResponse) => this.version = res));
  }
}
