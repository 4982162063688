import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CalendarTask, TaskStatuses} from '../../../../shared/models/tasks.model';
import {TasksService} from '../../../../services/tasks.service';
import {NoteCalendarService} from '../../../../services/note-calendar.service';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import {TranslateService} from '@ngx-translate/core';
import logoBase64 from './logoBase64';
import {Description} from '../../../../shared/models/description.model';
import * as moment from 'moment';

@Component({
  selector: 'app-pdf-downloader',
  templateUrl: './pdf-downloader.component.html',
  styleUrls: ['./pdf-downloader.component.scss']
})
export class PdfDownloaderComponent implements OnInit, OnChanges {
  @Input() companyBranchId: string;
  @Input() companyBranchName: string;

  public pdfForm: FormGroup;
  public tasksList: CalendarTask[] = [];
  public note: Description[];
  public buttonLoading = false;
  public buttonDisabled = true;

  constructor(
    private noteCalendarService: NoteCalendarService,
    private tasksService: TasksService,
    private translateService: TranslateService
  ) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    this.pdfForm = new FormGroup({
      dateFilter: new FormControl('', Validators.required)
    });
  }

  ngOnInit() {
    this.pdfForm.get('dateFilter').valueChanges
      .debounceTime(300)
      .subscribe(value => {
        this.getTasks();
      });
  }

  createTable() {
    const tableTitle = () => {
      const date = new Date(this.pdfForm.get('dateFilter').value);
      const dayName = this.translateService.instant(`SHARED.WEEKDAYS.${date.getDay() - 1}`);
      return `${this.companyBranchName} - ${this.parseDate}, ${dayName}`;
    };
    const getTime = (date: string) => {
      const dateObj = new Date(date);
      let hours = String(dateObj.getHours());
      let minutes = String(dateObj.getMinutes());
      return `${`0${hours}`.slice(-2)}:${`0${minutes}`.slice(-2)}`;
    };
    const getComment = (comment: string) => (!!comment ? comment : this.translateService.instant('TASKS.PDFFILTER.NONE'));
    const colorTaskRow = (taskType: string) =>
      taskType === 'export' ? '#b7d6aa' : '#ffffff';

    let body = [];

    const headerRow = [
      {
        text: tableTitle(),
        style: 'header',
        fillColor: '#000000',
        color: 'white',
        colSpan: 7,
        alignment: 'center'
      },
      '',
      '',
      '',
      '',
      '',
      ''
    ];
    const subheaderColTitles = [
      this.translateService.instant('TASKS.PDFFILTER.TABLE.HOUR'),
      this.translateService.instant('TASKS.PDFFILTER.TABLE.TYPE'),
      this.translateService.instant('TASKS.PDFFILTER.TABLE.TITLE'),
      this.translateService.instant('TASKS.PDFFILTER.TABLE.COMPANY'),
      this.translateService.instant('TASKS.PDFFILTER.TABLE.CARGO'),
      this.translateService.instant('TASKS.PDFFILTER.TABLE.VEHICLE'),
      this.translateService.instant('TASKS.PDFFILTER.TABLE.COMMENTS')
    ];
    const subheaderRow = [];
    // header
    body.push(headerRow);
    // subheader
    subheaderColTitles.map(item => {
      const subheaderItem = {
        text: item,
        style: 'smaller',
        alignment: 'center',
        fillColor: '#999999',
        color: 'white'
      };
      subheaderRow.push(subheaderItem);
    });

    body.push(subheaderRow);
    // task row
    this.tasksList.forEach(item => {
      let companyCol = [];

      item.taskData.forEach(taskDataItem => {
        let cargo = [
          {
            style: 'smaller',
            fillColor: colorTaskRow(item.taskType),
            margin: [-5, -3, 0, -3],
            layout: {},
            table: {
              widths: [100],
              body: []
            }
          }
        ];

        const companyRow = item => {
          return {
            text: item.customerCompany.name,
            border: [true, true, true, true],
            style: 'smaller'
          };
        };

        const cargoRow = item => {
          let cargoEl = {
            style: 'smaller',
            ul: [
              `${item.recyclingItem.name} - ${item.weight} kg`,
              {
                ul: []
              }
            ]
          };
          item.packageTypes.forEach(packageType => {
            const unit = this.translateService.instant(`TASKS.PDFFILTER.UNITS.${packageType.unit.toUpperCase()}`);
            cargoEl.ul[1]['ul'].push(
              `${packageType['name']} (${packageType['value']} ${unit})`
            );
          });
          return cargoEl;
        };

        const fractionRow = (fractions, value) => {
          let fractionEl = {
            style: 'smaller',
            ul: []
          };
          fractions.forEach(fraction => {
            fractionEl.ul.push(
              fraction['name']
            );
          });
          fractionEl.ul.push({
            ul: [`${value} kg`]
          });
          return fractionEl;
        };

        if (item.taskType === 'export') {
          let exportEl = [
            {
              fillColor: colorTaskRow(item.taskType),
              margin: [-5, -3, 0, -3],
              layout: {},
              table: {
                widths: [100],
                body: []
              }
            }
          ];
          companyCol.push([companyRow(taskDataItem), exportEl]);
          exportEl[0]['table'].body.push([fractionRow(taskDataItem.fractions, taskDataItem.value)]);
        } else {
          companyCol.push([companyRow(taskDataItem), cargo]);

          taskDataItem.orderData.forEach(orderEl => {
            cargo[0]['table'].body.push([cargoRow(orderEl)]);
          });
        }
      });

      const taskRow = [
        {
          text: getTime(item.startDate),
          style: 'smaller',
          alignment: 'center',
          fillColor: colorTaskRow(item.taskType)
        },
        {
          text: this.translateService.instant(`TASKS.TYPES.${item.taskType === 'receiving' ? 'IMPORT' : item.taskType.toUpperCase()}`),
          style: 'smaller',
          alignment: 'center',
          fillColor: colorTaskRow(item.taskType)
        },
        {
          text: item.name,
          style: 'smaller',
          alignment: 'center',
          fillColor: colorTaskRow(item.taskType)
        },
        {
          style: 'smaller',
          fillColor: colorTaskRow(item.taskType),
          colSpan: 2,
          margin: [-5, -3, -6, -3],
          layout: {
            defaultBorder: false
          },
          table: {
            widths: [100, 100],
            body: companyCol
          }
        },
        '',
        {
          text: `Typ: ${item.transport.name} \nRej.P: ${item.forwarderRegistrationNumberFront || '-'} \nRej.T: ${item.forwarderRegistrationNumberBack || '-'}`,
          style: 'smaller',
          alignment: 'left',
          fillColor: colorTaskRow(item.taskType)
        },
        {
          text: getComment(item.comment),
          style: 'smaller',
          alignment: 'center',
          fillColor: colorTaskRow(item.taskType)
        }
      ];
      body.push(taskRow);
    });

    return body;
  }

  drawTable() {
    return {
      pageOrientation: 'portrait',
      pageMargins: [50, 50, 50, 50],
      content: [
        {
          columns: [
            {
              image: logoBase64,
              width: 160,
              margin: [0, 10]
            },
            this.note && this.note [0] &&
            {
              text: this.note[0].note || '',
              width: 460,
              margin: [100, 10],
              style: 'note'
            }
          ],
        },
        {
          table: {
            widths: ['auto', 'auto', 'auto', 100, 100, 70, 'auto'],
            body: this.createTable()
          }
        }
      ],
      styles: {
        header: {
          fontSize: 14,
          bold: true
        },
        note: {
          bold: true,
        },
        smaller: {
          fontSize: 8
        },
        smallerNoBorder: {
          fontSize: 8
        }
      }
    };

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.companyBranchId.currentValue && this.pdfForm.get('dateFilter').value) {
      this.getTasks();
    }
  }

  getTasks(downloadPDF = false) {
    this.buttonLoading = true;
    this.getDescriptionForAdditionalFieldIToPdf();
    this.tasksService
      .getTasksListByDay(this.parseDate, this.companyBranchId)
      .subscribe(res => {
        this.buttonLoading = false;
        if (res.status === 'OK') {
          this.tasksList = res.tasks.filter(task => task.status !== TaskStatuses.Cancelled);
          if (res.tasks.length === 0) {
            this.buttonDisabled = true;
          } else {
            this.buttonDisabled = false;
          }
          if (downloadPDF) {
            this.downloadReportFile();
          }
        }
      });
  }

  get parseDate() {
    const selectedDate = new Date(this.pdfForm.get('dateFilter').value);
    const year = selectedDate.getFullYear();
    const month = selectedDate.getMonth() + 1;
    const day = selectedDate.getDate();
    return `${year}-${`0${month}`.slice(-2)}-${`0${day}`.slice(-2)}`;
  }

  public getDescriptionForAdditionalFieldIToPdf(): any {
    const givenDay = moment(this.pdfForm.get('dateFilter').value).format('YYYY-MM-DD');
    this.noteCalendarService.getNoteList({
      page: 1,
      limit: 1000, dateFrom: givenDay, dateTo: givenDay, companyBranchId: this.companyBranchId
    }).subscribe(res => {
      this.note = res.calendarNotes.map(item => ({
        date: item.date,
        note: item.note
      }));
    });
  }

  getReport() {
    this.getTasks(true);
  }

  downloadReportFile() {
    pdfMake
      .createPdf(this.drawTable())
      .download(`${this.translateService.instant('TASKS.PDFFILTER.FILENAME')}-${this.parseDate}.pdf`);
  }
}
