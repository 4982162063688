var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { FuseTranslationLoaderService } from "../../../../../../@fuse/services/translation-loader.service";
import { AuthService } from "../../../../../services/auth.service";
import { CompanyKpoEditorComponent } from "app/modules/company-kpo/components/company-kpo-editor/company-kpo-editor.component";
import { CompanyKpoService } from "app/services/company-kpo.service";
import { List } from "../../../../../shared/models/list.class";
import { locale as english } from "../../../../../translations/en";
import { locale as polish } from "../../../../../translations/pl";
var CompanyKpoListComponent = /** @class */ (function (_super) {
    __extends(CompanyKpoListComponent, _super);
    function CompanyKpoListComponent(companyKpoService, authService, matDialog, _fuseTranslationLoaderService) {
        var _this = _super.call(this) || this;
        _this.companyKpoService = companyKpoService;
        _this.authService = authService;
        _this.matDialog = matDialog;
        _this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        _this.displayedColumns = ["name", "surname", "email", "phone"];
        _this.listFilters = {};
        _this.requestParams = {
            page: 1,
            limit: 10,
            sort: "surname",
            order: "asc",
        };
        _this.user = _this.authService.getUser();
        _this.reloadList$ = companyKpoService.onEditEvent.subscribe(function () {
            _this.getElementsList();
        });
        _this._fuseTranslationLoaderService.loadTranslations(polish, english);
        _this.getElementsList = function () {
            _this.companyKpoService
                .getCompanyKpoList(_this.requestParams, _this.companyUuid)
                .subscribe(function (res) {
                _this.totalCount = res.totalCount;
                _this.dataSource = res.kpo;
            });
        };
        return _this;
    }
    CompanyKpoListComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.getElementsList();
    };
    CompanyKpoListComponent.prototype.ngOnDestroy = function () {
        this.reloadList$.unsubscribe();
    };
    CompanyKpoListComponent.prototype.addCompanyKpo = function () {
        this.disableAnimations = true;
        this.matDialog.open(CompanyKpoEditorComponent, {
            panelClass: "company-kpo-editor-dialog",
            autoFocus: false,
            disableClose: true,
            data: {
                mode: "add",
                companyUuid: this.companyUuid,
            },
        });
    };
    CompanyKpoListComponent.prototype.viewCompanyKpo = function (companyKpoUuid) {
        this.disableAnimations = true;
        this.matDialog.open(CompanyKpoEditorComponent, {
            panelClass: "company-kpo-editor-dialog",
            autoFocus: false,
            disableClose: true,
            data: {
                mode: "view",
                companyUuid: this.companyUuid,
                companyKpoUuid: companyKpoUuid,
            },
        });
    };
    return CompanyKpoListComponent;
}(List));
export { CompanyKpoListComponent };
