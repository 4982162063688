export const locale = {
  lang: 'en',
  data: {
    'ORDERS': {
    },
    'FRACTIONS': {
      'TITLE_LIST': 'Fractions list'
    },
    'CALENDAR': {
      'TITLE': 'Calendar'
    },
    'TRANSPORT': {
    },
    'USERS': {
      'TITLE_LIST': 'Users list',
      'TITLE_ADD': 'Add user',
      'TITLE_EDIT': 'Edit user',
      'TITLE_VIEW': 'User details',
      'SAVE': 'Save',
      'CANCEL': 'Cancel',
      'SEARCH_PLACEHOLDER': 'Search'
    },
    'CUSTOMER_COMPANIES': {
    },
    'PACKAGE': {
    },
    'RECYCLING': {
    },
    'NO_ACCESS': {
      'MESSAGE': 'You do not have access to this part of the system.'
    },
    'SHARED': {
      'LOGIN': 'Login',
      'EMAIL': 'Email',
      'PASS': 'Password',
      'CONFIRM_PASS': 'Confirm password',
      'LOGIN_TITLE': 'Login to your account',
      'LOGIN_ERROR': 'User or password is incorrect.',
      'ACCESS_ERROR': 'You do not have access to this part of the system.',
      'NOT_ACTIVE_ERROR': 'Your account is inactive. Activate your account or contact Administrator.',
      'USER_ERROR': 'User does not exist!',
      'EMAIL_ERROR': 'Please enter a valid email address',
      'NO_EMAIL_ERROR': 'Email is required',
      'NO_PASS_ERROR': 'Password is required',
      'FORGOT_PASS': 'Forgot password?',
      'RESET_PASS': 'Reset password',
      'RESET_PASS_TIP': 'Enter your email address and we will send you a recovery link.',
      'SEND': 'Send',

      'MESSAGES': {
        'FORGOT_PASSWORD_SUCCESS': 'Password recovery email sent.',
        'ERROR': 'Error occured. Try again.',
        'SESSION_EXPIRED': 'Session expired please login again'
      },
      'VALIDATORS': {
        'INCORRECT_EMAIL': 'Incorrect e-mail address'
      }
    }
  }
};
