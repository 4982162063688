import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RequestParams } from '../shared/models/list.model';
import { AuthService } from './auth.service';
import { TaskWarning, TasksWarningsListResponse } from '../shared/models/task-warnings.model';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TaskWarningsService {
  onEditEvent: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient, private authService: AuthService) {
  }

  getTaskWarningList(params: RequestParams): Observable<TasksWarningsListResponse> {
    return this.http.get(`${environment.apiUrl}/${this.authService.getUserType()}/tasks/warning`, {params: params})
      .pipe(map((res: TasksWarningsListResponse) => res));
  }

  createTaskWarning(taskWarning: TaskWarning): Observable<any> {
    return this.http.post(`${environment.apiUrl}/${this.authService.getUserType()}/tasks/warning`, taskWarning).pipe(
      map((res: any) => res)
    );
  }

  deleteTaskWarning(taskWarningId: TaskWarning['uuid']) {
    return this.http.delete<any>(`${environment.apiUrl}/${this.authService.getUserType()}/tasks/warning/${taskWarningId}`);
  }

}