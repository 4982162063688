import { Component, OnDestroy, OnInit } from '@angular/core';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { locale as polish } from '../../../../translations/pl';
import { locale as english } from '../../../../translations/en';
import { MatDialog } from '@angular/material';
import { TagsService } from '../../../../services/tags.service';
import { TagEditorComponent } from '../tag-editor/tag-editor.component';
import { Tag, TagsListRespone } from '../../../../shared/models/tags.model';
import { List } from '../../../../shared/models/list.class';
import { ListFilters } from '../../../../shared/models/list.model';
import { fuseAnimations } from '../../../../../@fuse/animations';
import { remondisListAnimation } from '../../../../shared/consts/remondis.lists.animation';
import { User } from '../../../../shared/models/users.model';
import { FormControl } from '@angular/forms';
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-tags-list',
  templateUrl: './tags-list.component.html',
  styleUrls: ['./tags-list.component.scss'],
  animations: [...fuseAnimations, ...remondisListAnimation]
})
export class TagsListComponent extends List implements OnInit, OnDestroy {
  public displayedColumns = ['name', 'active'];
  public user: User;
  public searchTag: FormControl;
  private listFilters: ListFilters = {};
  private reloadList$;

  constructor(private tagsService: TagsService,
              private matDialog: MatDialog,
              private authService: AuthService,
              private _fuseTranslationLoaderService: FuseTranslationLoaderService) {
    super();

    this._fuseTranslationLoaderService.loadTranslations(polish, english);

    this.reloadList$ = tagsService.onEditEvent.subscribe(
      () => {
        this.getElementsList();
      });

    this.getElementsList = () => {
      this.tagsService.getTagsList(this.getQueryParams())
        .subscribe((res: TagsListRespone) => {
          this.totalCount = res.totalCount;
          this.dataSource = res.tags;
        });
    };
  }

  ngOnInit() {
    super.ngOnInit();
    this.user = this.authService.getUser();
    this.getElementsList();
    this.createSearchFormControl();
  }

  ngOnDestroy() {
    this.reloadList$.unsubscribe();
  }

  public addTag() {
    this.disableAnimations = true;
    let editorDialogRef = this.matDialog.open(TagEditorComponent, {
      panelClass: 'tag-editor-dialog',
      autoFocus: false,
      disableClose: true,
      data: {
        mode: 'add'
      }
    });
  }

  public viewTag(tagUuid: Tag['uuid']) {
    this.disableAnimations = true;

    let editorDialogRef = this.matDialog.open(TagEditorComponent, {
      panelClass: 'tag-editor-dialog',
      autoFocus: false,
      disableClose: true,
      data: {
        mode: 'view',
        tagUuid: tagUuid
      }
    });
  }

  public clearSearch() {
    if (this.searchTag.value) {
      this.searchTag.setValue('');
    }
  }

  public getListFilters(filters: ListFilters) {
    this.listFilters = filters;
    this.requestParams.page = 1;
    this.disableAnimations = true;
    this.getElementsList();
  }

  private createSearchFormControl() {
    this.searchTag = new FormControl('');
    this.searchTag.valueChanges.debounceTime(300).subscribe((value) => {
      this.requestParams.search = value;
      this.requestParams.page = 1;
      this.disableAnimations = true;
      this.getElementsList();
    });
  }

  private getQueryParams() {
    return Object.assign({}, this.requestParams, this.listFilters);
  }
}
