<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">{{getTitle()}}</span>
      <button mat-icon-button (click)="packageTypeEditorDialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>

    <form [formGroup]="packageTypeForm">

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'PACKAGE.NAME' | translate}}</mat-label>
          <mat-icon matSuffix class="secondary-text">local_mall</mat-icon>
          <input name="name" formControlName="name" matInput required [attr.data-cy]="'packageName'">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'PACKAGE.DESC' | translate}}</mat-label>
          <textarea name="description" formControlName="description" matInput [attr.data-cy]="'packageDescription'"></textarea>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'PACKAGE.LENGTH' | translate}} [m]</mat-label>
          <input name="length" formControlName="length" matInput [attr.data-cy]="'packageLength'">
          <mat-error
            *ngIf="packageTypeForm.get('length').touched && packageTypeForm.controls['length'].errors?.pattern">
            {{'SHARED.MESSAGES.DECIMAL_ERROR_THREE' | translate}}
          </mat-error>
          <mat-error
            *ngIf="packageTypeForm.get('length').touched && packageTypeForm.controls['length'].errors?.smaller">
            {{'SHARED.MESSAGES.SMALLER_EQUAL_ZERO' | translate}}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'PACKAGE.WIDTH' | translate}} [m]</mat-label>
          <input name="width" formControlName="width" matInput [attr.data-cy]="'packageWidth'">
          <mat-error
            *ngIf="packageTypeForm.get('width').touched && packageTypeForm.controls['width'].errors?.pattern">
            {{'SHARED.MESSAGES.DECIMAL_ERROR_THREE' | translate}}
          </mat-error>
          <mat-error
            *ngIf="packageTypeForm.get('width').touched && packageTypeForm.controls['width'].errors?.smaller">
            {{'SHARED.MESSAGES.SMALLER_EQUAL_ZERO' | translate}}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'PACKAGE.HEIGHT' | translate}} [m]</mat-label>
          <input name="height" formControlName="height" matInput [attr.data-cy]="'packageHeight'">
          <mat-error
            *ngIf="packageTypeForm.get('height').touched && packageTypeForm.controls['height'].errors?.pattern">
            {{'SHARED.MESSAGES.DECIMAL_ERROR_THREE' | translate}}
          </mat-error>
          <mat-error
            *ngIf="packageTypeForm.get('height').touched && packageTypeForm.controls['height'].errors?.smaller">
            {{'SHARED.MESSAGES.SMALLER_EQUAL_ZERO' | translate}}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'PACKAGE.VOLUME' | translate}} [m<sup>3</sup>]</mat-label>
          <input name="volume" formControlName="volume" matInput [attr.data-cy]="'packageVolume'">
          <mat-error
            *ngIf="packageTypeForm.get('volume').touched && packageTypeForm.controls['volume'].errors?.pattern">
            {{'SHARED.MESSAGES.DECIMAL_ERROR_THREE' | translate}}
          </mat-error>
          <mat-error
            *ngIf="packageTypeForm.get('volume').touched && packageTypeForm.controls['volume'].errors?.smaller">
            {{'SHARED.MESSAGES.SMALLER_EQUAL_ZERO' | translate}}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'PACKAGE.CAPACITY' | translate}} [T]</mat-label>
          <input name="capacity" formControlName="capacity" matInput [attr.data-cy]="'packageCapacity'">
          <mat-error
            *ngIf="packageTypeForm.get('capacity').touched && packageTypeForm.controls['capacity'].errors?.pattern">
            {{'SHARED.MESSAGES.DECIMAL_ERROR_THREE' | translate}}
          </mat-error>
          <mat-error
            *ngIf="packageTypeForm.get('capacity').touched && packageTypeForm.controls['capacity'].errors?.smaller">
            {{'SHARED.MESSAGES.SMALLER_EQUAL_ZERO' | translate}}
          </mat-error>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex [attr.data-cy]="'packageUnit'">
          <mat-label>{{'PACKAGE.UNIT' | translate}}</mat-label>
          <mat-select formControlName="unit">
            <mat-option value="m3" [attr.data-cy]="'m3'">m<sup>3</sup></mat-option>
            <mat-option value="pieces" [attr.data-cy]="'pieces'">{{'PACKAGE.UNIT_TYPES.PIECES' | translate}}</mat-option>
            <mat-option value="kg" [attr.data-cy]="'kg'">{{'PACKAGE.UNIT_TYPES.KG' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex [attr.data-cy]="'packageIsPublic'">
          <mat-label>{{'PACKAGE.VISIBLE_BY_CLIENT' | translate}}</mat-label>
          <mat-select formControlName="isPublic">
            <mat-option [value]="true" [attr.data-cy]="'VISIBLE'">{{'PACKAGE.VISIBLE_STATUSES.VISIBLE' | translate}}</mat-option>
            <mat-option [value]="false" [attr.data-cy]="'INVISIBLE'">{{'PACKAGE.VISIBLE_STATUSES.INVISIBLE' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex [attr.data-cy]="'packageIsActive'">
          <mat-label>{{'PACKAGE.STATUS' | translate}}</mat-label>
          <mat-select formControlName="active">
            <mat-option [value]="true" [attr.data-cy]="'ACTIVE'">{{'PACKAGE.STATUSES.ACTIVE' | translate}}</mat-option>
            <mat-option [value]="false" [attr.data-cy]="'INACTIVE'">{{'PACKAGE.STATUSES.INACTIVE' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </form>

  </div>

  <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

    <button *ngIf="mode === 'edit'"
            mat-button
            [attr.data-cy]="'packageDelete'"
            class="mr-8"
            (click)="deletePackageType()">
      {{'PACKAGE.DELETE' | translate}}
    </button>

    <button *ngIf="mode === 'view' && user.roleType === 'admin'"
            mat-button
            [attr.data-cy]="'packageEdit'"
            class="mr-8"
            (click)="enableEditMode()">
      {{'PACKAGE.EDIT' | translate}}
    </button>

    <button *ngIf="mode === 'edit' || mode === 'add'"
            mat-button
            [attr.data-cy]="'packageSave'"
            class="save-button"
            (click)="submitPackageType()"
            [disabled]="isLoading">
      <span *ngIf="!isLoading"> {{'PACKAGE.SAVE' | translate}}</span>
      <span *ngIf="isLoading" class="loading-spinner-wrapper">
        <mat-progress-spinner mode="indeterminate"
                              [strokeWidth]="2"
                              [diameter]="20">
        </mat-progress-spinner>
      </span>
    </button>

  </div>
</div>