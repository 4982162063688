import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { ReportsListComponent } from './components/reports-list/reports-list.component';
import { Routes } from './reports.routes';
import { ReportsContainerComponent } from './reports-container.component';

@NgModule({
  declarations: [
    ReportsContainerComponent,
    ReportsListComponent,
  ],
  imports: [
    RouterModule.forChild(Routes),
    SharedModule,
  ],
  exports: [
    ReportsListComponent,
  ]
})
export class ReportsModule { }
