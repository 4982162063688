var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit, } from "@angular/core";
import { FormBuilder, FormControl, Validators, } from "@angular/forms";
import { MatDialog, MatDialogRef, MatSnackBar, } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { zip } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { FuseTranslationLoaderService } from "../../../../../../@fuse/services/translation-loader.service";
import { AuthService } from "../../../../../services/auth.service";
import { CompaniesService } from "../../../../../services/companies.service";
import { FilteredListsService } from "../../../../../services/filtered-lists.service";
import { OrdersService } from "../../../../../services/orders.service";
import { UsersService } from "../../../../../services/users.service";
import { OrderTypes } from "../../../../../shared/consts/orders";
import { ItemsOrigins } from "../../../../../shared/models/history.model";
import { OrderImport, OrderSatuses, } from "../../../../../shared/models/orders.model";
import { RoleTypes, UserTypes } from "../../../../../shared/models/users.model";
import { markFormGroupTouched } from "../../../../../shared/utils/markFormGroupAsTouched";
import { mapOrderDataApiValues, mapOrderDataToUuid, } from "../../../../../shared/utils/tasks-map";
import { ValidateItemsList } from "../../../../../shared/validators/itemsList.validator";
import { locale as english } from "../../../../../translations/en";
import { locale as polish } from "../../../../../translations/pl";
import { CompanyEditorComponent } from "../../../../companies/components/company-editor/company-editor.component";
import { ConfirmModalComponent } from "../../../../shared/components/confirm-modal/confirm-modal.component";
import { HistoryDialogComponent } from "../../../../shared/components/history-dialog/history-dialog.component";
import { TaskImportFormComponent } from "../../../../tasks/components/task-forms/task-import-form/task-import-form.component";
var OrderImportFormComponent = /** @class */ (function () {
    function OrderImportFormComponent(orderEditorDialogRef, data, matDialog, authService, ordersService, companiesService, filteredListsService, usersService, translateService, snackBar, formBuilder, _fuseTranslationLoaderService) {
        this.orderEditorDialogRef = orderEditorDialogRef;
        this.data = data;
        this.matDialog = matDialog;
        this.authService = authService;
        this.ordersService = ordersService;
        this.companiesService = companiesService;
        this.filteredListsService = filteredListsService;
        this.usersService = usersService;
        this.translateService = translateService;
        this.snackBar = snackBar;
        this.formBuilder = formBuilder;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this.orderStatuses = OrderSatuses;
        this.userTypes = UserTypes;
        this.roleTypes = RoleTypes;
        this.canUserEdit = false;
        this.canCancelOrder = false;
        this.canGenerateTask = false;
        this.canViewTask = false;
        this.transportOptions = [];
        this.customerCompanyOptions = [];
        this.companyBranches = [];
        this.isLoading = false;
        this.filterParams = {
            page: 1,
            limit: 20,
            sort: "name",
            order: "asc",
        };
        this.filterParamsFullList = {
            page: 1,
            limit: 1000,
            sort: "name",
            order: "asc",
            active: "true",
        };
        this.isCloseDialogOpen = false; // without this, you can open the dialog multiple times
        this.mode = data.mode;
        this.orderUuid = data.orderUuid;
        this.user = this.authService.user.user;
        this._fuseTranslationLoaderService.loadTranslations(polish, english);
    }
    OrderImportFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.createForm();
        this.createSearchFilters();
        if (this.mode === "edit" || this.mode === "view" || this.mode === "clone") {
            this.ordersService
                .getOrder(this.orderUuid)
                .pipe(map(function (res) {
                _this.order = res;
                _this.canCancelOrder =
                    _this.order &&
                        _this.order.status !== _this.orderStatuses.Cancelled &&
                        (_this.user.userType === _this.userTypes.Sales ||
                            _this.user.userType === _this.userTypes.Logistician ||
                            _this.user.uuid === _this.order.createdBy.uuid);
                _this.canUserEdit =
                    _this.order &&
                        !_this.order.taskNumber &&
                        (_this.user.userType === _this.userTypes.Sales ||
                            _this.user.userType === _this.userTypes.Logistician ||
                            _this.user.uuid === _this.order.createdBy.uuid);
                _this.canGenerateTask =
                    _this.order &&
                        _this.order.status !== _this.orderStatuses.Cancelled &&
                        !_this.order.taskNumber &&
                        _this.user.userType === _this.userTypes.Logistician;
                _this.canViewTask =
                    _this.order &&
                        _this.order.taskNumber &&
                        _this.user.userType === _this.userTypes.Logistician;
            }), switchMap(function () {
                return zip(_this.filteredListsService.getTransportList(_this.filterParamsFullList), _this.filteredListsService.getCompaniesList(_this.filterParams), _this.usersService.getCompanyBranchList(), _this.companiesService.getCompany(_this.order.customerCompany.uuid));
            }))
                .subscribe(function (_a) {
                var transportRes = _a[0], companiesRes = _a[1], branchesRes = _a[2], company = _a[3];
                _this.transportOptions = transportRes.transports;
                _this.customerCompanyOptions = companiesRes.customerCompanies;
                _this.companyBranches = branchesRes.branches;
                _this.bdo = company.bdo;
                _this.createForm(_this.order);
                _this.checkCustomerCompaniesMissing();
                _this.checkTransportMissing();
            });
        }
        else {
            this.todayDate = new Date();
            this.filteredListsService
                .getTransportList(this.filterParamsFullList)
                .subscribe(function (res) { return (_this.transportOptions = res.transports); });
            this.filteredListsService
                .getCompaniesList(this.filterParams)
                .subscribe(function (res) { return (_this.customerCompanyOptions = res.customerCompanies); });
            this.usersService
                .getCompanyBranchList()
                .subscribe(function (res) { return (_this.companyBranches = res.branches); });
        }
    };
    OrderImportFormComponent.prototype.submitOrder = function () {
        var _this = this;
        this.isLoading = true;
        markFormGroupTouched(this.orderImportForm);
        if (this.orderImportForm.invalid) {
            this.isLoading = false;
            return;
        }
        var newOrder = JSON.parse(JSON.stringify(this.orderImportForm.getRawValue()));
        if (newOrder.suggestedDate) {
            newOrder.suggestedDate = moment(newOrder.suggestedDate).format("YYYY-MM-DD");
        }
        newOrder.transportId = newOrder.transport ? newOrder.transport.uuid : null;
        newOrder.customerCompanyId = newOrder.customerCompany
            ? newOrder.customerCompany.uuid
            : null;
        newOrder.bdo = newOrder.bdo ? newOrder.bdo.toString() : null;
        newOrder.bdoFree = Boolean(newOrder.bdoFree && !newOrder.bdo);
        newOrder.bdoPeopleCollection = Boolean(newOrder.bdoPeopleCollection && !newOrder.bdo);
        delete newOrder.transport;
        delete newOrder.customerCompany;
        newOrder.orderData = mapOrderDataApiValues(newOrder.orderData);
        newOrder.price = Number(String(newOrder.price).replace(",", ".")) || null;
        if (this.mode === "add" || this.mode === "clone") {
            this.ordersService.createOrder(newOrder).subscribe(function () {
                _this.orderEditorDialogRef.close();
                _this.orderEditorDialogRef.afterClosed().subscribe(function () {
                    _this.ordersService.onEditEvent.emit();
                });
                _this.snackBar.open(_this.translateService.instant("ORDERS.MESSAGES.CREATE_SUCCESS"), "", { duration: 5000 });
            }, function () {
                _this.isLoading = false;
                _this.snackBar.open(_this.translateService.instant("ORDERS.MESSAGES.CREATE_ERROR"), "", { duration: 5000 });
            });
        }
        else if (this.mode === "view" && !this.canUserEdit) {
            this.ordersService
                .updateOrderDate(this.orderUuid, newOrder.suggestedDate)
                .subscribe(function () {
                _this.orderEditorDialogRef.close();
                _this.orderEditorDialogRef.afterClosed().subscribe(function () {
                    _this.ordersService.onEditEvent.emit();
                });
                _this.snackBar.open(_this.translateService.instant("ORDERS.MESSAGES.UPDATE_SUCCESS"), "", { duration: 5000 });
            }, function () {
                _this.isLoading = false;
                _this.snackBar.open(_this.translateService.instant("ORDERS.MESSAGES.UPDATE_ERROR"), "", { duration: 5000 });
            });
        }
        else {
            this.ordersService.updateOrder(this.orderUuid, newOrder).subscribe(function () {
                _this.orderEditorDialogRef.close();
                _this.orderEditorDialogRef.afterClosed().subscribe(function () {
                    _this.ordersService.onEditEvent.emit();
                });
                _this.snackBar.open(_this.translateService.instant("ORDERS.MESSAGES.UPDATE_SUCCESS"), "", { duration: 5000 });
            }, function () {
                _this.isLoading = false;
                _this.snackBar.open(_this.translateService.instant("ORDERS.MESSAGES.UPDATE_ERROR"), "", { duration: 5000 });
            });
        }
    };
    OrderImportFormComponent.prototype.deleteOrder = function () {
        var _this = this;
        var deleteDialogRef = this.matDialog.open(ConfirmModalComponent, {
            disableClose: false,
            autoFocus: false,
        });
        deleteDialogRef.componentInstance.confirmMessage =
            this.translateService.instant("ORDERS.MESSAGES.DELETE_QUESTION");
        deleteDialogRef.componentInstance.titleMessage =
            this.translateService.instant("ORDERS.MESSAGES.DELETE_ORDER_TITLE");
        deleteDialogRef.componentInstance.confirmButton =
            this.translateService.instant("ORDERS.DELETE");
        deleteDialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.ordersService.deleteOrder(_this.orderUuid).subscribe(function () {
                    _this.orderEditorDialogRef.close();
                    _this.orderEditorDialogRef.afterClosed().subscribe(function () {
                        _this.ordersService.onEditEvent.emit();
                    });
                    _this.snackBar.open(_this.translateService.instant("ORDERS.MESSAGES.DELETE_SUCCESS"), "", { duration: 5000 });
                }, function () {
                    _this.snackBar.open(_this.translateService.instant("ORDERS.MESSAGES.DELETE_ERROR"), "", { duration: 5000 });
                });
            }
        });
    };
    OrderImportFormComponent.prototype.generateTask = function () {
        if (this.order.salesAccepted) {
            this.orderEditorDialogRef.close();
            this.matDialog.open(TaskImportFormComponent, {
                panelClass: "task-editor-dialog",
                autoFocus: false,
                disableClose: true,
                data: {
                    mode: "add",
                    orders: [this.order],
                },
            });
        }
        else {
            this.snackBar.open(this.translateService.instant("ORDERS.MESSAGES.GENERATE_ERROR"), "", { duration: 5000 });
        }
    };
    OrderImportFormComponent.prototype.viewTask = function () {
        this.orderEditorDialogRef.close();
        this.matDialog.open(TaskImportFormComponent, {
            panelClass: "task-editor-dialog",
            autoFocus: false,
            disableClose: true,
            data: {
                mode: "view",
                taskNumber: this.order.taskNumber,
            },
        });
    };
    OrderImportFormComponent.prototype.changeOrderStatus = function (status) {
        var _this = this;
        var changeStatusDialogRef = this.matDialog.open(ConfirmModalComponent, {
            disableClose: false,
            autoFocus: false,
        });
        if (status === this.orderStatuses.Planned) {
            changeStatusDialogRef.componentInstance.confirmMessage =
                this.translateService.instant("ORDERS.MESSAGES.PLAN_QUESTION");
            changeStatusDialogRef.componentInstance.titleMessage =
                this.translateService.instant("ORDERS.MESSAGES.PLAN_ORDER_TITLE");
            changeStatusDialogRef.componentInstance.confirmButton =
                this.translateService.instant("ORDERS.PLAN_ORDER");
            changeStatusDialogRef.afterClosed().subscribe(function (result) {
                if (result) {
                    _this.ordersService
                        .changeOrderStatus(_this.orderUuid, _this.orderStatuses.Planned)
                        .subscribe(function () {
                        _this.snackBar.open(_this.translateService.instant("ORDERS.MESSAGES.PLAN_SUCCESS"), "", { duration: 5000 });
                        _this.order.status = _this.orderStatuses.Planned;
                        _this.ordersService.onEditEvent.emit();
                    }, function () {
                        _this.snackBar.open(_this.translateService.instant("ORDERS.MESSAGES.PLAN_ERROR"), "", { duration: 5000 });
                    });
                }
            });
        }
        else {
            changeStatusDialogRef.componentInstance.confirmMessage =
                this.translateService.instant("ORDERS.MESSAGES.CANCEL_QUESTION");
            changeStatusDialogRef.componentInstance.titleMessage =
                this.translateService.instant("ORDERS.MESSAGES.CANCEL_ORDER_TITLE");
            changeStatusDialogRef.componentInstance.confirmButton =
                this.translateService.instant("ORDERS.CANCEL_ORDER");
            changeStatusDialogRef.afterClosed().subscribe(function (result) {
                if (result) {
                    _this.ordersService
                        .changeOrderStatus(_this.orderUuid, _this.orderStatuses.Cancelled)
                        .subscribe(function () {
                        _this.snackBar.open(_this.translateService.instant("ORDERS.MESSAGES.CANCEL_SUCCESS"), "", { duration: 5000 });
                        _this.order.status = _this.orderStatuses.Cancelled;
                        _this.canCancelOrder = false;
                        _this.ordersService.onEditEvent.emit();
                        _this.orderEditorDialogRef.close();
                    }, function () {
                        _this.snackBar.open(_this.translateService.instant("ORDERS.MESSAGES.CANCEL_ERROR"), "", { duration: 5000 });
                    });
                }
            });
        }
    };
    OrderImportFormComponent.prototype.setCustomerCompany = function (customerCompany) {
        if (customerCompany && customerCompany.trusted) {
            this.orderImportForm.get("salesAccepted").patchValue(true);
        }
        else {
            this.orderImportForm.get("salesAccepted").patchValue(false);
        }
    };
    OrderImportFormComponent.prototype.compareObjectsByUuid = function (val1, val2) {
        return val1 && val2 && val1.uuid && val2.uuid && val1.uuid === val2.uuid;
    };
    OrderImportFormComponent.prototype.getTitle = function () {
        switch (this.mode) {
            case "add":
            case "clone":
                return this.translateService.instant("ORDERS.TITLE_ADD");
            case "edit":
                return this.translateService.instant("ORDERS.TITLE_EDIT");
            case "view":
                return this.translateService.instant("ORDERS.TITLE_VIEW");
            default:
                return;
        }
    };
    OrderImportFormComponent.prototype.showHistory = function () {
        this.matDialog.open(HistoryDialogComponent, {
            panelClass: "history-dialog",
            autoFocus: false,
            disableClose: true,
            data: {
                item: this.order,
                itemOrigin: ItemsOrigins.Order,
                itemId: this.orderUuid,
                itemNumber: this.order.orderNumber,
            },
        });
    };
    OrderImportFormComponent.prototype.enableEditMode = function () {
        this.orderEditorDialogRef.close();
        this.matDialog.open(OrderImportFormComponent, {
            panelClass: "order-editor-dialog",
            autoFocus: false,
            disableClose: true,
            data: {
                mode: "edit",
                orderUuid: this.orderUuid,
            },
        });
    };
    OrderImportFormComponent.prototype.enableCloneMode = function () {
        this.orderEditorDialogRef.close();
        this.matDialog.open(OrderImportFormComponent, {
            panelClass: "order-editor-dialog",
            autoFocus: false,
            disableClose: true,
            data: {
                mode: "clone",
                orderUuid: this.orderUuid,
            },
        });
    };
    OrderImportFormComponent.prototype.addCustomerCompany = function () {
        var _this = this;
        var editorDialogRef = this.matDialog.open(CompanyEditorComponent, {
            panelClass: "company-editor-dialog",
            autoFocus: false,
            disableClose: true,
            data: {
                mode: "add",
            },
        });
        editorDialogRef.componentInstance.onAddCompany.subscribe(function (res) {
            _this.customerCompanyOptions.push(res);
            _this.orderImportForm.patchValue({ customerCompany: res });
            _this.companySelect.close();
        });
    };
    OrderImportFormComponent.prototype.createForm = function (inputData) {
        var _this = this;
        if (inputData === void 0) { inputData = new OrderImport(); }
        inputData.orderData = mapOrderDataToUuid(inputData.orderData);
        this.orderImportForm = this.formBuilder.group({
            customerCompany: new FormControl(inputData.customerCompany || null, Validators.required),
            salesAccepted: new FormControl(inputData.salesAccepted || false, Validators.required),
            companyBranchId: new FormControl(inputData.companyBranch ? inputData.companyBranch.uuid : null, Validators.required),
            transport: new FormControl(inputData.transport || null, Validators.required),
            registrationNumFront: new FormControl(inputData.registrationNumFront || null),
            registrationNumBack: new FormControl(inputData.registrationNumBack || null),
            orderData: new FormControl(inputData.orderData || [], [
                Validators.required,
                ValidateItemsList,
            ]),
            suggestedDate: new FormControl(this.mode === "clone"
                ? null
                : inputData.suggestedDate
                    ? new Date(inputData.suggestedDate)
                    : null),
            tags: new FormControl(inputData.tags || []),
            kpoEmails: new FormControl(inputData.kpoEmails || null),
            comment: new FormControl(inputData.comment || null),
            bdo: new FormControl(inputData.bdo || null, Validators.pattern("[0-9]+")),
            bdoFree: new FormControl(inputData.bdoFree && !inputData.bdo && this.mode !== "add"),
            bdoPeopleCollection: new FormControl(inputData.bdoPeopleCollection && !inputData.bdo && this.mode !== "add"),
            reinvoice: new FormControl(inputData.reinvoice || false),
            price: new FormControl(inputData.price ? String(inputData.price).replace(".", ",") : ""),
            reinvoiceNumber: new FormControl(inputData.reinvoiceNumber || ""),
            invoiceComment: new FormControl(inputData.invoiceComment || ""),
            referenceNumber: new FormControl(this.mode === "clone" ? null : inputData.referenceNumber || null),
            orderType: new FormControl(inputData.orderType || OrderTypes.Import),
            status: new FormControl(this.mode === "clone" ? "new" : inputData.status || "new"),
        });
        if (this.mode === "view") {
            this.orderImportForm.disable();
        }
        if (this.mode === "view" && !this.canUserEdit) {
            this.orderImportForm.disable();
            this.orderImportForm.get("suggestedDate").enable();
        }
        if (this.mode === "add" ||
            this.mode === "clone" ||
            this.user.userType !== this.userTypes.Logistician) {
            this.orderImportForm.get("status").disable();
        }
        if ((this.mode === "clone" && this.orderImportForm.get("bdoFree").value) ||
            this.orderImportForm.get("bdo").value) {
            this.orderImportForm.get("bdo").disable();
        }
        if (this.mode === "add" || this.mode === "clone" || this.mode === "edit") {
            this.orderImportForm.get("bdoFree").valueChanges.subscribe(function (bdoFree) {
                var pickedCustomerCompany = _this.orderImportForm.get("customerCompany").value;
                var bdoPeopleCollection = _this.orderImportForm.get("bdoPeopleCollection").value;
                if (bdoFree) {
                    _this.orderImportForm.get("bdoPeopleCollection").setValue(false);
                    _this.orderImportForm.get("bdo").clearValidators();
                    _this.orderImportForm.get("bdo").setValue("");
                }
                else if (!bdoFree &&
                    !bdoPeopleCollection &&
                    ((pickedCustomerCompany && pickedCustomerCompany.bdo) ||
                        (_this.order && _this.order.bdo))) {
                    _this.orderImportForm.get("bdo").setValidators(Validators.required);
                    _this.orderImportForm
                        .get("bdo")
                        .setValue(pickedCustomerCompany.bdo || _this.order.bdo);
                }
                else if (!bdoFree &&
                    !bdoPeopleCollection &&
                    (!pickedCustomerCompany ||
                        (!pickedCustomerCompany.bdo && _this.mode !== "clone"))) {
                    _this.orderImportForm.get("bdo").setValidators(Validators.required);
                    _this.orderImportForm.get("bdo").enable();
                }
                else {
                    _this.orderImportForm.get("bdo").setValue(_this.bdo);
                }
            });
            this.orderImportForm
                .get("bdoPeopleCollection")
                .valueChanges.subscribe(function (bdoPeopleCollection) {
                var bdoFree = _this.orderImportForm.get("bdoFree").value;
                if (bdoPeopleCollection) {
                    _this.orderImportForm.get("bdoFree").setValue(false);
                    _this.orderImportForm.get("bdo").clearValidators();
                    _this.orderImportForm.get("bdo").setValue("");
                }
                else if (!bdoPeopleCollection && !bdoFree) {
                    _this.orderImportForm.get("bdo").setValidators(Validators.required);
                    _this.orderImportForm.get("bdo").enable();
                }
            });
        }
        if (this.mode === "add" || this.mode === "clone" || this.mode === "edit") {
            this.orderImportForm
                .get("customerCompany")
                .valueChanges.subscribe(function (pickedCustomerCompany) {
                var bdoFree = _this.orderImportForm.get("bdoFree").value;
                var bdoPeopleCollection = _this.orderImportForm.get("bdoPeopleCollection").value;
                if (pickedCustomerCompany &&
                    pickedCustomerCompany.bdo &&
                    !bdoFree &&
                    !bdoPeopleCollection) {
                    _this.orderImportForm.get("bdo").setValue(pickedCustomerCompany.bdo);
                    _this.orderImportForm.get("bdo").disable();
                }
                else if (pickedCustomerCompany &&
                    !pickedCustomerCompany.bdo &&
                    !bdoFree &&
                    !bdoPeopleCollection) {
                    _this.orderImportForm.get("bdo").setValue("");
                    _this.orderImportForm.get("bdo").enable();
                }
                else if (pickedCustomerCompany &&
                    !pickedCustomerCompany.bdo &&
                    (bdoFree || bdoPeopleCollection)) {
                    _this.orderImportForm.get("bdo").setValue("");
                    _this.orderImportForm.get("bdo").disable();
                }
                _this.orderImportForm
                    .get("kpoEmails")
                    .setValue(pickedCustomerCompany.kpo.map(function (kpo) { return kpo.email; }).join(","));
            });
        }
        if (this.orderImportForm.get("reinvoice").value === false) {
            this.orderImportForm.get("price").disable();
            this.orderImportForm.get("reinvoiceNumber").disable();
        }
    };
    OrderImportFormComponent.prototype.checkCustomerCompaniesMissing = function () {
        var chosenCustomerCompany = this.orderImportForm.get("customerCompany").value;
        if (chosenCustomerCompany &&
            !this.customerCompanyOptions.find(function (item) { return item.uuid === chosenCustomerCompany.uuid; })) {
            this.customerCompanyOptions.push(chosenCustomerCompany);
        }
    };
    OrderImportFormComponent.prototype.checkTransportMissing = function () {
        var chosenTransport = this.orderImportForm.get("transport").value;
        if (chosenTransport &&
            !this.transportOptions.find(function (item) { return item.uuid === chosenTransport.uuid; })) {
            this.transportOptions.push(chosenTransport);
        }
    };
    OrderImportFormComponent.prototype.createSearchFilters = function () {
        var _this = this;
        this.searchCustomerCompany = new FormControl("");
        this.searchTransport = new FormControl("");
        this.searchCustomerCompany.valueChanges
            .debounceTime(300)
            .subscribe(function (value) {
            _this.filteredListsService
                .getCompaniesList(__assign({}, _this.filterParams, { search: value }))
                .subscribe(function (response) {
                _this.customerCompanyOptions = response.customerCompanies;
                !value && _this.checkCustomerCompaniesMissing();
            });
        });
        this.searchTransport.valueChanges.debounceTime(300).subscribe(function (value) {
            _this.filteredListsService
                .getTransportList(__assign({}, _this.filterParamsFullList, { name: value }))
                .subscribe(function (response) {
                _this.transportOptions = response.transports;
                !value && _this.checkTransportMissing();
            });
        });
    };
    OrderImportFormComponent.prototype.getTooltipCompany = function (company) {
        if (company) {
            return company.name + " \n      " + company.address.street + "\n      " + company.address.postalCode + " " + company.address.city;
        }
    };
    OrderImportFormComponent.prototype.reinvoiceChange = function (reinvoiceChecked) {
        if (reinvoiceChecked) {
            this.orderImportForm.get("price").enable();
            this.orderImportForm.get("reinvoiceNumber").enable();
        }
        else {
            this.orderImportForm.get("price").setValue(null);
            this.orderImportForm.get("price").disable();
            this.orderImportForm.get("reinvoiceNumber").setValue("");
            this.orderImportForm.get("reinvoiceNumber").disable();
        }
    };
    OrderImportFormComponent.prototype.clearStartDate = function (e) {
        e.stopPropagation();
        this.orderImportForm.get("suggestedDate").reset();
    };
    OrderImportFormComponent.prototype.closeDialog = function () {
        var _this = this;
        if (!this.orderImportForm.dirty) {
            this.orderEditorDialogRef.close();
            return;
        }
        if (this.isCloseDialogOpen === false) {
            var saveDialogRef = this.matDialog.open(ConfirmModalComponent, {
                disableClose: true,
                autoFocus: false,
            });
            saveDialogRef.componentInstance.confirmMessage =
                this.translateService.instant("SHARED.MESSAGES.CLOSE_DIALOG_WITHOUT_SAVE");
            saveDialogRef.componentInstance.titleMessage =
                this.translateService.instant("SHARED.MESSAGES.USAVED_DATA");
            saveDialogRef.componentInstance.confirmButton =
                this.translateService.instant("SHARED.NO");
            saveDialogRef.componentInstance.skipButton =
                this.translateService.instant("SHARED.YES");
            this.isCloseDialogOpen = true;
            saveDialogRef.afterClosed().subscribe(function (result) {
                _this.isCloseDialogOpen = false;
                if (!result) {
                    _this.orderEditorDialogRef.close();
                }
            });
        }
    };
    return OrderImportFormComponent;
}());
export { OrderImportFormComponent };
