<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <div class="title-wrapper">
        <span *ngIf="itemOrigin" class="title dialog-title mr-8">
          {{'HISTORY.TITLE_' + itemOrigin.toUpperCase() | translate}}
        </span>
        <span class="title dialog-title"> [{{itemNumber}}]</span>
      </div>
      <button mat-icon-button (click)="historyDialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div *ngIf="dataSource">
    <mat-button-toggle-group class="toggle-order" [value]="requestParams.order" (change)="toggleOrder($event.value)">
      <mat-button-toggle class="toggle-button" value="asc">{{'HISTORY.ORDER.ASC' | translate}}</mat-button-toggle>
      <mat-button-toggle class="toggle-button" value="desc">{{'HISTORY.ORDER.DESC' | translate}}</mat-button-toggle>
    </mat-button-toggle-group>

    <mat-accordion>
      <mat-expansion-panel *ngFor="let historyItem of dataSourceNew" [disabled]="historyItem.eventType === 'create'">
        <mat-expansion-panel-header class="header">
          <mat-panel-title class="event-title">
            {{historyItem.createdDate*1000 | date:'yyy-MM-dd HH:mm':'':'pl'}}
          </mat-panel-title>
          <mat-panel-description>
              <span class="event-type" [ngClass]="historyItem.eventType">
                {{'HISTORY.EVENT_TYPES.' + historyItem.type.toUpperCase() | translate}}
              </span>
          </mat-panel-description>
          <mat-panel-description>
            <span *ngIf="historyItem.createdBy">{{historyItem.createdBy?.name}} {{historyItem.createdBy?.surname}}</span>
            <span *ngIf="!historyItem.createdBy">{{'HISTORY.ANONYMOUS_CLIENT' | translate}}</span>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <h3><strong>{{'HISTORY.USER' | translate}}:</strong></h3>
        <p class="mb-4 mt-4">
          <strong *ngIf="historyItem.createdBy">{{historyItem.createdBy?.name}} {{historyItem.createdBy?.surname}}</strong>
          <strong *ngIf="!historyItem.createdBy">{{'HISTORY.ANONYMOUS_CLIENT' | translate}}</strong>
        </p>
        <p class="mb-4 mt-4">
          <small *ngIf="historyItem.createdBy">{{'SHARED.EMAIL' | translate}}: {{historyItem.createdBy?.email}}</small>
        </p>
        <p class="mb-4 mt-4">
          <small *ngIf="historyItem.createdBy">UUID: {{historyItem.createdBy?.uuid | uppercase }}</small>
        </p>
        <mat-divider *ngIf="historyItem.changes"></mat-divider>

        <!--CHANGES-->
        <h3 *ngIf="historyItem.changes && historyItem.changes.taskData">
          <strong>{{'HISTORY.CHANGES' | translate}}</strong></h3>
        <ng-container *ngIf="historyItem.changes">
          <h3 class="section-tittle" *ngIf="historyItem.changes.startDateDay">
            {{'HISTORY.HISTORY_KEYS.taskDate' | translate}}
            <strong>{{historyItem.changes.startDateDay[1]}}</strong>
            -->
            <strong>{{historyItem.changes.startDateDay[0]}}</strong>
          </h3>
          <h3 class="section-tittle" *ngIf="historyItem.changes.startDateHour">
            {{'HISTORY.HISTORY_KEYS.taskStartHour' | translate}}
            <strong>{{historyItem.changes.startDateHour[1]}}</strong>
            -->
            <strong>{{historyItem.changes.startDateHour[0]}}</strong>
          </h3>
          <h3 class="section-tittle" *ngIf="historyItem.changes.endDateHour">
            {{'HISTORY.HISTORY_KEYS.taskEndHour' | translate}}
            <strong>{{historyItem.changes.endDateHour[1]}}</strong>
            -->
            <strong>{{historyItem.changes.endDateHour[0]}}</strong>
          </h3>
          <h3 class="section-tittle" *ngIf="historyItem.changes.status">
            {{'HISTORY.HISTORY_KEYS.status' | translate}}
            <strong>{{'ORDERS.STATUSES.'+ historyItem.changes.status[1].toUpperCase() | translate}}</strong>
            -->
            <strong>{{'ORDERS.STATUSES.'+ historyItem.changes.status[0].toUpperCase() | translate}}</strong>
          </h3>
        </ng-container>
        <div *ngIf="historyItem.changes && historyItem.changes.taskData">
          <h3 class="section-tittle" *ngIf="historyItem.changes.name">
            {{'HISTORY.HISTORY_KEYS.title' | translate}}
            <strong>{{historyItem.changes.name[1]}}</strong>
            -->
            <strong>{{historyItem.changes.name[0]}}</strong>
          </h3>
          <h3 class="section-tittle" *ngIf="historyItem.changes.companyBranch ">
            {{'HISTORY.HISTORY_KEYS.companyBranch' | translate}}
            <strong>{{historyItem.changes.companyBranch.name[1]}}</strong>
            -->
            <strong>{{historyItem.changes.companyBranch.name[0]}}</strong>
          </h3>
          <h3 class="section-tittle" *ngIf="historyItem.changes.transport">
            {{'HISTORY.HISTORY_KEYS.transport' | translate}}
            <strong>{{historyItem.changes.transport.name[1]}}</strong>
            -->
            <strong>{{historyItem.changes.transport.name[0]}}</strong>
          </h3>
          <h3 class="section-tittle" *ngIf="historyItem.changes.registrationNumFront">
            {{'HISTORY.HISTORY_KEYS.registrationNumFront' | translate}}:
            <strong>{{historyItem.changes.registrationNumFront[1]}}</strong>
            --> <strong>{{historyItem.changes.registrationNumFront[0]}}</strong>
          </h3>
          <h3 class="section-tittle" *ngIf="historyItem.changes.registrationNumBack">
            {{'HISTORY.HISTORY_KEYS.registrationNumBack' | translate}}:
            <strong>{{historyItem.changes.registrationNumBack[1]}}</strong>
            --> <strong>{{historyItem.changes.registrationNumBack[0]}}</strong>
          </h3>
          <h3 class="section-tittle" *ngIf="historyItem.changes.referenceNumber">
            {{'HISTORY.HISTORY_KEYS.taskReferenceNumber' | translate}}: <strong>{{historyItem.changes.referenceNumber[1]}}</strong>
            --> <strong>{{historyItem.changes.referenceNumber[0]}}</strong>
          </h3>
          <h3 class="section-tittle" *ngIf="historyItem.changes.comment">
            {{'HISTORY.HISTORY_KEYS.comment' | translate}}
            <strong>{{historyItem.changes.comment[1]}}</strong>
            -->
            <strong>{{historyItem.changes.comment[0]}}</strong>
          </h3>
          <ng-container *ngIf="historyItem.changes.taskForwarder">
             <h3 class="section-tittle" *ngIf="historyItem.changes.taskForwarder.bdo">
              {{'HISTORY.HISTORY_KEYS.taskForwarder.bdo' | translate}}:
              <strong>{{historyItem.changes.taskForwarder.bdo[1]}}</strong>
              --> <strong>{{historyItem.changes.taskForwarder.bdo[0]}}</strong>
            </h3>
            <h3 class="section-tittle" *ngIf="historyItem.changes.taskForwarder.carName">
              {{'HISTORY.HISTORY_KEYS.taskForwarder.carName' | translate}}:
              <strong>{{historyItem.changes.taskForwarder.carName[1]}}</strong>
              --> <strong>{{historyItem.changes.taskForwarder.carName[0]}}</strong>
            </h3>
            <h3 class="section-tittle" *ngIf="historyItem.changes.taskForwarder.carType">
              {{'HISTORY.HISTORY_KEYS.taskForwarder.carType' | translate}}:
              <strong>{{historyItem.changes.taskForwarder.carType[1]}}</strong>
              --> <strong>{{historyItem.changes.taskForwarder.carType[0]}}</strong>
            </h3>
            <h3 class="section-tittle" *ngIf="historyItem.changes.taskForwarder.companyName">
              {{'HISTORY.HISTORY_KEYS.taskForwarder.companyName' | translate}}:
              <strong>{{historyItem.changes.taskForwarder.companyName[1]}}</strong>
              --> <strong>{{historyItem.changes.taskForwarder.companyName[0]}}</strong>
            </h3>
            <h3 class="section-tittle" *ngIf="historyItem.changes.taskForwarder.driverName">
              {{'HISTORY.HISTORY_KEYS.taskForwarder.driverName' | translate}}:
              <strong>{{historyItem.changes.taskForwarder.driverName[1]}}</strong>
              --> <strong>{{historyItem.changes.taskForwarder.driverName[0]}}</strong>
            </h3>
            <h3 class="section-tittle" *ngIf="historyItem.changes.taskForwarder.driverPhone">
              {{'HISTORY.HISTORY_KEYS.taskForwarder.driverPhone' | translate}}:
              <strong>{{historyItem.changes.taskForwarder.driverPhone[1]}}</strong>
              --> <strong>{{historyItem.changes.taskForwarder.driverPhone[0]}}</strong>
            </h3>
            <h3 class="section-tittle" *ngIf="historyItem.changes.taskForwarder.expense">
              {{'HISTORY.HISTORY_KEYS.taskForwarder.expense' | translate}}:
              <strong>{{historyItem.changes.taskForwarder.expense[1]}}</strong>
              --> <strong>{{historyItem.changes.taskForwarder.expense[0]}}</strong>
            </h3>
            <h3 class="section-tittle" *ngIf="historyItem.changes.taskForwarder.invoiceNumber">
              {{'HISTORY.HISTORY_KEYS.taskForwarder.invoiceNumber' | translate}}:
              <strong>{{historyItem.changes.taskForwarder.invoiceNumber[1]}}</strong>
              --> <strong>{{historyItem.changes.taskForwarder.invoiceNumber[0]}}</strong>
            </h3>
            <h3 class="section-tittle" *ngIf="historyItem.changes.taskForwarder.originalCompanyName">
              {{'HISTORY.HISTORY_KEYS.taskForwarder.originalCompanyName' | translate}}:
              <strong>{{historyItem.changes.taskForwarder.originalCompanyName[1]}}</strong>
              --> <strong>{{historyItem.changes.taskForwarder.originalCompanyName[0]}}</strong>
            </h3>
            <h3 class="section-tittle" *ngIf="historyItem.changes.taskForwarder.registrationNumberFront">
              {{'HISTORY.HISTORY_KEYS.taskForwarder.registrationNumberFront' | translate}}:
              <strong>{{historyItem.changes.taskForwarder.registrationNumberFront[1]}}</strong>
              --> <strong>{{historyItem.changes.taskForwarder.registrationNumberFront[0]}}</strong>
            </h3>
            <h3 class="section-tittle" *ngIf="historyItem.changes.taskForwarder.registrationNumberBack">
              {{'HISTORY.HISTORY_KEYS.taskForwarder.registrationNumberBack' | translate}}:
              <strong>{{historyItem.changes.taskForwarder.registrationNumberBack[1]}}</strong>
              --> <strong>{{historyItem.changes.taskForwarder.registrationNumberBack[0]}}</strong>
            </h3>
          </ng-container>

          <ng-container *ngIf="historyItem.changes.taskData.added.length > 0">
            <h3 class="section-tittle">
              <strong>{{(historyItem.changes.taskData.added.length === 1 ? 'HISTORY.HISTORY_KEYS.addedSingle' : 'HISTORY.HISTORY_KEYS.added') | translate}}</strong>
            </h3>
            <app-task-orders-display-array
              [orders]="historyItem.changes.taskData.added"></app-task-orders-display-array>
          </ng-container>
          <ng-container *ngIf="historyItem.changes.taskData.removed.length > 0">
            <h3 class="section-tittle">
              <strong>{{(historyItem.changes.taskData.removed.length === 1 ? 'HISTORY.HISTORY_KEYS.removedSingle' : 'HISTORY.HISTORY_KEYS.removed') | translate}}</strong>
            </h3>
            <app-task-orders-display-array
              [orders]="historyItem.changes.taskData.removed"></app-task-orders-display-array>
          </ng-container>
          <ng-container *ngIf="historyItem.changes.taskData.noIdOrdersBefore.length > 0">
            <h3 class="section-tittle"><strong>{{'HISTORY.HISTORY_KEYS.noIdOrdersBefore' | translate}}</strong></h3>
            <app-task-orders-display-array
              [orders]="historyItem.changes.taskData.noIdOrdersBefore"></app-task-orders-display-array>
          </ng-container>
          <ng-container *ngIf="historyItem.changes.taskData.noIdOrdersAfter.length > 0">
            <h3 class="section-tittle"><strong>{{'HISTORY.HISTORY_KEYS.noIdOrdersAfter' | translate}}</strong></h3>
            <app-task-orders-display-array
              [orders]="historyItem.changes.taskData.noIdOrdersAfter"></app-task-orders-display-array>
          </ng-container>
          <ng-container *ngIf="historyItem.changes.taskData.changed.length > 0">
            <h3 class="section-tittle">
              <strong>{{(historyItem.changes.taskData.changed.length === 1 ? 'HISTORY.HISTORY_KEYS.changedSingle' : 'HISTORY.HISTORY_KEYS.changed') | translate}}</strong>
            </h3>
            <app-task-orders-changes-array
              [changedOrders]="historyItem.changes.taskData.changed"></app-task-orders-changes-array>
          </ng-container>

        </div>
        <ng-container *ngIf="historyItem.changes && !historyItem.changes.taskData">
          <app-order-changes [changes]="historyItem.changes"></app-order-changes>
        </ng-container>
      </mat-expansion-panel>
    </mat-accordion>

    <div *ngIf="totalCount" class="paginator-container mt-12 mb-24">
      <mat-paginator
        (page)="pageChange($event)"
        [pageSize]="requestParams.limit"
        [pageIndex]="(requestParams.page - 1)"
        [length]="totalCount"
        class="paginator-holder"
      ></mat-paginator>
    </div>
  </div>
</div>