var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
export var TaskTypes;
(function (TaskTypes) {
    TaskTypes["Import"] = "import";
    TaskTypes["Export"] = "export";
    TaskTypes["Receiving"] = "receiving";
})(TaskTypes || (TaskTypes = {}));
export var TaskStatuses;
(function (TaskStatuses) {
    TaskStatuses["New"] = "new";
    TaskStatuses["SendToForwarder"] = "send_to_forwarder";
    TaskStatuses["Notified"] = "notified";
    TaskStatuses["Realized"] = "realized";
    TaskStatuses["Invoiced"] = "invoiced";
    TaskStatuses["Cancelled"] = "cancelled";
})(TaskStatuses || (TaskStatuses = {}));
var CalendarTask = /** @class */ (function () {
    function CalendarTask() {
    }
    return CalendarTask;
}());
export { CalendarTask };
var BasicTask = /** @class */ (function () {
    function BasicTask() {
    }
    return BasicTask;
}());
export { BasicTask };
var Task = /** @class */ (function (_super) {
    __extends(Task, _super);
    function Task() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return Task;
}(BasicTask));
export { Task };
var RecurringTaskRequest = /** @class */ (function () {
    function RecurringTaskRequest() {
    }
    return RecurringTaskRequest;
}());
export { RecurringTaskRequest };
var TaskExport = /** @class */ (function (_super) {
    __extends(TaskExport, _super);
    function TaskExport() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return TaskExport;
}(BasicTask));
export { TaskExport };
var TaskImport = /** @class */ (function (_super) {
    __extends(TaskImport, _super);
    function TaskImport() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return TaskImport;
}(BasicTask));
export { TaskImport };
var TaskReceiving = /** @class */ (function (_super) {
    __extends(TaskReceiving, _super);
    function TaskReceiving() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return TaskReceiving;
}(BasicTask));
export { TaskReceiving };
// SUBFORMS
var BasicOrderSubform = /** @class */ (function () {
    function BasicOrderSubform() {
    }
    return BasicOrderSubform;
}());
export { BasicOrderSubform };
var OrderExportSubform = /** @class */ (function (_super) {
    __extends(OrderExportSubform, _super);
    function OrderExportSubform() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return OrderExportSubform;
}(BasicOrderSubform));
export { OrderExportSubform };
var OrderImportSubform = /** @class */ (function (_super) {
    __extends(OrderImportSubform, _super);
    function OrderImportSubform() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return OrderImportSubform;
}(BasicOrderSubform));
export { OrderImportSubform };
var OrderReceivingSubform = /** @class */ (function (_super) {
    __extends(OrderReceivingSubform, _super);
    function OrderReceivingSubform() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return OrderReceivingSubform;
}(BasicOrderSubform));
export { OrderReceivingSubform };
