import { Component, OnDestroy, OnInit } from '@angular/core';
import { CompaniesService } from '../../../../services/companies.service';
import { MatDialog } from '@angular/material';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { Company, CompaniesListRespone } from '../../../../shared/models/companies.model';
import { CompanyEditorComponent } from '../company-editor/company-editor.component';
import { locale as polish } from '../../../../translations/pl';
import { locale as english } from '../../../../translations/en';
import { fuseAnimations } from '../../../../../@fuse/animations';
import { remondisListAnimation } from '../../../../shared/consts/remondis.lists.animation';
import { List } from '../../../../shared/models/list.class';
import { RoleTypes, User, UserTypes } from '../../../../shared/models/users.model';
import { FormControl } from '@angular/forms';
import { ListFilters } from '../../../../shared/models/list.model';
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-companies-list',
  templateUrl: './companies-list.component.html',
  styleUrls: ['./companies-list.component.scss'],
  animations: [...fuseAnimations, ...remondisListAnimation]
})
export class CompaniesListComponent extends List implements OnInit, OnDestroy {
  public displayedColumns = ['name', 'city', 'phone', 'email', 'nip', 'owner', 'types', 'trusted'];
  public user: User;
  public searchCompany: FormControl;
  public ownersOptions = [];
  public hasAccessToUsersList = false;
  private listFilters: ListFilters = {};
  private ownerFilters = {
    page: 1,
    limit: 1000,
    sort: 'surname',
    order: 'asc',
    userType: UserTypes.Sales
  };
  private reloadList$;

  constructor(private companiesService: CompaniesService,
              private authService: AuthService,
              private matDialog: MatDialog,
              private _fuseTranslationLoaderService: FuseTranslationLoaderService) {
    super();
    this.user = this.authService.getUser();

    this.reloadList$ = companiesService.onEditEvent.subscribe(
      () => {
        this.getElementsList();
      });

    this._fuseTranslationLoaderService.loadTranslations(polish, english);

    this.getElementsList = () => {
      this.companiesService.getCompaniesList(this.getQueryParams())
        .subscribe((res: CompaniesListRespone) => {
          this.totalCount = res.totalCount;
          this.dataSource = res.customerCompanies;
        });
    };
  }

  ngOnInit() {
    super.ngOnInit();
    this.createSearchFormControl();

    if (this.user.userType === UserTypes.Logistician ||
      (this.user.userType === UserTypes.Sales && this.user.roleType === RoleTypes.Admin)) {
      this.hasAccessToUsersList = true;
      this.companiesService.getOwnersList(this.ownerFilters).subscribe(
        res => {
          res.users.forEach(user => {
            this.ownersOptions.push({name: `${user.name} ${user.surname}`, value: user.uuid});
          })
        });
    }
  }

  ngOnDestroy() {
    this.reloadList$.unsubscribe();
  }

  public addCompany() {
    this.disableAnimations = true;

    let editorDialogRef = this.matDialog.open(CompanyEditorComponent, {
      panelClass: 'company-editor-dialog',
      autoFocus: false,
      disableClose: true,
      data: {
        mode: 'add'
      }
    });
  }

  public viewCompany(companyUuid: Company['uuid']) {
    this.disableAnimations = true;

    let editorDialogRef = this.matDialog.open(CompanyEditorComponent, {
      panelClass: 'company-editor-dialog',
      autoFocus: false,
      disableClose: true,
      data: {
        mode: 'view',
        companyUuid: companyUuid
      }
    });
  }

  public clearSearch() {
    if (this.searchCompany.value) {
      this.searchCompany.setValue('');
    }
  }

  public getListFilters(filters: ListFilters) {
    this.listFilters = filters;
    this.requestParams.page = 1;
    this.disableAnimations = true;
    this.getElementsList();
  }

  private createSearchFormControl() {
    this.searchCompany = new FormControl('');
    this.searchCompany.valueChanges.debounceTime(300).subscribe((value) => {
      this.requestParams.search = value;
      this.requestParams.page = 1;
      this.disableAnimations = true;
      this.getElementsList();
    });
  }

  private getQueryParams() {
    return Object.assign({}, this.requestParams, this.listFilters);
  }

}
