export enum UserTypes {
  Logistician = 'logistician',
  Sales = 'sales',
  Manager = 'manager',
}

export enum RoleTypes {
  Admin = 'admin',
  User = 'user',
}

export interface UsersListResponse {
  totalCount: number;
  users: User[];
}

export interface User {
  uuid: string;
  companyId: string;
  email: string;
  name: string;
  surname: string;
  userType: string;
  roleType: string;
  aclGroupId: string;
  companyBranchId: string;
  companyBranch:string;
  active: boolean;
  avatarName?: string;
}

export interface UserAclGroups {
  logistician: AclType[];
  manager: AclType[];
  sales: AclType[];
}

export interface AclType {
  uuid: string;
  roleType: string;
}