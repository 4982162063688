<hr>
<h3> {{'ORDERS.ADDITIONAL_INFO' | translate}} </h3>
<form class="form" [formGroup]="detailsForm">
  <div class="input-wrapper">
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>{{'ORDERS.CREATED_DATE' | translate}}</mat-label>
      <input formControlName="createdDate" matInput>
    </mat-form-field>
  </div>

  <div class="input-wrapper">
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>{{'ORDERS.UPDATED_DATE' | translate}}</mat-label>
      <input formControlName="updatedDate" matInput>
    </mat-form-field>
  </div>

  <div class="input-wrapper">
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>{{'ORDERS.OWNER' | translate}}</mat-label>
      <input formControlName="author" matInput>
    </mat-form-field>
  </div>

  <div class="input-wrapper">
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>{{'ORDERS.STATUS' | translate}}</mat-label>
      <input formControlName="status" matInput>
    </mat-form-field>
  </div>

  <div fxLayout="row" fxLayoutAlign="start start" fxFlex="">
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>{{'ORDERS.TASK' | translate}}</mat-label>
      <input formControlName="taskNumber" matInput>
    </mat-form-field>
  </div>
</form>