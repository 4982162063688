import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { fuseAnimations } from "../../../../../../@fuse/animations";
import { FuseTranslationLoaderService } from "../../../../../../@fuse/services/translation-loader.service";
import { AuthService } from "../../../../../services/auth.service";

import { CompanyKpoEditorComponent } from "app/modules/company-kpo/components/company-kpo-editor/company-kpo-editor.component";
import { CompanyKpoService } from "app/services/company-kpo.service";
import {
  CompanyKpo,
  CompanyKpoListResponse,
} from "app/shared/models/company-kpo.model";
import { remondisListAnimation } from "../../../../../shared/consts/remondis.lists.animation";
import { List } from "../../../../../shared/models/list.class";
import {
  ListFilters,
  RequestParams,
} from "../../../../../shared/models/list.model";
import { User } from "../../../../../shared/models/users.model";
import { locale as english } from "../../../../../translations/en";
import { locale as polish } from "../../../../../translations/pl";

@Component({
  selector: "app-company-kpo-list",
  templateUrl: "./company-kpo-list.component.html",
  styleUrls: ["./company-kpo-list.component.scss"],
  animations: [...fuseAnimations, ...remondisListAnimation],
})
export class CompanyKpoListComponent extends List implements OnInit, OnDestroy {
  @Input() companyUuid: string;
  public displayedColumns = ["name", "surname", "email", "phone"];
  public user: User;
  private listFilters: ListFilters = {};
  public requestParams: RequestParams = {
    page: 1,
    limit: 10,
    sort: "surname",
    order: "asc",
  };
  private reloadList$;

  constructor(
    private companyKpoService: CompanyKpoService,
    private authService: AuthService,
    private matDialog: MatDialog,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService
  ) {
    super();
    this.user = this.authService.getUser();

    this.reloadList$ = companyKpoService.onEditEvent.subscribe(() => {
      this.getElementsList();
    });

    this._fuseTranslationLoaderService.loadTranslations(polish, english);

    this.getElementsList = () => {
      this.companyKpoService
        .getCompanyKpoList(this.requestParams, this.companyUuid)
        .subscribe((res: CompanyKpoListResponse) => {
          this.totalCount = res.totalCount;
          this.dataSource = res.kpo;
        });
    };
  }

  ngOnInit() {
    super.ngOnInit();
    this.getElementsList();
  }

  ngOnDestroy() {
    this.reloadList$.unsubscribe();
  }

  public addCompanyKpo() {
    this.disableAnimations = true;
    this.matDialog.open(CompanyKpoEditorComponent, {
      panelClass: "company-kpo-editor-dialog",
      autoFocus: false,
      disableClose: true,
      data: {
        mode: "add",
        companyUuid: this.companyUuid,
      },
    });
  }

  public viewCompanyKpo(companyKpoUuid: CompanyKpo["uuid"]) {
    this.disableAnimations = true;
    this.matDialog.open(CompanyKpoEditorComponent, {
      panelClass: "company-kpo-editor-dialog",
      autoFocus: false,
      disableClose: true,
      data: {
        mode: "view",
        companyUuid: this.companyUuid,
        companyKpoUuid: companyKpoUuid,
      },
    });
  }
}
