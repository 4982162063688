import { User } from "./users.model";
import { OrderExport, OrderImport, OrderReceiving } from "./orders.model";
import { TaskExport, TaskImport, TaskReceiving } from "./tasks.model";

export enum HistoryEventTypes {
  Create = "create",
  Update = "update",
  Cancel = "cancel",
  Delete = "delete",
}

export enum ItemsOrigins {
  Order = "order",
  Task = "task",
}

export interface OrderHistoryListResponse {
  totalCount: number;
  orderHistory: HistoryItemNew[];
}

export interface HistoryItem {
  eventDate: string;
  eventParams: {
    old?: TaskExport | TaskImport | TaskReceiving;
    new?: TaskExport | TaskImport | TaskReceiving;
    startDate?: number;
    endDate?: number;
  };
  eventType: "create" | "update" | "hourPatch";
  ip: string;
  user: User;
  uuid: string;
  changes: any;
}

export interface HistoryItemNew {
  createdDate: string;
  type: "create" | "update" | "hourPatch";
  createdBy: User;
  uuid: string;
  changes: {
    [key: string]: any[];
  };
}

export interface TaskHistoryListResponse {
  totalCount: number;
  taskHistory: TaskHistoryItem[];
}

export interface TaskHistoryItem {
  uuid?: string;
  eventDate?: string;
  eventParams: {
    old?: TaskExport | TaskImport | TaskReceiving;
    new?: TaskExport | TaskImport | TaskReceiving;
    startDate?: number;
    endDate?: number;
  };
  eventType: "create" | "update" | "hourPatch";
  taskId: string;
  user: User;
  changes: any;
}
