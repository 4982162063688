<div class="company-kpo">
  <div class="company-kpo-table">
    <mat-table
      matSort
      [dataSource]="dataSource"
      [matSortActive]="requestParams.sort"
      [matSortDirection]="requestParams.order"
      [@animateStaggerRemondis]="dataSource && dataSource.length"
      [@.disabled]="disableAnimations">

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{'COMPANY_KPO.NAME' | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let kpo"> {{kpo.name}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="surname">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{'COMPANY_KPO.SURNAME' | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let kpo"> {{kpo.surname}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{'COMPANY_KPO.EMAIL' | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let kpo"> {{kpo.email}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="phone">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{'COMPANY_KPO.PHONE' | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let kpo"> {{kpo.phone}}</mat-cell>
      </ng-container>



      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        class="company-kpo-row"
        *matRowDef="let kpo; columns: displayedColumns;"
        [@animateRemondis]="{value:'*',params:{y:'100%'}}"
        [id]="kpo.uuid"
        (click)="viewCompanyKpo(kpo.uuid)">
      </mat-row>
    </mat-table>

    <h4 class="no-data" *ngIf="totalCount === 0">
      {{'SHARED.NO_DATA' | translate}}
    </h4>

    <div class="paginator-container" [ngClass]="{'no-pagination': !totalCount}">
      <mat-paginator
        *ngIf="totalCount"
        (page)="pageChange($event)"
        [pageSize]="requestParams.limit"
        [length]="totalCount"
        class="paginator-holder"
      ></mat-paginator>
    </div>
    <div class="button-add-wrapper">
      <button mat-raised-button color="primary"
              [@animate]="{value:'*', params:{delay:'300ms',scale:'.2'}}"
              (click)="addCompanyKpo()"
              [attr.data-cy]="'addCompanyKpo'">
        {{ 'COMPANY_KPO.TITLE_ADD' | translate }}
      </button>
    </div>
  </div>
</div>
