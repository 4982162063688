import { EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./auth.service";
var TasksService = /** @class */ (function () {
    function TasksService(http, authService) {
        this.http = http;
        this.authService = authService;
        this.onEditEvent = new EventEmitter();
    }
    TasksService.prototype.getTasksList = function (params) {
        return this.http.get(environment.apiUrl + "/" + this.authService.getUserType() + "/tasks", { params: params })
            .pipe(map(function (res) { return res; }));
    };
    TasksService.prototype.getTasksListByDay = function (date, companyBranchId) {
        var params = {
            dateFrom: date,
            dateTo: date,
            companyBranchId: companyBranchId
        };
        return this.http.get(environment.apiUrl + "/" + this.authService.getUserType() + "/tasks", { params: params })
            .pipe(map(function (res) { return res; }));
    };
    TasksService.prototype.getTaskCalendarSummary = function (date, companyBranchId) {
        var params = {
            date: date,
            companyBranchId: companyBranchId
        };
        return this.http.get(environment.apiUrl + "/" + this.authService.getUserType() + "/tasks/calendar_summary", { params: params })
            .pipe(map(function (res) { return res; }));
    };
    TasksService.prototype.getTask = function (taskId) {
        var id = taskId.replace(/\//g, '-');
        return this.http.get(environment.apiUrl + "/" + this.authService.getUserType() + "/tasks/" + id).pipe(map(function (res) { return res.task; }));
    };
    TasksService.prototype.getTaskHistory = function (taskTypeUuid, params) {
        return this.http.get(environment.apiUrl + "/" + this.authService.getUserType() + "/tasks/" + taskTypeUuid + "/history", { params: params });
    };
    TasksService.prototype.getTasksExcel = function (params) {
        return this.http.get(environment.apiUrl + "/" + this.authService.getUserType() + "/tasks/download-view", { params: params, responseType: 'blob' });
    };
    TasksService.prototype.createTask = function (task) {
        return this.http.post(environment.apiUrl + "/" + this.authService.getUserType() + "/tasks", task).pipe(map(function (res) { return res.task; }));
    };
    TasksService.prototype.createRecurringTask = function (task) {
        return this.http.post(environment.apiUrl + "/" + this.authService.getUserType() + "/tasks/cycle", task).pipe(map(function (res) { return res.task; }));
    };
    TasksService.prototype.updateTask = function (taskId, task) {
        return this.http.put(environment.apiUrl + "/" + this.authService.getUserType() + "/tasks/" + taskId, task).pipe(map(function (res) { return res.task; }));
    };
    TasksService.prototype.updateTaskDate = function (taskId, startDate, endDate) {
        return this.http.post(environment.apiUrl + "/" + this.authService.getUserType() + "/tasks/" + taskId, { startDate: startDate, endDate: endDate }).pipe(map(function (res) { return res.task; }));
    };
    TasksService.prototype.updateTaskOrders = function (taskId, orderIds) {
        return this.http.post(environment.apiUrl + "/" + this.authService.getUserType() + "/tasks/" + taskId + "/orderCancelled", { orderIds: orderIds });
    };
    TasksService.prototype.deleteTask = function (taskId) {
        return this.http.delete(environment.apiUrl + "/" + this.authService.getUserType() + "/tasks/" + taskId);
    };
    TasksService.prototype.cancelTask = function (taskId) {
        return this.http.put(environment.apiUrl + "/" + this.authService.getUserType() + "/tasks/" + taskId + "/cancelled", {})
            .pipe(map(function (res) { return res.task; }));
    };
    TasksService.prototype.completeTask = function (taskId) {
        return this.http.put(environment.apiUrl + "/" + this.authService.getUserType() + "/tasks/" + taskId + "/complete", {})
            .pipe(map(function (res) { return res.task; }));
    };
    TasksService.prototype.passInvoiceNumberToTasks = function (data) {
        return this.http.put(environment.apiUrl + "/" + this.authService.getUserType() + "/tasks/invoices", data)
            .pipe(map(function (res) { return res.task; }));
    };
    TasksService.ngInjectableDef = i0.defineInjectable({ factory: function TasksService_Factory() { return new TasksService(i0.inject(i1.HttpClient), i0.inject(i2.AuthService)); }, token: TasksService, providedIn: "root" });
    return TasksService;
}());
export { TasksService };
