var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { locale as polish } from '../../../../translations/pl';
import { locale as english } from '../../../../translations/en';
import { TransportEditorComponent } from '../transport-editor/transport-editor.component';
import { TransportService } from '../../../../services/transport.service';
import { List } from '../../../../shared/models/list.class';
import { FormControl } from '@angular/forms';
var TransportListComponent = /** @class */ (function (_super) {
    __extends(TransportListComponent, _super);
    function TransportListComponent(transportService, matDialog, _fuseTranslationLoaderService) {
        var _this = _super.call(this) || this;
        _this.transportService = transportService;
        _this.matDialog = matDialog;
        _this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        _this.displayedColumns = ['name', 'description', 'capacity', 'volume', 'category', 'active'];
        _this.listFilters = {};
        _this._fuseTranslationLoaderService.loadTranslations(polish, english);
        _this.reloadList$ = transportService.onEditEvent.subscribe(function () {
            _this.getElementsList();
        });
        _this.getElementsList = function () {
            _this.transportService.getTransportList(_this.getQueryParams()).subscribe(function (res) {
                _this.totalCount = res.totalCount;
                _this.dataSource = res.transports;
            });
        };
        return _this;
    }
    TransportListComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.createSearchFormControl();
    };
    TransportListComponent.prototype.ngOnDestroy = function () {
        this.reloadList$.unsubscribe();
    };
    TransportListComponent.prototype.addTransport = function () {
        this.disableAnimations = true;
        var editorDialogRef = this.matDialog.open(TransportEditorComponent, {
            panelClass: 'transport-editor-dialog',
            autoFocus: false,
            disableClose: true,
            data: {
                mode: 'add'
            }
        });
    };
    TransportListComponent.prototype.viewTransport = function (transportUuid) {
        this.disableAnimations = true;
        var editorDialogRef = this.matDialog.open(TransportEditorComponent, {
            panelClass: 'transport-editor-dialog',
            autoFocus: false,
            disableClose: true,
            data: {
                mode: 'view',
                transportUuid: transportUuid
            }
        });
    };
    TransportListComponent.prototype.clearSearch = function () {
        if (this.searchTransport.value) {
            this.searchTransport.setValue('');
        }
    };
    TransportListComponent.prototype.getListFilters = function (filters) {
        this.listFilters = filters;
        this.requestParams.page = 1;
        this.disableAnimations = true;
        this.getElementsList();
    };
    TransportListComponent.prototype.createSearchFormControl = function () {
        var _this = this;
        this.searchTransport = new FormControl('');
        this.searchTransport.valueChanges.debounceTime(300).subscribe(function (value) {
            _this.requestParams.name = value;
            _this.requestParams.page = 1;
            _this.disableAnimations = true;
            _this.getElementsList();
        });
    };
    TransportListComponent.prototype.getQueryParams = function () {
        return Object.assign({}, this.requestParams, this.listFilters);
    };
    return TransportListComponent;
}(List));
export { TransportListComponent };
