var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { FuseTranslationLoaderService } from "../../../../../../@fuse/services/translation-loader.service";
import { locale as polish } from "../../../../../translations/pl";
import { locale as english } from "../../../../../translations/en";
import { List } from "../../../../../shared/models/list.class";
import { AuthService } from "../../../../../services/auth.service";
import { CompanyBranchesService } from "../../../../../services/company-branches.service";
import { CompanyBranchEditorComponent } from "../../../../company-branches/components/company-branch-editor/company-branch-editor.component";
var CompanyBranchesListComponent = /** @class */ (function (_super) {
    __extends(CompanyBranchesListComponent, _super);
    function CompanyBranchesListComponent(companyBranchesService, authService, matDialog, _fuseTranslationLoaderService) {
        var _this = _super.call(this) || this;
        _this.companyBranchesService = companyBranchesService;
        _this.authService = authService;
        _this.matDialog = matDialog;
        _this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        _this.displayedColumns = ["name", "address", "contact"];
        _this.listFilters = {};
        _this.requestParams = { page: 0, limit: 500 };
        _this.user = _this.authService.getUser();
        _this.reloadList$ = companyBranchesService.onEditEvent.subscribe(function () {
            _this.getElementsList();
        });
        _this._fuseTranslationLoaderService.loadTranslations(polish, english);
        _this.getElementsList = function () {
            _this.companyBranchesService
                .getCompanyBranchesList(_this.requestParams, _this.companyUuid)
                .subscribe(function (res) {
                _this.totalCount = res.branches.length;
                _this.dataSource = res.branches;
            });
        };
        return _this;
    }
    CompanyBranchesListComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.getElementsList();
    };
    CompanyBranchesListComponent.prototype.ngOnDestroy = function () {
        this.reloadList$.unsubscribe();
    };
    CompanyBranchesListComponent.prototype.addCompanyBranch = function () {
        this.disableAnimations = true;
        this.matDialog.open(CompanyBranchEditorComponent, {
            panelClass: "company-branch-editor-dialog",
            autoFocus: false,
            disableClose: true,
            data: {
                mode: "add",
                companyUuid: this.companyUuid
            }
        });
    };
    CompanyBranchesListComponent.prototype.viewCompanyBranch = function (companyBranchUuid) {
        this.disableAnimations = true;
        this.matDialog.open(CompanyBranchEditorComponent, {
            panelClass: "company-branch-editor-dialog",
            autoFocus: false,
            disableClose: true,
            data: {
                mode: "view",
                companyUuid: this.companyUuid,
                companyBranchUuid: companyBranchUuid
            }
        });
    };
    return CompanyBranchesListComponent;
}(List));
export { CompanyBranchesListComponent };
