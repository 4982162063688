import { Component } from '@angular/core';
import { FuseTranslationLoaderService } from '../../../@fuse/services/translation-loader.service';
import { locale as polish } from '../../translations/pl';
import { locale as english } from '../../translations/en';

@Component({
  selector: 'app-transport-container',
  templateUrl: './transport-container.component.html',
  styleUrls: ['./transport-container.component.scss']
})
export class TransportContainerComponent {

  constructor(private _fuseTranslationLoaderService: FuseTranslationLoaderService) {
    this._fuseTranslationLoaderService.loadTranslations(polish, english);
  }

}
