var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators, } from "@angular/forms";
import { MatDialog, MatDialogRef, MatSnackBar, MatStepper, } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { of, zip } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { FuseTranslationLoaderService } from "../../../../../../@fuse/services/translation-loader.service";
import { AuthService } from "../../../../../services/auth.service";
import { FilteredListsService } from "../../../../../services/filtered-lists.service";
import { OrdersService } from "../../../../../services/orders.service";
import { TasksService } from "../../../../../services/tasks.service";
import { UsersService } from "../../../../../services/users.service";
import { OrderTypes } from "../../../../../shared/consts/orders";
import { EndTimeOptions, StartTimeOptions, } from "../../../../../shared/consts/tasks";
import { ItemsOrigins } from "../../../../../shared/models/history.model";
import { OrderImportSubform, TaskImport, TaskStatuses, TaskTypes, } from "../../../../../shared/models/tasks.model";
import { UserTypes } from "../../../../../shared/models/users.model";
import { getDaysInMonth } from "../../../../../shared/utils/custom-date-formatter";
import { markFormGroupTouched } from "../../../../../shared/utils/markFormGroupAsTouched";
import { mapDatetimeToUtc, mapOrderDataApiValues, mapOrderDataToUuid, } from "../../../../../shared/utils/tasks-map";
import { ValidateItemsList } from "../../../../../shared/validators/itemsList.validator";
import { StartEndDateValidator } from "../../../../../shared/validators/startEndDate.validator";
import { locale as english } from "../../../../../translations/en";
import { locale as polish } from "../../../../../translations/pl";
import { CompanyEditorComponent } from "../../../../companies/components/company-editor/company-editor.component";
import { ConfirmModalComponent } from "../../../../shared/components/confirm-modal/confirm-modal.component";
import { HistoryDialogComponent } from "../../../../shared/components/history-dialog/history-dialog.component";
var TaskImportFormComponent = /** @class */ (function () {
    function TaskImportFormComponent(taskEditorDialogRef, data, matDialog, authService, tasksService, ordersService, filteredListsService, usersService, translateService, snackBar, formBuilder, _fuseTranslationLoaderService) {
        this.taskEditorDialogRef = taskEditorDialogRef;
        this.data = data;
        this.matDialog = matDialog;
        this.authService = authService;
        this.tasksService = tasksService;
        this.ordersService = ordersService;
        this.filteredListsService = filteredListsService;
        this.usersService = usersService;
        this.translateService = translateService;
        this.snackBar = snackBar;
        this.formBuilder = formBuilder;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this.isExpanded = [];
        this.taskStatuses = TaskStatuses;
        this.taskTypes = TaskTypes;
        this.userTypes = UserTypes;
        this.transportOptions = [];
        this.customerCompanyOptions = [];
        this.companyBranches = [];
        this.ordersOptions = [];
        this.ordersOptionsInitial = [];
        this.ordersOptionsRemoved = [];
        this.startTimeOptions = StartTimeOptions;
        this.endTimeOptions = EndTimeOptions;
        this.isLoading = false;
        this.filterParams = {
            page: 1,
            limit: 20,
            sort: "name",
            order: "asc",
        };
        this.filterParamsFullList = {
            page: 1,
            limit: 1000,
            sort: "name",
            order: "asc",
            active: "true",
        };
        this.filterOrdersParams = {
            page: 1,
            limit: 20,
            sort: "createdDate",
            order: "asc",
            available: true,
            orderType: this.taskTypes.Import,
            task: false,
            salesAccepted: true,
        };
        this.user = this.authService.user.user;
        this.mode = data.mode;
        this.taskId = data.taskId || data.taskNumber;
        this._fuseTranslationLoaderService.loadTranslations(polish, english);
        this.indexStep = data.indexStep || 0;
    }
    TaskImportFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.createForm();
        this.createSearchFilters();
        if (this.mode === "edit" || this.mode === "view" || this.mode === "clone") {
            this.tasksService
                .getTask(this.taskId)
                .pipe(map(function (res) {
                _this.task = res;
                _this.taskId = res.uuid;
                res.taskData.forEach(function (item) {
                    if (item.order && item.order.uuid) {
                        _this.ordersOptionsInitial.push(item.order.uuid);
                    }
                });
                _this.createForm(_this.task);
            }), switchMap(function () {
                return zip(_this.filteredListsService.getTransportList(_this.filterParamsFullList), _this.filteredListsService.getCompaniesList(_this.filterParams), _this.usersService.getCompanyBranchList(), _this.filteredListsService.getOrdersList(_this.filterOrdersParams));
            }))
                .subscribe(function (_a) {
                var transportRes = _a[0], companiesRes = _a[1], branchesRes = _a[2], ordersRes = _a[3];
                _this.transportOptions = transportRes.transports;
                _this.customerCompanyOptions = companiesRes.customerCompanies;
                _this.companyBranches = branchesRes.branches;
                _this.ordersOptions = ordersRes.orders;
            });
        }
        else {
            this.todayDate = new Date();
            zip(this.filteredListsService.getTransportList(this.filterParamsFullList), this.filteredListsService.getCompaniesList(this.filterParams), this.filteredListsService.getOrdersList(this.filterOrdersParams), this.usersService.getCompanyBranchList()).subscribe(function (_a) {
                var transportRes = _a[0], companiesRes = _a[1], ordersRes = _a[2], branchesRes = _a[3];
                _this.transportOptions = transportRes.transports;
                _this.customerCompanyOptions = companiesRes.customerCompanies;
                _this.ordersOptions = ordersRes.orders;
                _this.companyBranches = branchesRes.branches;
                if (_this.data.orders && _this.data.orders.length) {
                    _this.createForm(_this.data.orders[0]);
                    _this.data.orders.forEach(function (item, index) {
                        _this.addOrderSubform(index, item, true);
                    });
                }
            });
        }
    };
    TaskImportFormComponent.prototype.setRangeEndDateMax = function (date) {
        this.rangeDateEndMin = new Date(this.taskImportForm.get("startDate").value || this.todayDate);
        this.rangeDateEndMax = new Date(date || this.todayDate);
        this.rangeDateEndMax.setMonth(this.rangeDateEndMax.getMonth() !== 11
            ? this.rangeDateEndMax.getMonth() + 1
            : 0);
        if (this.rangeDateEndMax.getMonth() === 0) {
            this.rangeDateEndMax.setFullYear(this.rangeDateEndMax.getFullYear() + 1);
        }
        this.rangeDateEndMax.setDate(getDaysInMonth(this.rangeDateEndMax.getFullYear(), this.rangeDateEndMax.getMonth()));
    };
    TaskImportFormComponent.prototype.submitTask = function () {
        var _this = this;
        this.isLoading = true;
        markFormGroupTouched(this.taskImportForm);
        if (this.taskImportForm.invalid) {
            this.isLoading = false;
            return;
        }
        var taskForm = this.taskImportForm.getRawValue();
        this.createNewOrders(taskForm.taskData, taskForm.companyBranchId).subscribe(function (taskDataFromOther) {
            var taskData = taskForm.taskData.filter(function (item) { return item.uuid !== "other"; });
            taskDataFromOther.forEach(function (item) {
                taskData.push(item);
            });
            var newTask = {
                name: taskForm.name,
                companyBranchId: taskForm.companyBranchId,
                transportId: taskForm.taskData[0]
                    ? taskForm.taskData[0].transport.uuid
                    : null,
                startDate: mapDatetimeToUtc(taskForm.startDate, taskForm.startTime),
                endDate: mapDatetimeToUtc(taskForm.endDate, taskForm.endTime),
                taskType: taskForm.taskType,
                comment: taskForm.comment,
                referenceNumber: taskForm.referenceNumber,
                emailService: taskForm.emailService,
                registrationNumFront: taskForm.registrationNumFront,
                registrationNumBack: taskForm.registrationNumBack,
                taskData: _this.mapTaskData(taskData),
            };
            if (_this.mode === "add" || _this.mode === "clone") {
                _this.todayDate = new Date();
                _this.createTask(newTask);
            }
            else {
                var ordersToDelete_1 = [];
                var oldOrdersIds = _this.task && _this.task.taskData
                    ? _this.task.taskData.map(function (item) {
                        if (item.order && item.order.uuid) {
                            return item.order.uuid;
                        }
                    })
                    : [];
                var newOrdersIds_1 = newTask.taskData
                    ? newTask.taskData.map(function (item) {
                        if (item.orderId) {
                            return item.orderId;
                        }
                    })
                    : [];
                oldOrdersIds.forEach(function (oldItem) {
                    if (oldItem && !newOrdersIds_1.includes(oldItem)) {
                        ordersToDelete_1.push(oldItem);
                    }
                });
                zip(_this.tasksService.updateTask(_this.taskId, newTask), _this.tasksService.updateTaskOrders(_this.taskId, ordersToDelete_1)).subscribe(function () {
                    _this.taskEditorDialogRef.close();
                    _this.taskEditorDialogRef.afterClosed().subscribe(function () {
                        _this.tasksService.onEditEvent.emit();
                        _this.ordersService.onEditEvent.emit();
                    });
                    _this.snackBar.open(_this.translateService.instant("TASKS.MESSAGES.UPDATE_SUCCESS"), "", { duration: 5000 });
                }, function () {
                    _this.isLoading = false;
                    _this.snackBar.open(_this.translateService.instant("TASKS.MESSAGES.UPDATE_ERROR"), "", { duration: 5000 });
                });
            }
        });
    };
    TaskImportFormComponent.prototype.createTask = function (newTask) {
        var _this = this;
        var taskForm = this.taskImportForm.getRawValue();
        if (taskForm.recurring) {
            var recurringTask = {
                intervalType: "WEEK",
                intervalValue: taskForm.recurringInfo.intervalValue,
                rangeDateEnd: moment(taskForm.recurringInfo.rangeDateEnd).format("YYYY-MM-DD"),
                weekdays: taskForm.recurringInfo.weekdays,
                task: newTask,
            };
            this.tasksService.createRecurringTask(recurringTask).subscribe(function () {
                _this.taskEditorDialogRef.close();
                _this.taskEditorDialogRef.afterClosed().subscribe(function () {
                    _this.tasksService.onEditEvent.emit();
                    _this.ordersService.onEditEvent.emit();
                });
                _this.snackBar.open(_this.translateService.instant("TASKS.MESSAGES.CREATE_SUCCESS"), "", { duration: 5000 });
            }, function () {
                _this.isLoading = false;
                _this.snackBar.open(_this.translateService.instant("TASKS.MESSAGES.CREATE_ERROR"), "", { duration: 5000 });
            });
        }
        else {
            this.tasksService.createTask(newTask).subscribe(function () {
                _this.taskEditorDialogRef.close();
                _this.taskEditorDialogRef.afterClosed().subscribe(function () {
                    _this.tasksService.onEditEvent.emit();
                    _this.ordersService.onEditEvent.emit();
                });
                _this.snackBar.open(_this.translateService.instant("TASKS.MESSAGES.CREATE_SUCCESS"), "", { duration: 5000 });
            }, function () {
                _this.isLoading = false;
                _this.snackBar.open(_this.translateService.instant("TASKS.MESSAGES.CREATE_ERROR"), "", { duration: 5000 });
            });
        }
    };
    TaskImportFormComponent.prototype.deleteTask = function () {
        var _this = this;
        var deleteDialogRef = this.matDialog.open(ConfirmModalComponent, {
            disableClose: false,
            autoFocus: false,
        });
        deleteDialogRef.componentInstance.confirmMessage =
            this.translateService.instant("TASKS.MESSAGES.DELETE_QUESTION");
        deleteDialogRef.componentInstance.titleMessage =
            this.translateService.instant("TASKS.MESSAGES.DELETE_TASK_TITLE");
        deleteDialogRef.componentInstance.confirmButton =
            this.translateService.instant("TASKS.DELETE");
        deleteDialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                var ordersToDelete_2 = [];
                _this.task &&
                    _this.task.taskData &&
                    _this.task.taskData.forEach(function (item) {
                        if (item.order && item.order.uuid) {
                            ordersToDelete_2.push(item.order.uuid);
                        }
                    });
                zip(_this.tasksService.deleteTask(_this.taskId), _this.tasksService.updateTaskOrders(_this.taskId, ordersToDelete_2)).subscribe(function () {
                    _this.taskEditorDialogRef.close();
                    _this.taskEditorDialogRef.afterClosed().subscribe(function () {
                        _this.tasksService.onEditEvent.emit();
                        _this.ordersService.onEditEvent.emit();
                    });
                    _this.snackBar.open(_this.translateService.instant("TASKS.MESSAGES.DELETE_SUCCESS"), "", { duration: 5000 });
                }, function () {
                    _this.snackBar.open(_this.translateService.instant("TASKS.MESSAGES.DELETE_ERROR"), "", { duration: 5000 });
                });
            }
        });
    };
    TaskImportFormComponent.prototype.cancelTask = function () {
        var _this = this;
        var cancelDialogRef = this.matDialog.open(ConfirmModalComponent, {
            disableClose: false,
            autoFocus: false,
        });
        cancelDialogRef.componentInstance.confirmMessage =
            this.translateService.instant("TASKS.MESSAGES.CANCEL_QUESTION");
        cancelDialogRef.componentInstance.titleMessage =
            this.translateService.instant("TASKS.MESSAGES.CANCEL_TASK_TITLE");
        cancelDialogRef.componentInstance.confirmButton =
            this.translateService.instant("TASKS.CANCEL_TASK");
        cancelDialogRef.componentInstance.skipButton =
            this.translateService.instant("TASKS.CANCEL_SKIP");
        cancelDialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.tasksService.cancelTask(_this.taskId).subscribe(function () {
                    _this.taskEditorDialogRef.close();
                    _this.tasksService.onEditEvent.emit();
                    _this.ordersService.onEditEvent.emit();
                    _this.snackBar.open(_this.translateService.instant("TASKS.MESSAGES.CANCEL_SUCCESS"), "", { duration: 5000 });
                }, function () {
                    _this.snackBar.open(_this.translateService.instant("TASKS.MESSAGES.CANCEL_ERROR"), "", { duration: 5000 });
                });
            }
        });
    };
    TaskImportFormComponent.prototype.completeTask = function () {
        var _this = this;
        var completeDialogRef = this.matDialog.open(ConfirmModalComponent, {
            disableClose: false,
            autoFocus: false,
        });
        completeDialogRef.componentInstance.confirmMessage =
            this.translateService.instant("TASKS.MESSAGES.COMPLETE_QUESTION");
        completeDialogRef.componentInstance.titleMessage =
            this.translateService.instant("TASKS.MESSAGES.COMPLETE_TASK_TITLE");
        completeDialogRef.componentInstance.confirmButton =
            this.translateService.instant("TASKS.COMPLETE_TASK");
        completeDialogRef.componentInstance.skipButton =
            this.translateService.instant("TASKS.COMPLETE_SKIP");
        completeDialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.tasksService.completeTask(_this.taskId).subscribe(function () {
                    _this.taskEditorDialogRef.close();
                    _this.tasksService.onEditEvent.emit();
                    _this.ordersService.onEditEvent.emit();
                    _this.snackBar.open(_this.translateService.instant("TASKS.MESSAGES.COMPLETE_SUCCESS"), "", { duration: 5000 });
                }, function () {
                    _this.snackBar.open(_this.translateService.instant("TASKS.MESSAGES.COMPLETE_ERROR"), "", { duration: 5000 });
                });
            }
        });
    };
    TaskImportFormComponent.prototype.setStartDate = function (newDate) {
        this.taskImportForm.patchValue({ endDate: new Date(newDate) });
        this.setRangeEndDateMax(newDate);
        this.handleRecurringChange();
    };
    TaskImportFormComponent.prototype.handleRecurringChange = function () {
        var _a;
        var weekdays = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
        ];
        var date = this.taskImportForm.get("startDate").value || new Date();
        this.taskImportForm.get("recurringInfo").get("weekdays").patchValue({
            Monday: false,
            Tuesday: false,
            Wednesday: false,
            Thursday: false,
            Friday: false,
            Saturday: false,
            Sunday: false,
        });
        this.taskImportForm
            .get("recurringInfo")
            .get("weekdays")
            .patchValue((_a = {},
            _a[weekdays[moment(date).day()]] = true,
            _a));
    };
    TaskImportFormComponent.prototype.addOrderSubform = function (index, orderItem, generatedFromOrderId) {
        var _this = this;
        if (orderItem === void 0) { orderItem = new OrderImportSubform(); }
        if (generatedFromOrderId === void 0) { generatedFromOrderId = false; }
        Object.keys(this.isExpanded).forEach(function (item) { return (_this.isExpanded[item] = false); });
        this.isExpanded.push(!orderItem.orderData);
        orderItem.orderData = mapOrderDataToUuid(orderItem.orderData);
        this.orderFilter();
        var control = this.taskImportForm.controls.taskData;
        control.push(this.formBuilder.group({
            uuid: new FormControl(this.mode === "clone"
                ? "other"
                : (generatedFromOrderId && orderItem && orderItem.uuid) ||
                    (orderItem.order && orderItem.order.uuid) ||
                    orderItem.orderId ||
                    "other"),
            order: new FormControl(this.mode === "clone"
                ? { uuid: "other" }
                : (generatedFromOrderId && orderItem) ||
                    orderItem.order || { uuid: "other" }),
            customerCompany: new FormControl(orderItem.customerCompany || null, Validators.required),
            transport: new FormControl((orderItem && orderItem.transport) ||
                (this.task && this.task.transport) ||
                null, Validators.required),
            registrationNumFront: new FormControl(orderItem.registrationNumFront || null),
            registrationNumBack: new FormControl(orderItem.registrationNumBack || null),
            referenceNumber: new FormControl(this.mode === "clone" ? null : orderItem.referenceNumber || null),
            bdo: new FormControl(orderItem.bdo ? orderItem.bdo : null, Validators.required),
            bdoFree: new FormControl(orderItem.bdoFree),
            bdoPeopleCollection: new FormControl(orderItem.bdoPeopleCollection),
            kpoEmails: new FormControl(orderItem.kpoEmails ||
                (orderItem.order && orderItem.order.kpoEmails) ||
                null),
            reinvoice: new FormControl(orderItem.reinvoice || false),
            price: new FormControl(orderItem.price ? String(orderItem.price).replace(".", ",") : ""),
            reinvoiceNumber: new FormControl(orderItem.reinvoiceNumber || ""),
            invoiceComment: new FormControl(orderItem.invoiceComment || ""),
            salesAccepted: new FormControl((orderItem.order && orderItem.order.salesAccepted) ||
                orderItem.salesAccepted ||
                false, Validators.required),
            companyBranchId: new FormControl((this.task &&
                this.task.companyBranch &&
                this.task.companyBranch.uuid) ||
                (orderItem.companyBranch && orderItem.companyBranch.uuid) ||
                null, Validators.required),
            orderData: new FormControl(orderItem.orderData || [], [
                Validators.required,
                ValidateItemsList,
            ]),
            suggestedDate: new FormControl(this.mode === "clone"
                ? null
                : (orderItem.order && orderItem.order.suggestedDate) ||
                    orderItem.suggestedDate ||
                    null, Validators.required),
            order_comment: new FormControl(orderItem.comment ||
                (orderItem.order && orderItem.order.comment) ||
                null),
        }));
        generatedFromOrderId && control.at(index).get("order").disable();
        generatedFromOrderId &&
            setTimeout(function () { return _this.taskImportForm.get("companyBranchId").disable(); }, 0);
        this.updateBdo(orderItem.bdo, index);
        control.at(index).get("companyBranchId").disable();
        control.at(index).get("salesAccepted").disable();
        if (control.at(index).get("reinvoice").value === false) {
            control.at(index).get("price").disable();
            control.at(index).get("reinvoiceNumber").disable();
        }
    };
    TaskImportFormComponent.prototype.deleteOrderSubform = function (index) {
        var _this = this;
        this.isExpanded.splice(index, 1);
        var control = this.taskImportForm.controls.taskData;
        var order = control.value[index];
        if (order.uuid !== "other" &&
            this.ordersOptionsInitial.includes(order.uuid)) {
            this.ordersService.getOrder(order.uuid).subscribe(function (res) {
                _this.ordersOptionsRemoved.push(res);
            });
        }
        control.removeAt(index);
    };
    TaskImportFormComponent.prototype.pickOrder = function (order, index) {
        var _this = this;
        var control = this.taskImportForm.controls.taskData;
        var previousOrder = control.value[index];
        order.orderData = mapOrderDataToUuid(order.orderData);
        if (order.uuid === "other") {
            control.controls[index].reset();
            control.controls[index].patchValue({
                uuid: "other",
                order: { uuid: "other" },
                orderData: [],
            });
        }
        else {
            control.controls[index].patchValue({
                uuid: order.uuid || "other",
                order: order || { uuid: "other" },
                customerCompany: order.customerCompany || null,
                transport: order.transport || null,
                registrationNumFront: order.registrationNumFront || null,
                registrationNumBack: order.registrationNumBack || null,
                referenceNumber: order.referenceNumber || null,
                bdo: order.bdo || null,
                bdoFree: order.bdoFree,
                bdoPeopleCollection: order.bdoPeopleCollection,
                kpoEmails: order.kpoEmails,
                reinvoice: order.reinvoice,
                price: order.price ? String(order.price).replace(".", ",") : "",
                reinvoiceNumber: order.reinvoiceNumber,
                invoiceComment: order.invoiceComment,
                salesAccepted: order.salesAccepted,
                companyBranchId: order.companyBranch.uuid,
                suggestedDate: order.suggestedDate,
                orderData: order.orderData || [],
                order_comment: order.comment || null,
            });
        }
        if (order.uuid !== "other") {
            this.ordersOptionsRemoved = this.ordersOptionsRemoved.filter(function (item) { return item.uuid !== order.uuid; });
        }
        if (previousOrder &&
            this.ordersOptionsInitial.includes(previousOrder.uuid)) {
            this.ordersService.getOrder(previousOrder.uuid).subscribe(function (res) {
                _this.ordersOptionsRemoved.push(res);
            });
        }
        this.updateBdo(order.bdo, index);
    };
    TaskImportFormComponent.prototype.onChangeCustomerCompany = function (company, index) {
        var control = this.taskImportForm.controls.taskData;
        if (this.taskImportForm.get("taskData").value[index].uuid !== "other") {
            control.controls[index].patchValue({
                customerCompany: company,
                kpoEmails: company.kpo.map(function (kpo) { return kpo.email; }).join(","),
                uuid: "other",
                order: { uuid: "other" },
                recyclingItems: [],
                packageTypes: [],
                registrationNumFront: null,
                registrationNumBack: null,
            });
        }
        else {
            control.controls[index].patchValue({ customerCompany: company });
        }
        this.updateBdo(company.bdo, index, true);
    };
    TaskImportFormComponent.prototype.compareObjectsByUuid = function (val1, val2) {
        return val1 && val2 && val1.uuid && val2.uuid && val1.uuid === val2.uuid;
    };
    TaskImportFormComponent.prototype.addCustomerCompany = function (index) {
        var _this = this;
        var editorDialogRef = this.matDialog.open(CompanyEditorComponent, {
            panelClass: "company-editor-dialog",
            autoFocus: false,
            disableClose: true,
            data: {
                mode: "add",
            },
        });
        editorDialogRef.componentInstance.onAddCompany.subscribe(function (res) {
            _this.customerCompanyOptions.push(res);
            _this.taskImportForm.controls.taskData["controls"][index].patchValue({
                customerCompany: res,
            });
            _this.companySelect.close();
        });
    };
    TaskImportFormComponent.prototype.getTitle = function () {
        switch (this.mode) {
            case "add":
            case "clone":
                return this.translateService.instant("TASKS.TITLE_ADD");
            case "edit":
                return this.translateService.instant("TASKS.TITLE_EDIT");
            case "view":
                return this.translateService.instant("TASKS.TITLE_VIEW");
            default:
                return;
        }
    };
    TaskImportFormComponent.prototype.showHistory = function () {
        this.matDialog.open(HistoryDialogComponent, {
            panelClass: "history-dialog",
            autoFocus: false,
            disableClose: true,
            data: {
                item: this.task,
                itemOrigin: ItemsOrigins.Task,
                itemId: this.taskId,
                itemNumber: this.task.taskNumber,
            },
        });
    };
    TaskImportFormComponent.prototype.enableEditMode = function () {
        this.indexStep = this.stepper.selectedIndex;
        this.taskEditorDialogRef.close();
        this.matDialog.open(TaskImportFormComponent, {
            panelClass: "task-editor-dialog",
            autoFocus: false,
            disableClose: true,
            data: {
                mode: "edit",
                taskId: this.taskId,
                indexStep: this.indexStep,
            },
        });
    };
    TaskImportFormComponent.prototype.enableCloneMode = function () {
        this.indexStep = this.stepper.selectedIndex;
        this.taskEditorDialogRef.close();
        this.matDialog.open(TaskImportFormComponent, {
            panelClass: "task-editor-dialog",
            autoFocus: false,
            disableClose: true,
            data: {
                mode: "clone",
                taskId: this.taskId,
                indexStep: this.indexStep,
            },
        });
    };
    TaskImportFormComponent.prototype.getOrdersOptions = function (index) {
        var options = this.ordersOptions.concat(this.ordersOptionsRemoved);
        var selectedOrder = this.taskImportForm.get("taskData").value[index];
        this.taskImportForm.get("taskData").value &&
            this.taskImportForm.get("taskData").value.forEach(function (chosenOrder) {
                if (chosenOrder.uuid &&
                    chosenOrder.uuid !== "other" &&
                    options.findIndex(function (item) { return item.uuid === chosenOrder.uuid; }) > -1 &&
                    chosenOrder.uuid !== selectedOrder.uuid) {
                    options.splice(options.findIndex(function (item) { return item.uuid === chosenOrder.uuid; }), 1);
                }
            });
        if (selectedOrder.uuid &&
            options.findIndex(function (order) { return order.uuid === selectedOrder.uuid; }) === -1 &&
            selectedOrder.uuid !== "other") {
            options.push(selectedOrder.order || selectedOrder);
        }
        return options;
    };
    TaskImportFormComponent.prototype.getCustomerCompaniesOptions = function (index) {
        var options = this.customerCompanyOptions.slice();
        var selectedOrder = this.taskImportForm.get("taskData").value[index];
        if (selectedOrder.customerCompany &&
            selectedOrder.customerCompany.uuid &&
            options.findIndex(function (item) { return item.uuid === selectedOrder.customerCompany.uuid; }) === -1) {
            options.push(selectedOrder.customerCompany);
        }
        return options;
    };
    TaskImportFormComponent.prototype.getTransportOptions = function (index) {
        var options = this.transportOptions.slice();
        var selectedOrder = this.taskImportForm.get("taskData").value[index];
        if (selectedOrder.transport &&
            selectedOrder.transport.uuid &&
            options.findIndex(function (item) { return item.uuid === selectedOrder.transport.uuid; }) === -1) {
            options.push(selectedOrder.transport);
        }
        return options;
    };
    TaskImportFormComponent.prototype.reinvoiceChange = function (reinvoiceChecked, orderIndex) {
        var control = this.taskImportForm.controls.taskData;
        if (reinvoiceChecked) {
            control.controls[orderIndex].get("price").enable();
            control.controls[orderIndex].get("reinvoiceNumber").enable();
        }
        else {
            control.controls[orderIndex].get("price").setValue(null);
            control.controls[orderIndex].get("price").disable();
            control.controls[orderIndex].get("reinvoiceNumber").setValue("");
            control.controls[orderIndex].get("reinvoiceNumber").disable();
        }
    };
    TaskImportFormComponent.prototype.bdoRequiredToggle = function (bdoFree, orderIndex) {
        var control = this.taskImportForm.controls.taskData;
        var pickedOrder = control.controls[orderIndex].get("order").value;
        var pickedCustomerCompany = control.controls[orderIndex].get("customerCompany").value;
        var defaultBdo = (pickedOrder && pickedOrder.bdo) ||
            (pickedCustomerCompany && pickedCustomerCompany.bdo) ||
            null;
        if (bdoFree) {
            control.controls[orderIndex].get("bdo").clearValidators();
            control.controls[orderIndex].get("bdo").disable();
            control.controls[orderIndex].get("bdo").setValue("");
            control.controls[orderIndex].get("bdoPeopleCollection").setValue(false);
        }
        else if (!bdoFree && defaultBdo) {
            control.controls[orderIndex]
                .get("bdo")
                .setValidators(Validators.required);
            control.controls[orderIndex].get("bdo").disable();
            control.controls[orderIndex].get("bdo").setValue(defaultBdo);
        }
        else if (!bdoFree && !defaultBdo) {
            control.controls[orderIndex]
                .get("bdo")
                .setValidators(Validators.required);
            control.controls[orderIndex].get("bdo").enable();
        }
    };
    TaskImportFormComponent.prototype.bdoPeopleCollectionToggle = function (bdoPeopleCollection, orderIndex) {
        var control = this.taskImportForm.controls.taskData;
        var pickedOrder = control.controls[orderIndex].get("order").value;
        var pickedCustomerCompany = control.controls[orderIndex].get("customerCompany").value;
        var defaultBdo = (pickedOrder && pickedOrder.bdo) ||
            (pickedCustomerCompany && pickedCustomerCompany.bdo) ||
            null;
        if (bdoPeopleCollection) {
            control.controls[orderIndex].get("bdo").clearValidators();
            control.controls[orderIndex].get("bdo").disable();
            control.controls[orderIndex].get("bdo").setValue("");
            control.controls[orderIndex].get("bdoFree").setValue(false);
        }
        else if (!bdoPeopleCollection && defaultBdo) {
            control.controls[orderIndex]
                .get("bdo")
                .setValidators(Validators.required);
            control.controls[orderIndex].get("bdo").disable();
            control.controls[orderIndex].get("bdo").setValue(defaultBdo);
        }
        else if (!bdoPeopleCollection && !defaultBdo) {
            control.controls[orderIndex]
                .get("bdo")
                .setValidators(Validators.required);
            control.controls[orderIndex].get("bdo").enable();
        }
    };
    TaskImportFormComponent.prototype.updateBdo = function (bdo, orderIndex, enableCheckbox) {
        if (enableCheckbox === void 0) { enableCheckbox = false; }
        var control = this.taskImportForm.controls.taskData;
        if (enableCheckbox) {
            control.controls[orderIndex].get("bdoFree").enable();
            control.controls[orderIndex].get("bdoPeopleCollection").enable();
        }
        var bdoFree = control.controls[orderIndex].get("bdoFree").value;
        var bdoPeopleCollection = control.controls[orderIndex].get("bdoPeopleCollection").value;
        control.controls[orderIndex].get("bdo").clearValidators();
        if (bdo && !bdoFree && !bdoPeopleCollection) {
            control.controls[orderIndex].get("bdo").setValue(bdo);
            control.controls[orderIndex].get("bdo").disable();
        }
        else if ((!bdo && !bdoFree) || !bdoPeopleCollection) {
            control.controls[orderIndex].get("bdo").setValue("");
            control.controls[orderIndex].get("bdo").enable();
        }
        else if (!bdo && (bdoFree || bdoPeopleCollection)) {
            control.controls[orderIndex].get("bdo").setValue("");
            control.controls[orderIndex].get("bdo").disable();
        }
    };
    TaskImportFormComponent.prototype.startEndDateTimezoneValid = function (start, end) {
        if (this.mode === "view") {
            //we need to display current values even if they are wrong in `view` mode
            if (start && !this.startTimeOptions.includes(start)) {
                this.startTimeOptions = [start].concat(this.startTimeOptions);
            }
            if (end && !this.endTimeOptions.includes(end)) {
                this.endTimeOptions = [end].concat(this.endTimeOptions);
            }
            return true;
        }
        else {
            // if at least one of current values is wrong we clear fields
            if (start && !this.startTimeOptions.includes(start)) {
                return false;
            }
            if (end && !this.endTimeOptions.includes(end)) {
                return false;
            }
            return true;
        }
    };
    TaskImportFormComponent.prototype.createForm = function (inputData) {
        var _this = this;
        if (inputData === void 0) { inputData = new TaskImport(); }
        inputData.startTime = inputData.startDate
            ? moment(inputData.startDate).format("HH:mm")
            : null;
        inputData.endTime = inputData.endDate
            ? moment(inputData.endDate).format("HH:mm")
            : null;
        if (!this.startEndDateTimezoneValid(inputData.startTime, inputData.endTime)) {
            inputData.startTime = null;
            inputData.endTime = null;
        }
        this.taskImportForm = this.formBuilder.group({
            name: new FormControl(inputData.name || null, Validators.required),
            companyBranchId: new FormControl((inputData.companyBranch && inputData.companyBranch.uuid) ||
                this.data.companyBranchId ||
                null, Validators.required),
            startDate: new FormControl(this.mode === "clone"
                ? null
                : inputData.startDate || this.data.startDate || null, Validators.required),
            startTime: new FormControl(inputData.startTime || this.data.startTime || null, Validators.required),
            endDate: new FormControl(this.mode === "clone"
                ? null
                : inputData.endDate || this.data.endDate || null, Validators.required),
            endTime: new FormControl(inputData.endTime || this.data.endTime || null, Validators.required),
            taskType: new FormControl(inputData.taskType || this.taskTypes.Import, Validators.required),
            comment: new FormControl(this.mode === "add" || this.mode === "clone"
                ? null
                : inputData.comment || null),
            referenceNumber: new FormControl(this.mode === "add" || this.mode === "clone"
                ? null
                : inputData.referenceNumber || null),
            emailService: new FormControl(this.mode === "add" || this.mode === "clone"
                ? true
                : inputData.emailService),
            registrationNumFront: new FormControl(this.mode === "add" ? null : inputData.registrationNumFront || null),
            registrationNumBack: new FormControl(this.mode === "add" ? null : inputData.registrationNumBack || null),
            taskData: this.formBuilder.array([], Validators.required),
            recurring: new FormControl(false),
            recurringInfo: this.formBuilder.group({
                intervalType: new FormControl("WEEK"),
                intervalValue: new FormControl(1, Validators.pattern(/\d/)),
                rangeDateEnd: new FormControl(inputData.startDate),
                weekdays: new FormGroup({
                    Monday: new FormControl(false),
                    Tuesday: new FormControl(false),
                    Wednesday: new FormControl(false),
                    Thursday: new FormControl(false),
                    Friday: new FormControl(false),
                    Saturday: new FormControl(false),
                    Sunday: new FormControl(false),
                }),
            }),
        }, {
            validator: StartEndDateValidator.CheckDates,
        });
        inputData.taskData &&
            inputData.taskData.forEach(function (item, index) {
                return _this.addOrderSubform(index, item);
            });
        this.setRangeEndDateMax(this.taskImportForm.get("startDate").value);
        if (this.mode === "add" || this.mode === "clone") {
            this.taskImportForm.get("companyBranchId").disable();
        }
        if (this.mode === "view") {
            this.taskImportForm.disable();
        }
    };
    TaskImportFormComponent.prototype.createSearchFilters = function () {
        var _this = this;
        this.searchCustomerCompany = new FormControl("");
        this.searchTransport = new FormControl("");
        this.searchOrders = new FormControl("");
        this.searchCustomerCompany.valueChanges
            .debounceTime(300)
            .subscribe(function (value) {
            _this.filteredListsService
                .getCompaniesList(__assign({}, _this.filterParams, { search: value }))
                .subscribe(function (response) {
                _this.customerCompanyOptions = response.customerCompanies;
            });
        });
        this.searchTransport.valueChanges.debounceTime(300).subscribe(function (value) {
            _this.filteredListsService
                .getTransportList(__assign({}, _this.filterParamsFullList, { name: value }))
                .subscribe(function (response) {
                _this.transportOptions = response.transports;
            });
        });
        this.searchOrders.valueChanges.debounceTime(300).subscribe(function (value) {
            _this.filteredListsService
                .getOrdersList(__assign({}, _this.filterOrdersParams, { search: value }))
                .subscribe(function (response) {
                _this.ordersOptions = response.orders;
                _this.ordersOptions = _this.ordersOptions.filter(function (order) {
                    return order.companyBranch === null ||
                        (order.companyBranch &&
                            order.companyBranch.uuid ===
                                _this.taskImportForm.getRawValue().companyBranchId);
                });
            });
        });
    };
    TaskImportFormComponent.prototype.orderFilter = function () {
        var _this = this;
        this.filteredListsService
            .getOrdersList(__assign({}, this.filterOrdersParams))
            .subscribe(function (response) {
            _this.ordersOptions = response.orders;
            _this.ordersOptions = _this.ordersOptions.filter(function (order) {
                return order.companyBranch === null ||
                    (order.companyBranch &&
                        order.companyBranch.uuid ===
                            _this.taskImportForm.getRawValue().companyBranchId);
            });
        });
    };
    TaskImportFormComponent.prototype.createNewOrders = function (taskData, formCompanyBranchId) {
        var _this = this;
        var taskDataWithOtherType = taskData.filter(function (task) { return task.uuid === "other"; });
        if (!taskDataWithOtherType.length) {
            return of([]);
        }
        var tmpCompanyBranchId;
        if (this.data.companyBranchId) {
            tmpCompanyBranchId = this.data.companyBranchId;
        }
        else if (this.task &&
            this.task.companyBranch &&
            this.task.companyBranch.uuid) {
            tmpCompanyBranchId = this.task.companyBranch.uuid;
        }
        else if (this.task && this.task.companyBranchId) {
            tmpCompanyBranchId = this.task.companyBranchId;
        }
        else {
            tmpCompanyBranchId = formCompanyBranchId;
        }
        var observables = taskDataWithOtherType.map(function (item) {
            var newOrder = {
                companyBranchId: tmpCompanyBranchId,
                customerCompanyId: item.customerCompany.uuid,
                salesAccepted: item.salesAccepted,
                bdo: item.bdo ? item.bdo.toString() : null,
                bdoFree: Boolean(item.bdoFree && !item.bdo),
                bdoPeopleCollection: Boolean(item.bdoPeopleCollection && !item.bdo),
                kpoEmails: item.kpoEmails,
                reinvoice: item.reinvoice,
                price: Number(String(item.price).replace(",", ".")),
                reinvoiceNumber: item.reinvoiceNumber,
                invoiceComment: item.invoiceComment,
                transportId: item.transport.uuid,
                suggestedDate: moment(item.suggestedDate).format("YYYY-MM-DD") ||
                    moment(_this.taskImportForm.get("startDate").value).format("YYYY-MM-DD") ||
                    moment().format("YYYY-MM-DD"),
                referenceNumber: item.referenceNumber,
                registrationNumFront: item.registrationNumFront,
                registrationNumBack: item.registrationNumBack,
                orderData: mapOrderDataApiValues(item.orderData),
                orderType: OrderTypes.Import,
            };
            return _this.ordersService.createOrder(newOrder).pipe(map(function (resOrder) {
                return {
                    customerCompanyId: resOrder.customerCompany
                        ? resOrder.customerCompany.uuid
                        : null,
                    customerCompany: resOrder.customerCompany,
                    bdo: resOrder.bdo ? resOrder.bdo.toString() : null,
                    bdoFree: resOrder.bdoFree && !resOrder.bdo,
                    bdoPeopleCollection: resOrder.bdoPeopleCollection && !resOrder.bdo,
                    kpoEmails: resOrder.kpoEmails,
                    reinvoice: resOrder.reinvoice,
                    price: resOrder.price,
                    reinvoiceNumber: resOrder.reinvoiceNumber,
                    invoiceComment: resOrder.invoiceComment,
                    salesAccepted: resOrder.salesAccepted,
                    registrationNumFront: resOrder.registrationNumFront,
                    suggestedDate: item.suggestedDate,
                    registrationNumBack: resOrder.registrationNumBack,
                    referenceNumber: resOrder.referenceNumber || null,
                    transport: resOrder.transport || null,
                    uuid: resOrder.uuid,
                    orderData: item.orderData,
                };
            }));
        });
        return zip.apply(void 0, observables);
    };
    TaskImportFormComponent.prototype.mapTaskData = function (taskData) {
        taskData = taskData.map(function (item) {
            var taskDataItem = {
                order: item.order || null,
                customerCompanyId: item.customerCompany
                    ? item.customerCompany.uuid
                    : null,
                customerCompany: item.customerCompany,
                registrationNumFront: item.registrationNumFront,
                registrationNumBack: item.registrationNumBack,
                salesAccepted: item.salesAccepted,
                suggestedDate: item.suggestedDate,
                referenceNumber: item.referenceNumber || null,
                bdo: item.bdo ? item.bdo.toString() : null,
                bdoFree: item.bdoFree && !item.bdo,
                bdoPeopleCollection: item.bdoPeopleCollection && !item.bdo,
                kpoEmails: item.kpoEmails,
                reinvoice: item.reinvoice,
                price: Number(String(item.price).replace(",", ".")) || null,
                reinvoiceNumber: item.reinvoiceNumber,
                invoiceComment: item.invoiceComment,
                transportId: item.transport.uuid || null,
                orderData: mapOrderDataApiValues(item.orderData),
            };
            if (item.order) {
                item.order.comment = item.order_comment || null;
            }
            if (item.uuid !== "other") {
                taskDataItem["orderId"] = item.uuid;
            }
            return taskDataItem;
        });
        return taskData;
    };
    TaskImportFormComponent.prototype.changeStep = function (event) {
        this.indexStep = event.selectedIndex;
    };
    TaskImportFormComponent.prototype.prevStep = function (event) {
        this.indexStep = this.indexStep - 1 >= 0 ? this.indexStep - 1 : 0;
    };
    TaskImportFormComponent.prototype.getTooltipCompany = function (company) {
        if (company) {
            return company.name + " \n      " + company.address.street + "\n      " + company.address.postalCode + " " + company.address.city;
        }
    };
    TaskImportFormComponent.prototype.resendNotification = function (index) {
        var _this = this;
        var orderId = this.task.taskData[index].order.uuid;
        this.isLoading = true;
        this.ordersService.resendNotification(orderId).subscribe(function () {
            _this.isLoading = false;
            _this.snackBar.open(_this.translateService.instant("TASKS.MESSAGES.NOTIFICATION_SENT"), "", { duration: 5000 });
        }, function () {
            _this.isLoading = false;
            _this.snackBar.open(_this.translateService.instant("TASKS.MESSAGES.ERROR"), "", { duration: 5000 });
        });
    };
    return TaskImportFormComponent;
}());
export { TaskImportFormComponent };
