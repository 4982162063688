import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CompanyAccountsModule } from "../company-accounts/company-accounts.module";
import { CompanyBranchesModule } from "../company-branches/company-branches.module";
import { CompanyCarsModule } from "../company-cars/company-cars.module";
import { CompanyKpoModule } from "../company-kpo/company-kpo.module";
import { SharedModule } from "../shared/shared.module";
import { CompaniesContainerComponent } from "./companies.container.component";
import { Routes } from "./companies.routes";
import { CompaniesListComponent } from "./components/companies-list/companies-list.component";
import { CompanyAccountsListComponent } from "./components/company-editor/company-accounts-list/company-accounts-list.component";
import { CompanyBranchesListComponent } from "./components/company-editor/company-branches-list/company-branches-list.component";
import { CompanyCarsListComponent } from "./components/company-editor/company-cars-list/company-cars-list.component";
import { CompanyEditorComponent } from "./components/company-editor/company-editor.component";
import { CompanyKpoListComponent } from "./components/company-editor/company-kpo-list/company-kpo-list.component";

@NgModule({
  declarations: [
    CompaniesContainerComponent,
    CompaniesListComponent,
    CompanyEditorComponent,
    CompanyAccountsListComponent,
    CompanyKpoListComponent,
    CompanyBranchesListComponent,
    CompanyCarsListComponent,
  ],
  imports: [
    RouterModule.forChild(Routes),
    SharedModule,
    CompanyAccountsModule,
    CompanyKpoModule,
    CompanyBranchesModule,
    CompanyCarsModule,
  ],
  exports: [CompaniesListComponent, CompanyEditorComponent],
  entryComponents: [CompanyEditorComponent],
})
export class CompaniesModule {}
