import { EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./auth.service";
var CompanyAccountsService = /** @class */ (function () {
    function CompanyAccountsService(http, authService) {
        this.http = http;
        this.authService = authService;
        this.onEditEvent = new EventEmitter();
    }
    CompanyAccountsService.prototype.getCompanyAccountsList = function (params, companyUuid) {
        return this.http.get(environment.apiUrl + "/" + this.authService.getUserType() + "/customersCompanies/" + companyUuid + "/users", { params: params }).pipe(map(function (res) { return res; }));
    };
    CompanyAccountsService.prototype.getCompanyAccount = function (companyUuid, accountUuid) {
        return this.http.get(environment.apiUrl + "/" + this.authService.getUserType() + "/customersCompanies/" + companyUuid + "/users/" + accountUuid)
            .pipe(map(function (res) { return res.client; }));
    };
    CompanyAccountsService.prototype.createCompanyAccount = function (companyUuid, account) {
        return this.http.post(environment.apiUrl + "/" + this.authService.getUserType() + "/customersCompanies/" + companyUuid + "/users", account)
            .pipe(map(function (res) { return res.client; }));
    };
    CompanyAccountsService.prototype.updateCompanyAccount = function (companyUuid, accountUuid, account) {
        return this.http.put(environment.apiUrl + "/" + this.authService.getUserType() + "/customersCompanies/" + companyUuid + "/users/" + accountUuid, account);
    };
    CompanyAccountsService.prototype.attachCompanyAccount = function (companyUuid, accountUuid) {
        return this.http.post(environment.apiUrl + "/" + this.authService.getUserType() + "/customersCompanies/" + companyUuid + "/attachUser", { clientUuid: accountUuid });
    };
    CompanyAccountsService.prototype.detachCompanyAccount = function (companyUuid, accountUuid) {
        return this.http.post(environment.apiUrl + "/" + this.authService.getUserType() + "/customersCompanies/" + companyUuid + "/detachUser", { clientUuid: accountUuid });
    };
    CompanyAccountsService.prototype.changeCompanyAccountPassword = function (companyUuid, accountUuid, password) {
        return this.http.put(environment.apiUrl + "/" + this.authService.getUserType() + "/customersCompanies/" + companyUuid + "/users/" + accountUuid + "/set-password", { password: password });
    };
    CompanyAccountsService.ngInjectableDef = i0.defineInjectable({ factory: function CompanyAccountsService_Factory() { return new CompanyAccountsService(i0.inject(i1.HttpClient), i0.inject(i2.AuthService)); }, token: CompanyAccountsService, providedIn: "root" });
    return CompanyAccountsService;
}());
export { CompanyAccountsService };
