import { Component, Input } from '@angular/core';
import { RecyclingItem } from '../../../../../../shared/models/recycling.model';

@Component({
  selector: 'app-recycling-items-display',
  templateUrl: './recycling-items-display.component.html',
  styleUrls: ['./recycling-items-display.component.scss']
})
export class RecyclingItemsDisplayComponent {
  @Input() recyclingItems: RecyclingItem[];
}
