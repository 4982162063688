import { EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./auth.service";
var CompaniesService = /** @class */ (function () {
    function CompaniesService(http, authService) {
        this.http = http;
        this.authService = authService;
        this.onEditEvent = new EventEmitter();
    }
    CompaniesService.prototype.getCompaniesList = function (params) {
        return this.http.get(environment.apiUrl + "/" + this.authService.getUserType() + "/customersCompanies", { params: params })
            .pipe(map(function (res) { return res; }));
    };
    CompaniesService.prototype.getCompany = function (companyUuid) {
        return this.http.get(environment.apiUrl + "/" + this.authService.getUserType() + "/customersCompanies/" + companyUuid).pipe(map(function (res) { return res.customerCompany; }));
    };
    CompaniesService.prototype.createCompany = function (company) {
        return this.http.post(environment.apiUrl + "/" + this.authService.getUserType() + "/customersCompanies", company).pipe(map(function (res) { return res.customerCompany; }));
    };
    CompaniesService.prototype.updateCompany = function (companyUuid, company) {
        return this.http.post(environment.apiUrl + "/" + this.authService.getUserType() + "/customersCompanies/" + companyUuid, company);
    };
    CompaniesService.prototype.updateCompanyBusinessTerms = function (companyUuid, businessTerms) {
        return this.http.put(environment.apiUrl + "/" + this.authService.getUserType() + "/customersCompanies/" + companyUuid, businessTerms);
    };
    CompaniesService.prototype.deleteCompany = function (companyUuid) {
        return this.http.delete(environment.apiUrl + "/" + this.authService.getUserType() + "/customersCompanies/" + companyUuid);
    };
    CompaniesService.prototype.getOwnersList = function (params) {
        return this.http.get(environment.apiUrl + "/" + this.authService.getUserType() + "/users", { params: params })
            .pipe(map(function (res) { return res; }));
    };
    CompaniesService.prototype.getGusInformation = function (companyNip) {
        return this.http.post(environment.apiUrl + "/" + this.authService.getUserType() + "/gus_search", { nip: companyNip })
            .pipe(map(function (res) { return res.res; }));
    };
    CompaniesService.ngInjectableDef = i0.defineInjectable({ factory: function CompaniesService_Factory() { return new CompaniesService(i0.inject(i1.HttpClient), i0.inject(i2.AuthService)); }, token: CompaniesService, providedIn: "root" });
    return CompaniesService;
}());
export { CompaniesService };
