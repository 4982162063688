import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import {Company, CompaniesListRespone, Gus} from '../shared/models/companies.model';
import { RequestParams } from '../shared/models/list.model';
import { AuthService } from './auth.service';
import { UsersListResponse } from '../shared/models/users.model';

@Injectable({
  providedIn: 'root'
})
export class CompaniesService {
  onEditEvent: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient, private authService: AuthService) {
  }

  getCompaniesList(params: RequestParams): Observable<CompaniesListRespone> {
    return this.http.get(`${environment.apiUrl}/${this.authService.getUserType()}/customersCompanies`, {params: params})
      .pipe(map((res: CompaniesListRespone) => res));
  }

  getCompany(companyUuid: Company['uuid']): Observable<Company> {
    return this.http.get(`${environment.apiUrl}/${this.authService.getUserType()}/customersCompanies/${companyUuid}`).pipe(
      map((res: any) => res.customerCompany)
    );
  }

  createCompany(company: Company): Observable<Company> {
    return this.http.post<any>(`${environment.apiUrl}/${this.authService.getUserType()}/customersCompanies`, company).pipe(
      map((res: any) => res.customerCompany)
    );
  }

  updateCompany(companyUuid: Company['uuid'], company: Company): Observable<Company> {
    return this.http.post<any>(`${environment.apiUrl}/${this.authService.getUserType()}/customersCompanies/${companyUuid}`, company);
  }

  updateCompanyBusinessTerms(companyUuid: Company['uuid'], businessTerms: Company['businessTerms']): Observable<Company> {
    return this.http.put<any>(`${environment.apiUrl}/${this.authService.getUserType()}/customersCompanies/${companyUuid}`, businessTerms);
  }

  deleteCompany(companyUuid: Company['uuid']) {
    return this.http.delete<any>(`${environment.apiUrl}/${this.authService.getUserType()}/customersCompanies/${companyUuid}`);
  }

  public getOwnersList(params): Observable<UsersListResponse> {
    return this.http.get(`${environment.apiUrl}/${this.authService.getUserType()}/users`, {params: params})
      .pipe(map((res: UsersListResponse) => res));
  }

  public getGusInformation(companyNip:string): Observable<Gus> {
    return this.http.post<any>(`${environment.apiUrl}/${this.authService.getUserType()}/gus_search`, {nip: companyNip})
      .pipe(map((res: any) => res.res));
  }
}