import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { CalendarContainerComponent } from './calendar-container.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { Routes } from './calendar.routes';
import { SharedModule } from '../shared/shared.module';
import { CalendarDateFormatter, CalendarModule as AngularCalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CustomDateFormatter } from '../../shared/utils/custom-date-formatter';
import { TasksModule } from '../tasks/tasks.module';
import { DayEventsSummaryComponent } from './components/day-events-summary/day-events-summary.component';
import { DayAdditionalFieldComponent } from './components/day-additional-field/day-additional-field.component';


@NgModule({
  declarations: [
    CalendarContainerComponent,
    CalendarComponent,
    DayEventsSummaryComponent,
    DayAdditionalFieldComponent
  ],
  imports: [
    RouterModule.forChild(Routes),
    TasksModule,

    SharedModule,
    AngularCalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),

    TranslateModule,

    FuseSharedModule
  ],
  providers: [
    {
      provide: CalendarDateFormatter,
      useClass: CustomDateFormatter
    }
  ],
  exports: [
    CalendarComponent,
    DayEventsSummaryComponent,
    DayAdditionalFieldComponent
  ]
})

export class CalendarModule {
}
