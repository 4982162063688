import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { FuseTranslationLoaderService } from "../../../../../../@fuse/services/translation-loader.service";
import { locale as polish } from "../../../../../translations/pl";
import { locale as english } from "../../../../../translations/en";
import { fuseAnimations } from "../../../../../../@fuse/animations";
import { remondisListAnimation } from "../../../../../shared/consts/remondis.lists.animation";
import { List } from "../../../../../shared/models/list.class";
import { User } from "../../../../../shared/models/users.model";
import {
  ListFilters,
  RequestParams
} from "../../../../../shared/models/list.model";
import { AuthService } from "../../../../../services/auth.service";
import {
  CustomerCompanyBranch,
  CompanyBranchesListRespone
} from "../../../../../shared/models/company-branches.model";
import { CompanyBranchesService } from "../../../../../services/company-branches.service";
import { CompanyBranchEditorComponent } from "../../../../company-branches/components/company-branch-editor/company-branch-editor.component";

@Component({
  selector: "app-company-branches-list",
  templateUrl: "./company-branches-list.component.html",
  styleUrls: ["./company-branches-list.component.scss"],
  animations: [...fuseAnimations, ...remondisListAnimation]
})
export class CompanyBranchesListComponent extends List
  implements OnInit, OnDestroy {
  @Input() companyUuid: string;
  public displayedColumns = ["name", "address", "contact"];
  public user: User;
  private listFilters: ListFilters = {};
  public requestParams: RequestParams = { page: 0, limit: 500 };
  private reloadList$;

  constructor(
    private companyBranchesService: CompanyBranchesService,
    private authService: AuthService,
    private matDialog: MatDialog,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService
  ) {
    super();
    this.user = this.authService.getUser();

    this.reloadList$ = companyBranchesService.onEditEvent.subscribe(() => {
      this.getElementsList();
    });

    this._fuseTranslationLoaderService.loadTranslations(polish, english);

    this.getElementsList = () => {
      this.companyBranchesService
        .getCompanyBranchesList(this.requestParams, this.companyUuid)
        .subscribe((res: CompanyBranchesListRespone) => {
          this.totalCount = res.branches.length;
          this.dataSource = res.branches;
        });
    };
  }

  ngOnInit() {
    super.ngOnInit();
    this.getElementsList();
  }

  ngOnDestroy() {
    this.reloadList$.unsubscribe();
  }

  public addCompanyBranch() {
    this.disableAnimations = true;
    this.matDialog.open(CompanyBranchEditorComponent, {
      panelClass: "company-branch-editor-dialog",
      autoFocus: false,
      disableClose: true,
      data: {
        mode: "add",
        companyUuid: this.companyUuid
      }
    });
  }

  public viewCompanyBranch(companyBranchUuid: CustomerCompanyBranch["uuid"]) {
    this.disableAnimations = true;
    this.matDialog.open(CompanyBranchEditorComponent, {
      panelClass: "company-branch-editor-dialog",
      autoFocus: false,
      disableClose: true,
      data: {
        mode: "view",
        companyUuid: this.companyUuid,
        companyBranchUuid: companyBranchUuid
      }
    });
  }
}
