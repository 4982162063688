import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { locale as polish } from '../../../../translations/pl';
import { locale as english } from '../../../../translations/en';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: [ './confirm-modal.component.scss' ]
})
export class ConfirmModalComponent implements OnInit {
  public confirmMessage: string;
  public titleMessage: string;
  public confirmButton: string;
  public skipButton?: string;

  constructor(public matDialogRef: MatDialogRef<ConfirmModalComponent>,
              private _fuseTranslationLoaderService: FuseTranslationLoaderService) {
    this._fuseTranslationLoaderService.loadTranslations(polish, english);
  }

  ngOnInit() {
  }

}
