<div id="change-password" fxLayout="column">

  <div id="change-password-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

    <div id="change-password-form">

      <div class="logo">
        <img src="assets/images/logo.svg">
      </div>

      <div class="title">{{getTitle()}}</div>

      <form name="changePasswordForm" [formGroup]="changePasswordForm" novalidate (ngSubmit)="submit()">

        <mat-form-field>
          <input matInput
                 type="password"
                 placeholder="{{'SHARED.PASS' | translate}}"
                 formControlName="password"
                 required>
          <mat-error
            *ngIf="changePasswordForm.get('password').touched && changePasswordForm.controls['password'].errors?.minlength">
            {{'SHARED.MESSAGES.PASSWORDS_MIN_CHARACTERS' | translate}}
          </mat-error>
        </mat-form-field>

        <mat-form-field>
          <input matInput
                 type="password"
                 placeholder="{{'SHARED.CONFIRM_PASS' | translate}}"
                 formControlName="confirmPassword"
                 required>
          <mat-error
            *ngIf="changePasswordForm.get('confirmPassword').touched && changePasswordForm.controls['confirmPassword'].errors?.MatchPassword">
            {{'SHARED.MESSAGES.PASSWORDS_NOT_MATCH' | translate}}
          </mat-error>
        </mat-form-field>

        <button mat-raised-button
                type="submit"
                color="primary"
                class="submit-button"
                aria-label="LOG IN"
                [disabled]="changePasswordForm.invalid || isLoading">
          <span *ngIf="!isLoading">
            {{'SHARED.SEND' | translate}}
          </span>
          <span *ngIf="isLoading" class="loading-spinner-wrapper">
              <mat-progress-spinner
                mode="indeterminate"
                [strokeWidth]="2"
                [diameter]="20">
              </mat-progress-spinner>
            </span>
        </button>

      </form>

    </div>

  </div>

</div>