import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-task-order-changes',
  templateUrl: './task-order-changes.component.html',
  styleUrls: ['./task-order-changes.component.scss']
})
export class TaskOrderChangesComponent {
@Input() changes: any;
}
