export const TransportCategories = [
  {
    name: 'TRANSPORT.CATEGORIES.1',
    value: 1
  },
  {
    name: 'TRANSPORT.CATEGORIES.2',
    value: 2
  },
  {
    name: 'TRANSPORT.CATEGORIES.3',
    value: 3
  },
  {
    name: 'TRANSPORT.CATEGORIES.4',
    value: 4
  },
  {
    name: 'TRANSPORT.CATEGORIES.5',
    value: 5
  }
];