import { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TaskStatuses } from '../../../../shared/models/tasks.model';
import { TasksService } from '../../../../services/tasks.service';
import { NoteCalendarService } from '../../../../services/note-calendar.service';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { TranslateService } from '@ngx-translate/core';
import logoBase64 from './logoBase64';
import * as moment from 'moment';
var PdfDownloaderComponent = /** @class */ (function () {
    function PdfDownloaderComponent(noteCalendarService, tasksService, translateService) {
        this.noteCalendarService = noteCalendarService;
        this.tasksService = tasksService;
        this.translateService = translateService;
        this.tasksList = [];
        this.buttonLoading = false;
        this.buttonDisabled = true;
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
        this.pdfForm = new FormGroup({
            dateFilter: new FormControl('', Validators.required)
        });
    }
    PdfDownloaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.pdfForm.get('dateFilter').valueChanges
            .debounceTime(300)
            .subscribe(function (value) {
            _this.getTasks();
        });
    };
    PdfDownloaderComponent.prototype.createTable = function () {
        var _this = this;
        var tableTitle = function () {
            var date = new Date(_this.pdfForm.get('dateFilter').value);
            var dayName = _this.translateService.instant("SHARED.WEEKDAYS." + (date.getDay() - 1));
            return _this.companyBranchName + " - " + _this.parseDate + ", " + dayName;
        };
        var getTime = function (date) {
            var dateObj = new Date(date);
            var hours = String(dateObj.getHours());
            var minutes = String(dateObj.getMinutes());
            return ("0" + hours).slice(-2) + ":" + ("0" + minutes).slice(-2);
        };
        var getComment = function (comment) { return (!!comment ? comment : _this.translateService.instant('TASKS.PDFFILTER.NONE')); };
        var colorTaskRow = function (taskType) {
            return taskType === 'export' ? '#b7d6aa' : '#ffffff';
        };
        var body = [];
        var headerRow = [
            {
                text: tableTitle(),
                style: 'header',
                fillColor: '#000000',
                color: 'white',
                colSpan: 7,
                alignment: 'center'
            },
            '',
            '',
            '',
            '',
            '',
            ''
        ];
        var subheaderColTitles = [
            this.translateService.instant('TASKS.PDFFILTER.TABLE.HOUR'),
            this.translateService.instant('TASKS.PDFFILTER.TABLE.TYPE'),
            this.translateService.instant('TASKS.PDFFILTER.TABLE.TITLE'),
            this.translateService.instant('TASKS.PDFFILTER.TABLE.COMPANY'),
            this.translateService.instant('TASKS.PDFFILTER.TABLE.CARGO'),
            this.translateService.instant('TASKS.PDFFILTER.TABLE.VEHICLE'),
            this.translateService.instant('TASKS.PDFFILTER.TABLE.COMMENTS')
        ];
        var subheaderRow = [];
        // header
        body.push(headerRow);
        // subheader
        subheaderColTitles.map(function (item) {
            var subheaderItem = {
                text: item,
                style: 'smaller',
                alignment: 'center',
                fillColor: '#999999',
                color: 'white'
            };
            subheaderRow.push(subheaderItem);
        });
        body.push(subheaderRow);
        // task row
        this.tasksList.forEach(function (item) {
            var companyCol = [];
            item.taskData.forEach(function (taskDataItem) {
                var cargo = [
                    {
                        style: 'smaller',
                        fillColor: colorTaskRow(item.taskType),
                        margin: [-5, -3, 0, -3],
                        layout: {},
                        table: {
                            widths: [100],
                            body: []
                        }
                    }
                ];
                var companyRow = function (item) {
                    return {
                        text: item.customerCompany.name,
                        border: [true, true, true, true],
                        style: 'smaller'
                    };
                };
                var cargoRow = function (item) {
                    var cargoEl = {
                        style: 'smaller',
                        ul: [
                            item.recyclingItem.name + " - " + item.weight + " kg",
                            {
                                ul: []
                            }
                        ]
                    };
                    item.packageTypes.forEach(function (packageType) {
                        var unit = _this.translateService.instant("TASKS.PDFFILTER.UNITS." + packageType.unit.toUpperCase());
                        cargoEl.ul[1]['ul'].push(packageType['name'] + " (" + packageType['value'] + " " + unit + ")");
                    });
                    return cargoEl;
                };
                var fractionRow = function (fractions, value) {
                    var fractionEl = {
                        style: 'smaller',
                        ul: []
                    };
                    fractions.forEach(function (fraction) {
                        fractionEl.ul.push(fraction['name']);
                    });
                    fractionEl.ul.push({
                        ul: [value + " kg"]
                    });
                    return fractionEl;
                };
                if (item.taskType === 'export') {
                    var exportEl = [
                        {
                            fillColor: colorTaskRow(item.taskType),
                            margin: [-5, -3, 0, -3],
                            layout: {},
                            table: {
                                widths: [100],
                                body: []
                            }
                        }
                    ];
                    companyCol.push([companyRow(taskDataItem), exportEl]);
                    exportEl[0]['table'].body.push([fractionRow(taskDataItem.fractions, taskDataItem.value)]);
                }
                else {
                    companyCol.push([companyRow(taskDataItem), cargo]);
                    taskDataItem.orderData.forEach(function (orderEl) {
                        cargo[0]['table'].body.push([cargoRow(orderEl)]);
                    });
                }
            });
            var taskRow = [
                {
                    text: getTime(item.startDate),
                    style: 'smaller',
                    alignment: 'center',
                    fillColor: colorTaskRow(item.taskType)
                },
                {
                    text: _this.translateService.instant("TASKS.TYPES." + (item.taskType === 'receiving' ? 'IMPORT' : item.taskType.toUpperCase())),
                    style: 'smaller',
                    alignment: 'center',
                    fillColor: colorTaskRow(item.taskType)
                },
                {
                    text: item.name,
                    style: 'smaller',
                    alignment: 'center',
                    fillColor: colorTaskRow(item.taskType)
                },
                {
                    style: 'smaller',
                    fillColor: colorTaskRow(item.taskType),
                    colSpan: 2,
                    margin: [-5, -3, -6, -3],
                    layout: {
                        defaultBorder: false
                    },
                    table: {
                        widths: [100, 100],
                        body: companyCol
                    }
                },
                '',
                {
                    text: "Typ: " + item.transport.name + " \nRej.P: " + (item.forwarderRegistrationNumberFront || '-') + " \nRej.T: " + (item.forwarderRegistrationNumberBack || '-'),
                    style: 'smaller',
                    alignment: 'left',
                    fillColor: colorTaskRow(item.taskType)
                },
                {
                    text: getComment(item.comment),
                    style: 'smaller',
                    alignment: 'center',
                    fillColor: colorTaskRow(item.taskType)
                }
            ];
            body.push(taskRow);
        });
        return body;
    };
    PdfDownloaderComponent.prototype.drawTable = function () {
        return {
            pageOrientation: 'portrait',
            pageMargins: [50, 50, 50, 50],
            content: [
                {
                    columns: [
                        {
                            image: logoBase64,
                            width: 160,
                            margin: [0, 10]
                        },
                        this.note && this.note[0] &&
                            {
                                text: this.note[0].note || '',
                                width: 460,
                                margin: [100, 10],
                                style: 'note'
                            }
                    ],
                },
                {
                    table: {
                        widths: ['auto', 'auto', 'auto', 100, 100, 70, 'auto'],
                        body: this.createTable()
                    }
                }
            ],
            styles: {
                header: {
                    fontSize: 14,
                    bold: true
                },
                note: {
                    bold: true,
                },
                smaller: {
                    fontSize: 8
                },
                smallerNoBorder: {
                    fontSize: 8
                }
            }
        };
    };
    PdfDownloaderComponent.prototype.ngOnChanges = function (changes) {
        if (changes.companyBranchId.currentValue && this.pdfForm.get('dateFilter').value) {
            this.getTasks();
        }
    };
    PdfDownloaderComponent.prototype.getTasks = function (downloadPDF) {
        var _this = this;
        if (downloadPDF === void 0) { downloadPDF = false; }
        this.buttonLoading = true;
        this.getDescriptionForAdditionalFieldIToPdf();
        this.tasksService
            .getTasksListByDay(this.parseDate, this.companyBranchId)
            .subscribe(function (res) {
            _this.buttonLoading = false;
            if (res.status === 'OK') {
                _this.tasksList = res.tasks.filter(function (task) { return task.status !== TaskStatuses.Cancelled; });
                if (res.tasks.length === 0) {
                    _this.buttonDisabled = true;
                }
                else {
                    _this.buttonDisabled = false;
                }
                if (downloadPDF) {
                    _this.downloadReportFile();
                }
            }
        });
    };
    Object.defineProperty(PdfDownloaderComponent.prototype, "parseDate", {
        get: function () {
            var selectedDate = new Date(this.pdfForm.get('dateFilter').value);
            var year = selectedDate.getFullYear();
            var month = selectedDate.getMonth() + 1;
            var day = selectedDate.getDate();
            return year + "-" + ("0" + month).slice(-2) + "-" + ("0" + day).slice(-2);
        },
        enumerable: true,
        configurable: true
    });
    PdfDownloaderComponent.prototype.getDescriptionForAdditionalFieldIToPdf = function () {
        var _this = this;
        var givenDay = moment(this.pdfForm.get('dateFilter').value).format('YYYY-MM-DD');
        this.noteCalendarService.getNoteList({
            page: 1,
            limit: 1000, dateFrom: givenDay, dateTo: givenDay, companyBranchId: this.companyBranchId
        }).subscribe(function (res) {
            _this.note = res.calendarNotes.map(function (item) { return ({
                date: item.date,
                note: item.note
            }); });
        });
    };
    PdfDownloaderComponent.prototype.getReport = function () {
        this.getTasks(true);
    };
    PdfDownloaderComponent.prototype.downloadReportFile = function () {
        pdfMake
            .createPdf(this.drawTable())
            .download(this.translateService.instant('TASKS.PDFFILTER.FILENAME') + "-" + this.parseDate + ".pdf");
    };
    return PdfDownloaderComponent;
}());
export { PdfDownloaderComponent };
