import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import * as i0 from "@angular/core";
import * as i1 from "../auth.service";
import * as i2 from "@angular/router";
var AlreadyLoggedGuard = /** @class */ (function () {
    function AlreadyLoggedGuard(authService, router) {
        this.authService = authService;
        this.router = router;
    }
    AlreadyLoggedGuard.prototype.canActivate = function (next, state) {
        var loggedUser = this.authService.user;
        if (loggedUser['xsrfToken'] && loggedUser['xsrfToken'].length > 0) {
            this.router.navigate(['/'], { replaceUrl: true });
        }
        else {
            return true;
        }
    };
    AlreadyLoggedGuard.ngInjectableDef = i0.defineInjectable({ factory: function AlreadyLoggedGuard_Factory() { return new AlreadyLoggedGuard(i0.inject(i1.AuthService), i0.inject(i2.Router)); }, token: AlreadyLoggedGuard, providedIn: "root" });
    return AlreadyLoggedGuard;
}());
export { AlreadyLoggedGuard };
