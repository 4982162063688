<div class="recycling">
  <div class="header p-16 p-sm-24"
       fxLayout.gt-xs="row"
       fxLayoutAlign.gt-xs="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="logo" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="logo-icon mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
          repeat
        </mat-icon>
        <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
          {{'RECYCLING.TITLE_LIST' | translate}}
        </span>
      </div>
    </div>
    <div class="search-wrapper">
      <div class="search">
        <label for="name" class="mr-8">
          <mat-icon (click)="clearSearch()">{{searchRecycling.value ? 'clear' : 'search'}}</mat-icon>
        </label>
        <input id="name" [formControl]="searchRecycling"
               [placeholder]="'RECYCLING.SEARCH_PLACEHOLDER' | translate">
      </div>
    </div>
  </div>

  <div class="recycling-content">
    <app-list-filters class="filters-container"
                      [filters]="['isPublic', 'active']"
                      (listFilters)="getListFilters($event)">
    </app-list-filters>
    <div class="recycling-table">
      <mat-table matSort
                 [dataSource]="dataSource"
                 [matSortActive]="requestParams.sort"
                 [matSortDirection]="requestParams.order"
                 [@animateStaggerRemondis]="dataSource.length"
                 [@.disabled]="disableAnimations">

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'RECYCLING.NAME' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let recycling" class="text-ellipsis">{{recycling.name}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="weightPerPiece">
          <mat-header-cell *matHeaderCellDef>{{'RECYCLING.WEIGHT_PER_PIECE' | translate}} [kg]</mat-header-cell>
          <mat-cell *matCellDef="let recycling" class="text-ellipsis">{{recycling.weightPerPiece}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="weightPerCubicMeter">
          <mat-header-cell *matHeaderCellDef>{{'RECYCLING.WEIGHT_PER_CUBIC_METER' | translate}}<sup>3</sup> [kg]</mat-header-cell>
          <mat-cell *matCellDef="let recycling" class="text-ellipsis">{{recycling.weightPerCubicMeter}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="code">
          <mat-header-cell *matHeaderCellDef>{{'RECYCLING.CODE' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let recycling" class="text-ellipsis">{{recycling.code}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef>{{'RECYCLING.DESC' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let recycling" class="text-ellipsis">{{recycling.description}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="isPublic">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'RECYCLING.VISIBLE_BY_CLIENT' | translate}}
          </mat-header-cell>
          <mat-cell *matCellDef="let recycling">
            <mat-icon [ngClass]="recycling.isPublic && 'green-icon'">
              {{recycling.isPublic ? 'check' : 'close'}}
            </mat-icon>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="active">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'RECYCLING.STATUSES.ACTIVE' | translate}}
          </mat-header-cell>
          <mat-cell *matCellDef="let recycling">
            <mat-icon [ngClass]="recycling.active && 'green-icon'">
              {{recycling.active ? 'check' : 'close'}}
            </mat-icon>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          class="recycling-row"
          *matRowDef="let recycling; columns: displayedColumns;"
          [@animateRemondis]="{value:'*',params:{y:'100%'}}"
          [id]="recycling.uuid"
          (click)="viewRecyclingItem(recycling.uuid)"
        >
        </mat-row>
      </mat-table>

      <h4 class="no-data" *ngIf="totalCount === 0">
        {{'SHARED.NO_DATA' | translate}}
      </h4>

      <div *ngIf="totalCount" class="paginator-container">
        <mat-paginator
          (page)="pageChange($event)"
          [pageSize]="requestParams.limit"
          [pageIndex]="(requestParams.page - 1)"
          [length]="totalCount"
          class="paginator-holder"
        ></mat-paginator>
      </div>
    </div>
  </div>
  <button mat-fab class="add-recycling-button"
          [attr.data-cy]="'recyclingAdd'"
          [@animate]="{value:'*', params:{delay:'300ms',scale:'.2'}}"
          [matTooltip]="'RECYCLING.TITLE_ADD' | translate"
          matTooltipPosition="above"
          (click)="addRecyclingItem()">
    <mat-icon>add</mat-icon>
  </button>
</div>