export const Months = [
  {
    name: 'REPORTS.MONTHS.1',
    value: 1
  },
  {
    name: 'REPORTS.MONTHS.2',
    value: 2
  },
  {
    name: 'REPORTS.MONTHS.3',
    value: 3
  },
  {
    name: 'REPORTS.MONTHS.4',
    value: 4
  },
  {
    name: 'REPORTS.MONTHS.5',
    value: 5
  },
  {
    name: 'REPORTS.MONTHS.6',
    value: 6
  },
  {
    name: 'REPORTS.MONTHS.7',
    value: 7
  },
  {
    name: 'REPORTS.MONTHS.8',
    value: 8
  },
  {
    name: 'REPORTS.MONTHS.9',
    value: 9
  },
  {
    name: 'REPORTS.MONTHS.10',
    value: 10
  },
  {
    name: 'REPORTS.MONTHS.11',
    value: 11
  },
  {
    name: 'REPORTS.MONTHS.12',
    value: 12
  }
];