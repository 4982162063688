import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar } from '@angular/material';
import { FuseTranslationLoaderService } from '../../../../../../@fuse/services/translation-loader.service';
import { locale as polish } from '../../../../../translations/pl';
import { locale as english } from '../../../../../translations/en';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { OrdersService } from '../../../../../services/orders.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-order-link-form',
  templateUrl: './order-link-form.component.html',
  styleUrls: ['./order-link-form.component.scss']
})
export class OrderLinkFormComponent implements OnInit {
  public sendLinkForm: FormGroup;

  constructor(public matDialogRef: MatDialogRef<OrderLinkFormComponent>,
              private ordersService: OrdersService,
              private formBuilder: FormBuilder,
              private translateService: TranslateService,
              private snackBar: MatSnackBar,
              private _fuseTranslationLoaderService: FuseTranslationLoaderService) {
    this._fuseTranslationLoaderService.loadTranslations(polish, english);
  }

  ngOnInit() {
    this.sendLinkForm = this.formBuilder.group({
      email: new FormControl('', [Validators.required, Validators.email]),
    });
  }

  public submit() {
    this.ordersService.sendOrderLink(this.sendLinkForm.get('email').value).subscribe(() => {
      this.matDialogRef.close();
      this.snackBar.open(this.translateService.instant('ORDERS.MESSAGES.SEND_LINK_SUCCESS'), '', {duration: 5000});
    }, () => {
      this.snackBar.open(this.translateService.instant('ORDERS.MESSAGES.SEND_LINK_ERROR'), '', {duration: 5000});
    })
  }

}
