import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Tag, TagsListRespone } from '../shared/models/tags.model';
import { RequestParams } from '../shared/models/list.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class TagsService {
  onEditEvent: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient, private authService: AuthService) {
  }

  getTagsList(params: RequestParams): Observable<TagsListRespone> {
    return this.http.get(`${environment.apiUrl}/${this.authService.getUserType()}/tags`, {params: params})
      .pipe(map((res: TagsListRespone) => res));
  }

  getTag(tagTypeUuid: Tag['uuid']): Observable<Tag> {
    return this.http.get(`${environment.apiUrl}/${this.authService.getUserType()}/tags/${tagTypeUuid}`).pipe(
      map((res: any) => res.tag)
    );
  }

  createTag(tag: Tag): Observable<Tag> {
    return this.http.post<any>(`${environment.apiUrl}/${this.authService.getUserType()}/tags`, tag).pipe(
      map((res: any) => res.tag)
    );
  }

  updateTag(tagId: Tag['uuid'], tag: Tag): Observable<Tag> {
    return this.http.put<any>(`${environment.apiUrl}/${this.authService.getUserType()}/tags/${tagId}`, tag);
  }

  deleteTag(tagId: Tag['uuid']) {
    return this.http.delete<any>(`${environment.apiUrl}/${this.authService.getUserType()}/tags/${tagId}`);
  }
}