<div class="input-wrapper-order-export">
  <mat-form-field class="additional-field-no-padding" appearance="outline" fxFlex>
    <mat-label>{{'CALENDAR.DESCRIPTION' | translate}}</mat-label>
    <div *ngIf="user.userType === userTypes.Manager || user.userType === userTypes.Logistician" class="btn-additional-field-container">
      <button *ngIf="!isEditMode" class="btn-additional-field" (click)="goToEditMode()" [attr.data-cy]="'editNote'">
        <mat-icon class="icon-display">edit</mat-icon>
      </button>
      <button *ngIf="isEditMode" class="btn-additional-field" (click)="openDialog()" [attr.data-cy]="'saveNote'">
        <mat-icon *ngIf="!isLoading" class="icon-display success">check</mat-icon>
        <span *ngIf="isLoading" class="loading-spinner-wrapper">
        <mat-progress-spinner mode="indeterminate"
                              [strokeWidth]="2"
                              [diameter]="20">
        </mat-progress-spinner>
      </span>
      </button>
      <button *ngIf="isEditMode" class="btn-additional-field" (click)="goToViewMode()" [attr.data-cy]="'cancel'">
        <mat-icon class="icon-display">cancel</mat-icon>
      </button>
    </div>
    <textarea rows="5" class="field-font-size" matInput [formControl]="descriptionFormControl"></textarea>
  </mat-form-field>
</div>