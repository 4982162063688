import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { Company } from "../shared/models/companies.model";
import {
  CompanyKpo,
  CompanyKpoListResponse,
} from "../shared/models/company-kpo.model";
import { RequestParams } from "../shared/models/list.model";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class CompanyKpoService {
  onEditEvent: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient, private authService: AuthService) {}

  getCompanyKpoList(
    params: RequestParams,
    companyUuid: Company["uuid"]
  ): Observable<CompanyKpoListResponse> {
    return this.http
      .get(
        `${
          environment.apiUrl
        }/${this.authService.getUserType()}/customersCompanies/${companyUuid}/kpo`,
        { params: params }
      )
      .pipe(map((res: CompanyKpoListResponse) => res));
  }

  getCompanyKpo(
    companyUuid: Company["uuid"],
    kpoUuid: CompanyKpo["uuid"]
  ): Observable<CompanyKpo> {
    return this.http
      .get(
        `${
          environment.apiUrl
        }/${this.authService.getUserType()}/customersCompanies/${companyUuid}/kpo/${kpoUuid}`
      )
      .pipe(map((res: any) => res.kpo));
  }

  createCompanyKpo(
    companyUuid: Company["uuid"],
    kpo: CompanyKpo
  ): Observable<CompanyKpo> {
    return this.http
      .post<any>(
        `${
          environment.apiUrl
        }/${this.authService.getUserType()}/customersCompanies/${companyUuid}/kpo`,
        kpo
      )
      .pipe(map((res: any) => res.kpo));
  }

  updateCompanyKpo(
    companyUuid: Company["uuid"],
    kpoUuid: CompanyKpo["uuid"],
    kpo: CompanyKpo
  ): Observable<CompanyKpo> {
    return this.http.post<any>(
      `${
        environment.apiUrl
      }/${this.authService.getUserType()}/customersCompanies/${companyUuid}/kpo/${kpoUuid}`,
      kpo
    );
  }

  attachCompanyKpo(companyUuid: Company["uuid"], kpoUuid: CompanyKpo["uuid"]) {
    return this.http.post<any>(
      `${
        environment.apiUrl
      }/${this.authService.getUserType()}/customersCompanies/${companyUuid}/attachKpo`,
      { kpoUuid: kpoUuid }
    );
  }

  detachCompanyKpo(companyUuid: Company["uuid"], kpoUuid: CompanyKpo["uuid"]) {
    return this.http.delete<any>(
      `${
        environment.apiUrl
      }/${this.authService.getUserType()}/customersCompanies/${companyUuid}/kpo/${kpoUuid}`
    );
  }
}
