import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { RecyclingItemsEditorComponent } from '../recycling-items-editor/recycling-items-editor.component';
import { RecyclingService } from '../../../../services/recycling.service';
import { RecyclingItem, RecyclingItemsListRespone } from '../../../../shared/models/recycling.model';
import { locale as polish } from '../../../../translations/pl';
import { locale as english } from '../../../../translations/en';
import { fuseAnimations } from '../../../../../@fuse/animations';
import { remondisListAnimation } from '../../../../shared/consts/remondis.lists.animation';
import { List } from '../../../../shared/models/list.class';
import { FormControl } from '@angular/forms';
import { ListFilters } from '../../../../shared/models/list.model';

@Component({
  selector: 'app-recycling-items-list',
  templateUrl: './recycling-items-list.component.html',
  styleUrls: ['./recycling-items-list.component.scss'],
  animations: [...fuseAnimations, ...remondisListAnimation]
})
export class RecyclingItemsListComponent extends List implements OnInit, OnDestroy {
  public displayedColumns = ['name', 'weightPerPiece', 'weightPerCubicMeter', 'code', 'description', 'isPublic', 'active'];
  public searchRecycling: FormControl;
  private listFilters: ListFilters = {};
  private reloadList$;

  constructor(private recyclingService: RecyclingService,
              private matDialog: MatDialog,
              private _fuseTranslationLoaderService: FuseTranslationLoaderService) {
    super();

    this._fuseTranslationLoaderService.loadTranslations(polish, english);

    this.reloadList$ = recyclingService.onEditEvent.subscribe(
      () => {
        this.getElementsList();
      });

    this.getElementsList = () => {
      this.recyclingService.getRecyclingItemsList(this.getQueryParams()).subscribe((res: RecyclingItemsListRespone) => {
        this.totalCount = res.totalCount;
        this.dataSource = res.recyclingItems;
      });
    };
  }

  ngOnInit() {
    super.ngOnInit();
    this.createSearchFormControl();
  }

  ngOnDestroy() {
    this.reloadList$.unsubscribe();
  }

  public addRecyclingItem() {
    this.disableAnimations = true;

    let editorDialogRef = this.matDialog.open(RecyclingItemsEditorComponent, {
      panelClass: 'recycling-editor-dialog',
      autoFocus: false,
      disableClose: true,
      data: {
        mode: 'add'
      }
    });
  }

  public viewRecyclingItem(recyclingItemUuid: RecyclingItem['uuid']) {
    this.disableAnimations = true;

    let editorDialogRef = this.matDialog.open(RecyclingItemsEditorComponent, {
      panelClass: 'recycling-editor-dialog',
      autoFocus: false,
      disableClose: true,
      data: {
        mode: 'view',
        recyclingItemUuid: recyclingItemUuid
      }
    });
  }

  public clearSearch() {
    if (this.searchRecycling.value) {
      this.searchRecycling.setValue('');
    }
  }

  public getListFilters(filters: ListFilters) {
    this.listFilters = filters;
    this.requestParams.page = 1;
    this.disableAnimations = true;
    this.getElementsList();
  }


  private createSearchFormControl() {
    this.searchRecycling = new FormControl('');
    this.searchRecycling.valueChanges.debounceTime(300).subscribe((value) => {
      this.requestParams.search = value;
      this.requestParams.page = 1;
      this.disableAnimations = true;
      this.getElementsList();
    });
  }

  private getQueryParams() {
    return Object.assign({}, this.requestParams, this.listFilters);
  }

}
