var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as polish } from '../../../../translations/pl';
import { locale as english } from '../../../../translations/en';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Subject, zip } from 'rxjs';
import { TasksService } from '../../../../services/tasks.service';
import { NoteCalendarService } from '../../../../services/note-calendar.service';
import { CalendarWeekViewComponent } from 'angular-calendar';
import { isSameDay, isSameMonth, startOfDay } from 'date-fns';
import { CalendarTaskModel } from '../../calendar.model';
import { registerLocaleData } from '@angular/common';
import localePl from '@angular/common/locales/pl';
import * as moment from 'moment';
import { UsersService } from '../../../../services/users.service';
import { TranslateService } from '@ngx-translate/core';
import { CompaniesService } from '../../../../services/companies.service';
import { List } from '../../../../shared/models/list.class';
import { FormControl } from '@angular/forms';
import { TaskTypePickerComponent } from '../../../tasks/components/task-type-picker/task-type-picker.component';
import { RoleTypes, UserTypes } from '../../../../shared/models/users.model';
import { AuthService } from '../../../../services/auth.service';
import { TaskExportFormComponent } from '../../../tasks/components/task-forms/task-export-form/task-export-form.component';
import { TaskImportFormComponent } from '../../../tasks/components/task-forms/task-import-form/task-import-form.component';
import { TaskReceivingFormComponent } from '../../../tasks/components/task-forms/task-receiving-form/task-receiving-form.component';
import { ConfirmModalComponent } from '../../../shared/components/confirm-modal/confirm-modal.component';
import { TaskStatuses } from '../../../../shared/models/tasks.model';
import { TaskWarningsService } from '../../../../services/task-warnings.service';
import { FuseSidebarService } from '../../../../../@fuse/components/sidebar/sidebar.service';
var CalendarComponent = /** @class */ (function (_super) {
    __extends(CalendarComponent, _super);
    function CalendarComponent(tasksService, noteCalendarService, usersService, authService, companiesService, translateService, taskWarningService, snackBar, matDialog, _fuseSidebarService, _fuseTranslationLoaderService) {
        var _this = _super.call(this) || this;
        _this.tasksService = tasksService;
        _this.noteCalendarService = noteCalendarService;
        _this.usersService = usersService;
        _this.authService = authService;
        _this.companiesService = companiesService;
        _this.translateService = translateService;
        _this.taskWarningService = taskWarningService;
        _this.snackBar = snackBar;
        _this.matDialog = matDialog;
        _this._fuseSidebarService = _fuseSidebarService;
        _this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        _this.tasks = [];
        _this.mainCalendarTasks = [];
        _this.additionalCalendarTasks = [];
        _this.dailySummary = {};
        _this.cumulativelySummary = {};
        _this.totalResponse = {};
        _this.refresh = new Subject();
        _this.locale = 'pl';
        _this.companyBranches = [];
        _this.userTypes = UserTypes;
        _this.roleTypes = RoleTypes;
        _this.taskStatuses = TaskStatuses;
        _this.taskStatusesList = [];
        _this.calendarSplitView = false;
        _this.mainCalendarView = {
            export: true,
            import: true,
            receiving: true,
            cancelled: true
        };
        _this.additionalCalendarView = {
            export: false,
            import: false,
            receiving: false,
            cancelled: false
        };
        _this.tasksTabsActive = {
            export: true,
            import: true,
            receiving: true,
            cancelled: false
        };
        _this.taskWarnings = [];
        _this.getCalendarSummary = function () {
            var date = moment(_this.getRequestParams().dateFrom).startOf('month').format('YYYY-MM-DD');
            var endWeekDate = moment(_this.getRequestParams().dateTo).startOf('month').format('YYYY-MM-DD');
            if (moment(date).month() !== moment(endWeekDate).month()) {
                zip(_this.tasksService.getTaskCalendarSummary(date, _this.selectedCompanyBranchId), _this.tasksService.getTaskCalendarSummary(endWeekDate, _this.selectedCompanyBranchId)).subscribe(function (_a) {
                    var res = _a[0], resNextMonth = _a[1];
                    var _b, _c;
                    _this.dailySummary = __assign({}, res.response, resNextMonth.response);
                    _this.cumulativelySummary = __assign({}, res.cumulativelyResponse, resNextMonth.cumulativelyResponse);
                    _this.totalResponse = {
                        income: (_b = {},
                            _b[moment(date).month()] = res.totalIncome,
                            _b[moment(endWeekDate).month()] = resNextMonth.totalIncome,
                            _b),
                        outcome: (_c = {},
                            _c[moment(date).month()] = res.totalOutcome,
                            _c[moment(endWeekDate).month()] = resNextMonth.totalOutcome,
                            _c)
                    };
                });
            }
            else {
                _this.tasksService.getTaskCalendarSummary(date, _this.selectedCompanyBranchId).subscribe(function (res) {
                    var _a, _b;
                    _this.dailySummary = res.response;
                    _this.cumulativelySummary = res.cumulativelyResponse;
                    _this.totalResponse = {
                        income: (_a = {},
                            _a[moment(date).month()] = res.totalIncome,
                            _a),
                        outcome: (_b = {},
                            _b[moment(date).month()] = res.totalOutcome,
                            _b)
                    };
                });
            }
        };
        _this.user = _this.authService.user.user;
        _this._fuseTranslationLoaderService.loadTranslations(polish, english);
        registerLocaleData(localePl);
        // Set the defaults
        _this.view = 'week';
        _this.viewName = 'workweek';
        _this.viewDate = new Date();
        _this.activeDayIsOpen = true;
        _this.selectedDay = { date: startOfDay(new Date()) };
        _this.selectedCompanyBranchId = _this.user.companyBranchId;
        _this.selectedCompanyBranchName = _this.user.companyBranch;
        _this.getTasksList();
        return _this;
    }
    CalendarComponent.prototype.ngOnInit = function () {
        var _this = this;
        var _a;
        this.createSearchFormControl();
        this._fuseSidebarService.getSidebar('navbar').foldedChanged.subscribe(function () {
            _this.folded = _this._fuseSidebarService.getSidebar('navbar').folded;
        });
        this.tasksService.onEditEvent.subscribe(function () {
            _this.getTasksList();
        });
        this.usersService.getCompanyBranchList().subscribe(function (res) {
            _this.companyBranches = res.branches;
            if (!_this.selectedCompanyBranchId && _this.companyBranches.length > 0) {
                _this.selectedCompanyBranchId = _this.companyBranches[0].uuid;
                _this.getTasksList();
            }
            _this.selectedCompanyBranchName = _this.companyBranches.find(function (item) { return item.uuid === _this.selectedCompanyBranchId; }).name;
        });
        var filterParams = (_a = {
                page: 1,
                limit: 1000
            },
            _a['userType'] = UserTypes.Sales,
            _a);
        this.usersService.getUsersList(filterParams).subscribe(function (response) {
            _this.salesList = response.users;
            var filter_all_sales = _this.translateService.instant('CALENDAR.ALL_SELS_FITER');
            _this.salesList.unshift({ uuid: '0', name: filter_all_sales, surname: '' });
        });
        this.taskStatusesList.push({ display: 'ALL', value: 'all' });
        Object.keys(this.taskStatuses).forEach(function (key) {
            _this.taskStatusesList.push({
                display: key.toUpperCase(),
                value: _this.taskStatuses[key]
            });
        });
    };
    CalendarComponent.prototype.dayClicked = function (day) {
        var date = day.date;
        var tasks = day.events;
        if (isSameMonth(date, this.viewDate)) {
            if ((isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
                tasks.length === 0) {
                this.activeDayIsOpen = false;
            }
            else {
                this.activeDayIsOpen = true;
                this.viewDate = date;
            }
        }
        this.selectedDay = day;
        this.refresh.next();
    };
    CalendarComponent.prototype.toggleDayBlocked = function (day) {
        if ((this.user.userType === this.userTypes.Logistician &&
            this.user.roleType === this.roleTypes.Admin) || this.user.userType === this.userTypes.Manager) {
            var index = this.taskWarnings.findIndex(function (warning) {
                return warning.startDate.getUTCDate() >= day.getDate() &&
                    warning.endDate.getUTCDate() <= day.getDate();
            });
            if (index > -1) {
                this.deleteTaskWarning(this.taskWarnings[index].uuid);
            }
            else {
                this.createTaskWarning(day);
            }
        }
    };
    CalendarComponent.prototype.createTaskWarning = function (day) {
        var _this = this;
        var confirmDialogRef = this.matDialog.open(ConfirmModalComponent, {
            disableClose: false,
            autoFocus: false
        });
        confirmDialogRef.componentInstance.confirmMessage = this.translateService.instant('CALENDAR.BLOCK_DAY_MESSAGE');
        confirmDialogRef.componentInstance.titleMessage = this.translateService.instant('CALENDAR.BLOCK_DAY_TITLE');
        confirmDialogRef.componentInstance.confirmButton = this.translateService.instant('CALENDAR.BLOCK_DAY_BUTTON');
        var subscription = confirmDialogRef.afterClosed().subscribe(function (result) {
            subscription.unsubscribe();
            if (result) {
                var startDate = "" + moment(day).format('YYYY-MM-DD HH:mm:ss');
                var endDate = "" + moment(day)
                    .add(23, 'h')
                    .add(59, 'm')
                    .format('YYYY-MM-DD HH:mm:ss');
                _this.taskWarningService
                    .createTaskWarning({
                    companyBranchId: _this.selectedCompanyBranchId,
                    startDate: startDate,
                    endDate: endDate
                })
                    .subscribe(function () {
                    _this.snackBar.open(_this.translateService.instant('CALENDAR.BLOCK_DAY_SUCCESS'), '', { duration: 5000 });
                    _this.getWarningsList();
                }, function () {
                    _this.snackBar.open(_this.translateService.instant('CALENDAR.BLOCK_DAY_ERROR'), '', { duration: 5000 });
                });
            }
        });
    };
    CalendarComponent.prototype.deleteTaskWarning = function (taskWarningId) {
        var _this = this;
        var confirmDialogRef = this.matDialog.open(ConfirmModalComponent, {
            disableClose: false,
            autoFocus: false
        });
        confirmDialogRef.componentInstance.confirmMessage = this.translateService.instant('CALENDAR.UNBLOCK_DAY_MESSAGE');
        confirmDialogRef.componentInstance.titleMessage = this.translateService.instant('CALENDAR.UNBLOCK_DAY_TITLE');
        confirmDialogRef.componentInstance.confirmButton = this.translateService.instant('CALENDAR.UNBLOCK_DAY_BUTTON');
        var subscription = confirmDialogRef.afterClosed().subscribe(function (result) {
            subscription.unsubscribe();
            if (result) {
                _this.taskWarningService.deleteTaskWarning(taskWarningId).subscribe(function () {
                    _this.snackBar.open(_this.translateService.instant('CALENDAR.UNBLOCK_DAY_SUCCESS'), '', { duration: 5000 });
                    _this.getWarningsList();
                }, function () {
                    _this.snackBar.open(_this.translateService.instant('CALENDAR.UNBLOCK_DAY_ERROR'), '', { duration: 5000 });
                });
            }
        });
    };
    CalendarComponent.prototype.beforeMonthViewRender = function (renderEvent) {
        var _this = this;
        renderEvent.body.forEach(function (day) {
            var dayOfMonth = day.date.getDate();
            _this.taskWarnings.forEach(function (warning) {
                if (dayOfMonth >= warning.startDate.getUTCDate() &&
                    dayOfMonth <= warning.endDate.getUTCDate() &&
                    day.inMonth) {
                    day.cssClass = 'blocked-day';
                }
            });
        });
    };
    CalendarComponent.prototype.beforeWeekViewRender = function (renderEvent) {
        var _this = this;
        renderEvent.hourColumns.forEach(function (hourColumn) {
            hourColumn.hours.forEach(function (hour) {
                hour.segments.forEach(function (segment) {
                    _this.taskWarnings.forEach(function (warning) {
                        if (segment.date.getUTCDate() >= warning.startDate.getUTCDate() &&
                            segment.date.getUTCDate() <= warning.endDate.getUTCDate()) {
                            segment.cssClass = 'blocked-day';
                        }
                    });
                });
            });
        });
    };
    CalendarComponent.prototype.beforeDayViewRender = function (renderEvent) {
        var _this = this;
        renderEvent.body.hourGrid.forEach(function (hour) {
            hour.segments.forEach(function (segment, index) {
                _this.taskWarnings.forEach(function (warning) {
                    if (segment.date.getUTCDate() >= warning.startDate.getUTCDate() &&
                        segment.date.getUTCDate() <= warning.endDate.getUTCDate()) {
                        segment.cssClass = 'blocked-day';
                    }
                });
            });
        });
    };
    CalendarComponent.prototype.addTask = function (date) {
        if (this.user.userType === this.userTypes.Logistician &&
            this.selectedCompanyBranchId) {
            var start = date || null;
            var end = date
                ? new Date(new Date(start).setHours(start.getHours() + 1))
                : '';
            var startDate = start ? moment(start).format('YYYY-MM-DD') : null;
            var startTime = start ? moment(start).format('HH:mm') : null;
            var endDate = end ? moment(end).format('YYYY-MM-DD') : null;
            var endTime = end ? moment(end).format('HH:mm') : null;
            if (startTime === '16:30') {
                endTime = '17:00';
            }
            this.matDialog.open(TaskTypePickerComponent, {
                panelClass: 'task-type-picker',
                autoFocus: false,
                disableClose: true,
                data: {
                    mode: 'add',
                    startDate: startDate,
                    endDate: endDate,
                    startTime: startTime,
                    endTime: endTime,
                    companyBranchId: this.selectedCompanyBranchId
                }
            });
        }
    };
    CalendarComponent.prototype.editTask = function (task) {
        var _this = this;
        var editTaskDialogRef;
        if (this.user.userType === this.userTypes.Logistician || this.user.userType === this.userTypes.Sales || this.user.userType === this.userTypes.Manager) {
            if (task.type === 'export') {
                editTaskDialogRef = this.matDialog.open(TaskExportFormComponent, {
                    panelClass: 'task-editor-dialog',
                    autoFocus: false,
                    disableClose: true,
                    data: {
                        mode: 'view',
                        taskId: task.uuid
                    }
                });
            }
            else if (task.type === 'import') {
                editTaskDialogRef = this.matDialog.open(TaskImportFormComponent, {
                    panelClass: 'task-editor-dialog',
                    autoFocus: false,
                    disableClose: true,
                    data: {
                        mode: 'view',
                        taskId: task.uuid
                    }
                });
            }
            else if (task.type === 'receiving') {
                editTaskDialogRef = this.matDialog.open(TaskReceivingFormComponent, {
                    panelClass: 'task-editor-dialog',
                    autoFocus: false,
                    disableClose: true,
                    data: {
                        mode: 'view',
                        taskId: task.uuid
                    }
                });
            }
            var subscription_1 = editTaskDialogRef.afterClosed().subscribe(function () {
                // workaround for displaying tooltips after closing edit task modal
                if (_this.calendarComponent) {
                    _this.calendarComponent.dragActive = false;
                }
                if (_this.additionalCalendarComponent) {
                    _this.additionalCalendarComponent.dragActive = false;
                }
                subscription_1.unsubscribe();
            });
        }
    };
    CalendarComponent.prototype.updateOnDragOrResize = function (task) {
        var _this = this;
        var isSameDay = moment(task.newStart).isSame(task.newEnd, 'day');
        var isSameStartAndEnd = moment(task.newStart).isSame(task.event.start, 'minute') &&
            moment(task.newEnd).isSame(task.event.end, 'minute');
        if (this.user.userType === this.userTypes.Logistician &&
            isSameDay &&
            !isSameStartAndEnd) {
            var confirmDialogRef = this.matDialog.open(ConfirmModalComponent, {
                disableClose: false,
                autoFocus: false
            });
            confirmDialogRef.componentInstance.confirmMessage = this.translateService.instant('TASKS.MESSAGES.UPDATE_QUESTION');
            confirmDialogRef.componentInstance.titleMessage = this.translateService.instant('TASKS.MESSAGES.UPDATE_TASK_TITLE');
            confirmDialogRef.componentInstance.confirmButton = this.translateService.instant('TASKS.UPDATE');
            var subscription_2 = confirmDialogRef.afterClosed().subscribe(function (result) {
                if (result) {
                    var startDate = task.newStart
                        ? "" + moment.utc(task.newStart).format('YYYY-MM-DD HH:mm:ss')
                        : null;
                    var endDate = task.newEnd
                        ? "" + moment.utc(task.newEnd).format('YYYY-MM-DD HH:mm:ss')
                        : null;
                    subscription_2.unsubscribe();
                    _this.tasksService
                        .updateTaskDate(task.event.uuid, startDate, endDate)
                        .subscribe(function () {
                        _this.tasksService.onEditEvent.emit();
                        _this.snackBar.open(_this.translateService.instant('TASKS.MESSAGES.UPDATE_SUCCESS'), '', { duration: 5000 });
                    }, function () {
                        _this.snackBar.open(_this.translateService.instant('TASKS.MESSAGES.UPDATE_ERROR'), '', { duration: 5000 });
                    });
                }
            });
        }
        this.refresh.next();
    };
    CalendarComponent.prototype.changeCompanyBranch = function (companyId) {
        this.selectedCompanyBranchId = companyId;
        this.selectedCompanyBranchName = this.companyBranches.find(function (branch) { return branch.uuid === companyId; }).name;
        this.getTasksList();
    };
    CalendarComponent.prototype.excludeDays = function () {
        if (this.viewName === 'workweek') {
            return [0, 6];
        }
        return [];
    };
    CalendarComponent.prototype.clearSearch = function () {
        if (this.searchTask.value) {
            this.searchTask.setValue('');
        }
    };
    CalendarComponent.prototype.changeCalendarView = function (view, viewName) {
        this.view = view;
        this.viewName = viewName;
        for (var key in this.additionalCalendarView) {
            this.additionalCalendarView[key] = false;
        }
        for (var key in this.mainCalendarView) {
            this.mainCalendarView[key] = true;
        }
        for (var key in this.tasksTabsActive) {
            this.tasksTabsActive[key] = true;
        }
        this.calendarSplitView = false;
        this.getTasksList();
    };
    CalendarComponent.prototype.changeDate = function (event) {
        this.selectedDay.date = event;
        this.getTasksList();
    };
    CalendarComponent.prototype.splitCalendarView = function (type) {
        if (this.selectedCompanyBranchId) {
            for (var key in this.additionalCalendarView) {
                if (this.additionalCalendarView[key]) {
                    this.additionalCalendarView[key] = false;
                    this.mainCalendarView[key] = true;
                    this.tasksTabsActive[key] = true;
                }
            }
            this.mainCalendarView[type] = false;
            this.additionalCalendarView[type] = true;
            this.calendarSplitView = true;
            this.setCalendarsTasks();
        }
    };
    CalendarComponent.prototype.mergeCalendarView = function (type) {
        this.mainCalendarView[type] = true;
        this.additionalCalendarView[type] = false;
        this.tasksTabsActive[type] = true;
        this.calendarSplitView = false;
        this.setCalendarsTasks();
    };
    CalendarComponent.prototype.toggleActiveTaskTab = function (type) {
        if (this.selectedCompanyBranchId) {
            this.tasksTabsActive[type] = !this.tasksTabsActive[type];
            this.setCalendarsTasks();
        }
    };
    CalendarComponent.prototype.getDayTooltipMessage = function (events) {
        var exportNum = 0;
        var importNum = 0;
        var receivingNum = 0;
        events.length &&
            events.forEach(function (event) {
                if (event.type === 'export' && event.status !== 'cancelled') {
                    exportNum++;
                }
                else if (event.type === 'import' && event.status !== 'cancelled') {
                    importNum++;
                }
                else if (event.type === 'receiving' && event.status !== 'cancelled') {
                    receivingNum++;
                }
            });
        return this.translateService.instant('CALENDAR.TASK_TYPES.EXPORT') + ": " + exportNum + " \n    " + this.translateService.instant('CALENDAR.TASK_TYPES.IMPORT') + ": " + importNum + " \n    " + this.translateService.instant('CALENDAR.TASK_TYPES.RECEIVING') + ": " + receivingNum;
    };
    CalendarComponent.prototype.getDayTasksCount = function (events) {
        var dayTasksCount = 0;
        events.length &&
            events.forEach(function (event) {
                if (event.status !== 'cancelled') {
                    dayTasksCount++;
                }
            });
        return dayTasksCount;
    };
    CalendarComponent.prototype.getOrderTotalWeight = function (recyclingItems) {
        var totalWeight = 0;
        recyclingItems.forEach(function (item) {
            totalWeight += item.value;
        });
        return totalWeight;
    };
    CalendarComponent.prototype.getTasksList = function () {
        var _this = this;
        this.getCalendarSummary();
        var requestParams = this.getRequestParams();
        this.lastRequestParams = requestParams;
        this.selectedCompanyBranchId &&
            this.tasksService.getTasksList(requestParams).subscribe(function (res) {
                _this.tasks = res.tasks.map(function (item) {
                    return new CalendarTaskModel(_this.user, item);
                });
                _this.setCalendarsTasks();
            });
        this.getWarningsList();
        this.getNotesList();
    };
    CalendarComponent.prototype.getNotesList = function () {
        var _this = this;
        this.noteCalendarService.getNoteList(this.getRequestParams()).subscribe(function (res) {
            _this.noteList = res.calendarNotes.map(function (item) { return ({
                date: item.date,
                note: item.note
            }); });
            _this.refresh.next();
        });
    };
    CalendarComponent.prototype.getWarningsList = function () {
        var _this = this;
        this.taskWarningService
            .getTaskWarningList(this.getRequestParams())
            .subscribe(function (res) {
            _this.taskWarnings = res.taskWarning.map(function (warning) { return ({
                startDate: new Date(warning.startDate),
                endDate: new Date(warning.endDate),
                name: warning.name,
                uuid: warning.uuid
            }); });
            _this.refresh.next();
        });
    };
    CalendarComponent.prototype.setCalendarsTasks = function () {
        var _this = this;
        this.mainCalendarTasks = this.tasks.filter(function (task) {
            return task.status !== 'cancelled'
                ? _this.mainCalendarView[task.type] && _this.tasksTabsActive[task.type]
                : _this.mainCalendarView[task.status] &&
                    _this.tasksTabsActive[task.status];
        });
        this.additionalCalendarTasks = this.tasks.filter(function (task) {
            return task.status !== 'cancelled'
                ? _this.additionalCalendarView[task.type] &&
                    _this.tasksTabsActive[task.type]
                : _this.additionalCalendarView[task.status] &&
                    _this.tasksTabsActive[task.status];
        });
        this.refresh.next();
    };
    CalendarComponent.prototype.getRequestParams = function () {
        var filterParams = {
            page: 1,
            limit: 1000
        };
        if (this.searchTaskNameNumber) {
            filterParams.search = this.searchTaskNameNumber;
        }
        if (this.selectedCompanyBranchId) {
            filterParams.companyBranchId = this.selectedCompanyBranchId;
        }
        if (this.selectedSalesUserUuid) {
            filterParams['salesId'] = this.selectedSalesUserUuid;
        }
        if (this.selectedStatus) {
            filterParams['status'] = this.selectedStatus.join(',');
        }
        switch (this.viewName) {
            case 'day':
                filterParams.dateFrom = moment(this.selectedDay.date).format('YYYY-MM-DD');
                filterParams.dateTo = moment(this.selectedDay.date)
                    .add(1, 'd')
                    .format('YYYY-MM-DD');
                break;
            case 'week':
                filterParams.dateFrom = moment(this.selectedDay.date)
                    .startOf('isoWeek')
                    .add(0, 'd')
                    .format('YYYY-MM-DD');
                filterParams.dateTo = moment(this.selectedDay.date)
                    .endOf('isoWeek')
                    .add(4, 'day')
                    .format('YYYY-MM-DD');
                break;
            case 'month':
                filterParams.dateFrom = moment(this.selectedDay.date)
                    .startOf('month')
                    .format('YYYY-MM-DD');
                filterParams.dateTo = moment(this.selectedDay.date)
                    .endOf('month')
                    .add(1, 'd')
                    .format('YYYY-MM-DD');
                break;
            default:
                filterParams.dateFrom = moment(this.selectedDay.date)
                    .startOf('isoWeek')
                    .add(0, 'd')
                    .format('YYYY-MM-DD');
                filterParams.dateTo = moment(this.selectedDay.date)
                    .startOf('isoWeek')
                    .add(4, 'd')
                    .format('YYYY-MM-DD');
        }
        return filterParams;
    };
    CalendarComponent.prototype.createSearchFormControl = function () {
        var _this = this;
        this.searchTask = new FormControl('');
        this.searchTask.valueChanges.debounceTime(300).subscribe(function (value) {
            _this.searchTaskNameNumber = value;
            _this.disableAnimations = true;
            _this.getTasksList();
        });
    };
    CalendarComponent.prototype.getDescriptionForAdditionalFieldInGivenOneDay = function () {
        var givenDay = moment(this.selectedDay.date).format('YYYY-MM-DD');
        return this.noteList.find(function (note) { return moment(note.date).format('YYYY-MM-DD') === givenDay; });
    };
    CalendarComponent.prototype.getDescriptionForAdditionalFieldInGivenDayOfWeek = function (dayOfWeek) {
        var givenDay = moment(this.selectedDay.date)
            .startOf('week')
            .add(dayOfWeek + 1, 'd')
            .format('YYYY-MM-DD');
        return this.noteList.find(function (note) { return moment(note.date).format('YYYY-MM-DD') === givenDay; });
    };
    CalendarComponent.prototype.salesFilterChanged = function (event) {
        if (event.value === "0") {
            event.source.value = null;
            this.selectedSalesUserUuid = null;
        }
        else {
            this.selectedSalesUserUuid = event.value;
        }
        this.getTasksList();
    };
    CalendarComponent.prototype.statusFilterChanged = function (event) {
        var allItems = this.taskStatusesList.filter(function (item) { return item.value !== 'all'; }).map(function (item) { return item.value; });
        if (event.value.includes('all') && event.source.value.length === ['all'].concat(allItems).length) {
            event.source.value = null;
            this.selectedStatus = null;
        }
        else if (event.value.includes('all')) {
            event.source.value = allItems;
            this.selectedStatus = allItems;
        }
        else {
            this.selectedStatus = event.value;
        }
        this.getTasksList();
    };
    CalendarComponent.prototype.getSummaryObject = function (diffBetweenStartOfWeek, daily) {
        if (daily === void 0) { daily = false; }
        var baseDate = this.viewName !== 'day' ? moment(this.selectedDay.date).startOf('week').format('YYYY-MM-DD')
            : moment(this.selectedDay.date).format('YYYY-MM-DD');
        var date = moment(baseDate).add(diffBetweenStartOfWeek, 'days').format('YYYY-MM-DD');
        if (daily) {
            return this.dailySummary[date];
        }
        else {
            return this.cumulativelySummary[date];
        }
    };
    return CalendarComponent;
}(List));
export { CalendarComponent };
