import {
  Component,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
  MatOption,
  MatSnackBar,
} from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { zip } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { FuseTranslationLoaderService } from "../../../../../../@fuse/services/translation-loader.service";
import { AuthService } from "../../../../../services/auth.service";
import { CompanyBranchesService } from "../../../../../services/company-branches.service";
import { FilteredListsService } from "../../../../../services/filtered-lists.service";
import { OrdersService } from "../../../../../services/orders.service";
import { Mode } from "../../../../../shared/consts/mode";
import { OrderTypes } from "../../../../../shared/consts/orders";
import { CustomerCompanyBranch } from "../../../../../shared/models/company-branches.model";
import {
  FilteredCompany,
  FilteredFraction,
  FilteredTransport,
} from "../../../../../shared/models/filtered-items.model";
import { ItemsOrigins } from "../../../../../shared/models/history.model";
import { RequestParams } from "../../../../../shared/models/list.model";
import {
  OrderExport,
  OrderSatuses,
} from "../../../../../shared/models/orders.model";
import {
  RoleTypes,
  User,
  UserTypes,
} from "../../../../../shared/models/users.model";
import { markFormGroupTouched } from "../../../../../shared/utils/markFormGroupAsTouched";
import { ValidateGreaterThanZero } from "../../../../../shared/validators/greaterThanZero.validator";
import { locale as english } from "../../../../../translations/en";
import { locale as polish } from "../../../../../translations/pl";
import { CompanyEditorComponent } from "../../../../companies/components/company-editor/company-editor.component";
import { ConfirmModalComponent } from "../../../../shared/components/confirm-modal/confirm-modal.component";
import { HistoryDialogComponent } from "../../../../shared/components/history-dialog/history-dialog.component";
import { TaskExportFormComponent } from "../../../../tasks/components/task-forms/task-export-form/task-export-form.component";

@Component({
  selector: "app-order-export-form",
  templateUrl: "./order-export-form.component.html",
  styleUrls: ["./order-export-form.component.scss"],
})
export class OrderExportFormComponent implements OnInit {
  @ViewChild("companySelect") companySelect;
  public orderExportForm: FormGroup;
  public orderUuid: OrderExport["uuid"];
  public order: OrderExport;
  public orderStatuses = OrderSatuses;
  public user: User;
  public userTypes = UserTypes;
  public roleTypes = RoleTypes;
  public canUserEdit: boolean = false;
  public canCancelOrder: boolean = false;
  public canGenerateTask: boolean = false;
  public canViewTask: boolean = false;
  public fractionOptions: FilteredFraction[] = [];
  public transportOptions: FilteredTransport[] = [];
  public customerCompanyOptions: FilteredCompany[] = [];
  public customerCompanyBranches: CustomerCompanyBranch[] = [];
  public mode: Mode;
  public isLoading = false;
  public searchCustomerCompany: FormControl;
  public searchTransport: FormControl;
  public searchFractions: FormControl;
  public todayDate: Date;
  private filterParams: RequestParams = {
    page: 1,
    limit: 20,
    sort: "name",
    order: "asc",
  };
  private filterParamsFullList: RequestParams = {
    page: 1,
    limit: 1000,
    sort: "name",
    order: "asc",
    active: "true",
  };

  constructor(
    public orderEditorDialogRef: MatDialogRef<OrderExportFormComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private matDialog: MatDialog,
    private authService: AuthService,
    private ordersService: OrdersService,
    private filteredListsService: FilteredListsService,
    private translateService: TranslateService,
    private companyBranchesService: CompanyBranchesService,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService
  ) {
    this.mode = data.mode;
    this.orderUuid = data.orderUuid;
    this.user = this.authService.user.user;

    this._fuseTranslationLoaderService.loadTranslations(polish, english);
  }

  ngOnInit() {
    this.createForm();
    this.createSearchFilters();

    if (this.mode === "edit" || this.mode === "view" || this.mode === "clone") {
      this.ordersService
        .getOrder(this.orderUuid)
        .pipe(
          map((res) => {
            this.order = res;

            this.canCancelOrder =
              this.order &&
              this.order.status !== this.orderStatuses.Cancelled &&
              (this.user.roleType === this.roleTypes.Admin ||
                this.user.userType === this.userTypes.Logistician ||
                this.user.uuid === this.order.createdBy.uuid);

            this.canUserEdit =
              this.order &&
              !this.order.taskNumber &&
              (this.user.roleType === this.roleTypes.Admin ||
                this.user.userType === this.userTypes.Logistician ||
                this.user.uuid === this.order.createdBy.uuid);

            this.canGenerateTask =
              this.order &&
              this.order.status !== this.orderStatuses.Cancelled &&
              !this.order.taskNumber &&
              this.user.userType === this.userTypes.Logistician;

            this.canViewTask =
              this.order &&
              this.order.taskNumber &&
              this.user.userType === this.userTypes.Logistician;
          }),
          switchMap(() => {
            return zip(
              this.filteredListsService.getFractionsList(
                this.filterParamsFullList
              ),
              this.filteredListsService.getTransportList(
                this.filterParamsFullList
              ),
              this.filteredListsService.getCompaniesList(this.filterParams)
            );
          })
        )
        .subscribe(([fractionsRes, transportRes, companiesRes]) => {
          this.fractionOptions = fractionsRes.fractions;
          this.transportOptions = transportRes.transports;
          this.customerCompanyOptions = companiesRes.customerCompanies;

          this.createForm(this.order);
          this.checkCustomerCompaniesMissing();
          this.checkTransportMissing();
          this.checkFractionsMissing();
          this.getCustomerCompanyBranches(this.order.customerCompany.uuid);
        });
    } else {
      this.todayDate = new Date();

      this.filteredListsService
        .getFractionsList(this.filterParamsFullList)
        .subscribe((res) => (this.fractionOptions = res.fractions));
      this.filteredListsService
        .getTransportList(this.filterParamsFullList)
        .subscribe((res) => (this.transportOptions = res.transports));
      this.filteredListsService
        .getCompaniesList(this.filterParams)
        .subscribe(
          (res) => (this.customerCompanyOptions = res.customerCompanies)
        );
    }
  }

  public submitOrder() {
    this.isLoading = true;
    markFormGroupTouched(this.orderExportForm);
    if (this.orderExportForm.invalid) {
      this.isLoading = false;
      return;
    }
    let newOrder = JSON.parse(JSON.stringify(this.orderExportForm.value));
    if (newOrder.suggestedDate) {
      newOrder.suggestedDate = moment(newOrder.suggestedDate).format(
        "YYYY-MM-DD"
      );
    }
    newOrder.fractionsId =
      newOrder.fractions && newOrder.fractions.map((fraction) => fraction.uuid);
    newOrder.transportId = newOrder.transport ? newOrder.transport.uuid : null;
    newOrder.customerCompanyId = newOrder.customerCompany
      ? newOrder.customerCompany.uuid
      : null;

    const CCBranchId = newOrder.customerCompanyBranch;
    newOrder.customerCompanyBranch = {
      ...newOrder.customerCompanyBranchOther,
      uuid: CCBranchId !== "other" ? CCBranchId : null,
    };

    newOrder.customerCompanyBranch.email =
      newOrder.customerCompanyBranch.email || null;
    newOrder.price = Number(String(newOrder.price).replace(",", ".")) || null;

    delete newOrder.customerCompanyBranchOther;
    delete newOrder.fractions;
    delete newOrder.transport;
    delete newOrder.customerCompany;

    if (this.mode === "add" || this.mode === "clone") {
      this.ordersService.createOrder(newOrder).subscribe(
        () => {
          this.orderEditorDialogRef.close();
          this.orderEditorDialogRef.afterClosed().subscribe(() => {
            this.ordersService.onEditEvent.emit();
          });
          this.snackBar.open(
            this.translateService.instant("ORDERS.MESSAGES.CREATE_SUCCESS"),
            "",
            { duration: 5000 }
          );
        },
        () => {
          this.isLoading = false;
          this.snackBar.open(
            this.translateService.instant("ORDERS.MESSAGES.CREATE_ERROR"),
            "",
            { duration: 5000 }
          );
        }
      );
    } else if (this.mode === "view" && !this.canUserEdit) {
      this.ordersService
        .updateOrderDate(this.orderUuid, newOrder.suggestedDate)
        .subscribe(
          () => {
            this.orderEditorDialogRef.close();
            this.orderEditorDialogRef.afterClosed().subscribe(() => {
              this.ordersService.onEditEvent.emit();
            });
            this.snackBar.open(
              this.translateService.instant("ORDERS.MESSAGES.UPDATE_SUCCESS"),
              "",
              { duration: 5000 }
            );
          },
          () => {
            this.isLoading = false;
            this.snackBar.open(
              this.translateService.instant("ORDERS.MESSAGES.UPDATE_ERROR"),
              "",
              { duration: 5000 }
            );
          }
        );
    } else {
      this.ordersService.updateOrder(this.orderUuid, newOrder).subscribe(
        () => {
          this.orderEditorDialogRef.close();
          this.orderEditorDialogRef.afterClosed().subscribe(() => {
            this.ordersService.onEditEvent.emit();
          });
          this.snackBar.open(
            this.translateService.instant("ORDERS.MESSAGES.UPDATE_SUCCESS"),
            "",
            { duration: 5000 }
          );
        },
        () => {
          this.isLoading = false;
          this.snackBar.open(
            this.translateService.instant("ORDERS.MESSAGES.UPDATE_ERROR"),
            "",
            { duration: 5000 }
          );
        }
      );
    }
  }

  public deleteOrder() {
    let deleteDialogRef = this.matDialog.open(ConfirmModalComponent, {
      disableClose: false,
      autoFocus: false,
    });

    deleteDialogRef.componentInstance.confirmMessage =
      this.translateService.instant("ORDERS.MESSAGES.DELETE_QUESTION");
    deleteDialogRef.componentInstance.titleMessage =
      this.translateService.instant("ORDERS.MESSAGES.DELETE_ORDER_TITLE");
    deleteDialogRef.componentInstance.confirmButton =
      this.translateService.instant("ORDERS.DELETE");

    deleteDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.ordersService.deleteOrder(this.orderUuid).subscribe(
          () => {
            this.orderEditorDialogRef.close();
            this.orderEditorDialogRef.afterClosed().subscribe(() => {
              this.ordersService.onEditEvent.emit();
            });
            this.snackBar.open(
              this.translateService.instant("ORDERS.MESSAGES.DELETE_SUCCESS"),
              "",
              { duration: 5000 }
            );
          },
          () => {
            this.snackBar.open(
              this.translateService.instant("ORDERS.MESSAGES.DELETE_ERROR"),
              "",
              { duration: 5000 }
            );
          }
        );
      }
    });
  }

  public generateTask() {
    this.orderEditorDialogRef.close();
    this.matDialog.open(TaskExportFormComponent, {
      panelClass: "task-editor-dialog",
      autoFocus: false,
      disableClose: true,
      data: {
        mode: "add",
        orders: [this.order],
      },
    });
  }

  public viewTask() {
    this.orderEditorDialogRef.close();
    this.matDialog.open(TaskExportFormComponent, {
      panelClass: "task-editor-dialog",
      autoFocus: false,
      disableClose: true,
      data: {
        mode: "view",
        taskNumber: this.order.taskNumber,
      },
    });
  }

  public changeOrderStatus(status: string) {
    let changeStatusDialogRef = this.matDialog.open(ConfirmModalComponent, {
      disableClose: false,
      autoFocus: false,
    });

    if (status === this.orderStatuses.Planned) {
      changeStatusDialogRef.componentInstance.confirmMessage =
        this.translateService.instant("ORDERS.MESSAGES.PLAN_QUESTION");
      changeStatusDialogRef.componentInstance.titleMessage =
        this.translateService.instant("ORDERS.MESSAGES.PLAN_ORDER_TITLE");
      changeStatusDialogRef.componentInstance.confirmButton =
        this.translateService.instant("ORDERS.PLAN_ORDER");

      changeStatusDialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.ordersService
            .changeOrderStatus(this.orderUuid, this.orderStatuses.Planned)
            .subscribe(
              () => {
                this.snackBar.open(
                  this.translateService.instant("ORDERS.MESSAGES.PLAN_SUCCESS"),
                  "",
                  { duration: 5000 }
                );
                this.order.status = this.orderStatuses.Planned;
                this.ordersService.onEditEvent.emit();
              },
              () => {
                this.snackBar.open(
                  this.translateService.instant("ORDERS.MESSAGES.PLAN_ERROR"),
                  "",
                  { duration: 5000 }
                );
              }
            );
        }
      });
    } else {
      changeStatusDialogRef.componentInstance.confirmMessage =
        this.translateService.instant("ORDERS.MESSAGES.CANCEL_QUESTION");
      changeStatusDialogRef.componentInstance.titleMessage =
        this.translateService.instant("ORDERS.MESSAGES.CANCEL_ORDER_TITLE");
      changeStatusDialogRef.componentInstance.confirmButton =
        this.translateService.instant("ORDERS.CANCEL_ORDER");

      changeStatusDialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.ordersService
            .changeOrderStatus(this.orderUuid, this.orderStatuses.Cancelled)
            .subscribe(
              () => {
                this.snackBar.open(
                  this.translateService.instant(
                    "ORDERS.MESSAGES.CANCEL_SUCCESS"
                  ),
                  "",
                  { duration: 5000 }
                );
                this.order.status = this.orderStatuses.Cancelled;
                this.canCancelOrder = false;
                this.ordersService.onEditEvent.emit();
                this.orderEditorDialogRef.close();
              },
              () => {
                this.snackBar.open(
                  this.translateService.instant("ORDERS.MESSAGES.CANCEL_ERROR"),
                  "",
                  { duration: 5000 }
                );
              }
            );
        }
      });
    }
  }

  public compareObjectsByUuid(val1, val2): boolean {
    return val1 && val2 && val1.uuid && val2.uuid && val1.uuid === val2.uuid;
  }

  public getTitle(): string {
    switch (this.mode) {
      case "add":
      case "clone":
        return this.translateService.instant("ORDERS.TITLE_ADD");
      case "edit":
        return this.translateService.instant("ORDERS.TITLE_EDIT");
      case "view":
        return this.translateService.instant("ORDERS.TITLE_VIEW");
      default:
        return;
    }
  }

  public showHistory() {
    this.matDialog.open(HistoryDialogComponent, {
      panelClass: "history-dialog",
      autoFocus: false,
      disableClose: true,
      data: {
        item: this.order,
        itemOrigin: ItemsOrigins.Order,
        itemId: this.orderUuid,
        itemNumber: this.order.orderNumber,
      },
    });
  }

  public enableEditMode() {
    this.orderEditorDialogRef.close();
    this.matDialog.open(OrderExportFormComponent, {
      panelClass: "order-editor-dialog",
      autoFocus: false,
      disableClose: true,
      data: {
        mode: "edit",
        orderUuid: this.orderUuid,
      },
    });
  }

  public enableCloneMode() {
    this.orderEditorDialogRef.close();
    this.matDialog.open(OrderExportFormComponent, {
      panelClass: "order-editor-dialog",
      autoFocus: false,
      disableClose: true,
      data: {
        mode: "clone",
        orderUuid: this.orderUuid,
      },
    });
  }

  public async onChangeCustomerCompany(company: FilteredCompany) {
    if (company && company.uuid) {
      await this.getCustomerCompanyBranches(company.uuid);
    }
    const ccMainBranch = this.customerCompanyBranches[0];
    this.orderExportForm.get("customerCompanyBranch").enable();
    this.orderExportForm.patchValue({
      kpoEmails: company.kpo.map((kpo) => kpo.email).join(","),
      customerCompany: company,
      customerCompanyBranch: ccMainBranch ? ccMainBranch.uuid || "other" : null,
      customerCompanyBranchOther: {
        name: (ccMainBranch && ccMainBranch.name) || null,
        street: (ccMainBranch && ccMainBranch.street) || null,
        postalCode: (ccMainBranch && ccMainBranch.postalCode) || null,
        city: (ccMainBranch && ccMainBranch.city) || null,
        country: (ccMainBranch && ccMainBranch.country) || "Polska",
        contact: (ccMainBranch && ccMainBranch.contact) || null,
        email: (ccMainBranch && ccMainBranch.email) || null,
        phone: (ccMainBranch && ccMainBranch.phone) || null,
        note: (ccMainBranch && ccMainBranch.note) || null,
        mainBranch: false,
      },
    });
  }

  public openEmailClient() {
    const orderData = this.orderExportForm.getRawValue();
    if (orderData.customerCompanyBranchOther.email) {
      return `mailto:${orderData.customerCompanyBranchOther.email}`;
    }
  }

  public canSendEmail() {
    return (
      this.mode === "view" &&
      this.orderExportForm.getRawValue().customerCompanyBranchOther.email
    );
  }

  public fractionSelectOptionChange(option: MatOption) {
    const tmpSelection = this.orderExportForm.get("fractions").value;
    if (!tmpSelection.find((i) => i.uuid === option.value.uuid)) {
      this.orderExportForm
        .get("fractions")
        .setValue([...tmpSelection, option.value]);
    } else {
      this.orderExportForm
        .get("fractions")
        .setValue(tmpSelection.filter((i) => i.uuid !== option.value.uuid));
    }
  }

  private async getCustomerCompanyBranches(customerCompanyId: string) {
    try {
      const res = await this.companyBranchesService
        .getCompanyBranchesList({ limit: 500, page: 0 }, customerCompanyId)
        .toPromise();
      this.customerCompanyBranches = res.branches;
      return true;
    } catch (e) {
      return false;
    }
  }

  public addCustomerCompany() {
    let editorDialogRef = this.matDialog.open(CompanyEditorComponent, {
      panelClass: "company-editor-dialog",
      autoFocus: false,
      disableClose: true,
      data: {
        mode: "add",
      },
    });

    editorDialogRef.componentInstance.onAddCompany.subscribe((res) => {
      this.customerCompanyOptions.push(res);
      this.onChangeCustomerCompany(res);
      this.companySelect.close();
    });
  }

  private createForm(inputData: OrderExport = new OrderExport()) {
    this.orderExportForm = this.formBuilder.group({
      fractions: new FormControl(
        inputData.fractions || null,
        Validators.required
      ),
      value: new FormControl(inputData.value || null, [
        Validators.required,
        Validators.pattern("[\\d]+[.]{0,1}[\\d]{0,3}"),
        ValidateGreaterThanZero,
      ]),
      unit: new FormControl(inputData.unit || "kg", Validators.required),
      customerCompany: new FormControl(inputData.customerCompany || null),
      customerCompanyBranch: new FormControl(
        inputData.customerCompanyBranch
          ? inputData.customerCompanyBranch.uuid
            ? inputData.customerCompanyBranch.uuid
            : "other"
          : null,
        Validators.required
      ),
      transport: new FormControl(
        inputData.transport || null,
        Validators.required
      ),
      isReady: new FormControl(inputData.isReady || false, Validators.required),
      reinvoice: new FormControl(inputData.reinvoice || false),
      price: new FormControl(
        inputData.price ? String(inputData.price).replace(".", ",") : ""
      ),
      reinvoiceNumber: new FormControl(inputData.reinvoiceNumber || ""),
      invoiceComment: new FormControl(inputData.invoiceComment || ""),
      suggestedDate: new FormControl(
        this.mode === "clone" ? null : inputData.suggestedDate || null
      ),
      tags: new FormControl(inputData.tags || []),
      comment: new FormControl(
        this.mode === "clone" ? null : inputData.comment || null
      ),
      kpoEmails: new FormControl(inputData.kpoEmails || null),
      referenceNumber: new FormControl(
        this.mode === "clone" ? null : inputData.referenceNumber || null
      ),
      companyBranchId: new FormControl(
        inputData.companyBranch
          ? inputData.companyBranch.uuid
          : this.user.companyBranchId,
        Validators.required
      ),
      orderType: new FormControl(inputData.orderType || OrderTypes.Export),
      status: new FormControl(
        this.mode === "clone" ? "new" : inputData.status || "new"
      ),
      customerCompanyBranchOther: new FormGroup({
        name: new FormControl(
          (inputData.customerCompanyBranch &&
            inputData.customerCompanyBranch.name) ||
            null,
          inputData.customerCompanyBranch &&
          inputData.customerCompanyBranch.name
            ? Validators.required
            : null
        ),
        street: new FormControl(
          (inputData.customerCompanyBranch &&
            inputData.customerCompanyBranch.street) ||
            null,
          Validators.required
        ),
        postalCode: new FormControl(
          (inputData.customerCompanyBranch &&
            inputData.customerCompanyBranch.postalCode) ||
            null,
          Validators.required
        ),
        city: new FormControl(
          (inputData.customerCompanyBranch &&
            inputData.customerCompanyBranch.city) ||
            null,
          Validators.required
        ),
        country: new FormControl(
          (inputData.customerCompanyBranch &&
            inputData.customerCompanyBranch.country) ||
            "Polska",
          Validators.required
        ),
        contact: new FormControl(
          (inputData.customerCompanyBranch &&
            inputData.customerCompanyBranch.contact) ||
            null
        ),
        email: new FormControl(
          (inputData.customerCompanyBranch &&
            inputData.customerCompanyBranch.email) ||
            null
        ),
        phone: new FormControl(
          (inputData.customerCompanyBranch &&
            inputData.customerCompanyBranch.phone) ||
            null,
          Validators.required
        ),
        note: new FormControl(
          (inputData.customerCompanyBranch &&
            inputData.customerCompanyBranch.note) ||
            null
        ),
        mainBranch: new FormControl(false),
      }),
    });
    if (this.mode === "view") {
      this.orderExportForm.disable();
    }

    if (this.mode === "view" && !this.canUserEdit) {
      this.orderExportForm.disable();
      this.orderExportForm.get("suggestedDate").enable();
    }

    if (
      this.mode === "add" ||
      this.mode === "clone" ||
      this.user.userType !== this.userTypes.Logistician
    ) {
      this.orderExportForm.get("status").disable();
      this.orderExportForm.get("customerCompanyBranch").disable();
    }
    if (this.orderExportForm.get("reinvoice").value === false) {
      this.orderExportForm.get("price").disable();
      this.orderExportForm.get("reinvoiceNumber").disable();
    }
  }

  private checkCustomerCompaniesMissing() {
    const chosenCustomerCompany =
      this.orderExportForm.get("customerCompany").value;
    if (
      chosenCustomerCompany &&
      !this.customerCompanyOptions.find(
        (item) => item.uuid === chosenCustomerCompany.uuid
      )
    ) {
      this.customerCompanyOptions.push(chosenCustomerCompany);
    }
  }

  private checkTransportMissing() {
    const chosenTransport = this.orderExportForm.get("transport").value;
    if (
      chosenTransport &&
      !this.transportOptions.find((item) => item.uuid === chosenTransport.uuid)
    ) {
      this.transportOptions.push(chosenTransport);
    }
  }

  private checkFractionsMissing() {
    const chosenFractions = this.orderExportForm.get("fractions").value;
    chosenFractions &&
      chosenFractions.forEach((fraction) => {
        if (
          fraction &&
          !this.fractionOptions.find((item) => item.uuid === fraction.uuid)
        ) {
          this.fractionOptions.push(fraction);
        }
      });
  }

  private createSearchFilters() {
    this.searchCustomerCompany = new FormControl("");
    this.searchTransport = new FormControl("");
    this.searchFractions = new FormControl("");

    this.searchCustomerCompany.valueChanges
      .debounceTime(300)
      .subscribe((value) => {
        this.filteredListsService
          .getCompaniesList({ ...this.filterParams, ...{ search: value } })
          .subscribe((response) => {
            this.customerCompanyOptions = response.customerCompanies;
            !value && this.checkCustomerCompaniesMissing();
          });
      });
    this.searchTransport.valueChanges.debounceTime(300).subscribe((value) => {
      this.filteredListsService
        .getTransportList({ ...this.filterParamsFullList, ...{ name: value } })
        .subscribe((response) => {
          this.transportOptions = response.transports;
          !value && this.checkTransportMissing();
        });
    });
    this.searchFractions.valueChanges.debounceTime(300).subscribe((value) => {
      this.filteredListsService
        .getFractionsList({
          ...this.filterParamsFullList,
          ...{ search: value },
        })
        .subscribe((response) => {
          this.fractionOptions = response.fractions;
          !value && this.checkFractionsMissing();
        });
    });
  }

  public reinvoiceChange(reinvoiceChecked: boolean) {
    if (reinvoiceChecked) {
      this.orderExportForm.get("price").enable();
      this.orderExportForm.get("reinvoiceNumber").enable();
    } else {
      this.orderExportForm.get("price").setValue(null);
      this.orderExportForm.get("price").disable();
      this.orderExportForm.get("reinvoiceNumber").setValue("");
      this.orderExportForm.get("reinvoiceNumber").disable();
    }
  }

  public handleCCBranchChange(branchId: string) {
    const branch =
      this.customerCompanyBranches &&
      this.customerCompanyBranches.find(
        (item: CustomerCompanyBranch) => item.uuid === branchId
      );
    this.orderExportForm.get("customerCompanyBranchOther").patchValue({
      name: (branch && branch.name) || null,
      street: (branch && branch.street) || null,
      postalCode: (branch && branch.postalCode) || null,
      city: (branch && branch.city) || null,
      country: (branch && branch.country) || "Polska",
      contact: (branch && branch.contact) || null,
      email: (branch && branch.email) || null,
      phone: (branch && branch.phone) || null,
      note: (branch && branch.note) || null,
      mainBranch: false,
    });
  }

  public getTooltip(branch: any) {
    return `${branch.name}
    ${branch.street}
    ${branch.postalCode} ${branch.city}`;
  }

  public getTooltipCompany(company: any) {
    if (company) {
      return `${company.name} 
      ${company.address.street}
      ${company.address.postalCode} ${company.address.city}`;
    }
  }

  public clearStartDate(e) {
    e.stopPropagation();
    this.orderExportForm.get("suggestedDate").reset();
  }

  private isCloseDialogOpen: boolean = false; // without this, you can open the dialog multiple times
  @HostListener("window:keyup.esc")
  closeDialog() {
    if (!this.orderExportForm.dirty) {
      this.orderEditorDialogRef.close();
      return;
    }

    if (this.isCloseDialogOpen === false) {
      const saveDialogRef = this.matDialog.open(ConfirmModalComponent, {
        disableClose: true,
        autoFocus: false,
      });
      saveDialogRef.componentInstance.confirmMessage =
        this.translateService.instant(
          "SHARED.MESSAGES.CLOSE_DIALOG_WITHOUT_SAVE"
        );
      saveDialogRef.componentInstance.titleMessage =
        this.translateService.instant("SHARED.MESSAGES.USAVED_DATA");
      saveDialogRef.componentInstance.confirmButton =
        this.translateService.instant("SHARED.NO");
      saveDialogRef.componentInstance.skipButton =
        this.translateService.instant("SHARED.YES");

      this.isCloseDialogOpen = true;
      saveDialogRef.afterClosed().subscribe((result) => {
        this.isCloseDialogOpen = false;
        if (!result) {
          this.orderEditorDialogRef.close();
        }
      });
    }
  }
}
