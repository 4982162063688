import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { CompanyCarEditorComponent } from './components/company-car-editor/company-car-editor.component';

@NgModule({
  declarations: [
    CompanyCarEditorComponent
  ],
  imports: [
    SharedModule,
  ],
  exports: [
    CompanyCarEditorComponent
  ],
  entryComponents: [
    CompanyCarEditorComponent
  ]
})
export class CompanyCarsModule { }
