var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FuseTranslationLoaderService } from '../../../../../../@fuse/services/translation-loader.service';
import { locale as polish } from '../../../../../translations/pl';
import { locale as english } from '../../../../../translations/en';
import { List } from '../../../../../shared/models/list.class';
import { AuthService } from '../../../../../services/auth.service';
import { CompanyCarsService } from '../../../../../services/company-cars.service';
import { CompanyCarEditorComponent } from '../../../../company-cars/components/company-car-editor/company-car-editor.component';
var CompanyCarsListComponent = /** @class */ (function (_super) {
    __extends(CompanyCarsListComponent, _super);
    function CompanyCarsListComponent(companyCarsService, authService, matDialog, _fuseTranslationLoaderService) {
        var _this = _super.call(this) || this;
        _this.companyCarsService = companyCarsService;
        _this.authService = authService;
        _this.matDialog = matDialog;
        _this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        _this.displayedColumns = ['name', 'type', 'registration', 'customId', 'companyName'];
        _this.listFilters = {};
        _this.requestParams = { page: 0, limit: 500 };
        _this.user = _this.authService.getUser();
        _this.reloadList$ = companyCarsService.onEditEvent.subscribe(function () {
            _this.getElementsList();
        });
        _this._fuseTranslationLoaderService.loadTranslations(polish, english);
        _this.getElementsList = function () {
            _this.companyCarsService
                .getCompanyCarsList(_this.requestParams, _this.companyUuid)
                .subscribe(function (res) {
                _this.totalCount = res.cars.length;
                _this.dataSource = res.cars;
            });
        };
        return _this;
    }
    CompanyCarsListComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.getElementsList();
    };
    CompanyCarsListComponent.prototype.ngOnDestroy = function () {
        this.reloadList$.unsubscribe();
    };
    CompanyCarsListComponent.prototype.addCompanyCar = function () {
        this.disableAnimations = true;
        this.matDialog.open(CompanyCarEditorComponent, {
            panelClass: 'company-car-editor-dialog',
            autoFocus: false,
            disableClose: true,
            data: {
                mode: 'add',
                companyUuid: this.companyUuid
            }
        });
    };
    CompanyCarsListComponent.prototype.viewCompanyCar = function (companyCarUuid) {
        this.disableAnimations = true;
        this.matDialog.open(CompanyCarEditorComponent, {
            panelClass: 'company-car-editor-dialog',
            autoFocus: false,
            disableClose: true,
            data: {
                mode: 'view',
                companyUuid: this.companyUuid,
                companyCarUuid: companyCarUuid
            }
        });
    };
    return CompanyCarsListComponent;
}(List));
export { CompanyCarsListComponent };
