import {Component, Inject, OnInit} from "@angular/core";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from "@angular/material";
import {TranslateService} from "@ngx-translate/core";
import {FuseTranslationLoaderService} from "../../../../../@fuse/services/translation-loader.service";
import {locale as polish} from "../../../../translations/pl";
import {locale as english} from "../../../../translations/en";
import {ConfirmModalComponent} from "../../../shared/components/confirm-modal/confirm-modal.component";
import {Company} from "../../../../shared/models/companies.model";
import {CustomerCompanyBranch} from "../../../../shared/models/company-branches.model";
import {CompanyBranchesService} from "../../../../services/company-branches.service";
import {parseForm} from '../../../../shared/utils/parse-form';
import {markFormGroupTouched} from "../../../../shared/utils/markFormGroupAsTouched";

@Component({
  selector: "app-company-branch-editor",
  templateUrl: "./company-branch-editor.component.html",
  styleUrls: ["./company-branch-editor.component.scss"]
})
export class CompanyBranchEditorComponent implements OnInit {
  public companyBranchForm: FormGroup;
  public companyBranchUuid: CustomerCompanyBranch["uuid"];
  public companyBranch: CustomerCompanyBranch;
  public companyUuid: Company["uuid"];
  public mode: string;
  public isLoading = false;

  constructor(
    public companyBranchEditorDialogRef: MatDialogRef<
      CompanyBranchEditorComponent
    >,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private matDialog: MatDialog,
    private companyBranchesService: CompanyBranchesService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService
  ) {
    this.mode = data.mode;
    this.companyUuid = data.companyUuid;
    this.companyBranchUuid = data.companyBranchUuid;

    this._fuseTranslationLoaderService.loadTranslations(polish, english);
  }

  ngOnInit() {
    this.createForm({});

    if (this.mode === "edit" || this.mode === "view") {
      this.companyBranchesService
        .getCompanyBranch(this.companyUuid, this.companyBranchUuid)
        .subscribe(res => {
          this.companyBranch = res;
          this.createForm(res);
        });
    }
  }

  public submitCompanyBranch() {
    this.isLoading = true;
    markFormGroupTouched(this.companyBranchForm);

    if (this.companyBranchForm.invalid) {
      this.isLoading = false;
      return;
    }
    if (this.mode === "add") {
      this.companyBranchesService
        .createCompanyBranch(
          this.companyUuid,
          parseForm(this.companyBranchForm.getRawValue())
        )
        .subscribe(
          () => {
            this.companyBranchEditorDialogRef.close();
            this.companyBranchEditorDialogRef.afterClosed().subscribe(() => {
              this.companyBranchesService.onEditEvent.emit();
            });
            this.snackBar.open(
              this.translateService.instant(
                "COMPANY_BRANCHES.MESSAGES.CREATE_SUCCESS"
              ),
              "",
              { duration: 5000 }
            );
          },
          err => {
            this.isLoading = false;
            this.snackBar.open(
              this.translateService.instant(
                "COMPANY_BRANCHES.MESSAGES.CREATE_ERROR"
              ),
              "",
              { duration: 5000 }
            );
          }
        );
    } else {
      this.companyBranchesService
        .updateCompanyBranch(
          this.companyUuid,
          this.companyBranchUuid,
          parseForm(this.companyBranchForm.getRawValue())
        )
        .subscribe(
          () => {
            this.companyBranchEditorDialogRef.close();
            this.companyBranchEditorDialogRef.afterClosed().subscribe(() => {
              this.companyBranchesService.onEditEvent.emit();
            });
            this.snackBar.open(
              this.translateService.instant(
                "COMPANY_BRANCHES.MESSAGES.UPDATE_SUCCESS"
              ),
              "",
              { duration: 5000 }
            );
          },
          err => {
            this.isLoading = false;
            this.snackBar.open(
              this.translateService.instant(
                "COMPANY_BRANCHES.MESSAGES.UPDATE_ERROR"
              ),
              "",
              { duration: 5000 }
            );
          }
        );
    }
  }

  public deleteCompanyBranch() {
    let deleteDialogRef = this.matDialog.open(ConfirmModalComponent, {
      disableClose: false,
      autoFocus: false
    });
    deleteDialogRef.componentInstance.confirmMessage = this.translateService.instant(
      "COMPANY_BRANCHES.MESSAGES.DELETE_QUESTION"
    );
    deleteDialogRef.componentInstance.titleMessage = this.translateService.instant(
      "COMPANY_BRANCHES.MESSAGES.DELETE_TITLE"
    );
    deleteDialogRef.componentInstance.confirmButton = this.translateService.instant(
      "COMPANY_BRANCHES.DELETE"
    );

    deleteDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.companyBranchesService
          .deleteCompanyBranch(this.companyUuid, this.companyBranchUuid)
          .subscribe(
            () => {
              this.companyBranchEditorDialogRef.close();
              this.companyBranchEditorDialogRef.afterClosed().subscribe(() => {
                this.companyBranchesService.onEditEvent.emit();
              });
              this.snackBar.open(
                this.translateService.instant(
                  "COMPANY_BRANCHES.MESSAGES.DELETE_SUCCESS"
                ),
                "",
                { duration: 5000 }
              );
            },
            () => {
              this.snackBar.open(
                this.translateService.instant(
                  "COMPANY_BRANCHES.MESSAGES.DELETE_ERROR"
                ),
                "",
                { duration: 5000 }
              );
            }
          );
      }
    });
  }

  public enableEditMode() {
    this.companyBranchEditorDialogRef.close();
    this.matDialog.open(CompanyBranchEditorComponent, {
      panelClass: "company-branch-editor-dialog",
      autoFocus: false,
      disableClose: true,
      data: {
        mode: "edit",
        companyUuid: this.companyUuid,
        companyBranchUuid: this.companyBranchUuid
      }
    });
  }

  public getTitle(): string {
    switch (this.mode) {
      case "add":
        return this.translateService.instant("COMPANY_BRANCHES.TITLE_ADD");
      case "edit":
        return this.translateService.instant("COMPANY_BRANCHES.TITLE_EDIT");
      case "view":
        return this.translateService.instant("COMPANY_BRANCHES.TITLE_VIEW");
      default:
        return;
    }
  }

  private createForm(inputData: CustomerCompanyBranch | any) {
    this.companyBranchForm = this.formBuilder.group({
      name: new FormControl(inputData.name || "", Validators.required),
      contact: new FormControl(inputData.contact || ""),
      email: new FormControl(inputData.email || ""),
      phone: new FormControl(inputData.phone || "", Validators.required),
      street: new FormControl(inputData.street || "", Validators.required),
      postalCode: new FormControl(
        inputData.postalCode || "",
        Validators.required
      ),
      city: new FormControl(inputData.city || "", Validators.required),
      country: new FormControl(
        inputData.country || "Polska",
        Validators.required
      ),
      note: new FormControl(inputData.note || ""),
      mainBranch: new FormControl(inputData.mainBranch || false),
    });

    if (this.mode === "view") {
      this.companyBranchForm.disable();
    }
  }
}
