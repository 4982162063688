var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl } from '@angular/forms';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { locale as polish } from '../../../../translations/pl';
import { locale as english } from '../../../../translations/en';
import { FilteredListsService } from '../../../../services/filtered-lists.service';
var ItemsListPickerFormComponent = /** @class */ (function () {
    function ItemsListPickerFormComponent(filteredListsService, formBuilder, _fuseTranslationLoaderService) {
        this.filteredListsService = filteredListsService;
        this.formBuilder = formBuilder;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this.required = false;
        this.valuePattern = /^[1-9]([0-9]+)?$/;
        this.decimalValuePattern = /^(([1-9][0-9]+([\.][0-9]{1,3}))|([0-9][\.][0-9]{1,3})|([1-9]([0-9]+)?))$/;
        this.chosenItems = [];
        this.recyclingOptions = [];
        this.packagesOptions = [];
        this.onChange = function (items) {
        };
        this.onTouched = function () {
        };
        this.filterParamsFullList = {
            page: 1,
            limit: 1000,
            sort: 'name',
            order: 'asc'
        };
        this._fuseTranslationLoaderService.loadTranslations(polish, english);
    }
    ItemsListPickerFormComponent.prototype.writeValue = function (items) {
        this.chosenItems = items;
        this.onChange(items);
    };
    ItemsListPickerFormComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    ItemsListPickerFormComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    ItemsListPickerFormComponent.prototype.onModelChange = function () {
        this.onChange(this.chosenItems);
    };
    ItemsListPickerFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.filteredListsService.getPackageTypesList(this.filterParamsFullList).subscribe(function (res) {
            _this.packagesOptions = res.packageTypes;
        });
        this.filteredListsService.getRecyclingItemsList(this.filterParamsFullList).subscribe(function (res) {
            _this.recyclingOptions = res.recyclingItems;
        });
        this.createSearchFilters();
    };
    ItemsListPickerFormComponent.prototype.addItem = function () {
        var emptyObject = {
            recyclingItem: {
                uuid: '',
                unit: '',
                name: '',
                code: '',
                description: '',
                value: null,
                weightPerPiece: null,
                weightPerCubicMeter: null
            },
            packageTypes: [{
                    uuid: '',
                    unit: '',
                    name: '',
                    value: null
                }]
        };
        this.chosenItems.push(emptyObject);
        this.setStep(this.chosenItems.length - 1);
        this.onModelChange();
    };
    ItemsListPickerFormComponent.prototype.addPackageType = function (packageTypes) {
        packageTypes.push({
            uuid: '', unit: '', name: '', value: null
        });
        this.onModelChange();
    };
    ItemsListPickerFormComponent.prototype.pickOrderItem = function (itemUuid, item) {
        var pickedOption = this.recyclingOptions.find(function (el) { return el.uuid === itemUuid; });
        item.recyclingItem.name = pickedOption.name;
        item.recyclingItem.code = pickedOption.code;
        item.recyclingItem.description = pickedOption.description;
        item.recyclingItem.unit = '';
        item.recyclingItem.value = null;
        item.recyclingItem.weightPerPiece = pickedOption.weightPerPiece;
        item.recyclingItem.weightPerCubicMeter = pickedOption.weightPerCubicMeter;
        this.onModelChange();
    };
    ItemsListPickerFormComponent.prototype.pickPackageType = function (itemUuid, item) {
        item.name = this.packagesOptions.find(function (el) { return el.uuid === itemUuid; }).name;
        item.unit = this.packagesOptions.find(function (el) { return el.uuid === itemUuid; }).unit;
        item.value = null;
        this.onModelChange();
    };
    ItemsListPickerFormComponent.prototype.deleteItem = function (index) {
        this.chosenItems.splice(index, 1);
        this.setStep(null);
        this.onModelChange();
    };
    ItemsListPickerFormComponent.prototype.deletePackage = function (index, packages) {
        packages.splice(index, 1);
        this.onModelChange();
    };
    ItemsListPickerFormComponent.prototype.getRecyclingOptions = function () {
        var selectOptions = this.recyclingOptions.slice();
        this.chosenItems && this.chosenItems.forEach(function (chosenItem) {
            if (selectOptions.find(function (option) { return option.uuid === chosenItem.recyclingItem.uuid; })) {
                selectOptions.splice(selectOptions.findIndex(function (item) { return item.uuid === chosenItem.recyclingItem.uuid; }), 1);
            }
        });
        return selectOptions;
    };
    ItemsListPickerFormComponent.prototype.getPackageOptions = function (index) {
        var selectOptions = this.packagesOptions.slice();
        this.chosenItems && this.chosenItems[index] && this.chosenItems[index].packageTypes && this.chosenItems[index].packageTypes.forEach(function (chosenItem) {
            if (selectOptions.find(function (option) { return option.uuid === chosenItem.uuid; })) {
                selectOptions.splice(selectOptions.findIndex(function (item) { return item.uuid === chosenItem.uuid; }), 1);
            }
        });
        return selectOptions;
    };
    ItemsListPickerFormComponent.prototype.setStep = function (index) {
        this.step = index;
    };
    ItemsListPickerFormComponent.prototype.validateRecyclingItems = function () {
        return !!(this.formControl.errors);
    };
    ItemsListPickerFormComponent.prototype.getTotalWeight = function () {
        var _this = this;
        var totalWeight = 0;
        this.chosenItems.forEach(function (item) {
            if (item.recyclingItem.value && item.recyclingItem.unit) {
                totalWeight += _this.getWeight(item.recyclingItem);
            }
        });
        if (Number.isInteger(totalWeight)) {
            return totalWeight;
        }
        else {
            return totalWeight.toFixed(3);
        }
    };
    ;
    ItemsListPickerFormComponent.prototype.getItemWeight = function (item) {
        var weight = 0;
        if (item.recyclingItem.value && item.recyclingItem.unit) {
            weight = this.getWeight(item.recyclingItem);
        }
        if (Number.isInteger(weight)) {
            return weight;
        }
        else {
            return weight.toFixed(3);
        }
    };
    ;
    ItemsListPickerFormComponent.prototype.getWeight = function (item) {
        var value = parseFloat(item.value);
        var weight = 0;
        switch (item.unit) {
            case 'm3':
                weight = value * item.weightPerCubicMeter;
                break;
            case 'pieces':
                weight = value * item.weightPerPiece;
                break;
            default:
                weight = value;
                break;
        }
        return weight;
    };
    ItemsListPickerFormComponent.prototype.createSearchFilters = function () {
        var _this = this;
        this.searchRecycling = new FormControl('');
        this.searchPackage = new FormControl('');
        this.searchRecycling.valueChanges.debounceTime(300).subscribe(function (value) {
            _this.filteredListsService.getRecyclingItemsList(__assign({}, _this.filterParamsFullList, { search: value })).subscribe(function (response) {
                _this.recyclingOptions = response.recyclingItems;
            });
        });
        this.searchPackage.valueChanges.debounceTime(300).subscribe(function (value) {
            _this.filteredListsService.getPackageTypesList(__assign({}, _this.filterParamsFullList, { name: value })).subscribe(function (response) {
                _this.packagesOptions = response.packageTypes;
            });
        });
    };
    return ItemsListPickerFormComponent;
}());
export { ItemsListPickerFormComponent };
