import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { FuseConfigService } from "../../../../../@fuse/services/config.service";
import { FuseTranslationLoaderService } from "../../../../../@fuse/services/translation-loader.service";
import { AuthService } from "../../../../services/auth.service";
import { locale as polish } from "../../../../translations/pl";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public isLoading = false;
  public twoFa = false;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private _fuseConfigService: FuseConfigService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService
  ) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        toolbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
    };

    this._fuseTranslationLoaderService.loadTranslations(polish);
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
      twoFa: [""],
    });
  }

  public login() {
    this.isLoading = true;
    this.authService.login(this.loginForm.value).subscribe(
      () => {
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        if (err.error.error_code === 617) {
          this.twoFa = true;
        }
        if (err.error.error_code === 618 || err.error.error_code === 4008) {
          this.loginForm.get("twoFa").setErrors({ wrong: true });
        }
        this.snackBar.open(
          this.translateService.instant("SHARED.MESSAGES.ERROR"),
          "",
          { duration: 5000 }
        );
      }
    );
  }
}
