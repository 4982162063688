<h1 matDialogTitle class="title">{{'ORDERS.MESSAGES.SEND_LINK_TITLE' | translate}}</h1>
<div mat-dialog-content>
  <form class="form" [formGroup]="sendLinkForm">
    <mat-form-field appearance="outline" fxFlex>
      <mat-label>{{'USERS.EMAIL' | translate}}</mat-label>
      <input formControlName="email" matInput required [attr.data-cy]="'email'">
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions class="pt-24" fxLayoutAlign="end center">
  <button mat-button class="mr-16" (click)="matDialogRef.close(false)" [attr.data-cy]="'cancelLink'">
    {{'SHARED.CANCEL' | translate}}
  </button>
  <button mat-raised-button class="mat-accent" [disabled]="sendLinkForm.invalid" (click)="submit()" [attr.data-cy]="'sendLink'">
    {{'SHARED.SEND' | translate}}
  </button>
</div>
