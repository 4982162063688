import { HttpClient } from "@angular/common/http";
import { EventEmitter } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { AuthService } from "./auth.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./auth.service";
var CompanyKpoService = /** @class */ (function () {
    function CompanyKpoService(http, authService) {
        this.http = http;
        this.authService = authService;
        this.onEditEvent = new EventEmitter();
    }
    CompanyKpoService.prototype.getCompanyKpoList = function (params, companyUuid) {
        return this.http
            .get(environment.apiUrl + "/" + this.authService.getUserType() + "/customersCompanies/" + companyUuid + "/kpo", { params: params })
            .pipe(map(function (res) { return res; }));
    };
    CompanyKpoService.prototype.getCompanyKpo = function (companyUuid, kpoUuid) {
        return this.http
            .get(environment.apiUrl + "/" + this.authService.getUserType() + "/customersCompanies/" + companyUuid + "/kpo/" + kpoUuid)
            .pipe(map(function (res) { return res.kpo; }));
    };
    CompanyKpoService.prototype.createCompanyKpo = function (companyUuid, kpo) {
        return this.http
            .post(environment.apiUrl + "/" + this.authService.getUserType() + "/customersCompanies/" + companyUuid + "/kpo", kpo)
            .pipe(map(function (res) { return res.kpo; }));
    };
    CompanyKpoService.prototype.updateCompanyKpo = function (companyUuid, kpoUuid, kpo) {
        return this.http.post(environment.apiUrl + "/" + this.authService.getUserType() + "/customersCompanies/" + companyUuid + "/kpo/" + kpoUuid, kpo);
    };
    CompanyKpoService.prototype.attachCompanyKpo = function (companyUuid, kpoUuid) {
        return this.http.post(environment.apiUrl + "/" + this.authService.getUserType() + "/customersCompanies/" + companyUuid + "/attachKpo", { kpoUuid: kpoUuid });
    };
    CompanyKpoService.prototype.detachCompanyKpo = function (companyUuid, kpoUuid) {
        return this.http.delete(environment.apiUrl + "/" + this.authService.getUserType() + "/customersCompanies/" + companyUuid + "/kpo/" + kpoUuid);
    };
    CompanyKpoService.ngInjectableDef = i0.defineInjectable({ factory: function CompanyKpoService_Factory() { return new CompanyKpoService(i0.inject(i1.HttpClient), i0.inject(i2.AuthService)); }, token: CompanyKpoService, providedIn: "root" });
    return CompanyKpoService;
}());
export { CompanyKpoService };
