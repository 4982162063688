<mat-accordion>
  <mat-expansion-panel *ngFor="let order of orders">
    <mat-expansion-panel-header class="header">
      <mat-panel-title class="event-title">
        {{order.customerCompany.name}}
      </mat-panel-title>
      <mat-panel-description *ngIf="order.order">
        {{order.order.orderNumber}}
      </mat-panel-description>
    </mat-expansion-panel-header>

    <ng-container *ngIf="order.fractions">
      <h3 class="top-paragraph">{{'HISTORY.HISTORY_KEYS.fractions' | translate}} <strong
        *ngFor="let fraction of order.fractions; let i=index">{{fraction.name}}{{i < order.fractions.length - 1 ? ', ' : ''}}
      </strong>
      </h3>
      <p *ngIf="order.value">
        {{'HISTORY.HISTORY_KEYS.totalWeight' | translate}}
        <strong>{{order.value}}</strong> {{'HISTORY.HISTORY_KEYS.kg' | translate}}
      </p>
      <mat-divider></mat-divider>
      <h3><strong>{{'HISTORY.HISTORY_KEYS.targetPlace' | translate}}</strong></h3>
    </ng-container>

    <ng-container *ngIf="order.address">
      <p *ngIf="order.address.street" class="top-paragraph">
        {{'HISTORY.HISTORY_KEYS.street' | translate}} <strong>{{order.address.street}}</strong>
      </p>
      <p *ngIf="order.address.postalCode">
        {{'HISTORY.HISTORY_KEYS.postalCode' | translate}} <strong>{{order.address.postalCode}}</strong>
      </p>
      <p *ngIf="order.address.city">
        {{'HISTORY.HISTORY_KEYS.city' | translate}} <strong>{{order.address.city}}</strong>
      </p>
      <p *ngIf="order.address.country">
        {{'HISTORY.HISTORY_KEYS.country' | translate}} <strong>{{order.address.country}}</strong>
      </p>
    </ng-container>

    <ng-container *ngIf="!order.address && order.customerCompany && order.customerCompany.address">
      <p *ngIf="order.customerCompany.address.street" class="top-paragraph">
        {{'HISTORY.HISTORY_KEYS.street' | translate}} <strong>{{order.customerCompany.address.street}}</strong>
      </p>
      <p *ngIf="order.customerCompany.address.postalCode">
        {{'HISTORY.HISTORY_KEYS.postalCode' | translate}} <strong>{{order.customerCompany.address.postalCode}}</strong>
      </p>
      <p *ngIf="order.customerCompany.address.city">
        {{'HISTORY.HISTORY_KEYS.city' | translate}} <strong>{{order.customerCompany.address.city}}</strong>
      </p>
      <p *ngIf="order.customerCompany.address.country">
        {{'HISTORY.HISTORY_KEYS.country' | translate}} <strong>{{order.customerCompany.address.country}}</strong>
      </p>
    </ng-container>

    <p *ngIf="order.registrationNumFront">
      {{'HISTORY.HISTORY_KEYS.registrationNumFront' | translate}}: <strong>{{order.registrationNumFront}}</strong>
    </p>
    <p *ngIf="order.registrationNumBack">
      {{'HISTORY.HISTORY_KEYS.registrationNumBack' | translate}}: <strong>{{order.registrationNumBack}}</strong>
    </p>
    <p *ngIf="order.referenceNumber">
      {{'HISTORY.HISTORY_KEYS.orderReferenceNumber' | translate}}: <strong>{{order.referenceNumber}}</strong>
    </p>

    <ng-container *ngIf="order.orderData">
      <h3>
        <strong>{{(order.orderData.length === 1 ? 'HISTORY.HISTORY_KEYS.recyclingItem' : 'HISTORY.HISTORY_KEYS.recyclingItems') | translate}}</strong>
      </h3>
      <app-recycling-items-display [recyclingItems]="order.orderData"></app-recycling-items-display>
    </ng-container>

  </mat-expansion-panel>
</mat-accordion>