export function removeAccents(str) {
  let accents = 'ĄąĆćĘęŁłŃńÓóŚśŻżŹź';
  let accentsOut = "AaCcEeLlNnOoSsZzZz";
  str = str.split('');
  str.forEach((letter, index) => {
    let i = accents.indexOf(letter);
    if (i != -1) {
      str[index] = accentsOut[i];
    }
  });
  return str.join('');
}