import { Component, OnDestroy, OnInit } from '@angular/core';
import { List } from '../../../../shared/models/list.class';
import { UsersService } from '../../../../services/users.service';
import { RoleTypes, User, UsersListResponse } from '../../../../shared/models/users.model';
import { AuthService } from '../../../../services/auth.service';
import { MatDialog } from '@angular/material';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { locale as polish } from '../../../../translations/pl';
import { locale as english } from '../../../../translations/en';
import { FormControl } from '@angular/forms';
import 'rxjs-compat/add/operator/debounceTime';
import { ListFilters } from '../../../../shared/models/list.model';
import { fuseAnimations } from '../../../../../@fuse/animations';
import { remondisListAnimation } from '../../../../shared/consts/remondis.lists.animation';
import { UserEditorComponent } from '../user-editor/user-editor.component';


@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
  animations: [...fuseAnimations, ...remondisListAnimation]
})
export class UsersListComponent extends List implements OnInit, OnDestroy {
  public displayedColumns = ['avatar', 'name', 'surname', 'email', 'userType', 'roleType', 'companyBranch', 'active'];
  public loggedUser: User;
  public searchUser: FormControl;
  public companyBranches = [];
  public isAdmin = false;
  private listFilters: ListFilters = {};
  private reloadList$;

  constructor(private usersService: UsersService,
              private authService: AuthService,
              private matDialog: MatDialog,
              private _fuseTranslationLoaderService: FuseTranslationLoaderService) {
    super();

    this._fuseTranslationLoaderService.loadTranslations(polish, english);

    this.reloadList$ = this.usersService.onEditEvent.subscribe(
      () => {
        this.getElementsList();
      });

    this.loggedUser = this.authService.getUser();
    if (this.loggedUser.roleType === RoleTypes.Admin) {
      this.isAdmin = true;
    }

    this.getElementsList = () => {
      this.usersService.getUsersList(this.getQueryParams()).subscribe((res: UsersListResponse) => {
        this.totalCount = res.totalCount;
        this.dataSource = res.users;
      });
    };
  }

  ngOnInit() {
    super.ngOnInit();
    this.requestParams.sort = 'surname';
    this.createSearchFormControl();

    this.usersService.getCompanyBranchList().subscribe(
      res => {
        res.branches.forEach(branch => {
          this.companyBranches.push({name: branch.name, value: branch.uuid})
        });
      }
    );
  }

  ngOnDestroy() {
    this.reloadList$.unsubscribe();
  }

  public clearSearch() {
    if (this.searchUser.value) {
      this.searchUser.setValue('');
    }
  }

  public getListFilters(filters: ListFilters) {
    this.listFilters = filters;
    this.requestParams.page = 1;
    this.disableAnimations = true;
    this.getElementsList();
  }

  public addUser() {
    this.disableAnimations = true;

    let editorDialogRef = this.matDialog.open(UserEditorComponent, {
      panelClass: 'user-editor-dialog',
      autoFocus: false,
      disableClose: true,
      data: {
        mode: 'add'
      }
    });
  }

  public viewUser(editedUserUuid: User['uuid']) {
    this.disableAnimations = true;

    let editorDialogRef = this.matDialog.open(UserEditorComponent, {
      panelClass: 'user-editor-dialog',
      autoFocus: false,
      disableClose: true,
      data: {
        mode: 'view',
        editedUserUuid: editedUserUuid
      }
    });
  }

  private createSearchFormControl() {
    this.searchUser = new FormControl('');
    this.searchUser.valueChanges.debounceTime(300).subscribe((value) => {
      this.requestParams.search = value;
      this.disableAnimations = true;
      this.getElementsList();
    });
  }

  private getQueryParams() {
    return Object.assign({}, this.requestParams, this.listFilters);
  }
}
