import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { locale as polish } from '../../../../translations/pl';
import { locale as english } from '../../../../translations/en';
import { fuseAnimations } from '../../../../../@fuse/animations';
import { remondisListAnimation } from '../../../../shared/consts/remondis.lists.animation';
import { ReportsService } from '../../../../services/reports.service';
import { Months } from '../../../../shared/consts/months';
import * as moment from 'moment';
import { ReportsListItem } from '../../../../shared/models/reports.model';
import { UsersService } from '../../../../services/users.service';

@Component({
  selector: 'app-reports-list',
  templateUrl: './reports-list.component.html',
  styleUrls: ['./reports-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [...fuseAnimations, ...remondisListAnimation]
})
export class ReportsListComponent implements OnInit {
  public displayedColumns: string[] = [];
  public reports: ReportsListItem[] = [];
  public columnItems: string[] = [];
  public yearFilter: number;
  public monthFilter: number;
  public companyBranchIdFilter: string = '';
  public months = Months;
  public years: number[] = [];
  public currentMonth: number = moment().month() + 1;
  public currentYear: number = moment().year();
  public companyBranches = [];
  public monthData: any;

  constructor(private reportsService: ReportsService,
              private usersService: UsersService,
              private _fuseTranslationLoaderService: FuseTranslationLoaderService) {
    this._fuseTranslationLoaderService.loadTranslations(polish, english);

    this.getYears();
    this.yearFilter = this.currentYear;
    this.monthFilter = this.currentMonth;
  }

  ngOnInit() {
    this.getReport();

    this.usersService.getCompanyBranchList().subscribe(res => {
        this.companyBranches = res.branches;
      }
    );
  }

  public getTableColumns() {
    return ['week', 'date', ...this.columnItems, 'sum']
  }

  public getReport() {
    const baseFrom = `${this.yearFilter}-${this.monthFilter}-01`;
    const filterParams = {
      dateFrom: moment(baseFrom, 'YYYY-M-DD').format('YYYY-MM-DD'),
      dateTo: moment(baseFrom, 'YYYY-M-DD').endOf('month').format('YYYY-MM-DD'),
      companyBranchId: this.companyBranchIdFilter
    };
    if (!this.companyBranchIdFilter) {
      delete filterParams.companyBranchId;
    }

    this.reportsService.getReports(filterParams).subscribe(
      res => {
        this.reports = [];
        this.columnItems = res.itemsList; // column list: "AGD małe", "TV", "NonFood", "lodówki"

        const rowDataTemplate = {
          week: 0,
          date: '',
          sum: 0,
          isFirstDay: false,
          isWeekSum: false,
          isMonthSum: false
        };

        this.columnItems.forEach(item => {
          rowDataTemplate[item] = 0; // add field/property to "class/template"
        });
        
        this.monthData = {...rowDataTemplate};
        this.monthData.sum = res.totalWeight;
        this.monthData.isMonthSum = true;

        res.data.forEach(week => {
          const weekData = {...rowDataTemplate};
          weekData.isWeekSum = true;
          weekData.date = '';
          weekData.sum = week.totalWeight;

          week.days.forEach(day => {
            const rowData = {...rowDataTemplate};
            if (week.days[0].date === day.date) {
              rowData.isFirstDay = true
            }
            rowData.week = week.week;
            rowData.date = moment(day.date).format('DD');
            rowData.sum = day.totalWeight;

            day.items.forEach(item => {
              rowData[item.name] = item.totalWeight;
              weekData[item.name] = weekData[item.name] + rowData[item.name];
              this.monthData[item.name] = this.monthData[item.name] + rowData[item.name];
            });
            this.reports.push(rowData);
          });
          this.reports.push(weekData);
        });
      }
    )
  }

  private getYears() {
    for (let i = 2018; i <= this.currentYear + 1; i++) {
      this.years.unshift(i);
    }
  }
}
