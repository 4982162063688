<div class="package">
  <div class="header p-16 p-sm-24"
       fxLayout.gt-xs="row"
       fxLayoutAlign.gt-xs="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="logo" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="logo-icon mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
          local_mall
        </mat-icon>
        <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
          {{'PACKAGE.TITLE_LIST' | translate}}
        </span>
      </div>
    </div>
    <div class="search-wrapper">
      <div class="search">
        <label for="name" class="mr-8">
          <mat-icon (click)="clearSearch()">{{searchPackage.value ? 'clear' : 'search'}}</mat-icon>
        </label>
        <input id="name" [formControl]="searchPackage"
               [placeholder]="'PACKAGE.SEARCH_PLACEHOLDER' | translate">
      </div>
    </div>
  </div>

  <div class="package-content">
    <app-list-filters class="filters-container"
                      [filters]="['isPublic', 'active']"
                      (listFilters)="getListFilters($event)">
    </app-list-filters>
    <div class="package-table">
      <mat-table matSort
                 [dataSource]="dataSource"
                 [matSortActive]="requestParams.sort"
                 [matSortDirection]="requestParams.order"
                 [@animateStaggerRemondis]="dataSource.length"
                 [@.disabled]="disableAnimations">

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'PACKAGE.NAME' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let package"> {{package.name}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="unit">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'PACKAGE.UNIT' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let package"> {{'PACKAGE.UNIT_TYPES.' + package.unit.toUpperCase() | translate}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="length">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'PACKAGE.LENGTH' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let package">{{package.length}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="width">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'PACKAGE.WIDTH' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let package">{{package.width}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="height">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'PACKAGE.HEIGHT' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let package">{{package.height}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="volume">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'PACKAGE.VOLUME' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let package">{{package.volume}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="capacity">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'PACKAGE.CAPACITY' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let package">{{package.capacity}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="isPublic">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'PACKAGE.VISIBLE_BY_CLIENT' | translate}}
          </mat-header-cell>
          <mat-cell *matCellDef="let package">
            <mat-icon [ngClass]="package.isPublic && 'green-icon'">
              {{package.isPublic ? 'check' : 'close'}}
            </mat-icon>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="active">
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'PACKAGE.STATUSES.ACTIVE' | translate}}
          </mat-header-cell>
          <mat-cell *matCellDef="let package">
            <mat-icon [ngClass]="package.active && 'green-icon'">
              {{package.active ? 'check' : 'close'}}
            </mat-icon>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          class="package-row"
          *matRowDef="let package; columns: displayedColumns;"
          [@animateRemondis]="{value:'*',params:{y:'100%'}}"
          [id]="package.uuid"
          (click)="viewPackageType(package.uuid)">
        </mat-row>
      </mat-table>

      <h4 class="no-data" *ngIf="totalCount === 0">
        {{'SHARED.NO_DATA' | translate}}
      </h4>

      <div *ngIf="totalCount" class="paginator-container">
        <mat-paginator
          (page)="pageChange($event)"
          [pageSize]="requestParams.limit"
          [pageIndex]="(requestParams.page - 1)"
          [length]="totalCount"
          class="paginator-holder">
        </mat-paginator>
      </div>
    </div>
  </div>

  <button
    *ngIf="user.roleType === 'admin'"
    mat-fab class="add-package-button"
    [attr.data-cy]="'addPackage'"
    [@animate]="{value:'*', params:{delay:'300ms',scale:'.2'}}"
    [matTooltip]="'PACKAGE.TITLE_ADD' | translate"
    matTooltipPosition="above"
    (click)="addPackageType()">
    <mat-icon>add</mat-icon>
  </button>
</div>