import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { locale as polish } from '../../../../translations/pl';
import { locale as english } from '../../../../translations/en';
import { RequestParams } from '../../../../shared/models/list.model';
import { FilteredPackageType, FilteredOrderData, FilteredRecyclingItem } from '../../../../shared/models/filtered-items.model';
import { FilteredListsService } from '../../../../services/filtered-lists.service';

@Component({
  selector: 'app-items-list-picker-form',
  templateUrl: './items-list-picker-form.component.html',
  styleUrls: [ './items-list-picker-form.component.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ItemsListPickerFormComponent),
      multi: true
    }
  ]
})
export class ItemsListPickerFormComponent implements OnInit, ControlValueAccessor {
  @Input() mode: string;
  @Input() required: boolean = false;
  @Input() formControl: FormControl;
  public valuePattern = /^[1-9]([0-9]+)?$/;
  public decimalValuePattern = /^(([1-9][0-9]+([\.][0-9]{1,3}))|([0-9][\.][0-9]{1,3})|([1-9]([0-9]+)?))$/;
  public chosenItems: FilteredOrderData[] = [];
  public recyclingOptions: any[] = [];
  public packagesOptions: any[] = [];
  public searchRecycling: FormControl;
  public searchPackage: FormControl;
  public step: number;
  public onChange = (items) => {
  };
  public onTouched = () => {
  };
  private filterParamsFullList: RequestParams = {
    page: 1,
    limit: 1000,
    sort: 'name',
    order: 'asc'
  };

  constructor(private filteredListsService: FilteredListsService,
              private formBuilder: FormBuilder,
              private _fuseTranslationLoaderService: FuseTranslationLoaderService) {
    this._fuseTranslationLoaderService.loadTranslations(polish, english);
  }

  writeValue(items: FilteredOrderData[]) {
    this.chosenItems = items;
    this.onChange(items);
  }

  registerOnChange(fn: (items) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  onModelChange() {
    this.onChange(this.chosenItems);
  }

  ngOnInit() {
    this.filteredListsService.getPackageTypesList(this.filterParamsFullList).subscribe(res => {
      this.packagesOptions = res.packageTypes;
    });

    this.filteredListsService.getRecyclingItemsList(this.filterParamsFullList).subscribe(res => {
      this.recyclingOptions = res.recyclingItems;
    });

    this.createSearchFilters();
  }

  public addItem() {
    const emptyObject: FilteredOrderData = {
      recyclingItem:
        {
          uuid: '',
          unit: '',
          name: '',
          code: '',
          description: '',
          value: null,
          weightPerPiece: null,
          weightPerCubicMeter: null
        },
      packageTypes: [ {
        uuid: '',
        unit: '',
        name: '',
        value: null
      } ]
    };
    this.chosenItems.push(emptyObject);
    this.setStep(this.chosenItems.length - 1);

    this.onModelChange();
  }

  public addPackageType(packageTypes) {

    packageTypes.push({
      uuid: '', unit: '', name: '', value: null
    });
    this.onModelChange();
  }

  public pickOrderItem(itemUuid: string, item: FilteredOrderData) {
    const pickedOption = this.recyclingOptions.find(el => el.uuid === itemUuid);
    item.recyclingItem.name = pickedOption.name;
    item.recyclingItem.code = pickedOption.code;
    item.recyclingItem.description = pickedOption.description;
    item.recyclingItem.unit = '';
    item.recyclingItem.value = null;
    item.recyclingItem.weightPerPiece = pickedOption.weightPerPiece;
    item.recyclingItem.weightPerCubicMeter = pickedOption.weightPerCubicMeter;
    this.onModelChange();
  }

  public pickPackageType(itemUuid: string, item: FilteredPackageType) {
    item.name = this.packagesOptions.find(el => el.uuid === itemUuid).name;
    item.unit = this.packagesOptions.find(el => el.uuid === itemUuid).unit;
    item.value = null;
    this.onModelChange();
  }

  public deleteItem(index: number) {
    this.chosenItems.splice(index, 1);
    this.setStep(null);
    this.onModelChange();
  }

  public deletePackage(index: number, packages) {
    packages.splice(index, 1);
    this.onModelChange();
  }

  public getRecyclingOptions() {
    const selectOptions = [ ...this.recyclingOptions ];

    this.chosenItems && this.chosenItems.forEach((chosenItem) => {
      if (selectOptions.find(option => option.uuid === chosenItem.recyclingItem.uuid)) {
        selectOptions.splice(selectOptions.findIndex(item => item.uuid === chosenItem.recyclingItem.uuid), 1);
      }
    });
    return selectOptions;
  }

  public getPackageOptions(index) {
    const selectOptions = [ ...this.packagesOptions ];

    this.chosenItems && this.chosenItems[ index ] && this.chosenItems[ index ].packageTypes && this.chosenItems[ index ].packageTypes.forEach((chosenItem) => {
      if (selectOptions.find(option => option.uuid === chosenItem.uuid)) {
        selectOptions.splice(selectOptions.findIndex(item => item.uuid === chosenItem.uuid), 1);
      }
    });
    return selectOptions;
  }

  public setStep(index: number) {
    this.step = index;
  }

  public validateRecyclingItems() {
    return !!(this.formControl.errors);
  }

  public getTotalWeight() {
    let totalWeight = 0;
    this.chosenItems.forEach(item => {
      if (item.recyclingItem.value && item.recyclingItem.unit) {
        totalWeight += this.getWeight(item.recyclingItem);
      }
    });
    if (Number.isInteger(totalWeight)) {
      return totalWeight;
    } else {
      return totalWeight.toFixed(3);
    }
  };

  public getItemWeight(item: FilteredOrderData) {
    let weight = 0;
    if (item.recyclingItem.value && item.recyclingItem.unit) {
      weight = this.getWeight(item.recyclingItem);
    }
    if (Number.isInteger(weight)) {
      return weight;
    } else {
      return weight.toFixed(3);
    }
  };

  private getWeight(item: FilteredRecyclingItem) {
    const value = parseFloat(item.value);
    let weight = 0;
    switch (item.unit) {
      case 'm3':
        weight = value * item.weightPerCubicMeter;
        break;
      case 'pieces':
        weight = value * item.weightPerPiece;
        break;
      default:
        weight = value;
        break;
    }
    return weight;
  }

  private createSearchFilters() {
    this.searchRecycling = new FormControl('');
    this.searchPackage = new FormControl('');

    this.searchRecycling.valueChanges.debounceTime(300).subscribe((value) => {
      this.filteredListsService.getRecyclingItemsList({...this.filterParamsFullList, ...{search: value}}).subscribe(
        response => {
          this.recyclingOptions = response.recyclingItems;
        });
    });
    this.searchPackage.valueChanges.debounceTime(300).subscribe((value) => {
      this.filteredListsService.getPackageTypesList({...this.filterParamsFullList, ...{name: value}}).subscribe(
        response => {
          this.packagesOptions = response.packageTypes;
        });
    });
  }
}
