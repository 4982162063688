<div id="forgot-password" fxLayout="column">

  <div id="forgot-password-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

    <div id="forgot-password-form">

      <div class="logo">
        <img src="assets/images/logo.svg">
      </div>

      <div class="title">{{'SHARED.RESET_PASS' | translate}}</div>
      <div class="subtitle">{{'SHARED.RESET_PASS_TIP' | translate}}</div>

      <form name="forgotPasswordForm" [formGroup]="forgotPasswordForm" novalidate (ngSubmit)="submit()">

        <mat-form-field>
          <input matInput
                 type="email"
                 placeholder="{{'SHARED.EMAIL' | translate}}"
                 formControlName="email"
                 required>
          <mat-error *ngIf="forgotPasswordForm.get('email').hasError('required')">
            {{'SHARED.NO_EMAIL_ERROR' | translate}}
          </mat-error>
          <mat-error
            *ngIf="!forgotPasswordForm.get('email').hasError('required') &&
                                forgotPasswordForm.get('email').hasError('email')">
            {{'SHARED.EMAIL_ERROR' | translate}}
          </mat-error>
        </mat-form-field>

        <button mat-raised-button color="primary" class="submit-button" aria-label="LOG IN"
                [disabled]="forgotPasswordForm.invalid || isLoading" type="submit">
          <span *ngIf="!isLoading">{{'SHARED.SEND' | translate}}</span>
          <span *ngIf="isLoading" class="loading-spinner-wrapper">
              <mat-progress-spinner
                mode="indeterminate"
                [strokeWidth]="2"
                [diameter]="20">
              </mat-progress-spinner>
            </span>
        </button>

      </form>

    </div>

  </div>

</div>