import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '../../../../../../@fuse/services/translation-loader.service';
import { locale as polish } from '../../../../../translations/pl';
import { locale as english } from '../../../../../translations/en';
import * as moment from 'moment';
import { OrderExport, OrderImport, OrderReceiving } from '../../../../../shared/models/orders.model';

@Component({
  selector: 'app-order-details-form',
  templateUrl: './order-details-form.component.html',
  styleUrls: ['./order-details-form.component.scss']
})
export class OrderDetailsFormComponent implements OnInit {
  @Input() orderData: OrderExport | OrderImport | OrderReceiving;
  public detailsForm: FormGroup;

  constructor(private translateService: TranslateService,
              private formBuilder: FormBuilder,
              private _fuseTranslationLoaderService: FuseTranslationLoaderService) {
    this._fuseTranslationLoaderService.loadTranslations(polish, english);
  }

  ngOnInit() {
    this.createForm(this.orderData || {});
  }

  private createForm(inputData) {
    this.detailsForm = this.formBuilder.group({
      author: new FormControl(inputData.createdBy ? `${inputData.createdBy.name} ${inputData.createdBy.surname}` : ''),
      createdDate: new FormControl(moment(inputData.createdDate).format('YYYY-MM-DD HH:mm') || ''),
      updatedDate: new FormControl(moment(inputData.updatedDate).format('YYYY-MM-DD HH:mm') || ''),
      status: new FormControl(inputData.status ? this.translateService.instant('ORDERS.STATUSES.' + inputData.status.toUpperCase()) : ''),
      taskNumber: new FormControl(inputData.taskNumber || '')
    });
    this.detailsForm.disable();
  }

}
