<mat-accordion>
  <mat-expansion-panel *ngFor="let changes of changedOrders">
    <mat-expansion-panel-header class="header">
      <mat-panel-title class="event-title">
        {{changes.originalObject.customerCompany.name}}
      </mat-panel-title>
      <mat-panel-description>
        {{changes.originalObject.order.orderNumber}}
      </mat-panel-description>
    </mat-expansion-panel-header>
    <app-task-order-changes [changes]="changes"></app-task-order-changes>
  </mat-expansion-panel>
</mat-accordion>