import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import {
  MatAutocompleteSelectedEvent,
  MatChipInputEvent,
  MatAutocomplete,
} from "@angular/material";
import { TagsService } from "../../../services/tags.service";
import { RequestParams } from "../../../shared/models/list.model";

@Component({
  selector: "app-tags-autocomplete",
  templateUrl: "./tags-autocomplete.component.html",
  styleUrls: ["./tags-autocomplete.component.scss"],
})
export class TagsAutocompleteComponent implements OnInit {
  @Input() mode: string;
  @Input() tagsControl: FormControl;
  formControl = new FormControl();
  visible = true;
  selectable = false;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  selectedTags: string[] = [];
  allTags: string[] = [];

  @ViewChild("tagInput") tagInput: ElementRef<HTMLInputElement>;
  @ViewChild("auto") matAutocomplete: MatAutocomplete;
  private filterParamsFullList: RequestParams = {
    page: 1,
    limit: 1000,
    sort: "name",
    order: "asc",
  };
  constructor(private tagsService: TagsService) {}

  ngOnInit(): void {
    this.tagsService.getTagsList(this.filterParamsFullList).subscribe((res) => {
      this.allTags = res.tags.map((tag) => tag.name);
      if (this.tagsControl && this.tagsControl.value) {
        this.allTags = this.allTags.filter(
          (tag) => !this.tagsControl.value.includes(tag)
        );
      }
    });
    this.createSearchFilters();
    if (this.mode === "view") {
      this.formControl.disable();
      this.selectable = false;
      this.removable = false;
    } else {
      this.formControl.enable();
    }
  }

  add(event: MatChipInputEvent): void {
    this.selectedTags = this.tagsControl.value;
    // Add tag only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;

      // Add our tag
      if ((value || "").trim()) {
        this.selectedTags.push(value.trim());
      }

      // Reset the input value
      if (input) {
        input.value = "";
      }

      this.formControl.setValue(null);
      this.tagsControl.setValue(this.selectedTags);
    }
  }

  remove(tag: string): void {
    this.selectedTags = this.tagsControl.value;
    const index = this.selectedTags.indexOf(tag);

    if (index >= 0) {
      this.selectedTags.splice(index, 1);
    }
    this.tagsControl.setValue(this.selectedTags);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedTags = this.tagsControl.value || [];
    this.selectedTags.push(event.option.viewValue);
    this.tagInput.nativeElement.value = "";
    this.formControl.setValue(null);
    this.tagsControl.setValue(this.selectedTags);
  }

  private createSearchFilters(): void {
    this.formControl.valueChanges.debounceTime(300).subscribe((value) => {
      const search = value && value.toLowerCase();
      this.tagsService
        .getTagsList({
          ...this.filterParamsFullList,
          ...{ search: search || "" },
        })
        .subscribe((res) => {
          this.allTags = res.tags.map((tag) => tag.name);
          if (this.tagsControl && this.tagsControl.value) {
            this.allTags = this.allTags.filter(
              (tag) => !this.tagsControl.value.includes(tag)
            );
          }
        });
    });
  }
}
