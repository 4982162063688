import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var VersionService = /** @class */ (function () {
    function VersionService(http) {
        this.http = http;
    }
    VersionService.prototype.getVersion = function () {
        var _this = this;
        return this.http.get('./version.json').pipe(map(function (res) { return _this.version = res; }));
    };
    VersionService.ngInjectableDef = i0.defineInjectable({ factory: function VersionService_Factory() { return new VersionService(i0.inject(i1.HttpClient)); }, token: VersionService, providedIn: "root" });
    return VersionService;
}());
export { VersionService };
