import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
  MatSnackBar,
  MatStepper,
} from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { Observable, of, zip } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { FuseTranslationLoaderService } from "../../../../../../@fuse/services/translation-loader.service";
import { AuthService } from "../../../../../services/auth.service";
import { FilteredListsService } from "../../../../../services/filtered-lists.service";
import { OrdersService } from "../../../../../services/orders.service";
import { TasksService } from "../../../../../services/tasks.service";
import { UsersService } from "../../../../../services/users.service";
import { Mode } from "../../../../../shared/consts/mode";
import { OrderTypes } from "../../../../../shared/consts/orders";
import {
  EndTimeOptions,
  StartTimeOptions,
} from "../../../../../shared/consts/tasks";
import { CompanyBranch } from "../../../../../shared/models/company.model";
import {
  FilteredCompany,
  FilteredTransport,
} from "../../../../../shared/models/filtered-items.model";
import { ItemsOrigins } from "../../../../../shared/models/history.model";
import { RequestParams } from "../../../../../shared/models/list.model";
import { OrderImport } from "../../../../../shared/models/orders.model";
import {
  OrderImportSubform,
  TaskImport,
  TaskStatuses,
  TaskTypes,
} from "../../../../../shared/models/tasks.model";
import { User, UserTypes } from "../../../../../shared/models/users.model";
import { getDaysInMonth } from "../../../../../shared/utils/custom-date-formatter";
import { markFormGroupTouched } from "../../../../../shared/utils/markFormGroupAsTouched";
import {
  mapDatetimeToUtc,
  mapOrderDataApiValues,
  mapOrderDataToUuid,
} from "../../../../../shared/utils/tasks-map";
import { ValidateItemsList } from "../../../../../shared/validators/itemsList.validator";
import { StartEndDateValidator } from "../../../../../shared/validators/startEndDate.validator";
import { locale as english } from "../../../../../translations/en";
import { locale as polish } from "../../../../../translations/pl";
import { CompanyEditorComponent } from "../../../../companies/components/company-editor/company-editor.component";
import { ConfirmModalComponent } from "../../../../shared/components/confirm-modal/confirm-modal.component";
import { HistoryDialogComponent } from "../../../../shared/components/history-dialog/history-dialog.component";

@Component({
  selector: "app-task-import-form",
  templateUrl: "./task-import-form.component.html",
  styleUrls: ["./task-import-form.component.scss"],
})
export class TaskImportFormComponent implements OnInit {
  @ViewChild("companySelect") companySelect;
  @ViewChild("stepper") stepper: MatStepper;
  isExpanded: boolean[] = [];
  public indexStep: number;
  public taskImportForm: FormGroup;
  public taskId: TaskImport["uuid"] | TaskImport["taskNumber"];
  public task: TaskImport;
  public taskStatuses = TaskStatuses;
  public taskTypes = TaskTypes;
  public user: User;
  public userTypes = UserTypes;
  public transportOptions: FilteredTransport[] = [];
  public customerCompanyOptions: FilteredCompany[] = [];
  public companyBranches: CompanyBranch[] = [];
  public ordersOptions: OrderImport[] = [];
  public ordersOptionsInitial: string[] = [];
  public ordersOptionsRemoved: OrderImport[] = [];
  public startTimeOptions = StartTimeOptions;
  public endTimeOptions = EndTimeOptions;
  public mode: Mode;
  public isLoading = false;
  public searchCustomerCompany: FormControl;
  public searchTransport: FormControl;
  public searchOrders: FormControl;
  public todayDate: Date;
  public rangeDateEndMin: Date;
  public rangeDateEndMax: Date;
  private filterParams: RequestParams = {
    page: 1,
    limit: 20,
    sort: "name",
    order: "asc",
  };
  private filterParamsFullList: RequestParams = {
    page: 1,
    limit: 1000,
    sort: "name",
    order: "asc",
    active: "true",
  };
  private filterOrdersParams: RequestParams = {
    page: 1,
    limit: 20,
    sort: "createdDate",
    order: "asc",
    available: true,
    orderType: this.taskTypes.Import,
    task: false,
    salesAccepted: true,
  };

  constructor(
    public taskEditorDialogRef: MatDialogRef<TaskImportFormComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private matDialog: MatDialog,
    private authService: AuthService,
    private tasksService: TasksService,
    private ordersService: OrdersService,
    private filteredListsService: FilteredListsService,
    private usersService: UsersService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService
  ) {
    this.user = this.authService.user.user;
    this.mode = data.mode;
    this.taskId = data.taskId || data.taskNumber;
    this._fuseTranslationLoaderService.loadTranslations(polish, english);
    this.indexStep = data.indexStep || 0;
  }

  ngOnInit() {
    this.createForm();
    this.createSearchFilters();

    if (this.mode === "edit" || this.mode === "view" || this.mode === "clone") {
      this.tasksService
        .getTask(this.taskId)
        .pipe(
          map((res) => {
            this.task = res;
            this.taskId = res.uuid;
            res.taskData.forEach((item) => {
              if (item.order && item.order.uuid) {
                this.ordersOptionsInitial.push(item.order.uuid);
              }
            });
            this.createForm(this.task);
          }),
          switchMap(() => {
            return zip(
              this.filteredListsService.getTransportList(
                this.filterParamsFullList
              ),
              this.filteredListsService.getCompaniesList(this.filterParams),
              this.usersService.getCompanyBranchList(),
              this.filteredListsService.getOrdersList(this.filterOrdersParams)
            );
          })
        )
        .subscribe(([transportRes, companiesRes, branchesRes, ordersRes]) => {
          this.transportOptions = transportRes.transports;
          this.customerCompanyOptions = companiesRes.customerCompanies;
          this.companyBranches = branchesRes.branches;
          this.ordersOptions = <OrderImport[]>ordersRes.orders;
        });
    } else {
      this.todayDate = new Date();
      zip(
        this.filteredListsService.getTransportList(this.filterParamsFullList),
        this.filteredListsService.getCompaniesList(this.filterParams),
        this.filteredListsService.getOrdersList(this.filterOrdersParams),
        this.usersService.getCompanyBranchList()
      ).subscribe(([transportRes, companiesRes, ordersRes, branchesRes]) => {
        this.transportOptions = transportRes.transports;
        this.customerCompanyOptions = companiesRes.customerCompanies;
        this.ordersOptions = <OrderImport[]>ordersRes.orders;
        this.companyBranches = branchesRes.branches;

        if (this.data.orders && this.data.orders.length) {
          this.createForm(this.data.orders[0]);
          this.data.orders.forEach((item, index) => {
            this.addOrderSubform(index, item, true);
          });
        }
      });
    }
  }

  private setRangeEndDateMax(date: Date) {
    this.rangeDateEndMin = new Date(
      this.taskImportForm.get("startDate").value || this.todayDate
    );
    this.rangeDateEndMax = new Date(date || this.todayDate);
    this.rangeDateEndMax.setMonth(
      this.rangeDateEndMax.getMonth() !== 11
        ? this.rangeDateEndMax.getMonth() + 1
        : 0
    );
    if (this.rangeDateEndMax.getMonth() === 0) {
      this.rangeDateEndMax.setFullYear(this.rangeDateEndMax.getFullYear() + 1);
    }
    this.rangeDateEndMax.setDate(
      getDaysInMonth(
        this.rangeDateEndMax.getFullYear(),
        this.rangeDateEndMax.getMonth()
      )
    );
  }

  public submitTask() {
    this.isLoading = true;
    markFormGroupTouched(this.taskImportForm);
    if (this.taskImportForm.invalid) {
      this.isLoading = false;
      return;
    }
    const taskForm = this.taskImportForm.getRawValue();
    this.createNewOrders(taskForm.taskData, taskForm.companyBranchId).subscribe(
      (taskDataFromOther) => {
        const taskData = taskForm.taskData.filter(
          (item) => item.uuid !== "other"
        );
        taskDataFromOther.forEach((item) => {
          taskData.push(item);
        });
        const newTask = {
          name: taskForm.name,
          companyBranchId: taskForm.companyBranchId,
          transportId: taskForm.taskData[0]
            ? taskForm.taskData[0].transport.uuid
            : null,
          startDate: mapDatetimeToUtc(taskForm.startDate, taskForm.startTime),
          endDate: mapDatetimeToUtc(taskForm.endDate, taskForm.endTime),
          taskType: taskForm.taskType,
          comment: taskForm.comment,
          referenceNumber: taskForm.referenceNumber,
          emailService: taskForm.emailService,
          registrationNumFront: taskForm.registrationNumFront,
          registrationNumBack: taskForm.registrationNumBack,
          taskData: this.mapTaskData(taskData),
        };

        if (this.mode === "add" || this.mode === "clone") {
          this.todayDate = new Date();
          this.createTask(newTask);
        } else {
          const ordersToDelete = [];
          const oldOrdersIds =
            this.task && this.task.taskData
              ? this.task.taskData.map((item) => {
                  if (item.order && item.order.uuid) {
                    return item.order.uuid;
                  }
                })
              : [];
          const newOrdersIds = newTask.taskData
            ? newTask.taskData.map((item) => {
                if (item.orderId) {
                  return item.orderId;
                }
              })
            : [];

          oldOrdersIds.forEach((oldItem) => {
            if (oldItem && !newOrdersIds.includes(oldItem)) {
              ordersToDelete.push(oldItem);
            }
          });

          zip(
            this.tasksService.updateTask(this.taskId, newTask),
            this.tasksService.updateTaskOrders(this.taskId, ordersToDelete)
          ).subscribe(
            () => {
              this.taskEditorDialogRef.close();
              this.taskEditorDialogRef.afterClosed().subscribe(() => {
                this.tasksService.onEditEvent.emit();
                this.ordersService.onEditEvent.emit();
              });
              this.snackBar.open(
                this.translateService.instant("TASKS.MESSAGES.UPDATE_SUCCESS"),
                "",
                { duration: 5000 }
              );
            },
            () => {
              this.isLoading = false;
              this.snackBar.open(
                this.translateService.instant("TASKS.MESSAGES.UPDATE_ERROR"),
                "",
                { duration: 5000 }
              );
            }
          );
        }
      }
    );
  }

  private createTask(newTask: any) {
    const taskForm = this.taskImportForm.getRawValue();
    if (taskForm.recurring) {
      const recurringTask = {
        intervalType: "WEEK",
        intervalValue: taskForm.recurringInfo.intervalValue,
        rangeDateEnd: moment(taskForm.recurringInfo.rangeDateEnd).format(
          "YYYY-MM-DD"
        ),
        weekdays: taskForm.recurringInfo.weekdays,
        task: newTask,
      };
      this.tasksService.createRecurringTask(recurringTask).subscribe(
        () => {
          this.taskEditorDialogRef.close();
          this.taskEditorDialogRef.afterClosed().subscribe(() => {
            this.tasksService.onEditEvent.emit();
            this.ordersService.onEditEvent.emit();
          });
          this.snackBar.open(
            this.translateService.instant("TASKS.MESSAGES.CREATE_SUCCESS"),
            "",
            { duration: 5000 }
          );
        },
        () => {
          this.isLoading = false;
          this.snackBar.open(
            this.translateService.instant("TASKS.MESSAGES.CREATE_ERROR"),
            "",
            { duration: 5000 }
          );
        }
      );
    } else {
      this.tasksService.createTask(newTask).subscribe(
        () => {
          this.taskEditorDialogRef.close();
          this.taskEditorDialogRef.afterClosed().subscribe(() => {
            this.tasksService.onEditEvent.emit();
            this.ordersService.onEditEvent.emit();
          });
          this.snackBar.open(
            this.translateService.instant("TASKS.MESSAGES.CREATE_SUCCESS"),
            "",
            { duration: 5000 }
          );
        },
        () => {
          this.isLoading = false;
          this.snackBar.open(
            this.translateService.instant("TASKS.MESSAGES.CREATE_ERROR"),
            "",
            { duration: 5000 }
          );
        }
      );
    }
  }

  public deleteTask() {
    const deleteDialogRef = this.matDialog.open(ConfirmModalComponent, {
      disableClose: false,
      autoFocus: false,
    });
    deleteDialogRef.componentInstance.confirmMessage =
      this.translateService.instant("TASKS.MESSAGES.DELETE_QUESTION");
    deleteDialogRef.componentInstance.titleMessage =
      this.translateService.instant("TASKS.MESSAGES.DELETE_TASK_TITLE");
    deleteDialogRef.componentInstance.confirmButton =
      this.translateService.instant("TASKS.DELETE");

    deleteDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const ordersToDelete = [];
        this.task &&
          this.task.taskData &&
          this.task.taskData.forEach((item) => {
            if (item.order && item.order.uuid) {
              ordersToDelete.push(item.order.uuid);
            }
          });

        zip(
          this.tasksService.deleteTask(this.taskId),
          this.tasksService.updateTaskOrders(this.taskId, ordersToDelete)
        ).subscribe(
          () => {
            this.taskEditorDialogRef.close();
            this.taskEditorDialogRef.afterClosed().subscribe(() => {
              this.tasksService.onEditEvent.emit();
              this.ordersService.onEditEvent.emit();
            });
            this.snackBar.open(
              this.translateService.instant("TASKS.MESSAGES.DELETE_SUCCESS"),
              "",
              { duration: 5000 }
            );
          },
          () => {
            this.snackBar.open(
              this.translateService.instant("TASKS.MESSAGES.DELETE_ERROR"),
              "",
              { duration: 5000 }
            );
          }
        );
      }
    });
  }

  public cancelTask() {
    const cancelDialogRef = this.matDialog.open(ConfirmModalComponent, {
      disableClose: false,
      autoFocus: false,
    });
    cancelDialogRef.componentInstance.confirmMessage =
      this.translateService.instant("TASKS.MESSAGES.CANCEL_QUESTION");
    cancelDialogRef.componentInstance.titleMessage =
      this.translateService.instant("TASKS.MESSAGES.CANCEL_TASK_TITLE");
    cancelDialogRef.componentInstance.confirmButton =
      this.translateService.instant("TASKS.CANCEL_TASK");
    cancelDialogRef.componentInstance.skipButton =
      this.translateService.instant("TASKS.CANCEL_SKIP");

    cancelDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.tasksService.cancelTask(this.taskId).subscribe(
          () => {
            this.taskEditorDialogRef.close();
            this.tasksService.onEditEvent.emit();
            this.ordersService.onEditEvent.emit();
            this.snackBar.open(
              this.translateService.instant("TASKS.MESSAGES.CANCEL_SUCCESS"),
              "",
              { duration: 5000 }
            );
          },
          () => {
            this.snackBar.open(
              this.translateService.instant("TASKS.MESSAGES.CANCEL_ERROR"),
              "",
              { duration: 5000 }
            );
          }
        );
      }
    });
  }

  public completeTask() {
    const completeDialogRef = this.matDialog.open(ConfirmModalComponent, {
      disableClose: false,
      autoFocus: false,
    });
    completeDialogRef.componentInstance.confirmMessage =
      this.translateService.instant("TASKS.MESSAGES.COMPLETE_QUESTION");
    completeDialogRef.componentInstance.titleMessage =
      this.translateService.instant("TASKS.MESSAGES.COMPLETE_TASK_TITLE");
    completeDialogRef.componentInstance.confirmButton =
      this.translateService.instant("TASKS.COMPLETE_TASK");
    completeDialogRef.componentInstance.skipButton =
      this.translateService.instant("TASKS.COMPLETE_SKIP");

    completeDialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.tasksService.completeTask(this.taskId).subscribe(
          () => {
            this.taskEditorDialogRef.close();
            this.tasksService.onEditEvent.emit();
            this.ordersService.onEditEvent.emit();
            this.snackBar.open(
              this.translateService.instant("TASKS.MESSAGES.COMPLETE_SUCCESS"),
              "",
              { duration: 5000 }
            );
          },
          () => {
            this.snackBar.open(
              this.translateService.instant("TASKS.MESSAGES.COMPLETE_ERROR"),
              "",
              { duration: 5000 }
            );
          }
        );
      }
    });
  }

  public setStartDate(newDate) {
    this.taskImportForm.patchValue({ endDate: new Date(newDate) });
    this.setRangeEndDateMax(newDate);
    this.handleRecurringChange();
  }

  public handleRecurringChange() {
    const weekdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const date = this.taskImportForm.get("startDate").value || new Date();

    this.taskImportForm.get("recurringInfo").get("weekdays").patchValue({
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
      Sunday: false,
    });
    this.taskImportForm
      .get("recurringInfo")
      .get("weekdays")
      .patchValue({
        [weekdays[moment(date).day()]]: true,
      });
  }

  public addOrderSubform(
    index: number,
    orderItem: OrderImportSubform = new OrderImportSubform(),
    generatedFromOrderId: boolean = false
  ) {
    Object.keys(this.isExpanded).forEach(
      (item) => (this.isExpanded[item] = false)
    );
    this.isExpanded.push(!orderItem.orderData);
    orderItem.orderData = mapOrderDataToUuid(orderItem.orderData);
    this.orderFilter();
    const control = <FormArray>this.taskImportForm.controls.taskData;
    control.push(
      this.formBuilder.group({
        uuid: new FormControl(
          this.mode === "clone"
            ? "other"
            : (generatedFromOrderId && orderItem && orderItem.uuid) ||
              (orderItem.order && orderItem.order.uuid) ||
              orderItem.orderId ||
              "other"
        ),
        order: new FormControl(
          this.mode === "clone"
            ? { uuid: "other" }
            : (generatedFromOrderId && orderItem) ||
              orderItem.order || { uuid: "other" }
        ),
        customerCompany: new FormControl(
          orderItem.customerCompany || null,
          Validators.required
        ),
        transport: new FormControl(
          (orderItem && orderItem.transport) ||
            (this.task && this.task.transport) ||
            null,
          Validators.required
        ),
        registrationNumFront: new FormControl(
          orderItem.registrationNumFront || null
        ),
        registrationNumBack: new FormControl(
          orderItem.registrationNumBack || null
        ),
        referenceNumber: new FormControl(
          this.mode === "clone" ? null : orderItem.referenceNumber || null
        ),
        bdo: new FormControl(
          orderItem.bdo ? orderItem.bdo : null,
          Validators.required
        ),
        bdoFree: new FormControl(orderItem.bdoFree),
        bdoPeopleCollection: new FormControl(orderItem.bdoPeopleCollection),
        kpoEmails: new FormControl(
          orderItem.kpoEmails ||
            (orderItem.order && orderItem.order.kpoEmails) ||
            null
        ),
        reinvoice: new FormControl(orderItem.reinvoice || false),
        price: new FormControl(
          orderItem.price ? String(orderItem.price).replace(".", ",") : ""
        ),
        reinvoiceNumber: new FormControl(orderItem.reinvoiceNumber || ""),
        invoiceComment: new FormControl(orderItem.invoiceComment || ""),
        salesAccepted: new FormControl(
          (orderItem.order && orderItem.order.salesAccepted) ||
            orderItem.salesAccepted ||
            false,
          Validators.required
        ),
        companyBranchId: new FormControl(
          (this.task &&
            this.task.companyBranch &&
            this.task.companyBranch.uuid) ||
            (orderItem.companyBranch && orderItem.companyBranch.uuid) ||
            null,
          Validators.required
        ),
        orderData: new FormControl(orderItem.orderData || [], [
          Validators.required,
          ValidateItemsList,
        ]),
        suggestedDate: new FormControl(
          this.mode === "clone"
            ? null
            : (orderItem.order && orderItem.order.suggestedDate) ||
              orderItem.suggestedDate ||
              null,
          Validators.required
        ),
        order_comment: new FormControl(
          orderItem.comment ||
            (orderItem.order && orderItem.order.comment) ||
            null
        ),
      })
    );
    generatedFromOrderId && control.at(index).get("order").disable();
    generatedFromOrderId &&
      setTimeout(() => this.taskImportForm.get("companyBranchId").disable(), 0);
    this.updateBdo(orderItem.bdo, index);
    control.at(index).get("companyBranchId").disable();
    control.at(index).get("salesAccepted").disable();

    if (control.at(index).get("reinvoice").value === false) {
      control.at(index).get("price").disable();
      control.at(index).get("reinvoiceNumber").disable();
    }
  }

  public deleteOrderSubform(index: number) {
    this.isExpanded.splice(index, 1);
    const control = <FormArray>this.taskImportForm.controls.taskData;
    const order = control.value[index];
    if (
      order.uuid !== "other" &&
      this.ordersOptionsInitial.includes(order.uuid)
    ) {
      this.ordersService.getOrder(order.uuid).subscribe((res) => {
        this.ordersOptionsRemoved.push(res);
      });
    }
    control.removeAt(index);
  }

  public pickOrder(order: OrderImport, index: number) {
    const control = <FormArray>this.taskImportForm.controls.taskData;
    const previousOrder = control.value[index];

    order.orderData = mapOrderDataToUuid(order.orderData);

    if (order.uuid === "other") {
      control.controls[index].reset();
      control.controls[index].patchValue({
        uuid: "other",
        order: { uuid: "other" },
        orderData: [],
      });
    } else {
      control.controls[index].patchValue({
        uuid: order.uuid || "other",
        order: order || { uuid: "other" },
        customerCompany: order.customerCompany || null,
        transport: order.transport || null,
        registrationNumFront: order.registrationNumFront || null,
        registrationNumBack: order.registrationNumBack || null,
        referenceNumber: order.referenceNumber || null,
        bdo: order.bdo || null,
        bdoFree: order.bdoFree,
        bdoPeopleCollection: order.bdoPeopleCollection,
        kpoEmails: order.kpoEmails,
        reinvoice: order.reinvoice,
        price: order.price ? String(order.price).replace(".", ",") : "",
        reinvoiceNumber: order.reinvoiceNumber,
        invoiceComment: order.invoiceComment,
        salesAccepted: order.salesAccepted,
        companyBranchId: order.companyBranch.uuid,
        suggestedDate: order.suggestedDate,
        orderData: order.orderData || [],
        order_comment: order.comment || null,
      });
    }

    if (order.uuid !== "other") {
      this.ordersOptionsRemoved = this.ordersOptionsRemoved.filter(
        (item) => item.uuid !== order.uuid
      );
    }
    if (
      previousOrder &&
      this.ordersOptionsInitial.includes(previousOrder.uuid)
    ) {
      this.ordersService.getOrder(previousOrder.uuid).subscribe((res) => {
        this.ordersOptionsRemoved.push(res);
      });
    }

    this.updateBdo(order.bdo, index);
  }

  public onChangeCustomerCompany(company: FilteredCompany, index: number) {
    const control = <FormArray>this.taskImportForm.controls.taskData;
    if (this.taskImportForm.get("taskData").value[index].uuid !== "other") {
      control.controls[index].patchValue({
        customerCompany: company,
        kpoEmails: company.kpo.map((kpo) => kpo.email).join(","),
        uuid: "other",
        order: { uuid: "other" },
        recyclingItems: [],
        packageTypes: [],
        registrationNumFront: null,
        registrationNumBack: null,
      });
    } else {
      control.controls[index].patchValue({ customerCompany: company });
    }

    this.updateBdo(company.bdo, index, true);
  }

  public compareObjectsByUuid(val1, val2): boolean {
    return val1 && val2 && val1.uuid && val2.uuid && val1.uuid === val2.uuid;
  }

  public addCustomerCompany(index: number) {
    const editorDialogRef = this.matDialog.open(CompanyEditorComponent, {
      panelClass: "company-editor-dialog",
      autoFocus: false,
      disableClose: true,
      data: {
        mode: "add",
      },
    });

    editorDialogRef.componentInstance.onAddCompany.subscribe((res) => {
      this.customerCompanyOptions.push(res);
      <FormArray>this.taskImportForm.controls.taskData["controls"][
        index
      ].patchValue({
        customerCompany: res,
      });
      this.companySelect.close();
    });
  }

  public getTitle(): string {
    switch (this.mode) {
      case "add":
      case "clone":
        return this.translateService.instant("TASKS.TITLE_ADD");
      case "edit":
        return this.translateService.instant("TASKS.TITLE_EDIT");
      case "view":
        return this.translateService.instant("TASKS.TITLE_VIEW");
      default:
        return;
    }
  }

  public showHistory() {
    this.matDialog.open(HistoryDialogComponent, {
      panelClass: "history-dialog",
      autoFocus: false,
      disableClose: true,
      data: {
        item: this.task,
        itemOrigin: ItemsOrigins.Task,
        itemId: this.taskId,
        itemNumber: this.task.taskNumber,
      },
    });
  }

  public enableEditMode() {
    this.indexStep = this.stepper.selectedIndex;
    this.taskEditorDialogRef.close();
    this.matDialog.open(TaskImportFormComponent, {
      panelClass: "task-editor-dialog",
      autoFocus: false,
      disableClose: true,
      data: {
        mode: "edit",
        taskId: this.taskId,
        indexStep: this.indexStep,
      },
    });
  }

  public enableCloneMode() {
    this.indexStep = this.stepper.selectedIndex;
    this.taskEditorDialogRef.close();
    this.matDialog.open(TaskImportFormComponent, {
      panelClass: "task-editor-dialog",
      autoFocus: false,
      disableClose: true,
      data: {
        mode: "clone",
        taskId: this.taskId,
        indexStep: this.indexStep,
      },
    });
  }

  public getOrdersOptions(index: number) {
    const options = [...this.ordersOptions, ...this.ordersOptionsRemoved];
    const selectedOrder = this.taskImportForm.get("taskData").value[index];
    this.taskImportForm.get("taskData").value &&
      this.taskImportForm.get("taskData").value.forEach((chosenOrder) => {
        if (
          chosenOrder.uuid &&
          chosenOrder.uuid !== "other" &&
          options.findIndex((item) => item.uuid === chosenOrder.uuid) > -1 &&
          chosenOrder.uuid !== selectedOrder.uuid
        ) {
          options.splice(
            options.findIndex((item) => item.uuid === chosenOrder.uuid),
            1
          );
        }
      });
    if (
      selectedOrder.uuid &&
      options.findIndex((order) => order.uuid === selectedOrder.uuid) === -1 &&
      selectedOrder.uuid !== "other"
    ) {
      options.push(selectedOrder.order || selectedOrder);
    }
    return options;
  }

  public getCustomerCompaniesOptions(index: number) {
    const options = [...this.customerCompanyOptions];
    const selectedOrder = this.taskImportForm.get("taskData").value[index];
    if (
      selectedOrder.customerCompany &&
      selectedOrder.customerCompany.uuid &&
      options.findIndex(
        (item) => item.uuid === selectedOrder.customerCompany.uuid
      ) === -1
    ) {
      options.push(selectedOrder.customerCompany);
    }
    return options;
  }

  public getTransportOptions(index: number) {
    const options = [...this.transportOptions];
    const selectedOrder = this.taskImportForm.get("taskData").value[index];
    if (
      selectedOrder.transport &&
      selectedOrder.transport.uuid &&
      options.findIndex(
        (item) => item.uuid === selectedOrder.transport.uuid
      ) === -1
    ) {
      options.push(selectedOrder.transport);
    }
    return options;
  }

  public reinvoiceChange(reinvoiceChecked: boolean, orderIndex: number) {
    const control = <FormArray>this.taskImportForm.controls.taskData;
    if (reinvoiceChecked) {
      control.controls[orderIndex].get("price").enable();
      control.controls[orderIndex].get("reinvoiceNumber").enable();
    } else {
      control.controls[orderIndex].get("price").setValue(null);
      control.controls[orderIndex].get("price").disable();
      control.controls[orderIndex].get("reinvoiceNumber").setValue("");
      control.controls[orderIndex].get("reinvoiceNumber").disable();
    }
  }

  public bdoRequiredToggle(bdoFree: boolean, orderIndex: number) {
    let control = <FormArray>this.taskImportForm.controls.taskData;
    const pickedOrder = control.controls[orderIndex].get("order").value;
    const pickedCustomerCompany =
      control.controls[orderIndex].get("customerCompany").value;
    const defaultBdo =
      (pickedOrder && pickedOrder.bdo) ||
      (pickedCustomerCompany && pickedCustomerCompany.bdo) ||
      null;

    if (bdoFree) {
      control.controls[orderIndex].get("bdo").clearValidators();
      control.controls[orderIndex].get("bdo").disable();
      control.controls[orderIndex].get("bdo").setValue("");
      control.controls[orderIndex].get("bdoPeopleCollection").setValue(false);
    } else if (!bdoFree && defaultBdo) {
      control.controls[orderIndex]
        .get("bdo")
        .setValidators(Validators.required);
      control.controls[orderIndex].get("bdo").disable();
      control.controls[orderIndex].get("bdo").setValue(defaultBdo);
    } else if (!bdoFree && !defaultBdo) {
      control.controls[orderIndex]
        .get("bdo")
        .setValidators(Validators.required);
      control.controls[orderIndex].get("bdo").enable();
    }
  }

  public bdoPeopleCollectionToggle(
    bdoPeopleCollection: boolean,
    orderIndex: number
  ) {
    const control = <FormArray>this.taskImportForm.controls.taskData;
    const pickedOrder = control.controls[orderIndex].get("order").value;
    const pickedCustomerCompany =
      control.controls[orderIndex].get("customerCompany").value;
    const defaultBdo =
      (pickedOrder && pickedOrder.bdo) ||
      (pickedCustomerCompany && pickedCustomerCompany.bdo) ||
      null;
    if (bdoPeopleCollection) {
      control.controls[orderIndex].get("bdo").clearValidators();
      control.controls[orderIndex].get("bdo").disable();
      control.controls[orderIndex].get("bdo").setValue("");
      control.controls[orderIndex].get("bdoFree").setValue(false);
    } else if (!bdoPeopleCollection && defaultBdo) {
      control.controls[orderIndex]
        .get("bdo")
        .setValidators(Validators.required);
      control.controls[orderIndex].get("bdo").disable();
      control.controls[orderIndex].get("bdo").setValue(defaultBdo);
    } else if (!bdoPeopleCollection && !defaultBdo) {
      control.controls[orderIndex]
        .get("bdo")
        .setValidators(Validators.required);
      control.controls[orderIndex].get("bdo").enable();
    }
  }

  private updateBdo(
    bdo: string,
    orderIndex: number,
    enableCheckbox: boolean = false
  ) {
    let control = <FormArray>this.taskImportForm.controls.taskData;

    if (enableCheckbox) {
      control.controls[orderIndex].get("bdoFree").enable();
      control.controls[orderIndex].get("bdoPeopleCollection").enable();
    }

    const bdoFree = control.controls[orderIndex].get("bdoFree").value;
    const bdoPeopleCollection = control.controls[orderIndex].get(
      "bdoPeopleCollection"
    ).value;
    control.controls[orderIndex].get("bdo").clearValidators();
    if (bdo && !bdoFree && !bdoPeopleCollection) {
      control.controls[orderIndex].get("bdo").setValue(bdo);
      control.controls[orderIndex].get("bdo").disable();
    } else if ((!bdo && !bdoFree) || !bdoPeopleCollection) {
      control.controls[orderIndex].get("bdo").setValue("");
      control.controls[orderIndex].get("bdo").enable();
    } else if (!bdo && (bdoFree || bdoPeopleCollection)) {
      control.controls[orderIndex].get("bdo").setValue("");
      control.controls[orderIndex].get("bdo").disable();
    }
  }

  private startEndDateTimezoneValid(start, end) {
    if (this.mode === "view") {
      //we need to display current values even if they are wrong in `view` mode
      if (start && !this.startTimeOptions.includes(start)) {
        this.startTimeOptions = [start, ...this.startTimeOptions];
      }
      if (end && !this.endTimeOptions.includes(end)) {
        this.endTimeOptions = [end, ...this.endTimeOptions];
      }
      return true;
    } else {
      // if at least one of current values is wrong we clear fields
      if (start && !this.startTimeOptions.includes(start)) {
        return false;
      }
      if (end && !this.endTimeOptions.includes(end)) {
        return false;
      }
      return true;
    }
  }

  private createForm(inputData: TaskImport = new TaskImport()) {
    inputData.startTime = inputData.startDate
      ? moment(inputData.startDate).format("HH:mm")
      : null;
    inputData.endTime = inputData.endDate
      ? moment(inputData.endDate).format("HH:mm")
      : null;
    if (
      !this.startEndDateTimezoneValid(inputData.startTime, inputData.endTime)
    ) {
      inputData.startTime = null;
      inputData.endTime = null;
    }
    this.taskImportForm = this.formBuilder.group(
      {
        name: new FormControl(inputData.name || null, Validators.required),
        companyBranchId: new FormControl(
          (inputData.companyBranch && inputData.companyBranch.uuid) ||
            this.data.companyBranchId ||
            null,
          Validators.required
        ),
        startDate: new FormControl(
          this.mode === "clone"
            ? null
            : inputData.startDate || this.data.startDate || null,
          Validators.required
        ),
        startTime: new FormControl(
          inputData.startTime || this.data.startTime || null,
          Validators.required
        ),
        endDate: new FormControl(
          this.mode === "clone"
            ? null
            : inputData.endDate || this.data.endDate || null,
          Validators.required
        ),
        endTime: new FormControl(
          inputData.endTime || this.data.endTime || null,
          Validators.required
        ),
        taskType: new FormControl(
          inputData.taskType || this.taskTypes.Import,
          Validators.required
        ),
        comment: new FormControl(
          this.mode === "add" || this.mode === "clone"
            ? null
            : inputData.comment || null
        ),
        referenceNumber: new FormControl(
          this.mode === "add" || this.mode === "clone"
            ? null
            : inputData.referenceNumber || null
        ),
        emailService: new FormControl(
          this.mode === "add" || this.mode === "clone"
            ? true
            : inputData.emailService
        ),
        registrationNumFront: new FormControl(
          this.mode === "add" ? null : inputData.registrationNumFront || null
        ),
        registrationNumBack: new FormControl(
          this.mode === "add" ? null : inputData.registrationNumBack || null
        ),
        taskData: this.formBuilder.array([], Validators.required),
        recurring: new FormControl(false),
        recurringInfo: this.formBuilder.group({
          intervalType: new FormControl("WEEK"),
          intervalValue: new FormControl(1, Validators.pattern(/\d/)),
          rangeDateEnd: new FormControl(inputData.startDate),
          weekdays: new FormGroup({
            Monday: new FormControl(false),
            Tuesday: new FormControl(false),
            Wednesday: new FormControl(false),
            Thursday: new FormControl(false),
            Friday: new FormControl(false),
            Saturday: new FormControl(false),
            Sunday: new FormControl(false),
          }),
        }),
      },
      {
        validator: StartEndDateValidator.CheckDates,
      }
    );
    inputData.taskData &&
      inputData.taskData.forEach((item, index) =>
        this.addOrderSubform(index, item)
      );
    this.setRangeEndDateMax(this.taskImportForm.get("startDate").value);

    if (this.mode === "add" || this.mode === "clone") {
      this.taskImportForm.get("companyBranchId").disable();
    }

    if (this.mode === "view") {
      this.taskImportForm.disable();
    }
  }

  private createSearchFilters() {
    this.searchCustomerCompany = new FormControl("");
    this.searchTransport = new FormControl("");
    this.searchOrders = new FormControl("");

    this.searchCustomerCompany.valueChanges
      .debounceTime(300)
      .subscribe((value) => {
        this.filteredListsService
          .getCompaniesList({ ...this.filterParams, ...{ search: value } })
          .subscribe((response) => {
            this.customerCompanyOptions = response.customerCompanies;
          });
      });
    this.searchTransport.valueChanges.debounceTime(300).subscribe((value) => {
      this.filteredListsService
        .getTransportList({ ...this.filterParamsFullList, ...{ name: value } })
        .subscribe((response) => {
          this.transportOptions = response.transports;
        });
    });
    this.searchOrders.valueChanges.debounceTime(300).subscribe((value) => {
      this.filteredListsService
        .getOrdersList({ ...this.filterOrdersParams, ...{ search: value } })
        .subscribe((response) => {
          this.ordersOptions = <OrderImport[]>response.orders;
          this.ordersOptions = this.ordersOptions.filter(
            (order) =>
              order.companyBranch === null ||
              (order.companyBranch &&
                order.companyBranch.uuid ===
                  this.taskImportForm.getRawValue().companyBranchId)
          );
        });
    });
  }

  public orderFilter() {
    this.filteredListsService
      .getOrdersList({ ...this.filterOrdersParams })
      .subscribe((response) => {
        this.ordersOptions = <OrderImport[]>response.orders;
        this.ordersOptions = this.ordersOptions.filter(
          (order) =>
            order.companyBranch === null ||
            (order.companyBranch &&
              order.companyBranch.uuid ===
                this.taskImportForm.getRawValue().companyBranchId)
        );
      });
  }

  private createNewOrders(
    taskData: OrderImportSubform[],
    formCompanyBranchId: string
  ): Observable<OrderImportSubform[]> {
    const taskDataWithOtherType = taskData.filter(
      (task) => task.uuid === "other"
    );
    if (!taskDataWithOtherType.length) {
      return of([]);
    }
    let tmpCompanyBranchId;
    if (this.data.companyBranchId) {
      tmpCompanyBranchId = this.data.companyBranchId;
    } else if (
      this.task &&
      this.task.companyBranch &&
      this.task.companyBranch.uuid
    ) {
      tmpCompanyBranchId = this.task.companyBranch.uuid;
    } else if (this.task && this.task.companyBranchId) {
      tmpCompanyBranchId = this.task.companyBranchId;
    } else {
      tmpCompanyBranchId = formCompanyBranchId;
    }
    const observables = taskDataWithOtherType.map((item) => {
      const newOrder = {
        companyBranchId: tmpCompanyBranchId,
        customerCompanyId: item.customerCompany.uuid,
        salesAccepted: item.salesAccepted,
        bdo: item.bdo ? item.bdo.toString() : null,
        bdoFree: Boolean(item.bdoFree && !item.bdo),
        bdoPeopleCollection: Boolean(item.bdoPeopleCollection && !item.bdo),
        kpoEmails: item.kpoEmails,
        reinvoice: item.reinvoice,
        price: Number(String(item.price).replace(",", ".")),
        reinvoiceNumber: item.reinvoiceNumber,
        invoiceComment: item.invoiceComment,
        transportId: item.transport.uuid,
        suggestedDate:
          moment(item.suggestedDate).format("YYYY-MM-DD") ||
          moment(this.taskImportForm.get("startDate").value).format(
            "YYYY-MM-DD"
          ) ||
          moment().format("YYYY-MM-DD"),
        referenceNumber: item.referenceNumber,
        registrationNumFront: item.registrationNumFront,
        registrationNumBack: item.registrationNumBack,
        orderData: mapOrderDataApiValues(item.orderData),
        orderType: OrderTypes.Import,
      };
      return this.ordersService.createOrder(newOrder).pipe(
        map((resOrder: OrderImport) => {
          return {
            customerCompanyId: resOrder.customerCompany
              ? resOrder.customerCompany.uuid
              : null,
            customerCompany: resOrder.customerCompany,
            bdo: resOrder.bdo ? resOrder.bdo.toString() : null,
            bdoFree: resOrder.bdoFree && !resOrder.bdo,
            bdoPeopleCollection: resOrder.bdoPeopleCollection && !resOrder.bdo,
            kpoEmails: resOrder.kpoEmails,
            reinvoice: resOrder.reinvoice,
            price: resOrder.price,
            reinvoiceNumber: resOrder.reinvoiceNumber,
            invoiceComment: resOrder.invoiceComment,
            salesAccepted: resOrder.salesAccepted,
            registrationNumFront: resOrder.registrationNumFront,
            suggestedDate: item.suggestedDate,
            registrationNumBack: resOrder.registrationNumBack,
            referenceNumber: resOrder.referenceNumber || null,
            transport: resOrder.transport || null,
            uuid: resOrder.uuid,
            orderData: item.orderData,
          };
        })
      );
    });
    return zip(...observables);
  }

  private mapTaskData(taskData: OrderImportSubform[]) {
    taskData = taskData.map((item) => {
      const taskDataItem = {
        order: item.order || null,
        customerCompanyId: item.customerCompany
          ? item.customerCompany.uuid
          : null,
        customerCompany: item.customerCompany,
        registrationNumFront: item.registrationNumFront,
        registrationNumBack: item.registrationNumBack,
        salesAccepted: item.salesAccepted,
        suggestedDate: item.suggestedDate,
        referenceNumber: item.referenceNumber || null,
        bdo: item.bdo ? item.bdo.toString() : null,
        bdoFree: item.bdoFree && !item.bdo,
        bdoPeopleCollection: item.bdoPeopleCollection && !item.bdo,
        kpoEmails: item.kpoEmails,
        reinvoice: item.reinvoice,
        price: Number(String(item.price).replace(",", ".")) || null,
        reinvoiceNumber: item.reinvoiceNumber,
        invoiceComment: item.invoiceComment,
        transportId: item.transport.uuid || null,
        orderData: mapOrderDataApiValues(item.orderData),
      };
      if (item.order) {
        item.order.comment = item.order_comment || null;
      }

      if (item.uuid !== "other") {
        taskDataItem["orderId"] = item.uuid;
      }
      return taskDataItem;
    });
    return taskData;
  }

  public changeStep(event) {
    this.indexStep = event.selectedIndex;
  }

  public prevStep(event) {
    this.indexStep = this.indexStep - 1 >= 0 ? this.indexStep - 1 : 0;
  }

  public getTooltipCompany(company: any) {
    if (company) {
      return `${company.name} 
      ${company.address.street}
      ${company.address.postalCode} ${company.address.city}`;
    }
  }
  public resendNotification(index: number) {
    const orderId = this.task.taskData[index].order.uuid;
    this.isLoading = true;
    this.ordersService.resendNotification(orderId).subscribe(
      () => {
        this.isLoading = false;
        this.snackBar.open(
          this.translateService.instant("TASKS.MESSAGES.NOTIFICATION_SENT"),
          "",
          { duration: 5000 }
        );
      },
      () => {
        this.isLoading = false;
        this.snackBar.open(
          this.translateService.instant("TASKS.MESSAGES.ERROR"),
          "",
          { duration: 5000 }
        );
      }
    );
  }
}
