import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { RecyclingItem, RecyclingItemsListRespone } from '../shared/models/recycling.model';
import { RequestParams } from '../shared/models/list.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class RecyclingService {
  onEditEvent: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient, private authService: AuthService) {
  }

  getRecyclingItemsList(params: RequestParams): Observable<RecyclingItemsListRespone> {
    return this.http.get(`${environment.apiUrl}/${this.authService.getUserType()}/recycling`, {params: params})
      .pipe(map((res: RecyclingItemsListRespone) => res));
  }

  getRecyclingItem(recyclingItemUuid: RecyclingItem['uuid']): Observable<RecyclingItem> {
    return this.http.get(`${environment.apiUrl}/${this.authService.getUserType()}/recycling/${recyclingItemUuid}`).pipe(
      map((res: any) => res.recyclingItem)
    );
  }

  createRecyclingItem(recyclingItem: RecyclingItem): Observable<RecyclingItem> {
    return this.http.post<any>(`${environment.apiUrl}/${this.authService.getUserType()}/recycling`, recyclingItem).pipe(
      map((res: any) => res.recyclingItem)
    );
  }

  updateRecyclingItem(recyclingItemUuid: RecyclingItem['uuid'], recyclingItem: RecyclingItem): Observable<RecyclingItem> {
    return this.http.put<any>(`${environment.apiUrl}/${this.authService.getUserType()}/recycling/${recyclingItemUuid}`, recyclingItem);
  }

  deleteRecyclingItem(recyclingItemUuid: RecyclingItem['uuid']) {
    return this.http.delete<any>(`${environment.apiUrl}/${this.authService.getUserType()}/recycling/${recyclingItemUuid}`);
  }
}