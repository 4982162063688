var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { locale as polish } from '../../../../translations/pl';
import { locale as english } from '../../../../translations/en';
import { ConfirmModalComponent } from '../../../shared/components/confirm-modal/confirm-modal.component';
import { CompanyCarsService } from '../../../../services/company-cars.service';
import { CompaniesService } from '../../../../services/companies.service';
import { parseForm } from '../../../../shared/utils/parse-form';
import { UsersService } from '../../../../services/users.service';
import { FilteredListsService } from "../../../../services/filtered-lists.service";
import { markFormGroupTouched } from "../../../../shared/utils/markFormGroupAsTouched";
var CompanyCarEditorComponent = /** @class */ (function () {
    function CompanyCarEditorComponent(companyCarEditorDialogRef, data, matDialog, companyCarsService, companiesService, filteredListsService, usersService, translateService, snackBar, formBuilder, _fuseTranslationLoaderService) {
        this.companyCarEditorDialogRef = companyCarEditorDialogRef;
        this.data = data;
        this.matDialog = matDialog;
        this.companyCarsService = companyCarsService;
        this.companiesService = companiesService;
        this.filteredListsService = filteredListsService;
        this.usersService = usersService;
        this.translateService = translateService;
        this.snackBar = snackBar;
        this.formBuilder = formBuilder;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this.companyBranches = [];
        this.isLoading = false;
        this.transportOptions = [];
        this.filterParamsFullList = {
            page: 1,
            limit: 1000,
            sort: 'name',
            order: 'asc'
        };
        this.mode = data.mode;
        this.companyUuid = data.companyUuid;
        this.companyCarUuid = data.companyCarUuid;
        this._fuseTranslationLoaderService.loadTranslations(polish, english);
    }
    CompanyCarEditorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.createForm({});
        this.createSearchFilters();
        this.usersService.getCompanyBranchList().subscribe(function (res) {
            _this.companyBranches = res.branches;
        });
        this.filteredListsService.getTransportList(this.filterParamsFullList).subscribe(function (res) {
            _this.transportOptions = res.transports;
        });
        if (this.mode === 'add') {
            this.companiesService.getCompany(this.companyUuid)
                .subscribe(function (res) {
                _this.company = res;
                _this.createForm(res);
            });
        }
        if (this.mode === 'edit' || this.mode === 'view') {
            this.companyCarsService
                .getCompanyCar(this.companyUuid, this.companyCarUuid)
                .subscribe(function (res) {
                _this.companyCar = res;
                _this.createForm(res);
            });
        }
    };
    CompanyCarEditorComponent.prototype.submitCompanyCar = function () {
        var _this = this;
        this.isLoading = true;
        markFormGroupTouched(this.companyCarForm);
        if (this.companyCarForm.invalid) {
            this.isLoading = false;
            return;
        }
        if (this.mode === 'add') {
            this.companyCarsService
                .createCompanyCar(this.companyUuid, parseForm(this.companyCarForm.getRawValue()))
                .subscribe(function () {
                _this.companyCarEditorDialogRef.close();
                _this.companyCarEditorDialogRef.afterClosed().subscribe(function () {
                    _this.companyCarsService.onEditEvent.emit();
                });
                _this.snackBar.open(_this.translateService.instant('COMPANY_CARS.MESSAGES.CREATE_SUCCESS'), '', { duration: 5000 });
            }, function (err) {
                _this.isLoading = false;
                _this.snackBar.open(_this.translateService.instant('COMPANY_CARS.MESSAGES.CREATE_ERROR'), '', { duration: 5000 });
            });
        }
        else {
            this.companyCarsService
                .updateCompanyCar(this.companyUuid, this.companyCarUuid, parseForm(this.companyCarForm.getRawValue()))
                .subscribe(function () {
                _this.companyCarEditorDialogRef.close();
                _this.companyCarEditorDialogRef.afterClosed().subscribe(function () {
                    _this.companyCarsService.onEditEvent.emit();
                });
                _this.snackBar.open(_this.translateService.instant('COMPANY_CARS.MESSAGES.UPDATE_SUCCESS'), '', { duration: 5000 });
            }, function (err) {
                _this.isLoading = false;
                _this.snackBar.open(_this.translateService.instant('COMPANY_CARS.MESSAGES.UPDATE_ERROR'), '', { duration: 5000 });
            });
        }
    };
    CompanyCarEditorComponent.prototype.deleteCompanyCar = function () {
        var _this = this;
        var deleteDialogRef = this.matDialog.open(ConfirmModalComponent, {
            disableClose: false,
            autoFocus: false
        });
        deleteDialogRef.componentInstance.confirmMessage = this.translateService.instant('COMPANY_CARS.MESSAGES.DELETE_QUESTION');
        deleteDialogRef.componentInstance.titleMessage = this.translateService.instant('COMPANY_CARS.MESSAGES.DELETE_TITLE');
        deleteDialogRef.componentInstance.confirmButton = this.translateService.instant('COMPANY_CARS.DELETE');
        deleteDialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.companyCarsService
                    .deleteCompanyCar(_this.companyUuid, _this.companyCarUuid)
                    .subscribe(function () {
                    _this.companyCarEditorDialogRef.close();
                    _this.companyCarEditorDialogRef.afterClosed().subscribe(function () {
                        _this.companyCarsService.onEditEvent.emit();
                    });
                    _this.snackBar.open(_this.translateService.instant('COMPANY_CARS.MESSAGES.DELETE_SUCCESS'), '', { duration: 5000 });
                }, function () {
                    _this.snackBar.open(_this.translateService.instant('COMPANY_CARS.MESSAGES.DELETE_ERROR'), '', { duration: 5000 });
                });
            }
        });
    };
    CompanyCarEditorComponent.prototype.enableEditMode = function () {
        this.companyCarEditorDialogRef.close();
        this.matDialog.open(CompanyCarEditorComponent, {
            panelClass: 'company-car-editor-dialog',
            autoFocus: false,
            disableClose: true,
            data: {
                mode: 'edit',
                companyUuid: this.companyUuid,
                companyCarUuid: this.companyCarUuid
            }
        });
    };
    CompanyCarEditorComponent.prototype.getTitle = function () {
        switch (this.mode) {
            case 'add':
                return this.translateService.instant('COMPANY_CARS.TITLE_ADD');
            case 'edit':
                return this.translateService.instant('COMPANY_CARS.TITLE_EDIT');
            case 'view':
                return this.translateService.instant('COMPANY_CARS.TITLE_VIEW');
            default:
                return;
        }
    };
    CompanyCarEditorComponent.prototype.createForm = function (inputData) {
        this.companyCarForm = this.formBuilder.group({
            name: new FormControl(this.mode === 'add' ? '' : inputData.name || '', Validators.required),
            type: new FormControl(inputData.type || '', Validators.required),
            registrationNumberFront: new FormControl(inputData.registrationNumberFront || '', Validators.required),
            registrationNumberBack: new FormControl(inputData.registrationNumberBack || '', Validators.required),
            driverName: new FormControl(inputData.driverName || '', Validators.required),
            driverPhone: new FormControl(inputData.driverPhone || '', Validators.required),
            bdo: new FormControl(inputData.bdo || '', Validators.required),
            companyName: new FormControl(this.mode === 'add' ? inputData.name : inputData.companyName || '', Validators.required),
            comments: new FormControl(inputData.comments || ''),
            companyBranchId: new FormControl(inputData.companyBranchId || '', Validators.required),
        });
        if (this.mode === 'view') {
            this.companyCarForm.disable();
        }
    };
    CompanyCarEditorComponent.prototype.createSearchFilters = function () {
        var _this = this;
        this.searchTransport = new FormControl('');
        this.searchTransport.valueChanges.debounceTime(300).subscribe(function (value) {
            _this.filteredListsService.getTransportList(__assign({}, _this.filterParamsFullList, { name: value })).subscribe(function (response) {
                _this.transportOptions = response.transports;
            });
        });
    };
    return CompanyCarEditorComponent;
}());
export { CompanyCarEditorComponent };
