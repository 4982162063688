import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-order-changes',
  templateUrl: './order-changes.component.html',
  styleUrls: ['./order-changes.component.scss']
})
export class OrderChangesComponent {
  @Input() changes: any;
}
