var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { PackageTypesEditorComponent } from '../package-types-editor/package-types-editor.component';
import { locale as polish } from '../../../../translations/pl';
import { locale as english } from '../../../../translations/en';
import { PackageService } from '../../../../services/package.service';
import { MatDialog } from '@angular/material';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { List } from '../../../../shared/models/list.class';
import { FormControl } from '@angular/forms';
import { AuthService } from '../../../../services/auth.service';
var PackageTypesListComponent = /** @class */ (function (_super) {
    __extends(PackageTypesListComponent, _super);
    function PackageTypesListComponent(packageService, matDialog, _fuseTranslationLoaderService, authService) {
        var _this = _super.call(this) || this;
        _this.packageService = packageService;
        _this.matDialog = matDialog;
        _this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        _this.authService = authService;
        _this.displayedColumns = ['name', 'unit', 'length', 'width', 'height', 'volume', 'capacity', 'isPublic', 'active'];
        _this.listFilters = {};
        _this._fuseTranslationLoaderService.loadTranslations(polish, english);
        _this.reloadList$ = packageService.onEditEvent.subscribe(function () {
            _this.getElementsList();
        });
        _this.getElementsList = function () {
            _this.packageService.getPackageTypesList(_this.getQueryParams()).subscribe(function (res) {
                _this.totalCount = res.totalCount;
                _this.dataSource = res.packageTypes;
            });
        };
        return _this;
    }
    PackageTypesListComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.createSearchFormControl();
        this.user = this.authService.getUser();
    };
    PackageTypesListComponent.prototype.ngOnDestroy = function () {
        this.reloadList$.unsubscribe();
    };
    PackageTypesListComponent.prototype.addPackageType = function () {
        this.disableAnimations = true;
        var editorDialogRef = this.matDialog.open(PackageTypesEditorComponent, {
            panelClass: 'package-editor-dialog',
            autoFocus: false,
            disableClose: true,
            data: {
                mode: 'add'
            }
        });
    };
    PackageTypesListComponent.prototype.viewPackageType = function (packageTypeUuid) {
        this.disableAnimations = true;
        var editorDialogRef = this.matDialog.open(PackageTypesEditorComponent, {
            panelClass: 'package-editor-dialog',
            autoFocus: false,
            disableClose: true,
            data: {
                mode: 'view',
                packageTypeUuid: packageTypeUuid
            }
        });
    };
    PackageTypesListComponent.prototype.clearSearch = function () {
        if (this.searchPackage.value) {
            this.searchPackage.setValue('');
        }
    };
    PackageTypesListComponent.prototype.getListFilters = function (filters) {
        this.listFilters = filters;
        this.requestParams.page = 1;
        this.disableAnimations = true;
        this.getElementsList();
    };
    PackageTypesListComponent.prototype.createSearchFormControl = function () {
        var _this = this;
        this.searchPackage = new FormControl('');
        this.searchPackage.valueChanges.debounceTime(300).subscribe(function (value) {
            _this.requestParams.name = value;
            _this.requestParams.page = 1;
            _this.disableAnimations = true;
            _this.getElementsList();
        });
    };
    PackageTypesListComponent.prototype.getQueryParams = function () {
        return Object.assign({}, this.requestParams, this.listFilters);
    };
    return PackageTypesListComponent;
}(List));
export { PackageTypesListComponent };
