import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../../../services/auth.service';
import { FuseConfigService } from '../../../../../@fuse/services/config.service';
import { locale as polish } from '../../../../translations/pl';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  public forgotPasswordForm: FormGroup;
  public isLoading = false;

  constructor(private authService: AuthService,
              private translateService: TranslateService,
              private router: Router,
              private snackBar: MatSnackBar,
              private formBuilder: FormBuilder,
              private _fuseConfigService: FuseConfigService,
              private _fuseTranslationLoaderService: FuseTranslationLoaderService) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true
        },
        toolbar: {
          hidden: true
        },
        footer: {
          hidden: true
        },
        sidepanel: {
          hidden: true
        }
      }
    };

    this._fuseTranslationLoaderService.loadTranslations(polish);
  }

  ngOnInit() {
    this.forgotPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  public submit() {
    this.isLoading = true;

    this.authService.forgotPassword(this.forgotPasswordForm.value).subscribe(
      () => {
        this.snackBar.open(this.translateService.instant('SHARED.MESSAGES.FORGOT_PASSWORD_SUCCESS'), '', {duration: 5000});
        this.router.navigate(['/login']);
      }, () => {
        this.isLoading = false;
        this.snackBar.open(this.translateService.instant('SHARED.MESSAGES.ERROR'), '', {duration: 5000});
      }
    )
  }

}
