import { EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./auth.service";
var CompanyBranchesService = /** @class */ (function () {
    function CompanyBranchesService(http, authService) {
        this.http = http;
        this.authService = authService;
        this.onEditEvent = new EventEmitter();
    }
    CompanyBranchesService.prototype.getCompanyBranchesList = function (params, companyUuid) {
        return this.http.get(environment.apiUrl + "/" + this.authService.getUserType() + "/customersCompanies/" + companyUuid + "/branches", { params: params }).pipe(map(function (res) { return res; }));
    };
    CompanyBranchesService.prototype.getCompanyBranch = function (companyUuid, companyBranchUuid) {
        return this.http.get(environment.apiUrl + "/" + this.authService.getUserType() + "/customersCompanies/" + companyUuid + "/branches/" + companyBranchUuid)
            .pipe(map(function (res) { return res.branch; }));
    };
    CompanyBranchesService.prototype.createCompanyBranch = function (companyUuid, branch) {
        return this.http.post(environment.apiUrl + "/" + this.authService.getUserType() + "/customersCompanies/" + companyUuid + "/branches", branch)
            .pipe(map(function (res) { return res.branch; }));
    };
    CompanyBranchesService.prototype.updateCompanyBranch = function (companyUuid, branchUuid, branch) {
        return this.http.post(environment.apiUrl + "/" + this.authService.getUserType() + "/customersCompanies/" + companyUuid + "/branches/" + branchUuid, branch);
    };
    CompanyBranchesService.prototype.deleteCompanyBranch = function (companyUuid, branchUuid) {
        return this.http.delete(environment.apiUrl + "/" + this.authService.getUserType() + "/customersCompanies/" + companyUuid + "/branches/" + branchUuid);
    };
    CompanyBranchesService.ngInjectableDef = i0.defineInjectable({ factory: function CompanyBranchesService_Factory() { return new CompanyBranchesService(i0.inject(i1.HttpClient), i0.inject(i2.AuthService)); }, token: CompanyBranchesService, providedIn: "root" });
    return CompanyBranchesService;
}());
export { CompanyBranchesService };
