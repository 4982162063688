import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FuseTranslationLoaderService } from '../../../../../../@fuse/services/translation-loader.service';
import { locale as polish } from '../../../../../translations/pl';
import { locale as english } from '../../../../../translations/en';
import { fuseAnimations } from '../../../../../../@fuse/animations';
import { remondisListAnimation } from '../../../../../shared/consts/remondis.lists.animation';
import { List } from '../../../../../shared/models/list.class';
import { User } from '../../../../../shared/models/users.model';
import { ListFilters, RequestParams } from '../../../../../shared/models/list.model';
import { AuthService } from '../../../../../services/auth.service';
import { CompanyAccount, CompanyAccountsListRespone } from '../../../../../shared/models/company-accounts.model';
import { CompanyAccountsService } from '../../../../../services/company-accounts.service';
import { CompanyAccountEditorComponent } from '../../../../company-accounts/components/company-account-editor/company-account-editor.component';

@Component({
  selector: 'app-company-accounts-list',
  templateUrl: './company-accounts-list.component.html',
  styleUrls: ['./company-accounts-list.component.scss'],
  animations: [...fuseAnimations, ...remondisListAnimation]
})
export class CompanyAccountsListComponent extends List implements OnInit, OnDestroy {
  @Input() companyUuid: string;
  public displayedColumns = ['name', 'surname', 'email', 'phone', 'status'];
  public user: User;
  private listFilters: ListFilters = {};
  public requestParams: RequestParams = {
    page: 1,
    limit: 10,
    sort: 'surname',
    order: 'asc'
  };
  private reloadList$;

  constructor(private companyAccountsService: CompanyAccountsService,
              private authService: AuthService,
              private matDialog: MatDialog,
              private _fuseTranslationLoaderService: FuseTranslationLoaderService) {
    super();
    this.user = this.authService.getUser();

    this.reloadList$ = companyAccountsService.onEditEvent.subscribe(
      () => {
        this.getElementsList();
      });

    this._fuseTranslationLoaderService.loadTranslations(polish, english);

    this.getElementsList = () => {
      this.companyAccountsService.getCompanyAccountsList(this.requestParams, this.companyUuid)
        .subscribe((res: CompanyAccountsListRespone) => {
          this.totalCount = res.totalCount;
          this.dataSource = res.clients;
        });
    };
  }

  ngOnInit() {
    super.ngOnInit();
    this.getElementsList();
  }

  ngOnDestroy() {
    this.reloadList$.unsubscribe();
  }

  public addCompanyAccount() {
    this.disableAnimations = true;
    this.matDialog.open(CompanyAccountEditorComponent, {
      panelClass: 'company-account-editor-dialog',
      autoFocus: false,
      disableClose: true,
      data: {
        mode: 'add',
        companyUuid: this.companyUuid
      }
    });
  }

  public viewCompanyAccount(companyAccountUuid: CompanyAccount['uuid']) {
    this.disableAnimations = true;
    this.matDialog.open(CompanyAccountEditorComponent, {
      panelClass: 'company-account-editor-dialog',
      autoFocus: false,
      disableClose: true,
      data: {
        mode: 'view',
        companyUuid: this.companyUuid,
        companyAccountUuid: companyAccountUuid
      }
    });
  }

}
