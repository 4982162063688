<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">{{getTitle()}} - {{'TASKS.TYPES.EXPORT' | translate}}</span>
      <span *ngIf="task && task.taskNumber">{{task.taskNumber || ('TASKS.NO_NUMBER' | translate)}}</span>
      <button mat-icon-button (click)="taskEditorDialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div class="task-status" *ngIf="mode === 'view' && task">
    Status: {{'TASKS.STATUSES.' + task?.status.toUpperCase() | translate}}
  </div>

  <mat-horizontal-stepper class="task-content-wrapper" mat-dialog-content #stepper="matHorizontalStepper"
                          (selectionChange)="changeStep($event)" [selectedIndex]="indexStep">

    <!--STEP 1-->
    <mat-step [completed]="false" [stepControl]="taskExportForm">
      <ng-template matStepLabel><h3><b>{{'TASKS.GENERAL_INFO' | translate}}</b></h3></ng-template>
      <div class="general-info-step">
        <form class="form" [formGroup]="taskExportForm">
          <div class="full-width-wrapper-vertical-row">
            <mat-form-field appearance="outline" class="no-errors-spacer" fxFlex>
              <mat-label>{{'TASKS.NAME' | translate}}</mat-label>
              <input matInput formControlName="name" required [attr.data-cy]="'taskName'">
            </mat-form-field>
          </div>

          <div class="full-width-wrapper-horizontal">
            <div class="input-wrapper-task-export">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>{{'TASKS.COMPANY_BRANCH' | translate}}</mat-label>
                <mat-select formControlName="companyBranchId" required>
                  <mat-option *ngFor="let branch of companyBranches" [value]="branch.uuid">
                    {{ branch.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="input-wrapper-task-export">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>{{'TASKS.TRANSPORT' | translate}}</mat-label>
                <mat-select formControlName="transport"
                            [compareWith]="compareObjectsByUuid"
                            required [attr.data-cy]="'setTransport'">
                  <mat-option>
                    <ngx-mat-select-search [formControl]="searchTransport"
                                           [placeholderLabel]="'TRANSPORT.SEARCH_PLACEHOLDER' | translate"
                                           [noEntriesFoundLabel]="'SHARED.NO_RESULTS' | translate">
                    </ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let transport of transportOptions" [value]="transport" [attr.data-cy]="transport.name">
                    {{ transport.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="full-width-wrapper-vertical-row">
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>{{'TASKS.DATE' | translate}}</mat-label>
              <input matInput [matDatepicker]="startDate" formControlName="startDate" [min]="todayDate" required
                     (dateChange)="setStartDate($event.value)">
              <mat-datepicker-toggle matSuffix [for]="startDate" [attr.data-cy]="'startDate'"></mat-datepicker-toggle>
              <mat-datepicker #startDate></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="full-width-wrapper-horizontal">
            <div class="input-wrapper-task-export">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>{{'TASKS.START_TIME' | translate}}</mat-label>
                <mat-select formControlName="startTime" required [attr.data-cy]="'startTime'">
                  <mat-option *ngFor="let time of startTimeOptions; let i=index" [value]="time"
                              [disabled]="taskExportForm.get('endTime').value &&
                              startTimeOptions.includes(taskExportForm.get('endTime').value) &&
                              i+1 > startTimeOptions.indexOf(taskExportForm.get('endTime').value)" [attr.data-cy]="time">
                    {{ time }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="input-wrapper-task-export">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>{{'TASKS.END_TIME' | translate}}</mat-label>
                <mat-select formControlName="endTime" required [attr.data-cy]="'endTime'">
                  <mat-option *ngFor="let time of endTimeOptions; let i=index" [value]="time"
                              [disabled]="taskExportForm.get('startTime').value &&
                              i < startTimeOptions.indexOf(taskExportForm.get('startTime').value)" [attr.data-cy]="time">
                    {{ time }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="input-wrapper-task-export">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>{{'TASKS.REG_NUMBER_FRONT' | translate}}</mat-label>
                <input formControlName="registrationNumFront" matInput [attr.data-cy]="'numberFront'">
              </mat-form-field>
            </div>
            <div class="input-wrapper-task-export">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>{{'TASKS.REG_NUMBER_BACK' | translate}}</mat-label>
                <input formControlName="registrationNumBack" matInput [attr.data-cy]="'numberBack'">
              </mat-form-field>
            </div>
            <div class="input-wrapper-task-export">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>{{'TASKS.REFERENCE_NUMBER' | translate}}</mat-label>
                <input formControlName="referenceNumber" matInput [attr.data-cy]="'referenceNumber'">
              </mat-form-field>
            </div>
             <div class="input-wrapper-task-export">
                <div class="input-wrapper-task-export emailService-checkbox">
                    <mat-checkbox [attr.data-cy]="'emailService'" formControlName="emailService">
                        {{'TASKS.EMAIL_SERVICE' | translate}}
                    </mat-checkbox>
                </div>
            </div>

          </div>
          <div class="full-width-wrapper-vertical"
               [ngClass]="(mode==='add' || mode ==='clone') ? 'comment-col' : 'comment-col-view'">
            <h3 class="m-0"> {{'TASKS.COMMENT' | translate}} </h3>
            <mat-form-field appearance="outline" fxFlex="auto" [formGroup]="taskExportForm">
              <textarea formControlName="comment" matInput [attr.data-cy]="'comment'"></textarea>
            </mat-form-field>
          </div>
          <ng-container *ngIf="mode==='add' || mode ==='clone'">
            <div class="full-width-wrapper-vertical">
              <div class="input-wrapper-task-export recurring-checkbox">
                <mat-checkbox (ngModelChange)="handleRecurringChange()" formControlName="recurring" [attr.data-cy]="'recurring'">
                  {{'TASKS.RECURRING.CHECKBOX' | translate}}
                </mat-checkbox>
              </div>
            </div>
            <div *ngIf="taskExportForm.get('recurring').value" formGroupName="recurringInfo"
                 class="full-width-wrapper-horizontal">
              <div class="input-wrapper-task-export">
                <mat-form-field appearance="outline" fxFlex>
                  <mat-label>{{'TASKS.RECURRING.INTERVAL' | translate}}</mat-label>
                  <input formControlName="intervalValue" matInput required [attr.data-cy]="'intervalValue'">
                </mat-form-field>
              </div>
              <div class="input-wrapper-task-export">
                <mat-form-field appearance="outline" fxFlex>
                  <mat-label>{{'TASKS.RECURRING.RANGE_DATE_END' | translate}}</mat-label>
                  <input matInput [matDatepicker]="rangeDateEndPicker" formControlName="rangeDateEnd"
                         [min]="rangeDateEndMin"
                         [max]="rangeDateEndMax" required readonly>
                  <mat-datepicker-toggle matSuffix [for]="rangeDateEndPicker" [attr.data-cy]="'rangeDateEnd'"></mat-datepicker-toggle>
                  <mat-datepicker #rangeDateEndPicker></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="full-width-wrapper-horizontal weekdays-checkbox-wrapper" formGroupName="weekdays"
                   fxLayoutAlign="start start">
                <mat-checkbox labelPosition="before" formControlName="Monday" class="weekday-checkbox" [attr.data-cy]="'monday'">
                  {{'TASKS.RECURRING.MONDAY' | translate}}
                </mat-checkbox>
                <mat-checkbox labelPosition="before" formControlName="Tuesday" class="weekday-checkbox" [attr.data-cy]="'tuesday'">
                  {{'TASKS.RECURRING.TUESDAY' | translate}}
                </mat-checkbox>
                <mat-checkbox labelPosition="before" formControlName="Wednesday" class="weekday-checkbox" [attr.data-cy]="'wednesday'">
                  {{'TASKS.RECURRING.WEDNESDAY' | translate}}
                </mat-checkbox>
                <mat-checkbox labelPosition="before" formControlName="Thursday" class="weekday-checkbox" [attr.data-cy]="'thursday'">
                  {{'TASKS.RECURRING.THURSDAY' | translate}}
                </mat-checkbox>
                <mat-checkbox labelPosition="before" formControlName="Friday" class="weekday-checkbox" [attr.data-cy]="'friday'">
                  {{'TASKS.RECURRING.FRIDAY' | translate}}
                </mat-checkbox>
                <mat-checkbox labelPosition="before" formControlName="Saturday" class="weekday-checkbox" [attr.data-cy]="'saturday'">
                  {{'TASKS.RECURRING.SATURDAY' | translate}}
                </mat-checkbox>
                <mat-checkbox labelPosition="before" formControlName="Sunday" class="weekday-checkbox" [attr.data-cy]="'sunday'">
                  {{'TASKS.RECURRING.SUNDAY' | translate}}
                </mat-checkbox>
              </div>
            </div>
          </ng-container>
        </form>
      </div>
      <div *ngIf="mode === 'add' || mode === 'clone'" class="btn-next" [attr.data-cy]="'nextStep'">
        <button mat-button matStepperNext>{{'TASKS.STEPPER.NEXT' | translate}}</button>
      </div>
    </mat-step>

    <!--STEP 2-->
    <mat-step [completed]="false" [stepControl]="taskExportForm">
      <ng-template matStepLabel><h3><b> {{'TASKS.ORDERS' | translate}} <sup>*</sup></b></h3></ng-template>
      <mat-accordion>
        <mat-expansion-panel [expanded]="isExpanded[i]"
                             *ngFor="let order of taskExportForm.get('taskData')['controls']; let i=index">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{'TASKS.ORDER'| translate}}
            </mat-panel-title>
            <mat-panel-description *ngIf="order.value.customerCompany && !order.value.order">
              {{order.controls.order.value.customerCompany?.name || ""}}
              {{order.controls.order.value.orderNumber ? "[" + order.controls.order.value.orderNumber + "]" : ""}}
            </mat-panel-description>
            <mat-panel-description *ngIf="order.value.customerCompany && order.value.order">
              {{order.value.customerCompany?.name || ""}}
              {{order.value.order.orderNumber && "[" + order.value.order.orderNumber + "]" || ""}}
            </mat-panel-description>
          </mat-expansion-panel-header>
          <form [formGroup]="taskExportForm">
            <div class="full-width-wrapper-vertical" formArrayName="taskData"
            >
              <div fxLayout="row" fxLayoutAlign="end start">
                <button mat-icon-button [disabled]="mode === 'view' || (this.data && this.data.order && i === 0)"
                        (click)="deleteOrderSubform(i)" [attr.data-cy]="'deleteOrderSubform'">
                  <i class="material-icons s-36 delete-icon">delete</i>
                </button>
              </div>
              <div [formGroupName]='i' class="full-width-wrapper-vertical-order">
                <mat-form-field class="company-select" appearance="outline" fxFlex>
                  <mat-label>{{'TASKS.ORDER' | translate}}</mat-label>
                  <mat-select formControlName="order"
                              [compareWith]="compareObjectsByUuid"
                              (valueChange)="pickOrder($event, i)"
                              required
                              [attr.data-cy]="'setOrder'">
                    <mat-option>
                      <ngx-mat-select-search [formControl]="searchOrders"
                                             [placeholderLabel]="'ORDERS.SEARCH_PLACEHOLDER' | translate"
                                             [noEntriesFoundLabel]="'SHARED.NO_RESULTS' | translate">
                      </ngx-mat-select-search>
                    </mat-option>
                    <mat-option [value]="{uuid: 'other'}" [attr.data-cy]="'other'">
                      -- {{'TASKS.OTHER' | translate}} --
                    </mat-option>
                    <mat-option *ngFor="let order of getOrdersOptions(i)" [value]="order" [attr.data-cy]="order.customerCompany.name.split(' ').join('-')">
                      {{ order.customerCompany?.name }}
                      <small *ngIf="order && order.orderNumber"> [{{ order?.orderNumber }}]</small>
                    </mat-option>
                  </mat-select>
                </mat-form-field>


                <div class="full-width-wrapper-vertical full-name-select">
                  <mat-form-field appearance="outline" fxFlex="auto">
                    <mat-label>{{'TASKS.CUSTOMER_COMPANY' | translate}}</mat-label>
                    <mat-select formControlName="customerCompany" [compareWith]="compareObjectsByUuid"
                                (valueChange)="onChangeCustomerCompany($event, i)" #companySelect
                                required [attr.data-cy]="'customerCompany'">
                      <mat-option>
                        <ngx-mat-select-search [formControl]="searchCustomerCompany"
                                               [placeholderLabel]="'CUSTOMER_COMPANIES.SEARCH_PLACEHOLDER' | translate"
                                               [noEntriesFoundLabel]="'SHARED.NO_RESULTS' | translate">
                        </ngx-mat-select-search>
                      </mat-option>
                      <mat-option (click)="addCustomerCompany(i)" [attr.data-cy]="'companyAdd'">
                          <span class="add-item">
                            <mat-icon class="add-item-icon" matSuffix (click)="addCustomerCompany(i)"
                                      [matTooltip]="'CUSTOMER_COMPANIES.TITLE_ADD' | translate"
                                      matTooltipPosition="above">
                              add
                            </mat-icon>
                            {{ 'CUSTOMER_COMPANIES.TITLE_ADD' | translate }}
                          </span>
                      </mat-option>
                      <mat-option class="full-name-option"
                                  *ngFor="let customerCompany of getCustomerCompaniesOptions(i)"
                                  [value]="customerCompany" [attr.data-cy]="customerCompany.name.split(' ').join('-')">
                        <span matTooltipPosition="above" matTooltipClass="multiline-tooltip"
                              [matTooltip]="getTooltipCompany(customerCompany)">
                        {{ customerCompany.name }}
                        </span>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                  <mat-form-field appearance="outline" fxFlex="auto">
                    <mat-label>{{'ORDERS.DESTINATION' | translate}}</mat-label>
                    <mat-select formControlName="customerCompanyBranch" required
                                (ngModelChange)="handleCCBranchChange($event,i)" [attr.data-cy]="'customerBranch'">
                      <mat-option class="full-name-option"
                                  *ngFor="let branch of customerCompanyBranches[getFormCompanyUuid(i)];
                                  let i=index"
                                  [value]="branch.uuid" [attr.data-cy]="branch.name">
                          <span matTooltipPosition="above" matTooltipClass="multiline-tooltip"
                                [matTooltip]="getTooltip(branch)">
                          {{ branch.name }}
                          </span>
                      </mat-option>
                      <mat-option value="other" [attr.data-cy]="'otherBranch'">
                        {{ "ORDERS.CUSTOMER_COMPANY_BRANCH.OTHER" | translate}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>

                </div>


                <div class="full-width-wrapper-horizontal">
                  <div class="input-wrapper-task-export">
                    <mat-form-field appearance="outline" fxFlex>
                      <mat-label>{{'ORDERS.IS_READY' | translate}}</mat-label>
                      <mat-select formControlName="isReady" required [attr.data-cy]="'isReady'">
                        <mat-option [value]="true" [attr.data-cy]="'YES'">{{'SHARED.YES' | translate}}</mat-option>
                        <mat-option [value]="false" [attr.data-cy]="'NO'">{{'SHARED.NO' | translate}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="input-wrapper-task-export">
                    <mat-form-field appearance="outline" fxFlex>
                      <mat-label>{{'ORDERS.TRANSPORT' | translate}}</mat-label>
                      <mat-select formControlName="transport"
                                  [compareWith]="compareObjectsByUuid"
                                  required
                                  [attr.data-cy]="'chooseTransport'">
                        <mat-option [attr.data-cy]="'searchTransport'">
                          <ngx-mat-select-search [formControl]="searchTransport"
                                                 [placeholderLabel]="'TRANSPORT.SEARCH_PLACEHOLDER' | translate"
                                                 [noEntriesFoundLabel]="'SHARED.NO_RESULTS' | translate">
                          </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let transport of transportOptions" [value]="transport" [attr.data-cy]="transport.name">
                          {{ transport.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="input-wrapper-task-export">
                    <mat-form-field appearance="outline" fxFlex>
                      <mat-label>{{'ORDERS.SUGGESTED_DATE_EXPORT' | translate}}</mat-label>
                      <input matInput [matDatepicker]="suggestedDate" formControlName="suggestedDate" [min]="todayDate" readonly>
                      <mat-datepicker-toggle matSuffix [for]="suggestedDate" [attr.data-cy]="'suggestedDate'"></mat-datepicker-toggle>
                      <mat-datepicker #suggestedDate></mat-datepicker>
                    </mat-form-field>
                  </div>

                  <div class="input-wrapper-task-export">
                    <mat-form-field class="multiple-select" appearance="outline" fxFlex>
                      <mat-label>{{'TASKS.FRACTION' | translate}}</mat-label>
                      <mat-select formControlName="fractions"
                                  multiple
                                  required
                                  [compareWith]="compareObjectsByUuid"
                                  [attr.data-cy]="'fractions'">
                        <mat-option>
                          <ngx-mat-select-search [formControl]="searchFractions"
                                                 [placeholderLabel]="'FRACTIONS.SEARCH_PLACEHOLDER' | translate"
                                                 [noEntriesFoundLabel]="'SHARED.NO_RESULTS' | translate">
                          </ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let fraction of getFractionsOptions(i)"
                                    class="full-name-option"
                                    [value]="fraction"
                                    [attr.data-cy]="fraction.name"
                                    (onSelectionChange)="$event.isUserInput && fractionSelectOptionChange(i, $event.source)">
                          {{ fraction.name }}
                          <ng-container *ngIf="fraction.code">[<small>{{ fraction.code }}</small>]</ng-container>
                          <div *ngIf="fraction.description"><small>{{ fraction.description }}</small></div>
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div class="input-wrapper-task-export">
                    <mat-form-field appearance="outline" fxFlex>
                      <mat-label>{{'TASKS.QUANTITY_KG' | translate}}</mat-label>
                      <input formControlName="value" matInput required [attr.data-cy]="'value'">
                      <mat-error *ngIf="taskExportForm.get('taskData').controls[i].get('value').touched &&
                     taskExportForm.get('taskData').controls[i].get('value').errors?.pattern">
                        {{'SHARED.MESSAGES.DECIMAL_ERROR_THREE' | translate}}
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="input-wrapper-task-export">
                    <mat-form-field appearance="outline" fxFlex>
                      <mat-label>{{'ORDERS.REFERENCE_NUMBER' | translate}}</mat-label>
                      <input formControlName="referenceNumber" matInput [attr.data-cy]="'referenceNumber'">
                    </mat-form-field>
                  </div>


                  <div *ngIf="taskExportForm.get('taskData').controls[i].get('customerCompanyBranch')"
                       formGroupName="customerCompanyBranchOther" class="full-width-wrapper-horizontal">
                    <div class="full-width-wrapper-horizontal">
                      <h3> {{'ORDERS.DESTINATION' | translate}}</h3>
                    </div>
                    <div></div>
                    <div class="input-wrapper-task-export" >
                      <mat-form-field appearance="outline" fxFlex>
                        <mat-label>{{'ORDERS.CUSTOMER_COMPANY_BRANCH.NAME' | translate}}</mat-label>
                        <input formControlName="name" matInput [required]="mode!=='edit'" [attr.data-cy]="'otherBranchName'">
                      </mat-form-field>
                    </div>
                    <div class="input-wrapper-task-export">
                      <mat-form-field appearance="outline" fxFlex>
                        <mat-label>{{'ORDERS.CUSTOMER_COMPANY_BRANCH.STREET' | translate}}</mat-label>
                        <input formControlName="street" matInput required [attr.data-cy]="'otherBranchStreet'">
                      </mat-form-field>
                    </div>

                    <div class="input-wrapper-task-export">
                      <mat-form-field appearance="outline" fxFlex>
                        <mat-label>{{'ORDERS.CUSTOMER_COMPANY_BRANCH.POSTAL_CODE' | translate}}</mat-label>
                        <input formControlName="postalCode" matInput required [attr.data-cy]="'otherBranchPostalCode'">
                      </mat-form-field>
                    </div>

                    <div class="input-wrapper-task-export">
                      <mat-form-field appearance="outline" fxFlex>
                        <mat-label>{{'ORDERS.CUSTOMER_COMPANY_BRANCH.CITY' | translate}}</mat-label>
                        <input formControlName="city" matInput required [attr.data-cy]="'otherBranchCity'">
                      </mat-form-field>
                    </div>

                    <div class="input-wrapper-task-export">
                      <mat-form-field appearance="outline" fxFlex>
                        <mat-label>{{'ORDERS.CUSTOMER_COMPANY_BRANCH.COUNTRY' | translate}}</mat-label>
                        <input formControlName="country" matInput required [attr.data-cy]="'otherBranchCountry'">
                      </mat-form-field>
                    </div>

                    <div class="input-wrapper-task-export">
                      <mat-form-field appearance="outline" fxFlex>
                        <mat-label>{{'ORDERS.CUSTOMER_COMPANY_BRANCH.CONTACT' | translate}}</mat-label>
                        <input formControlName="contact" matInput [attr.data-cy]="'otherBranchContact'">
                      </mat-form-field>
                    </div>

                    <div class="input-wrapper-task-export">
                      <mat-form-field appearance="outline" fxFlex>
                        <mat-label>{{'ORDERS.CUSTOMER_COMPANY_BRANCH.PHONE' | translate}}</mat-label>
                        <input formControlName="phone" matInput required [attr.data-cy]="'otherBranchPhone'">
                      </mat-form-field>
                    </div>

                    <div class="input-wrapper-task-export">
                      <app-email-chips-input [label]="'ORDERS.KPO_CONTACT'" [mode]="mode" [emailControl]="order.controls['kpoEmails']">
                      </app-email-chips-input>
                    </div>

                    <div class="full-width-wrapper-vertical">
                      <app-email-chips-input [mode]="mode" [emailControl]="order.get('customerCompanyBranchOther').get('email')">
                      </app-email-chips-input>
                    </div>

                    <div class="full-width-wrapper-vertical">
                      <button *ngIf="mode === 'view' && task?.status !== taskStatuses.Cancelled"
                      mat-button
                      [disabled]="task?.status !== taskStatuses.SendToForwarder && task?.status !== taskStatuses.Notified"
                      class="mr-8 float-right"
                      [attr.data-cy]="'resendNotification'"
                      (click)="resendNotification(i)">
                        {{'TASKS.RESEND_NOTIFICATION' | translate}}
                       </button>
                    </div>

                    <div class="full-width-wrapper-vertical">
                      <h3> {{'ORDERS.CUSTOMER_COMPANY_BRANCH.CLIENT_COMMENT' | translate}}</h3>
                      <mat-form-field appearance="outline" fxFlex="auto">
                        <textarea formControlName="note" matInput [attr.data-cy]="'otherBranchNote'"></textarea>
                      </mat-form-field>
                    </div>

                    <div class="full-width-wrapper-vertical">
                      <h3 class="m-0"> {{'ORDERS.INTERNAL_COMMENT' | translate}}</h3>
                      <mat-form-field appearance="outline" fxFlex="auto">
                        <textarea formControlName="order_comment" matInput [attr.data-cy]="'order_comment'"></textarea>
                      </mat-form-field>
                    </div>



                  </div>
                  <div class="full-width-wrapper-horizontal">
                          <div class="input-wrapper-task-export">
                              <mat-checkbox class="bdo-required" formControlName="reinvoice"
                                            (change)="reinvoiceChange($event.checked, i)"
                                            [attr.data-cy]="'reinvoice'">
                                  {{'ORDERS.REINVOICE_CHECKBOX' | translate}}
                              </mat-checkbox>
                          </div>

                          <div class="input-wrapper-task-export">
                              <mat-form-field appearance="outline" fxFlex>
                                  <mat-label>{{'ORDERS.REINVOICE_VALUE' | translate}}</mat-label>
                                  <input formControlName="price" matInput
                                         [attr.data-cy]="'price'">
                              </mat-form-field>
                          </div>

                          <div class="input-wrapper-task-export">
                              <mat-form-field appearance="outline" fxFlex>
                                  <mat-label>{{'ORDERS.REINVOICE_NUMBER' | translate}}</mat-label>
                                  <input formControlName="reinvoiceNumber" matInput
                                         [attr.data-cy]="'reinvoiceNumber'">
                              </mat-form-field>
                          </div>
                      </div>
                      <div class="full-width-wrapper-vertical">
                          <h3 class="m-0"> {{'ORDERS.INTERNAL_COMMENT_FV_ISSUER' | translate}}</h3>
                          <mat-form-field appearance="outline" fxFlex="auto">
                                  <textarea formControlName="invoiceComment" matInput
                                            [attr.data-cy]="'invoiceComment'"></textarea>
                          </mat-form-field>
                      </div>
                </div>
              </div>
            </div>
          </form>
        </mat-expansion-panel>
      </mat-accordion>
      <div
        *ngIf="(mode === 'add' || mode === 'clone') || (mode === 'edit' && task && task.status !== taskStatuses.Cancelled)"
        class="mb-24 btn-add" fxLayout="row" fxLayoutAlign="center start">
        <button mat-button color="primary" (click)="addOrderSubform(taskExportForm.get('taskData').value.length)" [attr.data-cy]="'addOrderSubform'">{{'TASKS.ADD_ORDER' | translate}}</button>
      </div>
    </mat-step>

    <!--STEP 3-->
    <mat-step [completed]="false" [stepControl]="taskExportForm">
      <ng-template matStepLabel><h3><b>{{'TASKS.FORWARDER.NAME' | translate}}</b></h3></ng-template>
      <div class="general-info-step">
        <form class="form" [formGroup]="taskExportForm">

          <div *ngIf="mode !== 'view'" class="full-width-wrapper-vertical-row">
            <mat-form-field appearance="outline" fxFlex="auto">
              <mat-label>{{'TASKS.FORWARDER.COMPANY_SELECT' | translate}}</mat-label>
              <input
                      name="forwarder"
                      matInput
                      [formControl]="searchForwarder"
                      [matAutocomplete]="auto">
              <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                <mat-option *ngFor="let forwarderCompany of forwarderCompanies" [value]="forwarderCompany.uuid">
                  {{ forwarderCompany.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>


          <div *ngIf="mode === 'view'" class="full-width-wrapper-horizontal">
            <div class="full-width-wrapper-vertical-row">
              <mat-form-field appearance="outline" class="no-errors-spacer" fxFlex>
                <mat-label>{{'TASKS.FORWARDER.ORIGINAL_COMPANY_NAME' | translate}}</mat-label>
                <input matInput formControlName="originalCompanyName" [attr.data-cy]="'originalCompanyName'">
              </mat-form-field>
            </div>
          </div>

          <div class="full-width-wrapper-horizontal">
            <div class="full-width-wrapper-vertical-row">
              <mat-form-field appearance="outline" class="no-errors-spacer" fxFlex>
                <mat-label>{{'TASKS.FORWARDER.COMPANY_NAME' | translate}}</mat-label>
                <input matInput formControlName="forwarderCompanyName" [attr.data-cy]="'forwarderCompanyName'">
              </mat-form-field>
            </div>
          </div>
          <div *ngIf="mode !== 'view'" class="full-width-wrapper-vertical-row">
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>{{'TASKS.FORWARDER.CAR_SELECT' | translate}}</mat-label>
              <mat-select [formControl]="companyCarSelect">
                <mat-option *ngFor="let companyCar of companyCars" [value]="companyCar">
                  {{ companyCar.name }} - {{companyCar.driverName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="full-width-wrapper-horizontal">
            <div class="input-wrapper-task-export">
              <mat-form-field appearance="outline" class="no-errors-spacer" fxFlex>
                <mat-label>{{'TASKS.FORWARDER.DRIVER_NAME' | translate}}</mat-label>
                <input matInput formControlName="forwarderDriverName" [attr.data-cy]="'forwarderDriverName'">
              </mat-form-field>
            </div>
            <div class="input-wrapper-task-export">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>{{'TASKS.FORWARDER.DRIVER_PHONE' | translate}}</mat-label>
                <input formControlName="forwarderDriverPhone" matInput [attr.data-cy]="'forwarderDriverPhone'">
              </mat-form-field>
            </div>
            <div class="input-wrapper-task-export">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>{{'TASKS.FORWARDER.REGISTRATION_FRONT' | translate}}</mat-label>
                <input formControlName="forwarderRegistrationNumberFront" matInput [attr.data-cy]="'forwarderRegistrationNumberFront'">
              </mat-form-field>
            </div>
            <div class="input-wrapper-task-export">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>{{'TASKS.FORWARDER.REGISTRATION_BACK' | translate}}</mat-label>
                <input formControlName="forwarderRegistrationNumberBack" matInput [attr.data-cy]="'forwarderRegistrationNumberBack'">
              </mat-form-field>
            </div>
            <div class="input-wrapper-task-export">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>{{'TASKS.FORWARDER.BDO' | translate}}</mat-label>
                <input formControlName="forwarderBdo" matInput [attr.data-cy]="'forwarderBdo'">
              </mat-form-field>
            </div>
            <div class="input-wrapper-task-export">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>{{'TASKS.FORWARDER.CAR_NAME' | translate}}</mat-label>
                <input formControlName="forwarderCarName" matInput [attr.data-cy]="'forwarderCarName'">
              </mat-form-field>
            </div>
            <div class="input-wrapper-task-export">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>{{'TASKS.FORWARDER.CAR_TYPE' | translate}}</mat-label>
                <input formControlName="forwarderCarType" matInput [attr.data-cy]="'forwarderCarType'">
              </mat-form-field>
            </div>
            <div class="input-wrapper-task-export">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>{{'TASKS.FORWARDER.EXPENSE' | translate}}</mat-label>
                <input formControlName="forwarderExpense"  matInput [attr.data-cy]="'forwarderExpense'">
              </mat-form-field>
            </div>
            <div class="input-wrapper-task-export">
              <mat-form-field appearance="outline" fxFlex>
                <mat-label>{{'TASKS.FORWARDER.INVOICE_NUMBER' | translate}}</mat-label>
                <input formControlName="forwarderInvoiceNumber" matInput [attr.data-cy]="'forwarderInvoiceNumber'">
              </mat-form-field>
            </div>
             <div class="input-wrapper-task-export">
                  <mat-form-field appearance="outline" fxFlex>
                      <mat-label>{{'TASKS.FORWARDER.KILOMETERS' | translate}}</mat-label>
                      <input formControlName="forwarderKilometers" matInput
                             [attr.data-cy]="'forwarderKilometers'">
                  </mat-form-field>
              </div>
               <div class="full-width-wrapper-horizontal">
              <div class="full-width-wrapper-vertical-row">
                  <mat-form-field appearance="outline" class="no-errors-spacer" fxFlex>
                      <mat-label>{{'TASKS.FORWARDER.ROUTE_DESCRIPTION' | translate}}</mat-label>
                      <textarea matInput formControlName="forwarderRouteDescription"
                                [attr.data-cy]="'forwarderRouteDescription'"></textarea>
                  </mat-form-field>
              </div>
          </div>
          </div>
        </form>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
  <ng-container *ngIf="user.userType !== 'manager'">
  <div class="pb-0 task-number-position" fusePerfectScrollbar>
    <div *ngIf="mode !== 'add' && mode !== 'clone' && task && indexStep===0" class="full-width-wrapper-vertical">
      <hr>
      <h3 class="pb-task"> {{'TASKS.NUMBER' | translate}} </h3>
      <div class="pb-task">{{task.taskNumber || ('TASKS.NO_NUMBER' | translate)}}</div>
    </div>
  </div>

  <div *ngIf="mode !== 'add' && mode !== 'clone'" class="action-btn" fxLayout="row" fxLayoutAlign="end center">
      <button *ngIf="indexStep===1  && task?.status !== taskStatuses.Closed"
            mat-button
            class="mr-8"
            [attr.data-cy]="'completeTask'"
            (click)="completeTask()">
        {{'TASKS.COMPLETE_TASK' | translate}}
      </button>

      <button *ngIf="mode === 'edit' && task?.status !== taskStatuses.Cancelled"
            mat-button
            class="mr-8"
            [attr.data-cy]="'cancelTask'"
            (click)="cancelTask()">
      {{'TASKS.REVOKE' | translate}}
    </button>

    <app-task-pdf-downloader class="pdf-downloader"
                             [task]="task"
                             [branch]="pdfBranch"
                             [forwarder]="pdfForwarder"
    >
    </app-task-pdf-downloader>

    <button *ngIf="mode === 'edit'"
            mat-button
            class="mr-8"
            [attr.data-cy]="'deleteTask'"
            (click)="deleteTask()">
      {{'TASKS.DELETE' | translate}}
    </button>

    <button *ngIf="mode === 'view'"
            mat-button
            class="mr-8"
            [attr.data-cy]="'showHistory'"
            (click)="showHistory()">
      {{'TASKS.SHOW_HISTORY' | translate}}
    </button>
    <button *ngIf="mode === 'view' && user.userType !== 'sales'"
            mat-button
            class="mr-8"
            [attr.data-cy]="'cloneTask'"
            (click)="enableCloneMode()">
      {{'TASKS.CLONE' | translate}}
    </button>
    <button *ngIf="mode === 'view' && user.userType !== 'sales'"
            mat-button
            class="mr-8"
            [attr.data-cy]="'editTask'"
            (click)="enableEditMode()">
      {{'TASKS.EDIT' | translate}}
    </button>
    <button *ngIf="mode === 'edit'"
            mat-button
            class="save-button"
            [attr.data-cy]="'saveTask'"
            (click)="submitTask()"
            [disabled]="isLoading">
      <span *ngIf="!isLoading"> {{'TASKS.SAVE' | translate}}</span>
      <span *ngIf="isLoading" class="loading-spinner-wrapper">
        <mat-progress-spinner mode="indeterminate"
                              [strokeWidth]="2"
                              [diameter]="20">
        </mat-progress-spinner>
      </span>
    </button>
  </div>

  <div *ngIf="(mode === 'add' || mode === 'clone')&& (indexStep === 1 || indexStep === 2)" class="btn-space-between">
    <button
      mat-button
      [attr.data-cy]="'prevStep'"
      (click)="prevStep()">
      {{'TASKS.STEPPER.PREV' | translate}}
    </button>
    <button *ngIf="mode === 'add' || mode === 'clone'"
            mat-button
            class="save-button"
            [attr.data-cy]="'submitTask'"
            (click)="submitTask()"
            [disabled]="isLoading">
      <span *ngIf="!isLoading"> {{'TASKS.SAVE' | translate}}</span>
      <span *ngIf="isLoading" class="loading-spinner-wrapper">
        <mat-progress-spinner mode="indeterminate"
                              [strokeWidth]="2"
                              [diameter]="20">
        </mat-progress-spinner>
      </span>
    </button>
  </div>
</ng-container>
</div>