<div class="pdf-container">
    <div [formGroup]="pdfForm">

      <ng-container>
        <div fxLayout="row" fxLayoutAlign="start start">
          <mat-form-field class="date-filter" appearance="outline">
            <mat-label>{{'TASKS.PDFFILTER.PLACEHOLDER' | translate}}</mat-label>
            <input matInput autocomplete="off" [matDatepicker]="filter" (dateChange)="getTasks()"
                   placeholder="{{'TASKS.PDFFILTER.PLACEHOLDER' | translate}}" formControlName="dateFilter">
            <mat-datepicker-toggle matSuffix [for]="filter" [attr.data-cy]="'date-filter'"></mat-datepicker-toggle>
            <mat-datepicker #filter></mat-datepicker>
          </mat-form-field>
        </div>
      </ng-container>
    </div>

    <button mat-raised-button
            type="button"
            class="pdf-button"
            [disabled]="buttonDisabled"
            color="warn"
            [attr.data-cy]="'getReport'"
            (click)="getReport()"
            matTooltip="{{'TASKS.PDFFILTER.TOOLTIP' | translate}}" matTooltipPosition="left">
      {{'TASKS.PDFFILTER.HEADER' | translate}}
        <mat-icon *ngIf="buttonLoading"><mat-spinner color="accent" diameter="20">
        </mat-spinner></mat-icon>
    </button>
</div>

