<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">{{'TASKS.TITLE_PICK' | translate}}</span>
      <button mat-icon-button (click)="taskTypePickerDialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-actions class="m-0 p-24" fxLayout="column" fxLayoutAlign="center end">
    <button mat-raised-button class="task-type-button ml-0 mr-0" (click)="setTaskType(taskTypes.Export)" [attr.data-cy]="taskTypes.Export">
      {{'TASKS.TYPES.EXPORT' | translate}}
    </button>
    <button mat-raised-button class="task-type-button ml-0 mr-0" (click)="setTaskType(taskTypes.Import)" [attr.data-cy]="taskTypes.Import">
      {{'TASKS.TYPES.IMPORT' | translate}}
    </button>
    <button mat-raised-button class="task-type-button ml-0 mr-0" (click)="setTaskType(taskTypes.Receiving)" [attr.data-cy]="taskTypes.Receiving">
      {{'TASKS.TYPES.RECEIVING' | translate}}
    </button>
  </div>
</div>