import { Injectable } from '@angular/core';
import { UserTypes } from '../shared/models/users.model';
import { FuseNavigationService } from '../../@fuse/components/navigation/navigation.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  constructor(private _fuseNavigationService: FuseNavigationService) {
  }

  navigation(userType?, isUpdate?) {
    const navigation = [
      {
        id: 'applications',
        title: '',
        type: 'group',
        children: [
          {
            id: 'calendar',
            title: 'Kalendarz',
            translate: 'NAV.MAIN.CALENDAR',
            type: 'item',
            icon: 'calendar_today',
            url: '/calendar',
          },
          {
            id: 'users',
            title: 'Użytkownicy',
            translate: 'NAV.MAIN.USERS',
            type: 'item',
            icon: 'account_box',
            url: '/users',
            hidden: ![UserTypes.Logistician].includes(userType)
          },
          {
            id: 'companies',
            title: 'Firmy',
            translate: 'NAV.MAIN.CUSTOMER_COMPANIES',
            type: 'item',
            icon: 'store_mall_directory',
            url: '/companies',
            hidden: ![UserTypes.Logistician, UserTypes.Sales].includes(userType)
          },
          {
            id: 'orders',
            title: 'Zlecenia',
            translate: 'NAV.MAIN.ORDERS',
            type: 'item',
            icon: 'style',
            url: '/orders'
          },
          {
            id: 'tasks',
            title: 'Zadania',
            translate: 'NAV.MAIN.TASKS',
            type: 'item',
            icon: 'check_box',
            url: '/tasks',
            hidden: ![UserTypes.Logistician].includes(userType)
          },
          {
            id: 'fractions',
            title: 'Frakcje',
            translate: 'NAV.MAIN.FRACTIONS',
            type: 'item',
            icon: 'dashboard',
            url: '/fractions',
            hidden: ![UserTypes.Logistician].includes(userType)
          },
          {
            id: 'tags',
            title: 'Tagi',
            translate: 'NAV.MAIN.TAGS',
            type: 'item',
            icon: 'label',
            url: '/tags',
            hidden: ![UserTypes.Logistician].includes(userType)
          },
          {
            id: 'transport',
            title: 'Środki transportu',
            translate: 'NAV.MAIN.TRANSPORT',
            type: 'item',
            icon: 'local_shipping',
            url: '/transport',
            hidden: ![UserTypes.Logistician].includes(userType)
          },
          {
            id: 'recycling',
            title: 'Sprzęt do utylizacji',
            translate: 'NAV.MAIN.RECYCLING_ITEMS',
            type: 'item',
            icon: 'repeat',
            url: '/recycling',
            hidden: ![UserTypes.Logistician].includes(userType)
          },
          {
            id: 'package',
            title: 'Sposoby pakowania',
            translate: 'NAV.MAIN.PACKAGE_TYPES',
            type: 'item',
            icon: 'local_mall',
            url: '/package',
            hidden: ![UserTypes.Logistician].includes(userType)
          },
          {
            id: 'reports',
            title: 'Raporty',
            translate: 'NAV.MAIN.REPORTS',
            type: 'item',
            icon: 'list_alt',
            url: '/reports',
            // hidden: ![UserTypes.Logistician].includes(userType)
          }
        ]
      }
    ];

    if (isUpdate) {
      this._fuseNavigationService.unregister('main');
      this._fuseNavigationService.register('main', navigation);
      this._fuseNavigationService.setCurrentNavigation('main');
    }

    return navigation;
  }
}
