export const locale = {
  lang: 'pl',
  data: {
    'NAVBAR': {
      'LOGISTICIAN': 'Logistyk',
      'SALES': 'Handlowiec',
      'MANAGER': 'Kierownik',
    },
    'TOOLBAR': {
      'MY_PROFILE': 'Mój profil',
      'LOGOUT': 'Wyloguj',
    }
  }
};
