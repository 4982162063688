import {Component, EventEmitter, Inject, OnInit} from '@angular/core';
import {Company} from '../../../../shared/models/companies.model';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ConfirmModalComponent} from '../../../shared/components/confirm-modal/confirm-modal.component';
import {CompaniesService} from '../../../../services/companies.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {FuseTranslationLoaderService} from '../../../../../@fuse/services/translation-loader.service';
import {locale as polish} from '../../../../translations/pl';
import {locale as english} from '../../../../translations/en';
import {AuthService} from '../../../../services/auth.service';
import {RoleTypes, User, UserTypes} from '../../../../shared/models/users.model';
import {map, switchMap} from 'rxjs/operators';
import {UsersService} from '../../../../services/users.service';
import {of} from 'rxjs';
import {parseForm} from '../../../../shared/utils/parse-form';
import {markFormGroupTouched} from '../../../../shared/utils/markFormGroupAsTouched';

@Component({
  selector: 'app-company-editor',
  templateUrl: './company-editor.component.html',
  styleUrls: ['./company-editor.component.scss']
})
export class CompanyEditorComponent implements OnInit {
  public companyForm: FormGroup;
  public invoiceForm: FormGroup;
  public datesForm: FormGroup;
  public businessTermsForm: FormGroup;
  public companyUuid: Company['uuid'];
  public company;
  public ownersOptions = [];
  public user: User;
  public userTypes = UserTypes;
  public roleTypes = RoleTypes;
  public mode: string;
  public differentInvoiceAddress = false;
  public isRequestInProgress = false;
  public hasAccessToUsersList = false;
  public onAddCompany = new EventEmitter();
  public selectedTab = 0;
  private ownerFilters = {
    page: 1,
    limit: 1000,
    sort: 'surname',
    order: 'asc',
    userType: UserTypes.Sales
  };

  constructor(public companyEditorDialogRef: MatDialogRef<CompanyEditorComponent>,
              @Inject(MAT_DIALOG_DATA) private data: any,
              private matDialog: MatDialog,
              private companiesService: CompaniesService,
              private usersService: UsersService,
              private authService: AuthService,
              private translateService: TranslateService,
              private snackBar: MatSnackBar,
              private _formBuilder: FormBuilder,
              private _fuseTranslationLoaderService: FuseTranslationLoaderService) {
    this.mode = data.mode;
    this.companyUuid = data.companyUuid;
    this.user = this.authService.getUser();

    this._fuseTranslationLoaderService.loadTranslations(polish, english);
  }

  ngOnInit() {
    if (this.user.userType === UserTypes.Logistician ||
      (this.user.userType === UserTypes.Sales && this.user.roleType === RoleTypes.Admin)) {
      this.hasAccessToUsersList = true;
    }

    this.createForm({});
    this.createDateForm({});
    this.createInvoiceForm({});
    this.createBusinessTermsForm({});

    if (this.mode === 'edit' || this.mode === 'view') {
      this.companiesService.getCompany(this.companyUuid).pipe(
        map(res => {
          this.company = res;
        }),
        switchMap(() => {
          if (this.hasAccessToUsersList) {
            return this.companiesService.getOwnersList(this.ownerFilters);
          } else {
            return of({users: []});
          }
        })
      ).subscribe(res => {
        this.ownersOptions = res.users;
        this.createForm(this.company);
        this.createInvoiceForm(this.company);
        this.createDateForm(this.company);
        this.createBusinessTermsForm(this.company);
        this.disableForms();
      });
    } else if (this.hasAccessToUsersList) {
      this.companiesService.getOwnersList(this.ownerFilters).subscribe(
        res => {
          this.ownersOptions = res.users;
        }
      );
    }

    this.disableForms();
  }

  public submitCompany() {
    this.isRequestInProgress = true;
    markFormGroupTouched(this.companyForm);

    if (this.companyForm.invalid) {
      this.isRequestInProgress = false;
      return;
    }
    const companyData = this.companyForm.getRawValue();
    companyData.supplier = companyData.companyType.includes('supplier');
    companyData.recipient = companyData.companyType.includes('recipient');
    if (this.mode === 'add' || this.user.userType === this.userTypes.Logistician) {
      companyData.forwarder = companyData.companyType.includes('forwarder');
    } else {
      companyData.forwarder = this.company.companyType.forwarder;
    }
    delete companyData.companyType;

    if (this.mode === 'add') {
      if (!this.differentInvoiceAddress) {
        companyData.invoiceAddress = this.companyForm.get('address').value;
      } else {
        companyData.invoiceAddress = this.invoiceForm.value;
      }

      if (!this.hasAccessToUsersList) {
        companyData.ownerId = this.user.uuid;
      }

      this.companiesService.createCompany(parseForm(companyData)).subscribe(
        res => {
          this.companyEditorDialogRef.close();

          this.companyEditorDialogRef.afterClosed().subscribe(() => {
            this.companiesService.onEditEvent.emit();
            this.onAddCompany.emit(res);
          });

          this.snackBar.open(this.translateService.instant('CUSTOMER_COMPANIES.MESSAGES.CREATE_SUCCESS'), '', {duration: 5000});
        }, err => {
          this.isRequestInProgress = false;
          if (err.status === 409) {
            this.snackBar.open(this.translateService.instant('CUSTOMER_COMPANIES.MESSAGES.CREATE_NIP_ERROR'), '', {duration: 5000});
          } else {
            this.snackBar.open(this.translateService.instant('CUSTOMER_COMPANIES.MESSAGES.CREATE_ERROR'), '', {duration: 5000});
          }
        }
      );
    } else {
      companyData.invoiceAddress = this.invoiceForm.value;

      this.companiesService.updateCompany(this.companyUuid, parseForm(companyData)).subscribe(
        () => {
          this.companyEditorDialogRef.close();

          this.companyEditorDialogRef.afterClosed().subscribe(() => {
            this.companiesService.onEditEvent.emit();
          });

          this.snackBar.open(this.translateService.instant('CUSTOMER_COMPANIES.MESSAGES.UPDATE_SUCCESS'), '', {duration: 5000});
        }, err => {
          this.isRequestInProgress = false;
          if (err.status === 409) {
            this.snackBar.open(this.translateService.instant('CUSTOMER_COMPANIES.MESSAGES.UPDATE_NIP_ERROR'), '', {duration: 5000});
          } else {
            this.snackBar.open(this.translateService.instant('CUSTOMER_COMPANIES.MESSAGES.UPDATE_ERROR'), '', {duration: 5000});
          }
        }
      );
    }
  }

  public submitBusinessTerms() {
    this.isRequestInProgress = true;
    markFormGroupTouched(this.businessTermsForm);

    if (this.businessTermsForm.invalid) {
      this.isRequestInProgress = false;
      return;
    }
    const businessTerms = this.businessTermsForm.getRawValue();
    this.companiesService.updateCompanyBusinessTerms(this.companyUuid, parseForm(businessTerms)).subscribe(
      () => {
        this.companiesService.onEditEvent.emit();
        this.isRequestInProgress = false;
        this.businessTermsForm.disable();
        this.snackBar.open(this.translateService.instant('CUSTOMER_COMPANIES.MESSAGES.UPDATE_SUCCESS'), '', {duration: 5000});
      }, err => {
        this.isRequestInProgress = false;
        if (err.status === 409) {
          this.snackBar.open(this.translateService.instant('CUSTOMER_COMPANIES.MESSAGES.UPDATE_NIP_ERROR'), '', {duration: 5000});
        } else {
          this.snackBar.open(this.translateService.instant('CUSTOMER_COMPANIES.MESSAGES.UPDATE_ERROR'), '', {duration: 5000});
        }
      }
    );
  }

  public getGusInfo() {
    this.companiesService.getGusInformation(this.companyForm.get('nip').value.trim()).subscribe(
      (res) => {
        this.companyForm.patchValue({
          nip: res.nip,
          name: res.name,
          regon: res.regon,
          address: {
            city: res.city,
            postalCode: res.postalCode,
            street: res.street
          }
        });

        this.invoiceForm.patchValue({
          street: res.street,
          postalCode: res.postalCode,
          city: res.city
        });
        this.snackBar.open(this.translateService.instant('CUSTOMER_COMPANIES.MESSAGES.VALID_NIP'), '', {duration: 5000});
      }, err => {
        this.snackBar.open(this.translateService.instant('CUSTOMER_COMPANIES.MESSAGES.INVALID_NIP'), '', {duration: 5000});
      });
  }

  public deleteCompany() {
    const deleteDialogRef = this.matDialog.open(ConfirmModalComponent, {
      disableClose: false,
      autoFocus: false
    });

    deleteDialogRef.componentInstance.confirmMessage = this.translateService.instant('CUSTOMER_COMPANIES.MESSAGES.DELETE_QUESTION');
    deleteDialogRef.componentInstance.titleMessage = this.translateService.instant('CUSTOMER_COMPANIES.MESSAGES.DELETE_COMPANY_TITLE');
    deleteDialogRef.componentInstance.confirmButton = this.translateService.instant('CUSTOMER_COMPANIES.DELETE');

    deleteDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.companiesService.deleteCompany(this.companyUuid).subscribe(
          () => {
            this.companyEditorDialogRef.close();
            this.companyEditorDialogRef.afterClosed().subscribe(() => {
              this.companiesService.onEditEvent.emit();
            });
            this.snackBar.open(this.translateService.instant('CUSTOMER_COMPANIES.MESSAGES.DELETE_SUCCESS'), '', {duration: 5000});
          }, () => {
            this.snackBar.open(this.translateService.instant('CUSTOMER_COMPANIES.MESSAGES.DELETE_ERROR'), '', {duration: 5000});
          }
        );
      }
    });
  }

  public enableEditMode() {
    this.companyEditorDialogRef.close();
    this.matDialog.open(CompanyEditorComponent, {
      panelClass: 'company-editor-dialog',
      autoFocus: false,
      disableClose: true,
      data: {
        mode: 'edit',
        companyUuid: this.companyUuid
      }
    });

    if (this.user.userType === UserTypes.Logistician ||
      (this.user.userType === UserTypes.Sales && this.user.roleType !== RoleTypes.Admin)) {
      this.companyForm.get('ownerId').disable();
    }
  }

  public enableEditModeForBusinessTerms() {
    this.businessTermsForm.enable();
  }

  public toggleInvoiceAddress() {
    this.differentInvoiceAddress = !this.differentInvoiceAddress;

    if (this.differentInvoiceAddress) {
      this.createInvoiceForm({});
    } else {
      this.invoiceForm.reset();
    }
  }

  public getTitle(): string {
    switch (this.mode) {
      case 'add':
        return this.translateService.instant('CUSTOMER_COMPANIES.TITLE_ADD');
      case 'edit':
        return this.translateService.instant('CUSTOMER_COMPANIES.TITLE_EDIT');
      case 'view':
        return this.translateService.instant('CUSTOMER_COMPANIES.TITLE_VIEW');
      default:
        return;
    }
  }

  private createForm(inputData: Company | any) {
    const companyChosenType = [];
    if (inputData.companyType) {
      for (const key in inputData.companyType) {
        inputData.companyType[key] && companyChosenType.push(key);
      }
    }

    this.companyForm = this._formBuilder.group({
      name: new FormControl(inputData.name || null, Validators.required),
      companyType: new FormControl(companyChosenType || null, Validators.required),
      address: new FormGroup({
        street: new FormControl(inputData.address ? inputData.address.street : null, Validators.required),
        postalCode: new FormControl(inputData.address ? inputData.address.postalCode : null, Validators.required),
        city: new FormControl(inputData.address ? inputData.address.city : null, Validators.required),
        country: new FormControl(inputData.address ? inputData.address.country : 'Polska', Validators.required),
      }),
      nip: new FormControl(inputData.nip || null,
        [Validators.required, Validators.minLength(8)]),
      regon: new FormControl(inputData.regon || null, Validators.required),
      krs: new FormControl(inputData.krs || null),
      bdo: new FormControl(inputData.bdo || null),
      contact: new FormControl(inputData.contact || null),
      phone: new FormControl(inputData.phone || null),
      ownerId: new FormControl(inputData.owner ? inputData.owner.uuid : null || null),
      email: new FormControl(inputData.email || null, Validators.email),
      trusted: new FormControl(this.mode === 'add' ? false : inputData.trusted),
    });
  }

  private createInvoiceForm(inputData: Company | any) {
    this.invoiceForm = this._formBuilder.group({
      street: new FormControl(inputData.invoiceAddress ? inputData.invoiceAddress.street : null, Validators.required),
      postalCode: new FormControl(inputData.invoiceAddress ? inputData.invoiceAddress.postalCode : null, Validators.required),
      city: new FormControl(inputData.invoiceAddress ? inputData.invoiceAddress.city : null, Validators.required),
      country: new FormControl(inputData.invoiceAddress ? inputData.invoiceAddress.country : 'Polska', Validators.required),
    });
  }

  private createDateForm(inputData: Company | any) {
    this.datesForm = this._formBuilder.group({
      createdDate: new FormControl(inputData.createdDate || ''),
      updatedDate: new FormControl(inputData.updatedDate || ''),
    });
    this.datesForm.disable();
  }

  private createBusinessTermsForm(inputData: Company | any) {
    this.businessTermsForm = this._formBuilder.group({
      businessTerms: new FormControl(inputData.businessTerms || ''),
    });
  }

  private disableForms() {
    if (this.mode === 'view') {
      this.companyForm.disable();
      this.invoiceForm.disable();
      this.datesForm.disable();
      this.businessTermsForm.disable();
    } else if (this.mode === 'edit') {
      this.companyForm.get('nip').disable();
    }

    if (this.user.userType === UserTypes.Logistician) {
      this.companyForm.get('ownerId').disable();
    } else if (this.user.userType === UserTypes.Sales && this.user.roleType !== RoleTypes.Admin) {
      this.companyForm.get('ownerId').disable();
    }
  }
}
