import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import * as moment from 'moment';
import {FormBuilder, FormControl} from '@angular/forms';
import {NoteCalendarService} from '../../../../services/note-calendar.service';
import {MatDialog, MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';

import {FuseTranslationLoaderService} from '../../../../../@fuse/services/translation-loader.service';
import {locale as polish} from '../../../../translations/pl';
import {locale as english} from '../../../../translations/en';
import {Description} from '../../../../shared/models/description.model';
import {User, UserTypes} from '../../../../shared/models/users.model';
import {AuthService} from '../../../../services/auth.service';
import {ConfirmModalComponent} from '../../../shared/components/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-additional-field',
  templateUrl: './day-additional-field.component.html',
  styleUrls: ['./day-additional-field.component.scss']
})
export class DayAdditionalFieldComponent implements OnInit, OnChanges {
  @Input() private diffBetweenStartOfWeek = 0;
  @Input() private startDate: string; // 'YYYY-MM-DD'
  @Input() private selectedDate: Date;
  @Input() private viewName: string;
  @Input() private companyBranchId: string;
  @Input() private calendarView = 'day';
  @Input() public description: Description;
  public descriptionFormControl: FormControl;
  public isEditMode = false;
  public isLoading = false;
  public user: User;
  public userTypes = UserTypes;
  public tmpDesc = '';

  constructor(
    public dialog: MatDialog,
    private noteCalendarService: NoteCalendarService,
    private translateService: TranslateService,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService) {
    this.user = this.authService.user.user;
    this._fuseTranslationLoaderService.loadTranslations(polish, english);
  }


  ngOnInit() {
    this.descriptionFormControl = new FormControl(this.description ? this.description.note : null);
    this.tmpDesc = this.descriptionFormControl.value
    this.descriptionFormControl.disable();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.descriptionFormControl) {
      this.descriptionFormControl.patchValue(this.description ? this.description.note : null);
      this.descriptionFormControl.disable();
      this.isEditMode = false;
    }
  }

  public goToEditMode() {
    this.tmpDesc = this.descriptionFormControl.value;
    this.descriptionFormControl.enable();
    this.isEditMode = true;
  }

  public goToViewMode() {
    this.descriptionFormControl.patchValue(this.description && this.description.note !== this.descriptionFormControl.value ? this.tmpDesc : this.descriptionFormControl.value);
    this.descriptionFormControl.disable();
    this.isEditMode = false;
  }

  public openDialog() {
    const saveDialogRef = this.dialog.open(ConfirmModalComponent, {
      disableClose: false,
      autoFocus: false
    });
    saveDialogRef.componentInstance.confirmMessage = this.translateService.instant(
      'CALENDAR.ADDITIONAL_FIELD_QUESTION'
    );
    saveDialogRef.componentInstance.titleMessage = this.translateService.instant(
      'CALENDAR.ADDITIONAL_FIELD_TITLE'
    );
    saveDialogRef.componentInstance.confirmButton = this.translateService.instant(
      'CALENDAR.ADDITIONAL_FIELD_SAVE'
    );

    this.descriptionFormControl.disable();
    saveDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.submitEdit();
      } else {
        this.submitExit();
      }
    });
  }

  public submitEdit() {
    this.isEditMode = false;
    this.isLoading = true;
    this.descriptionFormControl.disable();
    this.noteCalendarService.addNote(
      this.descriptionFormControl.value,
      moment(this.selectedDate || this.startDate).add(this.diffBetweenStartOfWeek, 'd').format('YYYY-MM-DD'),
      this.companyBranchId).subscribe(() => {
        this.isLoading = false;
        this.snackBar.open(
          this.translateService.instant(
            'CALENDAR.ADDITIONAL_FIELD_SAVE_SUCCESS'
          ),
          '',
          {duration: 5000}
        );
      },
      err => {
        this.isLoading = false;
        this.snackBar.open(
          this.translateService.instant(
            'CALENDAR.ADDITIONAL_FIELD_SAVE_ERROR'
          ),
          '',
          {duration: 5000}
        );
      });
  }

  public submitExit() {
    this.isEditMode = true;
    this.descriptionFormControl.enable();
  }
}
