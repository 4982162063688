import { OnInit } from "@angular/core";
import { FormBuilder, FormControl, Validators, } from "@angular/forms";
import { MatDialog, MatDialogRef, MatSnackBar, } from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { zip } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import { FuseTranslationLoaderService } from "../../../../../@fuse/services/translation-loader.service";
import { AuthService } from "../../../../services/auth.service";
import { UsersService } from "../../../../services/users.service";
import { RoleTypes, UserTypes, } from "../../../../shared/models/users.model";
import { markFormGroupTouched } from "../../../../shared/utils/markFormGroupAsTouched";
import { PasswordValidator } from "../../../../shared/validators/password.validator";
import { locale as english } from "../../../../translations/en";
import { locale as polish } from "../../../../translations/pl";
import { ConfirmModalComponent } from "../../../shared/components/confirm-modal/confirm-modal.component";
var UserEditorComponent = /** @class */ (function () {
    function UserEditorComponent(userEditorDialogRef, data, matDialog, usersService, authService, translateService, snackBar, formBuilder, _fuseTranslationLoaderService) {
        this.userEditorDialogRef = userEditorDialogRef;
        this.data = data;
        this.matDialog = matDialog;
        this.usersService = usersService;
        this.authService = authService;
        this.translateService = translateService;
        this.snackBar = snackBar;
        this.formBuilder = formBuilder;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this.mode = "view";
        this.userTypes = UserTypes;
        this.companyBranches = [];
        this.changePassword = false;
        this.isLoading = false;
        this.isAdmin = false;
        this.myProfile = false;
        this.selectedTab = 0;
        this.mode = data.mode;
        this.editedUserUuid = data.editedUserUuid;
        this._fuseTranslationLoaderService.loadTranslations(polish, english);
        this.loggedUser = this.authService.getUser();
        if (this.loggedUser.roleType === RoleTypes.Admin) {
            this.isAdmin = true;
        }
        this.myProfile = this.loggedUser.uuid === this.editedUserUuid;
    }
    UserEditorComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.createForm({});
        this.usersService.getAclGroupsList().subscribe(function (res) {
            _this.aclGroups = res;
        });
        if (this.mode === "edit" || this.mode === "view") {
            this.usersService
                .getUser(this.editedUserUuid)
                .pipe(map(function (res) {
                _this.editedUser = res;
            }), switchMap(function () {
                return zip(_this.usersService.getCompanyBranchList(), _this.authService.getAuthenticator());
            }))
                .subscribe(function (_a) {
                var branches = _a[0], auth = _a[1];
                _this.companyBranches = branches.branches;
                _this.isAuthenticatorEnabled =
                    auth.authenticator.isAuthenticatorEnabled;
                _this.authenticatorSecret = auth.authenticator.authenticatorSecret;
                _this.createForm(_this.editedUser);
            });
        }
        else {
            this.usersService.getCompanyBranchList().subscribe(function (res) {
                _this.companyBranches = res.branches;
            });
        }
    };
    UserEditorComponent.prototype.submitUser = function () {
        var _this = this;
        this.isLoading = true;
        markFormGroupTouched(this.userForm);
        if (this.userForm.invalid) {
            this.isLoading = false;
            return;
        }
        var userType = this.userForm.get("userType").value;
        var roleType = this.userForm.get("roleType").value;
        var aclGroupId = this.aclGroups[userType].find(function (item) { return item.roleType === roleType; }).uuid;
        this.userForm.addControl("aclGroupId", new FormControl(aclGroupId, Validators.required));
        if (this.mode === "add") {
            this.usersService.createUser(this.userForm.value).subscribe(function () {
                _this.userEditorDialogRef.close();
                _this.userEditorDialogRef.afterClosed().subscribe(function () {
                    _this.usersService.onEditEvent.emit();
                });
                _this.snackBar.open(_this.translateService.instant("USERS.MESSAGES.CREATE_SUCCESS"), "", { duration: 5000 });
            }, function () {
                _this.isLoading = false;
                _this.snackBar.open(_this.translateService.instant("USERS.MESSAGES.CREATE_ERROR"), "", { duration: 5000 });
            });
        }
        else {
            if (this.changePassword) {
                this.userForm.patchValue({
                    password: this.passwordForm.get("password").value,
                });
            }
            this.usersService
                .updateUser(this.editedUserUuid, this.userForm.value)
                .subscribe(function () {
                _this.userEditorDialogRef.close();
                _this.userEditorDialogRef.afterClosed().subscribe(function () {
                    _this.usersService.onEditEvent.emit();
                });
                _this.snackBar.open(_this.translateService.instant("USERS.MESSAGES.UPDATE_SUCCESS"), "", { duration: 5000 });
            }, function () {
                _this.isLoading = false;
                _this.snackBar.open(_this.translateService.instant("USERS.MESSAGES.UPDATE_ERROR"), "", { duration: 5000 });
            });
        }
    };
    UserEditorComponent.prototype.deleteUser = function () {
        var _this = this;
        var deleteDialogRef = this.matDialog.open(ConfirmModalComponent, {
            disableClose: false,
            autoFocus: false,
        });
        deleteDialogRef.componentInstance.confirmMessage =
            this.translateService.instant("USERS.MESSAGES.DELETE_QUESTION");
        deleteDialogRef.componentInstance.titleMessage =
            this.translateService.instant("USERS.MESSAGES.DELETE_USER_TITLE");
        deleteDialogRef.componentInstance.confirmButton =
            this.translateService.instant("USERS.DELETE");
        deleteDialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.usersService.deleteUser(_this.editedUserUuid).subscribe(function () {
                    _this.userEditorDialogRef.close();
                    _this.userEditorDialogRef.afterClosed().subscribe(function () {
                        _this.usersService.onEditEvent.emit();
                    });
                    _this.snackBar.open(_this.translateService.instant("USERS.MESSAGES.DELETE_SUCCESS"), "", { duration: 5000 });
                }, function () {
                    _this.snackBar.open(_this.translateService.instant("USERS.MESSAGES.DELETE_ERROR"), "", { duration: 5000 });
                });
            }
        });
    };
    UserEditorComponent.prototype.enableEditMode = function () {
        this.userEditorDialogRef.close();
        this.matDialog.open(UserEditorComponent, {
            panelClass: "user-editor-dialog",
            autoFocus: false,
            disableClose: true,
            data: {
                mode: "edit",
                editedUserUuid: this.editedUserUuid,
            },
        });
        this.userForm.get("email").disable();
        this.userForm.get("userType").disable();
    };
    UserEditorComponent.prototype.toggleChangePassword = function () {
        this.changePassword = !this.changePassword;
        if (this.changePassword) {
            this.passwordForm = this.formBuilder.group({
                password: new FormControl("", [
                    Validators.required,
                    Validators.minLength(5),
                ]),
                confirmPassword: new FormControl("", Validators.required),
            }, {
                validator: PasswordValidator.MatchPassword,
            });
        }
        else {
            this.passwordForm = null;
            this.userForm.get("password").reset();
        }
    };
    UserEditorComponent.prototype.getTitle = function () {
        switch (this.mode) {
            case "add":
                return this.translateService.instant("USERS.TITLE_ADD");
            case "edit":
                return this.translateService.instant("USERS.TITLE_EDIT");
            case "view":
                return this.translateService.instant("USERS.TITLE_VIEW");
            default:
                return;
        }
    };
    UserEditorComponent.prototype.createForm = function (inputData) {
        this.userForm = this.formBuilder.group({
            name: new FormControl(inputData.name || "", Validators.required),
            surname: new FormControl(inputData.surname || "", Validators.required),
            email: new FormControl(inputData.email || "", [
                Validators.required,
                Validators.email,
            ]),
            roleType: new FormControl(inputData.roleType || "", Validators.required),
            userType: new FormControl(inputData.userType || "", Validators.required),
            companyBranchId: new FormControl(inputData.companyBranchId || "", Validators.required),
            password: new FormControl(null),
        });
        if (this.mode !== "add") {
            this.userForm.addControl("active", new FormControl(inputData.active || false, Validators.required));
        }
        if (this.mode === "view") {
            this.userForm.disable();
        }
    };
    UserEditorComponent.prototype.change2FA = function (twoFaEnabled) {
        var _this = this;
        this.authService
            .setAuthenticator({ isAuthenticator: twoFaEnabled })
            .subscribe(function () {
            _this.authService.getAuthenticator().subscribe(function (res) {
                _this.isAuthenticatorEnabled =
                    res.authenticator.isAuthenticatorEnabled;
                _this.authenticatorSecret = res.authenticator.authenticatorSecret;
            });
            _this.snackBar.open(_this.translateService.instant("USERS.MESSAGES.2FA_SUCCESS"), "", { duration: 5000 });
        }, function () {
            _this.snackBar.open(_this.translateService.instant("USERS.MESSAGES.2FA_ERROR"), "", { duration: 5000 });
        });
    };
    return UserEditorComponent;
}());
export { UserEditorComponent };
