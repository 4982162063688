import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { MatDialog } from "@angular/material";
import { NavigationEnd, Router } from "@angular/router";
import { FuseNavigationService } from "@fuse/components/navigation/navigation.service";
import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { FusePerfectScrollbarDirective } from "@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive";
import { FuseConfigService } from "@fuse/services/config.service";
import { TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { delay, filter, take, takeUntil } from "rxjs/operators";
import { FuseTranslationLoaderService } from "../../../../../../@fuse/services/translation-loader.service";
import { OrderLinkFormComponent } from "../../../../../modules/orders/components/order-forms/order-link-form/order-link-form.component";
import { UserEditorComponent } from "../../../../../modules/users/components/user-editor/user-editor.component";
import { AuthService } from "../../../../../services/auth.service";
import { User, UserTypes } from "../../../../../shared/models/users.model";
import { locale as polish } from "../../../i18n/pl";

@Component({
  selector: "navbar-vertical-style-1",
  templateUrl: "./style-1.component.html",
  styleUrls: ["./style-1.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class NavbarVerticalStyle1Component implements OnInit, OnDestroy {
  public user: User;

  fuseConfig: any;
  navigation: any;
  closeMenu: boolean;
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;

  // Private
  private _fusePerfectScrollbar: FusePerfectScrollbarDirective;
  private _unsubscribeAll: Subject<any>;

  constructor(
    private authService: AuthService,
    private matDialog: MatDialog,
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _translateService: TranslateService,
    private _router: Router
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
    this._fuseTranslationLoaderService.loadTranslations(polish);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // Directive
  @ViewChild(FusePerfectScrollbarDirective)
  set directive(theDirective: FusePerfectScrollbarDirective) {
    if (!theDirective) {
      return;
    }

    this._fusePerfectScrollbar = theDirective;

    // Update the scrollbar on collapsable item toggle
    this._fuseNavigationService.onItemCollapseToggled
      .pipe(delay(500), takeUntil(this._unsubscribeAll))
      .subscribe(() => {
        this._fusePerfectScrollbar.update();
      });

    // Scroll to the active item position
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        take(1)
      )
      .subscribe(() => {
        setTimeout(() => {
          const activeNavItem: any = document.querySelector(
            "navbar .nav-link.active"
          );

          if (activeNavItem) {
            const activeItemOffsetTop = activeNavItem.offsetTop,
              activeItemOffsetParentTop = activeNavItem.offsetParent.offsetTop,
              scrollDistance =
                activeItemOffsetTop - activeItemOffsetParentTop - 48 * 3 - 168;

            this._fusePerfectScrollbar.scrollToTop(scrollDistance);
          }
        });
      });
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    this.user = this.authService.getUser();

    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(() => {
        if (this._fuseSidebarService.getSidebar("navbar")) {
          this._fuseSidebarService.getSidebar("navbar").close();
        }
      });

    // Subscribe to the config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.fuseConfig = config;
        this.horizontalNavbar = config.layout.navbar.position === "top";
        this.rightNavbar = config.layout.navbar.position === "right";
        this.hiddenNavbar = config.layout.navbar.hidden === true;
      });

    // Get current navigation
    this._fuseNavigationService.onNavigationChanged
      .pipe(
        filter((value) => value !== null),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(() => {
        this.navigation = this._fuseNavigationService.getCurrentNavigation();
      });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  public sendOrderLink(): void {
    this.matDialog.open(OrderLinkFormComponent, { autoFocus: false });
  }

  getFolded(): boolean {
    return this._fuseSidebarService.getSidebar("navbar").folded;
  }

  toggleSidebarFolded(): void {
    this._fuseSidebarService.getSidebar("navbar").toggleFold();
    this.closeMenu = !this.closeMenu;
  }

  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  toggleSidebarClose(): void {
    this._fuseSidebarService.getSidebar("navbar").close();
  }

  public getNavbarTitle() {
    if (this.user) {
      switch (this.user.userType) {
        case UserTypes.Logistician:
          return "LOGISTICIAN";
        case UserTypes.Sales:
          return "SALES";
        case UserTypes.Manager:
          return "MANAGER";
      }
    }
  }

  public viewUser(): void {
    this.matDialog.open(UserEditorComponent, {
      panelClass: "user-editor-dialog",
      autoFocus: false,
      disableClose: true,
      data: {
        mode: "view",
        editedUserUuid: this.user.uuid,
      },
    });
  }

  public logout(): void {
    this.authService.logout();
  }
}
