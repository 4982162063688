import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {TransportService} from '../../../../services/transport.service';
import {TranslateService} from '@ngx-translate/core';
import {FuseTranslationLoaderService} from '../../../../../@fuse/services/translation-loader.service';
import {ConfirmModalComponent} from '../../../shared/components/confirm-modal/confirm-modal.component';
import {Transport} from '../../../../shared/models/transport.model';
import {locale as polish} from '../../../../translations/pl';
import {locale as english} from '../../../../translations/en';
import {TransportCategories} from '../../../../shared/consts/transport.categories';
import {ValidateGreaterThanZero} from '../../../../shared/validators/greaterThanZero.validator';
import {parseForm} from "../../../../shared/utils/parse-form";
import {markFormGroupTouched} from "../../../../shared/utils/markFormGroupAsTouched";

@Component({
  selector: 'app-transport-editor',
  templateUrl: './transport-editor.component.html',
  styleUrls: ['./transport-editor.component.scss']
})
export class TransportEditorComponent implements OnInit {
  public transportForm: FormGroup;
  public transportUuid: Transport['uuid'];
  public transport;
  public mode: string;
  public isLoading = false;
  public transportCategories = TransportCategories;

  constructor(public transportEditorDialogRef: MatDialogRef<TransportEditorComponent>,
              @Inject(MAT_DIALOG_DATA) private data: any,
              private matDialog: MatDialog,
              private transportService: TransportService,
              private translateService: TranslateService,
              private snackBar: MatSnackBar,
              private formBuilder: FormBuilder,
              private _fuseTranslationLoaderService: FuseTranslationLoaderService) {
    this.mode = data.mode;
    this.transportUuid = data.transportUuid;

    this._fuseTranslationLoaderService.loadTranslations(polish, english);
  }

  ngOnInit() {
    this.createForm({});

    if (this.mode === 'edit' || this.mode === 'view') {
      this.transportService.getTransport(this.transportUuid).subscribe(
        res => {
          this.transport = res;
          this.createForm(this.transport);
        }
      );
    }
  }

  public submitTransport() {
    this.isLoading = true;
    markFormGroupTouched(this.transportForm);
    if (this.transportForm.invalid) {
      this.isLoading = false;
      return;
    }
    if (this.mode === 'add') {
      this.transportService.createTransport(parseForm(this.transportForm.value)).subscribe(
        () => {
          this.transportEditorDialogRef.close();

          this.transportEditorDialogRef.afterClosed().subscribe(() => {
            this.transportService.onEditEvent.emit();
          });

          this.snackBar.open(this.translateService.instant('TRANSPORT.MESSAGES.CREATE_SUCCESS'), '', {duration: 5000});
        }, () => {
          this.isLoading = false;
          this.snackBar.open(this.translateService.instant('TRANSPORT.MESSAGES.CREATE_ERROR'), '', {duration: 5000});
        }
      );
    } else {
      this.transportService.updateTransport(this.transportUuid, parseForm(this.transportForm.value)).subscribe(
        () => {
          this.transportEditorDialogRef.close();

          this.transportEditorDialogRef.afterClosed().subscribe(() => {
            this.transportService.onEditEvent.emit();
          });

          this.snackBar.open(this.translateService.instant('TRANSPORT.MESSAGES.UPDATE_SUCCESS'), '', {duration: 5000});
        }, () => {
          this.isLoading = false;
          this.snackBar.open(this.translateService.instant('TRANSPORT.MESSAGES.UPDATE_ERROR'), '', {duration: 5000});
        }
      );
    }
  }

  public deleteTransport() {
    let deleteDialogRef = this.matDialog.open(ConfirmModalComponent, {
      disableClose: false,
      autoFocus: false
    });

    deleteDialogRef.componentInstance.confirmMessage = this.translateService.instant('TRANSPORT.MESSAGES.DELETE_QUESTION');
    deleteDialogRef.componentInstance.titleMessage = this.translateService.instant('TRANSPORT.MESSAGES.DELETE_TRANSPORT_TITLE');
    deleteDialogRef.componentInstance.confirmButton = this.translateService.instant('TRANSPORT.DELETE');

    deleteDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.transportService.deleteTransport(this.transportUuid).subscribe(
          () => {
            this.transportEditorDialogRef.close();
            this.transportEditorDialogRef.afterClosed().subscribe(() => {
              this.transportService.onEditEvent.emit();
            });
            this.snackBar.open(this.translateService.instant('TRANSPORT.MESSAGES.DELETE_SUCCESS'), '', {duration: 5000});
          }, () => {
            this.snackBar.open(this.translateService.instant('TRANSPORT.MESSAGES.DELETE_ERROR'), '', {duration: 5000});
          }
        );
      }
    });
  }

  public enableEditMode() {
    this.transportEditorDialogRef.close();
    this.matDialog.open(TransportEditorComponent, {
      panelClass: 'transport-editor-dialog',
      autoFocus: false,
      disableClose: true,
      data: {
        mode: 'edit',
        transportUuid: this.transportUuid
      }
    });
  }

  public getTitle(): string {
    switch (this.mode) {
      case 'add':
        return this.translateService.instant('TRANSPORT.TITLE_ADD');
      case 'edit':
        return this.translateService.instant('TRANSPORT.TITLE_EDIT');
      case 'view':
        return this.translateService.instant('TRANSPORT.TITLE_VIEW');
      default:
        return;
    }
  }

  private createForm(inputData: Transport | any) {
    this.transportForm = this.formBuilder.group({
      name: new FormControl(inputData.name || '', Validators.required),
      description: new FormControl(inputData.description || ''),
      capacity: new FormControl(inputData.capacity || null,
        [Validators.pattern('[\\d]+[.]{0,1}[\\d]{0,2}'), ValidateGreaterThanZero]),
      volume: new FormControl(inputData.volume || null,
        [Validators.pattern('[\\d]+[.]{0,1}[\\d]{0,2}'), ValidateGreaterThanZero]),
      category: new FormControl(inputData.category || null, Validators.required),
      active: new FormControl(this.mode === 'add' ? true : inputData.active),
    });

    if (this.mode === 'view') {
      this.transportForm.disable();
    }
  }
}
