import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FuseTranslationLoaderService } from '../../../../../../@fuse/services/translation-loader.service';
import { locale as polish } from '../../../../../translations/pl';
import { locale as english } from '../../../../../translations/en';
import { fuseAnimations } from '../../../../../../@fuse/animations';
import { remondisListAnimation } from '../../../../../shared/consts/remondis.lists.animation';
import { List } from '../../../../../shared/models/list.class';
import { User } from '../../../../../shared/models/users.model';
import {
  ListFilters,
  RequestParams
} from '../../../../../shared/models/list.model';
import { AuthService } from '../../../../../services/auth.service';
import {
  CustomerCompanyCar,
  CompanyCarsListRespone
} from '../../../../../shared/models/company-cars.model';
import { CompanyCarsService } from '../../../../../services/company-cars.service';
import { CompanyCarEditorComponent } from '../../../../company-cars/components/company-car-editor/company-car-editor.component';

@Component({
  selector: 'app-company-cars-list',
  templateUrl: './company-cars-list.component.html',
  styleUrls: ['./company-cars-list.component.scss'],
  animations: [...fuseAnimations, ...remondisListAnimation]
})
export class CompanyCarsListComponent extends List
  implements OnInit, OnDestroy {
  @Input() companyUuid: string;
  public displayedColumns = ['name', 'type', 'registration', 'customId', 'companyName'];
  public user: User;
  private listFilters: ListFilters = {};
  public requestParams: RequestParams = { page: 0, limit: 500 };
  private reloadList$;

  constructor(
    private companyCarsService: CompanyCarsService,
    private authService: AuthService,
    private matDialog: MatDialog,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService
  ) {
    super();
    this.user = this.authService.getUser();

    this.reloadList$ = companyCarsService.onEditEvent.subscribe(() => {
      this.getElementsList();
    });

    this._fuseTranslationLoaderService.loadTranslations(polish, english);

    this.getElementsList = () => {
      this.companyCarsService
        .getCompanyCarsList(this.requestParams, this.companyUuid)
        .subscribe((res: CompanyCarsListRespone) => {
          this.totalCount = res.cars.length;
          this.dataSource = res.cars;
        });
    };
  }

  ngOnInit() {
    super.ngOnInit();
    this.getElementsList();
  }

  ngOnDestroy() {
    this.reloadList$.unsubscribe();
  }

  public addCompanyCar() {
    this.disableAnimations = true;
    this.matDialog.open(CompanyCarEditorComponent, {
      panelClass: 'company-car-editor-dialog',
      autoFocus: false,
      disableClose: true,
      data: {
        mode: 'add',
        companyUuid: this.companyUuid
      }
    });
  }

  public viewCompanyCar(companyCarUuid: CustomerCompanyCar['uuid']) {
    this.disableAnimations = true;
    this.matDialog.open(CompanyCarEditorComponent, {
      panelClass: 'company-car-editor-dialog',
      autoFocus: false,
      disableClose: true,
      data: {
        mode: 'view',
        companyUuid: this.companyUuid,
        companyCarUuid: companyCarUuid
      }
    });
  }
}
