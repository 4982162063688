import { NgModule } from '@angular/core';
import { TransportContainerComponent } from './transport-container.component';
import { TransportListComponent } from './components/transport-list/transport-list.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { Routes } from './transport.routes';
import { TransportEditorComponent } from './components/transport-editor/transport-editor.component';

@NgModule({
  declarations: [
    TransportContainerComponent,
    TransportListComponent,
    TransportEditorComponent
  ],
  imports: [
    RouterModule.forChild(Routes),
    SharedModule,
  ],
  exports: [
    TransportListComponent,
    TransportEditorComponent
  ],
  entryComponents: [
    TransportEditorComponent
  ]
})
export class TransportModule { }
