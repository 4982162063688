import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
  MatSnackBar,
} from "@angular/material";
import { TranslateService } from "@ngx-translate/core";
import { FuseTranslationLoaderService } from "../../../../../@fuse/services/translation-loader.service";
import { AuthService } from "../../../../services/auth.service";
import { OrdersService } from "../../../../services/orders.service";
import { TasksService } from "../../../../services/tasks.service";
import {
  HistoryItemNew,
  ItemsOrigins,
  OrderHistoryListResponse,
  TaskHistoryListResponse,
} from "../../../../shared/models/history.model";
import { List } from "../../../../shared/models/list.class";
import { RequestParams } from "../../../../shared/models/list.model";
import { Order } from "../../../../shared/models/orders.model";
import { Task } from "../../../../shared/models/tasks.model";
import { locale as english } from "../../../../translations/en";
import { locale as polish } from "../../../../translations/pl";
import { compareObjectsChanges } from "./utils/compare-objects-function";

@Component({
  selector: "app-history-dialog",
  templateUrl: "./history-dialog.component.html",
  styleUrls: ["./history-dialog.component.scss"],
})
export class HistoryDialogComponent extends List implements OnInit {
  public item;
  public itemOrigin: string;
  public itemId: Order["uuid"] | Task["uuid"];
  public itemNumber: Order["orderNumber"] | Task["taskNumber"];
  public noValueHelperString: string;
  public dataSourceNew: HistoryItemNew[];
  public requestParams: RequestParams = {
    page: 1,
    limit: 20,
    sort: "eventDate",
    order: "asc",
  };

  constructor(
    public historyDialogRef: MatDialogRef<HistoryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private matDialog: MatDialog,
    private ordersService: OrdersService,
    private tasksService: TasksService,
    private authService: AuthService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService
  ) {
    super();
    this._fuseTranslationLoaderService.loadTranslations(polish, english);

    this.item = data.item;
    this.itemOrigin = data.itemOrigin;
    this.itemId = data.itemId;
    this.itemNumber = data.itemNumber;
    this.noValueHelperString = this.translateService.instant(
      "HISTORY.HISTORY_KEYS.noValue"
    );

    this.getElementsList = () => {
      if (this.itemOrigin === ItemsOrigins.Order) {
        this.ordersService
          .getOrderHistoryNew(this.itemId, this.requestParams)
          .subscribe((res2: OrderHistoryListResponse) => {
            this.totalCount = res2.totalCount;
            this.dataSourceNew = res2.orderHistory.map((item) => {
              return item;
            });
          });
      } else if (this.itemOrigin === ItemsOrigins.Task) {
        this.tasksService
          .getTaskHistory(this.itemId, this.requestParams)
          .subscribe((res: TaskHistoryListResponse) => {
            this.totalCount = res.totalCount;
            this.dataSource = res.taskHistory.map((item) => {
              return compareObjectsChanges(item, this.noValueHelperString);
            });
          });
      }
    };
  }

  ngOnInit() {
    super.ngOnInit();
    this.getElementsList();
  }

  public toggleOrder(order) {
    this.requestParams.order = order;
    this.requestParams.page = 1;
    this.getElementsList();
  }
}
