import { Component, Input } from '@angular/core';
import { PackageType } from '../../../../../../shared/models/package.model';

@Component({
  selector: 'app-package-types-display',
  templateUrl: './package-types-display.component.html',
  styleUrls: ['./package-types-display.component.scss']
})
export class PackageTypesDisplayComponent {
  @Input() packageTypes: PackageType[];
}
