import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recycling-items.container',
  templateUrl: './recycling-items.container.component.html',
  styleUrls: ['./recycling-items.container.component.scss']
})
export class RecyclingItemsContainerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
