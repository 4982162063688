import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Transport, TransportListRespone } from '../shared/models/transport.model';
import { AuthService } from './auth.service';
import { RequestParams } from '../shared/models/list.model';

@Injectable({
  providedIn: 'root'
})
export class TransportService {
  onEditEvent: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient, private authService: AuthService) {
  }

  getTransportList(params: RequestParams): Observable<TransportListRespone> {
    return this.http.get(`${environment.apiUrl}/${this.authService.getUserType()}/transport`, {params: params})
      .pipe(map((res: TransportListRespone) => res));
  }

  getTransport(transportUuid: Transport['uuid']): Observable<Transport> {
    return this.http.get(`${environment.apiUrl}/${this.authService.getUserType()}/transport/${transportUuid}`).pipe(
      map((res: any) => res.transport)
    );
  }

  createTransport(transport: Transport): Observable<Transport> {
    return this.http.post<any>(`${environment.apiUrl}/${this.authService.getUserType()}/transport`, transport).pipe(
      map((res: any) => res.transport)
    );
  }

  updateTransport(transportUuid: Transport['uuid'], transport: Transport): Observable<Transport> {
    return this.http.put<any>(`${environment.apiUrl}/${this.authService.getUserType()}/transport/${transportUuid}`, transport);
  }

  deleteTransport(transportUuid: Transport['uuid']) {
    return this.http.delete<any>(`${environment.apiUrl}/${this.authService.getUserType()}/transport/${transportUuid}`);
  }
}