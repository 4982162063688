import { Component, HostListener, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { OrdersService } from '../../../../services/orders.service';
import { locale as polish } from '../../../../translations/pl';
import { locale as english } from '../../../../translations/en';
import { OrderTypes } from '../../../../shared/consts/orders';
import { OrderExportFormComponent } from '../order-forms/order-export-form/order-export-form.component';
import { User, UserTypes } from '../../../../shared/models/users.model';
import { AuthService } from '../../../../services/auth.service';
import { OrderImportFormComponent } from '../order-forms/order-import-form/order-import-form.component';
import { OrderReceivingFormComponent } from '../order-forms/order-receiving-form/order-receiving-form.component';

@Component({
  selector: 'app-order-type-picker',
  templateUrl: './order-type-picker.component.html',
  styleUrls: ['./order-type-picker.component.scss']
})
export class OrderTypePickerComponent {
  public orderTypes = OrderTypes;
  public mode: string;
  public user: User;
  public userTypes = UserTypes;

  constructor(public orderTypePickerDialogRef: MatDialogRef<OrderTypePickerComponent>,
              @Inject(MAT_DIALOG_DATA) private data: any,
              private matDialog: MatDialog,
              private ordersService: OrdersService,
              private authService: AuthService,
              private translateService: TranslateService,
              private snackBar: MatSnackBar,
              private formBuilder: FormBuilder,
              private _fuseTranslationLoaderService: FuseTranslationLoaderService) {
    this.mode = data.mode;
    this.user = this.authService.user.user;

    this._fuseTranslationLoaderService.loadTranslations(polish, english);
  }

  public setOrderType(type) {
    if (type === OrderTypes.Import) {
      this.orderTypePickerDialogRef.close();
      this.matDialog.open(OrderImportFormComponent, {
        panelClass: 'order-editor-dialog',
        autoFocus: false,
        disableClose: true,
        data: {
          mode: this.mode
        }
      });
    } else if (type === OrderTypes.Receiving) {
      this.orderTypePickerDialogRef.close();
      this.matDialog.open(OrderReceivingFormComponent, {
        panelClass: 'order-editor-dialog',
        autoFocus: false,
        disableClose: true,
        data: {
          mode: this.mode
        }
      });
    } else {
      this.orderTypePickerDialogRef.close();
      this.matDialog.open(OrderExportFormComponent, {
        panelClass: 'order-editor-dialog',
        autoFocus: false,
        disableClose: true,
        data: {
          mode: this.mode
        }
      });
    }
  }

  @HostListener('window:keyup.esc') 
  closeDialog() {
      this.orderTypePickerDialogRef.close();
  }

}
