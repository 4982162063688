<div class="pdf-container">
    <button mat-raised-button
            type="button"
            class="pdf-button"
            color="warn"
            [attr.data-cy]="'getReport'"
            (click)="getReport()"
            matTooltip="{{'TASKS.DOWNLOAD_PDF.TOOLTIP' | translate}}" matTooltipPosition="left">
      {{'TASKS.DOWNLOAD_PDF.BUTTON' | translate}}
        <mat-icon *ngIf="buttonLoading"><mat-spinner color="accent" diameter="20">
        </mat-spinner></mat-icon>
    </button>
</div>

