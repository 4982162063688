import { HttpClient } from "@angular/common/http";
import { EventEmitter } from "@angular/core";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { AuthService } from "./auth.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./auth.service";
var OrdersService = /** @class */ (function () {
    function OrdersService(http, authService) {
        this.http = http;
        this.authService = authService;
        this.onEditEvent = new EventEmitter();
    }
    OrdersService.prototype.getOrdersList = function (params) {
        return this.http
            .get(environment.apiUrl + "/" + this.authService.getUserType() + "/orders", {
            params: params,
        })
            .pipe(map(function (res) { return res; }));
    };
    OrdersService.prototype.getOrdersListFilteredByTaskDate = function (params) {
        return this.http
            .get(environment.apiUrl + "/" + this.authService.getUserType() + "/tasks/orders", { params: params })
            .pipe(map(function (res) { return res; }));
    };
    OrdersService.prototype.closeOrders = function (orderIds) {
        return this.http.post(environment.apiUrl + "/" + this.authService.getUserType() + "/orders/completed", { orderIds: orderIds });
    };
    OrdersService.prototype.getOrder = function (orderTypeUuid) {
        var _this = this;
        return this.http
            .get(environment.apiUrl + "/" + this.authService.getUserType() + "/orders/" + orderTypeUuid)
            .pipe(map(function (res) {
            res.order &&
                res.order.files &&
                res.order.files.forEach(function (file) {
                    file.url = environment.apiUrl + "/" + _this.authService.getUserType() + "/orders/" + res.order.uuid + "/files/" + file.fileId;
                });
            return res.order;
        }));
    };
    OrdersService.prototype.getOrderHistory = function (orderTypeUuid, params) {
        return this.http.get(environment.apiUrl + "/" + this.authService.getUserType() + "/orders/" + orderTypeUuid + "/history", { params: params });
    };
    OrdersService.prototype.getOrderHistoryNew = function (orderTypeUuid, params) {
        return this.http.get(environment.apiUrl + "/" + this.authService.getUserType() + "/orders/" + orderTypeUuid + "/history/v2", { params: params });
    };
    OrdersService.prototype.createOrder = function (order) {
        return this.http
            .post(environment.apiUrl + "/" + this.authService.getUserType() + "/orders", order)
            .pipe(map(function (res) { return res.order; }));
    };
    OrdersService.prototype.updateOrder = function (orderId, order) {
        return this.http.put(environment.apiUrl + "/" + this.authService.getUserType() + "/orders/" + orderId, order);
    };
    OrdersService.prototype.updateOrderDate = function (orderId, suggestedDate) {
        return this.http.put(environment.apiUrl + "/" + this.authService.getUserType() + "/orders/" + orderId + "/set-date", { suggestedDate: suggestedDate });
    };
    OrdersService.prototype.changeOrderStatus = function (orderId, status) {
        return this.http
            .post(environment.apiUrl + "/" + this.authService.getUserType() + "/orders/" + orderId + "/" + status, {})
            .pipe(map(function (res) { return res.order; }));
    };
    OrdersService.prototype.deleteOrder = function (orderId) {
        return this.http.delete(environment.apiUrl + "/" + this.authService.getUserType() + "/orders/" + orderId);
    };
    OrdersService.prototype.sendOrderLink = function (email) {
        return this.http
            .post(environment.apiUrl + "/" + this.authService.getUserType() + "/orders/public", { email: email })
            .pipe(map(function (res) { return res.order; }));
    };
    OrdersService.prototype.resendNotification = function (orderId) {
        return this.http
            .post(environment.apiUrl + "/" + this.authService.getUserType() + "/orders/" + orderId + "/send-notified-notification", {})
            .pipe(map(function (res) { return res.order; }));
    };
    OrdersService.prototype.addFile = function (orderUuid, file) {
        return this.http
            .post(environment.apiUrl + "/" + this.authService.getUserType() + "/orders/" + orderUuid + "/files", file)
            .pipe(map(function (res) { return res.file; }));
    };
    OrdersService.prototype.deleteFile = function (orderUuid, fileId) {
        return this.http
            .delete(environment.apiUrl + "/" + this.authService.getUserType() + "/orders/" + orderUuid + "/files/" + fileId)
            .pipe(map(function (res) { return res.order; }));
    };
    OrdersService.ngInjectableDef = i0.defineInjectable({ factory: function OrdersService_Factory() { return new OrdersService(i0.inject(i1.HttpClient), i0.inject(i2.AuthService)); }, token: OrdersService, providedIn: "root" });
    return OrdersService;
}());
export { OrdersService };
