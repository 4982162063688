import {Component, OnDestroy, OnInit} from '@angular/core';
import {FuseTranslationLoaderService} from '../../../../../@fuse/services/translation-loader.service';
import {locale as polish} from '../../../../translations/pl';
import {locale as english} from '../../../../translations/en';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {TranslateService} from '@ngx-translate/core';
import {SelectionModel} from '@angular/cdk/collections';
import {MatDialog} from '@angular/material';
import * as _ from 'lodash';
import {List} from '../../../../shared/models/list.class';
import {ListFilters} from '../../../../shared/models/list.model';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {remondisListAnimation} from '../../../../shared/consts/remondis.lists.animation';
import {User, UserTypes} from '../../../../shared/models/users.model';
import {FormControl} from '@angular/forms';
import {AuthService} from '../../../../services/auth.service';
import {UsersService} from '../../../../services/users.service';
import {registerLocaleData} from '@angular/common';
import localePl from '@angular/common/locales/pl';
import {TasksService} from '../../../../services/tasks.service';
import {Task, TasksListResponse, TaskStatuses, TaskTypes} from '../../../../shared/models/tasks.model';
import {TaskTypePickerComponent} from '../task-type-picker/task-type-picker.component';
import {TaskExportFormComponent} from '../task-forms/task-export-form/task-export-form.component';
import {TaskImportFormComponent} from '../task-forms/task-import-form/task-import-form.component';
import {TaskReceivingFormComponent} from '../task-forms/task-receiving-form/task-receiving-form.component';
import {TaskInvoiceModalComponent} from '../task-invoice-modal/task-invoice-modal.component';


@Component({
  selector: 'app-tasks-list',
  templateUrl: './tasks-list.component.html',
  styleUrls: ['./tasks-list.component.scss'],
  animations: [...fuseAnimations, ...remondisListAnimation,
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0', display: 'none'})),
      state('expanded', style({minHeight: '80px'})),
      transition('expanded <=> collapsed', animate('2225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class TasksListComponent extends List implements OnInit, OnDestroy {
  public displayedColumns = ['select', 'taskNumber', 'createdDate', 'startDate', 'taskName', 'forwarderExpense',
    'forwarderInvoiceNumber', 'forwarderCompanyName', 'status', 'forwarderRegistrationNumber', 'forwarderDriver',
    'forwarderCarType', 'comment', 'cargo', 'suggestedDate', 'taskType'];
  public user: User;
  public userTypes = UserTypes;
  public companyBranches = [];
  public searchTask: FormControl;
  public tableFilters = ['taskType'];
  public textFilters = ['forwarderInvoiceNumber', 'forwarderCompanyName'];
  public tableStatus = ['taskStatus'];
  public objectKeys = Object.keys;
  public branchIndex = new FormControl(0);
  public companyBranchId: string;
  public companyBranchName: string;
  public invoicesTotalAmount: number;
  public taskTypes = TaskTypes;
  public taskStatuses = TaskStatuses;
  private listFilters: ListFilters = {};
  private reloadList$;
  private passInvoiceDialogRef;
  public selection = new SelectionModel<Task>(true, []);

  constructor(private tasksService: TasksService,
              private matDialog: MatDialog,
              private authService: AuthService,
              private usersService: UsersService,
              private translateService: TranslateService,
              private _fuseTranslationLoaderService: FuseTranslationLoaderService) {
    super();
    registerLocaleData(localePl);
    this.user = this.authService.user.user;

    this._fuseTranslationLoaderService.loadTranslations(polish, english);

    this.requestParams.sort = 'createdDate';
    this.requestParams.order = 'desc';

    this.reloadList$ = tasksService.onEditEvent.subscribe(
      () => {
        this.getElementsList();
      });

    this.getElementsList = () => {
      this.tasksService.getTasksList(this.getQueryParams())
        .subscribe((res: TasksListResponse) => {
          res.tasks.map(task => {
            task.companies = [];
            task.customerCompanyBranches = [];
            task.packages = [];
            if (task.taskType === TaskTypes.Import || task.taskType === TaskTypes.Receiving) {
              task.packages = [];
              task.taskData.forEach(order => {
                order.orderData.forEach(data => {
                  data.packageTypes.forEach(packageType => {
                    if (task.packages.filter(type => type.name === packageType.name).length === 0) {
                      task.packages.push(packageType);
                    } else {
                      task.packages.forEach(item => {
                        if (item.name === packageType.name) {
                          item.value += packageType.value;
                        }
                      });
                    }
                  });
                });
              });
            }
            if (task.forwarderExpense) {
              task.forwarderExpense = task.forwarderExpense.toString().replace('.', ',');
            }
            task.taskData.forEach(order => {
              task.customerCompanyBranches.push(order.customerCompanyBranch);
              if (task.companies.filter(item => item.uuid === order.customerCompany.uuid).length === 0) {
                task.companies.push(order.customerCompany);
              }
            });
          });
          this.totalCount = res.totalCount;
          this.dataSource = res.tasks;
          this.invoicesTotalAmount = res.invoiceAmount
        });
    };
  }

  ngOnInit() {
    super.ngOnInit();
    this.createSearchFormControl();

    if (this.user.userType !== this.userTypes.Manager) {
      this.usersService.getCompanyBranchList().subscribe(
        res => {
          res.branches.forEach(branch => {
            this.companyBranches.push({name: branch.name, value: branch.uuid})
          });
          this.branchIndex.setValue(this.companyBranches.findIndex(branch => branch.value === this.user.companyBranchId));
          this.companyBranchId = this.companyBranches[this.branchIndex.value].value;
          this.companyBranchName = this.companyBranches[this.branchIndex.value].name;
        }
      );
    }
  }

  ngOnDestroy() {
    this.reloadList$.unsubscribe();
  }

  public masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() : this.dataSource.forEach(row => row.status === this.taskStatuses.Realized && this.selection.select(row));
  }

  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.length;
    return numSelected === numRows;
  }

  public clearSelect() {
    this.selection = new SelectionModel<Task>(true, []);
  }

  public passInvoiceNumber(event: Event) {
    event.stopPropagation();
    const selected = _.uniqBy(this.selection.selected, 'uuid');
    this.passInvoiceDialogRef = this.matDialog.open(TaskInvoiceModalComponent, {
      panelClass: 'task-invoice-dialog',
      autoFocus: false,
      disableClose: true,
      data: {
        taskIds: selected.map(task => task.uuid)
      }
    });
    this.passInvoiceDialogRef.afterClosed().subscribe(() => {
      this.getElementsList();
    });
    this.clearSelect();
  }

  public clearSearch() {
    if (this.searchTask.value) {
      this.searchTask.setValue('');
    }
  }

  public getListFilters(filters: ListFilters) {
    this.listFilters = Object.assign({}, filters, {companyBranchId: this.companyBranchId});
    this.requestParams.page = 1;
    this.disableAnimations = true;
    this.getElementsList();
  }

  private createSearchFormControl() {
    this.searchTask = new FormControl('');
    this.searchTask.valueChanges.debounceTime(300).subscribe((value) => {
      this.requestParams.search = value;
      this.requestParams.page = 1;
      this.disableAnimations = true;
      this.getElementsList();
    });
  }

  private getQueryParams() {
    if (this.listFilters.companyBranchId) {
      return Object.assign({}, this.requestParams, this.listFilters);
    }
  }

  public getBranch(index: number) {
    this.branchIndex.setValue(index);
    this.companyBranchId = this.companyBranches[this.branchIndex.value].value;
    this.companyBranchName = this.companyBranches[this.branchIndex.value].name;
    this.getListFilters(this.listFilters);
  }


  public addTask() {
    if (this.user.userType === this.userTypes.Logistician && this.companyBranchId) {
      this.matDialog.open(TaskTypePickerComponent, {
        panelClass: 'task-type-picker',
        autoFocus: false,
        disableClose: true,
        data: {
          mode: 'add',
          companyBranchId: this.companyBranchId,
        }
      });
    }
  }

  public editTask(taskId: string, taskType: string) {
    if (this.user.userType === this.userTypes.Logistician) {
      if (taskType === 'export') {
        this.matDialog.open(TaskExportFormComponent, {
          panelClass: 'task-editor-dialog',
          autoFocus: false,
          disableClose: true,
          data: {
            mode: 'view',
            taskId: taskId
          }
        });
      } else if (taskType === 'import') {
        this.matDialog.open(TaskImportFormComponent, {
          panelClass: 'task-editor-dialog',
          autoFocus: false,
          disableClose: true,
          data: {
            mode: 'view',
            taskId: taskId
          }
        });
      } else if (taskType === 'receiving') {
        this.matDialog.open(TaskReceivingFormComponent, {
          panelClass: 'task-editor-dialog',
          autoFocus: false,
          disableClose: true,
          data: {
            mode: 'view',
            taskId: taskId
          }
        });
      }
    }
  }

  public downloadExcel() {
    this.tasksService.getTasksExcel(this.getQueryParams()).subscribe(
      blob => {
        const a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = 'tasks.xlsx';
        // start download
        a.click();
      });
  }
}
