import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { PackageType, PackageTypesListRespone } from '../shared/models/package.model';
import { RequestParams } from '../shared/models/list.model';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class PackageService {
  onEditEvent: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient, private authService: AuthService) {
  }

  getPackageTypesList(params: RequestParams): Observable<PackageTypesListRespone> {
    return this.http.get(`${environment.apiUrl}/${this.authService.getUserType()}/package`, {params: params})
      .pipe(map((res: PackageTypesListRespone) => res));
  }

  getPackage(packageTypeUuid: PackageType['uuid']): Observable<PackageType> {
    return this.http.get(`${environment.apiUrl}/${this.authService.getUserType()}/package/${packageTypeUuid}`).pipe(
      map((res: any) => res.packageType)
    );
  }

  createPackageType(packageType: PackageType): Observable<PackageType> {
    return this.http.post<any>(`${environment.apiUrl}/${this.authService.getUserType()}/package`, packageType).pipe(
      map((res: any) => res.packageType)
    );
  }

  updatePackageType(packageTypeUuid: PackageType['uuid'], packageType: PackageType): Observable<PackageType> {
    return this.http.put<any>(`${environment.apiUrl}/${this.authService.getUserType()}/package/${packageTypeUuid}`, packageType);
  }

  deletePackageType(packageTypeUuid: PackageType['uuid']) {
    return this.http.delete<any>(`${environment.apiUrl}/${this.authService.getUserType()}/package/${packageTypeUuid}`);
  }
}