import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {locale as polish} from '../../../../translations/pl';
import {locale as english} from '../../../../translations/en';
import {TasksService} from '../../../../services/tasks.service';
import {FuseTranslationLoaderService} from '../../../../../@fuse/services/translation-loader.service';

@Component({
  selector: 'app-task-invoice-modal',
  templateUrl: './task-invoice-modal.component.html',
  styleUrls: ['./task-invoice-modal.component.scss']
})
export class TaskInvoiceModalComponent implements OnInit {
  taskIds: string[];
  isLoading = false;
  invoiceForm: FormGroup;

  constructor(public taskInvoiceModalDialogRef: MatDialogRef<TaskInvoiceModalComponent>,
              @Inject(MAT_DIALOG_DATA) private data: any,
              private matDialog: MatDialog,
              private snackBar: MatSnackBar,
              private formBuilder: FormBuilder,
              private tasksService: TasksService,
              private translateService: TranslateService,
              private _fuseTranslationLoaderService: FuseTranslationLoaderService) {

    this._fuseTranslationLoaderService.loadTranslations(polish, english);
    this.taskIds = data.taskIds;
  }

  ngOnInit() {
    this.createForm({});
  }

  private createForm(inputData) {
    this.invoiceForm = this.formBuilder.group({
      invoiceNumber: new FormControl(inputData.invoice || '', Validators.required),
    });
  }

  public save() {
    const data = this.invoiceForm.getRawValue();
    data.tasksIds = this.taskIds;
    this.tasksService.passInvoiceNumberToTasks(data).subscribe(() => {
       this.taskInvoiceModalDialogRef.close();
      this.snackBar.open(this.translateService.instant('TASKS.MESSAGES.UPDATE_SUCCESS'), '', {duration: 5000});
    }, () => {
      this.snackBar.open(this.translateService.instant('TASKS.MESSAGES.UPDATE_ERROR'), '', {duration: 5000});
    });
  }

}
