import {FormGroup} from '@angular/forms';

export const markFormGroupTouched = function (formGroup: FormGroup) {
  (<any>Object).values(formGroup.controls).forEach(control => {
    control.markAsTouched();
    control.markAsDirty();

    if (control.controls) {
      markFormGroupTouched(control);
    }
  });
};