import * as moment from 'moment';
import { FilteredOrderData, FilteredPackageType } from '../models/filtered-items.model';

export function mapOrderDataToUuid(list) {
  if (list) {
    const orderData = list.map((item, index) => ({
      recyclingItem: {
        uuid: item.recyclingItem.uuid,
        name: item.recyclingItem.name,
        code: item.recyclingItem.code,
        description: item.recyclingItem.description,
        unit: item.unit ? item.unit : item.recyclingItem.unit,
        value: item.value ? item.value : item.recyclingItem.value,
        weightPerPiece: item.recyclingItem.weightPerPiece ? item.recyclingItem.weightPerPiece :item.recyclingItem.mass.weightPerPiece,
        weightPerCubicMeter: item.recyclingItem.weightPerCubicMeter ? item.recyclingItem.weightPerCubicMeter : item.recyclingItem.mass.weightPerCubicMeter
      },
      packageTypes: list[ index ].packageTypes.map(pack => {
        let packObject: FilteredPackageType = {
          name: pack.name,
          value: pack.value,
          unit: pack.unit
        };
        if (pack.uuid) {
          packObject.uuid = pack.uuid;
        } else {
          packObject.uuid = 'other'
        }
        return packObject;
      })

    }));
    return orderData;
  }
}

export function mapOrderDataApiValues(list: FilteredOrderData[]) {
  if (list) {
    const orderData = list.map((item, index) => ({
      recyclingItem: {
        uuid: item.recyclingItem.uuid,
        name: item.recyclingItem.name,
        code: item.recyclingItem.code,
        description: item.recyclingItem.description,
        unit: item.recyclingItem.unit,
        value: item.recyclingItem.value
      },
      packageTypes: list[ index ].packageTypes.map(pack => {
        let packObject: FilteredPackageType = {
          name: pack.name,
          value: pack.value,
          unit: pack.unit
        };
        if (pack.uuid !== 'other') {
          packObject.uuid = pack.uuid;
        }
        return packObject;
      })
    }));
    return orderData;
  }
}

export function mapDatetimeToUtc(date, time) {
  const tmpDate = new Date(`${moment(date).format('YYYY/MM/DD')} ${time}`);
  return moment.utc(tmpDate).format('YYYY-MM-DD HH:mm:ss');
}

