<h1 matDialogTitle>{{titleMessage}}</h1>
<div mat-dialog-content>{{confirmMessage}}</div>
<div mat-dialog-actions class="pt-24" fxLayoutAlign="end center">
  <button mat-button class="mr-16" (click)="matDialogRef.close(false)" [attr.data-cy]="'cancel'">
    {{skipButton ? skipButton : 'SHARED.CANCEL' | translate}}
  </button>
  <button mat-raised-button class="mat-accent" (click)="matDialogRef.close(true)" [attr.data-cy]="'confirm'">
    {{confirmButton}}
  </button>
</div>
