var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { locale as polish } from '../../../../translations/pl';
import { locale as english } from '../../../../translations/en';
import { ReportsService } from '../../../../services/reports.service';
import { Months } from '../../../../shared/consts/months';
import * as moment from 'moment';
import { UsersService } from '../../../../services/users.service';
var ReportsListComponent = /** @class */ (function () {
    function ReportsListComponent(reportsService, usersService, _fuseTranslationLoaderService) {
        this.reportsService = reportsService;
        this.usersService = usersService;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this.displayedColumns = [];
        this.reports = [];
        this.columnItems = [];
        this.companyBranchIdFilter = '';
        this.months = Months;
        this.years = [];
        this.currentMonth = moment().month() + 1;
        this.currentYear = moment().year();
        this.companyBranches = [];
        this._fuseTranslationLoaderService.loadTranslations(polish, english);
        this.getYears();
        this.yearFilter = this.currentYear;
        this.monthFilter = this.currentMonth;
    }
    ReportsListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.getReport();
        this.usersService.getCompanyBranchList().subscribe(function (res) {
            _this.companyBranches = res.branches;
        });
    };
    ReportsListComponent.prototype.getTableColumns = function () {
        return ['week', 'date'].concat(this.columnItems, ['sum']);
    };
    ReportsListComponent.prototype.getReport = function () {
        var _this = this;
        var baseFrom = this.yearFilter + "-" + this.monthFilter + "-01";
        var filterParams = {
            dateFrom: moment(baseFrom, 'YYYY-M-DD').format('YYYY-MM-DD'),
            dateTo: moment(baseFrom, 'YYYY-M-DD').endOf('month').format('YYYY-MM-DD'),
            companyBranchId: this.companyBranchIdFilter
        };
        if (!this.companyBranchIdFilter) {
            delete filterParams.companyBranchId;
        }
        this.reportsService.getReports(filterParams).subscribe(function (res) {
            _this.reports = [];
            _this.columnItems = res.itemsList; // column list: "AGD małe", "TV", "NonFood", "lodówki"
            var rowDataTemplate = {
                week: 0,
                date: '',
                sum: 0,
                isFirstDay: false,
                isWeekSum: false,
                isMonthSum: false
            };
            _this.columnItems.forEach(function (item) {
                rowDataTemplate[item] = 0; // add field/property to "class/template"
            });
            _this.monthData = __assign({}, rowDataTemplate);
            _this.monthData.sum = res.totalWeight;
            _this.monthData.isMonthSum = true;
            res.data.forEach(function (week) {
                var weekData = __assign({}, rowDataTemplate);
                weekData.isWeekSum = true;
                weekData.date = '';
                weekData.sum = week.totalWeight;
                week.days.forEach(function (day) {
                    var rowData = __assign({}, rowDataTemplate);
                    if (week.days[0].date === day.date) {
                        rowData.isFirstDay = true;
                    }
                    rowData.week = week.week;
                    rowData.date = moment(day.date).format('DD');
                    rowData.sum = day.totalWeight;
                    day.items.forEach(function (item) {
                        rowData[item.name] = item.totalWeight;
                        weekData[item.name] = weekData[item.name] + rowData[item.name];
                        _this.monthData[item.name] = _this.monthData[item.name] + rowData[item.name];
                    });
                    _this.reports.push(rowData);
                });
                _this.reports.push(weekData);
            });
        });
    };
    ReportsListComponent.prototype.getYears = function () {
        for (var i = 2018; i <= this.currentYear + 1; i++) {
            this.years.unshift(i);
        }
    };
    return ReportsListComponent;
}());
export { ReportsListComponent };
