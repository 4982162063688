import { EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./auth.service";
var RecyclingService = /** @class */ (function () {
    function RecyclingService(http, authService) {
        this.http = http;
        this.authService = authService;
        this.onEditEvent = new EventEmitter();
    }
    RecyclingService.prototype.getRecyclingItemsList = function (params) {
        return this.http.get(environment.apiUrl + "/" + this.authService.getUserType() + "/recycling", { params: params })
            .pipe(map(function (res) { return res; }));
    };
    RecyclingService.prototype.getRecyclingItem = function (recyclingItemUuid) {
        return this.http.get(environment.apiUrl + "/" + this.authService.getUserType() + "/recycling/" + recyclingItemUuid).pipe(map(function (res) { return res.recyclingItem; }));
    };
    RecyclingService.prototype.createRecyclingItem = function (recyclingItem) {
        return this.http.post(environment.apiUrl + "/" + this.authService.getUserType() + "/recycling", recyclingItem).pipe(map(function (res) { return res.recyclingItem; }));
    };
    RecyclingService.prototype.updateRecyclingItem = function (recyclingItemUuid, recyclingItem) {
        return this.http.put(environment.apiUrl + "/" + this.authService.getUserType() + "/recycling/" + recyclingItemUuid, recyclingItem);
    };
    RecyclingService.prototype.deleteRecyclingItem = function (recyclingItemUuid) {
        return this.http.delete(environment.apiUrl + "/" + this.authService.getUserType() + "/recycling/" + recyclingItemUuid);
    };
    RecyclingService.ngInjectableDef = i0.defineInjectable({ factory: function RecyclingService_Factory() { return new RecyclingService(i0.inject(i1.HttpClient), i0.inject(i2.AuthService)); }, token: RecyclingService, providedIn: "root" });
    return RecyclingService;
}());
export { RecyclingService };
