<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">{{getTitle()}}</span>
      <button mat-icon-button (click)="companyCarEditorDialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>
    <form [formGroup]="companyCarForm">
      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'COMPANY_CARS.NAME' | translate}}</mat-label>
          <input formControlName="name" matInput required [attr.data-cy]="'companyCarName'">
        </mat-form-field>
      </div>

      <div class="input-wrapper-order-import">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'COMPANY_CARS.CAR_TYPE' | translate}}</mat-label>
          <mat-select formControlName="type"
                      required>
            <mat-option>
              <ngx-mat-select-search [formControl]="searchTransport"
                                     [placeholderLabel]="'TRANSPORT.SEARCH_PLACEHOLDER' | translate"
                                     [noEntriesFoundLabel]="'SHARED.NO_RESULTS' | translate">
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let transport of transportOptions" [value]="transport.name">
              {{ transport.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'COMPANY_CARS.BDO' | translate}}</mat-label>
          <input formControlName="bdo" matInput required [attr.data-cy]="'companyCarBdo'">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'COMPANY_CARS.COMPANY_NAME' | translate}}</mat-label>
          <input formControlName="companyName" matInput required [attr.data-cy]="'companyCarCompanyName'">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'COMPANY_CARS.REGISTRATION_FRONT' | translate}}</mat-label>
          <input formControlName="registrationNumberFront" matInput required
                 [attr.data-cy]="'companyCarRegistrationNumberFront'">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'COMPANY_CARS.REGISTRATION_BACK' | translate}}</mat-label>
          <input formControlName="registrationNumberBack" matInput required
                 [attr.data-cy]="'companyCarRegistrationNumberBack'">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'COMPANY_CARS.DRIVER_NAME' | translate}}</mat-label>
          <input formControlName="driverName" matInput required [attr.data-cy]="'companyCarDriverName'">
        </mat-form-field>
      </div>

      <div class="input-wrapper" fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'COMPANY_CARS.DRIVER_PHONE' | translate}}</mat-label>
          <input formControlName="driverPhone" matInput required [attr.data-cy]="'companyCarDriverPhone'">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'COMPANY_CARS.COMMENTS' | translate}}</mat-label>
          <textarea formControlName="comments" matInput [attr.data-cy]="'companyCarComments'"></textarea>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'COMPANY_CARS.COMPANY_BRANCH' | translate}}</mat-label>
          <mat-select formControlName="companyBranchId" required [attr.data-cy]="'companyCarCompanyBranchId'">
            <mat-option *ngFor="let branch of companyBranches" [value]="branch.uuid" [attr.data-cy]="branch.name.toUpperCase()">
              {{ branch.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      
    </form>


  </div>

  <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

    <button *ngIf="mode === 'edit'"
            mat-button
            class="mr-8"
            [attr.data-cy]="'companyCarDelete'"
            (click)="deleteCompanyCar()">
      {{'COMPANY_CARS.DELETE' | translate}}
    </button>

    <button *ngIf="mode === 'view'"
            mat-button
            class="mr-8"
            [attr.data-cy]="'companyCarEdit'"
            (click)="enableEditMode()">
      {{'COMPANY_CARS.EDIT' | translate}}
    </button>

    <button *ngIf="mode === 'edit' || mode === 'add'"
            mat-button
            class="save-button"
            [attr.data-cy]="'companyCarSave'"
            (click)="submitCompanyCar()"
            [disabled]="isLoading">
      <span *ngIf="!isLoading"> {{'COMPANY_CARS.SAVE' | translate}}</span>
      <span *ngIf="isLoading" class="loading-spinner-wrapper">
        <mat-progress-spinner mode="indeterminate"
                              [strokeWidth]="2"
                              [diameter]="20">
        </mat-progress-spinner>
      </span>
    </button>

  </div>
</div>