<div class="tags">
  <div class="header p-16 p-sm-24"
       fxLayout.gt-xs="row"
       fxLayoutAlign.gt-xs="space-between center" *ngIf="user">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="logo" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="logo-icon mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
          label
        </mat-icon>
        <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
          {{'TAGS.TITLE_LIST' | translate}}
        </span>
      </div>
    </div>
    <div class="search-wrapper">
      <div class="search">
        <label for="name" class="mr-8">
          <mat-icon (click)="clearSearch()">{{searchTag.value ? 'clear' : 'search'}}</mat-icon>
        </label>
        <input id="name" [formControl]="searchTag" [placeholder]="'TAGS.SEARCH_PLACEHOLDER' | translate">
      </div>
    </div>
  </div>

  <div class="tags-content">
    <app-list-filters class="filters-container"
                      [filters]="['active']"
                      (listFilters)="getListFilters($event)">
    </app-list-filters>
    <div class="tags-table">
      <mat-table
        matSort
        [dataSource]="dataSource"
        [matSortActive]="requestParams.sort"
        [matSortDirection]="requestParams.order"
        [@animateStaggerRemondis]="dataSource.length"
        [@.disabled]="disableAnimations"
      >
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'TAGS.NAME' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let tag"> {{tag.name}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="active">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'TAGS.STATUSES.ACTIVE' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let tag">
            <mat-icon [ngClass]="tag.active && 'green-icon'">
              {{tag.active ? 'check' : 'close'}}
            </mat-icon>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          class="tag-row"
          *matRowDef="let tag; columns: displayedColumns;"
          [@animateRemondis]="{value:'*',params:{y:'100%'}}"
          [id]="tag.uuid"
          (click)="viewTag(tag.uuid)"
        >
        </mat-row>
      </mat-table>

      <h4 class="no-data" *ngIf="totalCount === 0">
        {{'SHARED.NO_DATA' | translate}}
      </h4>

      <div *ngIf="totalCount" class="paginator-container">
        <mat-paginator
          (page)="pageChange($event)"
          [pageSize]="requestParams.limit"
          [pageIndex]="(requestParams.page - 1)"
          [length]="totalCount"
          class="paginator-holder"
        ></mat-paginator>
      </div>
    </div>
  </div>
  <button mat-fab class="add-tag-button"
          [@animate]="{value:'*', params:{delay:'300ms',scale:'.2'}}"
          [matTooltip]="'TAGS.TITLE_ADD' | translate"
          matTooltipPosition="above"
          [attr.data-cy]="'tagAdd'"
          (click)="addTag()">
    <mat-icon>add</mat-icon>
  </button>
</div>

