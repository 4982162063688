import { TranslateService } from "@ngx-translate/core";
import "hammerjs";
import * as Sentry from "@sentry/browser";
import { environment } from "../environments/environment";
import { MatPaginatorI18n } from "./shared/utils/mat-paginator-i18n";
if (environment.sentry) {
    Sentry.init({
        dsn: "https://dcf2887973f544d597723612871aba5b@sentry.io/1434671",
    });
}
var ɵ0 = function (translateService) {
    return new MatPaginatorI18n(translateService).getPaginatorIntl();
};
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
