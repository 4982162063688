import {Component, Input} from '@angular/core';
import {CalendarTaskModel} from '../../calendar.model';
import * as moment from 'moment';
import {TransportCategories} from '../../../../shared/consts/transport.categories';
import {TasksService} from '../../../../services/tasks.service';

@Component({
  selector: 'app-day-events-summary',
  templateUrl: './day-events-summary.component.html',
  styleUrls: ['./day-events-summary.component.scss']
})
export class DayEventsSummaryComponent {
  @Input() private events: CalendarTaskModel[];
  @Input() private diffBetweenStartOfWeek = 0;
  @Input() private selectedDate: Date;
  @Input() private calendarView = 'day';
  @Input() private dailySummary: any = {};
  @Input() private cumulativelySummary: any = {};
  @Input() private totalSummary: any = {};
  public displayDate: any = {};
  public transportCategories = TransportCategories;

  constructor(private taskService: TasksService) {
  }

  public getTransportCategoryCount = (category: number) => {
    let dayCategoryCount = 0;
    let eventsFiltered = this.events.length && this.events.filter((event) => {
      const start = moment(event.start).format('YYYY-MM-DD');
      const end = moment(event.end).format('YYYY-MM-DD');
      const baseDate = this.calendarView !== 'day' ? moment(this.selectedDate).startOf('week').format('YYYY-MM-DD')
        : moment(this.selectedDate).format('YYYY-MM-DD');

      return moment(start).diff(moment(baseDate), 'days') === this.diffBetweenStartOfWeek ||
        moment(end).diff(moment(baseDate), 'days') === this.diffBetweenStartOfWeek;
    });

    if (eventsFiltered.length) {
      eventsFiltered.forEach((event) => {
        if (event.transport && event.transport.category === category && event.status !== 'cancelled' && event.type !== 'export') {
          dayCategoryCount++;
        }
      });
    }

    return dayCategoryCount;
  }

  public getValue(from: string, summary: boolean) {
    if (summary && this.cumulativelySummary) {
      return this.cumulativelySummary[from];
    } else if (!summary && this.dailySummary) {
      return this.dailySummary[from];
    } else {
      return '-';
    }
  }

  public getTotal(from: string) {
    const baseDate = this.calendarView !== 'day' ? moment(this.selectedDate).startOf('week').format('YYYY-MM-DD')
      : moment(this.selectedDate).format('YYYY-MM-DD');
    const date = moment(baseDate).add(this.diffBetweenStartOfWeek, 'days');
    return this.totalSummary && this.totalSummary[from] ? this.totalSummary[from][date.month()] : '-';
  }
}
