<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">{{getTitle()}}</span>
      <button mat-icon-button (click)="fractionEditorDialogRef.close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>

    <form [formGroup]="fractionForm">

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'FRACTIONS.NAME' | translate}}</mat-label>
          <mat-icon matSuffix class="secondary-text">dashboard</mat-icon>
          <input name="name" formControlName="name" matInput required [attr.data-cy]="'fractionName'">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'FRACTIONS.CODE' | translate}}</mat-label>
          <mat-icon matSuffix class="secondary-text">select_all</mat-icon>
          <input name="code" formControlName="code" matInput [attr.data-cy]="'fractionCode'">
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'FRACTIONS.DESC' | translate}}</mat-label>
          <textarea name="description" formControlName="description" matInput [attr.data-cy]="'fractionDescription'"></textarea>
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutAlign="start start">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'FRACTIONS.STATUS' | translate}}</mat-label>
          <mat-select formControlName="active" [attr.data-cy]="'fractionIsActive'">
            <mat-option [value]="true" [attr.data-cy]="'ACTIVE'">{{'FRACTIONS.STATUSES.ACTIVE' | translate}}</mat-option>
            <mat-option [value]="false" [attr.data-cy]="'INACTIVE'">{{'FRACTIONS.STATUSES.INACTIVE' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </form>

  </div>

  <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">

    <button *ngIf="mode === 'edit'"
            mat-button
            class="mr-8"
            [attr.data-cy]="'fractionDelete'"
            (click)="deleteFraction()">
      {{'FRACTIONS.DELETE' | translate}}
    </button>

    <button *ngIf="mode === 'view'"
            mat-button
            class="mr-8"
            [attr.data-cy]="'fractionEdit'"
            (click)="enableEditMode()">
      {{'FRACTIONS.EDIT' | translate}}
    </button>

    <button *ngIf="mode === 'edit' || mode === 'add'"
            mat-button
            class="save-button"
            [attr.data-cy]="'fractionSave'"
            (click)="submitFraction()"
            [disabled]="isLoading">
      <span *ngIf="!isLoading"> {{'FRACTIONS.SAVE' | translate}}</span>
      <span *ngIf="isLoading" class="loading-spinner-wrapper">
        <mat-progress-spinner mode="indeterminate"
                              [strokeWidth]="2"
                              [diameter]="20">
        </mat-progress-spinner>
      </span>
    </button>

  </div>
</div>