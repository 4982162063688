import * as moment from "moment";
import {
  HistoryItem,
  TaskHistoryItem,
} from "../../../../../shared/models/history.model";

export function compareObjectsChanges(
  historyItem: HistoryItem | TaskHistoryItem,
  noValueHelperString: string
) {
  if (historyItem.eventType === "update") {
    historyItem.changes = {};
    filterDataType(
      historyItem.eventParams.old,
      historyItem.eventParams.new,
      historyItem.changes,
      noValueHelperString
    );
  } else if (historyItem.eventType === "hourPatch") {
    historyItem.changes = {};
    checkChangedEventDates(
      historyItem.eventParams.old,
      historyItem.eventParams.new,
      historyItem.changes
    );
  }
  return historyItem;
}

function filterDataType(
  oldObject: any,
  newObject: any,
  targetObject: any,
  noValueHelperString
) {
  for (let key in oldObject) {
    if (Array.isArray(oldObject[key]) && Array.isArray(newObject[key])) {
      if (
        key === "taskData" ||
        key === "fractions" ||
        key === "tags" ||
        key === "orderData" ||
        key === "recyclingItems" ||
        key === "packageTypes"
      ) {
        targetObject[key] = {};
        compareArrays(
          oldObject[key],
          newObject[key],
          targetObject,
          key,
          noValueHelperString
        );
      }
    } else if (
      typeof oldObject[key] === "object" &&
      typeof newObject[key] === "object"
    ) {
      if (JSON.stringify(oldObject[key]) !== JSON.stringify(newObject[key])) {
        targetObject[key] = {};
        filterDataType(
          oldObject[key],
          newObject[key],
          targetObject[key],
          noValueHelperString
        );
      }
    } else {
      if (oldObject && newObject && oldObject[key] !== newObject[key]) {
        if (key === "suggestedDate") {
          targetObject[key] = {
            old: parseDateDay(oldObject[key]),
            new: parseDateDay(newObject[key]),
          };
        } else if (key === "startDate" || key === "endDate") {
          const eventDayBefore = parseDateDay(oldObject[key]);
          const eventDayAfter = parseDateDay(newObject[key]);
          const eventHourBefore = parseDateHour(oldObject[key]);
          const eventHourAfter = parseDateHour(newObject[key]);
          if (eventDayBefore !== eventDayAfter) {
            targetObject[key + "Day"] = {
              old: eventDayBefore,
              new: eventDayAfter,
            };
          }
          if (eventHourBefore !== eventHourAfter) {
            targetObject[key + "Hour"] = {
              old: eventHourBefore,
              new: eventHourAfter,
            };
          }
        } else {
          if (oldObject[key] || newObject[key]) {
            targetObject[key] = {
              old:
                oldObject[key] === null ||
                typeof oldObject[key] === "undefined" ||
                oldObject[key] === ""
                  ? noValueHelperString
                  : oldObject[key],
              new:
                newObject[key] === null ||
                typeof newObject[key] === "undefined" ||
                newObject[key] === ""
                  ? noValueHelperString
                  : newObject[key],
            };
          }
        }
      }
    }
  }
}

function compareArrays(
  oldObjectArray: any[],
  newObjectArray: any[],
  targetObject: any[],
  arrayName: string,
  noValueHelperString: string
) {
  let added = [];
  let removed = [];
  let changed = [];
  let noIdOrdersBefore = [];
  let noIdOrdersAfter = [];

  if (arrayName === "taskData") {
    oldObjectArray = oldObjectArray.filter((oldArrayElement) => {
      if (!oldArrayElement.order) {
        noIdOrdersBefore.push(oldArrayElement);
      } else {
        return oldArrayElement;
      }
    });
    newObjectArray = newObjectArray.filter((newArrayElement) => {
      if (!newArrayElement.order) {
        noIdOrdersAfter.push(newArrayElement);
      } else {
        return newArrayElement;
      }
    });
  }
  oldObjectArray = oldObjectArray.filter((oldArrayElement) => {
    if (
      newObjectArray.findIndex(
        (newArrayElement) =>
          getObjectUniqueValue(newArrayElement, arrayName) ===
          getObjectUniqueValue(oldArrayElement, arrayName)
      ) === -1
    ) {
      removed.push(oldArrayElement);
    } else {
      return oldArrayElement;
    }
  });

  newObjectArray = newObjectArray.filter((newArrayElement) => {
    if (
      oldObjectArray.findIndex(
        (oldArrayElement) =>
          getObjectUniqueValue(oldArrayElement, arrayName) ===
          getObjectUniqueValue(newArrayElement, arrayName)
      ) === -1
    ) {
      added.push(newArrayElement);
    } else {
      return newArrayElement;
    }
  });

  oldObjectArray.forEach((oldArrayElement) => {
    const newArrayElement = newObjectArray.find(
      (newElement) =>
        getObjectUniqueValue(newElement, arrayName) ===
        getObjectUniqueValue(oldArrayElement, arrayName)
    );
    if (JSON.stringify(oldArrayElement) !== JSON.stringify(newArrayElement)) {
      changed.push({ oldArrayElement, newArrayElement });
    }
  });

  targetObject[arrayName] = {
    added: [...added],
    removed: [...removed],
    changed: [],
  };

  if (arrayName === "taskData") {
    targetObject[arrayName].noIdOrdersBefore = [...noIdOrdersBefore];
    targetObject[arrayName].noIdOrdersAfter = [...noIdOrdersAfter];
  }

  if (changed.length > 0) {
    changed.forEach((element) => {
      targetObject[arrayName].changed.push({
        originalObject: element.oldArrayElement,
      });
    });
  }

  if (changed.length > 0) {
    changed.forEach((changedElement, index) => {
      filterDataType(
        changedElement.oldArrayElement,
        changedElement.newArrayElement,
        targetObject[arrayName].changed[index],
        noValueHelperString
      );
    });
  }
}

function getObjectUniqueValue(object, arrayName) {
  switch (arrayName) {
    case "taskData":
      return object.order._id;
    case "orderData":
      return object.recyclingItem._id;
    case "packageTypes":
      return object.uuid;
    default:
      return object._id;
  }
}

function checkChangedEventDates(oldDate: any, newDate: any, targetObject: any) {
  const eventDayBefore = parseDateDay(oldDate.startDate);
  const eventDayAfter = newDate.startDate
    ? parseDateDay(newDate.startDate)
    : parseDateDay(oldDate.startDate);
  const eventStartHourBefore = parseDateHour(oldDate.startDate);
  const eventStartHourAfter = newDate.startDate
    ? parseDateHour(newDate.startDate)
    : parseDateHour(oldDate.startDate);
  const eventEndHourBefore = parseDateHour(oldDate.endDate);
  const eventEndHourAfter = newDate.endDate
    ? parseDateHour(newDate.endDate)
    : parseDateHour(oldDate.endDate);

  if (eventDayBefore !== eventDayAfter) {
    targetObject.startDateDay = { old: eventDayBefore, new: eventDayAfter };
  }
  if (eventStartHourBefore !== eventStartHourAfter) {
    targetObject.startDateHour = {
      old: eventStartHourBefore,
      new: eventStartHourAfter,
    };
  }
  if (eventEndHourBefore !== eventEndHourAfter) {
    targetObject.endDateHour = {
      old: eventEndHourBefore,
      new: eventEndHourAfter,
    };
  }
}

function parseDateDay(historyItemDate: number) {
  return moment(new Date(historyItemDate * 1000)).format("YYYY-MM-DD");
}

function parseDateHour(historyItemDate: number) {
  return moment(new Date(historyItemDate * 1000)).format("HH:mm");
}
