import { Component, Input } from '@angular/core';
import { OrderExport, OrderImport, OrderReceiving } from '../../../../../../shared/models/orders.model';

@Component({
  selector: 'app-task-orders-display-array',
  templateUrl: './task-orders-display-array.component.html',
  styleUrls: ['./task-orders-display-array.component.scss']
})
export class OrdersDisplayAarayComponent {
  @Input() orders: OrderExport[] | OrderImport[] | OrderReceiving[];
}
