import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {FuseTranslationLoaderService} from '../../../../../@fuse/services/translation-loader.service';
import {locale as polish} from '../../../../translations/pl';
import {locale as english} from '../../../../translations/en';
import {ConfirmModalComponent} from '../../../shared/components/confirm-modal/confirm-modal.component';
import {Company} from '../../../../shared/models/companies.model';
import {CompanyAccount} from '../../../../shared/models/company-accounts.model';
import {CompanyAccountsService} from '../../../../services/company-accounts.service';
import {PasswordValidator} from '../../../../shared/validators/password.validator';
import {map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {markFormGroupTouched} from "../../../../shared/utils/markFormGroupAsTouched";

@Component({
  selector: 'app-company-account-editor',
  templateUrl: './company-account-editor.component.html',
  styleUrls: ['./company-account-editor.component.scss']
})
export class CompanyAccountEditorComponent implements OnInit {
  public companyAccountForm: FormGroup;
  public companyAccountUuid: CompanyAccount['uuid'];
  public companyAccount: CompanyAccount;
  public companyUuid: Company['uuid'];
  public passwordForm: FormGroup;
  public changePassword = false;
  public mode: string;
  public isLoading = false;

  constructor(public companyAccountEditorDialogRef: MatDialogRef<CompanyAccountEditorComponent>,
              @Inject(MAT_DIALOG_DATA) private data: any,
              private matDialog: MatDialog,
              private companyAccountService: CompanyAccountsService,
              private translateService: TranslateService,
              private snackBar: MatSnackBar,
              private formBuilder: FormBuilder,
              private _fuseTranslationLoaderService: FuseTranslationLoaderService) {
    this.mode = data.mode;
    this.companyUuid = data.companyUuid;
    this.companyAccountUuid = data.companyAccountUuid;

    this._fuseTranslationLoaderService.loadTranslations(polish, english);
  }

  ngOnInit() {
    this.createForm({});

    if (this.mode === 'edit' || this.mode === 'view') {
      this.companyAccountService.getCompanyAccount(this.companyUuid, this.companyAccountUuid).subscribe(
        res => {
          this.companyAccount = res;
          this.createForm(res);
        }
      );
    }
  }

  public submitCompanyAccount() {
    this.isLoading = true;
    markFormGroupTouched(this.companyAccountForm);

    if (this.companyAccountForm.invalid) {
      this.isLoading = false;
      return;
    }
    if (this.mode === 'add') {
      this.companyAccountService.createCompanyAccount(this.companyUuid, this.companyAccountForm.getRawValue()).subscribe(
        () => {
          this.companyAccountEditorDialogRef.close();
          this.companyAccountEditorDialogRef.afterClosed().subscribe(() => {
            this.companyAccountService.onEditEvent.emit();
          });
          this.snackBar.open(this.translateService.instant('COMPANY_ACCOUNTS.MESSAGES.CREATE_SUCCESS'), '', {duration: 5000});
        }, err => {
          this.isLoading = false;
          if (err.status === 409 && err.error && err.error.details) {
            this.assignExistingCompanyAccount(err.error.details.uuid);
          } else {
            this.snackBar.open(this.translateService.instant('COMPANY_ACCOUNTS.MESSAGES.CREATE_ERROR'), '', {duration: 5000});
          }
        }
      )
    } else {
      this.companyAccountService.updateCompanyAccount(this.companyUuid, this.companyAccountUuid, this.companyAccountForm.getRawValue()).pipe(
        map(res => res),
        switchMap(() => {
          if (this.changePassword) {
            return this.companyAccountService.changeCompanyAccountPassword(this.companyUuid, this.companyAccountUuid, this.passwordForm.get('password').value)
          } else {
            return of(null);
          }
        })
      ).subscribe(() => {
        this.companyAccountEditorDialogRef.close();
        this.companyAccountEditorDialogRef.afterClosed().subscribe(() => {
          this.companyAccountService.onEditEvent.emit();
        });
        this.snackBar.open(this.translateService.instant('COMPANY_ACCOUNTS.MESSAGES.UPDATE_SUCCESS'), '', {duration: 5000});
      }, err => {
        this.isLoading = false;
        if (err.status === 409) {
          this.snackBar.open(this.translateService.instant('COMPANY_ACCOUNTS.MESSAGES.UPDATE_EMAIL_ERROR'), '', {duration: 5000});
        } else {
          this.snackBar.open(this.translateService.instant('COMPANY_ACCOUNTS.MESSAGES.UPDATE_ERROR'), '', {duration: 5000});
        }
      });
    }
  }

  public deleteCompanyAccount() {
    let deleteDialogRef = this.matDialog.open(ConfirmModalComponent, {
      disableClose: false,
      autoFocus: false
    });
    deleteDialogRef.componentInstance.confirmMessage = this.translateService.instant('COMPANY_ACCOUNTS.MESSAGES.DELETE_QUESTION');
    deleteDialogRef.componentInstance.titleMessage = this.translateService.instant('COMPANY_ACCOUNTS.MESSAGES.DELETE_TITLE');
    deleteDialogRef.componentInstance.confirmButton = this.translateService.instant('COMPANY_ACCOUNTS.DELETE');

    deleteDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.companyAccountService.detachCompanyAccount(this.companyUuid, this.companyAccountUuid).subscribe(
          () => {
            this.companyAccountEditorDialogRef.close();
            this.companyAccountEditorDialogRef.afterClosed().subscribe(() => {
              this.companyAccountService.onEditEvent.emit();
            });
            this.snackBar.open(this.translateService.instant('COMPANY_ACCOUNTS.MESSAGES.DELETE_SUCCESS'), '', {duration: 5000});
          }, () => {
            this.snackBar.open(this.translateService.instant('COMPANY_ACCOUNTS.MESSAGES.DELETE_ERROR'), '', {duration: 5000});
          }
        )
      }
    });
  }

  public assignExistingCompanyAccount(companyAccountUuid: CompanyAccount['uuid']) {
    let assignDialogRef = this.matDialog.open(ConfirmModalComponent, {
      disableClose: false,
      autoFocus: false
    });
    assignDialogRef.componentInstance.confirmMessage = this.translateService.instant('COMPANY_ACCOUNTS.MESSAGES.ASSIGN_QUESTION');
    assignDialogRef.componentInstance.titleMessage = this.translateService.instant('COMPANY_ACCOUNTS.MESSAGES.ASSIGN_TITLE');
    assignDialogRef.componentInstance.confirmButton = this.translateService.instant('COMPANY_ACCOUNTS.ASSIGN');

    assignDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.companyAccountService.attachCompanyAccount(this.companyUuid, companyAccountUuid).subscribe(
          () => {
            this.companyAccountEditorDialogRef.close();
            this.companyAccountEditorDialogRef.afterClosed().subscribe(() => {
              this.companyAccountService.onEditEvent.emit();
            });
            this.snackBar.open(this.translateService.instant('COMPANY_ACCOUNTS.MESSAGES.ASSIGN_SUCCESS'), '', {duration: 5000});
          }, () => {
            this.snackBar.open(this.translateService.instant('COMPANY_ACCOUNTS.MESSAGES.ASSIGN_ERROR'), '', {duration: 5000});
          }
        )
      }
    });
  }

  public toggleChangePassword() {
    this.changePassword = !this.changePassword;

    if (this.changePassword) {
      this.passwordForm = this.formBuilder.group({
        password: new FormControl('', [Validators.required, Validators.minLength(5)]),
        confirmPassword: new FormControl('', Validators.required)
      }, {
        validator: PasswordValidator.MatchPassword
      })
    } else {
      this.passwordForm = null;
    }
  }

  public enableEditMode() {
    this.companyAccountEditorDialogRef.close();
    this.matDialog.open(CompanyAccountEditorComponent, {
      panelClass: 'company-account-editor-dialog',
      autoFocus: false,
      disableClose: true,
      data: {
        mode: 'edit',
        companyUuid: this.companyUuid,
        companyAccountUuid: this.companyAccountUuid
      }
    });
  }

  public getTitle(): string {
    switch (this.mode) {
      case 'add':
        return this.translateService.instant('COMPANY_ACCOUNTS.TITLE_ADD');
      case 'edit':
        return this.translateService.instant('COMPANY_ACCOUNTS.TITLE_EDIT');
      case 'view':
        return this.translateService.instant('COMPANY_ACCOUNTS.TITLE_VIEW');
      default:
        return;
    }
  }

  private createForm(inputData: CompanyAccount | any) {
    this.companyAccountForm = this.formBuilder.group({
      name: new FormControl(inputData.name || '', Validators.required),
      surname: new FormControl(inputData.surname || '', Validators.required),
      email: new FormControl(inputData.email || '', Validators.required),
      phone: new FormControl(inputData.phone || '', Validators.required),
      active: new FormControl(inputData.active || false, Validators.required)
    });

    if (this.mode === 'view') {
      this.companyAccountForm.disable();
    } else if (this.mode === 'add') {
      this.companyAccountForm.get('active').disable()
    }
  }

}
