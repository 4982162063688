<div class="users">
  <div class="header p-16 p-sm-24"
       fxLayout.gt-xs="row"
       fxLayoutAlign.gt-xs="space-between center" *ngIf="loggedUser">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="logo" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="logo-icon mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
          account_box
        </mat-icon>
        <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
          {{'USERS.TITLE_LIST' | translate}}
        </span>
      </div>
    </div>
    <div class="search-wrapper">
      <div class="search">
        <label for="search" class="mr-8">
          <mat-icon (click)="clearSearch()">{{searchUser.value ? 'clear' : 'search'}}</mat-icon>
        </label>
        <input id="search" [formControl]="searchUser" [placeholder]="'USERS.SEARCH_PLACEHOLDER' | translate">
      </div>
    </div>
  </div>

  <div class="users-content">
    <app-list-filters class="filters-container"
                      [filters]="['roleType','userType', 'active']"
                      [customFilters]="[{name: 'companyBranchUuid', options: companyBranches}]"
                      (listFilters)="getListFilters($event)">
    </app-list-filters>
    <div class="users-table">
      <mat-table
        matSort
        [dataSource]="dataSource"
        [matSortActive]="requestParams.sort"
        [matSortDirection]="requestParams.order"
        [@animateStaggerRemondis]="dataSource.length"
        [@.disabled]="disableAnimations"
      >
        <ng-container matColumnDef="avatar">
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let user" class="avatar-container">
            <avatar class="avatar" name="{{user.avatarName}}" size="40" background="#2D323E"
                    [displayType]="'circle'">
            </avatar>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'USERS.FIRST_NAME' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let user"> {{user.name}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="surname">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'USERS.LAST_NAME' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let user"> {{user.surname}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="email">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'USERS.EMAIL' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let user"> {{user.email}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="userType">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'USERS.TYPE' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let user"> {{'USERS.TYPES.' + user.userType.toUpperCase() | translate}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="roleType">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'USERS.ROLE' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let user"> {{'USERS.ROLES.' + user.roleType.toUpperCase() | translate}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="companyBranch">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'USERS.COMPANY_BRANCH' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let user"> {{user.companyBranch}}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="active">
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'USERS.STATUSES.ACTIVE' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let user">
            <mat-icon [ngClass]="user.active && 'green-icon'">
              {{user.active ? 'check' : 'close'}}
            </mat-icon>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row
          class="user-row"
          *matRowDef="let user; columns: displayedColumns;"
          [@animateRemondis]="{value:'*',params:{y:'100%'}}"
          [id]="user.uuid"
          (click)="viewUser(user.uuid)"
        >
        </mat-row>
      </mat-table>

      <h4 class="no-data" *ngIf="totalCount === 0">
        {{'SHARED.NO_DATA' | translate}}
      </h4>

      <div *ngIf="totalCount" class="paginator-container">
        <mat-paginator
          (page)="pageChange($event)"
          [pageSize]="requestParams.limit"
          [pageIndex]="(requestParams.page - 1)"
          [length]="totalCount"
          class="paginator-holder"
        ></mat-paginator>
      </div>
    </div>
  </div>
  <button *ngIf="isAdmin"
          mat-fab class="add-user-button"
          aria-label="add contact"
          [attr.data-cy]="'userAdd'"
          [@animate]="{value:'*', params:{delay:'300ms',scale:'.2'}}"
          (click)="addUser()"
          [matTooltip]="'USERS.TITLE_ADD' | translate"
          matTooltipPosition="above">
    <mat-icon>person_add</mat-icon>
  </button>
</div>