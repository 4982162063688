import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { RequestParams } from '../shared/models/list.model';
import { AuthService } from './auth.service';
import {
  FilteredCompaniesListRespone,
  FilteredFractionsListRespone,
  FilteredOrdersListRespone,
  FilteredPackageTypesListRespone,
  FilteredRecyclingItemsListRespone,
  FilteredTransportListRespone
} from '../shared/models/filtered-items.model';

@Injectable({
  providedIn: 'root'
})
export class FilteredListsService {
  constructor(private http: HttpClient, private authService: AuthService) {
  }

  getCompaniesList(params: RequestParams): Observable<FilteredCompaniesListRespone> {
    return this.http.get(`${environment.apiUrl}/${this.authService.getUserType()}/customersCompanies`, {params: params})
      .pipe(map((res: FilteredCompaniesListRespone) => res));
  }

  getPackageTypesList(params: RequestParams): Observable<FilteredPackageTypesListRespone> {
    return this.http.get(`${environment.apiUrl}/${this.authService.getUserType()}/package`, {params: params})
      .pipe(map((res: FilteredPackageTypesListRespone) => res));
  }

  getRecyclingItemsList(params: RequestParams): Observable<FilteredRecyclingItemsListRespone> {
    return this.http.get(`${environment.apiUrl}/${this.authService.getUserType()}/recycling`, {params: params})
      .pipe(map((res: FilteredRecyclingItemsListRespone) => res));
  }

  getTransportList(params: RequestParams): Observable<FilteredTransportListRespone> {
    return this.http.get(`${environment.apiUrl}/${this.authService.getUserType()}/transport`, {params: params})
      .pipe(map((res: FilteredTransportListRespone) => res));
  }

  getFractionsList(params: RequestParams): Observable<FilteredFractionsListRespone> {
    return this.http.get(`${environment.apiUrl}/${this.authService.getUserType()}/fraction`, {params: params})
      .pipe(map((res: FilteredFractionsListRespone) => res));
  }

  getOrdersList(params: RequestParams): Observable<FilteredOrdersListRespone> {
    return this.http.get(`${environment.apiUrl}/${this.authService.getUserType()}/orders`, {params: params})
      .pipe(map((res: FilteredOrdersListRespone) => res));
  }
}