import { Component, OnDestroy, OnInit } from '@angular/core';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { locale as polish } from '../../../../translations/pl';
import { locale as english } from '../../../../translations/en';
import { MatDialog } from '@angular/material';
import { FractionsService } from '../../../../services/fractions.service';
import { FractionEditorComponent } from '../fraction-editor/fraction-editor.component';
import { Fraction, FractionsListRespone } from '../../../../shared/models/fractions.model';
import { List } from '../../../../shared/models/list.class';
import { ListFilters } from '../../../../shared/models/list.model';
import { fuseAnimations } from '../../../../../@fuse/animations';
import { remondisListAnimation } from '../../../../shared/consts/remondis.lists.animation';
import { User } from '../../../../shared/models/users.model';
import { FormControl } from '@angular/forms';
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-fractions-list',
  templateUrl: './fractions-list.component.html',
  styleUrls: ['./fractions-list.component.scss'],
  animations: [...fuseAnimations, ...remondisListAnimation]
})
export class FractionsListComponent extends List implements OnInit, OnDestroy {
  public displayedColumns = ['name', 'description', 'code', 'active'];
  public user: User;
  public searchFraction: FormControl;
  private listFilters: ListFilters = {};
  private reloadList$;

  constructor(private fractionsService: FractionsService,
              private matDialog: MatDialog,
              private authService: AuthService,
              private _fuseTranslationLoaderService: FuseTranslationLoaderService) {
    super();

    this._fuseTranslationLoaderService.loadTranslations(polish, english);

    this.reloadList$ = fractionsService.onEditEvent.subscribe(
      () => {
        this.getElementsList();
      });

    this.getElementsList = () => {
      this.fractionsService.getFractionsList(this.getQueryParams())
        .subscribe((res: FractionsListRespone) => {
          this.totalCount = res.totalCount;
          this.dataSource = res.fractions;
        });
    };
  }

  ngOnInit() {
    super.ngOnInit();
    this.user = this.authService.getUser();
    this.createSearchFormControl();
  }

  ngOnDestroy() {
    this.reloadList$.unsubscribe();
  }

  public addFraction() {
    this.disableAnimations = true;
    let editorDialogRef = this.matDialog.open(FractionEditorComponent, {
      panelClass: 'fraction-editor-dialog',
      autoFocus: false,
      disableClose: true,
      data: {
        mode: 'add'
      }
    });
  }

  public viewFraction(fractionUuid: Fraction['uuid']) {
    this.disableAnimations = true;

    let editorDialogRef = this.matDialog.open(FractionEditorComponent, {
      panelClass: 'fraction-editor-dialog',
      autoFocus: false,
      disableClose: true,
      data: {
        mode: 'view',
        fractionUuid: fractionUuid
      }
    });
  }

  public clearSearch() {
    if (this.searchFraction.value) {
      this.searchFraction.setValue('');
    }
  }

  public getListFilters(filters: ListFilters) {
    this.listFilters = filters;
    this.requestParams.page = 1;
    this.disableAnimations = true;
    this.getElementsList();
  }

  private createSearchFormControl() {
    this.searchFraction = new FormControl('');
    this.searchFraction.valueChanges.debounceTime(300).subscribe((value) => {
      this.requestParams.search = value;
      this.requestParams.page = 1;
      this.disableAnimations = true;
      this.getElementsList();
    });
  }

  private getQueryParams() {
    return Object.assign({}, this.requestParams, this.listFilters);
  }
}
