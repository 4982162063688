import { EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./auth.service";
var PackageService = /** @class */ (function () {
    function PackageService(http, authService) {
        this.http = http;
        this.authService = authService;
        this.onEditEvent = new EventEmitter();
    }
    PackageService.prototype.getPackageTypesList = function (params) {
        return this.http.get(environment.apiUrl + "/" + this.authService.getUserType() + "/package", { params: params })
            .pipe(map(function (res) { return res; }));
    };
    PackageService.prototype.getPackage = function (packageTypeUuid) {
        return this.http.get(environment.apiUrl + "/" + this.authService.getUserType() + "/package/" + packageTypeUuid).pipe(map(function (res) { return res.packageType; }));
    };
    PackageService.prototype.createPackageType = function (packageType) {
        return this.http.post(environment.apiUrl + "/" + this.authService.getUserType() + "/package", packageType).pipe(map(function (res) { return res.packageType; }));
    };
    PackageService.prototype.updatePackageType = function (packageTypeUuid, packageType) {
        return this.http.put(environment.apiUrl + "/" + this.authService.getUserType() + "/package/" + packageTypeUuid, packageType);
    };
    PackageService.prototype.deletePackageType = function (packageTypeUuid) {
        return this.http.delete(environment.apiUrl + "/" + this.authService.getUserType() + "/package/" + packageTypeUuid);
    };
    PackageService.ngInjectableDef = i0.defineInjectable({ factory: function PackageService_Factory() { return new PackageService(i0.inject(i1.HttpClient), i0.inject(i2.AuthService)); }, token: PackageService, providedIn: "root" });
    return PackageService;
}());
export { PackageService };
