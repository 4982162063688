import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { CompanyKpoEditorComponent } from "./components/company-kpo-editor/company-kpo-editor.component";

@NgModule({
  declarations: [CompanyKpoEditorComponent],
  imports: [SharedModule],
  exports: [CompanyKpoEditorComponent],
  entryComponents: [CompanyKpoEditorComponent],
})
export class CompanyKpoModule {}
