import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { FuseTranslationLoaderService } from '../../../../../@fuse/services/translation-loader.service';
import { locale as polish } from '../../../../translations/pl';
import { locale as english } from '../../../../translations/en';
import { TransportEditorComponent } from '../transport-editor/transport-editor.component';
import { TransportService } from '../../../../services/transport.service';
import { Transport, TransportListRespone } from '../../../../shared/models/transport.model';
import { List } from '../../../../shared/models/list.class';
import { ListFilters } from '../../../../shared/models/list.model';
import { FormControl } from '@angular/forms';
import { fuseAnimations } from '../../../../../@fuse/animations';
import { remondisListAnimation } from '../../../../shared/consts/remondis.lists.animation';

@Component({
  selector: 'app-transport-list',
  templateUrl: './transport-list.component.html',
  styleUrls: ['./transport-list.component.scss'],
  animations: [...fuseAnimations, ...remondisListAnimation]
})
export class TransportListComponent extends List implements OnInit, OnDestroy {
  public displayedColumns = ['name', 'description', 'capacity', 'volume', 'category', 'active'];
  public searchTransport: FormControl;
  private listFilters: ListFilters = {};
  private reloadList$;

  constructor(private transportService: TransportService,
              private matDialog: MatDialog,
              private _fuseTranslationLoaderService: FuseTranslationLoaderService) {
    super();

    this._fuseTranslationLoaderService.loadTranslations(polish, english);

    this.reloadList$ = transportService.onEditEvent.subscribe(
      () => {
        this.getElementsList();
      });

    this.getElementsList = () => {
      this.transportService.getTransportList(this.getQueryParams()).subscribe((res: TransportListRespone) => {
        this.totalCount = res.totalCount;
        this.dataSource = res.transports;
      });
    };
  }

  ngOnInit() {
    super.ngOnInit();
    this.createSearchFormControl();
  }

  ngOnDestroy() {
    this.reloadList$.unsubscribe();
  }

  public addTransport() {
    this.disableAnimations = true;

    let editorDialogRef = this.matDialog.open(TransportEditorComponent, {
      panelClass: 'transport-editor-dialog',
      autoFocus: false,
      disableClose: true,
      data: {
        mode: 'add'
      }
    });
  }

  public viewTransport(transportUuid: Transport['uuid']) {
    this.disableAnimations = true;

    let editorDialogRef = this.matDialog.open(TransportEditorComponent, {
      panelClass: 'transport-editor-dialog',
      autoFocus: false,
      disableClose: true,
      data: {
        mode: 'view',
        transportUuid: transportUuid
      }
    });
  }

  public clearSearch() {
    if (this.searchTransport.value) {
      this.searchTransport.setValue('');
    }
  }

  public getListFilters(filters: ListFilters) {
    this.listFilters = filters;
    this.requestParams.page = 1;
    this.disableAnimations = true;
    this.getElementsList();
  }


  private createSearchFormControl() {
    this.searchTransport = new FormControl('');
    this.searchTransport.valueChanges.debounceTime(300).subscribe((value) => {
      this.requestParams.name = value;
      this.requestParams.page = 1;
      this.disableAnimations = true;
      this.getElementsList();
    });
  }

  private getQueryParams() {
    return Object.assign({}, this.requestParams, this.listFilters);
  }

}
