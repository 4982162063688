<mat-accordion>
  <mat-expansion-panel *ngFor="let item of recyclingItemsChanged">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <strong>{{item.originalObject.recyclingItem.name}}</strong>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <p *ngIf="item.value">
      {{'HISTORY.HISTORY_KEYS.value' | translate}}: <strong>{{item.value.old}}</strong>
      --> <strong>{{item.value.new}}</strong>
    </p>
    <p *ngIf="item.unit">
      {{'HISTORY.HISTORY_KEYS.unit' | translate}}
      <strong>{{'HISTORY.HISTORY_KEYS.' + item.unit.old | translate}}</strong>
      --> <strong>{{'HISTORY.HISTORY_KEYS.' + item.unit.new | translate}}</strong>
    </p>
    <ng-container *ngIf="item.packageTypes.added.length > 0">
      <h3>
        <strong>{{(item.packageTypes.added.length === 1 ? 'HISTORY.HISTORY_KEYS.packageTypeAdded' : 'HISTORY.HISTORY_KEYS.packageTypesAdded') | translate}}</strong>
      </h3>
      <app-package-types-display [packageTypes]="item.packageTypes.added"></app-package-types-display>
    </ng-container>
  </mat-expansion-panel>
</mat-accordion>