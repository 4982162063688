import { NgModule } from '@angular/core';
import { FractionEditorComponent } from './components/fraction-editor/fraction-editor.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { FractionsListComponent } from './components/fractions-list/fractions-list.component';
import { Routes } from './fractions.routes';
import { FractionsContainerComponent } from './fractions-container.component';

@NgModule({
  declarations: [
    FractionsContainerComponent,
    FractionsListComponent,
    FractionEditorComponent,
  ],
  imports: [
    RouterModule.forChild(Routes),
    SharedModule,
  ],
  exports: [
    FractionsListComponent,
    FractionEditorComponent,
  ],
  entryComponents: [
    FractionEditorComponent
  ]
})
export class FractionsModule { }
