<div class="dialog-content-wrapper">
    <mat-toolbar matDialogTitle class="mat-accent m-0">
        <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
            <span class="title dialog-title">{{getTitle()}} - {{'TASKS.TYPES.IMPORT' | translate}}</span>
            <span *ngIf="task && task.taskNumber">{{task.taskNumber || ('TASKS.NO_NUMBER' | translate)}}</span>
            <button mat-icon-button (click)="taskEditorDialogRef.close()" aria-label="Close dialog">
                <mat-icon>close</mat-icon>
            </button>
        </mat-toolbar-row>
    </mat-toolbar>

    <div class="task-status" *ngIf="mode === 'view' && task">
        Status: {{'TASKS.STATUSES.' + task?.status.toUpperCase() | translate}}
    </div>

    <mat-horizontal-stepper class="task-content-wrapper" mat-dialog-content #stepper="matHorizontalStepper"
                            (selectionChange)="changeStep($event)" [selectedIndex]="indexStep">

        <!--STEP 1-->
        <mat-step [completed]="false" [stepControl]="taskImportForm">
            <ng-template matStepLabel><h3><b>{{'TASKS.GENERAL_INFO' | translate}}</b></h3></ng-template>
            <div class="general-info-step">
                <form class="form" [formGroup]="taskImportForm">
                    <div class="full-width-wrapper-vertical-row">
                        <mat-form-field appearance="outline" class="no-errors-spacer" fxFlex>
                            <mat-label>{{'TASKS.NAME' | translate}}</mat-label>
                            <input matInput formControlName="name" required [attr.data-cy]="'taskName'">
                        </mat-form-field>
                    </div>

                    <div class="full-width-wrapper-horizontal">
                        <div class="input-wrapper-task-import">
                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>{{'TASKS.COMPANY_BRANCH' | translate}}</mat-label>
                                <mat-select formControlName="companyBranchId" required>
                                    <mat-option *ngFor="let branch of companyBranches" [value]="branch.uuid">
                                        {{ branch.name }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="input-wrapper-task-import">
                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>{{'TASKS.DATE' | translate}}</mat-label>
                                <input matInput [matDatepicker]="startDate" formControlName="startDate"
                                       [min]="todayDate" required
                                       (dateChange)="setStartDate($event.value)">
                                <mat-datepicker-toggle matSuffix [for]="startDate"
                                                       [attr.data-cy]="'startDate'"></mat-datepicker-toggle>
                                <mat-datepicker #startDate></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="full-width-wrapper-horizontal">
                        <div class="input-wrapper-task-import">
                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>{{'TASKS.START_TIME' | translate}}</mat-label>
                                <mat-select formControlName="startTime" required [attr.data-cy]="'startTime'">
                                    <mat-option *ngFor="let time of startTimeOptions; let i=index" [value]="time"
                                                [disabled]="taskImportForm.get('endTime').value &&
                           startTimeOptions.includes(taskImportForm.get('endTime').value) &&
                           (i+1 > startTimeOptions.indexOf(taskImportForm.get('endTime').value))" [attr.data-cy]="time">
                                        {{ time }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="input-wrapper-task-import">
                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>{{'TASKS.END_TIME' | translate}}</mat-label>
                                <mat-select formControlName="endTime" required [attr.data-cy]="'endTime'">
                                    <mat-option *ngFor="let time of endTimeOptions; let i=index" [value]="time"
                                                [disabled]="taskImportForm.get('startTime').value &&
                          i < startTimeOptions.indexOf(taskImportForm.get('startTime').value)" [attr.data-cy]="time">
                                        {{ time }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="input-wrapper-task-import">
                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>{{'TASKS.REG_NUMBER_FRONT' | translate}}</mat-label>
                                <input formControlName="registrationNumFront" matInput [attr.data-cy]="'numberFront'">
                            </mat-form-field>
                        </div>
                        <div class="input-wrapper-task-import">
                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>{{'TASKS.REG_NUMBER_BACK' | translate}}</mat-label>
                                <input formControlName="registrationNumBack" matInput [attr.data-cy]="'numberBack'">
                            </mat-form-field>
                        </div>
                        <div class="input-wrapper-task-import">
                            <mat-form-field appearance="outline" fxFlex>
                                <mat-label>{{'TASKS.REFERENCE_NUMBER' | translate}}</mat-label>
                                <input formControlName="referenceNumber" matInput [attr.data-cy]="'referenceNumber'">
                            </mat-form-field>
                        </div>

                        <div class="input-wrapper-task-import">
                            <div class="input-wrapper-task-import emailService-checkbox">
                                <mat-checkbox formControlName="emailService" [attr.data-cy]="'emailService'">
                                    {{'TASKS.EMAIL_SERVICE' | translate}}
                                </mat-checkbox>
                            </div>
                        </div>

                    </div>
                    <div class="full-width-wrapper-vertical"
                         [ngClass]="mode==='add' || mode === 'clone' ? 'comment-col' : 'comment-col-view'">
                        <h3 class="m-0"> {{'TASKS.COMMENT' | translate}} </h3>
                        <mat-form-field appearance="outline" fxFlex="auto" [formGroup]="taskImportForm">
                            <textarea formControlName="comment" matInput [attr.data-cy]="'comment'"></textarea>
                        </mat-form-field>
                    </div>
                    <ng-container *ngIf="mode==='add' || mode ==='clone'">
                        <div class="full-width-wrapper-vertical">
                            <div class="input-wrapper-task-import recurring-checkbox">
                                <mat-checkbox (ngModelChange)="handleRecurringChange()" formControlName="recurring"
                                              [attr.data-cy]="'recurring'">
                                    {{'TASKS.RECURRING.CHECKBOX' | translate}}
                                </mat-checkbox>
                            </div>
                        </div>
                        <div *ngIf="taskImportForm.get('recurring').value" formGroupName="recurringInfo"
                             class="full-width-wrapper-horizontal">
                            <div class="input-wrapper-task-import">
                                <mat-form-field appearance="outline" fxFlex>
                                    <mat-label>{{'TASKS.RECURRING.INTERVAL' | translate}}</mat-label>
                                    <input formControlName="intervalValue" matInput required
                                           [attr.data-cy]="'intervalValue'">
                                </mat-form-field>
                            </div>
                            <div class="input-wrapper-task-import">
                                <mat-form-field appearance="outline" fxFlex>
                                    <mat-label>{{'TASKS.RECURRING.RANGE_DATE_END' | translate}}</mat-label>
                                    <input matInput [matDatepicker]="rangeDateEndPicker" formControlName="rangeDateEnd"
                                           [min]="rangeDateEndMin"
                                           [max]="rangeDateEndMax" required readonly>
                                    <mat-datepicker-toggle matSuffix [for]="rangeDateEndPicker"
                                                           [attr.data-cy]="'rangeEndDate'"></mat-datepicker-toggle>
                                    <mat-datepicker #rangeDateEndPicker></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div class="full-width-wrapper-horizontal weekdays-checkbox-wrapper"
                                 formGroupName="weekdays"
                                 fxLayoutAlign="start start">
                                <mat-checkbox labelPosition="before" formControlName="Monday" class="weekday-checkbox"
                                              [attr.data-cy]="'monday'">
                                    {{'TASKS.RECURRING.MONDAY' | translate}}
                                </mat-checkbox>
                                <mat-checkbox labelPosition="before" formControlName="Tuesday" class="weekday-checkbox"
                                              [attr.data-cy]="'tuesday'">
                                    {{'TASKS.RECURRING.TUESDAY' | translate}}
                                </mat-checkbox>
                                <mat-checkbox labelPosition="before" formControlName="Wednesday"
                                              class="weekday-checkbox" [attr.data-cy]="'wednesday'">
                                    {{'TASKS.RECURRING.WEDNESDAY' | translate}}
                                </mat-checkbox>
                                <mat-checkbox labelPosition="before" formControlName="Thursday" class="weekday-checkbox"
                                              [attr.data-cy]="'thursday'">
                                    {{'TASKS.RECURRING.THURSDAY' | translate}}
                                </mat-checkbox>
                                <mat-checkbox labelPosition="before" formControlName="Friday" class="weekday-checkbox"
                                              [attr.data-cy]="'friday'">
                                    {{'TASKS.RECURRING.FRIDAY' | translate}}
                                </mat-checkbox>
                                <mat-checkbox labelPosition="before" formControlName="Saturday" class="weekday-checkbox"
                                              [attr.data-cy]="'saturday'">
                                    {{'TASKS.RECURRING.SATURDAY' | translate}}
                                </mat-checkbox>
                                <mat-checkbox labelPosition="before" formControlName="Sunday" class="weekday-checkbox"
                                              [attr.data-cy]="'sunday'">
                                    {{'TASKS.RECURRING.SUNDAY' | translate}}
                                </mat-checkbox>
                            </div>
                        </div>
                    </ng-container>
                </form>
            </div>
            <div *ngIf="mode === 'add' || mode === 'clone'" class="btn-next">
                <button mat-button matStepperNext
                        [attr.data-cy]="'nextStep'">{{'TASKS.STEPPER.NEXT' | translate}}</button>
            </div>
        </mat-step>

        <!--STEP 2-->
        <mat-step [completed]="false" [stepControl]="taskImportForm">
            <ng-template matStepLabel><h3><b> {{'TASKS.ORDERS' | translate}} <sup>*</sup></b></h3></ng-template>
            <mat-accordion>
                <mat-expansion-panel [expanded]="isExpanded[i]"
                                     *ngFor="let order of taskImportForm.get('taskData')['controls']; let i=index">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            {{'TASKS.ORDER'| translate}}
                        </mat-panel-title>
                        <mat-panel-description *ngIf="order.value.customerCompany && !order.value.order">
                            {{order.controls.order.value.customerCompany?.name || ""}}
                            {{order.controls.order.value.orderNumber ? "[" + order.controls.order.value.orderNumber + "]" : ""}}
                        </mat-panel-description>
                        <mat-panel-description *ngIf="order.value.customerCompany && order.value.order">
                            {{order.value.customerCompany?.name || ""}}
                            {{order.value.order.orderNumber && "[" + order.value.order.orderNumber + "]" || ""}}
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <form [formGroup]="taskImportForm">
                        <div class="full-width-wrapper-vertical" formArrayName="taskData"
                        >
                            <div fxLayout="row" fxLayoutAlign="end start">
                                <button mat-icon-button
                                        [disabled]="mode === 'view' || (this.data && this.data.order && i === 0)"
                                        (click)="deleteOrderSubform(i)" [attr.data-cy]="'deleteOrderSubform'">
                                    <i class="material-icons s-36 delete-icon">delete</i>
                                </button>
                            </div>
                            <div [formGroupName]="i" class="full-width-wrapper-vertical-order">
                                <mat-form-field class="company-select" appearance="outline" fxFlex>
                                    <mat-label>{{'TASKS.ORDER' | translate}}</mat-label>
                                    <mat-select formControlName="order"
                                                [compareWith]="compareObjectsByUuid"
                                                (valueChange)="pickOrder($event, i)"
                                                required [attr.data-cy]="'setOrder'">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="searchOrders"
                                                                   [placeholderLabel]="'ORDERS.SEARCH_PLACEHOLDER' | translate"
                                                                   [noEntriesFoundLabel]="'SHARED.NO_RESULTS' | translate">
                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option [value]="{uuid: 'other'}" [attr.data-cy]="'other'">
                                            -- {{'TASKS.OTHER' | translate}} --
                                        </mat-option>
                                        <mat-option *ngFor="let order of getOrdersOptions(i)" [value]="order"
                                                    [attr.data-cy]="order.customerCompany.name.split(' ').join('-')">
                                            {{ order?.customerCompany?.name }}
                                            <small *ngIf="order && order.orderNumber"> [{{ order?.orderNumber }}
                                                ]</small>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <div class="full-width-wrapper-horizontal full-name-select">
                                    <mat-form-field appearance="outline" fxFlex>
                                        <mat-label>{{'TASKS.CUSTOMER_COMPANY' | translate}}</mat-label>
                                        <mat-select formControlName="customerCompany"
                                                    [compareWith]="compareObjectsByUuid"
                                                    (valueChange)="onChangeCustomerCompany($event, i)" #companySelect
                                                    required [attr.data-cy]="'customerCompany'">
                                            <mat-option>
                                                <ngx-mat-select-search [formControl]="searchCustomerCompany"
                                                                       [placeholderLabel]="'CUSTOMER_COMPANIES.SEARCH_PLACEHOLDER' | translate"
                                                                       [noEntriesFoundLabel]="'SHARED.NO_RESULTS' | translate">
                                                </ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option (click)="addCustomerCompany(i)" [attr.data-cy]="'companyAdd'">
                        <span class="add-item">
                          <mat-icon class="add-item-icon" matSuffix (click)="addCustomerCompany(i)"
                                    [matTooltip]="'CUSTOMER_COMPANIES.TITLE_ADD' | translate"
                                    matTooltipPosition="above">
                            add
                          </mat-icon>
                            {{ 'CUSTOMER_COMPANIES.TITLE_ADD' | translate }}
                        </span>
                                            </mat-option>
                                            <mat-option class="full-name-option"
                                                        *ngFor="let customerCompany of getCustomerCompaniesOptions(i)"
                                                        [value]="customerCompany"
                                                        [attr.data-cy]="customerCompany.name.split(' ').join('-')">
                           <span matTooltipPosition="above" matTooltipClass="multiline-tooltip"
                                 [matTooltip]="getTooltipCompany(customerCompany)">
                          {{ customerCompany.name }}
                           </span>
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="full-width-wrapper-horizontal">
                                    <div class="input-wrapper-task-import">
                                        <mat-form-field appearance="outline" fxFlex>
                                            <mat-label>{{'ORDERS.ACCEPTED_BY_SALES' | translate}}</mat-label>
                                            <mat-select formControlName="salesAccepted" required
                                                        [attr.data-cy]="'acceptedBySales'">
                                                <mat-option [value]="true"
                                                            [attr.data-cy]="'YES'">{{'SHARED.YES' | translate}}</mat-option>
                                                <mat-option [value]="false"
                                                            [attr.data-cy]="'NO'">{{'SHARED.NO' | translate}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div class="input-wrapper-task-import">
                                        <mat-form-field appearance="outline" fxFlex>
                                            <mat-label>{{'ORDERS.COMPANY_BRANCH' | translate}}</mat-label>
                                            <mat-select formControlName="companyBranchId" required
                                                        [attr.data-cy]="'companyBranch'">
                                                <mat-option *ngFor="let branch of companyBranches" [value]="branch.uuid"
                                                            [attr.data-cy]="branch.name.toUpperCase()">
                                                    {{ branch.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div class="input-wrapper-task-import">
                                        <mat-form-field appearance="outline" fxFlex>
                                            <mat-label>{{'ORDERS.SUGGESTED_DATE_IMPORT' | translate}}</mat-label>
                                            <input matInput [matDatepicker]="suggestedDate"
                                                   formControlName="suggestedDate" [min]="todayDate" readonly required>
                                            <mat-datepicker-toggle matSuffix [for]="suggestedDate"
                                                                   [attr.data-cy]="'suggestedDate'"></mat-datepicker-toggle>
                                            <mat-datepicker #suggestedDate></mat-datepicker>
                                        </mat-form-field>
                                    </div>

                                    <div class="input-wrapper-task-import">
                                        <mat-form-field appearance="outline" fxFlex>
                                            <mat-label>{{'TASKS.TRANSPORT' | translate}}</mat-label>
                                            <mat-select formControlName="transport"
                                                        [compareWith]="compareObjectsByUuid"
                                                        required [attr.data-cy]="'setTransport'">
                                                <mat-option>
                                                    <ngx-mat-select-search [formControl]="searchTransport"
                                                                           [placeholderLabel]="'TRANSPORT.SEARCH_PLACEHOLDER' | translate"
                                                                           [noEntriesFoundLabel]="'SHARED.NO_RESULTS' | translate">
                                                    </ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option *ngFor="let transport of getTransportOptions(i)"
                                                            [value]="transport" [attr.data-cy]="transport.name">
                                                    {{ transport.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                    <div class="input-wrapper-task-import">
                                        <mat-form-field appearance="outline" fxFlex>
                                            <mat-label>{{'ORDERS.REFERENCE_NUMBER' | translate}}</mat-label>
                                            <input formControlName="referenceNumber" matInput
                                                   [attr.data-cy]="'referenceNumber'">
                                        </mat-form-field>
                                    </div>

                                    <div class="input-wrapper-task-import">
                                        <mat-form-field appearance="outline" fxFlex>
                                            <mat-label>{{'ORDERS.REG_NUMBER_FRONT' | translate}}</mat-label>
                                            <input formControlName="registrationNumFront" matInput
                                                   [attr.data-cy]="'numberFront'">
                                        </mat-form-field>
                                    </div>

                                    <div class="input-wrapper-task-import">
                                        <mat-form-field appearance="outline" fxFlex>
                                            <mat-label>{{'ORDERS.REG_NUMBER_BACK' | translate}}</mat-label>
                                            <input formControlName="registrationNumBack" matInput
                                                   [attr.data-cy]="'numberBack'">
                                        </mat-form-field>
                                    </div>

                                    <div class="input-wrapper-task-import">
                                      <app-email-chips-input [label]="'ORDERS.KPO_CONTACT'" [mode]="mode" [emailControl]="taskImportForm.get('taskData').controls[i].get('kpoEmails')">
                                      </app-email-chips-input>
                                    </div>
                                    
                                    <div class="full-width-wrapper-vertical">
                                      <button *ngIf="mode === 'view' && task?.status !== taskStatuses.Cancelled"
                                      mat-button
                                      [disabled]="task?.status !== taskStatuses.SendToForwarder && task?.status !== taskStatuses.Notified"
                                      class="mr-8 float-right"
                                      [attr.data-cy]="'resendNotification'"
                                      (click)="resendNotification(i)">
                                        {{'TASKS.RESEND_NOTIFICATION' | translate}}
                                       </button>
                                    </div>
                
                                    <div class="full-width-wrapper-vertical">
                                        <h3 class="m-0"> {{'ORDERS.INTERNAL_COMMENT' | translate}}</h3>
                                        <mat-form-field appearance="outline" fxFlex="auto">
                                            <textarea formControlName="order_comment" matInput
                                                      [attr.data-cy]="'order_comment'"></textarea>
                                        </mat-form-field>
                                    </div>

                                    <div class="full-width-wrapper-horizontal">
                                        <div class="input-wrapper-task-import">
                                            <mat-checkbox class="bdo-required" formControlName="reinvoice"
                                                          (change)="reinvoiceChange($event.checked, i)"
                                                          [attr.data-cy]="'reinvoice'">
                                                {{'ORDERS.REINVOICE_CHECKBOX' | translate}}
                                            </mat-checkbox>
                                        </div>

                                        <div class="input-wrapper-task-import">
                                            <mat-form-field appearance="outline" fxFlex>
                                                <mat-label>{{'ORDERS.REINVOICE_VALUE' | translate}}</mat-label>
                                                <input formControlName="price" matInput
                                                       [attr.data-cy]="'price'">
                                            </mat-form-field>
                                        </div>

                                        <div class="input-wrapper-task-import">
                                            <mat-form-field appearance="outline" fxFlex>
                                                <mat-label>{{'ORDERS.REINVOICE_NUMBER' | translate}}</mat-label>
                                                <input formControlName="reinvoiceNumber" matInput
                                                       [attr.data-cy]="'reinvoiceNumber'">
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="full-width-wrapper-vertical">
                                        <h3 class="m-0"> {{'ORDERS.INTERNAL_COMMENT_FV_ISSUER' | translate}}</h3>
                                        <mat-form-field appearance="outline" fxFlex="auto">
                                                <textarea formControlName="invoiceComment" matInput
                                                          [attr.data-cy]="'invoiceComment'"></textarea>
                                        </mat-form-field>
                                    </div>

                                    <div class="full-width-wrapper-vertical">
                                        <h3> {{'ORDERS.BDO' | translate}}</h3>

                                        <div class="full-width-wrapper-horizontal">
                                            <div class="input-wrapper-task-import bdo-wrapper-input">
                                                <mat-form-field appearance="outline" fxFlex>
                                                    <mat-label>{{'ORDERS.BDO_NUMBER' | translate}}</mat-label>
                                                    <input formControlName="bdo"
                                                           type="number"
                                                           matInput
                                                           [attr.data-cy]="'bdoNumber'"
                                                           [required]="!taskImportForm.get('taskData').controls[i].get('bdoFree').value && !taskImportForm.get('taskData').controls[i].get('bdoPeopleCollection').value">
                                                </mat-form-field>
                                            </div>
                                            <div class="input-wrapper-task-import bdo-checkbox-wrapper bdo-wrapper">
                                                <div class="or-text">{{'SHARED.OR' | translate}}</div>
                                                <mat-checkbox class="bdo-required"
                                                              [attr.data-cy]="'bdoCheckbox'"
                                                              formControlName="bdoFree"
                                                              (change)="bdoRequiredToggle($event.checked, i)">
                                                    {{'ORDERS.NO_BDO_CHECKBOX' | translate}}
                                                </mat-checkbox>
                                            </div>
                                            <div class="input-wrapper-task-import bdo-checkbox-wrapper bdo-wrapper">
                                                <div class="or-text">{{'SHARED.OR' | translate}}</div>
                                                <mat-checkbox class="bdo-required" formControlName="bdoPeopleCollection"
                                                              (change)="bdoPeopleCollectionToggle($event.checked, i)"
                                                              [attr.data-cy]="'bdoPeopleCollection'">
                                                    {{'ORDERS.BDO_PEOPLE_COLLECTION' | translate}}
                                                </mat-checkbox>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="full-width-wrapper-vertical last-margin">
                                        <app-items-list-picker-form
                                                [mode]="mode"
                                                [required]="true"
                                                [formControl]="taskImportForm.get('taskData').controls[i].get('orderData')"
                                        >
                                        </app-items-list-picker-form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </mat-expansion-panel>
            </mat-accordion>
            <div *ngIf="((mode === 'add' || mode === 'clone' ) || (mode === 'edit' && task && task.status !== taskStatuses.Cancelled))
       && taskImportForm.get('taskData').value.length === 0" class="mb-24" fxLayout="row" fxLayoutAlign="center start">
                <button mat-button color="primary"
                        (click)="addOrderSubform(taskImportForm.get('taskData').value.length)"
                        [attr.data-cy]="'addOrderSubform'">
                    {{'TASKS.ADD_ORDER' | translate}}
                </button>
            </div>
        </mat-step>
    </mat-horizontal-stepper>
    <ng-container *ngIf="user.userType !== 'manager'">
    <div class="pb-0 task-number-position" fusePerfectScrollbar>
        <div *ngIf="mode !== 'add' && mode !== 'clone' && task && indexStep===0" class="full-width-wrapper-vertical">
            <hr>
            <h3 class="pb-task"> {{'TASKS.NUMBER' | translate}} </h3>
            <div class="pb-task">{{task.taskNumber || ('TASKS.NO_NUMBER' | translate)}}</div>
        </div>
    </div>

    <div *ngIf="mode !== 'add' && mode !== 'clone'" class="action-btn" fxLayout="row" fxLayoutAlign="end center">
        <button *ngIf="indexStep===1  && task?.status !== taskStatuses.Closed"
                mat-button
                class="mr-8"
                [attr.data-cy]="'completeTask'"
                (click)="completeTask()">
            {{'TASKS.COMPLETE_TASK' | translate}}
        </button>

        <button *ngIf="mode === 'edit' && task?.status !== taskStatuses.Cancelled"
                mat-button
                class="mr-8"
                [attr.data-cy]="'cancelTask'"
                (click)="cancelTask()">
            {{'TASKS.REVOKE' | translate}}
        </button>

        <button *ngIf="mode === 'edit'"
                mat-button
                class="mr-8"
                [attr.data-cy]="'deleteTask'"
                (click)="deleteTask()">
            {{'TASKS.DELETE' | translate}}
        </button>

        <button *ngIf="mode === 'view'"
                mat-button
                class="mr-8"
                [attr.data-cy]="'showHistory'"
                (click)="showHistory()">
            {{'TASKS.SHOW_HISTORY' | translate}}
        </button>
        <button *ngIf="mode === 'view' && user.userType !== 'sales'"
                mat-button
                class="mr-8"
                [attr.data-cy]="'cloneTask'"
                (click)="enableCloneMode()">
            {{'TASKS.CLONE' | translate}}
        </button>
        <button *ngIf="mode === 'view' && user.userType !== 'sales'"
                mat-button
                class="mr-8"
                [attr.data-cy]="'editTask'"
                (click)="enableEditMode()">
            {{'TASKS.EDIT' | translate}}
        </button>

        <button *ngIf="mode === 'edit'"
                mat-button
                class="save-button"
                [attr.data-cy]="'saveTask'"
                (click)="submitTask()"
                [disabled]="isLoading">
            <span *ngIf="!isLoading"> {{'TASKS.SAVE' | translate}}</span>
            <span *ngIf="isLoading" class="loading-spinner-wrapper">
        <mat-progress-spinner mode="indeterminate"
                              [strokeWidth]="2"
                              [diameter]="20">
        </mat-progress-spinner>
      </span>
        </button>
    </div>

    <div *ngIf="(mode === 'add' || mode === 'clone') && indexStep === 1" class="btn-space-between">
        <button
                mat-button
                [attr.data-cy]="'prevStep'"
                (click)="prevStep()">
            {{'TASKS.STEPPER.PREV' | translate}}
        </button>
        <button *ngIf="mode === 'add' || mode === 'clone'"
                mat-button
                class="save-button"
                [attr.data-cy]="'saveTask'"
                (click)="submitTask()"
                [disabled]="isLoading">
            <span *ngIf="!isLoading"> {{'TASKS.SAVE' | translate}}</span>
            <span *ngIf="isLoading" class="loading-spinner-wrapper">
        <mat-progress-spinner mode="indeterminate"
                              [strokeWidth]="2"
                              [diameter]="20">
        </mat-progress-spinner>
      </span>
        </button>
    </div>
    </ng-container>
</div>