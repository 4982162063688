<div class="company-cars">
  <div class="company-cars-table">
    <mat-table
      matSort
      [dataSource]="dataSource"
      [matSortActive]="requestParams.sort"
      [matSortDirection]="requestParams.order"
      [@animateStaggerRemondis]="dataSource && dataSource.length"
      [@.disabled]="disableAnimations">

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>{{'COMPANY_CARS.NAME' | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let car"> {{car.name}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="type">
        <mat-header-cell *matHeaderCellDef>{{'COMPANY_CARS.CAR_TYPE' | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let car"> {{car.type}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="registration">
        <mat-header-cell *matHeaderCellDef>{{'COMPANY_CARS.REGISTRATION' | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let car"> {{car.registrationNumberFront}}
          <br/>{{car.registrationNumberBack}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="customId">
        <mat-header-cell *matHeaderCellDef>{{'COMPANY_CARS.CUSTOM_ID' | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let car"> {{car.customId}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="companyName">
        <mat-header-cell *matHeaderCellDef>{{'COMPANY_CARS.COMPANY_NAME' | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let car"> {{car.companyName}} {{car.bdo}}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        class="company-car-row"
        *matRowDef="let car; columns: displayedColumns;"
        [@animateRemondis]="{value:'*',params:{y:'100%'}}"
        [id]="car.uuid"
        (click)="viewCompanyCar(car.uuid)">
      </mat-row>
    </mat-table>

    <h4 class="no-data" *ngIf="!totalCount || totalCount === 0">
      {{'SHARED.NO_DATA' | translate}}
    </h4>

  </div>
  <div class="button-add-wrapper">
    <button mat-raised-button color="primary"
            [@animate]="{value:'*', params:{delay:'300ms',scale:'.2'}}"
            (click)="addCompanyCar()"
            [attr.data-cy]="'companyCarAdd'">
      {{ 'COMPANY_CARS.TITLE_ADD' | translate }}
    </button>
  </div>
</div>