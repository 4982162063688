import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { RequestParams } from '../shared/models/list.model';
import { AuthService } from './auth.service';
import { CustomerCompanyBranch, CompanyBranchesListRespone } from '../shared/models/company-branches.model';
import { Company } from '../shared/models/companies.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyBranchesService {
  onEditEvent: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient, private authService: AuthService) {
  }

  getCompanyBranchesList(params: RequestParams, companyUuid: Company['uuid']): Observable<CompanyBranchesListRespone> {
    return this.http.get(`${environment.apiUrl}/${this.authService.getUserType()}/customersCompanies/${companyUuid}/branches`,
      {params: params}).pipe(map((res: CompanyBranchesListRespone) => res));
  }

  getCompanyBranch(companyUuid: Company['uuid'], companyBranchUuid: CustomerCompanyBranch['uuid']): Observable<CustomerCompanyBranch> {
    return this.http.get(`${environment.apiUrl}/${this.authService.getUserType()}/customersCompanies/${companyUuid}/branches/${companyBranchUuid}`)
      .pipe(map((res: any) => res.branch));
  }

  createCompanyBranch(companyUuid: Company['uuid'], branch: CustomerCompanyBranch): Observable<CustomerCompanyBranch> {
    return this.http.post<any>(`${environment.apiUrl}/${this.authService.getUserType()}/customersCompanies/${companyUuid}/branches`, branch)
      .pipe(map((res: any) => res.branch));
  }

  updateCompanyBranch(companyUuid: Company['uuid'], branchUuid: CustomerCompanyBranch['uuid'], branch: CustomerCompanyBranch): Observable<CustomerCompanyBranch> {
    return this.http.post<any>(`${environment.apiUrl}/${this.authService.getUserType()}/customersCompanies/${companyUuid}/branches/${branchUuid}`, branch);
  }

  deleteCompanyBranch(companyUuid: Company['uuid'], branchUuid: CustomerCompanyBranch['uuid']) {
    return this.http.delete<any>(`${environment.apiUrl}/${this.authService.getUserType()}/customersCompanies/${companyUuid}/branches/${branchUuid}`,
    );
  }
}
