<div class="company-branches">
  <div class="company-branches-table">
    <mat-table
      matSort
      [dataSource]="dataSource"
      [matSortActive]="requestParams.sort"
      [matSortDirection]="requestParams.order"
      [@animateStaggerRemondis]="dataSource && dataSource.length"
      [@.disabled]="disableAnimations">

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>{{'COMPANY_BRANCHES.NAME' | translate}}</mat-header-cell>
        <mat-cell *matCellDef="let branch"> {{branch.name}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="address">
        <mat-header-cell *matHeaderCellDef>{{'COMPANY_BRANCHES.ADDRESS' | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let branch"> {{branch.street}}<br/>{{branch.postalCode}} {{branch.city}}
          <br/>{{branch.country}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="contact">
        <mat-header-cell *matHeaderCellDef>{{'COMPANY_BRANCHES.CONTACT' | translate}}
        </mat-header-cell>
        <mat-cell *matCellDef="let branch"> {{branch.contact}}<br/> {{branch.phone}}</mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row
        class="company-branch-row"
        *matRowDef="let branch; columns: displayedColumns;"
        [@animateRemondis]="{value:'*',params:{y:'100%'}}"
        [id]="branch.uuid"
        (click)="viewCompanyBranch(branch.uuid)">
      </mat-row>
    </mat-table>

    <h4 class="no-data" *ngIf="!totalCount || totalCount === 0">
      {{'SHARED.NO_DATA' | translate}}
    </h4>

  </div>
  <div class="button-add-wrapper">
    <button mat-raised-button color="primary"
            [@animate]="{value:'*', params:{delay:'300ms',scale:'.2'}}"
            (click)="addCompanyBranch()"
            [attr.data-cy]="'companyBranchAdd'">
      {{ 'COMPANY_BRANCHES.TITLE_ADD' | translate }}
    </button>
  </div>
</div>