import { NgModule } from '@angular/core';
import { RecyclingItemsContainerComponent } from './recycling-items.container.component';
import { RecyclingItemsEditorComponent } from './components/recycling-items-editor/recycling-items-editor.component';
import { RecyclingItemsListComponent } from './components/recycling-items-list/recycling-items-list.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { Routes } from './recycling-items.routes';

@NgModule({
  declarations: [
    RecyclingItemsContainerComponent,
    RecyclingItemsListComponent,
    RecyclingItemsEditorComponent,
  ],
  imports: [
    RouterModule.forChild(Routes),
    SharedModule,
  ],
  exports: [
    RecyclingItemsListComponent,
    RecyclingItemsEditorComponent,
  ],
  entryComponents: [
    RecyclingItemsEditorComponent
  ]
})
export class RecyclingItemsModule {
}
