<div *ngIf="mode !== 'filter'" class="full-width-wrapper-vertical">
    <h3> {{'TAGS.INPUT_NAME' | translate}} </h3>
    <mat-form-field appearance="outline" fxFlex="auto">
        <mat-chip-list #chipList [disabled]="mode==='view'">
            <mat-chip
                    *ngFor="let tag of tagsControl.value"
                    [selectable]="selectable"
                    [removable]="removable"
                    (removed)="remove(tag)">
                {{tag}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input
                    placeholder="{{'TAGS.SEARCH_PLACEHOLDER' | translate}}"
                    #tagInput
                    name="tags"
                    [disabled]="mode==='view'"
                    [formControl]="formControl"
                    [matAutocomplete]="auto"
                    [matChipInputFor]="chipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    [matChipInputAddOnBlur]="addOnBlur"
                    (matChipInputTokenEnd)="add($event)">
        </mat-chip-list>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            <mat-option *ngFor="let tag of allTags" [value]="tag">
                {{tag}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div>


<div *ngIf="mode === 'filter'" class="filter-tags-autocomplete">
    <mat-form-field appearance="outline" fxFlex="auto">
        <mat-label>{{'TAGS.INPUT_NAME' | translate}}</mat-label>
        <mat-chip-list #chipList [disabled]="mode==='view'">
            <mat-chip
                    *ngFor="let tag of tagsControl.value"
                    [selectable]="selectable"
                    [removable]="removable"
                    (removed)="remove(tag)">
                {{tag}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
        </mat-chip-list>
        <input
                class="tags-filter-input"
                placeholder="{{'TAGS.SEARCH_PLACEHOLDER' | translate}}"
                #tagInput
                name="tags"
                [disabled]="mode==='view'"
                [formControl]="formControl"
                [matAutocomplete]="auto"
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                [matChipInputAddOnBlur]="addOnBlur"
                (matChipInputTokenEnd)="add($event)">
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            <mat-option *ngFor="let tag of allTags" [value]="tag">
                {{tag}}
            </mat-option>
        </mat-autocomplete>

    </mat-form-field>
</div>