import { OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material';
import { RequestParams } from './list.model';

export abstract class List implements OnInit {

  public getElementsList: Function;
  public dataSource: any[] = [];
  public totalCount: number;
  public disableAnimations = false;
  @ViewChild(MatSort) sort: MatSort;
  public requestParams: RequestParams = {
    page: 1,
    limit: 20,
    sort: 'name',
    order: 'asc'
  };

  //ToDO - all events with filters, requestParams should go to the list.class

  ngOnInit() {
    if (this.sort) {
      this.sort.sortChange.subscribe((data) => {
        this.requestParams.sort = data.active;
        this.requestParams.order = data.direction;
        this.requestParams.page = 1;
        this.disableAnimations = true;
        this.getElementsList();
      });
    }
  }

  public pageChange(event) {
    this.requestParams.page = ++event.pageIndex;
    this.disableAnimations = true;
    this.getElementsList();
  }
}