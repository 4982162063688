import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { RequestParams } from '../shared/models/list.model';
import { AuthService } from './auth.service';
import { CompanyAccount, CompanyAccountsListRespone } from '../shared/models/company-accounts.model';
import { Company } from '../shared/models/companies.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyAccountsService {
  onEditEvent: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient, private authService: AuthService) {
  }

  getCompanyAccountsList(params: RequestParams, companyUuid: Company['uuid']): Observable<CompanyAccountsListRespone> {
    return this.http.get(`${environment.apiUrl}/${this.authService.getUserType()}/customersCompanies/${companyUuid}/users`,
      {params: params}).pipe(map((res: CompanyAccountsListRespone) => res));
  }

  getCompanyAccount(companyUuid: Company['uuid'], accountUuid: CompanyAccount['uuid']): Observable<CompanyAccount> {
    return this.http.get(`${environment.apiUrl}/${this.authService.getUserType()}/customersCompanies/${companyUuid}/users/${accountUuid}`)
      .pipe(map((res: any) => res.client));
  }

  createCompanyAccount(companyUuid: Company['uuid'], account: CompanyAccount): Observable<CompanyAccount> {
    return this.http.post<any>(`${environment.apiUrl}/${this.authService.getUserType()}/customersCompanies/${companyUuid}/users`, account)
      .pipe(map((res: any) => res.client));
  }

  updateCompanyAccount(companyUuid: Company['uuid'], accountUuid: CompanyAccount['uuid'], account: CompanyAccount): Observable<CompanyAccount> {
    return this.http.put<any>(`${environment.apiUrl}/${this.authService.getUserType()}/customersCompanies/${companyUuid}/users/${accountUuid}`, account);
  }

  attachCompanyAccount(companyUuid: Company['uuid'], accountUuid: CompanyAccount['uuid']) {
    return this.http.post<any>(`${environment.apiUrl}/${this.authService.getUserType()}/customersCompanies/${companyUuid}/attachUser`,
      {clientUuid: accountUuid});
  }

  detachCompanyAccount(companyUuid: Company['uuid'], accountUuid: CompanyAccount['uuid']) {
    return this.http.post<any>(`${environment.apiUrl}/${this.authService.getUserType()}/customersCompanies/${companyUuid}/detachUser`,
      {clientUuid: accountUuid});
  }

  changeCompanyAccountPassword(companyUuid: Company['uuid'], accountUuid: CompanyAccount['uuid'], password: string): Observable<CompanyAccount> {
    return this.http.put<any>(
      `${environment.apiUrl}/${this.authService.getUserType()}/customersCompanies/${companyUuid}/users/${accountUuid}/set-password`,
      {password})
  }
}
