<ng-container *ngIf="changes.fractions">
  <div *ngIf="changes.fractions.added.length > 0">
    <h3>{{'HISTORY.HISTORY_KEYS.fractionsAdded' | translate}} <strong
      *ngFor="let fraction of changes.fractions.added; let i=index">{{fraction.name}}{{i < changes.fractions.added.length - 1 ? ', ' : ''}} </strong>
    </h3>
  </div>
  <div *ngIf="changes.fractions.removed.length > 0">
    <h3>{{'HISTORY.HISTORY_KEYS.fractionsRemoved' | translate}} <strong
      *ngFor="let fraction of changes.fractions.removed; let i=index">{{fraction.name}}{{i < changes.fractions.removed.length - 1 ? ', ' : ''}} </strong>
    </h3>
  </div>
  <p *ngIf="changes.value">
    {{'HISTORY.HISTORY_KEYS.totalWeight' | translate}}
    <strong>{{changes.value.old}}</strong> {{'HISTORY.HISTORY_KEYS.kg' | translate}}
    --> <strong>{{changes.value.new}}</strong> {{'HISTORY.HISTORY_KEYS.kg' | translate}}
  </p>
  <mat-divider></mat-divider>
  <h3 *ngIf="changes.address"><strong>{{'HISTORY.HISTORY_KEYS.targetPlace' | translate}}</strong></h3>
</ng-container>

<p *ngIf="changes.address && changes.address.street">
  {{'HISTORY.HISTORY_KEYS.street' | translate}} <strong>{{changes.address.street.old}}</strong>
  --> <strong>{{changes.address.street.new}}</strong>
</p>
<p *ngIf="changes.address && changes.address.postalCode">
  {{'HISTORY.HISTORY_KEYS.postalCode' | translate}} <strong>{{changes.address.postalCode.old}}</strong>
  --> <strong>{{changes.address.postalCode.new}}</strong>
</p>
<p *ngIf="changes.address && changes.address.city">
  {{'HISTORY.HISTORY_KEYS.city' | translate}} <strong>{{changes.address.city.old}}</strong>
  --> <strong>{{changes.address.city.new}}</strong>
</p>
<p *ngIf="changes.address && changes.address.country">
  {{'HISTORY.HISTORY_KEYS.country' | translate}} <strong>{{changes.address.country.old}}</strong>
  --> <strong>{{changes.address.country.new}}</strong>
</p>
<p *ngIf="changes.registrationNumFront">
  {{'HISTORY.HISTORY_KEYS.registrationNumFront' | translate}}: <strong>{{changes.registrationNumFront.old}}</strong>
  --> <strong>{{changes.registrationNumFront.new}}</strong>
</p>
<p *ngIf="changes.registrationNumBack">
  {{'HISTORY.HISTORY_KEYS.registrationNumBack' | translate}}: <strong>{{changes.registrationNumBack.old}}</strong>
  --> <strong>{{changes.registrationNumBack.new}}</strong>
</p>
<p *ngIf="changes.referenceNumber">
  {{'HISTORY.HISTORY_KEYS.orderReferenceNumber' | translate}}: <strong>{{changes.referenceNumber.old}}</strong>
  --> <strong>{{changes.referenceNumber.new}}</strong>
</p>

<ng-container *ngIf="changes.orderData">
  <ng-container *ngIf="changes.orderData.added.length > 0">
    <h3>
      <strong>{{(changes.orderData.added.length === 1 ? 'HISTORY.HISTORY_KEYS.recyclingItemAdded' : 'HISTORY.HISTORY_KEYS.recyclingItemsAdded') | translate}}</strong>
    </h3>
    <app-recycling-items-display [recyclingItems]="changes.orderData.added"></app-recycling-items-display>
  </ng-container>
  <ng-container *ngIf="changes.orderData.removed.length > 0">
    <h3>
      <strong>{{(changes.orderData.removed.length === 1 ? 'HISTORY.HISTORY_KEYS.recyclingItemRemoved' : 'HISTORY.HISTORY_KEYS.recyclingItemsRemoved') | translate}}</strong>
    </h3>
    <app-recycling-items-display [recyclingItems]="changes.orderData.removed"></app-recycling-items-display>
  </ng-container>
  <ng-container *ngIf="changes.orderData.changed.length > 0">
    <h3>
      <strong>{{(changes.orderData.changed.length === 1 ? 'HISTORY.HISTORY_KEYS.recyclingItemChanged' : 'HISTORY.HISTORY_KEYS.recyclingItemsChanged') | translate}}</strong>
    </h3>
    <app-recycling-items-changes-display
      [recyclingItemsChanged]="changes.orderData.changed"></app-recycling-items-changes-display>
  </ng-container>
</ng-container>
