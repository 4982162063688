import {
  OrderExportSubform,
  OrderImportSubform,
  OrderReceivingSubform,
  TaskStatuses,
  TaskTypes
} from '../../shared/models/tasks.model';
import * as moment from 'moment';

export class CalendarTaskModel {
  start: Date;
  end: Date;
  title: string;
  tooltipTitle: string;
  type: string;
  taskNumber: string;
  status: TaskStatuses;
  recyclingItemsWeights?: RecyclingItemsWeight[];
  fractions?: Fractions;
  uuid: string;
  color: {
    primary: string;
    secondary: string;
  };
  cssClass?: string;
  resizable?: {
    beforeStart?: boolean;
    afterEnd?: boolean;
  };
  draggable?: boolean;
  taskData?: Array<OrderExportSubform | OrderImportSubform | OrderReceivingSubform>;
  transport?: {
    category: number;
    description: string;
    name: string;
    parameters: {
      capacity: number;
      volume: number;
    }
  };

  constructor(user, data?) {
    data = data || {};
    const colorPalette = {
      export: {
        primary: '#33cc33',
        secondary: '#c2f0c2'
      },
      import: {
        primary: '#003399',
        secondary: '#b3ccff'
      },
      receiving: {
        primary: '#660066',
        secondary: '#ffccff'
      },
      cancelled: {
        primary: '#706d70',
        secondary: '#dbdbdb'
      },
      background: {
        primary: '#DC143C',
        secondary: 'rgba(220, 20, 60, 0.42)'
      },
      backgroundAllDay: {
        primary: '#ff9900',
        secondary: 'rgba(255, 255, 0, 0.42)'
      }
    };

    if (data.taskType !== 'export') {
      this.recyclingItemsWeights = this.getRecyclingItemsWeights(data.totalWeight);
    } else {
      this.fractions = data.totalWeight;
    }
    this.start = moment(data.startDate).toDate();
    this.end = moment(data.endDate).toDate();
    this.title = this.getEventBoxText(data);
    this.tooltipTitle = data.name || '';
    this.type = data.taskType || '';
    this.taskNumber = data.taskNumber || '';
    this.status = data.status || '';
    this.uuid = data.uuid || '';
    this.taskData = data.taskData || '';
    this.transport = data.transport || null;
    this.color = {
      primary: data.color && data.color.primary || '#2D323E',
      secondary: data.color && data.color.secondary || '#8891b0'
    };
    this.draggable = data.status !== TaskStatuses.Cancelled && user.userType === 'logistician';
    this.resizable = {
      beforeStart: data.status !== TaskStatuses.Cancelled && user.userType === 'logistician',
      afterEnd: data.status !== TaskStatuses.Cancelled && user.userType === 'logistician'
    };
    this.cssClass = data.status === TaskStatuses.Cancelled ? 'calendar-event cancelled-event' : 'calendar-event';
    if (data.status === TaskStatuses.Cancelled) {
      this.color = {
        primary: colorPalette.cancelled.primary,
        secondary: colorPalette.cancelled.secondary
      };
    } else if (data.taskType === TaskTypes.Receiving) {
      this.color = {
        primary: colorPalette.receiving.primary,
        secondary: colorPalette.receiving.secondary
      };
    } else if (data.taskType === TaskTypes.Export) {
      this.color = {
        primary: colorPalette.export.primary,
        secondary: colorPalette.export.secondary
      };
    } else {
      this.color = {
        primary: colorPalette.import.primary,
        secondary: colorPalette.import.secondary
      };
    }
  }

  private getEventBoxText(data): string {
    let eventText = '';
    let numberOffAvailableRows = (moment(data.endDate).diff(moment(data.startDate), 'minutes') / 30) * 2;
    if (data.taskType !== 'export') {
      if (numberOffAvailableRows < 4) {
        eventText = `<span class='titleTask'>${data.name}</span>` +
          `<span class='taskTransport'>${data.transport.name}</span>`;
      } else if (numberOffAvailableRows === 4) {
        eventText = `<span class='titleTaskMultiline max-height-${numberOffAvailableRows - 2}'>${data.name}</span>` +
          `<span class='taskTransport'>${data.transport.name}</span>` +
          `<span class='recycleItem'>${this.recyclingItemsWeights[ 0 ].name}: ${this.recyclingItemsWeights[ 0 ].value} kg</span>`;
        if (this.recyclingItemsWeights.length > 1) {
          eventText += `<span class='more'>...</span>`;
        }
      } else {
        eventText = `<span class='titleTaskMultiline max-height-${numberOffAvailableRows - 3}'>${data.name}</span>` +
          `<span class='taskTransportMargin'>${data.transport.name}</span>` +
          `<span class='recycleItem'>${this.recyclingItemsWeights[ 0 ].name}: ${this.recyclingItemsWeights[ 0 ].value} kg</span>`;
        if (this.recyclingItemsWeights.length > 1) {
          eventText += `<span class='more'>...</span>`;
        }
      }
    } else {
      if (numberOffAvailableRows < 4) {
        eventText = `<span class='titleTask'>${data.name}</span>` +
          `<span class='taskTransport'>${data.transport.name}</span>`;
      } else if (numberOffAvailableRows === 4) {
        eventText = `<span class='titleTaskMultiline max-height-${numberOffAvailableRows - 2}'>${data.name}</span>` +
          `<span class='taskTransport'>${data.transport.name}</span>` +
          `<span class='recycleItem'>${this.fractions.name.join(', ')}</span>`;
      } else {
        eventText = `<span class='titleTaskMultiline max-height-${numberOffAvailableRows - 2}'>${data.name}</span>` +
          `<span class='taskTransportMargin'>${data.transport.name}</span>` +
          `<span class='recycleItem'>${this.fractions.name.join(', ')}</span>`;
      }
    }
    return eventText;
  }

  private getRecyclingItemsWeights(totalWeights: RecyclingItemsApiWeights) {
    let recyclingItems = [];
    Object.keys(totalWeights).forEach(function (key) {
      recyclingItems.push({name: key, value: totalWeights[ key ]});
    });
    return recyclingItems.sort((a,b)=> b.value-a.value);
  }
}

export interface CalendarUpdatedTaskModel {
  allDay: boolean;
  event: CalendarTaskModel;
  newEnd: Date;
  newStart: Date;
  type: string
}

export interface RecyclingItemsWeight {
  name: string;
  value: number;
}

export interface RecyclingItemsApiWeights {
  [ key: string ]: number;
}

export interface Fractions {
  name: string[];
  totalWeight: number;
}