<div class="reports">
  <div class="header p-16 p-sm-24"
       fxLayout.gt-xs="row"
       fxLayoutAlign.gt-xs="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center">
      <div class="logo" fxLayout="row" fxLayoutAlign="start center">
        <mat-icon class="logo-icon mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
          list_alt
        </mat-icon>
        <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
          {{'REPORTS.TITLE' | translate}}
        </span>
      </div>
    </div>

    <div class="filters-picker-wrapper">
      <mat-form-field class="branch-picker">
        <mat-select [(ngModel)]="companyBranchIdFilter" (selectionChange)="getReport()" [attr.data-cy]="'setBranch'">
          <mat-option value="" [attr.data-cy]="'all'">
            {{'REPORTS.ALL' | translate}}
          </mat-option>
          <mat-option *ngFor="let branch of companyBranches" [value]="branch.uuid" [attr.data-cy]="branch.name">
            {{branch.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="month-picker">
        <mat-select [(ngModel)]="monthFilter" (selectionChange)="getReport()" [attr.data-cy]="'setMonth'">
          <mat-option *ngFor="let month of months" [value]="month.value" [attr.data-cy]="month.value">
            {{month.name | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="year-picker">
        <mat-select [(ngModel)]="yearFilter" (selectionChange)="getReport()" [attr.data-cy]="'setYear'">
          <mat-option *ngFor="let year of years" [value]="year" [attr.data-cy]="year">
            {{year}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="reports-content">
    <div class="reports-table">
      <mat-table
        *ngIf="reports.length > 1"
        [dataSource]="reports"
      >
        <ng-container matColumnDef="week" sticky>
          <mat-header-cell *matHeaderCellDef></mat-header-cell>
          <mat-cell *matCellDef="let tableRow">
            <span
              *ngIf="tableRow.isFirstDay">
              {{'REPORTS.WEEK' | translate}} {{tableRow.week}}
            </span>
            <span *ngIf="tableRow.isWeekSum">{{'REPORTS.SUM' | translate}} {{'REPORTS.KG' | translate}}</span>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>&nbsp;</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="date" sticky>
          <mat-header-cell *matHeaderCellDef>{{('REPORTS.MONTHS.' + monthFilter | translate).toUpperCase()}}
          </mat-header-cell>
          <mat-cell *matCellDef="let tableRow">
            {{tableRow.date}}
            <span *ngIf="tableRow.isMonthSum">{{('REPORTS.SUM' | translate).toUpperCase()}} {{'REPORTS.KG' | translate}}</span>
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>{{'REPORTS.SUM' | translate}} {{'REPORTS.KG' | translate}}</mat-footer-cell>
        </ng-container>

        <ng-container *ngFor="let item of columnItems" [matColumnDef]="item">
          <mat-header-cell *matHeaderCellDef>{{item}}</mat-header-cell>
          <mat-cell *matCellDef="let tableRow"> {{tableRow[item]}}</mat-cell>
          <mat-footer-cell *matFooterCellDef>{{monthData[item]}}</mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="sum" stickyEnd>
          <mat-header-cell *matHeaderCellDef>{{'REPORTS.SUM' | translate}} {{'REPORTS.KG' | translate}}</mat-header-cell>
          <mat-cell *matCellDef="let tableRow">{{tableRow.sum}}</mat-cell>
          <mat-footer-cell *matFooterCellDef>{{ monthData.sum }}</mat-footer-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="getTableColumns();"></mat-header-row>
        <mat-row class="report-row"
                 [ngClass]="{'sum-week-row': tableRow.isWeekSum, 'sum-month-row': tableRow.isMonthSum}"
                 *matRowDef="let tableRow; columns: getTableColumns();">
        </mat-row>
        <mat-footer-row class="sum-month-row" *matFooterRowDef="getTableColumns();"></mat-footer-row>
      </mat-table>

      <h4 class="no-data" *ngIf="reports.length === 1">
        {{'SHARED.NO_DATA' | translate}}
      </h4>
    </div>
  </div>
</div>

